import { ActionCreator } from 'redux';
import {
  CLEAR_ANALYTIC_PIE_DATA_ACTION,
  LOAD_ANALYTIC_PIE_ACTION,
  LOAD_ANALYTIC_PIE_FAILED_ACTION,
  LOAD_ANALYTIC_PIE_SUCCEEDED_ACTION,
  LOAD_TRADEMARKS_DICTIONARY_ACTION,
  LOAD_TRADEMARKS_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_TRADEMARKS_DICTIONARY_ACTION_FAILED,
  LOAD_CATEGORY_DICTIONARY_ACTION,
  LOAD_CATEGORY_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_CATEGORY_DICTIONARY_ACTION_FAILED,
  LOAD_MAIN_STATISTIC_ACTION,
  LOAD_MAIN_STATISTIC_SUCCEEDED_ACTION,
  LOAD_MAIN_STATISTIC_FAILED_ACTION,
  LOAD_GS1_STATUS_ACTION,
  LOAD_GS1_STATUS_ACTION_SUCCEEDED,
  LOAD_GS1_STATUS_ACTION_FAILED,
  LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION,
  LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION_SUCCEEDED,
  LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION_FAILED,
  LOAD_PHOTO_CONTENT_STATISTICS_ACTION,
  LOAD_PHOTO_CONTENT_STATISTICS_ACTION_SUCCEEDED,
  LOAD_PHOTO_CONTENT_STATISTICS_ACTION_FAILED,
  LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION,
  LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION_SUCCEEDED,
  LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION_FAILED,
  LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION,
  LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION_SUCCEEDED,
  LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION_FAILED,
} from './Analytic.constants';

export const loadMainStatistic: ActionCreator = () => ({
  type: LOAD_MAIN_STATISTIC_ACTION,
});

export const loadMainStatisticSucceeded: ActionCreator = (data) => ({
  type: LOAD_MAIN_STATISTIC_SUCCEEDED_ACTION,
  data: data,
});

export const loadMainStatisticFailed: ActionCreator = () => ({
  type: LOAD_MAIN_STATISTIC_FAILED_ACTION,
});

export const loadAnalyticPie: ActionCreator = (query, cbk = () => null) => ({
  type: LOAD_ANALYTIC_PIE_ACTION,
  query,
  cbk: cbk,
});

export const loadAnalyticPieSucceded: ActionCreator = (data) => ({
  type: LOAD_ANALYTIC_PIE_SUCCEEDED_ACTION,
  data: data,
});

export const loadAnalyticPieFailed: ActionCreator = () => ({
  type: LOAD_ANALYTIC_PIE_FAILED_ACTION,
});

export const clearAnalyticPie: ActionCreator = () => ({
  type: CLEAR_ANALYTIC_PIE_DATA_ACTION,
});

export const loadTradeMarksDictionary: ActionCreator = (cbk = () => null) => ({
  type: LOAD_TRADEMARKS_DICTIONARY_ACTION,
  cbk,
});

export const loadTradeMarksDictionarySucceeded: ActionCreator = (data) => ({
  type: LOAD_TRADEMARKS_DICTIONARY_ACTION_SUCCEEDED,
  data,
});

export const loadTradeMarksDictionaryFailed: ActionCreator = () => ({
  type: LOAD_TRADEMARKS_DICTIONARY_ACTION_FAILED,
});

export const loadCategoriesDictionary: ActionCreator = (cbk = () => null) => ({
  type: LOAD_CATEGORY_DICTIONARY_ACTION,
  cbk,
});

export const loadCategoriesDictionarySucceeded: ActionCreator = (data) => ({
  type: LOAD_CATEGORY_DICTIONARY_ACTION_SUCCEEDED,
  data,
});

export const loadCategoriesDictionaryFailed: ActionCreator = () => ({
  type: LOAD_CATEGORY_DICTIONARY_ACTION_FAILED,
});

export const loadGs1Status: ActionCreator = () => ({
  type: LOAD_GS1_STATUS_ACTION,
});

export const loadGs1StatusSucceeded: ActionCreator = (data) => ({
  type: LOAD_GS1_STATUS_ACTION_SUCCEEDED,
  data,
});

export const loadGs1StatusFailed: ActionCreator = () => ({
  type: LOAD_GS1_STATUS_ACTION_FAILED,
});

export const loadTradeMarkElapsedStatistics: ActionCreator = () => ({
  type: LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION,
});

export const loadTradeMarkElapsedStatisticsSucceeded: ActionCreator = (data) => ({
  type: LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION_SUCCEEDED,
  data,
});

export const loadTradeMarkElapsedStatisticsFailed: ActionCreator = () => ({
  type: LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION_FAILED,
});

export const loadPhotoContentStatistics: ActionCreator = () => ({
  type: LOAD_PHOTO_CONTENT_STATISTICS_ACTION,
});

export const loadPhotoContentStatisticsSucceeded: ActionCreator = (data) => ({
  type: LOAD_PHOTO_CONTENT_STATISTICS_ACTION_SUCCEEDED,
  data,
});

export const loadPhotoContentStatisticsFailed: ActionCreator = () => ({
  type: LOAD_PHOTO_CONTENT_STATISTICS_ACTION_FAILED,
});

export const loadGoodsDateExpirationStatistics: ActionCreator = () => ({
  type: LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION,
});

export const loadGoodsDateExpirationStatisticsSucceeded: ActionCreator = (data) => ({
  type: LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION_SUCCEEDED,
  data,
});

export const loadGoodsDateExpirationStatisticsFailed: ActionCreator = () => ({
  type: LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION_FAILED,
});

export const loadGoodsExemplarStatistics: ActionCreator = () => ({
  type: LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION,
});

export const loadGoodsExemplarStatisticsSucceeded: ActionCreator = (data) => ({
  type: LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION_SUCCEEDED,
  data,
});

export const loadGoodsExemplarStatisticsFailed: ActionCreator = () => ({
  type: LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION_FAILED,
});
