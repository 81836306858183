import * as React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  RowItem,
  DateItem,
  ValueItem,
  ValueItemStatus,
} from './styled/DocumentAndOptions.styled';

class StatisticBlock extends React.Component {
  returnStatusString = (status) => {
    const { t } = this.props;
    switch (_.toString(status)) {
      case '0':
        // return <div style={{ color: '#00B634' }}>{t(`Действующий`)}</div>;
        return t(`Активен`);
      // case '1':
      //   return t(`Членство приостановлено`);
      // case '2':
      //   return t(`Исключен`);
      default:
        // return t(`Отсутствует`);
        return t(`Неактивен`);
    }
  };

  render() {
    const { t, data } = this.props;
    const gcpStatus = _.get(data, 'status', null);
    const gcpDate = _.get(data, 'issueDate', `00.00.0000`);

    return (
      <div style={{ padding: '15px 0' }}>
        <RowItem>
          {/* <div>{t('Зарегистрированный префикс GCP')}:</div> */}
          <div>{t('Префикс GCP')}:</div>
          <ValueItem>{_.get(data, 'prefix', null)}</ValueItem>
        </RowItem>
        <RowItem>
          {/* <div>{t('Статус членства в организации GS1')}:</div> */}
          <div>{t('Статус префикса')}:</div>
          <ValueItemStatus status={gcpStatus}>
            {this.returnStatusString(gcpStatus)}
          </ValueItemStatus>
        </RowItem>
        {gcpDate !== '00.00.0000' && (
          <RowItem>
            <DateItem>{t('Дата регистрации')}:</DateItem>
            <DateItem>
              <ValueItem>{gcpDate}</ValueItem>
            </DateItem>
          </RowItem>
        )}
      </div>
    );
  }
}

StatisticBlock.propTypes = {
  data: PropTypes.object.isRequired,
};

export default compose(withTranslation(), connect())(StatisticBlock);
