import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  Button,
  FormControlLabel,
  IconButton,
  MuiThemeProvider,
  Popover,
  Radio,
} from '@material-ui/core';
import {
  IconClose,
  IconNewFilterSmall,
  IconNewFilterSmallActive,
} from '../../../../../common/icons/customIcons';
import {
  DefaultFilterPopover,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContainer,
  FilterContent,
  FilterTitle,
  FilterBtnReset,
} from './Filters.styled';

class TmFileFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      tmAttachment: !!this.props.currentValue,
      isDirty: !_.isNull(this.props.currentValue),
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (_.isNull(nextProps.currentValue) && !_.isNull(state.currentValue)) {
      return {
        anchorEl: null,
        tmAttachment: false,
        isDirty: false,
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextState, this.state);
  }

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAccept = () => {
    this.setState({
      anchorEl: null,
      currentValue: this.state.tmAttachment,
    });
    this.props.onAccept('AttachmentCount', this.state.tmAttachment);
  };

  handleDissmiss = () => {
    this.setState({
      isDirty: false,
      anchorEl: null,
    });
    this.props.onDismiss('AttachmentCount');
  };

  handleChange = (value) => {
    this.setState({
      isDirty: true,
      tmAttachment: value,
    });
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <FilterContainer>
        <MuiThemeProvider theme={FilterBtn}>
          <FilterButton>
            <Button
              aria-owns={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
              {_.isNull(this.props.currentValue) ? (
                <IconNewFilterSmall />
              ) : (
                <IconNewFilterSmallActive />
              )}
            </Button>
          </FilterButton>
        </MuiThemeProvider>
        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Документы и изображения')}</h6>
              <MuiThemeProvider theme={FilterBtn}>
                <IconButton className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </IconButton>
              </MuiThemeProvider>
            </FilterTitle>
            <FilterContent>
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.tmAttachment && this.state.isDirty}
                    onChange={() => this.handleChange(true)}
                  />
                }
                label={t('Есть')}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={!this.state.tmAttachment && this.state.isDirty}
                    onChange={() => this.handleChange(false)}
                  />
                }
                label={t('Нет')}
              />
            </FilterContent>
            <FilterBtns>
              <MuiThemeProvider theme={FilterBtnReset}>
                <Button
                  disabled={!this.state.isDirty}
                  onClick={this.handleDissmiss}>
                  {t('Сбросить')}
                </Button>
              </MuiThemeProvider>
              <Button
                disabled={!this.state.isDirty}
                onClick={this.handleAccept}>
                {t('Применить')}
              </Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </FilterContainer>
    );
  }
}

TmFileFilter.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  currentValue: PropTypes.bool,
  parentId: PropTypes.string.isRequired,
};

export default withTranslation()(TmFileFilter);
