import styled from 'styled-components';
import colors from '../../common/styles/Common.colors';

export const AlertPopup = styled.div`
  border-radius: 8px;
  background: ${colors.Common.textColor};
  padding: 20px 16px 20px 20px;
  color: #fff; 
  font-size: 16px;
  line-height: 24px;  
  margin-top: 28px !important;
  z-index: 9999;
  max-width: 530px;
  display: flex;
  align-items: flex-start;
  button {
    background: none;
    border: none;
    margin: 0 0 0 12px;
    padding: 0;    
    svg:hover path{
      stroke: #AAA9A9;
    }  
  }
  p {
    margin: 0 0 0 4px;
  }
  span {
    align-self: center;
  }  
`;
