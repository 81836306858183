import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
  filter,
  flow,
  forEach,
  isEmpty,
  isEqual,
  isNil,
  map,
  some,
} from 'lodash';
import { Button, MenuItem, MuiThemeProvider, Tooltip, IconButton } from '@material-ui/core';
import {
  ProductButton,
  TextTooltipModeration,
  TextTooltipModerationUpper,
  DefaultTooltip,
} from '../../../../../common/styles/Common.styled';
import {
  MediaButton,
  MediaImg,
  MediaImgName,
  MediaItemMain,
  MediaItemPack,
  MediaItemSide,
  MediaItemSideDelete,
  MediaItemSideImage,
  TrashButton,
  ImageContainer,
  MediaItemCard,
  MediaItemCardTitle,
  MediaItemCardImage,
  MediaItemCardIconContainer,
  MediaItemCardIconItem,
  MediaItemBtnsContainer,
  MediaItemFullContent,
  MediaItemCardsContainer,
} from './MediaItem.styled';
import {
  IconArrowUpCircle,
  IconErrorRed,
  IconTrash,
  IconImage,
} from '../../../../../common/icons/customIcons';
import { throwNotification } from '../../../../../common/structure';
import { getNewPackagingType } from '../../../../../common/constants/Packaging';
import { gtinDisplay } from '../../../../../common/utils/utils';
import SimpleSlider from '../../../../../components/slider/slider';
import { getPhotoType } from '../../../../../common/constants/Packaging';

const allowedFileTypes = ['image/png', 'image/jpeg', 'image/gif'];

function fileIsIncorrectFiletype(file) {
  return file.size < 1 || allowedFileTypes.indexOf(file.type) === -1;
}

class MediaItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: this.props.data?.url || '',
      fileName: '',
      keyStr: '',
      bottom: true,
      hasWatermark: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!isEqual(this.state, nextState)];
    const nameProps = [
      'data',
      'allowDelete',
      'packaging',
      'error',
      'status',
      'disableImg',
      'locale',
      'images',
      'photoTypes',
      'isFullPhotoContent',
      'focusDownloadIndex',
    ];
    forEach(nameProps, (i) =>
      equal.push(!isEqual(this.props[i], nextProps[i])),
    );
    return some(equal);
  }

  handleChange = (name, value) => {
    this.props.updateItem(this.props.data.id, { [name]: value });
  };

  handleChangeImg = (event, hasWatermark) => {
    const file = event.target.files[0];
    const throwError = flow([this.props.t, this.props.throwError]);

    if (fileIsIncorrectFiletype(file)) {
      throwError('Неподдерживаемый формат изображения');
      return;
    }

    if (file.size / 1024 / 1024 > 15) {
      throwError('Файл не может превышать размер в 15 мб');
      return;
    }

    if (file) {
      hasWatermark ?
        this.setState(
          {
            hasWatermark: 1,
            fileName: file.name,
            preview: URL.createObjectURL(file),
          },
          () => { this.props.updateItem(this.props.data?.id, { url: file, hasWatermark: 1 }) }
        )
        : this.setState(
          {
            hasWatermark: 0,
            fileName: file.name,
            preview: URL.createObjectURL(file),
          },
          () => { this.props.updateItem(this.props.data?.id, { url: file, hasWaterMark: 0 }) }
        );
    }
  };

  deleteImg = () => {
    this.setState(
      {
        preview: '',
        fileName: '',
        keyStr: Math.random().toString(36),
      },
      () => this.props.updateItem(this.props.data.id, { url: '', hasWatermark: null }),
    );
  };

  deleteItem = (id) => {
    this.props.deleteItem(id);
  };

  handleOpen = (id) => (event) => {
    const element = document.getElementById(id);
    if (!isNil(element)) {
      const bottom = element.getBoundingClientRect().y > event.clientY;
      if (this.state.bottom !== bottom) {
        this.setState({ bottom });
      }
    }
  };

  checkUnusedImages = (images) => {
    const usedTypes = [];

    _.forEach(images, (obj) => {
      usedTypes.push(obj.type);
    });

    return usedTypes;
  };

  setCurrentIndex = (index, isExistPhoto) => {
    if (isExistPhoto) {
      return;
    } else this.props.setCurrentIndex(index);
  };

  render() {
    const { t, type, images, isTechCard, isUnmarkable, focusDownloadIndex } = this.props;
    const isError = !isEmpty(this.props.errors);
    const disabled = this.props.disableImg || this.props.isCatalogCard || !!this.props.data?.url;
    // const usedImageTypes = this.checkUnusedImages(images);
    // const isDisabledOption = (option) => {
    //   return _.includes(usedImageTypes, option);
    // };
    // const canUploadPhoto = !_.isEmpty(this.props.data?.type) || this.props.data?.type === undefined;
    const canUploadPhoto = !!this.props.data;
    let packingType;
    if (type === 'consumer' && isTechCard) {
      isUnmarkable ? packingType = 'unmarkable' : packingType = 'techCard';
    } else packingType = type;

    return (
      <MediaItemMain>
        {/* {isError && (
          <MuiThemeProvider
            theme={
              // this.state.bottom
              //   ? TextTooltipModeration
              //   : TextTooltipModerationUpper
              TextTooltipModeration
            }>
            <Tooltip
              interactive
              id={`tooltip_media_moderate_${this.props.data.id}`}
              onOpen={this.handleOpen(
                `tooltip_media_moderate_${this.props.data.id}`,
              )}
              placement="bottom-end"
              title={
                <div>
                  <b>Комментарий: </b>
                  <span>{this.props.error}</span>
                  <p>
                    <i>Модератор: {this.props.author}</i>
                  </p>
                </div>
              }
            //title={this.props.error}
            >
              <ModerationIconHolder>
                <IconErrorRed width={20} height={20} />
              </ModerationIconHolder>
            </Tooltip>
          </MuiThemeProvider>
        )} */}
        <SimpleSlider
          data={images}
        />
        {this.props.isFullPhotoContent && (
          <MediaItemFullContent>
            <MediaItemCardsContainer>
              {images.length &&
                images.map((item, index) => {
                  return (
                    <MediaItemCard
                      key={item.id}
                      onClick={() => this.setCurrentIndex(index, !!item.url)}
                    >
                      <MediaItemCardTitle>
                        {t(getPhotoType(item.type))}
                      </MediaItemCardTitle>
                      <ImageContainer active={focusDownloadIndex === index}>
                        {!!item.url
                          ? (
                            <MuiThemeProvider theme={DefaultTooltip}>
                              <Tooltip
                                placement="bottom"
                                title={t('Для добавления удалите имеющееся фото')}
                              >
                                <MediaItemCardImage
                                  alt="Изображение"
                                  src={typeof item.url === 'string' ? item.url : URL.createObjectURL(item.url)}
                                />
                              </Tooltip>
                            </MuiThemeProvider>
                          )
                          : (
                            <IconImage color={'#DCDCDC'} />
                          )
                        }
                      </ImageContainer>
                      <MediaItemCardIconContainer>
                        <>
                          {!!item.url && (
                            <>
                              {isError && !!this.props.errors[item.id] && (
                                <MuiThemeProvider theme={TextTooltipModeration}>
                                  <Tooltip
                                    placement="bottom-end"
                                    title={
                                      <div>
                                        <b>Комментарий: </b>
                                        <span>{this.props.errors[item.id]}</span>
                                        <p>
                                          <i>Модератор: {this.props.authors[item.id]}</i>
                                        </p>
                                      </div>
                                    }
                                  >
                                    <MediaItemCardIconItem>
                                      <IconErrorRed width={20} height={20} />
                                    </MediaItemCardIconItem>
                                  </Tooltip>
                                </MuiThemeProvider>
                              )}
                              <MuiThemeProvider theme={TrashButton}>
                                <IconButton
                                  disabled={item.disabled}
                                  onClick={() => this.deleteItem(item.id)}>
                                  <IconTrash color={'#AAA9A9'} style={{ width: 20, height: 20 }} />
                                </IconButton>
                              </MuiThemeProvider>
                            </>
                          )}
                        </>
                      </MediaItemCardIconContainer>
                    </MediaItemCard>
                  )
                })}
            </MediaItemCardsContainer>
            <MediaItemBtnsContainer>
              {/* <MediaItemSide>
                <CustomSelect
                  disabled={disabled}
                  value={_.defaultTo(this.props.data?.type, '')}
                  error={isError}
                  id="photo-type-select"
                  label={t('Ракурс')}
                  hideCleanButton
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  IconComponent={IconDropDown}
                  className={cx({ 'disabled-item': disabled })}
                  onChange={(event) => this.handleChange('type', event.target.value)}>
                  {map(photoTypes, (i) => (
                    <MenuItem
                      key={i.id}
                      value={i.id}
                      disabled={isDisabledOption(i.id)}>
                      {t(i.text)}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </MediaItemSide> */}
              {/* <MediaItemPack>
          <CustomSelect
            required={!!this.state.preview && !this.props.data?.gtin}
            disabled={disabled}
            hideCleanButton
            label={t('Упаковка')}
            value={this.props.data?.gtin}
            error={isError}
            id="packaging-select"
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            }}
            className={cx('pack-select-main', { 'disabled-item': disabled })}
            IconComponent={IconDropDown}
            onChange={(event) => this.handleChange('gtin', event.target.value)}>
            {_.includes(
              ['packageType', 'packageMaterial'],
              this.props.data?.type,
            )
              ? map(
                filter(this.props.packaging, (i) => !isEmpty(i.level)),
                (i) => (
                  <MenuItem key={`${type}_${i.gtin}`} value={i.gtin}>
                    <div className="pack-select">
                      {`${t(getNewPackagingType(packingType))} ${i.gtin ? `(${gtinDisplay(i.gtin)})` : ''
                        }`}
                    </div>
                  </MenuItem>
                ),
              )
              : _.map(this.props.packaging, (i) => (
                <MenuItem key={`${i.id}_${i.gtin}`} value={i.gtin}>
                  <div className="pack-select">
                    {`${t(getNewPackagingType(packingType))} ${i.gtin ? `(${gtinDisplay(i.gtin)})` : ''
                      }`}
                  </div>
                </MenuItem>
              ))} */}
              {/* use it if second-pack must be hard code */}
              {/* {concat(
                            <MenuItem>
                                <div className="pack-select">
                                    {`${t(getPackagingType("second-pack"))}`}
                                </div>
                            </MenuItem>,
                            map(filter(this.props.packaging, i => !isEmpty(i.level)), i =>
                                <MenuItem key={`${i.level}_${i.gtin}`} value={i.gtin}>
                                    <div className="pack-select">
                                        {`${t(getPackagingType(i.level))} ${i.gtin ? `(${gtinDisplay(i.gtin)})` : ''}`}
                                    </div>
                                </MenuItem>
                            )
                        )} */}
              {/* </CustomSelect>
        </MediaItemPack> */}
              {/* {!this.state.preview && ( */}
              {/* <MediaItemSideImage> */}
              <MuiThemeProvider theme={MediaButton}>
                <Button
                  component="label"
                  disabled={disabled || !canUploadPhoto}
                  color={'secondary'}>
                  <IconArrowUpCircle style={{ marginRight: 11 }} />
                  {t('Загрузить фото и наложить водяной знак системы')}
                  <input
                    key={this.state.keyStr}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(event) => this.handleChangeImg(event, 'hasWatermark')}
                  />
                </Button>
              </MuiThemeProvider>
              {/* </MediaItemSideImage> */}

              {/* <MediaItemSideImage> */}
              <MuiThemeProvider theme={MediaButton}>
                <Button
                  component="label"
                  disabled={disabled || !canUploadPhoto}
                  color={'secondary'}>
                  <IconArrowUpCircle style={{ marginRight: 11 }} />
                  {t('Загрузить файл')}
                  <input
                    key={this.state.keyStr}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={this.handleChangeImg}
                  />
                </Button>
              </MuiThemeProvider>
              {/* </MediaItemSideImage> */}

              {/* {!this.state.preview ? (
          <MediaItemSideImage>
            <MuiThemeProvider theme={ProductButton}>
              <Button
                component="label"
                disabled={disabled || !canUploadPhoto}
                color={'secondary'}>
                <IconUpload style={{ marginRight: '15px' }} />
                {t('Загрузить файл')}
                <input
                  key={this.state.keyStr}
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={this.handleChangeImg}
                />
              </Button>
            </MuiThemeProvider>
          </MediaItemSideImage>
        ) : (
          <MediaImg theme={{ main: isError ? '#FF4B4B' : '#D9D9D9' }}>
            <img
              style={{ maxWidth: '198px', height: '40px' }}
              alt=""
              src={this.state.preview}
            />
            <MediaImgName>{this.state.fileName}</MediaImgName>
            <MuiThemeProvider theme={ProductButton}>
              <Button
                disabled={disabled}
                color={'secondary'}
                onClick={this.deleteImg}>
                <IconDeleteSmall />
              </Button>
            </MuiThemeProvider>
          </MediaImg>
        )} */}

              {/* {this.props.allowDelete && (
          <MediaItemSideDelete>
            <MuiThemeProvider theme={ProductButton}>
              <Button
                disabled={disabled}
                color={'primary'}
                onClick={this.deleteItem}>
                <IconDeleteSmall style={{ marginRight: '15px' }} />
                {t('Удалить')}
              </Button>
            </MuiThemeProvider>
          </MediaItemSideDelete>
        )} */}
            </MediaItemBtnsContainer>
          </MediaItemFullContent>
        )}
      </MediaItemMain>
    );
  }
}

MediaItem.propTypes = {
  throwError: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  data: PropTypes.object,
  allowDelete: PropTypes.bool.isRequired,
  disableImg: PropTypes.bool,
  packaging: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.string,
    }),
  ),
  error: PropTypes.string,
  status: PropTypes.string,
  locale: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwError: (message) => throwNotification(message, 'error'),
    },
    dispatch,
  );
};

const mapStateToProps = (state) => ({
  locale: state.profileReducer.locale,
  photoTypes: state.productReducer.photoTypesDictionary,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(MediaItem);
