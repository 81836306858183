import * as React from 'react';
import * as PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Button, MuiThemeProvider } from '@material-ui/core';
import { CustomInput, PasswordInput } from '../../../components';
import { IconInfoCard, IconPreloader } from '../../../common/icons/customIcons';
import {
  validateEmail,
  validateEmailOnDirty,
  validatePassword,
} from '../../../common/utils/Validation';
import {
  HowLink,
  AuthTitle,
  AuthContent,
  AuthInputContainer,
  defaultAuthInput,
  defaultAuthBtn,
} from '../Authentification.styled';
import {
  BtnContPaddingless,
} from '../../../common/styles/Common.styled';
import ActiveSessionModal from '../modal/ActiveSessionModal';

class AuthentificationLoginView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      accountId: '',
      emailValid: true,
      emailDirty: false,
      accountIdDirty: false,
    };
  }

  handleChange = (name, value) => {
    let state = cloneDeep(this.state);
    state[name] = value;
    if (name === 'email') state.emailDirty = true;

    this.setState(state);
  };

  onUpdateEmail = (value) => {
    this.setState({ emailValid: validateEmail(value) });
  };

  onRemindPasswordClick = () => {
    if (this.props.loading) return;
    this.setState(
      {
        emailValid: validateEmail(this.state.email),
        emailDirty: true,
        accountIdDirty: true,
      },
      () => {
        if (
          this.state.emailValid &&
          this.state.emailDirty &&
          this.state.accountId
        ) {
          this.props.onRemindPassword({
            email: this.state.email,
            accountId: this.state.accountId,
          });
        }
      },
    );
  };

  render() {
    const { onLogin, t } = this.props;

    return (
      <AuthContent>
        <AuthTitle>{t('Вход в личный кабинет')}</AuthTitle>
        {this.props.isActiveSessionOpenModal && (
          <ActiveSessionModal
            activeSessionInfo={this.props.activeSessionInfo}
            handleSubmit={() =>
              onLogin({
                email: this.state.email,
                password: this.state.password,
                accountId: this.state.accountId,
                closeOpenSessions: true,
              })
            }
          />
        )}
        <MuiThemeProvider theme={defaultAuthInput}>
          <AuthInputContainer>
            <CustomInput
              id="accountId"
              name="accountId"
              cleanButtonStyles={{ display: 'none' }}
              onChange={(value) => this.handleChange('accountId', value)}
              label={t('Идентификатор  аккаунта компании')}
              value={this.state.accountId}
              type="number"
              error={!this.state.accountId && this.state.accountIdDirty}
              dirty={this.state.accountIdDirty}
            />
          </AuthInputContainer>
          <AuthInputContainer>
            <CustomInput
              id="email"
              cleanButtonStyles={{ display: 'none' }}
              name="email"
              onChange={(value) => this.handleChange('email', value)}
              onUpdate={(value) => this.onUpdateEmail(value)}
              label={
                !this.state.emailValid
                  ? t('Введите корректный E-mail')
                  : t('E-mail')
              }
              autoComplete="email"
              value={this.state.email}
              type="email"
              dirty={this.state.emailDirty}
              error={!this.state.emailValid && this.state.emailDirty}
            />
          </AuthInputContainer>
          <AuthInputContainer>
            <PasswordInput
              error={validatePassword(this.state.password)}
              name="password"
              id="password"
              onChange={(value) => this.handleChange('password', value)}
              value={this.state.password}
              label={t('Пароль')}
            />
          </AuthInputContainer>
        </MuiThemeProvider>
        <BtnContPaddingless>
          <MuiThemeProvider theme={defaultAuthBtn}>
            <Button
              disabled={
                this.props.loading ||
                validateEmailOnDirty(this.state.email) ||
                validatePassword(this.state.password) ||
                !this.state.accountId
              }
              onClick={() =>
                onLogin({
                  email: this.state.email,
                  password: this.state.password,
                  accountId: this.state.accountId,
                })
              }>
              {this.props.loading ? <IconPreloader /> : t('Войти')}
            </Button>
          </MuiThemeProvider>

          <HowLink onClick={this.onRemindPasswordClick}>
            <IconInfoCard style={{ width: '24px', height: '24px', marginRight: '8px' }} />
            {t('Забыли пароль?')}
          </HowLink>
        </BtnContPaddingless>
      </AuthContent>
    );
  }
}

AuthentificationLoginView.propTypes = {
  onLogin: PropTypes.func,
  onChange: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemindPassword: PropTypes.func,
  loading: PropTypes.bool,
  emailValid: PropTypes.bool,
  email: PropTypes.string,
  password: PropTypes.string,
  isActiveSessionOpenModal: PropTypes.bool.isRequired,
  activeSessionInfo: PropTypes.string.isRequired,
};

export default withTranslation()(AuthentificationLoginView);
