import styled from 'styled-components';

export const PopupProductContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;
export const PopupProductInn = styled.div`
  position: relative;
  flex-basis: 50%;
  max-width: 232px; 
  margin: 0 20px 0 0;
`;
export const PopupProductName = styled.div`
  position: relative;
  flex-grow: 1;
  flex-basis: 50%;
`;
export const PopupProductGcp = styled.div`
  position: relative;
  flex-basis: 27%;
`;
export const PopupProductCountry = styled.div`
  position: relative;
  flex-basis: 70%;
`;
export const ProductGtinBlock = styled.div`
  // flex-basis: 100%;
  width: calc((100% - 20px) / 2);  
`;
