import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../../common/styles/Common.colors';
import styled from 'styled-components';

export const DatePickerWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DaughterCompanyDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '750px',
        maxWidth: '750px',
        boxShadow: '0 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '30px 36px',
        textAlign: 'center',
        position: 'relative',
        color: '#63666A',
        fontSize: '14px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 36px 48px',
      },
    },
    MuiDialogActions: {
      root: {
        margin: '0',
        padding: '30px 36px',
        justifyContent: 'flex-end;',
        borderTop: '1px solid #E7E7E7;',
      },
    },
    MuiButton: {
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: '.4s ease',
        borderRadius: '3px',
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        padding: '14px 35px',
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          color: '#63666A',
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          boxShadow: 'none',
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textSecondary: {
        background: 'none',
        color: '#63666A',
        transition: '.4s ease',
        borderRadius: '3px',
        border: '1px solid #868686',
        padding: '14px 35px',
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          color: '#868686',
          backgroundColor: 'transparent',
        },
      },
      root: {
        fontSize: '16px',
        textTransform: 'none',
        borderRadius: '3px',
        height: 'auto',
        minWidth: '200px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: '600',
      },
      text: {
        padding: '14px 20px',
      },
      label: {
        lineHeight: '19px',
      },
      disabled: {},
    },
  },
});

export const datePicker = createMuiTheme({
  typography: {},
  overrides: {
    MuiFormControl: {
      root: {
        width: 'calc(50% - 10px)',
      }
    }
  },
});
