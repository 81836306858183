import { all, call, put, takeEvery } from 'redux-saga/effects';
import { defaultTo, isArray, isEmpty, reject } from 'lodash';
import { client } from '../../api/Client';
import i18n from '../../i18n';
import {
  LOAD_DICTIONARY_CHANNELS_GS1_ACTION,
  LOAD_DICTIONARY_COUNTRY_ACTION,
  LOAD_DICTIONARY_DEPARTMENT_ACTION,
  LOAD_DICTIONARY_EDO_PROVIDER_ACTION,
  LOAD_DICTIONARY_GCP_PRODUCTS_ACTION,
  LOAD_DICTIONARY_KNOWLEDGE_GS1_ACTION,
  LOAD_DICTIONARY_OBTAINING_METHOD_ACTION,
  LOAD_DICTIONARY_OKPD_ACTION,
  LOAD_DICTIONARY_OKVED_ACTION,
  LOAD_DICTIONARY_OPF_ACTION,
  LOAD_DICTIONARY_ORG_PROFILES_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIAL_ACTION,
  LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPE_ACTION,
  LOAD_DICTIONARY_PALLET_TYPE_ACTION,
  LOAD_DICTIONARY_POSITION_ACTION,
  LOAD_DICTIONARY_PROJECTS_GS1_ACTION,
  LOAD_DICTIONARY_SALES_GS1_ACTION,
  LOAD_DICTIONARY_SECTOR_ACTION,
  LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_ACTION,
  LOAD_DICTIONARY_SUBJECT_ACTION,
  LOAD_DICTIONARY_TM_ACTION,
  LOAD_NOTIFICATION_ACTION,
  LOAD_PRODUCTS_CATEGORIES_ACTION,
  LOAD_TNVED_ACTION,
  MARK_NOTIFICATION_ACTION,
  LOAD_GTINS_LIST_BY_PACKAGE_ACTION,
  LOAD_ACTIVE_KZ_DICTIONARY_ACTION,
  LOAD_GTINS_LIST_FOR_VALIDATION_ACTION,
  LOAD_KATO_DICTIONARY_ACTION,
  LOAD_DICTIONARY_COUNTRIES_ACTION,
  LOAD_NOTIFICATION_RECENT_ACTION,
} from './CatalogActionTypes.constants';

import {
  loadDictionaryChannelsFailed,
  loadDictionaryChannelsSuccessed,
  loadDictionaryCountryFailed,
  loadDictionaryCountrySuccessed,
  loadDictionaryDepartmentFailed,
  loadDictionaryDepartmentSuccessed,
  loadDictionaryEdoProvidersFailed,
  loadDictionaryEdoProvidersSuccessed,
  loadDictionaryGcpProductsFailed,
  loadDictionaryGcpProductsSuccessed,
  loadDictionaryKnowledgeFailed,
  loadDictionaryKnowledgeSuccessed,
  loadDictionaryObtainingMethodsFailed,
  loadDictionaryObtainingMethodsSuccessed,
  loadDictionaryOkpdFailed,
  loadDictionaryOkpdSuccessed,
  loadDictionaryOkvedFailed,
  loadDictionaryOkvedSuccessed,
  loadDictionaryOpfFailed,
  loadDictionaryOpfSuccessed,
  loadDictionaryOrgProfilesFailed,
  loadDictionaryOrgProfilesSuccessed,
  loadDictionaryPackageMaterialFailed,
  loadDictionaryPackageMaterialSuccessed,
  loadDictionaryPackageTypeFailed,
  loadDictionaryPackageTypeSuccessed,
  loadDictionaryPalletTypeFailed,
  loadDictionaryPalletTypeSuccessed,
  loadDictionaryPositionFailed,
  loadDictionaryPositionSuccessed,
  loadDictionaryProjectsFailed,
  loadDictionaryProjectsSuccessed,
  loadDictionarySalesFailed,
  loadDictionarySalesSuccessed,
  loadDictionarySectorsFailed,
  loadDictionarySectorsSuccessed,
  loadDictionaryShowboxDisplaySignFailed,
  loadDictionaryShowboxDisplaySignSuccessed,
  loadDictionarySubjectFailed,
  loadDictionarySubjectSuccessed,
  loadDictionaryTmFailed,
  loadDictionaryTmSuccessed,
  loadNotificationFailed,
  loadNotificationSuccessed,
  loadProductsCategoriesFailed,
  loadProductsCategoriesSuccessed,
  loadTnvedsFailed,
  loadTnvedsSucceesed,
  markAsReadNotificationSuccessed,
  loadDictionaryPackageLetterNumeralCodeFailed,
  loadDictionaryPackageLetterNumeralCodeSuccessed,
  loadDictionaryPackageTypesCappingFailed,
  loadDictionaryPackageTypesCappingSuccessed,
  loadDictionaryPackageMaterialsCappingFailed,
  loadDictionaryPackageMaterialsCappingSuccessed,
  loadGtinsByPackageDictionarySuccessed,
  loadGtinsByPackageDictionaryFailed,
  loadActiveKzDictionarySuccessed,
  loadActiveKzDictionaryFailed,
  loadGtinsListForValidationSucceeded,
  loadGtinsListForValidationFailed,
  loadKatoDictionarySucceeded,
  loadKatoDictionaryFailed,
  loadDictionaryCountriesSuccessed,
  loadDictionaryCountriesFailed,
  loadNotificationRecentSuccessed,
  loadNotificationRecentFailed,
} from './Catalog.action';
import { throwNotification } from '../structure';
import { handleErrorNotification } from '../../pages/ducks/HandleErrors';
import { insertParam } from '../../common/utils/utils';

export function* loadDictionaryTm() {
  const errT = i18n.t('Ошибка получения справочника торговых марок!');
  try {
    const response = yield call(client().get, '/dictionaries/tm');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryTmFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(loadDictionaryTmSuccessed(isArray(response) ? response : []));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadDictionaryTmFailed());
  }
}

export function* loadTnveds() {
  const errT = i18n.t('Ошибка получения данных ТНВЭД!');
  try {
    const response = yield call(client().get, '/dictionaries/tnved');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadTnvedsFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(loadTnvedsSucceesed(isArray(response) ? response : []));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadTnvedsFailed(e));
  }
}

export function* loadDictionaryOpf(action) {
  const errT = i18n.t('Ошибка получения справочника ОПФ!');
  try {
    const response = yield call(client().get, '/dictionaries/gs1/opf');
    if (response.error) {
      action.cbk({ error: errT });
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
    } else {
      if (!isArray(response)) {
        action.cbk({ error: errT });
        yield put(throwNotification(errT, 'error'));
        yield put(loadDictionaryOpfFailed());
      } else {
        action.cbk(null);
        yield put(
          loadDictionaryOpfSuccessed(isArray(response) ? response : []),
        );
      }
    }
  } catch (e) {
    action.cbk({ error: errT });
    yield put(loadDictionaryOpfFailed());
  }
}

export function* loadDictionaryPosition() {
  const errT = i18n.t('Ошибка получения справочника Должностей!');
  try {
    const response = yield call(client().get, 'dictionaries/gs1/positions');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryPositionFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryPositionSuccessed(isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadDictionaryPositionFailed());
  }
}

export function* loadDictionaryCountry() {
  const errT = i18n.t('Ошибка получения справочника Стран!');
  try {
    const response = yield call(client().get, 'dictionaries/gs1/countries');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryCountryFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      const country = isArray(response) ? response : [];
      yield put(loadDictionaryCountrySuccessed(country));
    }
  } catch (e) {
    yield put(loadDictionaryCountryFailed());
  }
}

export function* loadDictionarySubject() {
  const errT = i18n.t('Ошибка получения справочника Субъектов РФ!');
  try {
    const response = yield call(client().get, '/gs1/fias-regions');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionarySubjectFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionarySubjectSuccessed(isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadDictionarySubjectFailed());
  }
}

export function* loadDictionaryDepartment() {
  const errT = i18n.t('Ошибка получения справочника Отделов!');
  try {
    const response = yield call(client().get, '/dictionaries/gs1/contacts');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryDepartmentFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryDepartmentSuccessed(isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadDictionaryDepartmentFailed());
  }
}

export function* loadDictionaryKnowledge() {
  const errT = i18n.t('Ошибка получения справочника «Откуда вы узнали»!');
  try {
    const response = yield call(client().get, '/dictionaries/gs1/about');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryKnowledgeFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryKnowledgeSuccessed(isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadDictionaryKnowledgeFailed());
  }
}

export function* loadDictionaryProjects() {
  const errT = i18n.t(
    'Ошибка получения справочника Участие в государственных проектах!',
  );
  try {
    const response = yield call(
      client().get,
      '/dictionaries/gs1/participation',
    );
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryProjectsFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryProjectsSuccessed(isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadDictionaryProjectsFailed());
  }
}

export function* loadDictionarySales() {
  const errT = i18n.t('Ошибка получения справочника Способ реализации товара!');
  try {
    const response = yield call(client().get, '/dictionaries/gs1/selling-ways');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionarySalesFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionarySalesSuccessed(isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadDictionarySalesFailed());
  }
}

export function* loadDictionaryChannels() {
  const errT = i18n.t(
    'Ошибка получения справочника Основные каналы распространения продукции!',
  );
  try {
    const response = yield call(
      client().get,
      '/dictionaries/gs1/distribution-channels',
    );
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryChannelsFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryChannelsSuccessed(isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadDictionaryChannelsFailed());
  }
}

export function* loadDictionaryOrgProfiles() {
  const errT = i18n.t(
    'Ошибка получения справочника Профили деятельности организации!',
  );
  try {
    const response = yield call(
      client().get,
      '/dictionaries/gs1/profiles-organization',
    );
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryOrgProfilesFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryOrgProfilesSuccessed(isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadDictionaryOrgProfilesFailed());
  }
}

export function* loadDictionarySectors() {
  const errT = i18n.t('Ошибка получения справочника Сектор предприятия!');
  try {
    const response = yield call(
      client().get,
      '/dictionaries/gs1/enterprise-sector',
    );
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionarySectorsFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionarySectorsSuccessed(isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadDictionarySectorsFailed());
  }
}

export function* loadDictionaryGcpProducts() {
  const errT = i18n.t(
    'Ошибка получения справочника Основная продукция по GPC!',
  );
  try {
    const response = yield call(
      client().get,
      '/dictionaries/gs1/main-products',
    );
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryGcpProductsFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryGcpProductsSuccessed(
          reject(response, { text: '-----------' }),
        ),
      );
    }
  } catch (e) {
    yield put(loadDictionaryGcpProductsFailed());
  }
}

export function* loadDictionaryObtainingMethods() {
  const errT = i18n.t(
    'Ошибка получения справочника Способ получения документов!',
  );
  try {
    const response = yield call(client().get, '/dictionaries/gs1/documents');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryObtainingMethodsFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryObtainingMethodsSuccessed(
          isArray(response) ? response : [],
        ),
      );
    }
  } catch (e) {
    yield put(loadDictionaryObtainingMethodsFailed());
  }
}

export function* loadDictionaryEdoProviders() {
  const errT = i18n.t('Ошибка получения справочника Провайдер ЭДО!');
  try {
    const response = yield call(client().get, '/dictionaries/gs1/provider');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryEdoProvidersFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryEdoProvidersSuccessed(isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadDictionaryEdoProvidersFailed());
  }
}

export function* loadDictionaryOkpd() {
  const errT = i18n.t('Ошибка получения справочника ОКПД2!');
  try {
    const response = yield call(client().get, '/dictionaries/okpd2');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryOkpdFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(loadDictionaryOkpdSuccessed(isArray(response) ? response : []));
    }
  } catch (e) {
    yield put(loadDictionaryOkpdFailed());
  }
}

export function* loadDictionaryShowboxDisplaySign() {
  const errT = i18n.t('Ошибка получения справочника Признак выкладки шоубокс!');
  try {
    const response = yield call(client().get, '/attr-preset/has-show-box');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryShowboxDisplaySignFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryShowboxDisplaySignSuccessed(
          isArray(response) ? response : [],
        ),
      );
    }
  } catch (e) {
    yield put(loadDictionaryShowboxDisplaySignFailed());
  }
}

export function* loadDictionaryPackageType() {
  const errT = i18n.t('Ошибка получения справочника Тип упаковки!');
  try {
    const response = yield call(client().get, '/attr-preset/package-type');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryPackageTypeFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryPackageTypeSuccessed(isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadDictionaryPackageTypeFailed());
  }
}

export function* loadDictionaryPackageMaterial() {
  const errT = i18n.t('Ошибка получения справочника Материал упаковки!');
  try {
    const response = yield call(client().get, '/attr-preset/package-material');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryPackageMaterialFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryPackageMaterialSuccessed(
          isArray(response) ? response : [],
        ),
      );
    }
  } catch (e) {
    yield put(loadDictionaryPackageMaterialFailed());
  }
}

export function* loadDictionaryPackageLetterNumeralCode() {
  const errT = i18n.t(
    'Ошибка получения справочника Буквенных и Цифровых кодов!',
  );
  try {
    const response = yield call(client().get, 'materials/list');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryPackageLetterNumeralCodeFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryPackageLetterNumeralCodeSuccessed(
          isArray(response) ? response : [],
        ),
      );
    }
  } catch (e) {
    yield put(loadDictionaryPackageLetterNumeralCodeFailed());
  }
}

export function* loadDictionaryPackageTypesCapping() {
  const errT = i18n.t(
    'Ошибка получения справочника Тип укупорочного средства!',
  );
  try {
    const response = yield call(
      client().get,
      '/attr-preset/types-closure-means',
    );
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryPackageTypesCappingFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryPackageTypesCappingSuccessed(
          isArray(response) ? response : [],
        ),
      );
    }
  } catch (e) {
    yield put(loadDictionaryPackageTypesCappingFailed());
  }
}

export function* loadDictionaryPackageMaterialsCapping() {
  const errT = i18n.t(
    'Ошибка получения справочника Материал укупорочного средства!',
  );
  try {
    const response = yield call(
      client().get,
      '/attr-preset/materials-closure-means',
    );
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryPackageMaterialsCappingFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryPackageMaterialsCappingSuccessed(
          isArray(response) ? response : [],
        ),
      );
    }
  } catch (e) {
    yield put(loadDictionaryPackageMaterialsCappingFailed());
  }
}

export function* loadDictionaryPalletType() {
  const errT = i18n.t('Ошибка получения справочника Типы паллет!');
  try {
    const response = yield call(client().get, '/attr-preset/pallet-type');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryPalletTypeFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryPalletTypeSuccessed(isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadDictionaryPalletTypeFailed());
  }
}

export function* loadDictionaryOkved(action) {
  const errT = i18n.t('Ошибка получения данных ОКВЭД!');
  try {
    const url = !!action.searchText
      ? insertParam('/dictionaries/okved2', 'q', action.searchText)
      : '/dictionaries/okved2';

    const response = yield call(client().get, url);

    if (response.error) {
      action.cbk({ error: errT }, []);
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryOkvedFailed());
    } else {
      if (!isArray(response)) {
        action.cbk({ error: errT }, []);
        yield put(throwNotification(errT, 'error'));
        yield put(loadDictionaryOkvedFailed());
      } else {
        action.cbk(null, isArray(response) ? response : []);
        yield put(
          loadDictionaryOkvedSuccessed(isArray(response) ? response : []),
        );
      }
    }
  } catch (e) {
    action.cbk({ error: errT }, []);
    yield put(loadDictionaryOkvedFailed());
  }
}

export function* loadNotification(action) {
  try {
    const response =
      !!action.dateBegin && !!action.dateEnd
        ? yield call(
          client().get,
          `/notifications?dateBegin=${action.dateBegin}&dateEnd=${action.dateEnd}&pageNumber=${action.pageNumber}&pageSize=${action.pageSize}`,
        )
        : yield call(client().get, `/notifications?pageNumber=${action.pageNumber}&pageSize=${action.pageSize}`);

    if (response.error) {
      yield action.cbk(response.error);
      yield handleErrorNotification(response.error);
      yield put(loadNotificationFailed());
    } else {
      const resData = response.data;
      const resCount = response.count;
      if (!isArray(resData)) {
        yield put(
          throwNotification(i18n.t('Ошибка получения уведомлений!'), 'error'),
        );
        yield put(loadNotificationFailed());
        return;
      }
      yield action.cbk(null, isEmpty(resData), resCount);
      yield put(
        loadNotificationSuccessed(
          isArray(resData) ? resData : [],
          defaultTo(action.firstLoad, true),
          resCount,
        ),
      );
    }
  } catch (e) {
    yield action.cbk({
      error: i18n.t('Неизвестная ошибка получения уведомлений'),
    });
    yield put(loadNotificationFailed());
  }
}

export function* loadNotificationRecent(action) {
  try {
    const response = yield call(client().get, '/notifications/recent');

    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadNotificationRecentFailed());
    } else {
      yield put(
        loadNotificationRecentSuccessed(response),
      );
    }
  } catch (e) {
    yield handleErrorNotification(e, `${i18n.t('Неизвестная ошибка получения уведомлений')}`);
    yield put(loadNotificationRecentFailed());
  }
}

export function* markAsReadNotification(action) {
  const errT = i18n.t('Ошибка при отметке о прочтении уведомлений!');
  try {
    let response;
    response = !!action.ids
      ? yield call(client().post, `/notifications/read`, {
        ids: action.ids,
      })
      : yield call(client().post, `/notifications/read-all`);
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
    } else {
      if (!!action.cbk) {
        action.cbk(action.ids);
      }
      if (!!action.ids) {
        yield put(markAsReadNotificationSuccessed(action.ids));
      } else yield put(markAsReadNotificationSuccessed());
    }
  } catch (e) {
    yield handleErrorNotification(e, `${errT}`);
  }
}

export function* loadProductsCategories(action) {
  try {
    const response = yield call(
      client().get,
      `/dictionaries/okp?catId=${action.categoryId}`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadProductsCategoriesFailed());
    } else {
      if (!isArray(response)) {
        yield put(
          throwNotification(
            i18n.t('Ошибка получения справочника okp!'),
            'error',
          ),
        );
      }
      yield put(
        loadProductsCategoriesSuccessed(isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadProductsCategoriesFailed());
  }
}

export function* loadGtinsByPackageDictionary(action) {
  try {
    const response = yield call(
      client().get,
      `/gtinbypackage/list?gtinType=${action.data}`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadGtinsByPackageDictionaryFailed());
    } else {
      if (!isArray(response)) {
        yield put(
          throwNotification(i18n.t(`Ошибка получения GTIN'нов`), 'error'),
        );
      }
      yield put(
        loadGtinsByPackageDictionarySuccessed(
          isArray(response) ? response : [],
        ),
      );
    }
  } catch (error) {
    yield put(loadGtinsByPackageDictionaryFailed());
  }
}

export function* loadActiveKzDictionary(action) {
  try {
    const response = yield call(client().get, `/categories/active_kz?type=${action.modalType}`);
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadActiveKzDictionaryFailed());
    } else {
      if (!isArray(response)) {
        yield put(
          throwNotification(
            i18n.t(
              `Ошибка получения спарочника Кодов государственного классификатора `,
            ),
            'error',
          ),
        );
      }
      yield put(
        loadActiveKzDictionarySuccessed(isArray(response) ? response : []),
      );
    }
  } catch (error) {
    yield put(loadActiveKzDictionaryFailed());
  }
}

export function* loadGtinsListForValidation() {
  try {
    const response = yield call(
      client().get,
      `/gtinbypackage/list?gtinType=all`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadGtinsListForValidationFailed());
    } else {
      if (!isArray(response)) {
        yield put(
          throwNotification(i18n.t(`Ошибка получения GTIN'нов`), 'error'),
        );
      }
      yield put(
        loadGtinsListForValidationSucceeded(isArray(response) ? response : []),
      );
    }
  } catch (error) {
    yield put(loadGtinsListForValidationFailed());
  }
}

export function* loadKatoDictionary(action) {
  const errT = i18n.t('Ошибка получения справочника КАТО!');

  try {
    const response = yield call(
      client().get,
      `/dictionaries/gs1/district?q=${action.data}`,
    );
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
        yield put(loadKatoDictionaryFailed());
      } else {
        yield put(
          loadKatoDictionarySucceeded(isArray(response) ? response : []),
        );
      }
    }
  } catch (e) {
    yield put(loadDictionaryOpfFailed());
  }
}

export function* loadDictionaryCountries(action) {
  const errT = i18n.t('Ошибка получения справочника стран!');
  try {
    const response = yield call(client().get, '/dictionaries/isocountries/full');
    if (response.error) {
      action.cbk({ error: errT });
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
    } else {
      if (!isArray(response)) {
        action.cbk({ error: errT });
        yield put(throwNotification(errT, 'error'));
        yield put(loadDictionaryCountriesFailed());
      } else {
        action.cbk(null);
        yield put(
          loadDictionaryCountriesSuccessed(isArray(response) ? response : []),
        );
      }
    }
  } catch (e) {
    action.cbk({ error: errT });
    yield put(loadDictionaryCountriesFailed());
  }
}

export function* watchLoadDictionaryPackageLetterNumeralCode() {
  yield takeEvery(
    LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_ACTION,
    loadDictionaryPackageLetterNumeralCode,
  );
}

export function* watchLoadNotification() {
  yield takeEvery(LOAD_NOTIFICATION_ACTION, loadNotification);
}

export function* watchLoadNotificationRecent() {
  yield takeEvery(LOAD_NOTIFICATION_RECENT_ACTION, loadNotificationRecent);
}

export function* watchLoadDictionaryShowboxDisplaySign() {
  yield takeEvery(
    LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_ACTION,
    loadDictionaryShowboxDisplaySign,
  );
}

export function* watchLoadDictionaryPackageType() {
  yield takeEvery(
    LOAD_DICTIONARY_PACKAGE_TYPE_ACTION,
    loadDictionaryPackageType,
  );
}

export function* watchLoadDictionaryPalletType() {
  yield takeEvery(LOAD_DICTIONARY_PALLET_TYPE_ACTION, loadDictionaryPalletType);
}

export function* watchLoadDictionaryPackageMaterial() {
  yield takeEvery(
    LOAD_DICTIONARY_PACKAGE_MATERIAL_ACTION,
    loadDictionaryPackageMaterial,
  );
}

export function* watchLoadDictionaryPackageTypesCapping() {
  yield takeEvery(
    LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_ACTION,
    loadDictionaryPackageTypesCapping,
  );
}

export function* watchLoadDictionaryPackageMaterialsCapping() {
  yield takeEvery(
    LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_ACTION,
    loadDictionaryPackageMaterialsCapping,
  );
}

export function* watchLoadDictionaryOpf() {
  yield takeEvery(LOAD_DICTIONARY_OPF_ACTION, loadDictionaryOpf);
}

export function* watchLoadDictionaryCountry() {
  yield takeEvery(LOAD_DICTIONARY_COUNTRY_ACTION, loadDictionaryCountry);
}

export function* watchLoadDictionaryPosition() {
  yield takeEvery(LOAD_DICTIONARY_POSITION_ACTION, loadDictionaryPosition);
}

export function* watchLoadTnveds() {
  yield takeEvery(LOAD_TNVED_ACTION, loadTnveds);
}

export function* watchLoadDictionarySubject() {
  yield takeEvery(LOAD_DICTIONARY_SUBJECT_ACTION, loadDictionarySubject);
}

export function* watchLoadDictionaryDepartment() {
  yield takeEvery(LOAD_DICTIONARY_DEPARTMENT_ACTION, loadDictionaryDepartment);
}

export function* watchLoadDictionaryKnowledge() {
  yield takeEvery(
    LOAD_DICTIONARY_KNOWLEDGE_GS1_ACTION,
    loadDictionaryKnowledge,
  );
}

export function* watchLoadDictionaryProjects() {
  yield takeEvery(LOAD_DICTIONARY_PROJECTS_GS1_ACTION, loadDictionaryProjects);
}

export function* watchLoadDictionarySales() {
  yield takeEvery(LOAD_DICTIONARY_SALES_GS1_ACTION, loadDictionarySales);
}

export function* watchLoadDictionaryChannels() {
  yield takeEvery(LOAD_DICTIONARY_CHANNELS_GS1_ACTION, loadDictionaryChannels);
}

export function* watchLoadDictionaryOrgProfiles() {
  yield takeEvery(
    LOAD_DICTIONARY_ORG_PROFILES_ACTION,
    loadDictionaryOrgProfiles,
  );
}

export function* watchLoadDictionarySectors() {
  yield takeEvery(LOAD_DICTIONARY_SECTOR_ACTION, loadDictionarySectors);
}

export function* watchLoadDictionaryGcpProducts() {
  yield takeEvery(
    LOAD_DICTIONARY_GCP_PRODUCTS_ACTION,
    loadDictionaryGcpProducts,
  );
}

export function* watchLoadDictionaryObtainingMethods() {
  yield takeEvery(
    LOAD_DICTIONARY_OBTAINING_METHOD_ACTION,
    loadDictionaryObtainingMethods,
  );
}

export function* watchLoadDictionaryEdoProviders() {
  yield takeEvery(
    LOAD_DICTIONARY_EDO_PROVIDER_ACTION,
    loadDictionaryEdoProviders,
  );
}

export function* watchLoadDictionaryOkpd() {
  yield takeEvery(LOAD_DICTIONARY_OKPD_ACTION, loadDictionaryOkpd);
}

export function* watchLoadDictionaryOkved() {
  yield takeEvery(LOAD_DICTIONARY_OKVED_ACTION, loadDictionaryOkved);
}

export function* watchLoadProductsCategories() {
  yield takeEvery(LOAD_PRODUCTS_CATEGORIES_ACTION, loadProductsCategories);
}

export function* watchMarkAsReadNotification() {
  yield takeEvery(MARK_NOTIFICATION_ACTION, markAsReadNotification);
}

export function* watchLoadTm() {
  yield takeEvery(LOAD_DICTIONARY_TM_ACTION, loadDictionaryTm);
}

export function* watchLoadGtinsByPackageDictionary() {
  yield takeEvery(
    LOAD_GTINS_LIST_BY_PACKAGE_ACTION,
    loadGtinsByPackageDictionary,
  );
}

export function* watchLoadActiveKzDictionary() {
  yield takeEvery(LOAD_ACTIVE_KZ_DICTIONARY_ACTION, loadActiveKzDictionary);
}

export function* watchLoadGtinsValidationList() {
  yield takeEvery(
    LOAD_GTINS_LIST_FOR_VALIDATION_ACTION,
    loadGtinsListForValidation,
  );
}

export function* watchLoadKatoDictionary() {
  yield takeEvery(LOAD_KATO_DICTIONARY_ACTION, loadKatoDictionary);
}

export function* watchLoadDictionaryCountries() {
  yield takeEvery(LOAD_DICTIONARY_COUNTRIES_ACTION, loadDictionaryCountries);
}

export default function* catalogSaga() {
  yield all([
    watchLoadTnveds(),
    watchLoadDictionaryOpf(),
    watchLoadDictionaryPosition(),
    watchLoadDictionaryCountry(),
    watchLoadDictionarySubject(),
    watchLoadDictionaryDepartment(),
    watchLoadDictionaryChannels(),
    watchLoadDictionaryProjects(),
    watchLoadDictionarySales(),
    watchLoadDictionaryKnowledge(),
    watchLoadDictionaryOrgProfiles(),
    watchLoadDictionarySectors(),
    watchLoadDictionaryGcpProducts(),
    watchLoadDictionaryObtainingMethods(),
    watchLoadDictionaryEdoProviders(),
    watchLoadDictionaryOkpd(),
    watchLoadDictionaryPackageMaterial(),
    watchLoadDictionaryPackageLetterNumeralCode(),
    watchLoadDictionaryPackageTypesCapping(),
    watchLoadDictionaryPackageMaterialsCapping(),
    watchLoadDictionaryShowboxDisplaySign(),
    watchLoadDictionaryPackageType(),
    watchLoadDictionaryPalletType(),
    watchLoadDictionaryOkved(),
    watchLoadNotification(),
    watchLoadProductsCategories(),
    watchMarkAsReadNotification(),
    watchLoadTm(),
    watchLoadGtinsByPackageDictionary(),
    watchLoadActiveKzDictionary(),
    watchLoadGtinsValidationList(),
    watchLoadKatoDictionary(),
    watchLoadDictionaryCountries(),
    watchLoadNotificationRecent(),
  ]);
}
