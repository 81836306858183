import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEqual, isNull } from 'lodash';
import {
  Button,
  MuiThemeProvider,
  Popover,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from '@material-ui/core';
import { SimpleTooltip } from '../../../../../components';
import {
  IconClose,
  IconErrorSmall,
  IconNewFilterSmall,
  IconNewFilterSmallActive,
} from '../../../../../common/icons/customIcons';
import {
  defaultFilterInput,
  DefaultFilterPopover,
  FilterBtn,
  FilterBtnReset,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
} from './Filters.styled';

class SystemMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      // isMember: this.props.currentValue ? this.props.currentValue.join('\n') : '',
      isMember: this.props.currentValue || null,
      error: '',
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        isMember: '',
        error: '',
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state);
  }

  onValueChange = (e) => {
    this.setState({
      isMember: e.target.value === 'true' ? true : false,
      error: '',
    });
  };

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAccept = () => {
    const isMembers = this.state.isMember;
    this.setState({
      anchorEl: null,
      currentValue: isMembers,
    });
    this.props.onAccept('isMemberELK', isMembers);
  };

  validateisMembers = (isMembersArr) => {
    // const { t } = this.props;
    let valid = true;
    // let error = '';

    // if (!isEmpty(isMembersArr)) {
    //     forEach(isMembersArr, (isMember) => {
    //         if (!packageisMemberValidLength(isMember)) {
    //             error = `${t('Введите корректные коды товаров')}. \n ${t('Код товара должен содержать 13 или 14 цифр')}`;
    //             valid = false;
    //         }
    //     });
    //     this.setState({ isMember: join(isMembersArr, '\n'), error });
    // }
    return valid;
  };

  handleDissmiss = () => {
    this.setState({ anchorEl: null, isMember: '', error: '' });
    this.props.onDismiss('isMemberELK');
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <MuiThemeProvider theme={FilterBtn}>
          <FilterButton>
            <Button
              aria-owns={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
              {isNull(this.props.currentValue) ? (
                <IconNewFilterSmall />
              ) : (
                <IconNewFilterSmallActive />
              )}
            </Button>
          </FilterButton>
        </MuiThemeProvider>
        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Участник системы')}</h6>

              <MuiThemeProvider theme={FilterBtn}>
                <IconButton className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </IconButton>
              </MuiThemeProvider>
            </FilterTitle>
              
                <RadioGroup
                  aria-label="gender"
                  name="isMember-filter"
                  value={this.state.isMember}
                  onChange={this.onValueChange}>
                  <FilterContent>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={t('Да')}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label={t('Нет')}
                    />
                  </FilterContent>
                </RadioGroup>
              
              {!!this.state.error &&
                SimpleTooltip(this.state.error, <IconErrorSmall />)}

            <FilterBtns>
              <MuiThemeProvider theme={FilterBtnReset}>
                <Button onClick={this.handleDissmiss}>
                  {t('Сбросить')}
                </Button>
              </MuiThemeProvider>
              <Button onClick={this.handleAccept}>{t('Применить')}</Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </div>
    );
  }
}

SystemMember.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  currentValue: PropTypes.bool,
  parentId: PropTypes.string.isRequired,
};

export default withTranslation()(SystemMember);
