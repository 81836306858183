import * as React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Fab, MuiThemeProvider } from '@material-ui/core';
import { DefaultFloatBtn } from './FixingButton.styled';

export class FixingButtonView extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={DefaultFloatBtn}>
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

FixingButtonView.propTypes = {
  children: PropTypes.element.isRequired,
};

class FixingButton extends React.Component {
  shouldComponentUpdate(nextProps) {
    const equal = [];
    const nameProps = ['disabled', 'locale'];
    _.forEach(nameProps, (i) =>
      equal.push(!_.isEqual(this.props[i], nextProps[i])),
    );
    return _.some(equal);
  }

  render() {
    return (
      <Fab
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        color={this.props.color}>
        {this.props.icon}
        {this.props.text}
      </Fab>
    );
  }
}

FixingButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.any,
};

FixingButton.default = {
  disabled: false,
};

const mapStateToProps = (state) => ({
  locale: state.profileReducer.locale,
});

export default compose(connect(mapStateToProps))(FixingButton);
