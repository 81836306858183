import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import {
  FilterTitle,
} from './Filters.styled';
class IsExemplarFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isExemplar: !!this.props.currentValue,
      isDirty: !_.isNull(this.props.currentValue),
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (_.isNull(nextProps.currentValue) && !_.isNull(state.currentValue)) {
      return {
        anchorEl: null,
        isExemplar: false,
        isDirty: false,
        currentValue: nextProps.currentValue,
      };
    }
    if (
      !_.isNull(nextProps.currentValue) &&
      nextProps.currentValue !== state.currentValue
    ) {
      return {
        currentValue: nextProps.currentValue,
        isExemplar: !!nextProps.currentValue,
        isDirty: !_.isNull(nextProps.currentValue),
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextState, this.state);
  }

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAccept = () => {
    this.setState({
      anchorEl: null,
      currentValue: this.state.isExemplar,
    });
    this.props.onAccept('isExemplar', this.state.isExemplar);
  };

  handleDissmiss = () => {
    this.setState({
      isDirty: false,
      anchorEl: null,
    });
    this.props.onDismiss('isExemplar');
  };

  handleChange = (value) => {
    this.setState({
      isDirty: true,
      isExemplar: value,
    });

    if (this.props.type === 'modal') {
      this.props.onValueChange('isExemplar', value);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <FilterTitle>
          <h6>{t('Товары в совместном управлении')}</h6>
        </FilterTitle>
        <div style={{ padding: '16px 0' }}>
          <FormControlLabel
            control={
              <Radio
                checked={this.state.isExemplar && this.state.isDirty}
                onChange={() => this.handleChange(true)}
              />
            }
            label={t('Да')}
          />
          <FormControlLabel
            control={
              <Radio
                checked={!this.state.isExemplar && this.state.isDirty}
                onChange={() => this.handleChange(false)}
              />
            }
            label={t('Нет')}
          />
        </div>
      </div>
    );
  }
}

IsExemplarFilter.propTypes = {
  onAccept: PropTypes.func,
  onDismiss: PropTypes.func,
  currentValue: PropTypes.bool,
  parentId: PropTypes.string,
  onValueChange: PropTypes.func,
  type: PropTypes.string,
};

export default withTranslation()(IsExemplarFilter);
