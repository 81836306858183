import styled from 'styled-components';
import colors from '../../../common/styles/Common.colors';

export const ChartContainerHolder = styled.div`
  position: relative;
  width: 47%;
`;
export const ChartContainerInner = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;
export const ChartTooltip = styled.div`
  color: ${colors.Common.textColor};
  font-size: 18px;
  line-height: 24px;
`;
export const ChartStatusLine = styled.div`
  font-size: 16px;
  color: #63666a;
  font-weight: 500;
  margin-right: 28px;
  min-height: 25px;
  display: flex;
  align-items: center;
  &.active {
    font-weight: 600;
    .status-color {
      width: 20px;
      height: 20px;
      position: relative;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: 50%;
      }
    }
  }
`;
export const ChartStatusColorContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ChartStatusColor = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  display: inline-block;
  transition: 0.4s ease;
`;
export const ChartStatusTitle = styled.span`
    color: #000000
    margin: 0 5px 0 10px
    display: inline-block;
`;
export const ChartStatusesIconHolder = styled.div`
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  padding-top: 7px;
`;