import * as PropTypes from 'prop-types';
import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Typography,
  IconButton,
} from '@material-ui/core';
import { IconClose } from '../../common/icons/customIcons';
import { DefaultConfirmDialog } from './ConfirmDialog.styled';

class ConfirmDialog extends React.Component {
  render() {
    const {
      title,
      message,
      acceptBtnText,
      dismissBtnText,
      onAccept,
      onDismiss,
      onCancel,
    } = this.props;

    return (
      <MuiThemeProvider theme={DefaultConfirmDialog}>
        <Dialog
          open
          onClose={onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" disableTypography>
            <Typography variant="h2">
              {title}
            </Typography>
            <IconButton
              id="dismiss-btn-close"
              onClick={onCancel}>
              <IconClose />
            </IconButton>
          </DialogTitle>
          <DialogContent>{message}</DialogContent>
          <DialogActions>
            {!!onDismiss && !!dismissBtnText && (
              <Button
                color={'secondary'}
                id="dismiss-btn"
                onClick={onDismiss}>
                {dismissBtnText}
              </Button>
            )}
            {!!onAccept && !!acceptBtnText && (
              <Button
                color={'primary'}
                id="accept-btn"
                onClick={onAccept}
                autoFocus>
                {acceptBtnText}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

ConfirmDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  onDismiss: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  acceptBtnText: PropTypes.string,
  dismissBtnText: PropTypes.string,
};

export default ConfirmDialog;
