import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import { withTranslation } from 'react-i18next';
import { bindActionCreators, compose } from 'redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import _ from 'lodash';
import Root from '../../common/root/Root';
import { isKZ } from '../../config';
import { loadCerts, regRequest } from './ducks/Registration.actions';
import * as selectors from './ducks/Registration.selectors';
import { accountTypes } from '../../common/constants/Constants';
import { HeaderLanguageSelection, ModalLoader } from '../../components';
import RegistrationProcessView from './views/RegistrationProcess.view';
import {
  Content,
  Footer,
  Header,
  LoginFormCont,
  StyledH1,
} from '../../common/styles/Common.styled';
import { RegSuccess, RegSuccessH2 } from './Registration.styled';
import SvgLogo from '../../common/icons/logo';
import { LogoKz, LogoRusKz } from '../../common/icons/customIcons';

export class Registration extends Root {
  constructor(props) {
    super(props);
    if (!isKZ && !this.props.browserCheck) {
      this.props.goToPage('/browserCheck');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errors && !prevProps.errors.length && !!this.props.errors) {
      this.showErrorsAlerts(this.props.errors);
    }
  }

  render() {
    if (!isKZ && !this.props.browserCheck) return <div />;
    const { t, locale } = this.props;
    const logoKZ = locale === 'uz' ? <LogoKz /> : <LogoRusKz />;

    return (
      <div>
        <Header>
          <HeaderLanguageSelection />
          <span>{isKZ ? logoKZ : <SvgLogo />}</span>
          <StyledH1>{t('Заявка на регистрацию')}</StyledH1>
        </Header>
        <Content>
          <LoginFormCont>
            {this.props.regSuccess ? (
              <RegSuccess>
                <RegSuccessH2>{t('Спасибо')}</RegSuccessH2>
                <span>
                  {t('Ваша заявка отправлена на регистрацию')}.
                  <br />
                  {t(
                    'Результат рассмотрения заявки будет выслан на указанную при регистрации электронную почту в течении 24 часов',
                  )}
                </span>
                <br />
              </RegSuccess>
            ) : (
              <RegistrationProcessView
                loadCerts={this.props.loadCerts}
                certificates={this.props.certificates}
                accountTypes={accountTypes}
                onRegRequest={this.props.onRegRequest}
                regSuccess={this.props.regSuccess}
                validationErrors={this.props.validationErrors}
                loading={this.props.loading}
              />
            )}
          </LoginFormCont>
        </Content>

        {this.props.loading && <ModalLoader />}

        {this.props.regSuccess ? (
          <Footer>
            {t('Теперь вы можете')}{' '}
            <Link to="/login">{_.lowerCase(t('войти'))}</Link>{' '}
            {t('в свой личный кабинет')}
          </Footer>
        ) : (
          <Footer>
            {t('Уже зарегистрированы?')}{' '}
            <Link to="/login">{t('Авторизуйтесь')}</Link>.
          </Footer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  browserCheck: selectors.browserCheck(state),
  loading: selectors.loading(state),
  certificates: selectors.certificates(state),
  regSuccess: selectors.regSuccess(state),
  errors: selectors.errors(state),
  requestNumber: selectors.requestNumber(state),
  validationErrors: selectors.validationErrors(state),
  locale: selectors.locale(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      goToPage: (page) => push(page),
      loadCerts: () => loadCerts(),
      onRegRequest: (data) => regRequest(data),
    },
    dispatch,
  );
};

Registration.propTypes = {
  loadCerts: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  onRegRequest: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  browserCheck: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  regSuccess: PropTypes.bool,
  accountTypes: PropTypes.arrayOf(PropTypes.any),
  errors: PropTypes.arrayOf(PropTypes.object),
  validationErrors: PropTypes.object,
};

export default compose(
  withTranslation(),
  withAlert(),
  connect(mapStateToProps, mapDispatchToProps),
)(Registration);
