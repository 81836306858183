import styled from 'styled-components';
import colors from '../../common/styles/Common.colors';
import { createMuiTheme } from '@material-ui/core';

export const HowLink = styled.a`
  line-height: 24px;
  font-size: 16px;
  text-transform: none;
  color: ${colors.Buttons.textButton.color};
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.4s ease;
  font-weight: normal;
  :hover {
    color: ${colors.Buttons.textButton.colorHovered};   
  }
  :hover path{      
    stroke: ${colors.Buttons.textButton.colorHovered};
  }
  &:active {
    color: ${colors.Buttons.textButton.colorHovered};
  }
  &:active path{
    stroke: ${colors.Buttons.textButton.colorHovered};
  }  
`;

export const AuthContainer = styled.div`
  display: flex;
  min-height: 100vh;
`;
export const AuthBlockLeft = styled.div`
  width: 50%;
  background: ${colors.Home.background};  
`;
export const AuthBlockRight = styled.div`
  width: 50%;   
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  position: relative;
`;
export const AuthTitle = styled.h1`
  margin: 0 0 32px 16px;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
`;
export const AuthContent = styled.div`
  width: 428px; 
  margin: 96px 0 64px; 
  @media (min-width: 1920px) {
    width: 561px;
  }
`;
export const LogoBlock = styled.span`
  display: inline-block;
  margin: 40px 0 0 40px;
`;
export const AuthInputContainer = styled.div`
  padding: 16px 0;
`;
export const PasswordMessage = styled.p`
  margin: 0 0 28px;
  font-size: 16px;
  line-height: 24px;
`;

export const defaultAuthInput = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        width: '100%',
        borderRadius: '4px',
        border: '1px solid #C9C7C7',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
        '&$error': {
          border: '1px solid #E32A38',
        },
      },
      input: {
        color: `${colors.Common.textColor}`,
        padding: '23px 44px 7px 15px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 'normal',
        '&:required': {
          borderRadius: '4px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
      inputMultiline: {
        padding: '0!important',
      },
      multiline: {
        minHeight: '56px',
        padding: '23px 44px 7px 15px',
      },
      error: {},
    },
    MuiInputBase: {
      root: {
        width: '100%',
        marginTop: '0!important',
      },
      input: {
        boxSizing: 'border-box',
        height: 'auto',
        fontFamily: 'SegoeUI',
      },
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
      },
      formControl: {
        transform: 'translate(16px, 16px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 8px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        '&$focused': {
          color: `${colors.Common.textColor}`,
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});

export const defaultAuthBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '202px',
        marginRight: '20px',
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '4px',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '16px 35px',
        fontWeight: '600',
        textTransform: 'capitalize',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
        lineHeight: '24px',
      },
      disabled: {},
    },
  },
});
