import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, FormControlLabel, Checkbox, MuiThemeProvider } from '@material-ui/core';
import { IconCheck, IconCheckbox } from '../../../../common/icons/customIcons';
import { Preloader } from '../../../../components';
import {
  deleteProfileStatus,
  loadGlnDictionary,
  loadProfileStatus,
  loadStatisticAndOptions,
  saveOptions,
} from '../../ducks/ProfileStatus/ProfileStatus.actions';
import { throwNotification } from '../../../../common/structure';
import * as selectors from '../../ducks/ProfileStatus/ProfileStatus.selectors';
import { StatisticBlock } from './index';
import {
  ContentWrapper,
  ColumnItem,
  RowItem,
  ValueItem,
  ColumnItemTitle,
  RowItemCheckbox,
  DocAndOptionsCont,
} from './styled/DocumentAndOptions.styled';
import { BlueBtn, ProfileFooter, ProfileFooterButton, ProfileFooterButtonLine, ProfileTabCont } from '../../Profile.styled';
import colors from '../../../../common/styles/Common.colors';

class DocumentsAndOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
    };
  }

  componentDidMount() {
    this.props.loadData();
  }

  checkIfProducer = (dataArray) => {
    let isProducer = false;

    _.forEach(dataArray, (item) => {
      if (item.type === 0) {
        isProducer = true;
        return false;
      }
    });

    return isProducer;
  };

  checkMembershipGS1 = (dataArray) => {
    let isMember = false;
    let data = dataArray.statistic

    _.forEach(data?.gcpPrefixes, (item) => {
      if (item.status === 0) {
        isMember = true;
        return false;
      }
    });

    return isMember;
  };

  render() {
    const { t, loading, statisticAndOptions, data } = this.props;

    const cheboxStatus =
      this.state.status || _.get(statisticAndOptions?.options, 'status', null);

    /**
     * смотрим в ответ от /usercompany.
     * говорят что type:0 - это "Производитель"
     */
    const isProducer = this.checkIfProducer(data);
    const statusText = statisticAndOptions?.options?.statusText;
    const statusTextWithoutDots = statusText && statusText.replace(/\./g, '')
    const cheboxIsEnabled =
      _.get(statisticAndOptions.options, 'enabled', false) && isProducer;

    const isMemberGS1 = this.checkMembershipGS1(statisticAndOptions);
    const statisticBlock = statisticAndOptions.statistic;
    const isNullStatisticBlock = statisticBlock?.gcpPrefixes[0].prefix === null && statisticBlock?.gtinCount === 0 && statisticBlock?.glnCount === 0

    return (
      <React.Fragment>
        {/* <ProfileTabCont style={{height: 'auto', padding: '0', boxShadow: 'none'}}> */}
        <DocAndOptionsCont>
          <ContentWrapper>
            <ColumnItem>
              <ColumnItemTitle>{t('Статистика')}</ColumnItemTitle>
              <RowItem style={{ padding: 0 }}>
                <div>{t('Количество зарегистрированных префиксов GCP')}:</div>
                <ValueItem>{statisticBlock?.gcpPrefixes[0].prefix !== null ? statisticBlock?.gcpPrefixes.length : 0 || 0}</ValueItem>
              </RowItem>
              <RowItem style={{ padding: 0 }}>
                <div>{t('Количество зарегистрированных GTIN')}:</div>
                <ValueItem>{statisticBlock?.gtinCount || 0}</ValueItem>
              </RowItem>
              <RowItem style={{ padding: 0 }}>
                <div>{t('Количество зарегистрированных номеров GLN')}:</div>
                <ValueItem>{statisticBlock?.glnCount || 0}</ValueItem>
              </RowItem>

              {!isNullStatisticBlock && _.map(statisticBlock?.gcpPrefixes, (item, index) => (
                <StatisticBlock {...statisticBlock} key={index + Math.random()} data={item} />
              ))}
            </ColumnItem>

            <ColumnItem>
              <ColumnItemTitle>{t('Опции')}</ColumnItemTitle>

              <RowItem style={{ paddingTop: 0 }} color={colors.Common.textColor}>
                <div>{t('Система для генерации новых GTIN')}</div>
              </RowItem>
              <RowItem>
                <RowItemCheckbox>
                  <div>{t('Национальный каталог')}</div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cheboxStatus === 'NK'}
                          disabled={!cheboxIsEnabled}
                          onChange={() => this.setState({ status: 'NK' })}
                          checkedIcon={<IconCheck />}
                          icon={<IconCheckbox />}
                          color="default"
                        />
                      }
                    />
                  </div>
                </RowItemCheckbox>
              </RowItem>
              <RowItem style={{ paddingTop: 0 }}>
                <RowItemCheckbox>
                  <div>{t('Система GS1')}</div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cheboxStatus === 'GSOne'}
                          disabled={!cheboxIsEnabled}
                          onChange={() => this.setState({ status: 'GSOne' })}
                          checkedIcon={<IconCheck />}
                          icon={<IconCheckbox />}
                          color="default"
                        />
                      }
                    />
                  </div>
                </RowItemCheckbox>
              </RowItem>
              {statusText && (
                <div style={{ marginTop: '10px', color: colors.Common.textColor }}>{t(`${statusTextWithoutDots}`)}</div>
              )}
            </ColumnItem>
            <Preloader isOpen={loading} text={t('Загрузка')} />
          </ContentWrapper>
        </DocAndOptionsCont>
        <ProfileFooter>
          <ProfileFooterButton>
            <MuiThemeProvider theme={BlueBtn}>
              <Button
                onClick={() => this.props.saveData(this.state)}
                disabled={!this.state.status || !isProducer || !isMemberGS1}>
                {t('Сохранить изменения')}
              </Button>
            </MuiThemeProvider>
          </ProfileFooterButton>
        </ProfileFooter>
        <ProfileFooterButtonLine />
      </React.Fragment>
    );
  }
}

DocumentsAndOptions.propTypes = {
  throwNotification: PropTypes.func.isRequired,
  onLoadProfileStatus: PropTypes.func.isRequired,
  onDeleteProfileStatus: PropTypes.func.isRequired,
  loadGlnDictionary: PropTypes.func.isRequired,
  glnDictionary: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  totalObjects: PropTypes.number.isRequired,
  isMainAccountUser: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: !!selectors.loading(state),
  totalObjects: selectors.totalObjects(state),
  data: selectors.data(state),
  glnDictionary: selectors.glnDictionary(state),
  isMainAccountUser: selectors.isMainAccountUser(state),
  statisticAndOptions: selectors.statisticAndOptions(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwNotification: (message, type) => throwNotification(message, type),
      onLoadProfileStatus: () => loadProfileStatus(),
      onDeleteProfileStatus: (id, cbk) => deleteProfileStatus(id, cbk),
      loadGlnDictionary: () => loadGlnDictionary(),
      loadData: () => loadStatisticAndOptions(),
      saveData: (data, cbk) => saveOptions(data, cbk),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentsAndOptions);
