import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import {
  defaultTo,
  find,
  includes,
  isEqual,
  isNull,
  map,
  reject,
  replace,
  trim,
} from 'lodash';
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Popover,
  Select,
  IconButton,
} from '@material-ui/core';
import {
  DefaultFilterPopover,
  defaultFilterSelect,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
  FilterBtnReset,
  FilterContentPopup,
} from './Filters.styled';
import {
  IconClose,
  IconDropDown,
  IconNewFilterSmall,
  IconNewFilterSmallActive,
} from '../../../common/icons/customIcons';
import { PackingTypes } from '../../../common/constants/Statuses';

class PackingTypeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      packingPurpose: defaultTo(
        find(PackingTypes, { value: this.props.currentValue }),
        '',
      ),
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        packingPurpose: '',
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state);
  }

  onValueChange = (event) => {
    this.setState({ packingPurpose: event.target.value });

    if (this.props.type === 'modal') {
      this.props.onValueChange('packingPurpose', event.target.value);
    }
  };

  handleClick = (event) => {
    const element = document.getElementById(this.props.parentId);
    this.setState({ anchorEl: !!element ? element : event.currentTarget });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      packingPurpose: defaultTo(
        find(PackingTypes, { value: this.props.currentValue }),
        '',
      ),
    });
  };

  handleAccept = () => {
    const value = this.state.packingPurpose.value;
    this.setState({ anchorEl: null, currentValue: value });
    this.props.onAccept('packingPurpose', value);
  };

  handleDissmiss = () => {
    this.setState({ anchorEl: null, packingPurpose: '' });
    this.props.onDismiss('packingPurpose');
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const UserPackingTypes = this.props.isTechCardSupport
      ? this.props.isNotMarkedSupport
        ? PackingTypes
        : reject(PackingTypes, ['value', 'unmarkable'])
      : PackingTypes.filter(el => el.value !== 'unmarkable' && el.value !== 'techCard');

    return (
      <>
        {this.props.type !== 'modal' && (
          <div>
            <MuiThemeProvider theme={FilterBtn}>
              <FilterButton>
                <Button
                  aria-owns={open ? 'simple-popper' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}>
                  {isNull(this.props.currentValue) ? (
                    <IconNewFilterSmall />
                  ) : (
                    <IconNewFilterSmallActive />
                  )}
                </Button>
              </FilterButton>
            </MuiThemeProvider>

            <MuiThemeProvider theme={DefaultFilterPopover}>
              <Popover
                id="simple-popper"
                open={open}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                <FilterTitle>
                  <h6>{t('Назначение упаковки')}</h6>
                  <MuiThemeProvider theme={FilterBtn}>
                    <IconButton className="backEmpty" onClick={this.handleClose}>
                      <IconClose />
                    </IconButton>
                  </MuiThemeProvider>
                </FilterTitle>

                <FilterContent>
                  <MuiThemeProvider theme={defaultFilterSelect}>
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor="select-multiple-chip">
                        {t('Выберите упаковку')}
                      </InputLabel>
                      <Select
                        value={this.state.packingPurpose}
                        input={<Input id="select-multiple-chip" />}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        }}
                        IconComponent={IconDropDown}
                        onChange={this.onValueChange}>
                        {map(
                          reject(UserPackingTypes, (o) =>
                            includes(['published_notsigned'], o.value),
                          ),
                          (packingPurpose) => (
                            <MenuItem
                              key={packingPurpose.id}
                              value={packingPurpose}>
                              {t(
                                trim(
                                  replace(packingPurpose.title, /\([^)]*\)/g, ''),
                                ),
                              )}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  </MuiThemeProvider>
                </FilterContent>
                <FilterBtns>
                  <MuiThemeProvider theme={FilterBtnReset}>
                    <Button
                      disabled={!this.state.packingPurpose}
                      onClick={this.handleDissmiss}>
                      {t('Сбросить')}
                    </Button>
                  </MuiThemeProvider>
                  <Button
                    disabled={!this.state.packingPurpose}
                    onClick={this.handleAccept}>
                    {t('Применить')}
                  </Button>
                </FilterBtns>
              </Popover>
            </MuiThemeProvider>
          </div>
        )}
        {this.props.type === 'modal' && (
          <div>
            <FilterTitle>
              <h6>{t('Назначение упаковки')}</h6>
            </FilterTitle>
            <FilterContentPopup>
              <MuiThemeProvider theme={defaultFilterSelect}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="select-multiple-modal">
                    {t('Выберите упаковку')}
                  </InputLabel>
                  <Select
                    value={this.state.packingPurpose}
                    input={<Input id="select-multiple-modal" />}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    }}
                    IconComponent={IconDropDown}
                    onChange={this.onValueChange}>
                    {map(
                      reject(UserPackingTypes, (o) =>
                        includes(['published_notsigned'], o.value),
                      ),
                      (packingPurpose) => (
                        <MenuItem
                          key={packingPurpose.id}
                          value={packingPurpose}>
                          {t(
                            trim(
                              replace(packingPurpose.title, /\([^)]*\)/g, ''),
                            ),
                          )}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              </MuiThemeProvider>
            </FilterContentPopup>
          </div>
        )}
      </>
    );
  }
}

PackingTypeFilter.propTypes = {
  onAccept: PropTypes.func,
  onDismiss: PropTypes.func,
  currentValue: PropTypes.string,
  parentId: PropTypes.string,
  onValueChange: PropTypes.func,
  type: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
    },
    dispatch,
  );
};

const mapStateToProps = (state) => ({
  isTechCardSupport: state.profileReducer.info.user.isTechCardSupport,
  isNotMarkedSupport: state.profileReducer.info.user.isNotMarkedSupport,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(PackingTypeFilter);
