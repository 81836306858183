import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import { ActiveSessionDialog } from './ActiveSessionModal.styled';

class ActiveSessionModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, activeSessionInfo} = this.props;
    const activeSessionInfoWithoutCode = activeSessionInfo.substring(0, 69)
    const activeSessionInfoCode = activeSessionInfo.substring(69)
    return (
      <MuiThemeProvider theme={ActiveSessionDialog}>
        <Dialog open>
          <DialogTitle>
            {t('Система обнаружила наличие уже активной сессии')}.{t('Для продолжения работы с системой все дополнительные сессии будут деактивированы')}
          </DialogTitle>
          <DialogContent>
            <p>{t(activeSessionInfoWithoutCode)}{activeSessionInfoCode}</p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.handleSubmit}
            >
              {t('Подтвердить')}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {},
    dispatch,
  );
};

ActiveSessionModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  activeSessionInfo: PropTypes.string.isRequired,
};

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps),
)(ActiveSessionModal);

