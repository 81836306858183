import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  defaultTo,
  find,
  includes,
  isEqual,
  isNull,
  map,
  reject,
  replace,
  trim,
} from 'lodash';
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Popover,
  Select,
  IconButton,
} from '@material-ui/core';
import {
  DefaultFilterPopover,
  defaultFilterSelect,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
  FilterBtnReset,
  FilterContentPopup,
} from './Filters.styled';
import {
  IconClose,
  IconNewFilterSmall,
  IconNewFilterSmallActive,
  IconDropDown,
} from '../../../../common/icons/customIcons';
import { Statuses } from '../../../../common/constants/Statuses';

class StatusFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      status: defaultTo(find(Statuses, { value: this.props.currentValue }), ''),
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        status: '',
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state);
  }

  onValueChange = (event) => {
    this.setState({ status: event.target.value });

    if (this.props.type === 'modal') {
      this.props.onValueChange('status', event.target.value);
    }
  };

  handleClick = (event) => {
    const element = document.getElementById(this.props.parentId);
    this.setState({ anchorEl: !!element ? element : event.currentTarget });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      status: defaultTo(find(Statuses, { value: this.props.currentValue }), ''),
    });
  };

  handleAccept = () => {
    const value = this.state.status.value;
    this.setState({ anchorEl: null, currentValue: value });
    this.props.onAccept('status', value);
  };

  handleDissmiss = () => {
    this.setState({ anchorEl: null, status: '' });
    this.props.onDismiss('status');
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <>
        {this.props.type !== 'modal' && (
          <div>
            <MuiThemeProvider theme={FilterBtn}>
              <FilterButton>
                <Button
                  aria-owns={open ? 'simple-popper' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}>
                  {isNull(this.props.currentValue) ? (
                    <IconNewFilterSmall />
                  ) : (
                    <IconNewFilterSmallActive />
                  )}
                </Button>
              </FilterButton>
            </MuiThemeProvider>

            <MuiThemeProvider theme={DefaultFilterPopover}>
              <Popover
                id="simple-popper"
                open={open}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                <FilterTitle>
                  <h6>{t('Статус')}</h6>
                  <MuiThemeProvider theme={FilterBtn}>
                    <IconButton className="backEmpty" onClick={this.handleClose}>
                      <IconClose />
                    </IconButton>
                  </MuiThemeProvider>
                </FilterTitle>

                <FilterContent>
                  <MuiThemeProvider theme={defaultFilterSelect}>
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor="status-multiple-chip">
                        {t('Выберите статус')}
                      </InputLabel>
                      <Select
                        value={this.state.status}
                        input={<Input id="status-multiple-chip" />}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        }}
                        IconComponent={IconDropDown}
                        onChange={this.onValueChange}>
                        {map(
                          reject(Statuses, (o) =>
                            includes(['published_notsigned', 'archive'], o.value),
                          ),
                          (status) => (
                            <MenuItem key={status.id} value={status}>
                              {t(trim(replace(status.title, /\([^)]*\)/g, '')))}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  </MuiThemeProvider>
                </FilterContent>
                <FilterBtns>
                  <MuiThemeProvider theme={FilterBtnReset}>
                    <Button
                      disabled={!this.state.status}
                      onClick={this.handleDissmiss}>
                      {t('Сбросить')}
                    </Button>
                  </MuiThemeProvider>
                  <Button disabled={!this.state.status} onClick={this.handleAccept}>
                    {t('Применить')}
                  </Button>
                </FilterBtns>
              </Popover>
            </MuiThemeProvider>
          </div>
        )}
        {this.props.type === 'modal' && (
          <div>
            <FilterTitle>
              <h6>{t('Статус')}</h6>
            </FilterTitle>
            <FilterContentPopup>
              <MuiThemeProvider theme={defaultFilterSelect}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="status-multiple-modal">
                    {t('Выберите статус')}
                  </InputLabel>
                  <Select
                    value={this.state.status}
                    input={<Input id="status-multiple-modal" />}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    }}
                    IconComponent={IconDropDown}
                    onChange={this.onValueChange}>
                    {map(
                      reject(Statuses, (o) =>
                        includes(['published_notsigned', 'archive'], o.value),
                      ),
                      (status) => (
                        <MenuItem key={status.id} value={status}>
                          {t(trim(replace(status.title, /\([^)]*\)/g, '')))}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              </MuiThemeProvider>
            </FilterContentPopup>
          </div>
        )}
      </>
    );
  }
}

StatusFilter.propTypes = {
  onAccept: PropTypes.func,
  onDismiss: PropTypes.func,
  currentValue: PropTypes.string,
  parentId: PropTypes.string,
  onValueChange: PropTypes.func,
  type: PropTypes.string,
};

export default withTranslation()(StatusFilter);
