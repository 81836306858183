import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, MuiThemeProvider } from '@material-ui/core';
import { GeneralElement } from '../elements';
import { getSuggestions } from '../../../ducks/Product.actions';
import { IconPlusNew, IconEdit } from '../../../../../common/icons/customIcons';
import { ProductButton } from '../../../../../common/styles/Common.styled';
import { ProductDivider, IconEditWrapper } from './ConsumerItem.styled';

const getDefaultTo = (obj, path, defaultValue = '') =>
  _.defaultTo(_.get(obj, path, defaultValue), defaultValue);

const createCopyAttribute = (obj, multiplicityGroupUiId, newBlockId) => {
  return {
    ...obj,
    _attruid: _.uniqueId('attruid_'),
    uId: null,
    value: undefined,
    valueId: undefined,
    valueType: undefined,
    valueTitle: undefined,
    disabled: false,
    multiplicityGroup: null,
    multiplicityGroupUiId,
    newBlockId
  };
};

class ConsumerItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: {},
      defaultValueAddress: '',
      defaultValueCountry: '',
      disabledForEditing: true,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!_.isEqual(this.state, nextState)];
    const nameProps = [
      'groupIndex',
      'items',
      'errors',
      'popupElement',
      'moderateErrors',
      'errorFields',
      'preview',
      'currentLocale',
      'editableAddressBlock',
    ];
    _.forEach(nameProps, (i) => {
      const isEq = !_.isEqual(this.props[i], nextProps[i]);
      if (i === 'errors' && isEq) {
        const funcIntersection = (props) => {
          const idsFieldErrors = _.keys(props.errors);
          const idsFields = _.uniq(_.map(props.items, (i) => _.toString(i.id)));
          return !_.isEmpty(_.intersection(idsFields, idsFieldErrors));
        };
        equal.push(
          !_.isEqual(funcIntersection(nextProps), funcIntersection(this.props)),
        );
      } else {
        equal.push(isEq);
      }
    });
    return _.some(equal);
  }

  setError = (key, value) => {
    this.props.setError(key, value);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.defaultValueAddress !== prevState.defaultValueAddress &&
      nextProps.defaultValueCountry !== prevState.defaultValueCountry
    ) {
      return {
        defaultValueAddress: nextProps.defaultValueAddress,
        defaultValueCountry: nextProps.defaultValueCountry,
      };
    }
    if (nextProps.defaultValueAddress !== prevState.defaultValueAddress) {
      return {
        defaultValueAddress: nextProps.defaultValueAddress,
      };
    }
    if (nextProps.defaultValueCountry !== prevState.defaultValueCountry) {
      return {
        defaultValueCountry: nextProps.defaultValueCountry,
      };
    }
    return null;
  }

  handleChange = (value, type, index) => {
    const items = _.cloneDeep(this.props.items);
    let item = items[index];

    switch (type) {
      case 'select': {
        const v = _.find(item.preset, { id: value });
        item.value = v ? v.id : null;
        item.valueTitle = v ? v.text : null;
        _.set(items, `[${index}]`, item);
        this.props.updateItem(items, this.props.groupIndex, index);
        break;
      }
      case 'select-type': {
        const v = _.find(item.valuesTypePreset, { id: value });
        item.valueType = v.id;
        _.set(items, `[${index}]`, item);
        this.props.updateItem(items, this.props.groupIndex, index);
        break;
      }
      case 'autocomplete': {
        item.value = _.defaultTo(
          value.id,
          _.isString(value) && _.isEmpty(value) ? null : value,
        );
        item.valueTitle = _.defaultTo(value.text, null);
        _.set(items, `[${index}]`, item);
        this.props.updateItem(items, this.props.groupIndex, index);
        break;
      }
      default: {
        _.set(items, `[${index}].value`, value === '' ? null : value);
        this.props.updateItem(items, this.props.groupIndex, index);
        break;
      }
    }
  };

  handleSuggestionsFetchRequested = (value, isManualInput, index) => {
    const inputValue = _.toLower(_.deburr(_.trim(value)));
    if (inputValue.length === 0) {
      this.handleChange('', 'autocomplete', index);
    }
    if (isManualInput && _.isEmpty(this.props.items[index].valueTitle)) {
      this.handleChange(value, 'autocomplete', index);
    }
    this.fetchData(inputValue, isManualInput, index);
  };

  fetchData = (value, isManualInput, index) => {
    let url;
    let preset;
    const field = this.props.items[index];
    if (!!field) {
      url = field.url;
      preset = field.preset;
    }

    if (!!url) {
      // запрос по предоставленному url
      if (value.length >= 3) {
        this.props.getSuggestions(
          url.replace('rest/', ''),
          value,
          (error, suggestions) => {
            if (!error) {
              this.setState((prevState) => ({
                suggestions: {
                  ...prevState.suggestions,
                  [`index_${index}`]: suggestions,
                },
              }));
            }
          },
        );
      }
    }
    if (isManualInput) {
      const suggestions =
        value.length === 0
          ? preset
          : _.filter(preset, (suggestion) =>
            suggestion.text.toLowerCase().includes(value),
          );

      this.setState((prevState) => ({
        suggestions: {
          ...prevState.suggestions,
          [`index_${index}`]: suggestions,
        },
      }));
    }
  };

  changeAccessForEditing = () => {
    return this.setState({
      disabledForEditing: !this.state.disabledForEditing,
    });
  };

  buildBlocks = () => {
    const {
      items,
      elements,
      errorFields,
      multiplicity,
      errors,
      moderateErrors,
      onValidate,
      onChange,
      t,
      editableAddressBlock,
      shouldBePadded,
      preview,
      allowEditRequiredAttr,
      // hasPublishedGood,
      resizeable,
      multiplicityGroup,
      multiplicityGroupId,
      multiplicityGroupUiId,
      multiplicityDelete,
      status,
    } = this.props;
    const { disabledForEditing } = this.state;
    const fields = [];
    let disabledGroupButton = false;

    /**
     * TODO:
     * вырезать после того как научатся блочить поля через аттрибуты.
     */
    const groupIdToDisable = [195, 169];
    _.forEach(items, (item, index) => {
      const attrUid = item._attruid + index;
      // let disabled =
      //   ((status !== 'draft' && (preview || item.disabled)) ||
      //     _.includes(groupIdToDisable, item.groupId)) &&
      //   disabledForEditing;

    /**
     * TODO:
     * пока оставить для аттрибутов участники оборота
     */

      let disabled =
        ((status !== 'draft' && (preview || item.disabled)) ||
          _.includes([169], item.groupId)) &&
        disabledForEditing;

      // if (hasPublishedGood && !item.required) {
      //   disabled = false;
      // }
      const moderateError = _.defaultTo(
        _.find(moderateErrors, { uniqueAttrId: item.uId }),
        {},
      );

      if (item.id === 6002 || item.id === 6003) {
        item.url = null;
        item.fieldType = 'text';
      }

      /**
       * TODO:
       * костыль для полной карточки транспортной\групповой упаковки.
       * для вложений так же надо дозаполнять нулями выводимый gtin до 14 знаков.
       */
      if (shouldBePadded && item.id === 15800) {
        item.value = item.value.padStart(14, '0');
      }
      const atrrId = `${item.id}_${item._attruid}_${item.sortOrder}`;

      fields.push(
        // <div style={{ display: 'flex', marginBottom: '16px', marginTop: item.id === 15752 ? 20 : 0 }} key={`general${attrUid}`}>
        <div style={{ display: 'flex', marginBottom: '16px' }} key={`general${attrUid}`}>
          <GeneralElement
            {...item}
            key={`generalElement_${attrUid}`}
            index={index}
            setError={this.setError}
            errorField={errorFields[atrrId]}
            attrUid={item._attruid}
            searchId={atrrId}
            disabled={disabled}
            required={item.required}
            item={item}
            items={elements}
            multiplicity={multiplicity}
            groupIndex={this.props.groupIndex}
            suggestions={getDefaultTo(
              this.state.suggestions,
              `index_${index}`,
              [],
            )}
            isModerateError={!_.isEmpty(moderateError)}
            moderateError={moderateError}
            errors={errors}
            onValidate={onValidate}
            handleChange={this.handleChange}
            deleteItem={this.deleteItem}
            handleSuggestionsFetchRequested={
              this.handleSuggestionsFetchRequested
            }
            showPopupSearch={this.props.showPopupSearch}
            hasPublishedGood={this.props.hasPublishedGood}
            onChange={onChange}
            t={t}
            defaultValueAddress={this.state.defaultValueAddress}
            defaultValueCountry={this.state.defaultValueCountry}
            editableAddressBlock={editableAddressBlock}
            allowEditRequiredAttr={allowEditRequiredAttr}
            resizeable={resizeable}
            multiplicityGroup={multiplicityGroup}
            multiplicityGroupId={multiplicityGroupId}
            multiplicityGroupUiId={multiplicityGroupUiId}
            multiplicityDelete={multiplicityDelete}
            preview={preview}
            isLockedField={ // доп.флаг для синей галочки в поле
              (item.disabled
                || _.includes(groupIdToDisable, item.groupId)
                || (item.groupId === 25 && item.id === 15677)) // для поля Юридический адрес в модалке регистрации
              && disabledForEditing}
          />
          {allowEditRequiredAttr && (
            <IconEditWrapper onClick={this.changeAccessForEditing}>
              <IconEdit />
            </IconEditWrapper>
          )}
        </div>,
      );

      if (!disabledGroupButton && disabled) {
        disabledGroupButton = disabled;
      }
    });

    return { fields, disabledGroupButton };
  };

  addItem = () => {
    const { items, groupIndex, attrGroup } = this.props;
    const isMultiplicityBlock = !_.isEmpty(items[0].multiplicityGroup);
    const multiplicityGroupUiId = _.uniqueId('groupId');
    const newBlockId = Math.random();
    if (isMultiplicityBlock) {
      /**
       * нужно найти id аттрибута, после которого надо инсертнуть блок новых полей.
       */
      const idOfLastItem =
        items[0].multiplicityGroup[items[0].multiplicityGroup.length - 1];
      let multAttrGroup = [];
      /**
       * ищем всегда первые элементы(из оригинального списка), id которых удовлетворяют тем что есть в группе.
       * иначе поля будут плодиться n*2 каждый раз.
       */
      let currentAttrGroup = _.cloneDeep(attrGroup[groupIndex]);
      _.forEach(items[0].multiplicityGroup, (item) => {
        let correspondAttribute = _.find(
          currentAttrGroup.attributes,
          (attribute) => {
            return attribute.id === item;
          },
        );

        multAttrGroup.push(
          createCopyAttribute(correspondAttribute, multiplicityGroupUiId, newBlockId),
        );
      });

      return this.props.addItem(
        multAttrGroup,
        groupIndex,
        isMultiplicityBlock,
        idOfLastItem,
      );
    }
    const newItem = {
      ...items[items.length - 1],
      _attruid: _.uniqueId('attruid_'),
      uId: null,
      value: undefined,
      valueId: undefined,
      valueType: undefined,
      valueTitle: undefined,
      disabled: false,
      newBlockId
    };

    this.props.addItem(newItem, groupIndex, isMultiplicityBlock);
  };

  deleteItem = (attrUid) => {
    const { items, groupIndex } = this.props;
    this.props.deleteItem(items, groupIndex, attrUid);
  };

  render() {
    const {
      multiplicity,
      t,
      multiplicityGroup,
      draftAndEditMode,
      multiplicityAdd,
    } = this.props;
    const { fields } = this.buildBlocks();

    /**
     * TODO: почистить не используемое.
     */
    if (multiplicity || multiplicityAdd) {
      return (
        <div style={{ marginBottom: '90px'}}>
          {fields}
          <div style={{ display: 'flex', justifyContent: 'flex-start', position: 'absolute', left: '0' }}>
            <MuiThemeProvider theme={ProductButton}>
              <Button
                color={'secondary'}
                // disabled={disabledGroupButton || !addIsAllowed}
                // disabled={!addIsAllowed && editIsAllowed}
                disabled={!draftAndEditMode}
                onClick={this.addItem}>
                <IconPlusNew style={{ marginRight: '8px' }} />
                {multiplicityGroup && multiplicityAdd
                  ? t('Добавить блок')
                  : t('Добавить')}
              </Button>
            </MuiThemeProvider>
          </div>
          {/* <ProductDivider /> */}
        </div>
      );
    } else {
      return fields;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSuggestions: (url, value, cbk) => getSuggestions(url, value, cbk),
    },
    dispatch,
  );
};

ConsumerItem.propTypes = {
  // addItem: PropTypes.func.isRequired,
  // deleteItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  showPopupSearch: PropTypes.func,
  closePopupSearch: PropTypes.func,
  onValidate: PropTypes.func.isRequired,
  groupIndex: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  popupElement: PropTypes.object,
  moderateErrors: PropTypes.array,
  multiplicity: PropTypes.bool.isRequired,
  preview: PropTypes.bool,
  hasPublishedGood: PropTypes.bool.isRequired,
  isCopy: PropTypes.bool,
};

ConsumerItem.defaultProps = {
  errorFields: {}
}

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps),
)(ConsumerItem);
