import * as React from 'react';
import * as PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Button, MuiThemeProvider } from '@material-ui/core';
import { PasswordInput } from '../../../../components';
import { IconPreloader } from '../../../../common/icons/customIcons';
import { client } from '../../../../api/Client';
import { parse } from 'qs';
import {
  AuthContent,
  AuthTitle,
  AuthInputContainer,
  defaultAuthInput,
  defaultAuthBtn,
  PasswordMessage,
} from '../../Authentification.styled';
import { BtnContPaddingless } from '../../../../common/styles/Common.styled';
import _ from "lodash";
// import { PasswordErrorsWarning } from './ChangePassword.styled';
//import ActiveSessionModal from '../modal/ActiveSessionModal';

async function acceptChangePassword(data) {
  return await client().instance.post(
    `/user/change-password`, data
  );
}
class ChangePasswordView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      email: '',
      fromMail: '',
      password: '',
      passwordRepeat: '',
      accountId: '',
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const params = parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (params) {
      this.setState({
        code: params.code,
        email: params.email,
        fromMail: params.fromMail,
        accountId: params.accountId,
      });
    }
  }

  handleChange = (name, value) => {
    let state = cloneDeep(this.state);
    state[name] = value;

    this.setState(state);
  };


  onChangePassword = () => {
    //this.props.onChangePassword(this.state);
    acceptChangePassword(this.state)
      .then(() => {
        this.props.onLogin(this.state);
      })
      .catch((errors) => {
        const err = _.get(errors, 'response.data.errors');
        _.forEach(err, (i) => this.props.throwError(i));
        this.props.goToPage('/login');
      });

  };

  validatePassword = (password) => {
    const type = {
      minimum8Chars: /^.{8,}$/,
      containsNumbers: /^.*[0-9]+.*$/,
      containsUpperCaseLetters: /^.*[A-Z]+.*$/,
      containsLowerCaseLetters: /^.*[a-z]+.*$/,
      withoutSpecialChars: /[!@#$%^&*]/
    };
    const arr = _.reduce(type, (result, value) => {
      result.push(value.test(password));
      return result;
    }, []);
    return _.every(arr);
  };



  render() {
    const { t, loading } = this.props;
    const { password, passwordRepeat } = this.state;
    const passwordValid = this.validatePassword(password);
    const labelPasswordError = t('Пароль должен содержать не менее 8 символов, строчные и прописные буквы латинского алфавита, цифры и хотя бы один из спецсимволов(!@#$%^&*)');
    const disabledChangePassword = (!((password && passwordRepeat) && password === passwordRepeat)) || !passwordValid;
    return (
      <AuthContent>
        <AuthTitle>{t('Восстановление доступа')}</AuthTitle>
        <PasswordMessage>{labelPasswordError}</PasswordMessage>
        {/* {this.props.isActiveSessionOpenModal && (
          <ActiveSessionModal
            activeSessionInfo={this.props.activeSessionInfo}
            handleSubmit={() =>
              onLogin({
                email: this.state.email,
                password: this.state.password,
                accountId: this.state.accountId,
                closeOpenSessions: true,
              })
            }
          />
        )} */}
        <MuiThemeProvider theme={defaultAuthInput}>
          <AuthInputContainer>
            <PasswordInput
              error={password && !passwordValid}
              name="newPassword"
              id="newPassword"
              onChange={(value) => this.handleChange('password', value)}
              value={password}
              label={t('Новый пароль')}
            />
            {/* {(password && !passwordValid) &&
              <PasswordErrorsWarning>{labelPasswordError}</PasswordErrorsWarning>
            } */}
          </AuthInputContainer>
          <AuthInputContainer>
            <PasswordInput
              error={!!(password && passwordRepeat) && (password !== passwordRepeat)}
              name="repeatPassword"
              id="repeatPassword"
              onChange={(value) => this.handleChange('passwordRepeat', value)}
              value={passwordRepeat}
              label={!!password && password !== passwordRepeat
                ? t('Пароли не совпадают')
                : t('Подтверждение пароля')
              }
            />
          </AuthInputContainer>
        </MuiThemeProvider>
        <BtnContPaddingless>
          <MuiThemeProvider theme={defaultAuthBtn}>
            <Button
              disabled={disabledChangePassword}
              onClick={this.onChangePassword}
            >
              {loading ? <IconPreloader /> : t('Войти')}
            </Button>
          </MuiThemeProvider>
        </BtnContPaddingless>
      </AuthContent>
    );
  }
}



ChangePasswordView.propTypes = {
  onLogin: PropTypes.func,
  onChange: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemindPassword: PropTypes.func,
  loading: PropTypes.bool,
  emailValid: PropTypes.bool,
  email: PropTypes.string,
  password: PropTypes.string,
  isActiveSessionOpenModal: PropTypes.bool.isRequired,
  activeSessionInfo: PropTypes.string.isRequired,
};

export default withTranslation()(ChangePasswordView);
