import { Reducer } from 'redux';
import i18n from '../../../i18n';
import {
  CLEAR_ALERT_GRID_ACTION,
  CLEAR_VERSIONS_ACTION,
  DELETE_PRODUCTS_FAILED_ACTION,
  DELETE_PRODUCTS_SUCCESSED_ACTION,
  LOAD_DATA_ACTION,
  LOAD_DATA_FAILED_ACTION,
  LOAD_DATA_SUCCESSED_ACTION,
  LOAD_DICTIONARY_GOODS_BRAND_SUCCESSED_ACTION,
  CLEAR_DICTIONARY_GOODS_BRAND_ACTION,
  LOAD_DICTIONARY_GOODS_CATEGORY_SUCCESSED_ACTION,
  CLEAR_DICTIONARY_GOODS_CATEGORY_ACTION,
  LOAD_VERSIONS_SUCCESSED_ACTION,
  OPEN_DRAFT_PUBLISHED_FAILED_ACTION,
  OPEN_DRAFT_PUBLISHED_SUCCESED_ACTION,
  RESET_STATE_ACTION,
  SET_FILTERS_GRID_ACTION,
  IMPORT_PRODUCT_ACTION,
  IMPORT_PRODUCT_ACTION_SUCCEEDED,
  IMPORT_PRODUCT_ACTION_FAILED,
  ABORT_MASS_MEDIA_IMPORT_ACTION,
  CHANGE_MASS_UPLOAD_LIST_COUNT,
  MASS_MEDIA_IMPORT_ACTION,
  MASS_MEDIA_IMPORT_ACTION_SUCCEEDED,
  MASS_MEDIA_IMPORT_ACTION_FAILED,
  CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION,
  CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION_SUCCEDED,
  CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION_FAILED,
} from './Products.constants';
import _ from 'lodash';

const _initialState = {
  loading: false,
  filters: null,
  filtersCache: null,
  data: [],
  totalObjects: 0,
  versions: [],
  dictionaryBrand: [],
  dictionaryCategory: [],
  alert: {
    type: null,
    message: null,
  },
  loadingProductReport: false,
  abortMassUpload: false,
  massUploadListCount: 0,
  loadingMassUpload: false,
};

export const productsReducer: Reducer = (state = _initialState, action) => {
  switch (action.type) {
    case CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION:
    case MASS_MEDIA_IMPORT_ACTION:
    case LOAD_DATA_ACTION: {
      return {
        ...state,
        loading: true,
      };
    }

    case CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION_FAILED:
    case CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION_SUCCEDED:
    case MASS_MEDIA_IMPORT_ACTION_SUCCEEDED:
    case MASS_MEDIA_IMPORT_ACTION_FAILED:
    case LOAD_DATA_FAILED_ACTION:
    case OPEN_DRAFT_PUBLISHED_FAILED_ACTION: {
      return {
        ...state,
        loading: false,
      };
    }
    case LOAD_DATA_SUCCESSED_ACTION: {
      const data = _.map(action.data, (d) => {
        d.isEdit = false;
        return d;
      });
      return {
        ...state,
        loading: false,
        data: data,
        totalObjects: action.totalObjects,
        filters: null,
        filtersCache: _.cloneDeep(action.filters),
      };
    }
    case SET_FILTERS_GRID_ACTION: {
      return {
        ...state,
        filters: _.cloneDeep(state.filtersCache),
        filtersCache: null,
      };
    }
    case LOAD_VERSIONS_SUCCESSED_ACTION: {
      return {
        ...state,
        versions: action.data,
      };
    }
    case CLEAR_VERSIONS_ACTION: {
      return {
        ...state,
        versions: [],
      };
    }
    case LOAD_DICTIONARY_GOODS_BRAND_SUCCESSED_ACTION: {
      return {
        ...state,
        dictionaryBrand: _.filter(action.data, 'text'),
      };
    }
    case CLEAR_DICTIONARY_GOODS_BRAND_ACTION: {
      return {
        ...state,
        dictionaryBrand: [],
      };
    }
    case LOAD_DICTIONARY_GOODS_CATEGORY_SUCCESSED_ACTION: {
      return {
        ...state,
        dictionaryCategory: _.filter(action.data, 'text'),
      };
    }
    case CLEAR_DICTIONARY_GOODS_CATEGORY_ACTION: {
      return {
        ...state,
        dictionaryCategory: [],
      };
    }

    case DELETE_PRODUCTS_SUCCESSED_ACTION: {
      const data = _.cloneDeep(state.data);
      let message = '';
      if (action.ids.length > 1) {
        message = i18n.t('Карточки товаров удалены');
      } else {
        const item = _.find(
          data,
          (o) => !_.isNull(o.draft) && o.draft.id === action.ids[0],
        );
        message = i18n.t(`Карточка товара кода товара ${item.draft.gtin}${item.draft.name ? ` ${item.draft.name}` : ''} удалена`);
      }

      return {
        ...state,
        alert: {
          type: 'success',
          message: message,
        },
      };
    }
    case DELETE_PRODUCTS_FAILED_ACTION: {
      return { ...state };
    }

    case CLEAR_ALERT_GRID_ACTION: {
      return {
        ...state,
        alert: {
          type: null,
          message: null,
        },
      };
    }
    case OPEN_DRAFT_PUBLISHED_SUCCESED_ACTION: {
      let data = _.cloneDeep(state.data);
      _.forEach(action.data, (i) => {
        const index = _.findIndex(
          data,
          (o) => !_.isNull(o.goods) && o.goods.id === i.goodId,
        );
        if (index !== -1) {
          _.set(data, `[${index}].draft`, i.draft);
        }
      });
      return {
        ...state,
        loading: false,
        data: data,
      };
    }

    case RESET_STATE_ACTION: {
      return { ..._initialState };
    }

    case IMPORT_PRODUCT_ACTION: {
      return {
        ...state,
        loadingProductReport: true,
      };
    }

    case ABORT_MASS_MEDIA_IMPORT_ACTION: {
      return {
        ...state,
        abortMassUpload: action.data,
      };
    }

    case IMPORT_PRODUCT_ACTION_SUCCEEDED:
    case IMPORT_PRODUCT_ACTION_FAILED: {
      return {
        ...state,
        loadingProductReport: false,
      };
    }

    case CHANGE_MASS_UPLOAD_LIST_COUNT: {
      return {
        ...state,
        massUploadListCount: action.data,
      };
    }

    default:
      return { ...state };
  }
};
