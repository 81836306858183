import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import colors from '../../../../common/styles/Common.colors';

export const defaultTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '4px',
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      input: {
        padding: '8px 20px',
        lineHeight: '21px',
        height: '40px',
        '&:required': {
          borderRadius: '4px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      underline: {
        color: 'transparent',
        height: '56px',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
      error: {},
    },
    MuiSelect: {
      root: {
        width: '100%',
        // border: "1px solid #D9D9D9",
        // borderRadius: "3px",
        // '&$disabled': {
        //     backgroundColor: "#F9F9F9!important"
        // }
      },
      select: {
        padding: '8px 20px',
      },
      selectMenu: {
        color: `${colors.Common.textColor}`,
        fontWeight: '400',
      },
      iconOpen: {
        transform: 'translate(-8px, 2px) rotate(180deg)',
      },
      disabled: {},
    },
    MuiInputBase: {
      root: {
        width: '100%',
        fontFamily: 'SegoeUI',
        '&$disabled': {
          backgroundColor: '#F9F9F9!important',
        },
      },
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
        color: '#868686',
      },
      shrink: {
        color: '#868686',
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '15px 20px',
        paddingTop: '15px',
        paddingBottom: '15px',
        color: '#52535A',
        fontSize: '16px',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0 0 3px 3px',
      },
      elevation8: {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, .15)',
      },
    },
    MuiButton: {
      root: {
        minWidth: '40px',
        minHeight: '33px',
        backgroundColor: '#eeeeee',
        border: '1px solid #ebebeb',
        borderRadius: '3px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      },
    },
  },
});

export const PageWrapper = styled.div`
  padding: 40px;
  min-width: 1100px;
  height: calc(100vh - 375px);
  min-height: 600px;
`;

export const DragDropField = styled.fieldset`
  border: 1px dashed #C9C7C7;
  height: 255px;
  padding: 32px;
  text-align: center;
`;

export const DragDrop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DragDropForm = styled.form`
  display: flex;
  justify-content: space-between;
  height: calc(100% - 106px);
`;

export const DragDropTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  padding: 40px 0 20px;
`;

export const DragDropLabel = styled.label`
  font-size: 16px;
  line-height: 24px;
`;

export const DragDropInput = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
`;

export const DragDropWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 15px 0 40px;
  text-align: center;
`;

export const DragDropButton = styled.label`
  width: 100%;
  max-width: 190px;
  height: 44px;
  background: ${colors.Buttons.buttonPrimary.background};
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;
`;

export const DragDropButtonText = styled.span`
  line-height: 1;
  margin-top: 1px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;


export const AboutPageProfile = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  white-space: nowrap;
`;

export const PageFieldLeftWrapper = styled.div`
  width: 100%;
`;

export const RowRightWrapper = styled.div`
  margin-top: -26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 0 28px;
  @media (min-width: 1920px) {
    padding: 0 184px;
  }
`;

export const ColumnItem = styled.div`
  flex-direction: column;
  min-width: 40vw;
`;

export const InputRowItem = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
`;

export const InputLabelItem = styled.div`
  min-width: 170px;
  margin-right: 10px;
  align-self: center;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  .iconFix {
    margin-left: 20px;
    margin-bottom: -3px;
  }
`;

export const CheckBoxItem = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin-left: 160px;
`;

export const PredicateWrapper = styled.div`
  min-width: 100px;
  min-height: 33px;
  background-color: #eeeeee;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const SaveButtonWrapper = styled.div`
  width: 550px;
  display: flex;
  margin: 32px 0 32px 170px;
`;
