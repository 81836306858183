import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { MenuItem, MuiThemeProvider, Select } from '@material-ui/core';
import { findStatusObj } from '../../../../common/utils/utils';
import { IconSelectStatus } from '../../../../common/icons/customIcons';
import {
  ColumnCellInner,
  StatusLine,
} from '../../../../common/styles/Common.styled';
import {
  ColumnCellStatus,
  GridStatusSelect,
  StatusLineSelect,
} from './SelectSwitchingGoods.styled';

class SelectSwitchingGoods extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps.original, this.props.original);
  }

  clearStatusText = (value) => {
    const { t } = this.props;
    return t(_.trim(_.replace(value, /\([^)]*\)/g, '')));
  };

  render() {
    const { onChange, original } = this.props;

    const key = `${original.dto.id}_${original.dto.status}`;

    let st;
    if (!_.isNull(original.goods) && !_.isNull(original.draft)) {
      const statusGoods = findStatusObj(original.goods.status);
      const statusDraft = findStatusObj(original.draft.status);
      st = (
        <MuiThemeProvider theme={GridStatusSelect}>
          <Select
            value={original.nameDto}
            name="status_select"
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: { vertical: 'center', horizontal: 'center' },
              transformOrigin: { vertical: 'center', horizontal: 'center' },
            }}
            IconComponent={IconSelectStatus}
            onChange={onChange}>
            <MenuItem key={key} value="goods" original={original}>
              <StatusLineSelect color={statusGoods.style.color}>
                {statusGoods.style.icon}
                <span>{this.clearStatusText(statusGoods.title)}</span>
              </StatusLineSelect>
            </MenuItem>

            <MenuItem key={`${key}_draft`} value="draft" original={original}>
              <StatusLineSelect color={statusDraft.style.color}>
                {statusDraft.style.icon}
                <span>{this.clearStatusText(statusDraft.title)}</span>
              </StatusLineSelect>
            </MenuItem>
          </Select>
        </MuiThemeProvider>
      );
    } else {
      const status = findStatusObj(original.dto.status);
      st = (
        <StatusLine key={key} color={status.style.color}>
          {status.style.icon}
          <span>{this.clearStatusText(status.title)}</span>
        </StatusLine>
      );
    }
    return (
      <ColumnCellStatus>
        <ColumnCellInner>{st}</ColumnCellInner>
      </ColumnCellStatus>
    );
  }
}

SelectSwitchingGoods.propTypes = {
  onChange: PropTypes.func.isRequired,
  original: PropTypes.object.isRequired,
};

export default withTranslation()(SelectSwitchingGoods);
