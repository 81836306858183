import styled from 'styled-components';
import { NewGsTitle } from '../../../profile/views/gsOne/Gs1.styled';
import { StatusLine } from '../../../../common/styles/Common.styled';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const ProductMainContainer = styled.div`
  padding: 28px 40px 75px;
  //height: ${props => props.fullScroll ? 'none' : 'calc(100vh - 80px)' };
  background-color: #f8f8f8;  
`;
export const StatusBar = styled.div`  
  position: fixed;
  display: flex; 
  top: 109px;
  left: 40px;  
  right: 40px;
  z-index: 5;
  background: #fff;
  border-radius: 8px; 
  min-height: 80px;
  margin-bottom: 28px;
  width: calc(100vw - 95px);
  &::before {
    content: "";
    position: absolute;
    top: -28px;
    left: 0;
    background-color: #f8f8f8; 
    width: 100%;
    height: 28px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -28px;
    left: 0;
    background-color: #f8f8f8; 
    width: 100%;
    height: 28px;
  }
`;
export const ProductCont = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: calc(100% - 217px);
`;
export const ProductContLeft = styled.div`
  //padding: 0 40px 100px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  width: ${props => props.isFullPhotoContent ? "100%" : "410px"};
`;
export const ProductContRight = styled.div`
  border-radius: 8px;
  background: #fff;
  position: relative;
  width: calc(100% - 438px);
  display: ${props => props.isFullPhotoContent ? "none" : "block"};  
  padding-top: 108px;   
`;

export const ProductEditButtons = styled.div`
  margin-left: 20px;
  button {
    @media (max-width: 1440px) {
      min-width: auto;
    }
  }
`;
export const ProductStatus = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 36px 0 0;
`;
export const CardState = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
  }
  @media (min-width: 1920px) {
    margin-right: 51px;
  }
`;
export const ProductStatusInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 23px;
`;
export const ProductStatusInfoLeft = styled.div`
  margin-right: 23px; 
  display: flex;
  align-items: center;
  span {
    margin-left: 11px;
    font-size: 16px;
  }
  @media (min-width: 1920px) {
    margin-right: 51px; 
  }
`;
export const ProductStatusInfoRight = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 11px;
    font-size: 16px;
  }
`;
export const TitleClarification = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  margin-bottom: -35px;
`;
export const NewProductTitle = styled(NewGsTitle)`
  // position: inherit;
  width: fit-content;
  // top: 15px;
  justify-content: space-between;
  padding: 28px 20px 28px 36px; 
  white-space: nowrap;
  color: ${colors.Common.textColor};
`;
export const NewProductGtin = styled.div`
  font-size: 16px;
`;
export const ProductStatusLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.Common.textColor};
`;
export const ProductStatusRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.Common.textColor};
`;
export const ProductStatusLine = styled(StatusLine)`
  color: ${(props) => props.color};
  span {
    font-size: 16px;
  }
  @media (min-width: 1920px) {
    padding-left: 28px;
  }
`;
export const ProductPackingPurpose = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 16px;
  @media (min-width: 1920px) {
    padding-left: 48px;
  }
`;

export const ProductCodeIkpu = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-right: 17px;  
`;
export const ProductCodeIkpuTitle = styled.div`
  font-size: 16px;
  cursor: pointer;
`;
export const ProductCodeIkpuIcons = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;
export const CopyBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '25px',
        maxHeight: '45px',
        marginLeft: '8px',
      },
      text: {
        padding: '4px',
      },
    },
  },
});
export const TooltipIkpu = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: "340px",
        minHeight: "80px",
        position: "relative",
        borderRadius: "0"
      },
      label: {
        display: "flex",
        flexDirection: "row",
        lineHeight: "1.2",
        alignItems: "center",
        justifyContent: "flex-start"
      },
      text: {
        padding: "0 115px 0 30px"
      }
    },
    MuiButtonBase: {
      root: {
        backgroundColor: "transparent!important",
        padding: "0 10px",
        '&:hover': {
          backgroundColor: "transparent!important",
          color: "#000"
        }
      }
    }
  }
});