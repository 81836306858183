export const RESET_STATE_ACTION = 'RESET_STATE_ACTION';
export const LOAD_DATA_ACTION = 'LOAD_DATA';
export const LOAD_DATA_SUCCESSED_ACTION = 'LOAD_DATA_SUCCESSED';
export const LOAD_DATA_FAILED_ACTION = 'LOAD_DATA_FAILED';
export const SET_FILTERS_GRID_ACTION = 'SET_FILTERS_GRID';
export const LOAD_VERSIONS_ACTION = 'LOAD_VERSIONS';
export const LOAD_VERSIONS_SUCCESSED_ACTION = 'LOAD_VERSIONS_SUCCESED';
export const CLEAR_VERSIONS_ACTION = 'CLEAR_VERSIONS';

export const EXPORT_PRODUCT_ACTION = 'EXPORT_PRODUCT';
export const SEND_FOR_MODERATION_PRODUCT_ACTION = 'SEND_FOR_MODERATION_PRODUCT';
export const OPEN_DRAFT_ACTION = 'OPEN_DRAFT';
export const OPEN_DRAFT_PUBLISHED_SUCCESED_ACTION =
  'OPEN_DRAFT_PUBLISHED_SUCCESED';
export const OPEN_DRAFT_PUBLISHED_FAILED_ACTION = 'OPEN_DRAFT_PUBLISHED_FAILED';
export const PUBLISH_PRODUCT_ACTION = 'PUBLISH_PRODUCT';
export const DELETE_PRODUCTS_ACTION = 'DELETE_PRODUCTS';
export const DELETE_PRODUCTS_SUCCESSED_ACTION = 'DELETE_PRODUCTS_SUCCESSED';
export const DELETE_PRODUCTS_FAILED_ACTION = 'DELETE_PRODUCTS_FAILED';
export const LOAD_DICTIONARY_GOODS_CATEGORY_ACTION =
  'LOAD_DICTIONARY_GOODS_CATEGORY';
export const LOAD_DICTIONARY_GOODS_CATEGORY_SUCCESSED_ACTION =
  'LOAD_DICTIONARY_GOODS_CATEGORY_SUCCESSED';
export const CLEAR_DICTIONARY_GOODS_CATEGORY_ACTION =
  'CLEAR_DICTIONARY_GOODS_CATEGORY';
export const LOAD_DICTIONARY_GOODS_BRAND_ACTION = 'LOAD_DICTIONARY_GOODS_BRAND';
export const LOAD_DICTIONARY_GOODS_BRAND_SUCCESSED_ACTION =
  'LOAD_DICTIONARY_GOODS_BRAND_SUCCESSED';
export const CLEAR_DICTIONARY_GOODS_BRAND_ACTION = 'CLEAR_DICTIONARY_GOODS_BRAND';
export const CLEAR_ALERT_GRID_ACTION = 'CLEAR_ALERT_GRID';
export const ARCHIVE_CARDS_ACTION = 'ARCHIVE_CARDS';

export const IMPORT_PRODUCT_ACTION = 'IMPORT_PRODUCT_ACTION';
export const IMPORT_PRODUCT_ACTION_SUCCEEDED =
  'IMPORT_PRODUCT_ACTION_SUCCEEDED';
export const IMPORT_PRODUCT_ACTION_FAILED = 'IMPORT_PRODUCT_ACTION_FAILED';

export const MASS_MEDIA_IMPORT_ACTION = 'MASS_MEDIA_IMPORT_ACTION';
export const MASS_MEDIA_IMPORT_ACTION_SUCCEEDED =
  'MASS_MEDIA_IMPORT_ACTION_SUCCEEDED';
export const MASS_MEDIA_IMPORT_ACTION_FAILED =
  'MASS_MEDIA_IMPORT_ACTION_FAILED';

export const ABORT_MASS_MEDIA_IMPORT_ACTION = 'ABORT_MASS_MEDIA_IMPORT_ACTION';

export const CHANGE_MASS_UPLOAD_LIST_COUNT = 'CHANGE_MASS_UPLOAD_LIST_COUNT';

export const CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION =
  'CANCEL_ALL_UPLOADED_MASS_MEDIA';

export const CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION_SUCCEDED =
  'CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION_SUCCEDED';

export const CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION_FAILED =
  'CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION_FAILED';
