import * as PropTypes from 'prop-types';
import * as React from 'react';
import download from 'downloadjs';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  ExportDialogMin,
  ExportDialogMinInner,
  ExportModalDialog,
} from './ExportModal.styled';
import { IconClose } from '../../../../common/icons/customIcons';
import { exportProduct } from '../../ducks/Products.actions';

const openxmlFormats =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

class ExportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  exportProduct = () => {
    const { getBuildParamsForList, onExportProduct, selected, t } = this.props;
    const params = getBuildParamsForList();
    const goodIds = [];

    if (selected) {
      _.forEach(selected, (object) => {
        goodIds.push(object.id);
      });

      params.goodIds = goodIds;
    }

    this.setState({ loading: true });
    onExportProduct('short', params, (errors, data) => {
      if (!errors) {
        this.setState({ loading: false });
        const blob = new Blob([data], { type: openxmlFormats });
        return download(blob, `${t('Краткий отчет')}.xlsx`);
      }
      this.props.handleClose();
    });
  };

  exportProductFull = () => {
    const { getBuildParamsForList, onExportProduct, selected, t } = this.props;
    const params = getBuildParamsForList();
    const goodIds = [];

    if (selected) {
      _.forEach(selected, (object) => {
        goodIds.push(object.id);
      });

      params.goodIds = goodIds;
    }

    this.setState({ loading: true });
    onExportProduct('full', params, (errors, data) => {
      if (!errors) {
        this.setState({ loading: false });
        const blob = new Blob([data], { type: openxmlFormats });
        return download(blob, `${t('Полный отчет')}.xlsx`);
      }
      this.props.handleClose();
    });
  };

  render() {
    const { handleClose, filters, t } = this.props;

    const allowedExportStatuses = _.includes(
      ['published', 'draft', 'errors'],
      filters.status,
    );

    return (
      <MuiThemeProvider theme={ExportModalDialog}>
        <Dialog open>
          <DialogTitle id="alert-dialog-title" disableTypography>
            <Typography variant="h2">{t('Экспорт атрибутов')}</Typography>
            <IconButton
              id="dismiss-btn-close"
              onClick={handleClose}>
              <IconClose />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <p>
              {t(
                'Обращаем внимание, что экcпорт данных возможен только после применения обязательных фильтров',
              ) + `. `}
            </p>
            <p>
              {t(
                'Системное ограничение для экспорта составляет 100 товаров',
              ) + `.`}
            </p>
            <ExportDialogMin>
              <p>
                {t(`Необходимо выбрать фильтр по столбцам`,)}
              </p>
              <p>
                {t(`'Назначение упаковки' и 'Статус'`,)}
              </p>
              <ExportDialogMinInner
              // disabled={!filters.packingPurpose || !allowedExportStatuses}
              >
                <h3>
                  {t('Краткий отчет')} <span>.xlsx</span>
                </h3>
                <Button
                  color={'primary'}
                  disabled={
                    this.state.loading ||
                    !filters.packingPurpose ||
                    !allowedExportStatuses
                  }
                  onClick={this.exportProduct}>
                  {t('Скачать')}
                </Button>
              </ExportDialogMinInner>
            </ExportDialogMin>

            <ExportDialogMin>
              <p>
                {t(`Необходимо выбрать фильтры по столбцам`,)}
              </p>
              <p>
                {t(`'Назначение упаковки', 'Классификация' и 'Статус'`,)}
              </p>
              <ExportDialogMinInner
              // disabled={
              //   !filters.packingPurpose ||
              //   !filters.category ||
              //   !allowedExportStatuses
              // }
              >
                <h3>
                  {t('Полный отчет')} <span>.xlsx</span>
                </h3>
                <Button
                  color={'primary'}
                  disabled={
                    this.state.loading ||
                    !filters.packingPurpose ||
                    !filters.category ||
                    !allowedExportStatuses
                  }
                  onClick={this.exportProductFull}>
                  {t('Скачать')}
                </Button>
              </ExportDialogMinInner>
            </ExportDialogMin>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

ExportModal.propTypes = {
  onExportProduct: PropTypes.func.isRequired,
  getBuildParamsForList: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  categoryFilter: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onExportProduct: (status, params, cbk) =>
        exportProduct(status, params, cbk),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps),
)(ExportModal);
