import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { MenuItem, MuiThemeProvider } from '@material-ui/core';
import { loadKatoDictionary } from '../../../../../common/catalog/Catalog.action';
import { ProfileDivider } from '../../../Profile.styled';
import { AddressBlock } from '../gsComponents/address/Adress.styled';
import { CustomInput, Autocomplete } from '../../../../../components';
import { IconCheckMenu } from '../../../../../common/icons/customIcons';
import { GridItem } from '../../../../profile/Profile.styled';
import {
  GridItemSmall,
  ProfileAutocomplete,
  GridBlock,
  GridItemA,
  GridItemB,
  GridItemSmallC,
  GridBlockA,
  GridBlockC,
  GridItemSmallA,
  GridItemD,
  GridBlockE,
  InputContainer,
  InputIcon,
} from '../../../../../common/styles/Common.styled';
import { GsTitle } from '../Gs1.styled';

/**
 * TODO:
 * отрефакторить
 */

const getDefaultTo = (obj, path, defaultValue = '') =>
  _.defaultTo(_.get(obj, path, defaultValue), defaultValue);

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return (
    <MenuItem disableGutters={true} selected={isHighlighted} component="div">
      {suggestion.text}
    </MenuItem>
  );
}

class Addresses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.legalAddress.region !== 'Узбекистан') {
      this.props.handleStateChange(
        'legalAddress',
        'region',
        'Узбекистан',
        false,
      );
    }
  }

  handleChange = (name, value, tab) => {
    const { handleStateChange } = this.props;
    switch (name) {
      case 'postalCode': {
        value = value.replace(/[^[0-9]/, '');
        handleStateChange(
          tab,
          name,
          value === '' ? '' : _.toString(value),
          !!!_.defaultTo(value, ''),
        );
        break;
      }
      case 'area':
      case 'locality':
      case 'fullAddressStateLang':
      case 'fullPhysicallAddressState': {
        handleStateChange(
          tab,
          name,
          value === '' ? '' : _.toString(value),
          false,
        );
        break;
      }
      default: {
        handleStateChange(
          tab,
          name,
          value === '' ? '' : _.toString(value),
          !!!_.defaultTo(value, ''),
        );
      }
    }
  };

  handleSuggestionsFetchRequested = (name, value, fieldName) => {
    const inputValue = _.toLower(_.deburr(_.trim(value)));
    if (inputValue.length === 0 || inputValue.length < 3) {
      switch (name) {
        case 'regionCodeDisplaySigns': {
          this.handleChange('regionCode', null, 'legalAddress');
          break;
        }
        default:
          break;
      }
    } else {
      this.fetchData(name, inputValue);
    }
  };

  fetchData = (name, value) => {
    switch (name) {
      case 'regionCodeDisplaySigns': {
        this.props.loadKatoDictionary(value);
        break;
      }
      default:
        break;
    }
  };

  getSuggestionValue = (value, name, tab) => {
    this.handleChange(name, value.id, tab);
    return value.text;
  };

  render() {
    const { t, legalAddress, physicalAddress } = this.props;

    return (
      <div>
        <GsTitle>{t('Юридический адрес')}</GsTitle>
        <GridBlockC>
          <GridItemA>
            <InputContainer>
              <CustomInput
                id="region"
                name="region"
                required
                valid={!!_.defaultTo(legalAddress.region, '')}
                label={t('Выберите страну')}
                value={_.defaultTo(legalAddress.region, '')}
                // maxLength={9}
                onChange={(value) =>
                  this.handleChange('region', value, 'legalAddress')
                }
                type="text"
                disabled
              // dirty={true}
              // error={
              //     errors.bik ||
              //     (errors.settlementAccount && errors.errorsettlementAccount.code === 4) ||
              //     (errors.correspondentAccount && errors.errorcorrespondentAccount.code === 4)
              // }
              />
              {!!_.defaultTo(legalAddress.region, '') && (
                <InputIcon>
                  <IconCheckMenu />
                </InputIcon>
              )}
            </InputContainer>
          </GridItemA>
          <GridItemB>
            <MuiThemeProvider theme={ProfileAutocomplete}>
              <Autocomplete
                disabled={false}
                required
                name="regionCode"
                suggestions={this.props.katoDictionary}
                label={t('Административно-территориальный объект')}
                handleSuggestionsFetchRequested={(value) =>
                  this.handleSuggestionsFetchRequested(
                    'regionCodeDisplaySigns',
                    value,
                    'regionCode',
                  )
                }
                handleSuggestionsClearRequested={() =>
                  this.handleSuggestionsFetchRequested(
                    'regionCodeDisplaySigns',
                  )
                }
                renderSuggestion={renderSuggestion}
                getSuggestionValue={(value) =>
                  this.getSuggestionValue(value, 'regionCode', 'legalAddress')
                }
                initValue={getDefaultTo(legalAddress, 'regionCode')}
                // onlyFromList={true}
                valid={!!_.defaultTo(legalAddress.regionCode, '')}
                // error={this.isErrorAndNotEmptyErrorField('hasShowBox')}
                emptyLabel={true}
              />
            </MuiThemeProvider>
          </GridItemB>
          {/* <MuiThemeProvider theme={ProfileAutocomplete}> */}
          <GridItemD>
            <CustomInput
              id="area"
              name="area"
              valid={!!_.defaultTo(legalAddress.area, '')}
              label={t('Район')}
              value={_.defaultTo(legalAddress.area, '')}
              onChange={(value) =>
                this.handleChange('area', value, 'legalAddress')
              }
              type="text"
            />
          </GridItemD>
          <GridItemA>
            <CustomInput
              id="city"
              name="city"
              required
              valid={!!_.defaultTo(legalAddress.city, '')}
              label={t('Город')}
              value={_.defaultTo(legalAddress.city, '')}
              onChange={(value) =>
                this.handleChange('city', value, 'legalAddress')
              }
              type="text"
            />
          </GridItemA>
          <GridItemB>
            <CustomInput
              id="locality"
              name="locality"
              valid={!!_.defaultTo(legalAddress.locality, '')}
              label={t('Населенный пункт')}
              value={_.defaultTo(legalAddress.locality, '')}
              onChange={(value) =>
                this.handleChange('locality', value, 'legalAddress')
              }
              type="text"
            />
          </GridItemB>
          <GridItemD>
            <CustomInput
              id="street"
              name="street"
              required
              valid={!!_.defaultTo(legalAddress.street, '')}
              label={t('Улица')}
              value={_.defaultTo(legalAddress.street, '')}
              onChange={(value) =>
                this.handleChange('street', value, 'legalAddress')
              }
              type="text"
            />
          </GridItemD>
          {/* </MuiThemeProvider> */}
        </GridBlockC>
        <GridBlockE>
          <GridItemSmall>
            <CustomInput
              id="building"
              name="building"
              required
              valid={!!_.defaultTo(legalAddress.building, '')}
              label={t('Дом/Здание')}
              value={_.defaultTo(legalAddress.building, '')}
              onChange={(value) =>
                this.handleChange('building', value, 'legalAddress')
              }
              type="text"
            />
          </GridItemSmall>
          <GridItemSmallA>
            <CustomInput
              id="postalCode"
              name="postalCode"
              required
              valid={!!_.defaultTo(legalAddress.postalCode, '')}
              label={t('Индекс')}
              value={_.defaultTo(legalAddress.postalCode, '')}
              onChange={(value) =>
                this.handleChange('postalCode', value, 'legalAddress')
              }
              type="text"
              maxLength={7}
            />
          </GridItemSmallA>
          <GridItemSmall>
            <CustomInput
              required
              valid={!!getDefaultTo(legalAddress, 'fullAddress')}
              id="fullAddress"
              name="fullAddress"
              label={t('Полный юридический адрес на русском языке')}
              value={getDefaultTo(legalAddress, 'fullAddress')}
              onChange={(value) =>
                this.handleChange(
                  'fullAddress',
                  value.replace(/[^[а-яА-Я0-9ёЁ!.?,\\'\\"\s-+«»]/, ''),
                  'legalAddress',
                )
              }
              onUpdate={() => { }}
              type="text"
            />
          </GridItemSmall>
          <GridItemSmallA>
            <CustomInput
              valid={!!getDefaultTo(legalAddress, 'fullAddressStateLang')}
              id="fullAddressStateLang"
              name="fullAddressStateLang"
              label={t('Полный юридический адрес на государственном языке')}
              value={getDefaultTo(legalAddress, 'fullAddressStateLang')}
              onChange={(value) =>
                this.handleChange(
                  'fullAddressStateLang',
                  value,
                  'legalAddress',
                )
              }
              onUpdate={() => { }}
              type="text"
            />
          </GridItemSmallA>
        </GridBlockE>
        <ProfileDivider />
        <GsTitle>{t('Фактический адрес')}</GsTitle>
        <GridBlockE>
          <GridItemSmall>
            <CustomInput
              id="fullPhysicalAddressRu"
              name="fullPhysicalAddressRu"
              label={t('Полный фактический адрес на русском языке')}
              value={getDefaultTo(physicalAddress, 'fullAddress')}
              valid={!!getDefaultTo(physicalAddress, 'fullAddress')}
              onChange={(value) =>
                this.handleChange(
                  'fullAddress',
                  value.replace(/[^[а-яА-Я0-9ёЁ!.?,\\'\\"\s-+«»]/, ''),
                  'physicalAddress',
                )
              }
              onUpdate={() => { }}
              type="text"
              required
            />
          </GridItemSmall>
          <GridItemSmallA>
            <CustomInput
              id="fullPhysicallAddressState"
              name="fullPhysicallAddressState"
              label={t('Полный фактический адрес на государственном языке')}
              value={getDefaultTo(physicalAddress, 'fullAddressStateLang')}
              valid={!!getDefaultTo(physicalAddress, 'fullAddressStateLang')}
              onChange={(value) =>
                this.handleChange(
                  'fullAddressStateLang',
                  value,
                  'physicalAddress',
                )
              }
              onUpdate={() => { }}
              type="text"
            />
          </GridItemSmallA>
        </GridBlockE>

        <ProfileDivider />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.catalogReducer.loading,
  katoDictionary: state.catalogReducer.katoDictionary,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadKatoDictionary: (data) => loadKatoDictionary(data),
    },
    dispatch,
  );
};

Addresses.propTypes = {
  loadKatoDictionary: PropTypes.func.isRequired,
  katoDictionary: PropTypes.array,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Addresses);
