import * as React from 'react';
import { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import _ from 'lodash';
import {
  Button,
  MuiThemeProvider,
  Popover,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import {
  IconNewFilter,
  IconCloseWhite,
  IconDropDown,
  IconPlusNew,
  IconTrash,
  IconQuestion,
} from '../../../common/icons/customIcons';
import DateFilter from './DateFilter';
import GtinFilter from './GtinFilter';
import BrandFilter from './BrandFilter';
import CategoryFilter from './CategoryFilter';
import PackagesFilter from './PackagesFilter';
import PhotoFilter from './PhotoFilter';
import IsValidFilter from './IsValidFilter';
import IsExemplarFilter from './isExemplarFilter';
import AttributeFilter from './AttributeFilter';
import PackingTypeFilter from './PackingTypeFilter';
import { CustomInput } from '../../../components';
import {
  FilterTitle,
  defaultFilterInput,
  DateContainer,
  DateFilterContainer,
  DateFilterField,
  FilterPopover,
  FilterMainTitle,
  FilterItem,
  FilterMainContent,
  FilterMainBtns,
  FilterItemContent,
  ButtonAddFieldsTheme,
  FilterAccordion,
  FilterContentPopup,
  CancelFilterButton,
  FilterBtnReset,
  FilterBtnTitle,
  DeleteAttributeButton,
} from './Filters.styled';
import { ResetFilterButton } from '../Catalog.styled';
import { PackingTypes } from '../../../common/constants/Statuses';
import { DefaultTooltipCenter, ExemplarIconContainer } from '../../../common/styles/Common.styled';

const attributeItem = {
  text: '',
  value: '',
  id: '',
}

const FiltersCatalogPopup = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({});
  const [isOpenStandartFilters, setIsOpenStandartFilters] = useState(false);
  const [isOpenAdvancedFilters, setIsOpenAdvancedFilters] = useState(false);
  const [isOpenAttributeFilters, setIsOpenAttributeFilters] = useState(false);
  const [isOpenAdditionalFields, setIsOpenAdditionalFields] = useState(false);
  const [errorGtin, setErrorGtin] = useState('');
  const [errorGtins, setErrorGtins] = useState('');
  const { photo, isValid, created, publicationTime,
    packingPurpose, name, brand, gtin, category, categoryTnved,
    packages, isExemplar, attributes } = filters;
  const { t } = props;
  const disabled = (
    (photo === null) &&
    (isValid === null) &&
    (created && !created.from) &&
    (publicationTime && !publicationTime.from) &&
    !packingPurpose &&
    !name &&
    !brand &&
    !gtin &&
    !category &&
    !categoryTnved &&
    !packages &&
    (isExemplar === null) &&
    !attributes[0].id
  );
  const isSelectedAttributes = attributes ? Boolean(attributes[attributes.length - 1].text) : false;
  const isSelectedAttribute = attributes ? _.some(attributes, (item => !!item.id)) : false;
  const open = Boolean(anchorEl);

  const initializeFilters = () => {
    setFilters({
      photo: _.defaultTo(props.currentFiltersValue.photo, null),
      isValid: _.defaultTo(props.currentFiltersValue.isValid, null),
      created: {
        from: _.defaultTo(props.currentFiltersValue.created.from, ''),
        to: _.defaultTo(props.currentFiltersValue.created.to, ''),
      },
      publicationTime: {
        from: _.defaultTo(props.currentFiltersValue.publicationTime.from, ''),
        to: _.defaultTo(props.currentFiltersValue.publicationTime.to, ''),
      },
      packingPurpose: _.defaultTo(
        _.find(PackingTypes, { value: props.currentFiltersValue.packingPurpose }), ''),
      name: _.defaultTo(props.currentFiltersValue.name, ''),
      brand: _.defaultTo(props.currentFiltersValue.brand, ''),
      gtin: props.currentFiltersValue.gtin ? props.currentFiltersValue.gtin.join('\n') : '',
      category: _.defaultTo(props.currentFiltersValue.category, ''),
      categoryTnved: _.defaultTo(props.currentFiltersValue.categoryTnved, ''),
      packages: props.currentFiltersValue.packages ? props.currentFiltersValue.packages.join('\n') : '',
      isExemplar: _.defaultTo(props.currentFiltersValue.isExemplar, null),
      attributes: props.currentFiltersValue.attributes
        ? _.map(_.cloneDeep(props.currentFiltersValue.attributes), item => ({ ...item, attrId: _.uniqueId('attrId_') }))
        : [{ ...attributeItem, attrId: _.uniqueId('attrId_') }],
    })
  };

  const checkFilters = (...elems) => Object.values(
    _.pick(props.currentFiltersValue, elems))
    .some(item => (item !== ('' || null)));

  // при открытии модалки открываются вкладки с установленными фильтрами.  
  const initOpenFilterTabs = () => {
    setIsOpenStandartFilters(checkFilters(
      'photo', 'isValid', 'packingPurpose', 'gtin', 'name')
      || props.currentFiltersValue.created.from
      || props.currentFiltersValue.publicationTime.from
      || checkFilters('brand', 'category', 'categoryTnved', 'packages'));
    setIsOpenAdvancedFilters(checkFilters('isExemplar'));
    setIsOpenAttributeFilters(props.currentFiltersValue.attributes ? true : false);

    setIsOpenAdditionalFields(checkFilters(
      'brand', 'category', 'categoryTnved', 'packages',
    ));
  };

  const onValueChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleClick = (event) => {
    let element = document.getElementById(props.parentId);
    setAnchorEl(!!element ? element : event.currentTarget);
    initializeFilters();
    initOpenFilterTabs();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetFilters = () => {
    props.resetFilters();
    handleClose();
  };

  const clearFilters = () => {
    props.resetFilters();
  };

  const makeGtinArray = (gtinString) => {
    return _.compact(gtinString.split(new RegExp('[\\n;,\\s+]', 'g')),)
  };

  const getAttributeFilters = () => {
    const attrArray = attributes.map((elem) => {
      if (elem.text) {
        return { id: elem.id, text: elem.text, value: elem.value }
      } else return null;
    })
    return _.compact(attrArray);
  };

  const handleSubmit = () => {
    props.onFiltersSubmit({
      packingPurpose: packingPurpose ? packingPurpose.value : null,
      photo: (photo !== null) ? photo : null,
      isValid: (isValid !== null) ? isValid : null,
      created: created.from ? created : {
        from: null,
        to: null,
      },
      publicationTime: publicationTime.from ? publicationTime : {
        from: null,
        to: null,
      },
      name: name ? name : null,
      brand: brand ? brand : null,
      gtin: gtin ? makeGtinArray(gtin) : null,
      category: category ? category : null,
      categoryTnved: categoryTnved ? categoryTnved : null,
      packages: packages ? makeGtinArray(packages) : null,
      isExemplar: (isExemplar !== null) ? isExemplar : null,
      attributes: isSelectedAttribute ? getAttributeFilters() : null,
    })
    handleClose();
  };

  const getDataTitle = (name, title) => {
    let dataTitle = t(title);
    if (filters[name] && filters[name].from && filters[name].to) {
      const startDate = filters[name].from;
      const endDate = filters[name].to;
      dataTitle = (
        moment(startDate).format('DD.MM.YYYY')
        === moment(endDate).format('DD.MM.YYYY')
      )
        ? moment(startDate).format('DD.MM.YYYY')
        : `${moment(startDate).format('DD.MM.YYYY')} - ${moment(endDate).format(
          'DD.MM.YYYY',
        )}`;
    }
    return dataTitle;
  };

  const onDateFilterDissmiss = (name) => {
    this.setState({
      [name]: { from: '', to: '' },
    })
  };

  const toggleAdditionalFields = () => {
    setIsOpenAdditionalFields(!isOpenAdditionalFields);
  }

  const addItem = () => {
    if (isSelectedAttributes) {
      setFilters(
        {
          ...filters,
          attributes: [
            ...attributes,
            {
              ...attributeItem,
              attrId: _.uniqueId('attrId_'),
            }]
        });
    } else return;
  };

  const deleteItem = () => {
    setFilters(
      {
        ...filters,
        attributes:
          attributes.slice(0, -1),
      });
  };

  const updateItem = (id, fieldData) => {
    const attributeArray = _.cloneDeep(attributes);
    _.forEach(attributeArray, (v, k) => {
      if (v.attrId === id) {
        attributeArray[k] = _.assign({}, v, fieldData);
      }
    });
    setFilters(
      {
        ...filters,
        attributes: attributeArray
      });
  };

  const renderAttributeFields = () => {
    return _.map(attributes, (value) => {
      const defaultProps = {
        data: value,
        key: value.attrId,
        updateItem,
      };
      return (
        < AttributeFilter {...defaultProps} />
      )
    });
  };

  return (
    <div>
      <MuiThemeProvider theme={ResetFilterButton}>
        <Button
          aria-owns={open ? 'filter-popper' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{
            textTransform: 'none', padding: '10px 24px',
            outline: '1px solid #dcdcdc'
          }}>
          <IconNewFilter />
          < FilterBtnTitle>
            {t('Фильтры')}
          </FilterBtnTitle>
        </Button>
      </MuiThemeProvider>
      {!props.isNotActiveFilters && (
        <MuiThemeProvider theme={CancelFilterButton}>
          <Tooltip title={t('Сбросить фильтры')}>
            <Button
              onClick={clearFilters}
            >
              <IconCloseWhite />
            </Button>
          </Tooltip>
        </MuiThemeProvider>
      )}
      <MuiThemeProvider theme={FilterPopover}>
        <Popover
          id="filter-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <FilterMainTitle>
            {t('Фильтры')}
          </FilterMainTitle>

          <FilterMainContent>
            <MuiThemeProvider theme={FilterAccordion}>
              <Accordion
                square
                defaultExpanded={isOpenStandartFilters}
              >
                <AccordionSummary
                  expandIcon={< IconDropDown />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <FilterItem>{t('Стандартный поиск')}</FilterItem>
                </AccordionSummary>
                <AccordionDetails>
                  <FilterItemContent>
                    <div style={{ padding: '0 36px' }}>
                      <PhotoFilter
                        type='modal'
                        onValueChange={onValueChange}
                        currentValue={props.currentFiltersValue.photo}
                      />
                      <IsValidFilter
                        type='modal'
                        onValueChange={onValueChange}
                        currentValue={props.currentFiltersValue.isValid}
                      />
                      <DateContainer>
                        <div>
                          <FilterTitle>
                            <h6>{t('Регистрация')}</h6>
                          </FilterTitle>
                          <FilterContentPopup>
                            <DateFilterContainer>
                              <DateFilterField id="datePopupFilter" >
                                <DateFilter
                                  type='modal'
                                  onValueChange={onValueChange}
                                  parentId="datePopupFilter"
                                  currentValue={props.currentFiltersValue.created}
                                  onAccept={() => { }}
                                  onDismiss={onDateFilterDissmiss}
                                  name={'created'}
                                  getDataTitle={getDataTitle}
                                  defaultCreated={filters.created && !filters.created.from}
                                />
                              </DateFilterField>
                            </DateFilterContainer>
                          </FilterContentPopup>
                        </div>
                        <div>
                          <FilterTitle>
                            <h6>{t('Публикация')}</h6>
                          </FilterTitle>
                          <FilterContentPopup>
                            <DateFilterContainer>
                              <DateFilterField id="publicationTimePopupFilter">
                                <DateFilter
                                  type='modal'
                                  onValueChange={onValueChange}
                                  parentId="publicationTimePopupFilter"
                                  currentValue={props.currentFiltersValue.publicationTime}
                                  onAccept={() => { }}
                                  onDismiss={onDateFilterDissmiss}
                                  name={'publicationTime'}
                                  getDataTitle={getDataTitle}
                                  defaultPublication={filters.publicationTime && !filters.publicationTime.from}
                                />
                              </DateFilterField>
                            </DateFilterContainer>
                          </FilterContentPopup>
                        </div>
                      </DateContainer>

                      <PackingTypeFilter
                        type='modal'
                        onValueChange={onValueChange}
                        currentValue={props.currentFiltersValue.packingPurpose}
                      />

                      <GtinFilter
                        type='modal'
                        onValueChange={onValueChange}
                        currentValue={props.currentFiltersValue.gtin}
                        setErrorGtin={setErrorGtin}
                      />

                      <FilterTitle>
                        <h6>{t('Торговое наименование товара')}</h6>
                      </FilterTitle>
                      <FilterContentPopup>
                        <MuiThemeProvider theme={defaultFilterInput}>
                          <CustomInput
                            label={t('Введите наименование товара')}
                            onChange={(value) => onValueChange('name', value)}
                            cleanButtonStyles={{ marginTop: -15, display: 'none' }}
                            onBlur={false}
                            value={filters.name}
                            name="name-filter"
                          />
                        </MuiThemeProvider>
                      </FilterContentPopup>

                      {isOpenAdditionalFields &&
                        <div>
                          <BrandFilter
                            type='modal'
                            onValueChange={onValueChange}
                            currentValue={props.currentFiltersValue.brand}
                          />
                          <CategoryFilter
                            type='modal'
                            onValueChange={onValueChange}
                            currentValue={props.currentFiltersValue.category}
                          />
                          <FilterTitle>
                            <h6>{t('Код ТНВЭД')}</h6>
                          </FilterTitle>
                          <FilterContentPopup>
                            <MuiThemeProvider theme={defaultFilterInput}>
                              <CustomInput
                                label={t('Введите код')}
                                onChange={(value) => onValueChange('categoryTnved', value)}
                                cleanButtonStyles={{ marginTop: -15, display: 'none' }}
                                onBlur={false}
                                value={categoryTnved}
                                name="tnved-filter"
                              />
                            </MuiThemeProvider>
                          </FilterContentPopup>
                          <PackagesFilter
                            type='modal'
                            onValueChange={onValueChange}
                            currentValue={props.currentFiltersValue.packages}
                            setErrorGtins={setErrorGtins}
                          />
                        </div>
                      }
                    </div>
                    <MuiThemeProvider theme={ButtonAddFieldsTheme}>
                      <Button
                        fullWidth
                        onClick={toggleAdditionalFields}
                      >
                        {!isOpenAdditionalFields ? t('Показать все поля') : t('Скрыть часть полей')}
                      </Button>
                    </MuiThemeProvider>
                  </FilterItemContent>
                </AccordionDetails>
              </Accordion>
            </MuiThemeProvider>
            <MuiThemeProvider theme={FilterAccordion}>
              <Accordion
                square
                defaultExpanded={isOpenAdvancedFilters}
              >
                <AccordionSummary
                  expandIcon={<IconDropDown />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <FilterItem>{t('Дополнительный поиск')}</FilterItem>
                </AccordionSummary>
                <AccordionDetails>
                  <FilterItemContent>
                    <div style={{ padding: '0 36px' }}>
                      <IsExemplarFilter
                        type='modal'
                        onValueChange={onValueChange}
                        currentValue={props.currentFiltersValue.isExemplar}
                      />
                    </div>
                  </FilterItemContent>
                </AccordionDetails>
              </Accordion>
            </MuiThemeProvider>

            <MuiThemeProvider theme={FilterAccordion}>
              <Accordion
                square
                defaultExpanded={isOpenAttributeFilters}
              >
                <AccordionSummary
                  expandIcon={<IconDropDown />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <FilterItem>{t('Расширенный поиск')}</FilterItem>
                  <MuiThemeProvider theme={DefaultTooltipCenter}>
                    <Tooltip
                      placement="bottom"
                      title={t('Расширенный поиск доступен только для опубликованных товаров')}
                    >
                      <ExemplarIconContainer height='24px'>
                        <IconQuestion />
                      </ExemplarIconContainer>
                    </Tooltip>
                  </MuiThemeProvider>
                </AccordionSummary>
                <AccordionDetails>
                  <FilterItemContent>
                    <div style={{ padding: '0 36px' }}>
                      {renderAttributeFields()}
                      <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '20px' }}>
                        <MuiThemeProvider theme={FilterBtnReset}>
                          <Button
                            disabled={!isSelectedAttributes}
                            onClick={addItem}
                            name="addButton"
                          >
                            <IconPlusNew style={{ marginRight: '8px' }} />
                            {t('Добавить атрибут')}
                          </Button>
                        </MuiThemeProvider>
                        {attributes && attributes.length > 1 && (
                          <MuiThemeProvider theme={DeleteAttributeButton}>
                            <Button
                              onClick={deleteItem}
                            >
                              <IconTrash />
                            </Button>
                          </MuiThemeProvider>
                        )}
                      </div>
                    </div>
                  </FilterItemContent>
                </AccordionDetails>
              </Accordion>
            </MuiThemeProvider>
          </FilterMainContent>
          <FilterMainBtns>
            <MuiThemeProvider theme={FilterBtnReset}>
              <Button
                onClick={resetFilters}
                disabled={disabled}
              >
                {t('Сбросить')}
              </Button>
            </MuiThemeProvider>
            <Button
              disabled={disabled || !!errorGtin || !!errorGtins}
              onClick={handleSubmit}
            >
              {t('Применить')}
            </Button>
          </FilterMainBtns>
        </Popover>
      </MuiThemeProvider>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {},
    dispatch,
  );
};

FiltersCatalogPopup.propTypes = {
  currentFiltersValue: PropTypes.object.isRequired,
  resetFilters: PropTypes.func.isRequired,
  onFiltersSubmit: PropTypes.func.isRequired,
  parentId: PropTypes.string.isRequired,
  isNotActiveFilters: PropTypes.bool.isRequired,
};

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps),
)(FiltersCatalogPopup);

