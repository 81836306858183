import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';

export const ProductViewMediaItemsTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ProductViewMediaCount = styled.div`
  font-size: 14px;
  color: #52535a;
  font-weight: bold;
  margin: 0 auto;
  transform: translateX(20px);
`;
export const ProductViewMediaText = styled.div`
  font-size: 14px;
  color: #868686;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-top: 50px;
`;
export const ProductImageDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;  
`;
export const ProductViewMediaDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paper: {
        maxHeight: '770px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
        borderRadius: '4px',
        padding: '25px 15px',
        boxSizing: 'border-box',
        position: 'relative',
      },
      paperWidthSm: {
        minWidth: '749px',
        maxWidth: '749px',
      },
    },
    MuiFormControlLabel: {
      root: {
        minWidth: '120px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '0',
        textAlign: 'right',
      },
    },
    MuiDialogContent: {
      root: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0 70px',
        '&:first-child': {
          paddingTop: '0',
        },
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
      },
      text: {
        padding: '3px',
      },
      label: {
        color: '#52535A!important',
      },
      textPrimary: {
        background: 'none',
        color: '#52535A',
        width: 'auto',
        padding: '3px',
        height: '30px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '20px',
      },
      textSecondary: {
        background: 'none',
        color: '#52535A',
        width: 'auto',
        padding: '3px',
        height: '30px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '20px',
      },
    },
  },
});
