import styled from 'styled-components';
import { ProfileTabCont } from '../../Profile.styled';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const ProductManagementCont = styled(ProfileTabCont)`
  padding: 20px 0 0;
  height: ${props => props.height === 4 ? 'auto' : 'calc(100vh - 195px)'};
`;
export const TabEmpty = styled.div`
  flex-grow: 1;
  border-bottom: 1px solid #E7E7E7;
`;
export const SmallTabsNew = createMuiTheme({
  typography: {},
  overrides: {
    MuiTabs: {
      root: {
        margin: '0',
        minHeight: '51px',
        padding: '0',
        overflow: 'visible',
      },
      indicator: {
        backgroundColor: '#FFF',
        height: '1px',
        bottom: '-1px',
        zIndex: '9',
      },
      fixed: {
        overflow: 'visible!important',
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'SegoeUI',
        minHeight: '40px',
        '&$selected': {
          borderBottom: `1px solid ${colors.Tabs.tabLine}`,
        },
        borderBottom: `1px solid #E7E7E7`,
        // '&$disabled': {
        //   opacity: '1!important',
        // },
        textTransform: 'inherit;',
        fontSize: '18px!important',
        lineHeight: '20px',
        //color: '#63666A!important',
        letterSpacing: '0!important',
      },
      fullWidth: {
        flexGrow: '0',
        minWidth: 'max-content',
        padding: '15px 40px',
      },
      labelContainer: {
        padding: "0!important"
      },
      textColorInherit: {
        opacity: '1',
        color: `${colors.Common.textGrey}`,
        '&$selected': {
          color: `${colors.Common.background}`,
        },
        '&$disabled': {
          color: '#e7e7e7',
          opacity: '1',
        },
      },

      disabled: {},

    },
  },
});
