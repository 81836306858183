import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../../common/styles/Common.colors';

export const customInputLeft = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      input: {
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
        '&:required': {
          borderRadius: '4px 0 0 4px',
          borderLeft: '4px solid #E32A38',
          "&[valid='false']": {
            borderLeft: `4px solid #E32A38`,
          },
          "&[valid='true']": {
            borderLeft: 'none',
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiSelect: {
      root: {
        padding: '15px 47px 15px 15px !important',
      },
      icon: {
        right: '16px',
        '&$disabled': {
          '& path': {
            stroke: '#AAA9A9',
          },
        },
      },
      select: {
        minWidth: '22px',
        maxWidth: 'auto',
        border: '1px solid #C9C7C7',
        borderRadius: '4px 0 0 4px',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$disabled': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
          },
        },
        '&:focus': {
          borderRadius: '4px 0 0 4px',
          backgroundColor: '#ffffff',
          border: `1px solid ${colors.Input.border}`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        fontSize: '16px',
        lineHeight: '24px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(19px, 20px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      filled: {
        color: `${colors.Common.textGrey}`,
      },
      disabled: {},
    },
    MuiInputBase: {
      root: {
        "&[required][valid='false']": {
          '& div': {
            borderRadius: '0',
            borderLeft: 'none',
          },
        },
        "&[required][valid='false']$disabled": {
          '& div': {
            borderRadius: '4px 0 0 4px',
            borderLeft: '1px solid #DCDCDC',
          },
        },
        "&$error": {
          borderRadius: '4px 0 0 4px',
          '& div': {
            border: '1px solid #E32A38 !important',
          },
        },
      },
      input: {
        fontWeight: 'normal',
        height: 'auto',
        color: `${colors.Common.textColor}`,
        fontFamily: 'SegoeUI',
        fontSize: '16px',
        lineHeight: '24px',
      },
      multiline: {
        padding: '0',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        '&$focused': {
          color: `${colors.Common.textGrey} !important`,
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '403px!important',
        overflowY: 'auto',
        padding: '8px 0',
        borderTop: '1px solid #AAA9A9',
        margin: '8px 0 0',
      },
      elevation1: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
      }
    },
    MuiMenuItem: {
      root: {
        padding: '16px 20px 16px 24px !important',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: `${colors.Common.textColor}`,
        fontFamily: 'SegoeUI',
      },
    },
    MuiList: {
      root: {
        width: '100%!important',
        paddingRight: '0!important',
      }
    }
  },
});
