import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { HeaderLogoUZ } from '../../common/icons/customIcons';
import {
  MessageDesktopContainer,
  MessageDesktopContent,
  MessageDesktopTitle,
  MessageDesktopText,
} from './MessageDesktopModal.styled';
import colors from '../../common/styles/Common.colors';

const MessageDesktopModal = (props) => {
  const { t } = props;

  return (
    <MessageDesktopContainer>
      <MessageDesktopContent>
        <HeaderLogoUZ color={colors.Common.background} />
        <MessageDesktopTitle>
          {t('Разрешение не поддерживается приложением')}
        </MessageDesktopTitle>
        <MessageDesktopText>
          {t(`Чтобы начать работу в личном кабинете вам необходимо использовать десктопную версию`)}
        </MessageDesktopText>
      </MessageDesktopContent>
    </MessageDesktopContainer>
  )
}

export default withTranslation()(MessageDesktopModal);
