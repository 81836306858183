import * as React from 'react';
import { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import {
  MuiThemeProvider,
  TextField,
  FormControl,
} from '@material-ui/core';
import _ from 'lodash';
import { client } from '../../../api/Client';
import { IconDropDown } from '../../../common/icons/customIcons';
import {
  AttributeSelect,
  FilterTitle,
  FilterContentPopup,
} from './Filters.styled';
import { throwNotification } from '../../../common/structure';

const AttributeFilter = (props) => {
  const [attrName, setAttrName] = useState({ id: props.data.id, text: props.data.text });
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [attrValue, setAttrValue] = useState(props.data.value);
  const [inputAttrValue, setInputAttrValue] = useState('');
  const [optionsValue, setOptionsValue] = useState([]);
  const [isLoadingValue, setIsLoadingValue] = useState(false)
  const { t, updateItem, data } = props;

  //Получение атрибутов с бэка в ответ на пользовательский ввод от 3-х символов.
  useEffect(() => {
    if (inputValue && inputValue.length > 2 && attrName && !attrName.text) {
      const message = 'Ошибка получения значений атрибутов';
      setIsLoading(true);
      client()
        .post('/attr/search', JSON.stringify({ 'attribute': { 'name': inputValue } }))
        .then((res) => {
          if (!res.error) {
            setOptions(_.map(res, item => ({ id: String(item.AttrId), text: item.Name })));
          } else {
            props.throwError(t(message));
          }
        })
        .catch((err) => props.throwError(t(message)))
        .finally(() => setIsLoading(false))
    }
    if (!inputValue) {
      setOptions([]);
    }
    if (!attrName) {
      setAttrName({ id: '', text: '' });
    }
    // eslint-disable-next-line
  }, [inputValue, attrName]);

  //Получение значений атрибутов с бэка в ответ на пользовательский ввод от 1 символа.
  useEffect(() => {
    if (inputAttrValue && attrName && attrName.id) {
      const message = 'Ошибка получения значений атрибутов';
      setIsLoadingValue(true);
      client()
        .post('attr/search-value', JSON.stringify({ 'attribute': { 'id': attrName.id, 'value': inputAttrValue } }))
        .then((res) => {
          if (!res.error) {
            setOptionsValue(_.map(res, item => item.Value));
          } else {
            props.throwError(t(message));
          }
        })
        .catch((err) => props.throwError(t(message)))
        .finally(() => setIsLoadingValue(false))
    }
    if (!inputAttrValue) {
      setOptionsValue([]);
    }
    // eslint-disable-next-line
  }, [inputAttrValue, attrName]);

  //При выборе атрибута или значения атрибута (attrName, attrValue), он записывается в объект фильтров;
  // также очищается поле значения атрибута (attrValue) если сам атрибут был сброшен. 
  useEffect(() => {
    if (attrName && attrName.id) {
      updateItem(
        data.attrId,
        { id: attrName.id, text: attrName.text, value: attrValue ? attrValue : '' },
      );
    } else {
      updateItem(
        data.attrId,
        { id: '', text: '', value: '' },
      );
    }
    if (!attrName && attrValue) {
      setAttrValue('');
    }
    // eslint-disable-next-line
  }, [attrName, data.attrId, attrValue]);

  return (
    <>
      <FilterTitle>
        <h6>{t('Атрибут')}</h6>
      </FilterTitle>
      <FilterContentPopup>
        <MuiThemeProvider theme={AttributeSelect}>
          <FormControl fullWidth={true}>
            <Autocomplete
              value={attrName}
              options={options}
              loading={isLoading}
              autoHighlight
              onChange={(event, newValue) => {
                setAttrName(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              getOptionLabel={(option) => option.text}
              // getOptionSelected={(option, value) => option.text === value.text}
              popupIcon={<IconDropDown />}
              loadingText={t('Загружаем подходящие значения...')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Выберите атрибут')}
                  variant="standard"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                />
              )}
              noOptionsText={t('Нет совпадений')}
            />
          </FormControl>
        </MuiThemeProvider>
      </FilterContentPopup>
      <FilterTitle>
        <h6>{t('Значение атрибута')}</h6>
      </FilterTitle>
      <FilterContentPopup>
        <MuiThemeProvider theme={AttributeSelect}>
          <FormControl fullWidth={true}>
            <Autocomplete
              value={attrValue}
              options={optionsValue}
              loading={isLoadingValue}
              disabled={!attrName || !attrName.text}
              autoHighlight
              onChange={(event, newValue) =>
                setAttrValue(newValue)
              }
              onInputChange={(event, newInputValue) => {
                setInputAttrValue(newInputValue);
              }}
              getOptionLabel={(option) => option}
              popupIcon={<IconDropDown />}
              loadingText={t('Загружаем значения атрибута...')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Выберите значение')}
                  variant="standard"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                />
              )}
              noOptionsText={t('Нет совпадений')}
            />
          </FormControl>
        </MuiThemeProvider>
      </FilterContentPopup>
    </>
  )


};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwError: (message) => throwNotification(message, 'error'),
    },
    dispatch,
  );
};

AttributeFilter.propTypes = {
  updateItem: PropTypes.func.isRequired,
  key: PropTypes.string,
  data: PropTypes.object.isRequired,
  throwError: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps))(AttributeFilter);
