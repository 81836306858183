import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../common/styles/Common.colors';

export const GridActionsView = styled.div`
  padding: 0 20px 0 0;
  flex-shrink: 0;
  @media (max-width: 1366px) {
    padding: 0 16px 0 0;
  }
  // @media (min-width: 1400px) and (max-width: 1600px) {
  //   padding: 0 25px 0 0;
  // }
  display: flex;
  justify-content: space-between;
  //min-width: 300px
`;

export const GridExamplarFilter = styled.div`
  
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #52535a;
  align-items: center;
  
`;

export const GridSelect = styled.div`
  min-width: 210px;
  // @media (max-width: 1600px) {
  //     margin-left: 25px;
  // }
`;
export const ResetFilterButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      label: {
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
        textTransform: "none"
      },
      root: {
        borderRadius: '4px',
        border: '1px solid #DCDCDC',
        outline: 'none !important',
        padding: '9px 23px !important',
        marginRight: '20px',
        '@media (max-width: 1366px)': {
          marginRight: '16px',
        },
        '&:hover': {
          backgroundColor: '#F5F5F5',
          borderColor: '#F5F5F5',
        },
        '&:last-child': {
          marginRight: '0',
          padding: '9px !important',
          minWidth: '44px',
        },
        '&:first-child': {
          marginRight: '0',
          padding: '9px 23px !important',
          minWidth: 'auto',
        },
      }
    },
  },
});
export const AddProductGridBtns = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: '#F6F42E',
        width: '40px',
        maxWidth: '40px',
        height: '40px',
        marginRight: '10px',
      },
      textPrimary: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '21px',
        color: '#63666A',
        textTransform: 'none',
      },
    },
  },
});

export const GridTable = styled.div`
  box-shadow: 0px 1px 8px rgba(41, 49, 53, 0.1);
  border-radius: 4px;
`;
export const ColumnCell = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.Common.textColor};
  padding: 10px 12px;
  width: 100%;
  height: 100%;
`;
export const ColumnCellStatus = styled.div`
  position: relative;
  font-size: 14px;
  color: ${colors.Common.textColor};
  width: 100%;
  height: 100%;
`;
export const ColumnCellProfile = styled(ColumnCell)`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.Common.textColor};
  padding: 18px 12px;
  width: 100%;
  height: 100%;
`;
export const ColumnCellPhoto = styled(ColumnCell)`
  padding: 4px;
  justify-content: center;
  height: 100%;
  align-items: center;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.4s ease;
  // &:hover {
  //   background-color: #fff;
  // }  
`;
export const ColumnHeaderDate = styled(ColumnCell)`
  padding: 10px 12px;
`;
export const ColumnHeaderPackage = styled(ColumnCell)`
  padding: 10px 12px;
`;
export const PackageLine = styled.div`
  display: flex;
  align-items: center; 
  transition: color 0.4s ease;
  margin-top: 2px;
  &:first-child {
    margin-top: 0;
  }
  &:hover {
    color: #000;
  }
  span {
    padding-left: 8px;
  }
`;
export const PackageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

`;
export const NameCount = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  span {
    padding-left: 2px;
    display: flex;
    align-items: center;
  }
`;
export const Sorted = styled.span`
  color: #fff;
`;
export const SortIcon = styled.span`
  position: absolute;
  left: 10px;
  cursor: pointer;
`;
export const PointerName = styled.span`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => (props.disabled ? colors.Common.textColor : colors.Buttons.textButton.color)};
  white-space: nowrap;
  width: -webkit-fill-available;
  transition: 0.4s ease;
  &:hover {
    color: ${colors.Buttons.textButton.colorHovered};
  }
  &:active {
    color: ${colors.Buttons.textButton.colorHovered};
  }
`;
export const VersionBlock = styled.div`
  cursor: pointer;
  font-size: 10px;
  color: #b8b8b8;
  font-weight: bold;
  display: flex;
  margin-left: 15px;
  span {
    padding-left: 4px;
  }
`;

export const ProductContainer = styled.div`
  height: calc(100vh - 80px);
  background-color: #f8f8f8;
  padding: 28px 40px;
`;
