import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { authentificationReducer } from './pages/authentication/ducks/Authentification.reducers';
import { registrationReducer } from './pages/registration/ducks/Registration.reducers';
import { productsReducer } from './pages/products/ducks/Products.reducers';
import { productReducer } from './pages/product/ducks/Product.reducers';
import { profileReducer } from './pages/profile/ducks/Profile/Profile.reducers';
import { profileStatusReducer } from './pages/profile/ducks/ProfileStatus/ProfileStatus.reducers';
import { subAccountReducer } from './pages/profile/ducks/SubAccount/SubAccount.reducers';
import { tradeMarksReducer } from './pages/profile/ducks/TradeMarks/TradeMarks.reducers';
import { parentCompanyReducer } from './pages/profile/ducks/ParentCompany/ParentCompany.reducers';
import { subProfilesReducer } from './pages/profile/ducks/SubProfiles/SubProfiles.reducers';
import { notificationReducer } from './common/structure/notification/Notification.reducer';
import { catalogReducer } from './common/catalog/Catalog.reducers';
import { gs1Reducer } from './pages/profile/ducks/GS1/GS1.reducers';
import { analyticReducer } from './pages/analytic/ducks/Analytic.reducers';
import { siteProfileReducer } from './pages/profile/ducks/SiteProfile/SiteProfile.reducer';
import { catalogGoodsReducer } from './pages/catalog/ducks/Catalog.reducers';
import { companiesReducer } from './pages/companies/ducks/Companies.reducers';

export const reducers = {
  authentificationReducer,
  registrationReducer,
  productsReducer,
  productReducer,
  profileReducer,
  profileStatusReducer,
  subAccountReducer,
  tradeMarksReducer,
  parentCompanyReducer,
  subProfilesReducer,
  notificationReducer,
  catalogReducer,
  gs1Reducer,
  analyticReducer,
  siteProfileReducer,
  catalogGoodsReducer,
  companiesReducer
};

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    ...reducers,
  });
