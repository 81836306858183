import { createGlobalStyle } from 'styled-components';
import colors from './styles/Common.colors';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    min-height:100vh;
    font-family: "SegoeUI";
    overflow-x: hidden;
    font-size: 14px;
    color: ${colors.Common.textColor};
    box-sizing: border-box;
    font-weight: normal;
  }
  input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        color: ${colors.Common.textColor}!important;
        transition: background-color 5000s ease-in-out 0s;
    }
    button, input, h3{
        font-family: "SegoeUI"!important;
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {        
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }    
    ::-webkit-scrollbar-track {
        background-color: #FFFFFF;
        border: 1px solid #E7E7E7;
    }   
    ::-webkit-scrollbar-track:vertical {      
        border-bottom: 1px solid transparent;
    }
    ::-webkit-scrollbar-thumb {      
        border-radius: 8px;
        background-color: #C9C7C7;
        border: 4px solid #FFFFFF;
    }    
    // ::-webkit-scrollbar-thumb:hover{
    //     background-color: ${colors.Scroll.background};
    // }
    ::-webkit-scrollbar {
        width: 16px;
        height: 16px;
    }
    ::-webkit-scrollbar-corner {
        background-color: #FFFFFF;
        border-bottom: 1px solid #E7E7E7;
        border-right: 1px solid #E7E7E7;
        border-left: 1px solid transparent;
        border-top: 1px solid transparent;
    }
    div {
        scrollbar-width: thin;
        scrollbar-color: #C9C7C7 #FFFFFF;          
    }    
    `;
