import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEqual, isNull } from 'lodash';
import {
  Button,
  MuiThemeProvider,
  Popover,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { SimpleTooltip } from '../../../../../components';
import {
  IconClose,
  IconDropDown,
  IconErrorSmall,
  IconNewFilterSmall,
  IconNewFilterSmallActive,
} from '../../../../../common/icons/customIcons';
import {
  defaultFilterInput,
  DefaultFilterPopover,
  defaultFilterSelect,
  FilterBtn,
  FilterBtnReset,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
} from './Filters.styled';

class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      // status: this.props.currentValue ? this.props.currentValue.join('\n') : '',
      status: this.props.currentValue || '',
      error: '',
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        status: '',
        error: '',
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state);
  }

  onValueChange = (e) => {
    this.setState({
      status: e.target.value,
      error: '',
    });
  };

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAccept = () => {
    if (this.validatestatuss(this.state.status)) {
      this.setState({
        anchorEl: null,
        currentValue: this.state.status,
      });
      this.props.onAccept('status', this.state.status);
    }
  };

  validatestatuss = (statussArr) => {
    let valid = true;
    return valid;
  };

  handleDissmiss = () => {
    this.setState({ anchorEl: null, status: '', error: '' });
    this.props.onDismiss('status');
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    
    return (
      <div>
        <MuiThemeProvider theme={FilterBtn}>
          <FilterButton>
            <Button
              aria-owns={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
              {isNull(this.props.currentValue) ? (
                <IconNewFilterSmall />
              ) : (
                <IconNewFilterSmallActive />
              )}
            </Button>
          </FilterButton>
        </MuiThemeProvider>
        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Статус пользователя')}</h6>

              <MuiThemeProvider theme={FilterBtn}>
                <IconButton className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </IconButton>
              </MuiThemeProvider>
            </FilterTitle>
            <FilterContent>
              <MuiThemeProvider theme={defaultFilterSelect}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">{t('Статус пользователя')}</InputLabel>
                  <Select
                    labelId="status-label"
                    id="status"
                    value={this.state.status}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    }}
                    onChange={this.onValueChange}
                    IconComponent={IconDropDown}
                    style={{ color: 'black' }}>
                    <MenuItem value={'deleted'}>{t('Удалён')}</MenuItem>
                    <MenuItem value={'blocked'}>{t('Заблокирован')}</MenuItem>
                    <MenuItem value={'active'}>{t('Активен')}</MenuItem>
                  </Select>
                </FormControl>
              </MuiThemeProvider>
              {!!this.state.error &&
                SimpleTooltip(this.state.error, <IconErrorSmall />)}
            </FilterContent>
            <FilterBtns>
              <MuiThemeProvider theme={FilterBtnReset}>
                <Button
                  disabled={!this.state.status}
                  onClick={this.handleDissmiss}>
                  {t('Сбросить')}
                </Button>
              </MuiThemeProvider>
              <Button disabled={!this.state.status} onClick={this.handleAccept}>
                {t('Применить')}
              </Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </div>
    );
  }
}

Status.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
  parentId: PropTypes.string.isRequired,
};

export default withTranslation()(Status);
