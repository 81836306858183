import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../../common/styles/Common.colors';

export const ProductDivider = styled.div`
  height: 40px;
`;
export const ProductTypeSelectField = styled.div`
  display: flex;
  width: 100%;
`;
export const IconEditWrapper = styled.div`
  height: 72px;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;
export const ProductTypeSelectLeft = styled.div`
  min-width: 60px;
  flex-shrink: 0;
`;
export const ProductTypeSelectRight = styled.div`
  flex-grow: 1;
`;
export const IconSearchHolder = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-3px);
  left: 75px;
`;
export const customInputTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #C9C7C7',
        borderLeft: 'none',
        borderRadius: '0px 4px 4px 0px',
        '&:hover': {
          border: '1px solid #AAA9A9',
          borderLeft: 'none',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: `${colors.Common.textGrey}`,
        },
        '&$error': {
          border: '1px solid #E32A38 !important',
        },
      },
      input: {
        padding: '23px 44px 7px 15px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
        textOverflow: 'ellipsis',
        '&:required': {
          borderRadius: '0px 4px 4px 0px',
          borderLeft: '4px solid #E32A38',
          "&[valid='true']": {
            borderLeft: 'none',
          },
          "&[valid='false']": {
            borderLeft: '4px solid #E32A38',
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        fontSize: '16px',
        lineHeight: '24px',
        width: 'calc(100% - 16px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px, 32px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      shrink: {
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      filled: {
        color: `${colors.Common.textGrey}`,
      },
      disabled: {},
    },
    MuiInputBase: {
      input: {
        fontWeight: 'normal',
        height: 'auto',
        color: `${colors.Common.textColor}`,
        fontFamily: 'SegoeUI',
      },
      multiline: {
        padding: '0',
      },
      root: {
        '&$disabled': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
            borderLeft: 'none',
          },
        },
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        '&$focused': {
          color: `${colors.Common.textGrey}`,
        },
        '&$error': {
          color: `${colors.Common.textGrey}`,
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});
export const customInputThemeSearch = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderLeft: 'none',
        borderRadius: '0px 3px 3px 0px',
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      input: {
        padding: '21px 55px 6px',
        lineHeight: '21px',
        fontFamily: 'SegoeUI',
        '&:required': {
          borderRadius: '0px 3px 3px 0px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(55px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(55px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        color: '#868686',
      },
      disabled: {},
    },
    MuiInputBase: {
      input: {
        fontWeight: '600',
        height: 'auto',
        color: '#52535A',
        fontFamily: 'SegoeUI',
      },
      multiline: {
        padding: '0',
      },
      root: {
        '&$disabled': {
          backgroundColor: '#F9F9F9',
          color: '#52535A!important',
        },
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686!important',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});
export const customInputRight = createMuiTheme({
  typography: {},
  overrides: {
    MuiInputBase: {
      root: {
        border: '1px solid #C9C7C7',
        borderLeft: 'none',
        borderRadius: '0 4px 4px 0',
        marginTop: '16px!important',
        fontFamily: 'SegoeUI',
        '&:hover': {
          border: '1px solid #AAA9A9',
          borderLeft: 'none',
        },
        '&$disabled': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
            borderLeft: 'none',
          },
          '& path': {
            stroke: '#AAA9A9',
          },
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
        '&$error': {
          border: '1px solid #E32A38!important',
          borderLeft: 'none',
        },
      },
      input: {
        padding: '23px 92px 7px 15px',
        fontSize: '16px',
        color: `${colors.Common.textColor}`,
        lineHeight: '24px',
        height: 'auto',
        fontFamily: 'SegoeUI',
        textOverflow: 'ellipsis',
        '&::placeholder': {
          color: `${colors.Common.textGrey} !important`,
          opacity: '1!important',
          transform: 'translateY(-8px)',
        },
        '&:required': {
          borderRadius: '0 4px 4px 0',
          borderLeft: '4px solid #E32A38',
          "&[valid='true']": {
            borderLeft: 'none',
          },
          "&[valid='false']": {
            borderLeft: `4px solid #E32A38`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '16px',
        lineHeight: '24px',
        width: 'calc(100% - 54px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px, 32px) scale(1)',
        color: `${colors.Common.textGrey} !important`,
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey} !important`,
      },
      filled: {
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey} !important`,
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        fontFamily: 'SegoeUI',
        '&$focused': {
          color: `${colors.Common.textGrey} !important`,
          '&$disabled': {
            color: `${colors.Common.textGrey} !important`,
          },
        },
      },
      disabled: {},
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        padding: '0',
        paddingTop: '16px',
        paddingBottom: '16px',
        fontSize: '16px',
        color: `${colors.Common.textColor}`,
        whiteSpace: 'normal',
        lineHeight: '24px',
        height: 'auto',
        fontFamily: 'SegoeUI',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '300px!important',
        overflowY: 'auto',
        padding: '8px 24px',
        marginTop: '8px',
      },
      elevation8: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        width: '24px',
        maxHeight: '100%',
        pointerEvents: 'none',
      },
      positionStart: {
        marginRight: '15px',
      }
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '0',
        minWidth: '15px',
        width: '24px',
        height: '24px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '30px',
        '&:hover': {
          background: 'none!important',
        },
      },
      text: {
        padding: '2px',
        color: `${colors.Common.textColor}`,
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)!important',
        },
      }
    },
  },
});
export const customSelectTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      input: {},
      error: {},
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputBase: {
      root: {
        marginTop: '16px!important',
        fontFamily: 'SegoeUI',
        "&[required][valid='false']": {
          '& div': {
            borderRadius: '0 4px 4px 0',
            borderLeft: 'none',
          },
        },
        "&[required][valid='false']$disabled": {
          '& div': {
            borderRadius: '4px',
            borderLeft: '1px solid #DCDCDC',
          },
        },
        "&$error": {
          borderRadius: '4px',
          '& div': {
            border: '1px solid #E32A38 !important',
          },
        },
      },
      input: {
        padding: '23px 92px 7px 15px',
        fontSize: '16px',
        color: `${colors.Common.textColor}`,
        lineHeight: '24px',
        height: 'auto',
        fontFamily: 'SegoeUI',
        textOverflow: 'ellipsis',
        '&::placeholder': {
          color: `${colors.Common.textGrey} !important`,
          opacity: '1',
          fontWeight: '500',
          transform: 'translateY(-8px)',
        },
        '&:required': {
          borderRadius: '4px',
          borderLeft: '4px solid #E32A38',
        },
      },
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
        width: 'calc(100% - 54px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px, 32px) scale(1)',
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey} !important`,
        '&$focused': {
          zIndex: '1',
        }
      },
      filled: {
        fontSize: '14px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        '&$focused': {
          color: `${colors.Common.textGrey} !important`,
          '&$disabled': {
            color: `${colors.Common.textGrey} !important`,
          },
        },
        '&$error': {
          color: '#E32A38',
        },
      },
      disabled: {},
      error: {},
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        padding: '0',
        paddingTop: '16px',
        paddingBottom: '16px',
        fontSize: '16px',
        color: `${colors.Common.textColor}`,
        whiteSpace: 'normal',
        lineHeight: '24px',
        height: 'auto',
        fontFamily: 'SegoeUI',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '300px!important',
        overflowY: 'auto',
        padding: '8px 24px',
        marginTop: '8px',
      },
      rounded: {
        borderRadius: '8px',
      },
      elevation8: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        width: '24px',
        maxHeight: '100%',
      },
    },
    MuiSelect: {
      select: {
        paddingRight: '92px !important',
        border: '1px solid #C9C7C7',
        borderRadius: '4px',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$disabled': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
          },
        },
        '&:focus': {
          borderRadius: '4px',
          backgroundColor: '#ffffff',
          border: `1px solid ${colors.Input.border}`,
        },
      },
      icon: {
        right: '15px',
        '&$disabled': {
          '& path': {
            stroke: '#AAA9A9',
          },
        },
      },
    },
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
  },
});
