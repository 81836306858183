import { push } from 'connected-react-router';
import _ from 'lodash';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { client } from '../../../../api/Client';
import { isKZ, mock } from '../../../../config';
import i18n from '../../../../i18n';
import { handleErrorNotification } from '../../../ducks/HandleErrors';
import {
  CLEAR_INFO_ERROR,
  LOAD_PROFILE_CERTIFICATES_ACTION,
  LOAD_PROFILE_INFO_ACTION,
  SAVE_INFO_ACTION,
  SAVE_PASSWORD_ACTION,
} from './ProfileActionTypes.constants';
import {
  loadCertificatesFailed,
  loadCertificatesSucceesed,
  loadProfileFailed,
  loadProfileSucceded,
  saveInfoFailed,
  saveInfoSuccessed,
} from './Profile.actions';
import { throwNotification } from '../../../../common/structure';
import {
  handlerCryptoMessage,
  signData,
} from '../../../../common/utils/cryptoPro';
import { difference } from '../../../../common/utils/utils';

export function* loadProfile(action) {
  const errT = i18n.t('Ошибка загрузки профиля');
  try {
    const response = yield call(client().get, '/profile');
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      if (_.get(response, 'error.response.status') === 500) {
        yield put(push('/500'));
      } else {
        action.cbk({ error: errT });
        yield handleErrorNotification(response.error);
        yield put(loadProfileFailed(response.error));
      }
    } else {
      if (!_.has(response, 'user')) {
        action.cbk({ error: errT });
        yield put(loadProfileFailed({ error: i18n.t('Данные некорректны') }));
      } else {
        action.cbk(null);
        yield put(loadProfileSucceded(response));
      }
    }
  } catch (e) {
    action.cbk({ error: errT });
    yield put(loadProfileFailed({ error: e.message }));
  }
}

export function* loadCertificates() {
  try {
    const certificates = yield call(client().get, '/cert/list?type=SIGNATURE');

    yield put(loadCertificatesSucceesed(certificates || []));
  } catch (e) {
    yield put(
      throwNotification(
        i18n.t('Произошла ошибка при загрзуке сертификатов'),
        'error',
      ),
    );
    yield put(loadCertificatesFailed(e));
  }
}

export function* savePassword(action) {
  try {
    const response = yield call(client().put, '/profile', {
      password: action.password,
    });
    if (response.error) {
      yield handleErrorNotification(response.error);
    } else {
      yield put(throwNotification(i18n.t('Пароль сохранен!'), 'success'));
      yield put(saveInfoSuccessed(response));
    }
  } catch (e) { }
}

export function* saveInfo(action) {
  try {
    yield put({ type: CLEAR_INFO_ERROR });
    let diff = difference(action.newInfo, action.oldInfo);
    let request = parseProfile(diff, action.newInfo);
    if (diff.certificate) {
      const dataForSign = yield call(client().get, '/signed-data');
      if (dataForSign.error) {
        yield handleErrorNotification(dataForSign.error);
        yield put(saveInfoFailed(action.oldInfo));
        return;
      }

      request.signature = isKZ
        ? mock.signature
        : yield call(signData, diff.certificate.certificate, dataForSign.data);
      request.fingerprint = diff.certificate.fingerprint;
    }
    const response = yield call(client().put, '/profile', request);

    if (response.error) {
      yield handleErrorNotification(response.error);
      if (diff.certificate) {
        action.cbk({
          errors: {
            type: 'certificate',
            message: i18n.t('Проблема с сертификатом'),
          },
        });
      } else {
        action.cbk({
          errors: { type: 'all', message: i18n.t('Ошибка сохранения профиля') },
        });
      }
      if (response.error.message) {
        const validationErrors = response.error.response.data.validationErrors;
        if (!_.isEmpty(validationErrors)) {
          yield put(saveInfoFailed(action.newInfo, validationErrors));
        } else {
          yield put(saveInfoFailed(action.oldInfo));
        }
      }
    } else {
      action.cbk(null);
      yield put(
        throwNotification(
          i18n.t('Информация о пользователе обновлена!'),
          'success',
        ),
      );
      yield put(saveInfoSuccessed(response));
    }
  } catch (e) {
    const text = i18n.t('Ошибка сохранении информации о пользователе!');
    yield put(throwNotification(handlerCryptoMessage(e, text), 'error'));
    yield put(saveInfoFailed(action.oldInfo));
  }
}

export function parseProfile(diff, newInfo) {
  let request = {};
  if (!_.isUndefined(diff.company) && !_.isUndefined(diff.company.isMarked)) {
    request.isMarked = diff.company.isMarked ? 1 : 0;
  }

  if (!_.isUndefined(diff.user) && !_.isUndefined(diff.user.phone)) {
    request.phone = diff.user.phone;
  }

  if (!_.isUndefined(diff.user) && !_.isUndefined(diff.user.email)) {
    request.email = diff.user.email;
  }

  if (!_.isUndefined(diff.company) && !_.isUndefined(diff.company.okved2)) {
    request.okved2 = diff.company.okved2.id;
  }

  if (!_.isUndefined(diff.company) && !_.isUndefined(diff.company.opf)) {
    request.opf = diff.company.opf;
  }

  if (!_.isUndefined(diff.company) && !_.isUndefined(diff.company.country)) {
    request.country = diff.company.country;
  }

  if (!_.isUndefined(diff.company) && !_.isUndefined(diff.company.nonResidentialGCPs)) {
    request.nonResidentialGCPs = newInfo.company.nonResidentialGCPs;
  }

  if (
    !_.isUndefined(diff.user) &&
    !_.isUndefined(diff.user.isEmailNotifications)
  ) {
    request.isEmailNotifications = diff.user.isEmailNotifications;
  }

  if (!_.isUndefined(diff.user) && !_.isUndefined(diff.user.isTechCardSupport)) {
    request.isTechCardSupport = diff.user.isTechCardSupport;
  }

  return request;
}

export function* watchSaveInfo() {
  yield takeEvery(SAVE_INFO_ACTION, saveInfo);
}

export function* watchSavePassword() {
  yield takeEvery(SAVE_PASSWORD_ACTION, savePassword);
}

export function* watchLoadProfile() {
  yield takeEvery(LOAD_PROFILE_INFO_ACTION, loadProfile);
}

export function* watchLoadCertificated() {
  yield takeEvery(LOAD_PROFILE_CERTIFICATES_ACTION, loadCertificates);
}

export default function* profileSaga() {
  yield all([
    watchSaveInfo(),
    watchSavePassword(),
    watchLoadProfile(),
    watchLoadCertificated(),
  ]);
}
