import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import {
  assign,
  clone,
  cloneDeep,
  isEmpty,
  isNull,
  isNumber,
  toNumber,
  isEqual,
  find,
  forEach,
  without,
  toString,
} from 'lodash';
import memoize from 'memoize-state';
import { Button, MuiThemeProvider } from '@material-ui/core';
import { throwNotification } from '../../../../common/structure';
import { ConfirmDialog, NoDataComponent, Pagination, Preloader } from '../../../../components';
import {
  TradeMarkRegistrationModal,
  AddDocumentModal,
  TradeMarkFileView,
} from './modal';
import GridActionsItems from './gridActions/GridActionsItems';
import {
  IconGridEmpty,
  IconPlusBlue,
  IconDocuments,
  IconSortUp,
  IconSortDown,
  IconPlusNew,
  IconFileText,
} from '../../../../common/icons/customIcons';
import {
  ColumnCellProfile,
  SortIcon,
  Sorted,
} from '../../../products/productGrid/ProductsGrid.styled';
import {
  ColumnCellInner,
  ColumnHeaderProfile,
  DefaultGridButton,
  GridActionsRight,
  GridEmptyText,
  GridTableContainer,
  ManagementSiteActions,
  ManagementSiteCont,
  TableEmpty,
  SortingBtn,
  WithoutSortingBtn,
  GridActionsLeft,
  GridEmpty,
  GridEmptyTitle,
  GridEmptySubstring,
  StatusLine,
} from '../../../../common/styles/Common.styled';
import * as selectors from '../../ducks/TradeMarks/TradeMarks.selectors';
import {
  deleteTradeMark,
  loadTradeMarks,
} from '../../ducks/TradeMarks/TradeMarks.actions';
import { findTradeMarksStatusObj, getSortField } from '../../../../common/utils/utils';
import {
  TmIssueDateFilter,
  TmValidDateFilter,
  TmStatusFilter,
  TmFileFilter,
  TmNameFilter,
  TmNumberFilter,
  TmByFilter,
} from './filters';
import { TmNoDataComponent } from './utils';
import TmTypeFilter from './filters/TmTypeFilter';
import ProfileEllipsisCell from '../../views/subProfiles/cell/ProfileEllipsisCell';
import { GridButton, GridTableProfile, ProfileGridWrap } from '../../Profile.styled';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const isNotActiveFilters = (filters) => isEqual(FiltersInit, filters);

const FiltersInit = {
  tmName: null,
  tmType: null,
  tmNumber: null,
  issueDate: {
    from: null,
    to: null,
  },
  validDate: {
    from: null,
    to: null,
  },
  tmBy: null,
  AttachmentCount: null,
  tmStatus: null,
};

const ConfirmStateInit = {
  isOpen: false,
  title: '',
  message: '',
  funcAccept: null,
  funcDismiss: null,
  funcCancel: null,
  acceptText: '',
  dismissText: '',
};

const SortInit = [
  { field: 'tmName', direction: '' },
  { field: 'tmType', direction: '' },
  { field: 'tmNumber', direction: '' },
  { field: 'issueDate', direction: '' },
  { field: 'validDate', direction: '' },
  { field: 'tmBy', direction: '' },
  { field: 'tmStatus', direction: '' },
  { field: 'AttachmentCount', direction: '' },
];

const returnStatusText = (status) => {
  switch (status) {
    case 'moderation': {
      return 'На проверке';
    }
    case 'published': {
      return 'Подтверждён';
    }
    case 'cancelled': {
      return 'Отклонён';
    }
    default: {
      return ``;
    }
  }
};

const returnTypeText = (type) => {
  switch (type) {
    case 'rights': {
      return 'Право использования';
    }
    case 'owner': {
      return 'Собственный';
    }
    default: {
      return ``;
    }
  }
};

const formatDate = (date) => {
  if (!date) return '';

  return date.split(' ')[0].split('-').reverse().join('.');
};

const defaultColumns = [
  'tmName',
  'tmType',
  'tmNumber',
  'issueDate',
  'validDate',
  'tmBy',
  'AttachmentCount',
  'status',
];

const iconSwitch = (sort, name, action) => {
  switch (sort) {
    case 'ASC':
      return [
        <SortIcon id="ask" onClick={action} key={'sr_1'}>
          <IconSortUp />
        </SortIcon>,
        <Sorted key={'sr_2'}>{name}</Sorted>,
      ];
    case 'DESC':
      return [
        <SortIcon id="desc" onClick={action} key={'sr_1'}>
          <IconSortDown />
        </SortIcon>,
        <Sorted key={'sr_2'}>{name}</Sorted>,
      ];
    case '':
      return [
        <SortIcon onClick={action} key={'sr_1'}>
          <IconSortDown />
        </SortIcon>,
        <span key={'sr_2'}>{name}</span>,
      ];
    default:
      return <span>{name}</span>;
  }
};

class TradeMarksGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: FiltersInit,
      pageSize: 10,
      pageNum: 0,
      currentRow: null,
      columns: defaultColumns,
      sort: SortInit,
      selectedIds: [],
      deleteIds: [],
      modal: {
        confirm: ConfirmStateInit,
        isTradeMarkModalOpen: false,
        tradeMarkModalType: null,
        isVisibleAddDocuments: false,
      },
      modalViewFiles: {
        isOpen: false,
        viewFileModalType: '',
      },
      loading: false,
    };

    // const defaultColumns = ['tmName', 'type', 'tmNumber', 'issueDate', 'validDate', 'by', 'status'];
    // const paramsList = {
    //     ..._.pick(this.state, ['pageNum', 'pageSize', 'sort', 'filters']),
    //     columns: defaultColumns
    // }
    // this.props.onLoadTradeMarksData(paramsList);
    this.mIsNotActiveFilters = memoize(isNotActiveFilters);
    this.mIconSwitch = memoize(iconSwitch, { cacheSize: 5 });
  }

  componentDidMount() {
    const filters = localStorage.getItem('savedFiltersTradeMarks');
    if (filters) {
      this.setState({ filters: JSON.parse(filters) }, this.changeGridParams);
    } else {
      this.changeGridParams();
    }
  }

  componentDidUpdate(prevProps) {
    if (isEmpty(this.props.data) && (this.props.totalObjects !== prevProps.totalObjects)) {
        this.setState({ pageNum: this.state.pageNum === 0 ? this.state.pageNum : this.state.pageNum - 1 }, this.changeGridParams);
    }
  }

  onFilterDissmiss = (name) => {
    // let filters = cloneDeep(this.state.filters);
    // if (name === 'issueDate') {
    //   filters['issueDate'] = { to: null, from: null };
    // } else if (name === 'validDate') {
    //   filters['validDate'] = { to: null, from: null };
    // } else {
    //   filters[name] = null;
    // }
    // удалить один из фильтров в localStorage
    let savedFilters = localStorage.getItem('savedFiltersTradeMarks');
    savedFilters = JSON.parse(savedFilters);

    let filters = cloneDeep(this.state.filters);

    filters[name] = null;
    savedFilters[name] = null;

    localStorage.setItem(
      'savedFiltersTradeMarks',
      JSON.stringify(savedFilters),
    );
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: filters,
      }),
      this.changeGridParams,
    );
  };

  onFilterAccept = (name, value) => {
    let savedFilters;
    savedFilters = localStorage.getItem('savedFiltersTradeMarks');
    if (savedFilters) {
      savedFilters = JSON.parse(savedFilters);
    }
    if (!savedFilters) {
      savedFilters = cloneDeep(FiltersInit);
    }
    savedFilters[name] = value;
    localStorage.setItem(
      'savedFiltersTradeMarks',
      JSON.stringify(savedFilters),
    );
    if (name && toString(value)) {
      this.setState(
        {
          pageNum: 0,
          filters: assign({}, this.state.filters, { [name]: value }),
        },
        this.changeGridParams,
      );
    }
  };

  resetFilters = () => {
    this.setState(
      {
        filters: FiltersInit,
      },
      this.changeGridParams,
    );
  };

  getBuildParamsForList = () => {
    return {
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize,
      sort: getSortField(this.state.sort),
      filters: this.state.filters,
      fields: this.state.columns,
    };
  };

  changeGridParams = () => {
    const params = this.getBuildParamsForList();
    this.props.onLoadTradeMarksData(params);
  };

  getPaginationProps = () => ({ totalObjects: this.props.totalObjects });

  confirmModal = (confirm, state = {}) => {
    const s = assign({}, state);
    this.setState((prevState) => ({
      ...prevState,
      ...s,
      modal: { ...prevState.modal, confirm: confirm },
    }));
  };

  onDelete = (original) => {
    const { t } = this.props;
    const { tmId, tmName } = original;
    const id = toNumber(tmId);
    const ids = isNumber(id) ? [id] : this.state.selectedIds;

    let message = `${t('Вы действительно хотите удалить товарный знак')} ${tmName}?`;

    if (!isEmpty(ids)) {
      const objModal = {
        isOpen: true,
        message: message,
        funcAccept: this.onDeleteAccept,
        funcDismiss: this.onDeleteDissmiss,
        funcCancel: this.onDeleteDissmiss,
        acceptText: t('Удалить'),
        dismissText: t('Отменить'),
      };
      this.confirmModal(objModal, { deleteIds: ids });
    }
  };

  onDeleteAccept = () => {
    const state = { deleteIds: [], selectedIds: [] };
    const deleteIds = clone(this.state.deleteIds);

    this.confirmModal(ConfirmStateInit, state);
    this.props.onDeleteTradeMark(deleteIds[0], (error) => {
      if (isNull(error)) {
        const message = `Товарный знак успешно удален`;
        this.props.throwNotification(message, 'success');
        this.changeGridParams();
      }
    });
  };

  onDeleteDissmiss = () => {
    this.confirmModal(ConfirmStateInit, { deleteIds: [] });
  };

  handleCloseTradeMarkModal = () => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        isTradeMarkModalOpen: false,
        tradeMarkModalType: null,
      },
      currentRow: null,
    }));
  };

  handleOnRequestOpen = () => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        isTradeMarkModalOpen: true,
        tradeMarkModalType: 'create',
      },
    }));
  };

  handleAddDocumentsOpen = (original) => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        isVisibleAddDocuments: !prevState.modal.isVisibleAddDocuments,
      },
      currentRow: original,
    }));
  };

  handleOpenPreview = (original) => {
    return this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        isTradeMarkModalOpen: !prevState.modal.isTradeMarkModalOpen,
        tradeMarkModalType: 'view',
      },
      currentRow: original,
    }));
  };

  handleOnEdit = (original) => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        isTradeMarkModalOpen: !prevState.modal.isTradeMarkModalOpen,
        tradeMarkModalType: 'edit',
      },
      currentRow: original,
    }));
  };

  onFileUpload = (original) => {
    return this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        isVisibleAddDocuments: !prevState.modal.isVisibleAddDocuments,
      },
      currentRow: original,
    }));
  };

  handleCloseFileModal = () => {
    return this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        isVisibleAddDocuments: !prevState.modal.isVisibleAddDocuments,
      },
      currentRow: null,
    }));
  };

  handleOpenFilePreview = (original) => {
    return this.setState((prevState) => ({
      modalViewFiles: {
        ...prevState.modalViewFiles,
        isOpen: !prevState.modalViewFiles.isOpen,
      },
      currentRow: original,
    }));
  };

  handleClosFilePreview = () => {
    return this.setState((prevState) => ({
      modalViewFiles: {
        ...prevState.modalViewFiles,
        isOpen: !prevState.modalViewFiles.isOpen,
      },
      currentRow: null,
    }));
  };

  getNoDataProps = () => ({ resetFilters: this.resetFilters });

  onSort = (name) => () => {
    let direction;
    const sort = clone(this.state.sort);
    const field = find(sort, { field: name });
    switch (field.direction) {
      case 'ASC': {
        direction = 'DESC';
        break;
      }
      default:
        direction = 'ASC';
        //clear other sorts
        forEach(without(sort, field), (s) => (s.direction = ''));
        break;
    }
    field.direction = direction;
    this.setState({ sort }, this.changeGridParams);
  };

  getStatus = (textStatus, id) => {
    const { t } = this.props;
    const status = findTradeMarksStatusObj(textStatus);
    return (
      <StatusLine key={`${id}_${textStatus}`} color={status.style.color}>
        {status.style.icon}
        <span>{t(status.title)}</span>
      </StatusLine>
    );
  };

  render() {
    const { loading, t, data, totalObjects } = this.props;

    const IsNotActiveFilters = this.mIsNotActiveFilters(this.state.filters);
    const emptyTable = !this.props.totalObjects && IsNotActiveFilters;

    return (
      <React.Fragment>
        {this.state.modal.confirm.isOpen && (
          <ConfirmDialog
            onAccept={this.state.modal.confirm.funcAccept}
            onDismiss={this.state.modal.confirm.funcDismiss}
            onCancel={this.state.modal.confirm.funcCancel}
            title={this.state.modal.confirm.title}
            message={this.state.modal.confirm.message}
            acceptBtnText={this.state.modal.confirm.acceptText}
            dismissBtnText={this.state.modal.confirm.dismissText}
          />
        )}

        {this.state.modal.isTradeMarkModalOpen && (
          <TradeMarkRegistrationModal
            handleClose={this.handleCloseTradeMarkModal}
            modalType={this.state.modal.tradeMarkModalType}
            currentRow={this.state.currentRow}
            changeGridParams={this.changeGridParams}
            throwNotification={this.props.throwNotification}
          />
        )}
        {this.state.modal.isVisibleAddDocuments && (
          <AddDocumentModal
            currentRow={this.state.currentRow}
            handleClose={this.handleCloseFileModal}
            changeGridParams={this.changeGridParams}
          />
        )}

        {this.state.modalViewFiles.isOpen && (
          <TradeMarkFileView
            currentRow={this.state.currentRow}
            handleClose={this.handleClosFilePreview}
            changeGridParams={this.changeGridParams}
          />
        )}

        <ManagementSiteCont>
          <ManagementSiteActions>
            <GridActionsLeft>
              <MuiThemeProvider theme={GridButton}>
                <Button disabled={loading} onClick={this.handleOnRequestOpen}>
                  <IconPlusNew />
                  <span style={{ marginLeft: '10px' }}>
                    {t('Добавить товарный знак')}
                  </span>
                </Button>
              </MuiThemeProvider>
            </GridActionsLeft>
          </ManagementSiteActions>
          <ProfileGridWrap>
            <GridTableContainer noData={!data.length && !IsNotActiveFilters} height="37vh" minHeight="10vh" className="customTable" profile>
              {/* <GridTableProfile> */}
              {!data.length && IsNotActiveFilters && !totalObjects ?
                <GridEmpty>
                  <GridEmptyTitle>{t('Таблица не заполнена')}</GridEmptyTitle>
                  <GridEmptySubstring>{t('У вас еще нет добавленных товарных знаков')}</GridEmptySubstring>
                </GridEmpty>
                :
                <div style={{ display: loading ? 'none' : 'block', height: '100%' }}>
                  <ReactTableFixedColumns
                    data={data}
                    columns={[
                      {
                        name: t('Товарный знак'),
                        show: true,
                        position: 0,
                        accessor: 'tmName',
                        sortable: false,
                        minWidth: 250,
                        Header: (x) => {
                          return (
                            <ColumnHeaderProfile id="tradeMarkFilter">
                              <SortingBtn>
                                {this.mIconSwitch(
                                  find(this.state.sort, { field: 'tmName' })
                                    .direction,
                                  t('Товарный знак'),
                                  this.onSort('tmName'),
                                )}
                              </SortingBtn>

                              <TmNameFilter
                                parentId="tradeMarkFilter"
                                currentValue={this.state.filters.tmName}
                                onAccept={this.onFilterAccept}
                                onDismiss={this.onFilterDissmiss}
                              />
                            </ColumnHeaderProfile>
                          );
                        },
                        Cell: ({ original }) => {
                          return <ProfileEllipsisCell
                            value={original.tmName}
                            cellId={`tm-name-${original.tmId}`}
                          />;
                        },
                      },
                      {
                        name: t('Номер свидетельства'),
                        show: true,
                        position: 1,
                        accessor: 'tmNumber',
                        sortable: false,
                        minWidth: 240,
                        Header: (x) => {
                          return (
                            <ColumnHeaderProfile id="tradeNumberFilter">
                              <SortingBtn>
                                {this.mIconSwitch(
                                  find(this.state.sort, { field: 'tmNumber' })
                                    .direction,
                                  t('Номер свидетельства'),
                                  this.onSort('tmNumber'),
                                )}
                              </SortingBtn>

                              <TmNumberFilter
                                parentId="tradeNumberFilter"
                                currentValue={this.state.filters.tmNumber}
                                onAccept={this.onFilterAccept}
                                onDismiss={this.onFilterDissmiss}
                              />
                            </ColumnHeaderProfile>
                          );
                        },
                        Cell: ({ original }) => {
                          return (
                            <ColumnCellProfile>
                              <ColumnCellInner>
                                {original.tmNumber}
                              </ColumnCellInner>
                            </ColumnCellProfile>
                          );
                        },
                      },
                      {
                        name: t('Дата выдачи'),
                        show: true,
                        position: 2,
                        accessor: 'issueDate',
                        sortable: false,
                        minWidth: 175,
                        Header: (x) => {
                          return (
                            <ColumnHeaderProfile id="issueDateFilter">
                              <SortingBtn>
                                {this.mIconSwitch(
                                  find(this.state.sort, { field: 'issueDate' })
                                    .direction,
                                  t('Дата выдачи'),
                                  this.onSort('issueDate'),
                                )}
                              </SortingBtn>

                              <TmIssueDateFilter
                                parentId="issueDateFilter"
                                currentValue={this.state.filters.issueDate}
                                onAccept={this.onFilterAccept}
                                onDismiss={this.onFilterDissmiss}
                                name={'issueDate'}
                              />
                            </ColumnHeaderProfile>
                          );
                        },
                        Cell: ({ original }) => {
                          return (
                            <ColumnCellProfile>
                              <ColumnCellInner>
                                {formatDate(original.issueDate)}
                              </ColumnCellInner>
                            </ColumnCellProfile>
                          );
                        },
                      },
                      {
                        name: t('Срок действия'),
                        show: true,
                        position: 3,
                        accessor: 'validDate',
                        sortable: false,
                        minWidth: 190,
                        Header: (x) => {
                          return (
                            <ColumnHeaderProfile id="validDateFilter">
                              <SortingBtn>
                                {this.mIconSwitch(
                                  find(this.state.sort, { field: 'validDate' })
                                    .direction,
                                  t('Срок действия'),
                                  this.onSort('validDate'),
                                )}
                              </SortingBtn>

                              <TmValidDateFilter
                                parentId="validDateFilter"
                                currentValue={this.state.filters.validDate}
                                onAccept={this.onFilterAccept}
                                onDismiss={this.onFilterDissmiss}
                                name={'validDate'}
                              />
                            </ColumnHeaderProfile>
                          );
                        },
                        Cell: ({ original }) => {
                          return (
                            <ColumnCellProfile>
                              <ColumnCellInner>
                                {formatDate(original.validDate)}
                              </ColumnCellInner>
                            </ColumnCellProfile>
                          );
                        },
                      },
                      {
                        name: t('Кем выдано'),
                        show: true,
                        position: 4,
                        accessor: 'tmBy',
                        sortable: false,
                        minWidth: 189,
                        Header: (x) => {
                          return (
                            <ColumnHeaderProfile id="tmByFilter">
                              <SortingBtn>
                                {this.mIconSwitch(
                                  find(this.state.sort, { field: 'tmBy' })
                                    .direction,
                                  t('Кем выдано'),
                                  this.onSort('tmBy'),
                                )}
                              </SortingBtn>

                              <TmByFilter
                                parentId="tmByFilter"
                                currentValue={this.state.filters.tmBy}
                                onAccept={this.onFilterAccept}
                                onDismiss={this.onFilterDissmiss}
                              />
                            </ColumnHeaderProfile>
                          );
                        },
                        Cell: ({ original }) => {
                          return <ProfileEllipsisCell
                            value={original.tmBy}
                            cellId={`tm-by-${original.tmId}`}
                          />;
                        },
                      },
                      {
                        name: t('Документы и изображения'),
                        show: true,
                        position: 5,
                        accessor: 'AttachmentCount',
                        sortable: false,
                        minWidth: 240,
                        Header: (x) => {
                          return (
                            <ColumnHeaderProfile id="fileFilter">
                              {t('Документы и изображения')}
                              <WithoutSortingBtn>
                                <TmFileFilter
                                  parentId="fileFilter"
                                  currentValue={
                                    this.state.filters.AttachmentCount
                                  }
                                  onAccept={this.onFilterAccept}
                                  onDismiss={this.onFilterDissmiss}
                                />
                              </WithoutSortingBtn>
                            </ColumnHeaderProfile>
                          );
                        },
                        Cell: ({ original }) => {
                          return (
                            <ColumnCellProfile>
                              <ColumnCellInner>
                                {original.AttachmentCount &&
                                  toNumber(original.AttachmentCount) >= 1 && (
                                    <div
                                      onClick={() =>
                                        this.handleOpenFilePreview(original)
                                      }
                                      style={{
                                        display: 'flex',
                                        cursor: 'pointer',
                                        height: '40px',
                                        alignItems: 'center',
                                      }}>
                                      <IconFileText />
                                      <div
                                        style={{
                                          marginLeft: '10px',
                                        }}>
                                        {original.AttachmentCount}
                                      </div>
                                    </div>
                                  )}
                              </ColumnCellInner>
                            </ColumnCellProfile>
                          );
                        },
                      },
                      {
                        name: t('Тип'),
                        show: true,
                        position: 5,
                        accessor: 'tmType',
                        sortable: false,
                        minWidth: 234,
                        Header: (x) => {
                          return (
                            <ColumnHeaderProfile id="typeFilter">
                              <SortingBtn>
                                {this.mIconSwitch(
                                  find(this.state.sort, { field: 'tmType' })
                                    .direction,
                                  t('Тип'),
                                  this.onSort('tmType'),
                                )}
                              </SortingBtn>

                              <TmTypeFilter
                                parentId="typeFilter"
                                onAccept={this.onFilterAccept}
                                onDismiss={this.onFilterDissmiss}
                                currentValue={this.state.filters.tmType}
                              />
                            </ColumnHeaderProfile>
                          );
                        },
                        Cell: ({ original }) => {
                          return (
                            <ColumnCellProfile>
                              <ColumnCellInner>
                                {t(returnTypeText(original.tmType))}
                              </ColumnCellInner>
                            </ColumnCellProfile>
                          );
                        },
                      },
                      {
                        name: t('Статус'),
                        show: true,
                        position: 5,
                        accessor: 'tmStatus',
                        sortable: false,
                        minWidth: 195,
                        Header: (x) => {
                          return (
                            <ColumnHeaderProfile id="statusFilter">
                              <SortingBtn>
                                {this.mIconSwitch(
                                  find(this.state.sort, { field: 'tmStatus' })
                                    .direction,
                                  t('Статус'),
                                  this.onSort('tmStatus'),
                                )}
                              </SortingBtn>

                              <TmStatusFilter
                                parentId="statusFilter"
                                onAccept={this.onFilterAccept}
                                onDismiss={this.onFilterDissmiss}
                                currentValue={this.state.filters.tmStatus}
                              />
                            </ColumnHeaderProfile>
                          );
                        },
                        Cell: ({ original }) => {
                          return (
                            <ColumnCellProfile>
                              <ColumnCellInner>
                                {/* {t(returnStatusText(original.tmStatus))} */}
                                {this.getStatus(original.tmStatus, original.tmId)}
                              </ColumnCellInner>
                            </ColumnCellProfile>
                          );
                        },
                      },
                      {
                        accessor: 'actions',
                        Header: '',
                        sortable: false,
                        filterable: false,
                        //fixed: 'right',
                        width: 52,
                        Cell: ({ original }) => {
                          return (
                            <React.Fragment>
                              <GridActionsItems
                                onDelete={this.onDelete}
                                original={original}
                                onAddDocuments={this.handleAddDocumentsOpen}
                                onOpenPreview={this.handleOpenPreview}
                                onEdit={this.handleOnEdit}
                                onFileUpload={this.onFileUpload}
                              />
                            </React.Fragment>
                          );
                        },
                      },
                    ]}
                    defaultPageSize={this.state.pageSize}
                    className="-highlight"
                    page={this.state.pageNum}
                    pages={Math.ceil(
                      this.props.totalObjects / this.state.pageSize,
                    )}
                    pageSize={this.state.pageSize}
                    PaginationComponent={Pagination}
                    getPaginationProps={this.getPaginationProps}
                    onPageChange={(page) => {
                      this.setState({ pageNum: page }, this.changeGridParams);
                    }}
                    resizable={false}
                    NoDataComponent={NoDataComponent}
                    getNoDataProps={this.getNoDataProps}
                    minRows={0}
                    manual
                  />
                </div>
              }
              {/* </GridTableProfile> */}
            </GridTableContainer>
          </ProfileGridWrap>
          <Preloader isOpen={loading} text={t('Загрузка')} />
        </ManagementSiteCont>
      </React.Fragment>
    );
  }
}

TradeMarksGrid.propTypes = {
  throwNotification: PropTypes.func.isRequired,
  onLoadTradeMarksData: PropTypes.func.isRequired,
  onDeleteTradeMark: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  totalObjects: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  loading: !!selectors.loading(state),
  totalObjects: selectors.totalObjects(state),
  data: selectors.data(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwNotification: (message, type) => throwNotification(message, type),
      onLoadTradeMarksData: (p) => loadTradeMarks(p),
      // onLoadProductData: (p) => loadProductData(p.pageNum, p.pageSize, p.sort, p.filters, p.fields),
      onDeleteTradeMark: (id, cbk) => deleteTradeMark(id, cbk),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(TradeMarksGrid);
