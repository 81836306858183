import { createMuiTheme } from '@material-ui/core';
import styled from 'styled-components';

export const PhotoDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minHeight: '335px',
        minWidth: '750px',
        maxWidth: '750px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
        boxSizing: 'border-box',
      },
    },
    MuiDialogContent: {
      root: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0 40px 40px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '15px 20px 0',
        textAlign: 'right',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
      },
      text: {
        padding: '3px',
      },
      label: {
        color: '#52535A!important',
      },
    },
  },
});

export const IconContainer = styled.div`
  width: 44px;
  height: 44px;
  padding: 9px;
  border: 1px solid #DCDCDC;
  border-radius: 4px;    
`;

export const PreviewImage = styled.img`
  width: 44px;
  height: 44px;
  object-fit: cover;
  border: 1px solid #DCDCDC;
  border-radius: 4px;
`;
export const DialogImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;
