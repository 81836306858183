export const GENERATE_GTIN_ACTION = 'GENERATE_GTIN';
export const VALIDATION_GTIN_ACTION = 'VALIDATION_GTIN';
export const VALIDATION_GTIN_PACKAGE_ACTION = 'VALIDATION_GTIN_PACKAGE';
export const VALIDATION_UNREGISTERED_GTIN_ACTION =
  'VALIDATION_UNREGISTERED_GTIN_ACTION';

export const CREATE_CARD_ITEM_ACTION = 'CREATE_CARD_ITEM';
export const CREATE_CARD_ITEM_SUCCEEDED_ACTION = 'CREATE_CARD_ITEM_SUCCEEDED';
export const CREATE_CARD_ITEM_FAILED_ACTION = 'CREATE_CARD_ITEM_FAILED';
export const CREATE_CONSUMER_ITEM_ACTION = 'CREATE_CONSUMER_ITEM';
export const CREATE_CONSUMER_ITEM_SUCCEEDED_ACTION =
  'CREATE_CONSUMER_ITEM_SUCCEEDED';
export const CREATE_CONSUMER_ITEM_FAILED_ACTION = 'CREATE_CONSUMER_ITEM_FAILED';

export const LOAD_CARD_ITEM_ACTION = 'LOAD_CARD_ITEM';
export const LOAD_CARD_ITEM_VERSION_ACTION = 'LOAD_CARD_ITEM_VERSION';
export const LOAD_CARD_ITEM_SUCCEEDED_ACTION = 'LOAD_CARD_ITEM_SUCCEEDED';
export const LOAD_CARD_ITEM_FAILED_ACTION = 'LOAD_CARD_ITEM_FAILED';

export const LOAD_CATALOG_CARD_ITEM_ACTION = 'LOAD_CATALOG_CARD_ITEM';

export const SAVE_PRODUCT_ACTION = 'SAVE_PRODUCT';
export const SAVE_PRODUCT_SUCCEEDED_ACTION = 'SAVE_PRODUCT_SUCCEEDED';
export const LOAD_MODAL_ACTION = 'LOAD_MODAL';
export const MODERATE_PRODUCT_ACTION = 'MODERATE_PRODUCT_ACTION';
export const DOWNLOAD_PRODUCT_ACTION = 'DOWNLOAD_PRODUCT';
export const GET_SUGGESTIONS_ACTION = 'GET_SUGGESTIONS';
export const CLEAR_TRANSACTION_ACTION = 'CLEAR_TRANSACTION';
export const CLEAR_COPY_OF_PRODUCT_ACTION = 'CLEAR_COPY_OF_PRODUCT';
export const CLEAR_PRODUCT_DATA_ACTION = 'CLEAR_PRODUCT_DATA';

export const SEARCH_STATE_CERTIFICATE_ACTION = 'SEARCH_STATE_CERTIFICATE';
export const SEARCH_STATE_CERTIFICATE_SUCCEEDED_ACTION =
  'SEARCH_STATE_CERTIFICATE_SUCCEEDED';
export const SEARCH_STATE_CERTIFICATE_FAILED_ACTION =
  'SEARCH_STATE_CERTIFICATE_FAILED';
export const CLEAR_CERTIFICATE_SEARCH_ACTION = 'CLEAR_CERTIFICATE_SEARCH';

export const LOAD_MODERATE_ERRORS_ACTION = 'LOAD_MODERATE_ERRORS';
export const LOAD_MODERATE_ERRORS_SUCCEEDED_ACTION =
  'LOAD_MODERATE_ERRORS_SUCCEEDED';
export const LOAD_MODERATE_ERRORS_FAILED_ACTION = 'LOAD_MODERATE_ERRORS_FAILED';
export const CLEAR_MODERATE_ERRORS_ACTION = 'CLEAR_MODERATE_ERRORS';

export const LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_ACTION =
  'LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_ACTION';
export const LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_SUCCEEDED_ACTION';
export const LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_FAILED_ACTION =
  'LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_FAILED_ACTION';

export const VALIDATION_GROUP_AND_TRANSPORT_GTIN_ACTION =
  'VALIDATION_GROUP_AND_TRANSPORT_GTIN_ACTION';

export const LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_ACTION =
  'LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_ACTION';
export const LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_SUCCEEDED_ACTION';
export const LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_FAILED_ACTION =
  'LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_FAILED_ACTION';

export const LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_ACTION =
  'LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_ACTION';
export const LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_SUCCEEDED_ACTION';
export const LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_FAILED_ACTION =
  'LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_FAILED_ACTION';

export const CLEAR_GPC_AND_TNVED_SELECT_OPTIONS =
  'CLEAR_GPC_AND_TNVED_SELECT_OPTIONS';

export const CHECK_TNVED_FOR_MARKING_ACTION = 'CHECK_TNVED_FOR_MARKING_ACTION';
export const CHECK_TNVED_FOR_MARKING_SUCCEEDED_ACTION =
  'CHECK_TNVED_FOR_MARKING_SUCCEEDED_ACTION';
export const CHECK_TNVED_FOR_MARKING_FAILED_ACTION =
  'CHECK_TNVED_FOR_MARKING_FAILED_ACTION';
export const CLEAR_TNVED_MARKING_ARRAY = 'CLEAR_TNVED_MARKING_ARRAY';

export const LOAD_PHOTO_TYPES_ACTION = 'LOAD_PHOTO_TYPES_ACTION';
export const LOAD_PHOTO_TYPES_ACTION_SUCCEEDED =
  'LOAD_PHOTO_TYPES_ACTION_SUCCEEDED';
export const LOAD_PHOTO_TYPES_ACTION_FAILED = 'LOAD_PHOTO_TYPES_ACTION_FAILED';

export const LOAD_TECHNICAL_CATEGORIES_UNMARKABLE_ACTION = 'LOAD_UNMARKABLE_TECHNICAL_CATEGORIES_ACTION';
export const LOAD_TECHNICAL_CATEGORIES_UNMARKABLE_ACTION_SUCCEEDED = 'LOAD_UNMARKABLE_TECHNICAL_CATEGORIES_ACTION_SUCCEEDED';
export const LOAD_TECHNICAL_CATEGORIES_UNMARKABLE_ACTION_FAILED = 'LOAD_UNMARKABLE_TECHNICAL_CATEGORIES_ACTION_FAILED';

export const LOAD_TECHNICAL_OPTIONS_ACTION = 'LOAD_TECHNICAL_OPTIONS_ACTION';
export const LOAD_TECHNICAL_OPTIONS_ACTION_SUCCEEDED = 'LOAD_TECHNICAL_OPTIONS_ACTION_SUCCEEDED';
export const LOAD_TECHNICAL_OPTIONS_ACTION_FAILED = 'LOAD_TECHNICAL_OPTIONS_ACTION_FAILED';

export const LOAD_TECHNICAL_CATEGORIES_ACTION = 'LOAD_TECHNICAL_CATEGORIES_ACTION';
export const LOAD_TECHNICAL_CATEGORIES_ACTION_SUCCEEDED = 'LOAD_TECHNICAL_CATEGORIES_ACTION_SUCCEEDED';
export const LOAD_TECHNICAL_CATEGORIES_ACTION_FAILED = 'LOAD_TECHNICAL_CATEGORIES_ACTION_FAILED';

export const CREATE_TECHNICAL_ITEM_ACTION = 'CREATE_TECHNICAL_ITEM_ACTION';
export const CREATE_TECHNICAL_ITEM_ACTION_SUCCEEDED = 'CREATE_TECHNICAL_ITEM_ACTION_SUCCEEDED';
export const CREATE_TECHNICAL_ITEM_ACTION_FAILED = 'CREATE_TECHNICAL_ITEM_ACTION_FAILED';

export const VALIDATION_TECHNICAL_CARD_ACTION = 'VALIDATION_TECHNICAL_CARD_ACTION';

export const LOAD_COUNTRIES_ACTION = 'LOAD_COUNTRIES_ACTION';
export const LOAD_COUNTRIES_ACTION_SUCCEEDED = 'LOAD_COUNTRIES_ACTION_SUCCEEDED';
export const LOAD_COUNTRIES_ACTION_FAILED = 'LOAD_COUNTRIES_ACTION_FAILED';

export const CHECK_ACCOUNT_CAN_CREATE_GTIN_ACTION =
  'CHECK_ACCOUNT_CAN_CREATE_GTIN_ACTION';
export const CHECK_ACCOUNT_CAN_CREATE_GTIN_SUCCEEDED_ACTION =
  'CHECK_ACCOUNT_CAN_CREATE_GTIN_SUCCEEDED_ACTION';
export const CHECK_ACCOUNT_CAN_CREATE_GTIN_FAILED_ACTION =
  'CHECK_ACCOUNT_CAN_CREATE_GTIN_FAILED_ACTION';

export const LOAD_ACCOUNT_GCP_LIST_ACTION =
  'LOAD_ACCOUNT_GCP_LIST_ACTION';
export const LOAD_ACCOUNT_GCP_LIST_SUCCEEDED_ACTION =
  'LOAD_ACCOUNT_GCP_LIST_SUCCEEDED_ACTION';
export const LOAD_ACCOUNT_GCP_LIST_FAILED_ACTION =
  'LOAD_ACCOUNT_GCP_LIST_FAILED_ACTION';

export const CHECK_AVAILABILITY_CREATION_GTIN_ACTION =
  'CHECK_AVAILABILITY_CREATION_GTIN_ACTION';
export const CHECK_AVAILABILITY_CREATION_GTIN_SUCCEEDED_ACTION =
  'CHECK_AVAILABILITY_CREATION_GTIN_SUCCEEDED_ACTION';
export const CHECK_AVAILABILITY_CREATION_GTIN_FAILED_ACTION =
  'CHECK_AVAILABILITY_CREATION_GTIN_FAILED_ACTION';
export const CLEAR_AVAILABILITY_CREATION_GTIN_ACTION =
  'CLEAR_AVAILABILITY_CREATION_GTIN_ACTION';

export const CREATE_GTIN_ACTION = 'CREATE_GTIN_ACTION';
export const CREATE_GTIN_SUCCEEDED_ACTION = 'CREATE_GTIN_SUCCEEDED_ACTION';
export const CREATE_GTIN_FAILED_ACTION = 'CREATE_GTIN_FAILED_ACTION';
export const CLEAR_GENERATED_GTIN_ACTION = 'CLEAR_GENERATED_GTIN_ACTION';

