import { all, call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { client } from '../../../api/Client';
import i18n from '../../../i18n';
import {
  loadAnalyticPieFailed,
  loadAnalyticPieSucceded,
  loadTradeMarksDictionarySucceeded,
  loadTradeMarksDictionaryFailed,
  loadCategoriesDictionarySucceeded,
  loadCategoriesDictionaryFailed,
  loadMainStatisticSucceeded,
  loadMainStatisticFailed,
  loadGs1StatusSucceeded,
  loadGs1StatusFailed,
  loadTradeMarkElapsedStatisticsSucceeded,
  loadTradeMarkElapsedStatisticsFailed,
  loadPhotoContentStatisticsSucceeded,
  loadPhotoContentStatisticsFailed,
  loadGoodsDateExpirationStatisticsSucceeded,
  loadGoodsDateExpirationStatisticsFailed,
  loadGoodsExemplarStatisticsSucceeded,
  loadGoodsExemplarStatisticsFailed,
} from './Analytic.actions';
import {
  LOAD_ANALYTIC_PIE_ACTION,
  LOAD_TRADEMARKS_DICTIONARY_ACTION,
  LOAD_CATEGORY_DICTIONARY_ACTION,
  LOAD_MAIN_STATISTIC_ACTION,
  LOAD_GS1_STATUS_ACTION,
  LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION,
  LOAD_PHOTO_CONTENT_STATISTICS_ACTION,
  LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION,
  LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION,
} from './Analytic.constants';
import { throwNotification } from '../../../common/structure';
import { handleErrorNotification } from '../../ducks/HandleErrors';

export function* loadAnalyticPie(actions) {
  const { query } = actions;
  let url = `/reports/pie-status?`;
  let packingAdd = ``;
  let catIdAdd = ``;
  let brandIdAdd = ``;

  if (query) {
    if (query?.packingPurpose &&
      query.packingPurpose.value !== ('consumer' || 'techCard' || 'unmarkable')) {
      packingAdd = `packageType=${query.packingPurpose?.value}`;
    }
    if (query?.packingPurpose && query.packingPurpose.value === 'consumer') {
      packingAdd = 'packageType=consumer&isTechCard=0&isUnmarkable=0';
    }
    if (query?.packingPurpose && query.packingPurpose.value === 'techCard') {
      packingAdd = 'packageType=consumer&isTechCard=1&isUnmarkable=0';
    }
    if (query?.packingPurpose && query.packingPurpose.value === 'unmarkable') {
      packingAdd = 'packageType=consumer&isTechCard=1&isUnmarkable=1';
    }
    if (!_.isEmpty(query?.category)) {
      catIdAdd = `&catIds=${query.category.id}`;
    }
    if (!_.isEmpty(query?.brand)) {
      brandIdAdd = `&brandIds=${query.brand.id}`;
    }
  }

  let concatUrl = url + packingAdd + catIdAdd + brandIdAdd;

  try {
    const response = yield call(client().get, query ? concatUrl : url);
    if (_.isString(response)) {
      yield put(push('/500'));
    }
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        i18n.t('Ошибка получения данных Аналитики'),
      );
      yield put(loadAnalyticPieFailed());
    } else {
      yield put(loadAnalyticPieSucceded(response));
    }

    actions.cbk();
  } catch (e) {
    yield put(
      throwNotification(i18n.t('Ошибка получения данных Аналитики'), 'error'),
    );
    yield put(loadAnalyticPieFailed());
  }
}

export function* loadTrademarksDictionary(action) {
  try {
    const response = yield call(client().get, `/dictionaries/tm/account`);
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        i18n.t('Ошибка получения данных Торговых марок'),
      );
      yield put(loadTradeMarksDictionaryFailed());
    } else {
      yield put(loadTradeMarksDictionarySucceeded(response));
    }
    action.cbk();
  } catch (e) {
    yield put(
      throwNotification(
        i18n.t('Ошибка получения данных Торговых марок'),
        'error',
      ),
    );
    yield put(loadTradeMarksDictionaryFailed());
  }
}

export function* loadCategoriesDictionary(action) {
  try {
    const response = yield call(client().get, `/categories/account`);
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        i18n.t('Ошибка получения данных Категории'),
      );
      yield put(loadCategoriesDictionaryFailed());
    } else {
      yield put(loadCategoriesDictionarySucceeded(response));
    }
    action.cbk();
  } catch (e) {
    yield put(
      throwNotification(i18n.t('Ошибка получения данных Категории'), 'error'),
    );
    yield put(loadCategoriesDictionaryFailed());
  }
}
export function* loadMainStatistic(action) {
  try {
    const response = yield call(client().get, `/reports/pie-main-status`);
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        i18n.t('Ошибка получения данных общего состояния'),
      );
      yield put(loadMainStatisticFailed());
    } else {
      yield put(loadMainStatisticSucceeded(response));
    }
  } catch (e) {
    yield put(
      throwNotification(
        i18n.t('Ошибка получения данных общего состояния'),
        'error',
      ),
    );
    yield put(loadMainStatisticFailed());
  }
}

export function* loadGs1Status(action) {
  const errT = i18n.t('Ошибка получения данных gs1 статуса!');
  try {
    const response = yield call(client().get, `/gs1/account/status`);
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadGs1StatusFailed());
    } else {
      yield put(loadGs1StatusSucceeded(response));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadGs1StatusFailed());
  }
}

export function* loadTradeMarkElapsedStatistics(action) {
  const errT = i18n.t('Ошибка получения данных Товаров с истекшим сроком действия свидетельства на Товарный знак');
  try {
    const response = yield call(client().get, `/reports/trademark-elapsed-statistics`);
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadTradeMarkElapsedStatisticsFailed());
    } else {
      yield put(loadTradeMarkElapsedStatisticsSucceeded(response));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadTradeMarkElapsedStatisticsFailed());
  }
}

export function* loadPhotoContentStatistics(action) {
  const errT = i18n.t('Ошибка получения данных Товаров с защитой фотоконтента водяным знаком системы');
  try {
    const response = yield call(client().get, `/reports/photo-content-statistics`);
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadPhotoContentStatisticsFailed());
    } else {
      yield put(loadPhotoContentStatisticsSucceeded(response));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadPhotoContentStatisticsFailed());
  }
}

export function* loadGoodsDateExpirationStatistics(action) {
  const errT = i18n.t('Ошибка получения данных Товаров с просроченной разрешительной документацией');
  try {
    const response = yield call(client().get, `/reports/goods-date-expiration-statistics`);
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadGoodsDateExpirationStatisticsFailed());
    } else {
      yield put(loadGoodsDateExpirationStatisticsSucceeded(response));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadGoodsDateExpirationStatisticsFailed());
  }
}

export function* loadGoodsExemplarStatistics(action) {
  const errT = i18n.t('Ошибка получения данных Товаров в совместном управлении');
  try {
    const response = yield call(client().get, `/reports/goods-exemplar-statistics`);
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadGoodsExemplarStatisticsFailed());
    } else {
      yield put(loadGoodsExemplarStatisticsSucceeded(response));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadGoodsExemplarStatisticsFailed());
  }
}

export function* watchLoadAnalyticPie() {
  yield takeEvery(LOAD_ANALYTIC_PIE_ACTION, loadAnalyticPie);
}

export function* watchLoadTrademarks() {
  yield takeEvery(LOAD_TRADEMARKS_DICTIONARY_ACTION, loadTrademarksDictionary);
}

export function* watchLoadCategoriesDictionary() {
  yield takeEvery(LOAD_CATEGORY_DICTIONARY_ACTION, loadCategoriesDictionary);
}
export function* watchLoadMainStatistic() {
  yield takeEvery(LOAD_MAIN_STATISTIC_ACTION, loadMainStatistic);
}
export function* watchLoadGs1Status() {
  yield takeEvery(LOAD_GS1_STATUS_ACTION, loadGs1Status);
}
export function* watchLoadTradeMarkElapsedStatistics() {
  yield takeEvery(LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION, loadTradeMarkElapsedStatistics);
}
export function* watchLoadPhotoContentStatistics() {
  yield takeEvery(LOAD_PHOTO_CONTENT_STATISTICS_ACTION, loadPhotoContentStatistics);
}
export function* watchLoadGoodsDateExpirationStatistics() {
  yield takeEvery(LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION, loadGoodsDateExpirationStatistics);
}
export function* watchLoadGoodsExemplarStatistics() {
  yield takeEvery(LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION, loadGoodsExemplarStatistics);
}

export default function* analyticSaga() {
  yield all([
    watchLoadAnalyticPie(),
    watchLoadTrademarks(),
    watchLoadCategoriesDictionary(),
    watchLoadMainStatistic(),
    watchLoadGs1Status(),
    watchLoadTradeMarkElapsedStatistics(),
    watchLoadPhotoContentStatistics(),
    watchLoadGoodsDateExpirationStatistics(),
    watchLoadGoodsExemplarStatistics(),
  ]);
}
