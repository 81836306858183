import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import { config, isKZ, isUZ } from "./config";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: isKZ ? "/locales/kz/{{lng}}.json" : isUZ ? "/locales/uz/{{lng}}.json" : "/locales/{{lng}}.json"
    },
    lng: config.lang,
    fallbackLng: 'ru',
    preload: isKZ ? ['ru', 'kz'] : isUZ ? ['ru', 'uz', 'en'] : ['ru'],
    debug: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
