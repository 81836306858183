import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEqual, isNull, defaultTo } from 'lodash';
import { Button, MuiThemeProvider, Popover, IconButton } from '@material-ui/core';
import { CustomInput, SimpleTooltip } from '../../../components';
import {
  IconClose,
  IconErrorSmall,
  IconNewFilterSmall,
  IconNewFilterSmallActive,
} from '../../../common/icons/customIcons';
import {
  defaultFilterInput,
  DefaultFilterPopover,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
  FilterBtnReset,
} from './Filters.styled';

class LegalAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      // address: this.props.currentValue ? this.props.currentValue.join('\n') : '',
      address: defaultTo(this.props.currentValue, ''),
      error: '',
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        address: '',
        error: '',
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state);
  }

  onValueChange = (value) => {
    this.setState({
      address: value,
      error: '',
    });
  };

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      address: defaultTo(this.props.currentValue, ''),
    });
  };

  handleAccept = () => {
    if (this.validateaddress(this.state.address)) {
      this.setState({
        anchorEl: null,
        currentValue: this.state.address,
      });
      this.props.onAccept('address', this.state.address);
    }
  };

  validateaddress = (addresssArr) => {
    let valid = true;
    return valid;
  };

  handleDissmiss = () => {
    this.setState({ anchorEl: null, address: '', error: '' });
    this.props.onDismiss('address');
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <MuiThemeProvider theme={FilterBtn}>
          <FilterButton>
            <Button
              aria-owns={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
              {isNull(this.props.currentValue) ? (
                <IconNewFilterSmall />
              ) : (
                <IconNewFilterSmallActive />
              )}
            </Button>
          </FilterButton>
        </MuiThemeProvider>
        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Юридический адрес')}</h6>

              <MuiThemeProvider theme={FilterBtn}>
                <IconButton className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </IconButton>
              </MuiThemeProvider>
            </FilterTitle>
            <FilterContent>
              <MuiThemeProvider theme={defaultFilterInput}>
                <CustomInput
                  cleanButtonStyles={{ marginTop: -15, display: 'none' }}
                  label={t('Юридический адрес')}
                  onChange={this.onValueChange}
                  value={this.state.address}
                  onBlur={false}
                  name="address-filter"
                />
              </MuiThemeProvider>
              {!!this.state.error &&
                SimpleTooltip(this.state.error, <IconErrorSmall />)}
            </FilterContent>
            <FilterBtns>
              <MuiThemeProvider theme={FilterBtnReset}>
                <Button
                  disabled={!this.state.address}
                  onClick={this.handleDissmiss}>
                  {t('Сбросить')}
                </Button>
              </MuiThemeProvider>
              <Button
                disabled={!this.state.address}
                onClick={this.handleAccept}>
                {t('Применить')}
              </Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </div>
    );
  }
}

LegalAddress.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
  parentId: PropTypes.string.isRequired,
};

export default withTranslation()(LegalAddress);
