import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { IconClose, IconCheck, IconComponentClose } from '../../../../../common/icons/customIcons';
import { difference } from '../../../../../common/utils/utils';
import { createProfileStatus } from '../../../ducks/ProfileStatus/ProfileStatus.actions';
import * as selectors from '../../../ducks/ProfileStatus/ProfileStatus.selectors';
import {
  DialogTitleStyled,
  defaultTheme,
} from '../../../../../common/styles/Common.styled';
import { BlueBtn, CreationCloseBtns, WhiteBtn } from '../../../Profile.styled';
import {
  CreationSiteDialog,
  CreationSiteText,
} from '../styled/CreationStatusModal.styled';

const getDefaultTo = (obj, path, defaultValue) =>
  _.defaultTo(_.get(obj, path, defaultValue), defaultValue);

const statusTypes = [
  {
    id: 'manufacturer',
    value: 'manufacturer',
    label: 'Владелец идентификатора товара (GTIN)',
  },
  {
    id: 'producer',
    value: 'producer',
    label: 'Производственная площадка',
  },
  {
    id: 'importer',
    value: 'importer',
    label: 'Участник оборота товара',
  },
];

const getQueryStatusType = (data) => {
  const result = [];

  Object.keys(data)
    .filter((type) => data[type] === true)
    .forEach((el) => {
      if (el === 'manufacturer') result.push(0);
      if (el === 'producer') result.push(1);
      if (el === 'importer') result.push(2);
    });

  return result;
};

class CreationStatusModal extends React.Component {
  constructor(props) {
    super(props);
    // const gln = this.getGln();
    this.state = {
      partyId: getDefaultTo(this.props, 'editData.id', null),
      data: {
        manufacturer: false,
        producer: false,
        importer: false,
        // name: getDefaultTo(this.props, 'editData.name', ''),
      },
      errors: {},
    };
  }

  getGln = () => {
    const findGln = _.find(this.props.glnDictionary, {
      text: _.toString(this.props.editData.gln),
    });
    return _.defaultTo(findGln, { id: null });
  };

  handleChange = (name, value) => {
    if (!_.isEmpty(this.props.editData.name)) {
      // this.setState(state => ({data: {...state.data, [name]: !state.data[name]}}));
      const newData = {
        [name]: !this.state.data[name],
      };

      return this.setState({ data: newData });
    }

    this.setState((state) => ({
      data: { ...state.data, [name]: !state.data[name] },
    }));
  };

  createStatus = () => {
    const gln = this.getGln();
    const diff = difference(
      this.state.data,
      _.assign({}, this.props.editData, { glnId: gln.id }),
    );
    if (_.isEmpty(diff)) {
      this.props.handleClose();
      return;
    }
    this.props.createStatus(getQueryStatusType(this.state.data), (error) => {
      this.props.handleClose(!error);
    });
  };

  render() {
    const { handleClose, editData, t } = this.props;
    const { data } = this.state;

    const activedButton = Object.values(data).find((el) => el === true);
    const title = _.isEmpty(editData.name)
      ? t('Добавление статуса профиля')
      : t('Редактирование статуса профиля');

    return (
      <MuiThemeProvider theme={CreationSiteDialog}>
        <Dialog open>
          <DialogTitle>
            <DialogTitleStyled>{title}</DialogTitleStyled>
            <MuiThemeProvider theme={CreationCloseBtns}>
              <Button className="backEmpty" onClick={handleClose}>
                <IconComponentClose />
              </Button>
            </MuiThemeProvider>
          </DialogTitle>
          <DialogContent>
            <FormGroup>
              {statusTypes.map((el) => {
                return (
                  <MuiThemeProvider theme={defaultTheme} key={el.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data[el.value]}
                          onChange={() => this.handleChange(el.value)}
                          value={el.value}
                          checkedIcon={<IconCheck />}
                        />
                      }
                      label={t(el.label)}
                    />
                    {el.value === 'manufacturer' && (
                      <CreationSiteText>
                        {_.isEmpty(editData.name) && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t(
                                'Внимание! Добавление статуса владельца идентификатора товара подразумевает наличие действующего членства в организации GS1',
                              ),
                            }}
                          />
                        )}
                      </CreationSiteText>
                    )}
                  </MuiThemeProvider>
                );
              })}
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <MuiThemeProvider theme={WhiteBtn}>
              <Button onClick={handleClose}>
                {t('Отменить')}
              </Button>
            </MuiThemeProvider>
            <MuiThemeProvider theme={BlueBtn}>
              <Button
                disabled={!activedButton}
                onClick={this.createStatus}>
                {t('Сохранить')}
              </Button>
            </MuiThemeProvider>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

CreationStatusModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  createStatus: PropTypes.func.isRequired,
  glnDictionary: PropTypes.array.isRequired,
  editData: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createStatus: (data, partyId, cbk) =>
        createProfileStatus(data, partyId, cbk),
    },
    dispatch,
  );
};

const mapStateToProps = (state) => ({
  glnDictionary: selectors.glnDictionary(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(CreationStatusModal);
