import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, MuiThemeProvider } from '@material-ui/core';
import {
  GridActionsPageSize,
  PageSizeBtn,
  PageSizeTitle,
} from './GridPageSize.styled';

class GridPageSize extends React.Component {
  shouldComponentUpdate(nextProps) {
    const equal = [];
    const nameProps = ['activeValue', 'disabled', 'locale'];
    _.forEach(nameProps, (i) =>
      equal.push(!_.isEqual(this.props[i], nextProps[i])),
    );
    return _.some(equal);
  }

  changePageSize = (e) => {
    this.props.onChangePageSize(_.toNumber(e.currentTarget.value));
  };

  render() {
    const { activeValue, disabled, t } = this.props;
    return (
      <MuiThemeProvider theme={PageSizeBtn}>
        <GridActionsPageSize className={cx({ disabled: disabled })}>
          <PageSizeTitle>{t('Показать по')}:</PageSizeTitle>
          {_.map([10, 50, 100], (n) => (
            <Button
              key={`btn_size_${n}`}
              disabled={activeValue === n || disabled}
              value={n}
              onClick={this.changePageSize}>
              {n}
            </Button>
          ))}
        </GridActionsPageSize>
      </MuiThemeProvider>
    );
  }
}

GridPageSize.propTypes = {
  onChangePageSize: PropTypes.func.isRequired,
  activeValue: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  locale: state.profileReducer.locale,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(GridPageSize);
