import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import { IconCopySimple } from '../../../common/icons/customIcons';
import {
  ColumnCellInner,
  ExemplarIconContainer,
  DefaultTooltipCenter,
} from '../../../common/styles/Common.styled';
import { ColumnHeaderDate } from '../Catalog.styled';

export default class CreatedCell extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { value, t } = this.props;

    const packingPurposeType = (value) => {
      switch (value) {
        case 'consumer':
          return t('Потребительская');
        case 'transport':
          return t('Транспортная');
        case 'group':
          return t('Групповая');
        case 'techCard':
          return t('Техническая карточка');
        case 'unmarkable':
          return t('Немаркируемый товар');
        default:
          return '';
      }
    };

    const checkConsumerPacking = (isTechCard, isUnmarkable) => {
      if (isTechCard) {
        return isUnmarkable
          ? packingPurposeType('unmarkable')
          : packingPurposeType('techCard');
      } else return packingPurposeType('consumer');
    };

    return (
      <ColumnHeaderDate>
        <ColumnCellInner>
          {value.packingPurpose !== 'consumer'
            ? packingPurposeType(value.packingPurpose)
            : checkConsumerPacking(value.isTechCard, value.isUnmarkable)
          }
          {value.isExemplar ? (
            <MuiThemeProvider theme={DefaultTooltipCenter}>
              <Tooltip
                placement="bottom"
                title={t('Карточки товаров в совместном управлении участниками системы')}
              >
                <ExemplarIconContainer left='8px'>
                  <IconCopySimple />
                </ExemplarIconContainer>
              </Tooltip>
            </MuiThemeProvider>
          ) : null}
        </ColumnCellInner>
      </ColumnHeaderDate>
    );
  }
}

CreatedCell.propTypes = {
  value: PropTypes.object,
};
