import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators, compose } from 'redux';
import {
    AnalyticInstruction,
    AnalyticInstructionTitle,
    AnalyticInstructionMap,
    AnalyticInstructionLeft,
    AnalyticInstructionLeftTitle,
    AnalyticInstructionBlock,
    AnalyticInstructionEllipse,
    AnalyticInstructionSvg,
    AnalyticInstructionName,
    AnalyticInstructionDashed,
    AnalyticInstructionRight,
    AnalyticInstructionRightTitle,
    AnalyticInstructionService,
    AnalyticInstructionFourSvg,
    AnalyticInstructionBottom,
    CreationCloseBtns,
    InstructionDialog,
} from '../styled/Instruction.styled';
import {
    IconUserSetting,
    IconGtin,
    IconList,
    IconImage,
    IconFile,
    IconShield,
    IconFlash,
    IconEllipseInstr,
    IconCar,
    IconChecks,
    IconComponentClose,
    IconQuestion,
} from '../../../common/icons/customIcons';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MuiThemeProvider,
    Tooltip,
} from '@material-ui/core';
import colors from '../../../common/styles/Common.colors';

class Instruction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        const { t, handleClose } = this.props;
        return (
            <MuiThemeProvider theme={InstructionDialog}>
                <Dialog open>
                    <DialogTitle>

                        <AnalyticInstructionTitle>
                            {t('Краткая инструкция пользователя')}
                        </AnalyticInstructionTitle>

                        <MuiThemeProvider theme={CreationCloseBtns}>
                            <Button className="backEmpty" onClick={handleClose}>
                                <IconComponentClose />
                            </Button>
                        </MuiThemeProvider>
                    </DialogTitle>
                    <DialogContent>
                        <AnalyticInstruction>
                            <AnalyticInstructionMap>
                                <AnalyticInstructionLeft>
                                    <AnalyticInstructionLeftTitle>
                                        {t('Действия пользователя')}
                                    </AnalyticInstructionLeftTitle>
                                    <AnalyticInstructionBlock>
                                        <AnalyticInstructionEllipse>
                                            <IconEllipseInstr />
                                            <AnalyticInstructionSvg>
                                                <IconUserSetting />
                                            </AnalyticInstructionSvg>
                                        </AnalyticInstructionEllipse>
                                        <AnalyticInstructionName>
                                            {t('Настройка профиля')}
                                        </AnalyticInstructionName>
                                        <AnalyticInstructionDashed />
                                    </AnalyticInstructionBlock>
                                    <AnalyticInstructionBlock>
                                        <AnalyticInstructionEllipse>
                                            <IconEllipseInstr />
                                            <AnalyticInstructionSvg>
                                                <IconGtin />
                                            </AnalyticInstructionSvg>
                                        </AnalyticInstructionEllipse>
                                        <AnalyticInstructionName>
                                            {t('Регистрация кодов упаковок (GTIN)')}
                                        </AnalyticInstructionName>
                                    </AnalyticInstructionBlock>
                                    <AnalyticInstructionBlock>
                                        <AnalyticInstructionEllipse>
                                            <IconEllipseInstr />
                                            <AnalyticInstructionSvg>
                                                <IconList color={colors.Icons.background} />
                                            </AnalyticInstructionSvg>
                                        </AnalyticInstructionEllipse>
                                        <AnalyticInstructionName>
                                            {t('Заполнение обязательных атрибутов')}
                                        </AnalyticInstructionName>
                                    </AnalyticInstructionBlock>
                                    <AnalyticInstructionBlock>
                                        <AnalyticInstructionEllipse>
                                            <IconEllipseInstr />
                                            <AnalyticInstructionSvg>
                                                <IconImage color={colors.Icons.background} />
                                            </AnalyticInstructionSvg>
                                        </AnalyticInstructionEllipse>
                                        <AnalyticInstructionName>
                                            {t('Загрузка фотоконтента')}
                                        </AnalyticInstructionName>
                                    </AnalyticInstructionBlock>
                                    <AnalyticInstructionBlock>
                                        <AnalyticInstructionEllipse>
                                            <IconEllipseInstr />
                                            <AnalyticInstructionSvg>
                                                <IconFile color={colors.Icons.background} />
                                            </AnalyticInstructionSvg>
                                        </AnalyticInstructionEllipse>
                                        <AnalyticInstructionName>
                                            {t('Внесение сведений по разрешительным документам')}
                                        </AnalyticInstructionName>
                                    </AnalyticInstructionBlock>
                                    <AnalyticInstructionBlock>
                                        <AnalyticInstructionEllipse>
                                            <IconEllipseInstr />
                                            <AnalyticInstructionSvg>
                                                <IconShield />
                                            </AnalyticInstructionSvg>
                                        </AnalyticInstructionEllipse>
                                        <AnalyticInstructionName>
                                            {t('Прохождение модерации')}
                                        </AnalyticInstructionName>
                                        <AnalyticInstructionDashed />
                                    </AnalyticInstructionBlock>
                                    <AnalyticInstructionBlock>
                                        <AnalyticInstructionEllipse>
                                            <IconEllipseInstr />
                                            <AnalyticInstructionSvg>
                                                <IconFlash />
                                            </AnalyticInstructionSvg>
                                        </AnalyticInstructionEllipse>
                                        <AnalyticInstructionName>
                                            {t('Подписание ЭЦП и публикация')}
                                        </AnalyticInstructionName>
                                    </AnalyticInstructionBlock>
                                </AnalyticInstructionLeft>
                                <AnalyticInstructionRight>
                                    <AnalyticInstructionRightTitle>
                                        {t('Контент–сервис провайдер')}
                                    </AnalyticInstructionRightTitle>
                                    <AnalyticInstructionService>
                                        <AnalyticInstructionEllipse>
                                            <IconEllipseInstr />
                                            <AnalyticInstructionSvg>
                                                <IconCar />
                                            </AnalyticInstructionSvg>
                                        </AnalyticInstructionEllipse>
                                        <AnalyticInstructionName>
                                            {t('Предоставляете образцы товаров')}
                                        </AnalyticInstructionName>
                                    </AnalyticInstructionService>
                                    <AnalyticInstructionService>
                                        <AnalyticInstructionEllipse>
                                            <IconEllipseInstr />
                                            <AnalyticInstructionSvg>
                                                <IconGtin />
                                            </AnalyticInstructionSvg>
                                        </AnalyticInstructionEllipse>
                                        <AnalyticInstructionName style={{ paddingBottom: '90px' }}>
                                            {t('Регистрация кодов упаковок (GTIN)')}
                                        </AnalyticInstructionName>
                                        <AnalyticInstructionFourSvg style={{ position: 'relative' }}>
                                            <AnalyticInstructionDashed style={{ transform: 'rotate(90deg)', top: '-90px', left: '-46px', width: '91px', height: '91px' }} />
                                            <AnalyticInstructionSvg fourSvg>
                                                <IconImage color={'#AAA9A9'} />
                                            </AnalyticInstructionSvg>
                                            <AnalyticInstructionSvg fourSvg>
                                                <IconList color={'#AAA9A9'} />
                                            </AnalyticInstructionSvg>
                                            <AnalyticInstructionSvg fourSvg>
                                                <IconFile color={'#AAA9A9'} />
                                            </AnalyticInstructionSvg>
                                            <AnalyticInstructionSvg fourSvg>
                                                <IconShield />
                                            </AnalyticInstructionSvg>
                                        </AnalyticInstructionFourSvg>
                                        <AnalyticInstructionName style={{ padding: '0 0 51px' }}>
                                            {t('Заполняем атрибуты, фотографируем, проходим модерацию')}
                                        </AnalyticInstructionName>
                                    </AnalyticInstructionService>
                                    <AnalyticInstructionService>
                                        <AnalyticInstructionEllipse>
                                            <IconEllipseInstr />
                                            <AnalyticInstructionSvg>
                                                <IconChecks />
                                            </AnalyticInstructionSvg>
                                        </AnalyticInstructionEllipse>
                                        <AnalyticInstructionName>
                                            {t('Принимаете результат')}
                                        </AnalyticInstructionName>
                                    </AnalyticInstructionService>
                                </AnalyticInstructionRight>
                            </AnalyticInstructionMap>
                            <AnalyticInstructionBottom>
                                <div>
                                    {t(
                                        'Список аккредитованных контент-сервис провайдеров',
                                    )}
                                </div>
                                <Tooltip
                                    arrow
                                    placement="top"
                                    title={t('Предоставляется по запросу')}>
                                    <div><IconQuestion /></div>
                                </Tooltip>
                            </AnalyticInstructionBottom>
                        </AnalyticInstruction>
                    </DialogContent>
                </Dialog>
            </MuiThemeProvider>
        )
    }
}

Instruction.propTypes = {

};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {

        },
        dispatch,
    );
};

const mapStateToProps = (state) => ({

});

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(Instruction);