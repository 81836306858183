import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import colors from './Common.colors';
/*Styled components*/
export const NonMarkedLabel = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
`;

export const MarkedLabel = styled.div`
  display: flex;
  justify-content: flex-end;
  color: red;
  font-size: 14px;
  font-weight: 400;
`;
export const BlocksDivider = styled.div`
  margin-top: 40px;
`;
export const Header = styled.div`
  min-height: 430px;
  padding: 6.5% 0 115px 0;
  background: ${colors.Home.background};
  text-align: center;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 1024px) {
    padding: 40px 0;
    min-height: 365px;
    span {
      transform: scale(0.6) translateX(-40px);
      display: inline-block;
    }
  }
`;
export const HeaderLangRow = styled.div`
  display: flex;
  padding: 20px 70px 0 0;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
`;
export const Footer = styled.div`
  text-align: center;
  font-size: 16px;
  color: #52535a;
  font-weight: 600;
  padding: 45px 0;
  a {
    color: #4aa5e8;
  }
  @media (max-width: 1024px) {
    padding: 45px 20px;
  }
`;
export const StyledH1 = styled.h1`
  font-size: 32px;
  color: ${colors.Home.title};
  text-transform: uppercase;
  margin: 75px 0 0 0;
  line-height: 43px;
  text-align: center;
  @media (max-width: 1024px) {
    margin: 25px 0 0 0;
    padding: 0 20px;
    font-size: 25px;
  }
`;
export const Content = styled.div``;
export const TabsCont = styled.div`
  padding: 35px 110px 0;
  border-bottom: 1px solid #f2f2f2;
  @media (max-width: 1024px) {
    padding: 24px 10px 0px;
    display: flex;
    justify-content: space-around;
  }
`;
export const SelectContLogin = styled.div`
  padding: 40px 110px;
  overflow: hidden;
  @media (max-width: 1024px) {
    padding: 40px 80px;
  }
  @media (max-width: 700px) {
    padding: 40px 20px;
  }
`;
export const LoginFormCont = styled.div`
  width: 1180px;
  box-shadow: 0px 4px 10px rgba(41, 49, 53, 0.1);
  border-radius: 6px;
  margin: 0 auto;
  margin-top: -70px;
  background: #fff;
  position: relative;
  @media (max-width: 1440px) {
    width: 860px;
  }
  @media (max-width: 1024px) {
    width: 620px;
  }
  @media (max-width: 700px) {
    width: 90%;
  }
`;
export const LoginFormContAuth = styled(LoginFormCont)`
  width: 860px;
  box-shadow: 0px 4px 10px rgba(41, 49, 53, 0.1);
  border-radius: 6px;
  margin: 0 auto;
  margin-top: -70px;
  background: #fff;
  @media (max-width: 1440px) {
    width: 860px;
  }
  @media (max-width: 1024px) {
    width: 620px;
  }
  @media (max-width: 700px) {
    width: 90%;
  }
`;
export const SelectOption = styled.option`
  font-size: 14px;
  color: #52535a;
  padding: 15px 20px;
  width: 100%;
`;
export const GridActions = styled.div`
  display: flex;
  padding-bottom: 28px;
  justify-content: flex-end;
`;
export const GridActionsPie = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  justify-content: flex-start;
  flex-grow: 1;
`;
export const GridActionsLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: center;
`;
export const GridActionsRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center;
`;
export const CountCont = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.Common.textGrey};
  font-weight: normal;
  flex-shrink: 0;
  margin-right: 8px
`;
export const PageGridRight = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
export const CompanyBlock = styled.div`
  display: flex;
  //border-bottom: 1px solid #f2f2f2;
  //padding: 28px 36px;
  padding: 28px 36px 0;
`;
export const CompanyBlockMedia = styled(CompanyBlock)`
  flex-direction: column; 
  position: ${props => props.edit ? 'fixed' : 'sticky'};
  top: ${props => props.edit ? '217px' : '108px'};
  left: ${props => props.edit ? '40px' : '0'};   
  right: ${props => props.open ? '40px' : 'none'}; 
  padding: ${props => props.edit ? '28px 4px 0' : '28px 36px 0'};
  background-color: #ffffff;  
`;
export const CompanyBlockMediaTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 32px 0;
`;
export const CompanyBlockMediaContent = styled.div`
  height: calc(100vh - 420px);
  min-height: 100px;
  overflow-y: ${props => props.isFullPhotoContent ? 'auto' : 'hidden'};
  overflow-x: hidden;
`;
export const SmallEl = styled.span`
  color: #ff4b4b;
`;
export const ChartContInner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  transform: translate(0);
  background: rgba(255, 255, 255, 1);
  transition: all 0.3s ease;
  z-index: 2;
  font-weight: 600;
  font-size: 20px;
  color: #63666a;
`;
export const SelectedCertMin = styled.div`
  flex-basis: 50%;
  flex-shrink: 0;
  span {
    display: block;
    white-space: normal;
    word-break: break-word;
  }
`;
export const InputContainer = styled.div`
  :nth-of-type(1) {
    margin-bottom: 4px;
  }
  position: relative;
`;
export const InputContainerMail = styled(InputContainer)`
  :nth-of-type(1) {
    margin-bottom: 0;
  }

`;
export const InputIcon = styled.span`
  position: absolute;
  top: 35px;
  right: 16px;
  transform: translateY(-3px);
`;
export const FieldIcon = styled(InputIcon)`
  top: 16px;
  transform: 'none';
`;
export const AddressInputIcon = styled.span`
  position: absolute;
  top: 34%;
  right: 5px;
  transform: translateY(-3px);
`;
export const InputIconLeft = styled.span`
  position: absolute;
  top: ${props => props.date ? '40px' : '50%'};
  right: 44px;
  transform: translateY(-2px);
`;
export const SelectIconLeft = styled.span`
  position: absolute;
  top: 50%;
  right: 42px;  
  transform: translateY(-4px);
`;
export const BtnContPaddingless = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;  
`;
export const MoreIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 44px;
`;
export const MoreIconAuto = styled.div`
  align-self: flex-end;
  transform: translateY(2px);
`;
/*Таблица*/
export const ColumnHeader = styled.div`
  display: flex;
  min-height: 20px;
  padding: 20px 6px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: ${colors.Common.textColor};
`;
export const ColumnHeaderProfile = styled(ColumnHeader)`
  padding: 18px 12px;
  white-space: ${props => props.small ? "pre-wrap" : "nowrap"};
`;
export const ColumnHeaderPhoto = styled(ColumnHeader)`
  padding: 18px 30px 18px 12px;
  `;
export const ColumnHeaderIsValid = styled(ColumnHeader)`
  padding: 20px 3px;
`;
export const ColumnCellInner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
export const ColumnCellCheck = styled.div`
  padding: 0 10px;
  text-align: center;
  width: 100%;
  height: 100%;
`;
export const ColumnCellCheckbox = styled(ColumnCellCheck)`
padding: 0 13px 0 10px; 
`;
export const ColumnCellCheckHeader = styled(ColumnCellCheck)`
  padding: 0 13px;
  background-color: #F5F5F5;
`;
export const ColumnCellCheckInner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const GridTableContainer = styled.div`
  height: ${props => props.profile ? '100%' : 'calc(100% - 100px)'};
  overflow: hidden;

  .table-grid--list {
    height: 100%;
  }

  .ReactTable {
    max-height: 100%;
    height: 100%;
    border: none;
    // min-height: calc(100vh - 183px);
    // @media (min-width: 1920px) {
    //   min-height: calc(100vh - 209px);
    // }
  }
  &.customTable {
    .ReactTable {
      border: none;
    }
  }
  &.customTable.customBorderTable {          
    .rt-tr-group {
      border-left: 1px solid #f2f2f2; 
      border-right: 1px solid #f2f2f2; 
    } 
    .rt-table {    
      max-height: ${window.innerHeight - 264 >= 700 ? '655px' : 'calc(100vh - 309px)'};
      min-height: 150px;
      overflow-x: hidden;
      scrollbar-width: thin;      
    }   
  }
  .ReactTable .rt-table {
    overflow-x: auto;
    height: 100%;
    flex: initial !important;
    min-height: ${state => state.noData ? '400px' : 'auto'};   
  }

  .ReactTable .rt-tbody {
    // overflow: visible !important;
    overflow-x: visible;
    overflow-y: visible;

      // max-height: ${props => props.height};
      // min-height: ${props => props.minHeight};
  }

  .ReactTable .rt-thead {
    max-height: 64px;
    position: sticky;
    z-index: 12;
  }
  .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: none !important;
    flex: 0 0 auto;
  }
  .ReactTable .rt-tr {
    background: #F5F5F5 !important;
    height: 56px;
  }
  .ReactTable .rt-noData {
    display: block;
    position: absolute;
    left: 0;
    top: 63px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 119px);
    transform: translate(0);
    background: rgba(255, 255, 255, 1);
    transition: all 0.3s ease;
    z-index: 2;
    pointer-events: auto;
    padding: 20px;
    font-weight: 600;
    font-size: 16px;
    color: ${colors.Common.textColor};
    button {
      padding: 0;
      background: none;
      box-shadow: none;
      color: #4aa5e8;
      font-size: 16px;
      text-transform: none;
      margin-top: 10px;
      font-weight: 600;
      display: inline-block;
      margin: 0;
      line-height: 3px;
      vertical-align: baseline;
      min-width: auto;
    }
  }
  .ReactTable .rt-thead {
    box-shadow: none !important;
    border-bottom: none;
  }
  .ReactTable .rt-thead .rt-th {
    border-bottom: none;
    border-right: none;
    padding: 0;
    text-align: left;
    align-items: center;
    display: flex;
    font-weight: 500;
    overflow: visible;
    &:nth-last-of-type(1) {
      padding: 0;
    }
    &.rthfc-th-fixed-left {
      position: sticky !important;
      z-index: 10;     
    }
  }
  .ReactTable .rt-tbody .rt-td {
    border-right: none;
    border-bottom: 1px solid #E7E7E7 !important;
    padding: 0;
    text-align: left;
    background: #fff;
    transition: all 0.4s ease;
    font-size: 14px;
    word-break: break-word;
    white-space: normal;
    &:first-of-type {
      border-left: 1px solid #E7E7E7 !important;      
    }
    &:last-of-type {      
      border-right: 1px solid #E7E7E7 !important;
    }
    &:nth-last-of-type(1) {
      padding: 0;
      overflow: visible;
    }
    &.rthfc-td-fixed-left {
      position: sticky !important;
      z-index: 10;
      background: #fff;
      left: 0;
    }
    &.rthfc-td-fixed-right {
      position: sticky !important;
      z-index: 10;
      background: #fff;
      right: 0;
    }
  }
  .ReactTable .rt-tbody .rt-tr {
    position: relative;
  }
  .ReactTable .rt-tbody .rt-tr:hover .rt-td {
    background: #f9f9f9;
  }
  .ReactTable .rt-tbody .rt-tr:hover button {
    opacity: 1;
  }
`;

export const GridTableContainerRedesign = styled(GridTableContainer)`
  height: calc(100% - 72px);  
`;

export const AdresTitle = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-start;
  position: relative;
  padding-right: 50px;
  flex-direction: column;
`;
export const AdresTitleH3 = styled.h3`
  font-size: 13px;
  font-weight: 600;
  margin: 5px 0 0;
  text-transform: uppercase;
`;
export const MultipleTransportTitleH3 = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.Common.textColor};
  margin: 0;
`;
export const MultipleTransportBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const MultipleTransportEl = styled.div`
  :nth-of-type(odd) {
    width: calc(70% - 20px);
    margin-right: 20px;
  }
  :nth-of-type(even) {
    width: 30%;
  }
`;

export const AdresTitleMedia = styled.h2`  
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 20px;
`;
export const AdresFields = styled.div`
  //width: 80%;
  width: 100%;
`;
/*Грид*/
export const GridBlock = styled.div`
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr [col] 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 3px 20px;
  display: grid;
`;
export const GridBlockA = styled(GridBlock)`
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr [col] 1fr;
  grid-template-rows: auto auto;
`;
export const GridBlockB = styled(GridBlock)`
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr;
  grid-template-rows: auto auto;
`;
export const GridBlockC = styled(GridBlock)`
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr;
  grid-template-rows: auto auto;
`;
export const GridBlockD = styled(GridBlock)`
  grid-template-columns: [col] 2fr [col] 1fr;
  grid-template-rows: auto;
`;
export const GridBlockE = styled(GridBlock)`
  grid-template-columns: [col] 1fr [col] 1fr;
  grid-template-rows: auto;
`;
export const GridBlockF = styled(GridBlock)`
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr;
  grid-template-rows: auto auto;
`;
export const GridItemA = styled.div`
  grid-column: col / span 2;
`;
export const GridItemC = styled.div`
  grid-column: col 2 / span 2;
`;
export const GridItemB = styled.div`
  grid-column: col 3 / span 2;
`;
export const GridItemD = styled.div`
  grid-column: col 5 / span 2;
`;
export const GridItemSmall = styled.div`
  grid-column: col;
`;
export const GridItemSmallA = styled.div`
  grid-column: col 2;
`;
export const GridItemSmallB = styled.div`
  grid-column: col 3;
`;
export const GridItemSmallC = styled.div`
  grid-column: col 4;
`;
export const GridItemSmallD = styled.div`
  grid-column: col 5;
`;
/*Окна*/
export const DialogTitleStyled = styled.div`
  font-size: 21px;
  line-height: 24px;
  color: ${colors.Common.textColor};
  margin: 0;
  font-weight: 600;
  text-align: start;  
`;

export const DialogTitleOfContentStyled = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: ${colors.Common.textColor};  
  margin: ${props => props.margin ? props.margin : 0};
  font-weight: 600;
`;
export const DialogTitlePhotoStyled = styled.div`
  font-size: 20px;
  color: #63666a;
  margin: 0 auto;
  font-weight: 600;
`;
export const DialogTitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 36px;
  .backChanges {
    position: absolute;
    top: 0;
    left: 0;
  }
  .backEmpty {
    background: none;
    border: none;
  }
`;
export const ButtonActionCont = styled.div`
  position: absolute;  
  width: 50px;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;  
  justify-content: center;
  button {
    background: transparent;
    width: 100%;
    border-radius: 0;  
    box-shadow: none;    
    transition: all 0.4s ease;
    min-width: 50px;
    padding: 0;
    &:hover {   
      box-shadow: none;
      background: transparent !important;      
      path {        
        fill: #AAA9A9;
        &:first-child {
          stroke: #AAA9A9;
        }
      }
    }
  }
`;
export const NoDataInner = styled.div`
  text-align: center;  
  font-size: 21px;
  line-height: 24px; 
  margin: auto;
`;
export const NoDataIconHolder = styled.div`
  display: inline-block;
  margin-bottom: 45px;
`;
export const StatusLine = styled.span`
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    padding-left: 8px;
    font-weight: 400;
  }
`;
export const GridEmpty = styled.div`
  //padding: 140px 20px 20px;
  text-align: center;
  line-height: 1.5;
  display: flex;
  align-items: center;
  //height: calc(100vh - 190px);
  height: calc(100% - 100px);
  flex-direction: column;
  justify-content: center;
`;
export const GridEmptyText = styled.div`
  font-size: 20px;
  color: ${colors.Common.textColor};
  text-align: center;
  line-height: 1;
  font-weight: 600;
  a {
    color: #4aa5e8;
    text-decoration: none;
  }
`;
export const GridEmptyTitle = styled.div`
  font-size: 21px;
  line-height: 28px;
  color: ${colors.Common.textColor};
  text-align: center;
  font-weight: 600;
  margin-bottom: 8px;
`;
export const GridEmptySubstring = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.Common.textColor};
  text-align: center;
  font-weight: 400;
`;
export const DialogIcon = styled.div`
  margin-bottom: 18px;
`;
export const DialogText = styled.div`
  font-size: 20px;
  color: #52535a;
  font-weight: 600;
  text-align: center;
  line-height: 30px;
`;
/*Кнопки*/
export const SortingBtn = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 27px;
  display: flex;
  align-items: center;
  transition: 0.4s ease;
  span {
    color: ${colors.Common.textColor};
  }
  // &:hover {
  //   color: #fff;
  //   box-shadow: inset 0 -10px 20px -11px rgba(242, 242, 242, 1);
  // }
`;
export const WithoutSortingBtn = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //padding-left: 11px;
  right: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: 0.4s ease;
  // &:hover {
  //   color: #fff;
  //   box-shadow: inset 0 -10px 20px -11px rgba(242, 242, 242, 1);
  // }
`;

export const WithoutSortingTitle = styled.span`
  position: absolute;
  left: ${(props) => props.left};
`;
export const StickyBtns = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 5px;
  bottom: 200px;
  align-items: flex-end;
`;
export const StickyBtnsAnalytic = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 25px;
  bottom: 30px;
  align-items: flex-end;

  @media (min-width: 1920px) {
    display: none;
  }
`;
export const ProductViewMdiaItem = styled.div`
  padding: 8px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  position: relative;
  //max-width: 110px;
  width: 142px;
  height: 142px;
  //margin: 0 20px 10px 0;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    //width: 100%;    
    max-width: 85px;   
    max-height: 85px;
    object-fit: contain;
  }
`;

export const ProductFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px #E7E7E7 solid;
  background-color: #FFFFFF;
  padding: 32px 36px 24px; 
  position: ${props => props.view ? 'sticky' : 'fixed'};
  right: ${props => props.view ? '20px' : '40px'};
  left: ${props => props.view ? '20px' : '40px'};
  bottom: ${props => props.view ? '0' : '28px'};
  width: calc(100vw - 95px);
  z-index: 5;
  &::after {
    content: "";
    position: absolute;
    bottom: -28px;
    left: 0;
    background-color: #f8f8f8; 
    width: 100%;
    height: 28px;
  }
  & > button {
    margin-left: 20px;
  }
`;
export const BlueBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '44px',
        maxHeight: '44px',
        backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
        textTransform: 'inherit',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        marginLeft: '20px',
        borderRadius: '4px',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '10px 35px',
      },
    },
  },
});
export const WhiteBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '44px',
        maxHeight: '44px',
        //marginRight: '20px',
        border: '1px solid #DCDCDC',
        borderRadius: '4px',
        textTransform: 'inherit',
        color: `${colors.Common.textColor}`,
        fontWeight: '600',
        '&:hover': {
          backgroundColor: '#F5F5F5 !important',
          color: `${colors.Common.textColor} !important`,
          borderColor: '#F5F5F5',
        },
        '&$disabled': {
          color: '#C9C7C7',
        },
      },
      text: {
        padding: '9px 24px',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0',
      },
    },
  },
});
export const ProfileContainer = styled.div`
  padding: 28px 40px;
  padding: 28px 40px 0;
  height: calc(100% - 80px);
  //overflow-y: ${props => props.overflow};
  height: ${props => props.value === 1 ? (props.valueProfileManagement !== 4 ? 'calc(100vh - 220px)' : 'calc(100% - 80px)') : 'calc(100% - 80px)'};
`;
export const AnalyticContainer = styled.div`
  padding: 30px;
  overflow: auto;
`;
export const PackageTitleHolder = styled.div`
  position: relative;
  padding-top: 45px;
`;
export const FieldTitle = styled.h3`
  color: ${colors.Common.textColor};
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin: 0 16px 0 0;
`;
export const CustomPagination = styled.div`
  position: relative;
  //padding-top: 45px;
  .Table__pageButton--active {
    color: ${colors.Grid.paginationColor};
    font-weight: normal;
    background-color: ${colors.Grid.paginationBackground};
    border-radius: 4px;
  }
  .Table__arrowIcon:hover > path {
    stroke: #AAA9A9;
  }
`;
export const FieldIconsHolder = styled.div`
  position: absolute;
  top: 15px;
  right: 35px;
  height: calc(100% - 15px);
  display: flex;
  align-items: center;
}
`;
export const FieldIconHolder = styled(FieldIconsHolder)`    
  width: auto;
  right: 40px;
}
`;
export const HeaderSearchIcon = styled.div`
  position: absolute;
  top: 80%;
  right: 20px;
  transform: translateY(-50%);
}
`;
export const LangMenuIcon = styled.span`
  margin-right: 25px;
  flex-shrink: 0;
`;
export const LangMenuIconHome = styled(LangMenuIcon)`
  margin-right: 12px;
`;
export const HomeLangSelect = styled.div`
  position: absolute;
  top: 20px;
  left: 4px;  
`;
export const ProductsGridContainer = styled.div`
  padding: 28px 36px 0;
  background: #ffffff;
  position: relative;
  height: 100%;

  // @media (max-width: 1919px) {
  //   height: calc(100% - 70px);
  // }

  // @media (min-width: 600px) {
  //   height: calc(100% - 64px);
  // }
`;
export const ManagementSiteCont = styled(ProductsGridContainer)`
  padding: 0;
  background: none;
  height: calc(100% - 51px);
  .ReactTable .Table__pagination {
    border-top: 1px solid #f2f2f2;
  }
`;
export const ManagementSiteActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px 40px;
  //border-bottom: 1px solid #f2f2f2;
`;
export const TableEmpty = styled(GridEmpty)`
  height: calc(100vh - 325px);
`;
export const StatusBlock = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  .icon {
    margin-right: 13px;
  }
  &.valid {
    color: #2fa93b;
  }
  &.invalid {
    color: #ff4b4b;
  }
`;
export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${props => props.margin ? props.margin : 0};
`;
export const FieldContainer = styled.div`
  width: calc((100% - 20px)/2);
  margin: ${props => props.margin ? props.margin : 0};
  position: relative;
`;
export const ExemplarIconContainer = styled.span`
  height: ${props => props.height ? props.height : '20px'};
  margin-right: ${props => props.right ? props.right : 0};
  margin-left: ${props => props.left ? props.left : 0};
`;
export const EllipsisStringContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

/*Material themes*/
export const ClearBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiIconButton: {
      root: {
        transition: ".4s ease",
        boxShadow: "none !important",
        padding: "0",
      },
      colorSecondary: {
        color: "rgba(0, 0, 0, 0.54)",
        padding: '0',
      },
    },
    MuiButtonBase: {
      root: {
        backgroundColor: "transparent!important",
        padding: "0 10px",
        '&:hover': {
          '& path': {
            stroke: `${colors.Common.textColor}`,
          },
        },
      },
    }
  }
});
export const MainDefaultTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
        border: 'none',
        fontSize: '16px',
        color: `${colors.Buttons.buttonPrimary.color}`,
        textTransform: 'none',
        borderRadius: '4px',
        height: 'auto',
        width: 'auto',
        minWidth: '200px',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        transition: 'background-color 0.4s ease',
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
      },
      textPrimary: {
        color: `${colors.Buttons.buttonPrimary.color}`,
        '&:hover': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}`,
        },
      },
      textSecondary: {
        color: `${colors.Common.textColor}`,
        border: '1px solid #DCDCDC',
        backgroundColor: '#fff',
        marginRight: '20px',
        // minWidth: '310px',
        '&:hover': {
          color: `${colors.Common.textColor} !important`,
          backgroundColor: '#F5F5F5 !important',
        },
        '&$disabled': {
          color: '#C9C7C7',
          backgroundColor: 'transparent !important',
        },
      },
      text: {
        padding: '10px 35px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
      },
      label: {
        lineHeight: '19px',
      },
      disabled: {},
    },
    MuiInput: {
      root: {
        border: '1px solid #C9C7C7',
        borderRadius: '4px',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
        '&:required': {
          borderLeft: '4px solid #E32A38',
        },
        '&$error': {
          border: '1px solid #E32A38',
        },
      },
      input: {
        color: `${colors.Common.textColor}`,
        padding: '23px 44px 7px 15px',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        textOverflow: 'ellipsis',
        '&:required': {
          borderLeft: '4px solid #E32A38',
          borderRadius: '4px',
          "&[valid='true']": {
            borderLeft: 'none',
          },
          "&[valid='false']": {
            borderLeft: `4px solid #E32A38`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '16px',
        lineHeight: '24px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px, 32px) scale(1)',
      },
      shrink: {
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      filled: {
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      disabled: {},
    },
    MuiInputBase: {
      multiline: {
        paddingTop: '23px',
        '& textarea': {
          paddingTop: '0',
        }
      },
      input: {
        color: `${colors.Common.textColor}`,
        height: 'auto',
        fontFamily: 'SegoeUI',
      },
      root: {
        fontFamily: 'SegoeUI',
        '&$disabled': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
          },
        },
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'SegoeUI',
        color: `${colors.Common.textGrey}`,
        '&$focused': {
          color: `${colors.Common.textGrey} !important`,
        },
        '&$disabled': {
          color: `${colors.Common.textGrey} !important`,
        },
      },
      asterisk: {
        display: 'none',
      },
      disabled: {},
    },
    MuiCheckbox: {
      root: {
        color: `${colors.Common.textColor}`,
        checked: {
          color: `${colors.Input.checkBox}`,
        },
      },
      colorSecondary: {
        '&$checked': {
          color: `${colors.Input.checkBox}`,
        },
      },
    },
    MuiButtonBase: {
      root: {
        width: '20px',
        height: '20px',
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '0',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0',
      },
      label: {
        marginLeft: '20px',
        marginBottom: '15px',
        color: `${colors.Common.textColor}`,
        fontFamily: 'SegoeUI',
        '&:nth-last-of-type(1)': {
          marginBottom: '0',
        },
        fontWeight: 'normal',
      },
    },
    // в случае чего вернуть как было.
    MuiTooltip: {
      // popper: {
      //     background: "#fff",
      //     width: "200px"
      // },
      popper: {
        minWidth: '162px',
        maxWidth: '221px',
        margin: '0',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        // color: "#FF4B4B",
        color: '#FFFFFF',
        opacity: '1!important',
        transform: 'none!important',
        backgroundColor: `${colors.Common.textColor}`,
        borderRadius: '8px',
        // boxShadow: "0px 2px 15px rgba(0, 0, 0, .25)",
        padding: '12px',
        position: 'relative!important',
        // '&::before': {
        //     content: '""',
        //     display: "block",
        //     width: "0",
        //     height: "0",
        //     borderWidth: "0 10px 17px 10px",
        //     borderColor: "transparent transparent #fff transparent",
        //     borderStyle: "solid",
        //     position: "absolute",
        //     top: "-31px",
        //     right: "30px"
        // }
      },
      tooltipPlacementBottom: {
        margin: '8px 0 0 !important',
      }
    },
    MuiSelect: {
      root: {
        width: '100%',
        borderRadius: '4px',
        fontSize: '16px',
        lineHeight: '24px',
        '&:focus': {
          backgroundColor: 'transparent!important',
        },
      },
      select: {
        padding: '23px 44px 7px 15px',
      },
      selectMenu: {
        color: `${colors.Common.textColor}`,
      },
      icon: {
        right: '16px',
      },
      iconOpen: {
        transform: 'translate(-8px, 2px) rotate(180deg)',
      },
      disabled: {},
      //icon: {
      //top: "calc(50% - 10px)",
      //transform: "translateX(-10px)"
      //}
    },
    MuiMenu: {
      paper: {
        maxHeight: '300px!important',
        overflowY: 'auto',
        margin: '8px 0 0',
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '0',
        paddingTop: '16px',
        paddingBottom: '16px',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
        '&:hover': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingRight: '24px',
        paddingLeft: '24px',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '8px',
      },
      elevation8: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        right: '-30px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
      },
    },
    MuiFormControl: {
      root: {
        '&:required': {
          borderRadius: '4px',
          borderLeft: '4px solid #E32A38',
        },
      },
    },
  },
});
export const defaultTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #C9C7C7',
        borderRadius: '4px',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: `${colors.Common.textGrey}`,
        },
        '&$error': {
          border: '1px solid #E32A38 !important',
        },
      },
      input: {
        padding: '23px 44px 7px 15px',
        lineHeight: '24px',
        textOverflow: 'ellipsis',
        '&:required': {
          borderLeft: '4px solid #E32A38',
          borderRadius: '4px 0 0 4px',
          "&[valid='false']": {
            borderLeft: `4px solid #E32A38`,
          },
          "&[valid='true']": {
            borderLeft: 'none',
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '16px',
        lineHeight: '24px',
        width: 'calc(100% - 16px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px,32px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      filled: {
        color: `${colors.Common.textGrey}`,
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
      },
      disabled: {},
    },
    MuiInputBase: {
      input: {
        fontWeight: 'normal',
        height: 'auto',
        color: `${colors.Common.textColor}`,
        fontFamily: 'SegoeUI',
      },
      multiline: {
        padding: '0',
      },
      root: {
        '&$disabled': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
          },
        },
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        fontFamily: 'SegoeUI',
        '&$focused': {
          color: `${colors.Common.textGrey}`,
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        right: '-30px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
      },
    },
    MuiSelect: {
      icon: {
        right: '15px',
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 'none',
        padding: '0',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$disabled path': {
          stroke: '#AAA9A9',
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
  },
});
export const defaultSelectTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiSelect: {
      root: {
        width: '100%',
      },
      select: {
        padding: '23px 44px 7px 15px !important',
        borderRadius: '4px',
        border: '1px solid #C9C7C7',
        lineHeight: '24px',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$disabled': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
          },
        },
        '&:focus': {
          borderRadius: '4px',
          backgroundColor: 'transparent',
          border: `1px solid ${colors.Input.border}`,
        },
      },
      selectMenu: {
        color: `${colors.Common.textColor}`,
      },
      icon: {
        right: '16px',
        '&$disabled': {
          '& path': {
            stroke: '#AAA9A9',
          },
        },
      },
      disabled: {},
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
        textAlign: 'left',
        fontFamily: 'SegoeUI',
        "&[required][valid='false']": {
          '& div': {
            borderRadius: '0 4px 4px 0',
            borderLeft: 'none',
          },
        },
        "&[required][valid='false']$disabled": {
          '& div': {
            borderRadius: '4px',
            borderLeft: '1px solid #DCDCDC',
          },
        },
        "&$error": {
          borderRadius: '4px',
          '& div': {
            border: '1px solid #E32A38 !important',
          },
        },
      },
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px, 32px) scale(1)',
        color: `${colors.Common.textGrey} !important`,
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        color: `${colors.Common.textGrey} !important`,
        transform: 'translate(16px, 24px) scale(1)',
      },
      filled: {
        color: `${colors.Common.textGrey}`,
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        fontFamily: 'SegoeUI',
        '&$focused': {
          color: `${colors.Common.textGrey} !important`,
          '&$disabled': {
            color: `${colors.Common.textGrey} !important`,
          },
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '0',
        paddingTop: '16px',
        paddingBottom: '16px',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
        '&:hover': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingRight: '24px',
        paddingLeft: '24px',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '403px!important',
        margin: '8px 0 0',
      },
      rounded: {
        borderRadius: '8px',
      },
      elevation8: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
      },
    },
  },
});
export const ButtonDeleteTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        border: '1px solid #868686',
        borderRadius: '3px',
        height: '60px',
        width: '60px',
        fontFamily: 'SegoeUI',
        position: 'absolute',
        left: '-80px',
        top: '17px',
      },
      text: {
        padding: '15px',
      },
      label: {
        lineHeight: '19px',
      },
    },
  },
});
export const ButtonAdormentTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        border: '1px solid #868686',
        borderRadius: '100px',
        height: '50px',
        minWidth: '50px',
        fontFamily: 'SegoeUI',
        backgroundColor: 'red',
      },
      text: {
        padding: '15px',
      },
      label: {
        lineHeight: '19px',
      },
    },
    MuiButtonBase: {
      root: {
        backgroundColor: 'transparent!important',
        '&:hover path': {
          stroke: '#AAA9A9',
        }
      }
    }
  },
});
export const CheckBoxDefault = createMuiTheme({
  typography: {},
  overrides: {
    MuiCheckbox: {
      root: {
        color: '#D9D9D9',
        checked: {
          color: `${colors.Input.checkBox}`,
        },
      },
      colorSecondary: {
        '&$checked': {
          color: `${colors.Input.checkBox}`,
        },
      },
    },
    MuiButtonBase: {
      root: {
        width: '20px',
        height: '20px',
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '0',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0',
      },
      label: {
        marginLeft: '20px',
        fontFamily: 'SegoeUI',
      },
    },
  },
});
export const CheckBoxGrid = createMuiTheme({
  typography: {},
  overrides: {
    MuiCheckbox: {
      root: {
        '&:hover': {
          background: 'none',
        },
      },
      colorSecondary: {
        '&$checked': {
          color: `${colors.Input.checkBox}`,
        },
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '0',
      },
    },
    MuiButtonBase: {
      root: {
        width: '24px',
        height: '24px',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0',
      },
      label: {
        marginLeft: '20px',
      },
    },
  },
});
export const CheckBoxGridThead = createMuiTheme({
  typography: {},
  overrides: {
    MuiCheckbox: {
      root: {
        // color: '#D9D9D9',       
        '&:hover': {
          backgroundColor: 'none',
        },
      },
      // colorSecondary: {
      //   '&$checked': {
      //     color: `${colors.Input.checkBox}`,
      //     border: '2px solid white',
      //     borderRadius: '2px',
      //   },
      // },
    },
    PrivateSwitchBase: {
      root: {
        padding: '0',
      },
    },
    MuiButtonBase: {
      root: {
        width: '24px',
        height: '24px',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0',
      },
      label: {
        marginLeft: '20px',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: '#fff',
      },
    },
  },
});
export const CheckBoxProfile = createMuiTheme({
  typography: {},
  overrides: {
    MuiCheckbox: {
      root: {
        color: '#D9D9D9',
        checked: {
          color: `${colors.Input.checkBox}`,
        },
      },
      colorSecondary: {
        '&$checked': {
          color: `${colors.Input.checkBox}`,
        },
      },
    },
    MuiButtonBase: {
      root: {
        width: '20px',
        height: '20px',
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '0',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0',
        marginBottom: '15px',
      },
      label: {
        marginLeft: '20px',
        fontFamily: 'SegoeUI',
      },
    },
  },
});
export const DefaultTooltip = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        minWidth: '162px',
        maxWidth: '221px',
        margin: '0',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 'normal',
        color: '#FFF',
        opacity: '1!important',
        transform: 'translate(30px, 0)!important',
        backgroundColor: `${colors.Common.textColor}`,
        borderRadius: '8px',
        padding: '12px',
        position: 'relative!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 6px 8px',
          borderColor: `transparent transparent ${colors.Common.textColor} transparent`,
          borderStyle: 'solid',
          position: 'absolute',
          top: '-7px',
          right: 'calc(50% - 8px)',
        },
      },
      tooltipPlacementBottom: {
        margin: '8px 0 0 !important',
      }
    },
  },
});
export const TextTooltip = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        maxWidth: '221px',
        margin: '4px 0 0 0',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        boxShadow: 'none',
        padding: '12px',
        fontFamily: 'SegoeUI',
        position: 'relative!important',
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
        opacity: '1!important',
        backgroundColor: `${colors.Common.textColor}`,
        borderRadius: '8px',
        transform: 'none!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 6px 8px',
          borderColor: `transparent transparent ${colors.Common.textColor} transparent`,
          borderStyle: 'solid',
          position: 'absolute',
          top: '-7px',
          right: '30px',
        },
      },
      tooltipPlacementBottom: {
        margin: '0 !important',
      }
    },
  },
});
export const TextTooltipName = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        maxWidth: '340px',
        margin: '0',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        boxShadow: 'none',
        padding: '12px',
        position: 'relative!important',
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
        backgroundColor: `${colors.Common.textColor}`,
        borderRadius: '8px',
        opacity: '1!important',
        transform: 'translate(30px, 0)!important',
        transition: 'none!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 6px 8px',
          borderColor: `transparent transparent ${colors.Common.textColor} transparent`,
          borderStyle: 'solid',
          position: 'absolute',
          top: '-7px',
          right: '30px',
        },
      },
    },
  },
});
export const TextTooltipArchive = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, .7)',
        maxWidth: '340px',
        marginTop: '20px',
        borderRadius: '4px',
        marginRight: '-38px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        boxShadow: 'none',
        padding: '5px 20px',
        textAlign: 'center',
        position: 'relative!important',
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
        background: 'none!important',
        opacity: '1!important',
        transform: 'none!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 10px 17px 10px',
          borderColor: 'transparent transparent rgba(0, 0, 0, .7) transparent',
          borderStyle: 'solid',
          position: 'absolute',
          top: '-31px',
          right: '30px',
        },
      },
    },
  },
});
export const TextTooltipModeration = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        maxWidth: '221px',
        minWidth: '168px',
        margin: '0',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '1!important',
        transform: 'translate(44px, 16px)!important',
        backgroundColor: `${colors.Common.textColor}`,
        borderRadius: '8px',
        padding: '12px',
        position: 'relative!important',
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 6px 8px',
          borderColor: `transparent transparent ${colors.Common.textColor} transparent`,
          borderStyle: 'solid',
          position: 'absolute',
          top: '-7px',
          right: '30px',
        },
      },
      tooltipPlacementBottom: {
        margin: '8px 0 0 !important',
      }
    },
  },
});
export const TextTooltipUpper = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        maxWidth: '221px',
        margin: '4px 0 0 0',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        boxShadow: 'none',
        padding: '12px',
        fontFamily: 'SegoeUI',
        position: 'relative!important',
        wordBreak: 'break-word',
        opacity: '1!important',
        backgroundColor: `${colors.Common.textColor}`,
        borderRadius: '8px',
        transform: 'none!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 6px 8px',
          borderColor: `transparent transparent ${colors.Common.textColor} transparent`,
          borderStyle: 'solid',
          position: 'absolute',
          top: '-7px',
          right: '30px',
        },
      },
      tooltipPlacementBottom: {
        margin: '0 !important',
      }
    },
  },
});
export const TextTooltipModerationUpper = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, .7)',
        maxWidth: '340px',
        marginTop: '15px',
        borderRadius: '4px',
        marginRight: '-47px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '1!important',
        transform: 'none!important',
        background: 'none!important',
        boxShadow: 'none',
        padding: '5px 20px',
        textAlign: 'center',
        position: 'relative!important',
        wordBreak: 'break-word',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '17px 10px 0 10px',
          borderColor: 'rgba(0, 0, 0, .7) transparent transparent transparent',
          borderStyle: 'solid',
          position: 'absolute',
          bottom: '-31px',
          right: '30px',
        },
      },
    },
  },
});
export const DefaultTooltipCenter = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        maxWidth: '221px',
        margin: '0',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '1!important',
        transform: 'none!important',
        backgroundColor: `${colors.Common.textColor}`,
        borderRadius: '8px',
        padding: '12px',
        position: 'relative!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 6px 8px',
          borderColor: `transparent transparent ${colors.Common.textColor} transparent`,
          borderStyle: 'solid',
          position: 'absolute',
          top: '-7px',
          right: 'calc(50% - 8px)',
        },
        '& p': {
          margin: '8px 0 0',
        }
      },
      tooltipPlacementBottom: {
        margin: '8px 0 0 !important',
      },
    },
  },
});

export const NewCloseBtns = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        width: '40px',
        height: '40px',
      },
      text: {
        padding: '3px',
        color: '#868686',
        fontSize: '14px',
        textTransform: 'uppercase',
      },
    },
  },
});
export const NewBackBtns = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '70px',
        height: '30px',
      },
      text: {
        padding: '0 3px 0 0',
        color: '#868686',
        fontSize: '14px',
        textTransform: 'uppercase',
      },
    },
  },
});
export const DefaultGridButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        color: '#4AA5E8',
        '&$disabled': {
          color: '#52535A',
          opacity: '.7',
        },
      },
      label: {
        fontSize: '14px',
        fontWeight: '500',
        textTransform: 'none',
      },
      disabled: {},
    },
  },
});
export const AdressBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '25px',
        position: 'absolute',
        top: '0',
        left: '-35px',
      },
      text: {
        padding: '4px',
      },
    },
  },
});
export const ProductTabBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '25px',
        position: 'absolute',
        top: '45px',
        left: '-35px',
      },
      text: {
        padding: '4px',
      },
    },
  },
});
export const ProductBtnArray = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {

      text: {
        padding: '0',
        textTransform: 'inherit',
      }
    }
  }
})
export const ProfileAutocomplete = createMuiTheme({
  typography: {},
  overrides: {
    MuiInputBase: {
      root: {
        border: '1px solid #C9C7C7',
        borderRadius: '4px',
        marginTop: '16px!important',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$disabled': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
          },
          '& path': {
            stroke: '#AAA9A9',
          },
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
        '&$error': {
          border: '1px solid #E32A38 !important',
        },
      },
      error: {},
      input: {
        padding: '23px 68px 7px 15px',
        fontSize: '16px',
        color: `${colors.Common.textColor}`,
        lineHeight: '24px',
        height: 'auto',
        fontFamily: 'SegoeUI',
        textOverflow: 'ellipsis',
        '&::placeholder': {
          color: `${colors.Common.textGrey} !important`,
          opacity: '1',
          transform: 'translateY(-8px)',
        },
        '&:required': {
          borderLeft: '4px solid #E32A38',
          borderRadius: '4px',
          "&[valid='false']": {
            borderLeft: `4px solid #E32A38`,
          },
          "&[valid='true']": {
            borderLeft: 'none',
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        fontSize: '16px',
        lineHeight: '24px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px, 32px) scale(1)',
      },
      shrink: {
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey} !important`,
      },
      filled: {
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        fontFamily: 'SegoeUI',
        '&$focused': {
          color: `${colors.Common.textGrey} !important`,
          '&$disabled': {
            color: `${colors.Common.textGrey} !important`,
          },
        },
      },
      disabled: {},
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        padding: '0',
        paddingTop: '16px',
        paddingBottom: '16px',
        fontSize: '16px',
        color: `${colors.Common.textColor}`,
        whiteSpace: 'normal',
        lineHeight: '24px',
        height: 'auto',
        fontFamily: 'SegoeUI',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '300px!important',
        overflowY: 'scroll',
        padding: '8px 24px',
        marginTop: '8px',
      },
      rounded: {
        borderRadius: '8px',
      },
      elevation8: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        width: '24px',
        maxHeight: '100%',
        pointerEvents: 'none',
      },
      positionStart: {
        marginRight: '15px'
      }
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '0',
        minWidth: '15px',
        width: '20px',
        height: '20px',
        color: `${colors.Common.textColor}`,
        marginRight: '4px',
        '&:hover': {
          background: 'none!important',
        },
      },
      text: {
        padding: '0',
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      }
    },
  },
});

export const ProductAutocomplete = createMuiTheme({
  typography: {},
  overrides: {
    MuiInputBase: {
      root: {
        border: '1px solid #C9C7C7',
        borderRadius: '4px',
        marginTop: '16px!important',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$disabled': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
          },
          '& path': {
            stroke: '#AAA9A9',
          },
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
        '&$error': {
          border: '1px solid #E32A38 !important',
        },
      },
      error: {},
      input: {
        padding: '23px 92px 7px 15px',
        fontSize: '16px',
        color: `${colors.Common.textColor}`,
        lineHeight: '24px',
        height: 'auto',
        fontFamily: 'SegoeUI',
        textOverflow: 'ellipsis',
        '&::placeholder': {
          color: `${colors.Common.textGrey} !important`,
          opacity: '1',
          transform: 'translateY(-8px)',
        },
        '&:required': {
          borderLeft: '4px solid #E32A38',
          borderRadius: '4px',
          "&[valid='false']": {
            borderLeft: `4px solid #E32A38`,
          },
          "&[valid='true']": {
            borderLeft: 'none',
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        fontSize: '16px',
        lineHeight: '24px',
        width: 'calc(100% - 54px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px, 32px) scale(1)',
        color: `${colors.Common.textGrey} !important`,
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey} !important`,
      },
      filled: {
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        fontFamily: 'SegoeUI',
        '&$focused': {
          color: `${colors.Common.textGrey} !important`,
          '&$disabled': {
            color: `${colors.Common.textGrey} !important`,
          },
        },
      },
      disabled: {},
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        padding: '0',
        paddingTop: '16px',
        paddingBottom: '16px',
        fontSize: '16px',
        color: `${colors.Common.textColor}`,
        whiteSpace: 'normal',
        lineHeight: '24px',
        height: 'auto',
        fontFamily: 'SegoeUI',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '300px!important',
        overflowY: 'scroll',
        padding: '8px 24px',
        marginTop: '8px',
      },
      rounded: {
        borderRadius: '8px',
      },
      elevation8: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        width: '24px',
        maxHeight: '100%',
        pointerEvents: 'none',
      },
      positionStart: {
        marginRight: '15px'
      }
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '0',
        minWidth: '15px',
        width: '20px',
        height: '20px',
        color: `${colors.Common.textColor}`,
        marginRight: '4px',
        '&:hover': {
          background: 'none!important',
        },
      },
      text: {
        padding: '0',
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      }
    },
  },
});

export const AddressButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      textPrimary: {
        background: '#868686',
        color: '#FFF',
        border: '1px solid #868686',
        '&:hover': {
          color: '#868686',
          backgroundColor: 'transparent',
        },
      },
      textSecondary: {
        color: '#868686',
        background: '#FFF',
        border: '1px solid #868686',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
        },
      },
      root: {
        fontSize: '16px',
        textTransform: 'none',
        borderRadius: '3px',
        height: 'auto',
        minWidth: '200px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: '600',
      },
      text: {
        padding: '14px 20px',
      },
      label: {
        lineHeight: '19px',
      },
    },
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, .7)',
        maxWidth: '220px',
        marginTop: '20px',
        borderRadius: '4px',
        marginLeft: '-100px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '1!important',
        transform: 'none!important',
        background: 'none!important',
        boxShadow: 'none',
        padding: '10px 20px',
        textAlign: 'center',
        position: 'relative!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 10px 17px 10px',
          borderColor: 'transparent transparent rgba(0, 0, 0, .7) transparent',
          borderStyle: 'solid',
          position: 'absolute',
          top: '-31px',
          right: '30px',
        },
      },
    },
  },
});
export const ProductButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      textPrimary: {
        color: `${colors.Common.textColor}`,
        background: '#FFF',
        borderRadius: '4px',
        border: '1px solid #DCDCDC',
        // minWidth: '120px',
        '&:hover': {
          color: `${colors.Common.textColor} !important`,
          backgroundColor: '#F5F5F5!important',
          borderColor: '#F5F5F5',
        },
        '&$disabled': {
          color: '#C9C7C7',
          '& path': {
            stroke: '#C9C7C7',
          },
        },
      },
      textSecondary: {
        color: `${colors.Common.textColor}`,
        background: '#FFF',
        border: '1px solid #DCDCDC',
        '&:hover': {
          color: `${colors.Common.textColor} !important`,
          backgroundColor: '#F5F5F5!important',
          borderColor: '#F5F5F5',
        },
        '&$disabled': {
          color: '#C9C7C7',
          '& path': {
            stroke: '#C9C7C7',
          },
        },
      },
      root: {
        fontSize: '16px',
        lineHeight: '24px',
        textTransform: 'none',
        borderRadius: '4px',
        // minWidth: '200px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: '600',
        marginTop: '16px',
      },
      text: {
        padding: '9px 24px',
      },
      label: {
        lineHeight: '24px',
      },
    },
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, .7)',
        maxWidth: '340px',
        marginTop: '20px',
        borderRadius: '4px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '1!important',
        transform: 'none!important',
        background: 'none!important',
        boxShadow: 'none',
        padding: '5px 20px',
        textAlign: 'center',
        position: 'relative!important',
        wordBreak: 'break-word',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 10px 17px 10px',
          borderColor: 'transparent transparent rgba(0, 0, 0, .5) transparent',
          borderStyle: 'solid',
          position: 'absolute',
          top: '-31px',
          right: '30px',
        },
      },
    },
  },
});
export const HomeLangSelectTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiMenuItem: {
      root: {
        minWidth: '257px',
        height: 'auto',
        padding: '16px 24px',
        paddingTop: '16px',
        paddingBottom: '16px',
        marginBottom: '8px',
        fontFamily: 'SegoeUI',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 'normal',
        '&:first-of-type': {
          minWidth: 'auto',
        },
        '&:hover': {
          backgroundColor: 'rgba(231, 231, 231, 0.35) !important',
        },
        '&$selected': {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiListItem: {
      root: {
        display: 'flex',
        alignItems: 'center',
      }
    },
    MuiSelect: {
      select: {
        padding: '16px 24px',
        fontFamily: 'SegoeUI',
        color: `${colors.Common.textColor}`,
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        display: 'flex',
        alignItems: 'center',
        paddingRight: '32px!important',
        '&:focus': {
          backgroundColor: 'transparent',
        }
      },
      icon: {
        zIndex: '1301',
      }
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '8px',
        minWidth: 'auto',
        marginTop: '-1px',
      },
      elevation8: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
      },
    },
    MuiTypography: {
      root: {
        flexGrow: '1',
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputBase: {
      input: {
        padding: '16px 32px 16px 0',
      },
      root: {
        fontFamily: 'SegoeUI',
        color: `${colors.Common.textColor}`,
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        '&:hover svg:last-child path': {
          stroke: '#AAA9A9',
        },
      }
    }
  },
});

export const MultiLineBox = styled.span` 
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  bottom: 0;  
`;

export const LineBox = styled.span`
  position: absolute;
  bottom: -4px;
  right: 0;
`;

export const TextButtonTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '4px',
        minWidth: '30px',
        margin: '0 0 20px 14px',
      },
      text: {
        padding: '3px',
      },
      textSecondary: {
        background: 'none',
        whiteSpace: 'nowrap',
        color: `${colors.Buttons.textButton.color}`,
        width: 'auto',
        height: '24px',
        padding: '0',
        fontSize: '16px',
        lineHeight: '24px',
        textTransform: 'inherit',
        transition: '.4s ease',
        '&:hover': {
          backgroundColor: 'transparent',
          color: `${colors.Buttons.textButton.colorHovered}`,
        },
        '&:active': {
          backgroundColor: 'transparent !important',
          color: `${colors.Buttons.textButton.colorHovered}`,
        },
      },
    },
    MuiTouchRipple: {
      root: {
        display: 'none',
      },
    },
  }
});

export const selectWithoutLabelTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiSelect: {
      root: {
        width: '100%',
      },
      select: {
        padding: '15px 44px 15px 15px !important',
        borderRadius: '4px',
        lineHeight: '24px',
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      selectMenu: {
        color: `${colors.Common.textColor}`,
      },
      icon: {
        right: '16px',
      },
      disabled: {},
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
        textAlign: 'left',
        borderRadius: '4px',
        border: '1px solid #C9C7C7',
        fontFamily: 'SegoeUI',
        fontWeight: '600',
        //marginTop: '16px',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
        '&$disabled': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
          },
          '& path': {
            stroke: '#AAA9A9',
          },
        },
      },
      disabled: {},
    },
  },
});
