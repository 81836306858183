import * as React from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment-timezone";
import { bindActionCreators, compose } from 'redux';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Autocomplete } from '@material-ui/lab';
import { MuiThemeProvider, TextField } from "@material-ui/core";
import {
  CertSelectAuto,
  InvalidCert,
  MenuItemDate,
  MenuItemIcon,
  MenuItemInner,
  MenuItemName,
  SelectedCert,
  SelectedCertInner,
  SelectedIcon,
  SelectedMinInner,
  SelectedMinInnerLeft,
  SelectedMinText,
  SelectedMinTitle,
  SelectedMinTitleCert
} from "./CertificateSelect.styled";
import { SelectedCertMin } from "../../common/styles/Common.styled";
import {
  IconDropDown,
  IconClose,
  IconCircle,
} from "../../common/icons/customIcons";
import { EIMZOClient } from "../../common/utils/eizmoClient";
import { throwNotification } from "../../common/structure";

const createItem = function (itemId, v) {
  let item = {
    id: itemId,
    isValid: Date.now() < v.validTo,
    certUid: v.UID,
    owner: v.CN,
    inn: v.TIN,
    issuedBy: v.O,
    startDate: v.validFrom.toLocaleDateString(),
    expireDate: v.validTo.toLocaleDateString(),
    organization: v.O,
    lastName: v.CN.split(' ')[0],
    firstName: v.CN.split(' ')[1] + ' ' + v.CN.split(' ')[2],
    post: v.T,
  };
  return { ...v, ...item }
}
class CertificateSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      certs: [],
    }
  }

  componentDidMount() {
    this.preLoadCertificate();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!_.isEqual(this.state, nextState)];
    const nameProps = ['certificates', 'cert', 'locale'];
    _.forEach(nameProps, i => equal.push(!_.isEqual(this.props[i], nextProps[i])));
    return _.some(equal);
  }


  componentDidUpdate = () => {
    this.preLoadCertificate();
  };

  /**
   * TODO: убрать консоль логи после дебага дев сервера.
   */
  preLoadCertificate = () => {
    if (_.isEmpty(this.state.certs)) {
      EIMZOClient.installApiKeys(
        () => {
          return EIMZOClient.listAllUserKeys(
            function (o, i) { //itemIdGen
              var itemId = "itm-" + o.serialNumber + "-" + i;
              return itemId;
            },
            function (itemId, v) { //itemUiGen
              return createItem(itemId, v);
            },
            (items, firstId) => { //success
              return this.setState({
                certs: [...this.state.certs, ...items]
              });
            },
            (e, r) => { //fail
              return this.props.throwError(this.props.t('Ошибка соединения с E-IMZO. Возможно у вас не установлен модуль E-IMZO.'));
            }
          )
        },
        (e, r) => {
          if (r) {
            return this.props.throwError(this.props.t(r));
          } else {
            return this.props.throwError(this.props.t('Ошибка соединения с E-IMZO. Возможно у вас не установлен модуль E-IMZO.'));
          }
        }
      );
    }
  };

  render() {
    const { cert, t } = this.props;
    const certUid = _.get(cert, 'certUid', null);

    let validDate;
    if (cert.startDate && cert.expireDate) {
      const currentDate = moment();
      const startDate = moment(cert.startDate, "DD.MM.YYYY");
      const expireDate = moment(cert.expireDate, "DD.MM.YYYY");
      validDate = startDate <= currentDate && currentDate <= expireDate;
    }

    const messageInvalidCert = [];
    if (certUid && !cert.isValid) {
      if (!validDate) {
        messageInvalidCert.push(`${t('Срок действия сертификата истек')}. ${t('Пожалуйста, загрузите действующий УКЭП')}. `);
      } else {
        if (!cert.isValid) {
          messageInvalidCert.push(`${t('Ошибка при использовании сертификата')}. ${t('Пожалуйста, обратитесь в техническую поддержку')}. `);
        }
      }
    }

    return (
      <React.Fragment>
        <MuiThemeProvider theme={CertSelectAuto}>
          <Autocomplete
            options={this.state.certs}
            autoHighlight
            onChange={(event, cert) => this.props.onSelectCert(cert || {})}
            getOptionLabel={(cert) => _.replace(cert.issuedBy, /"/g, '')}
            popupIcon={<IconDropDown />}
            closeIcon={<IconClose style={{ width: 20, height: 20 }} />}
            clearText=''
            openText=''
            renderOption={(cert) => (
              <MenuItemInner>
                <MenuItemIcon>
                  {cert.isValid ? <IconCircle color={'#00B634'} /> : <IconCircle color={'#E32A38'} />}
                </MenuItemIcon>
                <span>{_.replace(cert.organization, /"/g, '')}</span>
                <MenuItemDate isValid={cert.isValid}>
                  {cert.startDate}-{cert.expireDate}
                </MenuItemDate>
                <MenuItemName>
                  {cert.fullName} {cert.post ? `• ${cert.post}` : ''}
                </MenuItemName>
              </MenuItemInner>
            )}
            renderInput={params => (
              <TextField
                {...params}
                label={_.replace(_.get(cert, 'organization', t('Выберите электронную подпись')), /"/g, '')}
                variant="standard"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'disabled'
                }}
              />
            )}
            noOptionsText={t('Нет совпадений')}
          />
        </MuiThemeProvider>

        {certUid && (
          // <SelectedCert>
          <SelectedCertInner>
            <SelectedIcon>
              {cert.isValid ? <IconCircle color={'#00B634'} /> : <IconCircle color={'#E32A38'} />}
            </SelectedIcon>

            <SelectedCertMin>
              <SelectedMinInnerLeft>
                <SelectedMinTitleCert>
                  {t('Сертификат')}:
                </SelectedMinTitleCert>
                <SelectedMinText>{cert.certUid}</SelectedMinText>
              </SelectedMinInnerLeft>
              <SelectedMinInnerLeft>
                <SelectedMinTitle>{t('Владелец')}:</SelectedMinTitle>
                <span>{_.replace(cert.owner, /"/g, '')}</span>
              </SelectedMinInnerLeft>
              <SelectedMinInnerLeft>
                <SelectedMinTitle>{t('Налоговый идентификатор')}:</SelectedMinTitle>
                <span>{cert.inn}</span>
              </SelectedMinInnerLeft>
            </SelectedCertMin>

            <SelectedCertMin>
              <SelectedMinInner>
                <SelectedMinTitle>{t('Выдан')}:</SelectedMinTitle>
                <span>{_.replace(cert.issuedBy, /"/g, '')}</span>
              </SelectedMinInner>
              <SelectedMinInner>
                <SelectedMinTitleCert>
                  {t('Действителен')}:{' '}
                </SelectedMinTitleCert>
                <span>
                  {t('c startDate до endDate', {
                    startDate: cert.startDate,
                    endDate: cert.expireDate,
                  })}
                </span>
              </SelectedMinInner>
            </SelectedCertMin>
          </SelectedCertInner>
          // </SelectedCert>
        )}

        {!_.isEmpty(messageInvalidCert) && (
          <InvalidCert>{messageInvalidCert}</InvalidCert>
        )}
      </React.Fragment>
    );
  }
}

CertificateSelect.propTypes = {
  onSelectCert: PropTypes.func.isRequired,
  certificates: PropTypes.array.isRequired,
  cert: PropTypes.any.isRequired,
  locale: PropTypes.string.isRequired,
  throwError: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    throwError: (message) => throwNotification(message, 'error'),
  }, dispatch);
};

const mapStateToProps = (state) => ({
  locale: state.profileReducer.locale
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CertificateSelect);
