import styled from 'styled-components';
import { StatusLine } from '../../../../common/styles/Common.styled';
import { createMuiTheme } from '@material-ui/core';
import { ColumnCell } from '../../productGrid/ProductsGrid.styled';
import colors from '../../../../common/styles/Common.colors';

export const StatusLineSelect = styled(StatusLine)`
  color: ${(props) => props.color};
  margin-left: 0;
`;
export const ColumnCellStatus = styled(ColumnCell)`
  padding: 10px 12px;
`;
export const GridStatusSelect = createMuiTheme({
  typography: {},
  overrides: {
    MuiSelect: {
      root: {
        width: 'auto',
        border: 'none',
        borderRadius: '0',
      },
      select: {
        padding: '0',
        paddingRight: '0',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:focus': {
          backgroundColor: 'none',
        },
      },
      selectMenu: {
        color: `${colors.Common.textColor}`,
        paddingRight: '20px',
      },
      icon: {
        position: 'absolute',
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '14px',
      },
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        fontSize: '14px',
        lineHeight: '20px',
      },
      formControl: {
        transform: 'translate(9px, 18px) scale(1)',
        color: `${colors.Common.textColor}`,
      },
      shrink: {
        display: 'none',
      },
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        '&$focused': {
          color: `${colors.Common.textColor}`,
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '0',
        paddingTop: '16px',
        paddingBottom: '16px',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)!important',
        },
        // '&:focus': {
        //   backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
        // },
      },
    },
    MuiPaper: {
      root: {
        marginTop: '8px',
      },
      rounded: {
        padding: '8px 24px',
        borderRadius: '8px',
      },
      elevation8: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
      },
    },
  },
});
