export const LOAD_TNVED_ACTION = 'LOAD_TNVED';
export const LOAD_TNVEDS_SUCCEEDED_ACTION = 'LOAD_TNVEDS_SUCCEEDED';
export const LOAD_TNVEDS_FAILED_ACTION = 'LOAD_TNVEDS_FAILED';

export const LOAD_DICTIONARY_TM_ACTION = 'LOAD_DICTIONARY_TM';
export const LOAD_DICTIONARY_TM_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_TM_SUCCEEDED';
export const LOAD_DICTIONARY_TM_FAILED_ACTION = 'LOAD_DICTIONARY_TM_FAILED';

export const LOAD_DICTIONARY_POSITION_ACTION = 'LOAD_DICTIONARY_POSITION';
export const LOAD_DICTIONARY_POSITION_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_POSITION_SUCCEEDED';
export const LOAD_DICTIONARY_POSITION_FAILED_ACTION =
  'LOAD_DICTIONARY_POSITION_FAILED';

export const LOAD_DICTIONARY_COUNTRY_ACTION = 'LOAD_DICTIONARY_COUNTRY';
export const LOAD_DICTIONARY_COUNTRY_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_COUNTRY_SUCCEEDED';
export const LOAD_DICTIONARY_COUNTRY_FAILED_ACTION =
  'LOAD_DICTIONARY_COUNTRY_FAILED';

export const LOAD_DICTIONARY_OPF_ACTION = 'LOAD_DICTIONARY_OPF';
export const LOAD_DICTIONARY_OPF_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_OPF_SUCCEEDED';
export const LOAD_DICTIONARY_OPF_FAILED_ACTION = 'LOAD_DICTIONARY_OPF_FAILED';

export const LOAD_DICTIONARY_SUBJECT_ACTION = 'LOAD_DICTIONARY_SUBJECT';
export const LOAD_DICTIONARY_SUBJECT_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_SUBJECT_SUCCEEDED';
export const LOAD_DICTIONARY_SUBJECT_FAILED_ACTION =
  'LOAD_DICTIONARY_SUBJECT_FAILED';

export const LOAD_DICTIONARY_DEPARTMENT_ACTION = 'LOAD_DICTIONARY_DEPARTMENT';
export const LOAD_DICTIONARY_DEPARTMENT_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_DEPARTMENT_SUCCEEDED';
export const LOAD_DICTIONARY_DEPARTMENT_FAILED_ACTION =
  'LOAD_DICTIONARY_DEPARTMENT_FAILED';

export const LOAD_DICTIONARY_KNOWLEDGE_GS1_ACTION =
  'LOAD_DICTIONARY_KNOWLEDGE_GS1';
export const LOAD_DICTIONARY_KNOWLEDGE_GS1_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_KNOWLEDGE_GS1_SUCCEEDED';
export const LOAD_DICTIONARY_KNOWLEDGE_GS1_FAILED_ACTION =
  'LOAD_DICTIONARY_KNOWLEDGE_GS1_FAILED';
export const LOAD_DICTIONARY_PROJECTS_GS1_ACTION =
  'LOAD_DICTIONARY_PROJECTS_GS1';
export const LOAD_DICTIONARY_PROJECTS_GS1_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_PROJECTS_GS1_SUCCEEDED';
export const LOAD_DICTIONARY_PROJECTS_GS1_FAILED_ACTION =
  'LOAD_DICTIONARY_PROJECTS_GS1_FAILED';
export const LOAD_DICTIONARY_SALES_GS1_ACTION = 'LOAD_DICTIONARY_SALES_GS1';
export const LOAD_DICTIONARY_SALES_GS1_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_SALES_GS1_SUCCEEDED';
export const LOAD_DICTIONARY_SALES_GS1_FAILED_ACTION =
  'LOAD_DICTIONARY_SALES_GS1_FAILED';
export const LOAD_DICTIONARY_CHANNELS_GS1_ACTION =
  'LOAD_DICTIONARY_CHANNELS_GS1';
export const LOAD_DICTIONARY_CHANNELS_GS1_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_CHANNELS_GS1_SUCCEEDED';
export const LOAD_DICTIONARY_CHANNELS_GS1_FAILED_ACTION =
  'LOAD_DICTIONARY_CHANNELS_GS1_FAILED';

export const LOAD_DICTIONARY_ORG_PROFILES_ACTION =
  'LOAD_DICTIONARY_ORG_PROFILES';
export const LOAD_DICTIONARY_ORG_PROFILES_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_ORG_PROFILES_SUCCEEDED';
export const LOAD_DICTIONARY_ORG_PROFILES_FAILED_ACTION =
  'LOAD_DICTIONARY_ORG_PROFILES_FAILED';
export const LOAD_DICTIONARY_SECTOR_ACTION = 'LOAD_DICTIONARY_SECTOR';
export const LOAD_DICTIONARY_SECTOR_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_SECTOR_SUCCEEDED';
export const LOAD_DICTIONARY_SECTOR_FAILED_ACTION =
  'LOAD_DICTIONARY_SECTOR_FAILED';
export const LOAD_DICTIONARY_GCP_PRODUCTS_ACTION =
  'LOAD_DICTIONARY_GCP_PRODUCTS';
export const LOAD_DICTIONARY_GCP_PRODUCTS_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_GCP_PRODUCTS_SUCCEEDED';
export const LOAD_DICTIONARY_GCP_PRODUCTS_FAILED_ACTION =
  'LOAD_DICTIONARY_GCP_PRODUCTS_FAILED';
export const LOAD_DICTIONARY_OBTAINING_METHOD_ACTION =
  'LOAD_DICTIONARY_OBTAINING_METHOD';
export const LOAD_DICTIONARY_OBTAINING_METHOD_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_OBTAINING_METHOD_SUCCEEDED';
export const LOAD_DICTIONARY_OBTAINING_METHOD_FAILED_ACTION =
  'LOAD_DICTIONARY_OBTAINING_METHOD_FAILED';
export const LOAD_DICTIONARY_EDO_PROVIDER_ACTION =
  'LOAD_DICTIONARY_EDO_PROVIDER';
export const LOAD_DICTIONARY_EDO_PROVIDER_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_EDO_PROVIDER_SUCCEEDED';
export const LOAD_DICTIONARY_EDO_PROVIDER_FAILED_ACTION =
  'LOAD_DICTIONARY_EDO_PROVIDER_FAILED';
export const LOAD_DICTIONARY_OKPD_ACTION = 'LOAD_DICTIONARY_OKPD';
export const LOAD_DICTIONARY_OKPD_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_OKPD_SUCCEEDED';
export const LOAD_DICTIONARY_OKPD_FAILED_ACTION = 'LOAD_DICTIONARY_OKPD_FAILED';

export const LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_ACTION =
  'LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN';
export const LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_SUCCEEDED';
export const LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_FAILED_ACTION =
  'LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_FAILED';

export const LOAD_DICTIONARY_PACKAGE_TYPE_ACTION =
  'LOAD_DICTIONARY_PACKAGE_TYPE';
export const LOAD_DICTIONARY_PACKAGE_TYPE_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_PACKAGE_TYPE_SUCCEEDED';
export const LOAD_DICTIONARY_PACKAGE_TYPE_FAILED_ACTION =
  'LOAD_DICTIONARY_PACKAGE_TYPE_FAILED';

export const LOAD_DICTIONARY_PACKAGE_MATERIAL_ACTION =
  'LOAD_DICTIONARY_PACKAGE_MATERIAL';
export const LOAD_DICTIONARY_PACKAGE_MATERIAL_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_PACKAGE_MATERIAL_SUCCEEDED';
export const LOAD_DICTIONARY_PACKAGE_MATERIAL_FAILED_ACTION =
  'LOAD_DICTIONARY_PACKAGE_MATERIAL_FAILED';

export const LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_ACTION =
  'LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE';
export const LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_SUCCEEDED';
export const LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_FAILED_ACTION =
  'LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_FAILED';

export const LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_ACTION =
  'LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING';
export const LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_SUCCEEDED';
export const LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_FAILED_ACTION =
  'LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_FAILED';

export const LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_ACTION =
  'LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING';
export const LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_SUCCEEDED';
export const LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_FAILED_ACTION =
  'LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_FAILED';

export const LOAD_DICTIONARY_PALLET_TYPE_ACTION = 'LOAD_DICTIONARY_PALLET_TYPE';
export const LOAD_DICTIONARY_PALLET_TYPE_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_PALLET_TYPE_SUCCEEDED';
export const LOAD_DICTIONARY_PALLET_TYPE_FAILED_ACTION =
  'LOAD_DICTIONARY_PALLET_TYPE_FAILED';

export const LOAD_DICTIONARY_OKVED_ACTION = 'LOAD_DICTIONARY_OKVED';
export const LOAD_DICTIONARY_OKVED_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_OKVED_SUCCEEDED';
export const LOAD_DICTIONARY_OKVED_FAILED_ACTION =
  'LOAD_DICTIONARY_OKVED_FAILED';

export const LOAD_NOTIFICATION_ACTION = 'LOAD_NOTIFICATION';
export const LOAD_NOTIFICATION_SUCCEEDED_ACTION = 'LOAD_NOTIFICATION_SUCCEEDED';
export const LOAD_NOTIFICATION_FAILED_ACTION = 'LOAD_NOTIFICATION_FAILED';

export const CLEAN_NOTIFICATION_ACTION = 'CLEAN_NOTIFICATION';

export const MARK_NOTIFICATION_ACTION = 'MARK_NOTIFICATION';
export const MARK_NOTIFICATION_SUCCESSED_ACTION = 'MARK_NOTIFICATION_SUCCESSED';

export const LOAD_PRODUCTS_CATEGORIES_ACTION = 'LOAD_PRODUCTS_CATEGORIES';
export const LOAD_PRODUCTS_CATEGORIES_SUCCEEDED_ACTION =
  'LOAD_PRODUCTS_CATEGORIES_SUCCEEDED';
export const LOAD_PRODUCTS_CATEGORIES_FAILED_ACTION =
  'LOAD_PRODUCTS_CATEGORIES_FAILED';

export const LOAD_GTINS_LIST_BY_PACKAGE_ACTION =
  'LOAD_GTINS_LIST_BY_PACKAGE_ACTION';
export const LOAD_GTINS_LIST_BY_PACKAGE_SUCCEEDED_ACTION =
  'LOAD_GTINS_LIST_BY_PACKAGE_SUCCEEDED_ACTION';
export const LOAD_GTINS_LIST_BY_PACKAGE_FAILED_ACTION =
  'LOAD_GTINS_LIST_BY_PACKAGE_FAILED_ACTION';

export const LOAD_ACTIVE_KZ_DICTIONARY_ACTION =
  'LOAD_ACTIVE_KZ_DICTIONARY_ACTION';
export const LOAD_ACTIVE_KZ_DICTIONARY_SUCCEEDED_ACTION =
  'LOAD_ACTIVE_KZ_DICTIONARY_SUCCEEDED_ACTION';
export const LOAD_ACTIVE_KZ_DICTIONARY_FAILED_ACTION =
  'LOAD_ACTIVE_KZ_DICTIONARY_FAILED_ACTION';
export const CLEAR_ACTIVE_KZ_DICTIONARY_ACTION =
  'CLEAR_ACTIVE_KZ_DICTIONARY_ACTION';

export const CLEAR_GTINS_LIST_BY_PACKAGE_ACTION =
  'CLEAR_GTINS_LIST_BY_PACKAGE_ACTION';

export const LOAD_GTINS_LIST_FOR_VALIDATION_ACTION =
  'LOAD_GTINS_LIST_FOR_VALIDATION_ACTION';
export const LOAD_GTINS_LIST_FOR_VALIDATION_SUCCEEDED_ACTION =
  'LOAD_GTINS_LIST_FOR_VALIDATION_SUCCEEDED_ACTION';
export const LOAD_GTINS_LIST_FOR_VALIDATION_FAILED_ACTION =
  'LOAD_GTINS_LIST_FOR_VALIDATION_FAILED_ACTION';

// export const LOAD_NEW_OPF_DICTIONARY_ACTION = "LOAD_NEW_OPF_DICTIONARY_ACTION"
// export const LOAD_NEW_OPF_DICTIONARY_ACTION_SUCCEEDED = "LOAD_NEW_OPF_DICTIONARY_ACTION_SUCCEEDED"
// export const LOAD_NEW_OPF_DICTIONARY_ACTION_FAILED = "LOAD_NEW_OPF_DICTIONARY_ACTION_FAILED"

export const LOAD_KATO_DICTIONARY_ACTION = 'LOAD_KATO_DICTIONARY_ACTION';
export const LOAD_KATO_DICTIONARY_ACTION_SUCCEEDED =
  'LOAD_KATO_DICTIONARY_ACTION_SUCCEEDED';
export const LOAD_KATO_DICTIONARY_ACTION_FAILED =
  'LOAD_KATO_DICTIONARY_ACTION_FAILED';

export const LOAD_DICTIONARY_COUNTRIES_ACTION = 'LOAD_DICTIONARY_COUNTRIES';
export const LOAD_DICTIONARY_COUNTRIES_SUCCEEDED_ACTION =
  'LOAD_DICTIONARY_COUNTRIES_SUCCEEDED';
export const LOAD_DICTIONARY_COUNTRIES_FAILED_ACTION = 'LOAD_DICTIONARY_COUNTRIES_FAILED';

export const LOAD_NOTIFICATION_RECENT_ACTION = 'LOAD_NOTIFICATION_RECENT';
export const LOAD_NOTIFICATION_RECENT_SUCCEEDED_ACTION = 'LOAD_NOTIFICATION_RECENT_SUCCEEDED';
export const LOAD_NOTIFICATION_RECENT_FAILED_ACTION = 'LOAD_NOTIFICATION_RECENT_FAILED';
