import * as PropTypes from 'prop-types';
import * as React from 'react';
import moment from 'moment-timezone';
// import Moment from 'react-moment';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
// import Scrollspy from 'react-scrollspy';
import {
  Button,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import { IconCriticalCard, IconEllipse, IconInfoCard, IconPreloader, IconWarningCard } from '../../../icons/customIcons';
import {
  loadNotification,
  markAsReadNotification,
  cleanNotification,
} from '../../../catalog/Catalog.action';
import {
  DialogTitleTop,
  NotAllDialog,
  NotDialogBlock,
  NotDialogBlockDate,
  NotDialogBlockMin,
  NotDialogContent,
  NotDialogEllipse,
  NotDialogTitle,
  MessageContent,
  PreloaderContainer,
} from './NotificationMenu.styled';
import { config, selectLang } from '../../../../config';
import NotificationSearch from './NotificationSearch';

const NUMBER_NOTICE_PER_PAGE = 50;

class NotificationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment().toISOString(true),
      dateEnd: moment().toISOString(true),
      error: false,
      isEnd: false,
      isLoading: false,
      suggestions: [],
      datePageNumber: 1,
      searchPageNumber: 1,
      searchTotalCount: 0,
      isOnlySearchNotifications: false,
      isSuccessfulSearch: true,
    };
    const tz = moment.tz.guess();
    moment.locale(selectLang(config.lang));
    moment.tz.setDefault(tz);
  }

  componentDidMount() {
    if (_.isEmpty(this.props.items)) {
      this.uploadNotification();
    } else if (this.props.notificationLastDate > this.props.items[0].date) {
      this.props.cleanNotifications();
      this.uploadNotification();
    } else {
      this.setState({ datePageNumber: _.ceil(this.props.items.length / NUMBER_NOTICE_PER_PAGE) + 1 })
    }
  }

  onScroll = (event) => {
    const { error, isLoading, isEnd, dateEnd } = this.state;
    if (error || isLoading || (isEnd && _.isEmpty(this.state.suggestions))) return;
    if (
      event.target.scrollTop + event.target.clientHeight + 1 >=
      event.target.scrollHeight
    ) {
      //this.markAsRead();
      if (!this.state.isOnlySearchNotifications
        && this.props.items.length < this.props.itemsCount
      ) {
        // this.uploadNotification(dateEnd);
        this.uploadNotification();
      } else if (this.state.searchTotalCount > this.state.suggestions.length &&
        this.state.searchPageNumber * NUMBER_NOTICE_PER_PAGE === this.state.suggestions.length) {
        this.setState({ searchPageNumber: this.state.searchPageNumber + 1 });
      }
    }
  };

  onNotificationSearch = (value, count, success = true) => {
    this.setState({
      suggestions: value,
      searchTotalCount: count,
      isSuccessfulSearch: success,
    });
    if (count === 0) this.setState({ searchPageNumber: 1 });
  }

  checkReadedSuggestions = (data) => {
    const readedSuggestions = _.map(this.state.suggestions, (d) => {
      if (_.includes(data, d.id)) {
        _.set(d, 'isRead', true);
      }
      return d;
    });
    this.setState({ suggestions: readedSuggestions });
  }

  markAsRead = () => {
    // const unReadIds = !!this.state.isOnlySearchNotifications
    //   ? _.map(
    //     _.filter(this.state.suggestions, { isRead: false }),
    //     'id')
    //   : _.map(
    //     _.filter(this.props.items, { isRead: false }),
    //     'id');
    // if (!_.isEmpty(unReadIds)) {
    //   !!this.state.isOnlySearchNotifications
    //     ? this.props.markAsReadNotification(unReadIds,
    //       (data) => this.checkReadedSuggestions(data))
    //     : this.props.markAsReadNotification(unReadIds)
    // }
    this.props.markAsReadNotification();
  };

  oneMarkAsRead = (id) => {
    let ids = [id]
    !!this.state.isOnlySearchNotifications
      ? this.props.markAsReadNotification(ids,
        (data) => this.checkReadedSuggestions(data))
      : this.props.markAsReadNotification(ids);
  };

  uploadNotification = (date) => {
    this.setState({ isLoading: true }, () => {
      // const prevMonths = moment(date || this.state.date);
      // const dateBegin = prevMonths.toISOString(true);
      // const dateEnd = prevMonths.subtract(2, 'months').toISOString(true);     
      const dateBegin = null;
      const dateEnd = null;
      this.props.loadNotifications(
        dateBegin,
        dateEnd,
        this.state.datePageNumber,
        NUMBER_NOTICE_PER_PAGE,
        !date,
        (error, isEnd, count = 0) => {
          // const state = error ? { error: true } : { dateEnd: dateEnd };
          const state = error ? { error: true } : { error: false };
          this.setState({ ...state, isLoading: false, isEnd: isEnd });
          count === 0
            ? this.setState({ datePageNumber: 1 })
            : this.setState({ datePageNumber: this.state.datePageNumber + 1 })
        },
      );
    });
  };

  renderIconCard = (level) => {
    switch (level) {
      case 'info':
        return <IconInfoCard />;
      case 'warning':
        return <IconWarningCard />;
      case 'critical':
        return <IconCriticalCard width={28} height={28} />;
      default:
        return null;
    }
  };

  showOnlySearchNotifications = () => {
    this.setState({ isOnlySearchNotifications: true });
  };

  showOnlyCurrentNotifications = () => {
    this.setState({ isOnlySearchNotifications: false });
  };

  buildItems = (items) => {
    const jsxElements = [];
    let jsxBlocks = [];

    for (let i = 0; i < items.length; i++) {
      let currentDate = moment(items[i].date).format('DD.MM.YYYY');
      let isRead = items[i].isRead
      if (i === 0) {
        jsxBlocks.push(
          <NotDialogBlock key={`notif_${i}`}>
            <NotDialogBlockDate>{currentDate}</NotDialogBlockDate>
            <NotDialogBlockMin onClick={!isRead
              ? () => this.oneMarkAsRead(items[i].id)
              : () => { }}
            >
              {this.renderIconCard(items[i].level)}
              <NotDialogContent dangerouslySetInnerHTML={{ __html: items[i].text }} />
              <NotDialogEllipse>
                {!isRead ? <IconEllipse /> : null}
              </NotDialogEllipse>
            </NotDialogBlockMin>
          </NotDialogBlock>,
        )
      } else {
        let currentDateNext = moment(items[i - 1].date).format('DD.MM.YYYY');

        if (currentDate !== currentDateNext) {
          jsxBlocks.push(
            <NotDialogBlock key={`notif_${i}`}>
              <NotDialogBlockDate>{currentDate}</NotDialogBlockDate>
              <NotDialogBlockMin onClick={!isRead
                ? () => this.oneMarkAsRead(items[i].id)
                : () => { }}
              >
                {this.renderIconCard(items[i].level)}
                <NotDialogContent dangerouslySetInnerHTML={{ __html: items[i].text }} />
                <NotDialogEllipse>
                  {!isRead ? <IconEllipse /> : null}
                </NotDialogEllipse>
              </NotDialogBlockMin>
            </NotDialogBlock>,
          )
        } else jsxBlocks.push(
          <NotDialogBlockMin
            onClick={!isRead
              ? () => this.oneMarkAsRead(items[i].id)
              : () => { }}
            key={`notif_${i}`}>
            {this.renderIconCard(items[i].level)}
            <NotDialogContent dangerouslySetInnerHTML={{ __html: items[i].text }} />
            <NotDialogEllipse>
              {!isRead ? <IconEllipse /> : null}
            </NotDialogEllipse>
          </NotDialogBlockMin>,
        );
      }
    }
    jsxElements.push(
      <div key={_.uniqueId('notif_block_')}>{jsxBlocks}</div>
    );
    return jsxElements;
  };

  render() {
    const { t, notificationNewCount, items } = this.props;
    const { searchTotalCount,
      searchPageNumber,
      isOnlySearchNotifications,
      isSuccessfulSearch,
      isLoading } = this.state;
    // const keysOnBlocks = _.keys(_.groupBy(this.props.items, 'sectionName'));
    const isNotNotifications = _.isEmpty(items) &&
      !isOnlySearchNotifications &&
      !isLoading;
    const isHiddenMarkAsReadBtn = isNotNotifications || !isSuccessfulSearch;

    return (
      <MuiThemeProvider theme={NotAllDialog}>

        <DialogTitle disableTypography={true} id="alert-dialog-title">
          <DialogTitleTop>
            <NotDialogTitle>{t('Уведомления')}</NotDialogTitle>
            {!isHiddenMarkAsReadBtn && (
              <Button
                color={'primary'}
                id="clean-btn"
                onClick={this.markAsRead}
                disabled={!notificationNewCount || isLoading}
              >
                {t('Отметить все как прочитанные')}
              </Button>
            )}
          </DialogTitleTop>
          {/* <NotifMonth id="tooltip" /> */}
          {/* <Scrollspy
              rootEl="#dialog"
              items={keysOnBlocks}
              onUpdate={(el) => {
                const name = _.get(el, 'id') || '';
                const title = keysOnBlocks[0] === name ? '' : name;
                const tooltipEl = document.getElementById('tooltip');
                tooltipEl.style.opacity = !!title ? 1 : 0;
                tooltipEl.innerHTML = title;
              }}
            /> */}
          <NotificationSearch
            onNotificationSearch={this.onNotificationSearch}
            pageNumber={searchPageNumber}
            totalCount={searchTotalCount}
            numberNoticePerPage={NUMBER_NOTICE_PER_PAGE}
            showOnlySearchNotifications={this.showOnlySearchNotifications}
            showOnlyCurrentNotifications={this.showOnlyCurrentNotifications}
            isDisabledSearch={isLoading}
          />
          {isLoading && (
            <PreloaderContainer>
              <IconPreloader />
            </PreloaderContainer>
          )}
        </DialogTitle>

        <DialogContent id={'dialog'}
          onScroll={this.onScroll}
          onClick={this.props.exportIfReport}
          onContextMenu={this.props.cancelReportLink}
        >
          {isNotNotifications && (
            <MessageContent>
              {t('У вас пока нет уведомлений')}
            </MessageContent>
          )}
          {!!searchTotalCount && (
            <MessageContent small>
              {t('Всего найдено')}: {searchTotalCount}
            </MessageContent>
          )}
          {!isSuccessfulSearch && (
            <MessageContent>
              {t('По вашему запросу ничего не найдено')}
            </MessageContent>
          )}
          {isOnlySearchNotifications
            ? this.buildItems(this.state.suggestions)
            : this.buildItems(items)}
        </DialogContent>
      </MuiThemeProvider>
    );
  }
}

NotificationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  loadNotifications: PropTypes.func.isRequired,
  markAsReadNotification: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  exportIfReport: PropTypes.func.isRequired,
  cancelReportLink: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  items: state.catalogReducer.notifications,
  notificationNewCount: state.catalogReducer.notificationRecentInfo.newCount,
  notificationLastDate: state.catalogReducer.notificationRecentInfo.lastDate,
  itemsCount: state.catalogReducer.notificationsTotalCount,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadNotifications: (dateBegin, dateEnd, pageNumber, pageSize, firstLoad, cbk) =>
        loadNotification(dateBegin, dateEnd, pageNumber, pageSize, firstLoad, cbk),
      markAsReadNotification: (ids, cbk = null) => markAsReadNotification(ids, cbk),
      cleanNotifications: () => cleanNotification(),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationModal);
