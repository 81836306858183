import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import _ from 'lodash';
import { MuiThemeProvider, Button } from '@material-ui/core';
import {
  IconGridEmpty,
  IconPlusNew,
  IconPlusBlue,
} from '../../../../common/icons/customIcons';
import { Preloader, ConfirmDialog, Pagination } from '../../../../components';
import {
  ColumnCellInner,
  ColumnHeaderProfile,
  DefaultGridButton,
  GridActionsLeft,
  GridActionsRight,
  GridEmpty,
  GridEmptySubstring,
  GridEmptyText,
  GridEmptyTitle,
  GridTableContainer,
  ManagementSiteActions,
  ManagementSiteCont,
  // SortingBtn,
  WithoutSortingBtn,
} from "../../../../common/styles/Common.styled";
import { ColumnCellProfile } from "../../../products/productGrid/ProductsGrid.styled";


import { getSortField } from '../../../../common/utils/utils';
import GridActionsItems from './gridActions/GridActionsItems';
import {
  deleteProfileStatus,
  loadGlnDictionary,
  loadProfileStatus,
} from '../../ducks/ProfileStatus/ProfileStatus.actions';
import { throwNotification } from '../../../../common/structure';
import * as selectors from '../../ducks/ProfileStatus/ProfileStatus.selectors';
import CreationStatusModal from './modal/CreationStatusModal';
import { GridButton, GridTableProfile, ProfileGridWrap } from '../../Profile.styled';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const ConfirmStateInit = {
  isOpen: false,
  title: '',
  message: '',
  funcAccept: null,
  funcDismiss: null,
  funcCancel: null,
  acceptText: '',
  dismissText: '',
};

const FiltersInit = {
  name: null,
  inn: null,
  address: null,
  gln: null,
};

const SortInit = [
  { field: 'name', direction: '' },
  { field: 'inn', direction: '' },
  { field: 'address', direction: '' },
  { field: 'gln', direction: '' },
];

const isNotActiveFilters = (filters) => _.isEqual(FiltersInit, filters);

const renderTypeToString = (type) => {
  switch (type) {
    case 0:
      return 'ВЛАДЕЛЕЦ ИДЕНТИФИКАТОРА ТОВАРА (GTIN)';
    case 1:
      return 'ПРОИЗВОДСТВЕННАЯ ПЛОЩАДКА';
    case 2:
      return 'УЧАСТНИК ОБОРОТА ТОВАРА';
    default:
      return;
  }
};

class ProfileStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      pageNum: 0,
      sort: SortInit,
      filters: FiltersInit,

      selectedIds: [],
      deleteIds: [],
      editData: {},
      modalConfirm: ConfirmStateInit,
      modalCreation: { isOpen: false },
    };

    this.mIsNotActiveFilters = _.memoize(isNotActiveFilters);
  }

  componentDidMount() {
    if (_.isEmpty(this.props.glnDictionary)) {
      this.props.loadGlnDictionary();
    }
    this.changeGridParams();
  }

  getBuildParamsForList = () => {
    return {
      pageSize: this.state.pageSize,
      pageNum: this.state.pageNum,
      filters: this.state.filters,
      sort: getSortField(this.state.sort),
    };
  };

  changeGridParams = () => {
    // const params = this.getBuildParamsForList();
    this.props.onLoadProfileStatus();
  };

  resetFilters = () => {
    this.setState(
      (prevState) => ({ ...prevState, filters: FiltersInit }),
      this.changeGridParams,
    );
  };

  getNoDataProps = () => ({ resetFilters: this.resetFilters });
  getPaginationProps = () => ({ totalObjects: this.props.totalObjects });

  confirmModal = (confirm, state = {}) => {
    const s = _.assign({}, state);
    this.setState((prevState) => ({
      ...prevState,
      ...s,
      modalConfirm: confirm,
    }));
  };

  onDelete = (id) => {
    const { t } = this.props;
    const ids = _.isNumber(id) ? [id] : this.state.selectedIds;
    let message = t('Вы уверены, что хотите удалить статусы?');
    if (_.size(ids) === 1) {
      const item = _.find(this.props.data, { id: ids[0] });
      message =
        t('Вы уверены, что хотите удалить статус') +
        ` «${t(renderTypeToString(item.type))}» ?`;
    }

    if (!_.isEmpty(ids)) {
      const objModal = {
        isOpen: true,
        message: message,
        funcAccept: this.onDeleteAccept,
        funcDismiss: this.onDeleteDissmiss,
        funcCancel: this.onDeleteDissmiss,
        acceptText: t('Удалить'),
        dismissText: t('Отменить'),
      };
      this.confirmModal(objModal, { deleteIds: ids });
    }
  };

  onDeleteAccept = () => {
    const state = { deleteIds: [], selectedIds: [] };
    const deleteIds = _.clone(this.state.deleteIds);
    this.confirmModal(ConfirmStateInit, state);
    this.props.onDeleteProfileStatus(deleteIds[0], (error, data) => {
      let message = 'Статусы успешно удалены';
      if (_.isNull(error)) {
        const item = _.find(this.props.data, { id: deleteIds[0] });
        message = `Статус «${renderTypeToString(item.type)}» успешно удален`;
        this.props.throwNotification(message, 'success');
        this.changeGridParams();
      }
    });
  };

  onDeleteDissmiss = () => {
    this.confirmModal(ConfirmStateInit, { deleteIds: [] });
  };

  // method for possible future using
  // onEdit = (id) => {
  //   const item = _.find(this.props.data, {id: id});
  //   this.setState({
  //     modalCreation: { ...this.state.modalCreation, isOpen: true },
  //     editData: item,
  //   });
  // };

  openCreationModal = () => {
    this.setState({
      modalCreation: { ...this.state.modalCreation, isOpen: true },
    });
  };

  hideCreationModal = (reload) => {
    this.setState({
      modalCreation: { ...this.state.modalCreation, isOpen: false },
      editData: {},
    });
    if (_.isBoolean(reload)) {
      this.changeGridParams();
    }
  };

  render() {
    const { t, loading, data, totalObjects } = this.props;
    const {
      modalCreation,
      modalConfirm,
      editData,
      pageNum,
      pageSize,
    } = this.state;

    return (
      <div>
        {modalCreation.isOpen && (
          <CreationStatusModal
            editData={editData}
            handleClose={this.hideCreationModal}
            t={t}
          />
        )}

        {modalConfirm.isOpen && (
          <ConfirmDialog
            onAccept={modalConfirm.funcAccept}
            onDismiss={modalConfirm.funcDismiss}
            onCancel={modalConfirm.funcCancel}
            title={modalConfirm.title}
            message={modalConfirm.message}
            acceptBtnText={modalConfirm.acceptText}
            dismissBtnText={modalConfirm.dismissText}
          />
        )}

        <ManagementSiteCont>
          <ManagementSiteActions>
            <GridActionsLeft>
              <MuiThemeProvider theme={GridButton}>
                <Button disabled={loading} onClick={this.openCreationModal}>
                  <IconPlusNew />
                  <span style={{ marginLeft: '10px' }}>
                    {t('Добавить статус профиля')}
                  </span>
                </Button>
              </MuiThemeProvider>
            </GridActionsLeft>
          </ManagementSiteActions>
          <ProfileGridWrap>
            <GridTableContainer className="customTable">
              <GridTableProfile>
                <div style={{ display: loading || !data.length ? 'none' : 'block' }}>
                  <ReactTableFixedColumns
                    data={data}
                    columns={[
                      {
                        name: t("Статус"),
                        show: true,
                        position: 0,
                        accessor: "type",
                        sortable: false,
                        minWidth: 170,
                        Header: (x) => (
                          <ColumnHeaderProfile id="nameGridFilter">
                            {t("Статус")}
                            <WithoutSortingBtn />
                          </ColumnHeaderProfile>
                        ),
                        Cell: ({ original }) => (
                          <ColumnCellProfile>
                            <ColumnCellInner>{t(renderTypeToString(original.type))}</ColumnCellInner>
                          </ColumnCellProfile>
                        )
                      },
                      {
                        name: t("GCP"),
                        show: true,
                        position: 0,
                        accessor: "gcp",
                        sortable: false,
                        minWidth: 170,
                        Header: (x) => (
                          <ColumnHeaderProfile id="nameGridFilter">
                            {t("GCP")}
                            <WithoutSortingBtn />
                          </ColumnHeaderProfile>
                        ),
                        Cell: ({ original }) => (
                          <ColumnCellProfile>
                            <ColumnCellInner>{original.gcp || ''}</ColumnCellInner>
                          </ColumnCellProfile>
                        )
                      },
                      {
                        name: "GLN",
                        show: true,
                        position: 4,
                        accessor: "gln",
                        sortable: false,
                        width: 170,
                        Header: (x) => (
                          <ColumnHeaderProfile id="glnGridFilter">
                            GLN
                            <WithoutSortingBtn />
                          </ColumnHeaderProfile>
                        ),
                        Cell: ({ original }) => (
                          <ColumnCellProfile>
                            <ColumnCellInner>{original.gln || ''}</ColumnCellInner>
                          </ColumnCellProfile>
                        )
                      },
                      {
                        name: t("Дата регистрации в ГС1"),
                        show: true,
                        position: 3,
                        accessor: "validity",
                        sortable: false,
                        minWidth: 170,
                        Header: (x) => (
                          <ColumnHeaderProfile id="addressGridFilter">
                            {t("Дата регистрации в ГС1")}
                            <WithoutSortingBtn />
                          </ColumnHeaderProfile>
                        ),
                        Cell: ({ original }) => (
                          <ColumnCellProfile>
                            <ColumnCellInner>{original.validity}</ColumnCellInner>
                          </ColumnCellProfile>
                        )
                      },
                      {
                        name: null,
                        accessor: "actions",
                        sortable: false,
                        width: 0,
                        Header: null,
                        Cell: ({ original }) => (
                          <GridActionsItems
                            onDelete={this.onDelete}
                            id={original.id}
                          />
                        )
                      },
                    ]}
                    defaultPageSize={pageSize}
                    className="-highlight"
                    page={pageNum}
                    pages={Math.ceil(totalObjects / pageSize)}
                    pageSize={pageSize}
                    PaginationComponent={Pagination}
                    getPaginationProps={this.getPaginationProps}
                    onPageChange={(page) => {
                      this.setState({ pageNum: page }, this.changeGridParams);
                    }}
                    resizable={false}
                    getNoDataProps={this.getNoDataProps}
                    minRows={0}
                    manual
                  />

                </div>
              </GridTableProfile>
              {!data.length && (
                <GridEmpty>
                  <GridEmptyTitle>{t('Таблица не заполнена')}</GridEmptyTitle>
                  <GridEmptySubstring>{t('У вас еще нет добавленных статусов')}</GridEmptySubstring>
                </GridEmpty>
              )}
            </GridTableContainer>
          </ProfileGridWrap>
          <Preloader isOpen={loading} text={t('Загрузка')}  />
        </ManagementSiteCont>
      </div>
    );
  }
}

ProfileStatus.propTypes = {
  throwNotification: PropTypes.func.isRequired,
  onLoadProfileStatus: PropTypes.func.isRequired,
  onDeleteProfileStatus: PropTypes.func.isRequired,
  loadGlnDictionary: PropTypes.func.isRequired,
  glnDictionary: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  totalObjects: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  loading: !!selectors.loading(state),
  totalObjects: selectors.totalObjects(state),
  data: selectors.data(state),
  glnDictionary: selectors.glnDictionary(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwNotification: (message, type) => throwNotification(message, type),
      onLoadProfileStatus: () => loadProfileStatus(),
      onDeleteProfileStatus: (id, cbk) => deleteProfileStatus(id, cbk),
      loadGlnDictionary: () => loadGlnDictionary(),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ProfileStatus);
