import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { MuiThemeProvider, Button } from '@material-ui/core';
import {
  DragDrop,
  DragDropButton,
  DragDropButtonText,
  DragDropContainerImport,
  DragDropContent,
  DragDropInput,
  DragDropLabel,
  DragDropView,
  DragDropViewLeft,
  DragDropViewRight,
  DragDropViewFileName,
  DragDropViewFileSize,
  DragDropViewFile,
  DragDropWrapper,
  DragDropMsg,
} from './dragDropField.styled';
import { IconTrash } from '../../common/icons/customIcons';
import { CreationTrashBtns } from '../../pages/profile/Profile.styled';

class DragDropFieldImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: false,
      isDragging: false,
    };
  }

  onDropHandler = (event) => {
    event.preventDefault();
    let files = [...event.dataTransfer.files]
    this.props.handleChangeFile(event)
    this.setState({
      value: false,
      isDragging: false,
    })
  };

  dragStartHandler = (event) => {
    event.preventDefault();
    this.setState({
      value: true,
    })
  };

  dragLeaveHandler = (event) => {
    event.preventDefault();
    this.setState({
      value: false,
      isDragging: false,
    })
  };

  dragOverHandler = (event) => {
    event.preventDefault();
    this.setState({
      value: true,
      isDragging: true,
    })
  };

  render() {
    const { preview, fileName, fileSize, importFile, importFolder, t, message } = this.props;

    return (
      <DragDropWrapper>
        {!preview && (
          <DragDropContainerImport
            border={this.state.isDragging}
            id="drop-area"
            onDragStart={this.dragStartHandler}
            onDragLeave={this.dragLeaveHandler}
            onDragOver={this.dragOverHandler}
            onDrop={this.onDropHandler}
          >
            {importFile && (
              <DragDrop>
                <DragDropContent bottom='14px'>
                  <DragDropLabel htmlFor="fileselect">
                    {t('Перетащите сюда файл или')}
                  </DragDropLabel>
                  <DragDropInput
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx"
                    id="fileselect"
                    name="fileselect"
                    onChange={this.props.handleChangeFile}
                    style={{ display: 'none' }}
                  />
                  <DragDropButton htmlFor="fileselect" >
                    <DragDropButtonText>
                      {t('Выберите файл')}
                    </DragDropButtonText>
                  </DragDropButton>
                </DragDropContent>
                <DragDropMsg>{message}</DragDropMsg>
              </DragDrop>
            )}
            {importFolder && (
              <DragDrop>
                <DragDropContent top='38px' bottom='58px'>
                  <DragDropLabel>
                    {t('Перетащите сюда файлы или')}
                    <DragDropInput
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={this.props.handleChangeFile}
                      style={{ display: 'none' }}
                    />
                  </DragDropLabel>
                  <div style={{ marginTop: '16px' }}>
                    <Button component="label" disabled={false} color={'primary'}>
                      {t('Выберите папку')}
                      <input
                        type="file"
                        onChange={this.props.handleChangeFolder}
                        style={{ display: 'none' }}
                        webkitdirectory=""
                        mozdirectory=""
                        directory=""
                        multiple
                      />
                    </Button>
                  </div>
                </DragDropContent>
                <DragDropMsg>{message}</DragDropMsg>
              </DragDrop>
            )}
          </DragDropContainerImport>
        )}
        {preview &&
          <>
            {importFolder ?
              (this.props.folder.map((file) => (
                <DragDropView key={file.name}>
                  <DragDropViewLeft>
                    <DragDropViewFile>
                      <DragDropViewFileName>{file.name}</DragDropViewFileName>
                      {file.size && <DragDropViewFileSize>{(file.size / (1024 * 1024)).toFixed(2) + ' ' + 'MB'}</DragDropViewFileSize>}
                    </DragDropViewFile>
                  </DragDropViewLeft>
                  <DragDropViewRight>
                    <MuiThemeProvider theme={CreationTrashBtns}>
                      <Button onClick={() => this.props.deleteImg(file.name)}>
                        <IconTrash />
                      </Button>
                    </MuiThemeProvider>
                  </DragDropViewRight>
                </DragDropView>
              )))
              : (
                <DragDropView>
                  <DragDropViewLeft>
                    <DragDropViewFile>
                      <DragDropViewFileName>{fileName}</DragDropViewFileName>
                      {fileSize && <DragDropViewFileSize>{fileSize + ' ' + 'MB'}</DragDropViewFileSize>}
                    </DragDropViewFile>
                  </DragDropViewLeft>
                  <DragDropViewRight>
                    <MuiThemeProvider theme={CreationTrashBtns}>
                      <Button onClick={this.props.deleteImg}>
                        <IconTrash />
                      </Button>
                    </MuiThemeProvider>
                  </DragDropViewRight>
                </DragDropView>
              )
            }
          </>
        }
      </DragDropWrapper>
    );
  }
}

DragDropFieldImport.propTypes = {
  handleChangeFile: PropTypes.func.isRequired,
  handleChangeFolder: PropTypes.func,
  preview: PropTypes.any,
  deleteImg: PropTypes.func.isRequired,
  importFolder: PropTypes.bool,
  importFile: PropTypes.bool,
  folder: PropTypes.array,
  fileName: PropTypes.string,
  fileSize: PropTypes.string,
};

export default withTranslation()(DragDropFieldImport);