import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { CancelToken, client } from '../../../../api/Client';
import {
  withStyles,
  IconButton,
  MuiThemeProvider,
  Tabs,
  Tab,
  TextField,
  FormControl,
} from '@material-ui/core';
import { throwNotification } from '../../../../common/structure';
import { gtinValidationForSearch } from '../../../../common/utils/utils';
import {
  defaultSearchInput,
  SearchTabs,
  ErrorContent,
  PreloaderContainer,
} from './NotificationMenu.styled';
import { ClearBtn, HeaderSearchIcon } from '../../../../common/styles/Common.styled';
import {
  IconSearchClose,
  IconSearchField,
  IconPreloader,
} from '../../../../common/icons/customIcons';
import { CustomInput } from '../../../../components';
import { defaultFilterInput } from '../../../../pages/products/productGrid/filters/Filters.styled';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    '&:hover g': {
      opacity: '1',
    },
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
});

let cancelGetSuggestions;

async function getSuggestions(payload, pageNumber, pageSize) {
  if (_.isFunction(cancelGetSuggestions)) {
    cancelGetSuggestions('canceled');
  }
  const result = await client().instance.get(
    `/notifications?search=${encodeURIComponent(payload)}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {
      cancelToken: new CancelToken((c) => {
        cancelGetSuggestions = c;
      }),
    },
  );
  cancelGetSuggestions = undefined;
  return result.data;
}

class NotificationAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: this.props.pathname,
      popper: '',
      suggestions: [],
      isOpenRemainderModal: null,
      tab: 0,
      errorMsg: '',
      loading: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { popper, suggestions, tab } = this.state;
    const { pageNumber, totalCount } = this.props;
    if (prevProps.pageNumber !== pageNumber && !!popper) {
      if (!!totalCount &&
        totalCount > suggestions.length) {
        this.suggestionsFetchRequested(popper, pageNumber);
      }
    }
    if (prevState.popper !== popper && !popper) {
      this.handleSuggestionsClearRequested();
      this.props.onNotificationSearch([], 0);
      this.props.showOnlyCurrentNotifications();
    }
    if (prevState.tab !== tab) {
      this.setState({ popper: '', errorMsg: '' });
    }
  }

  handleChange = (newValue, type) => {
    if (type === 'searchGtin') {
      newValue = newValue.replace(/[^[0-9]/, '');
    }
    if (type === 'date') {
      this.setState({ popper: newValue.target.value });
      return;
    }
    this.setState({ popper: newValue });
  };

  handlePaste = (name) => (value) => {
    this.handleChange(value, name);
  };

  suggestionsFetchRequested = (value, pageNumber) => {
    const throwError = _.flow([this.props.t, this.props.throwError]);
    this.setState({ loading: true });
    getSuggestions(value, pageNumber, this.props.numberNoticePerPage)
      .then((response) => {
        if (_.isEmpty(response)) {
          this.props.onNotificationSearch([], 0, false);
          this.setState({ suggestions: [] });
        } else {
          const suggestions = _.get(response, 'data', []);
          const count = _.get(response, 'count', 0);
          const isEmptySuggestions = _.isEmpty(suggestions);
          this.props.onNotificationSearch([...this.state.suggestions, ...suggestions], count, !isEmptySuggestions);
          this.setState({ suggestions: [...this.state.suggestions, ...suggestions] });
        }
      })
      .catch((e) => {
        if (_.get(e, 'message') === 'canceled') {
          this.props.onNotificationSearch([], 0);
          this.setState({ suggestions: [] });
          throwError('Ошибка получения данных поиска!');
        } else {
          const err = _.get(e, 'response.data.errors');
          if (_.isArray(err) && !_.isEmpty(err)) {
            _.forEach(err, (i) => throwError(i));
          } else {
            throwError('Ошибка получения данных поиска!');
          }
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleSuggestionsClearRequested = () => this.setState({
    suggestions: [],
    popper: '',
    errorMsg: '',
  });

  clearField = () => {
    this.handleSuggestionsClearRequested();
    this.props.onNotificationSearch([], 0);
    this.props.showOnlyCurrentNotifications();
  };

  onKeyPress = (name, keyCode) => {
    if (_.includes([13], keyCode) && this.validateSearch(this.state.popper)) {
      this.props.showOnlySearchNotifications();
      this.setState({ suggestions: [] });
      this.suggestionsFetchRequested(this.state.popper, 1);
    } else {
      return
    };
  };

  onKeyUp = (event) => {
    this.onKeyPress('search-date', event.keyCode);
  }

  handleChangeTab = (event, tab) => this.setState({ tab });

  validateSearch = (value) => {
    const { t } = this.props;
    if (this.state.tab === 0) {
      if (value.trim().length < 3) {
        this.setState({ errorMsg: `${t('Введите не менее 3-х символов')}` });
        return false;
      }
    }
    if (this.state.tab === 1) {
      if (!gtinValidationForSearch(value)) {
        this.setState({ errorMsg: `${t('Код состоит из 8, 12, 13 или 14 цифр')}` });
        return false;
      }
    }
    if (this.state.tab === 2) {
      const regex = /\d{2}\.\d{2}\.\d{4}/;
      if (!value.match(regex)) {
        this.setState({
          errorMsg: `${t('Введен некорректный формат даты')}. \n
          ${t('Исправьте и попробуйте снова')}`
        });
        return false;
      }
    }
    this.setState({ errorMsg: '' });
    return true;
  };

  onClickHandler = () => {
    this.props.showOnlySearchNotifications();
    this.setState({ suggestions: [] });
    if (this.validateSearch(this.state.popper)) {
      this.suggestionsFetchRequested(this.state.popper, 1);
    } else return;
  };

  render() {
    const { classes, t, isDisabledSearch } = this.props;
    const { popper, errorMsg, tab, loading } = this.state;
    const disabled = isDisabledSearch || loading;
    const placeholderWithouDots = 'Укажите дату формата ддммгггг'.replace(/\./g, '')
    const values = {
      0:
        <CustomInput
          name='search-text'
          placeholder={t('Введите текст')}
          onChange={(value) => this.handleChange(value, 'text')}
          value={popper}
          cleanButtonStyles={{ display: 'none' }}
          onKeyPress={this.onKeyPress}
          type={'text'}
          error={!!errorMsg}
          maxLength={200}
          disabled={disabled}
        />,
      1:
        <CustomInput
          name='searchGtin'
          placeholder={t('Укажите код упаковки (GTIN)')}
          onChange={(value) => this.handleChange(value, 'searchGtin')}
          value={popper}
          cleanButtonStyles={{ display: 'none' }}
          onKeyPress={this.onKeyPress}
          type={'text'}
          maxLength={14}
          error={!!errorMsg}
          onPaste={this.handlePaste('searchGtin')}
          t={t}
          disabled={disabled}
        />,

      2:
        <FormControl fullWidth={true}>
          <InputMask
            value={popper}
            onChange={(event) => this.handleChange(event, 'date')}
            mask={'99.99.9999'}
            disabled={disabled}
          >
            {(inputProps) => (
              <TextField
                {...inputProps}
                name='search-date'
                id='search-date'
                type="text"
                label={t(placeholderWithouDots)}
                error={!!errorMsg}
                onKeyUp={this.onKeyUp}
              />
            )}
          </InputMask>
        </FormControl>,
    };

    return (
      <>
        <div className={classes.root}>
          <MuiThemeProvider theme={SearchTabs}>
            <Tabs
              value={tab}
              onChange={this.handleChangeTab}
              variant="fullWidth">

              <Tab
                disabled={disabled}
                value={0}
                label={t('Текст')}
              />

              <Tab
                disabled={disabled}
                value={1}
                label={t('GTIN')} />

              <Tab
                disabled={disabled}
                value={2}
                label={t('Дата')} />

            </Tabs>
          </MuiThemeProvider>
          <MuiThemeProvider theme={tab === 2 ? defaultFilterInput : defaultSearchInput}>
            {values[tab]}
          </MuiThemeProvider>

          <HeaderSearchIcon>
            {!!popper ? (
              <MuiThemeProvider theme={ClearBtn}>
                <IconButton
                  disabled={disabled}
                  onClick={this.onClickHandler}>
                  <IconSearchField />
                </IconButton>
                <IconButton
                  onClick={this.clearField}>
                  <IconSearchClose />
                </IconButton>
              </MuiThemeProvider>
            ) : (
              <MuiThemeProvider theme={ClearBtn}>
                <IconButton
                  disabled={disabled}
                  onClick={this.onClickHandler}>
                  <IconSearchField />
                </IconButton>
              </MuiThemeProvider>
            )}
            {this.props.children}
          </HeaderSearchIcon>
        </div>
        <ErrorContent>
          {errorMsg}
        </ErrorContent>
        {loading && (
          <PreloaderContainer>
            <IconPreloader />
          </PreloaderContainer>
        )}
      </>
    );
  }
}

NotificationAutocomplete.propTypes = {
  throwError: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onNotificationSearch: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  numberNoticePerPage: PropTypes.number.isRequired,
  showOnlySearchNotifications: PropTypes.func.isRequired,
  showOnlyCurrentNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  locale: state.profileReducer.locale,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwError: (message) => throwNotification(message, 'error'),
      goToPage: (page) => push(page),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationAutocomplete);
