import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators, compose } from 'redux';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import ReactTable from 'react-table';
import _ from 'lodash';
import moment from "moment-timezone";
import { push } from 'connected-react-router';
import {
    ColumnCellInner,
    ColumnHeaderProfile,
    GridTableContainer,
    WithoutSortingBtn,
} from '../../../common/styles/Common.styled';
import { Preloader, ConfirmDialog, Pagination } from '../../../components';
import { ColumnCellProfile } from '../../products/productGrid/ProductsGrid.styled';
import { PointerName } from '../styled/StatisticsTable.styled';
import { config } from '../../../config';
import NoDataTableAnalytic from '../../../components/NoDataTableAnalytic/NoDataTableAnalytic';
import ProfileEllipsisCell from '../../profile/views/subProfiles/cell/ProfileEllipsisCell';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

class StatisticsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onView = (id) => {
        const page = `${config.urls.product}/goods/${id}`
        this.props.goToPage(page);
    };

    render() {
        const {
            t,
            typeModal,
            goodsDateExpirationStatistics,
            goodsExemplarStatistics,
            tradeMarkElapsedStatistics,
        } = this.props;
        return (
            <GridTableContainer className="customTable customBorderTable">
                {typeModal === 'goodsDateExpirationCount' && (
                    <ReactTableFixedColumns
                        data={goodsDateExpirationStatistics}
                        columns={[
                            {
                                name: t("Торговое наименование товара"),
                                show: true,
                                position: 0,
                                accessor: "type",
                                sortable: false,
                                minWidth: 260,
                                Header: (x) => (
                                    <ColumnHeaderProfile id="nameGridFilter">
                                        {t("Торговое наименование товара")}
                                        <WithoutSortingBtn />
                                    </ColumnHeaderProfile>
                                ),
                                Cell: ({ original }) => (
                                    <ProfileEllipsisCell
                                        value={original.goodName}
                                        cellId={`good-name-${original.goodId}`}
                                    />
                                )
                            },
                            {
                                name: t("Код упаковки"),
                                show: true,
                                position: 0,
                                accessor: "gtin",
                                sortable: false,
                                minWidth: 140,
                                Header: (x) => (
                                    <ColumnHeaderProfile id="nameGridFilter">
                                        {t("Код упаковки")}
                                        <WithoutSortingBtn />
                                    </ColumnHeaderProfile>
                                ),
                                Cell: ({ original }) => (
                                    <ColumnCellProfile>
                                        <ColumnCellInner onClick={() => (this.onView(original.goodId))}>
                                            <PointerName>{original.gtin}</PointerName>
                                        </ColumnCellInner>
                                    </ColumnCellProfile>
                                )
                            },
                            {
                                name: "Номер документа",
                                show: true,
                                position: 4,
                                accessor: "gln",
                                sortable: false,
                                width: 150,
                                Header: (x) => (
                                    <ColumnHeaderProfile id="glnGridFilter">
                                        {t("Номер документа")}
                                        <WithoutSortingBtn />
                                    </ColumnHeaderProfile>
                                ),
                                Cell: ({ original }) => (
                                    <ProfileEllipsisCell
                                        value={original.docNumber}
                                        cellId={`doc-number-${original.goodId}`}
                                    />
                                )
                            },
                            {
                                name: t("Срок действия"),
                                show: true,
                                position: 3,
                                accessor: "validity",
                                sortable: false,
                                minWidth: 140,
                                Header: (x) => (
                                    <ColumnHeaderProfile small id="addressGridFilter">
                                        {t("Срок действия")}
                                        <WithoutSortingBtn />
                                    </ColumnHeaderProfile>
                                ),
                                Cell: ({ original }) => (
                                    <ColumnCellProfile>
                                        <ColumnCellInner>{_.defaultTo(moment(original.docDate).format('DD.MM.YYYY'), '')}</ColumnCellInner>
                                    </ColumnCellProfile>
                                )
                            },
                        ]}
                        //defaultPageSize={pageSize}
                        className="-highlight"
                        //page={pageNum}
                        //pages={Math.ceil( totalObjects / pageSize )}
                        //pageSize={pageSize}
                        pages={0}
                        getPaginationProps={() => ({ totalObjects: 0 })}
                        PaginationComponent={Pagination}
                        // getPaginationProps={this.getPaginationProps}
                        // onPageChange={(page) => {
                        //     this.setState({ pageNum: page }, this.changeGridParams);
                        // }}
                        resizable={false}
                        getNoDataProps={this.getNoDataProps}
                        NoDataComponent={NoDataTableAnalytic}
                        minRows={0}
                        manual
                    />
                )}
                {typeModal === 'goodsExemplarCount' && (
                    <ReactTableFixedColumns
                        data={goodsExemplarStatistics}
                        columns={[
                            {
                                name: t("Наименование организации"),
                                show: true,
                                position: 0,
                                accessor: "type",
                                sortable: false,
                                minWidth: 260,
                                Header: (x) => (
                                    <ColumnHeaderProfile id="nameGridFilter">
                                        {t("Наименование организации")}
                                        <WithoutSortingBtn />
                                    </ColumnHeaderProfile>
                                ),
                                Cell: ({ original }) => (
                                    <ProfileEllipsisCell
                                        value={original.accountName}
                                        cellId={`account-name-${original.goodId}`}
                                    />
                                )
                            },
                            {
                                name: t("Налоговый идентификатор"),
                                show: true,
                                position: 0,
                                accessor: "gcp",
                                sortable: false,
                                width: 150,
                                Header: (x) => (
                                    <ColumnHeaderProfile small id="nameGridFilter">
                                        {t("Налоговый идентификатор")}
                                        <WithoutSortingBtn />
                                    </ColumnHeaderProfile>
                                ),
                                Cell: ({ original }) => (
                                    <ColumnCellProfile>
                                        <ColumnCellInner>{original.inn}</ColumnCellInner>
                                    </ColumnCellProfile>
                                )
                            },
                            {
                                name: "Дата публикации",
                                show: true,
                                position: 4,
                                accessor: "gln",
                                sortable: false,
                                width: 140,
                                Header: (x) => (
                                    <ColumnHeaderProfile id="glnGridFilter">
                                        {t('Дата публикации')}
                                        <WithoutSortingBtn />
                                    </ColumnHeaderProfile>
                                ),
                                Cell: ({ original }) => (
                                    <ColumnCellProfile>
                                        <ColumnCellInner>{original.publishedDate === null ? null : _.defaultTo(moment(original.publishedDate).format('DD.MM.YYYY'), '')}</ColumnCellInner>
                                    </ColumnCellProfile>
                                )
                            },
                            {
                                name: t("Код упаковки"),
                                show: true,
                                position: 3,
                                accessor: "validity",
                                sortable: false,
                                width: 140,
                                Header: (x) => (
                                    <ColumnHeaderProfile id="addressGridFilter">
                                        {t("Код упаковки")}
                                        <WithoutSortingBtn />
                                    </ColumnHeaderProfile>
                                ),
                                Cell: ({ original }) => (
                                    <ColumnCellProfile>
                                        <ColumnCellInner onClick={() => (this.onView(original.goodId))}>
                                            <PointerName>{original.gtin}</PointerName>
                                        </ColumnCellInner>
                                    </ColumnCellProfile>
                                )
                            },
                        ]}
                        //defaultPageSize={pageSize}
                        className="-highlight"
                        //page={pageNum}
                        //pages={Math.ceil( totalObjects / pageSize )}
                        //pageSize={pageSize}
                        pages={0}
                        getPaginationProps={() => ({ totalObjects: 0 })}
                        PaginationComponent={Pagination}
                        // getPaginationProps={this.getPaginationProps}
                        // onPageChange={(page) => {
                        //     this.setState({ pageNum: page }, this.changeGridParams);
                        // }}
                        resizable={false}
                        getNoDataProps={this.getNoDataProps}
                        NoDataComponent={NoDataTableAnalytic}
                        minRows={0}
                        manual
                    />
                )}

                {typeModal === 'goodsTradeMarkElapsed' && (
                    <ReactTableFixedColumns
                        data={tradeMarkElapsedStatistics}
                        columns={[
                            {
                                name: t("Торговое наименование товара"),
                                show: true,
                                position: 0,
                                accessor: "type",
                                sortable: false,
                                minWidth: 260,
                                Header: (x) => (
                                    <ColumnHeaderProfile id="nameGridFilter">
                                        {t("Торговое наименование товара")}
                                        <WithoutSortingBtn />
                                    </ColumnHeaderProfile>
                                ),
                                Cell: ({ original }) => (
                                    <ProfileEllipsisCell
                                        value={original.goodName}
                                        cellId={`good-name-${original.goodId}`}
                                    />
                                )
                            },
                            {
                                name: t("Код упаковки"),
                                show: true,
                                position: 0,
                                accessor: "gtin",
                                sortable: false,
                                minWidth: 140,
                                Header: (x) => (
                                    <ColumnHeaderProfile id="nameGridFilter">
                                        {t("Код упаковки")}
                                        <WithoutSortingBtn />
                                    </ColumnHeaderProfile>
                                ),
                                Cell: ({ original }) => (
                                    <ColumnCellProfile>
                                        <ColumnCellInner onClick={() => (this.onView(original.goodId))}>
                                            <PointerName>{original.gtin}</PointerName>
                                        </ColumnCellInner>
                                    </ColumnCellProfile>
                                )
                            },
                            {
                                name: "Номер свидетельства",
                                show: true,
                                position: 4,
                                accessor: "gln",
                                sortable: false,
                                width: 150,
                                Header: (x) => (
                                    <ColumnHeaderProfile small id="glnGridFilter">
                                        {t("Номер свидетельства")}
                                        <WithoutSortingBtn />
                                    </ColumnHeaderProfile>
                                ),
                                Cell: ({ original }) => (
                                    <ColumnCellProfile>
                                        <ColumnCellInner>{original.docNumber}</ColumnCellInner>
                                    </ColumnCellProfile>
                                )
                            },
                            {
                                name: t("Срок действия"),
                                show: true,
                                position: 3,
                                accessor: "validity",
                                sortable: false,
                                minWidth: 140,
                                Header: (x) => (
                                    <ColumnHeaderProfile small id="addressGridFilter">
                                        {t("Срок действия")}
                                        <WithoutSortingBtn />
                                    </ColumnHeaderProfile>
                                ),
                                Cell: ({ original }) => (
                                    <ColumnCellProfile>
                                        <ColumnCellInner>{_.defaultTo(moment(original.docDate).format('DD.MM.YYYY'), '')}</ColumnCellInner>
                                    </ColumnCellProfile>
                                )
                            },
                        ]}
                        //defaultPageSize={pageSize}
                        className="-highlight"
                        //page={pageNum}
                        //pages={Math.ceil( totalObjects / pageSize )}
                        //pageSize={pageSize}
                        pages={0}
                        getPaginationProps={() => ({ totalObjects: 0 })}
                        PaginationComponent={Pagination}
                        // getPaginationProps={this.getPaginationProps}
                        // onPageChange={(page) => {
                        //     this.setState({ pageNum: page }, this.changeGridParams);
                        // }}
                        resizable={false}
                        getNoDataProps={this.getNoDataProps}
                        NoDataComponent={NoDataTableAnalytic}
                        minRows={0}
                        manual
                    />
                )}
            </GridTableContainer>
        )
    }
}

StatisticsTable.propTypes = {
    goToPage: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            goToPage: (page) => push(page),
        },
        dispatch,
    );
};

const mapStateToProps = (state) => ({

});

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(StatisticsTable);