import { createMuiTheme } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../../common/styles/Common.colors';

export const MediaItemMain = styled.div`
  display: flex;  
  justify-content: space-between;
`;
export const MediaItemMainAddLetter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
`;
export const MediaItemSide = styled.div`
  width: 100%;
  min-width: 110px;
  //align-items: flex-end;
  display: flex; 
`;
export const MediaItemSideImage = styled(MediaItemSide)`
  margin-right: 0;
`;
export const MediaItemSideDelete = styled.div`
  margin-left: 15px;
  @media (min-width: 1620px) and (max-width: 1818px) {
    margin-left: 0px;
  }
`;
export const MediaItemPack = styled.div`
  margin-top: 16px;
  min-width: 250px;
  max-width: auto;
  flex-shrink: 0;
  flex-grow: 1;
  .pack-select-main {
    .pack-select {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
export const MediaImg = styled.div`
  border: 1px solid ${(props) => props.theme.main};
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 20px;
  min-width: 200px;
  max-width: 250px;
  border-radius: 3px;
  margin: 16px 0 0;
  button {
    min-width: 20px;
    height: 30px;
    border: none;
    padding: 3px;
    margin: 0;
    line-height: 20px;
  }
`;
export const MediaImgName = styled.span`
  display: inline-block;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const UploadWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 40px 0;
`;

export const MediaDeleteButton = styled.div`
  color: #63666a;
  border: 1px solid #868686;
  padding: 14px 0;
  font-size: 16px;
  background: none;
  transition: 0.4s ease;
  font-family: SegoeUI;
  font-weight: 600;
  border-radius: 3px;
  min-width: 120px;
  text-transform: 'none';
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 25px;
  &:hover: {
    color: #868686;
    background-color: transparent;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px; 
  border-radius: 8px;
  border: ${props => props.active ? `1px solid ${colors.Common.background}` : '1px solid #DCDCDC'};
  overflow: hidden;
`;

export const MediaItemCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% / 10);
  margin: 0 8px 0 0;  
  cursor: pointer;
  &:last-child {
    margin: 0;
  }
`;
export const MediaItemCardTitle = styled.h4`
  margin: 0 0 8px;
  font-weight: normal;
  min-height: 40px;
  text-align: center;
`;
export const MediaItemCardImage = styled.img`
  display: block;
  width: 65px;
  height: 65px;
  object-fit: contain;
`;
export const MediaItemCardIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  min-height: 20px;
`;
export const MediaItemCardIconItem = styled.span`
  margin-right: 8px;
  height: 20px;
`;
export const MediaItemBtnsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 16px 0 1px;  
`;
export const MediaItemFullContent = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 374px);
  padding: 0 32px 0 16px;
`;
export const MediaItemCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const MediaButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      textPrimary: {
        color: `${colors.Common.textColor}`,
        background: '#FFF',
        borderRadius: '4px',
        border: '1px solid #DCDCDC',
        '&:hover': {
          color: `${colors.Common.textColor} !important`,
          backgroundColor: '#F5F5F5!important',
          borderColor: '#F5F5F5',
        },
        '&$disabled': {
          color: '#C9C7C7',
          '& path': {
            stroke: '#C9C7C7',
          },
        },
      },
      textSecondary: {
        color: `${colors.Common.textColor}`,
        background: '#FFF',
        border: '1px solid #DCDCDC',

        '&:hover': {
          color: `${colors.Common.textColor} !important`,
          backgroundColor: '#F5F5F5!important',
          borderColor: '#F5F5F5',
        },
        '&$disabled': {
          color: '#C9C7C7',
          '& path': {
            stroke: '#C9C7C7',
          },
        },
      },
      root: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0',
        textTransform: 'none',
        borderRadius: '4px',
        // minWidth: '200px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: 'normal',
        margin: '16px 0 0 32px',
      },
      text: {
        padding: '9px',
        whiteSpace: 'nowrap',
      },
      label: {
        lineHeight: '24px',
      },
    },
  },
});

export const TrashButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiIconButton: {
      root: {
        padding: '0',
      },
      label: {
        '&:hover path': {
          stroke: `${colors.Common.textColor}`,
        },
      },
    }
  },
});


