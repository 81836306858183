import * as React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { config, selectLang } from '../../../../../config';
import {
  IconCircle,
  IconFlash,
  IconInvalid,
  IconValid,
} from '../../../../../common/icons/customIcons';
import {
  CertIcon,
  CertName,
  CertOwner,
  CertTitle,
  CertTitleLine,
  CertTitleName,
  CertViewBlock,
  CertViewBlockIcon,
  CertViewMainBlock,
  SelectedCert,
} from './CertificateView.styled';
import { SelectedCertMin } from '../../../../../common/styles/Common.styled';

class CertificateView extends React.Component {
  constructor(props) {
    super(props);
    const tz = moment.tz.guess();
    moment.locale(selectLang(config.lang));
    moment.tz.setDefault(tz);
  }

  shouldComponentUpdate(nextProps) {
    return (
      !_.isEqual(this.props.cert, nextProps.cert) ||
      !_.isEqual(this.props.locale, nextProps.locale)
    );
  }

  render() {
    const { cert, user, t } = this.props;
    let startDate = '';
    let endDate = '';
    if (cert && cert.validDateFrom && cert.validDateTo) {
      startDate = moment(cert.validDateFrom).format('DD-MM-YYYY');
      endDate = moment(cert.validDateTo).format('DD-MM-YYYY');
    }

    return (
      <React.Fragment>
        {!!cert && (
          <CertViewMainBlock>
            <CertViewBlock>
              <CertOwner>{cert.companyName}</CertOwner>
              <CertName>{cert.userFullName}</CertName>
              <span>{cert.userPosition}</span>
              <IconFlash/>
            </CertViewBlock>
            <CertViewBlockIcon>
              <CertIcon>
                {cert.isValid ? <IconCircle color={'#00B634'} /> : <IconCircle color={'#E32A38'} />}
              </CertIcon>

              <SelectedCert>
                {!user.isElkUser && (
                  <CertTitleLine>
                    <CertTitle>{t('Сертификат')}:</CertTitle>
                    <CertTitleName>{cert.certUid}</CertTitleName>
                  </CertTitleLine>
                )}

                  <CertTitleLine>
                    <CertTitle>{t('Владелец')}: </CertTitle>
                    <CertTitleName>{_.replace(cert.owner, /"/g, '')}</CertTitleName>
                  </CertTitleLine>

                  <CertTitleLine>
                    <CertTitle>{t('Налоговый идентификатор')}: </CertTitle>
                    <CertTitleName>{cert.inn}</CertTitleName>
                  </CertTitleLine>

               

                {!user.isElkUser && (
                  <React.Fragment>
                    <span>
                      {t('Выдан')}: {_.replace(cert.issuedBy, /"/g, '')}
                    </span>
                    <span>
                      {t('Действителен')}:{' '}
                      {t('c startDate до endDate', { startDate, endDate })}
                    </span>
                  </React.Fragment>
                )}
              </SelectedCert>
            </CertViewBlockIcon>
          </CertViewMainBlock>
        )}
      </React.Fragment>
    );
  }
}

CertificateView.propTypes = {
  user: PropTypes.object.isRequired,
  cert: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.authentificationReducer.user,
  locale: state.profileReducer.locale,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(CertificateView);
