import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../common/styles/Common.colors';

export const DefaultFilterPopover = createMuiTheme({
  typography: {},
  overrides: {
    MuiPopover: {
      paper: {
        maxHeight: '305px',
        minWidth: 'fit-content',
        maxWidth: '223px',
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
        borderRadius: '8px',
        padding: '25px 15px',
        boxSizing: 'border-box',
        marginLeft: '-175px',
        marginTop: '25px',
      },
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '3px',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.background}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '8px 35px',
        fontWeight: '600',
        textTransform: 'capitalize',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
      },
      disabled: {},
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        minWidth: '120px',
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: '#52535A!important',
      },
    },
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
      },
      input: {
        padding: '15px 9px',
        lineHeight: '21px',
        boxSizing: 'border-box',
      },
    },
    MuiInputBase: {
      input: {
        boxSizing: 'border-box',
      },
    },
  },
});

export const defaultFilterSelect = createMuiTheme({
  typography: {},
  overrides: {
    MuiSelect: {
      root: {
        width: '100%',
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
      },
      select: {
        padding: '16px 9px',
      },
      selectMenu: {
        color: '#000',
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
        marginTop: '0!important',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#52535A',
        fontSize: '14px',
        lineHeight: '20px',
      },
      formControl: {
        transform: 'translate(9px, 18px) scale(1)',
        color: '#52535A',
      },
      shrink: {
        display: 'none',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#52535A',
        '&$focused': {
          color: '#52535A',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '9px',
        paddingTop: '9px',
        paddingBottom: '9px',
        color: '#52535A',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0 0 3px 3px',
      },
      elevation8: {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
      },
    },
  },
});
export const FilterBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '173px',
        minHeight: '25px',
        maxHeight: '45px',
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        justifyContent: 'space-between',
        border: '1px solid #DCDCDC',
        position: 'absolute',
        top: '-9px',
        right: '0px',
        '&:hover': {
          border: '1px solid #F5F5F5',
        },
      },
      text: {
        padding: '10px 12px',
      },
      label: {
        textTransform: 'initial',
        fontSize: '16px',
        lineHeight: '24px',
      }
    },
  },
});
export const FilterBtns = styled.div`
  display: flex;
  justify-content: space-between;
  .backEmpty {
    background: none;
    color: #52535a !important;
  }
`;
export const FilterButton = styled.div`
  transform: translateY(-50%);
  position: absolute;
  top: 20px;
  right: 0px;
  //border: 1px solid #DCDCDC;
  border-radius: 4px;
`;
export const FilterContent = styled.div`
  position: relative;
`;
export const FilterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6 {
    color: #52535a;
    font-size: 14px;
    margin: 0;
    line-height: 20px;
  }
  .backEmpty {
    background: none;
  }
`;
export const MenuItemSpan = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${colors.Common.textColor};
    font-size: 16px;
    text-decoration: none;
    line-height: 24px;
    width: 100%;
    transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
     
    & svg path {
        transition: fill 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
`;
