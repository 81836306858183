import { ActionCreator } from 'redux';
import {
  CLEAN_NOTIFICATION_ACTION,
  LOAD_DICTIONARY_CHANNELS_GS1_ACTION,
  LOAD_DICTIONARY_CHANNELS_GS1_FAILED_ACTION,
  LOAD_DICTIONARY_CHANNELS_GS1_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_COUNTRY_ACTION,
  LOAD_DICTIONARY_COUNTRY_FAILED_ACTION,
  LOAD_DICTIONARY_COUNTRY_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_DEPARTMENT_ACTION,
  LOAD_DICTIONARY_DEPARTMENT_FAILED_ACTION,
  LOAD_DICTIONARY_DEPARTMENT_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_EDO_PROVIDER_ACTION,
  LOAD_DICTIONARY_EDO_PROVIDER_FAILED_ACTION,
  LOAD_DICTIONARY_EDO_PROVIDER_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_GCP_PRODUCTS_ACTION,
  LOAD_DICTIONARY_GCP_PRODUCTS_FAILED_ACTION,
  LOAD_DICTIONARY_GCP_PRODUCTS_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_KNOWLEDGE_GS1_ACTION,
  LOAD_DICTIONARY_KNOWLEDGE_GS1_FAILED_ACTION,
  LOAD_DICTIONARY_KNOWLEDGE_GS1_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_OBTAINING_METHOD_ACTION,
  LOAD_DICTIONARY_OBTAINING_METHOD_FAILED_ACTION,
  LOAD_DICTIONARY_OBTAINING_METHOD_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_OKPD_ACTION,
  LOAD_DICTIONARY_OKPD_FAILED_ACTION,
  LOAD_DICTIONARY_OKPD_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_OKVED_ACTION,
  LOAD_DICTIONARY_OKVED_FAILED_ACTION,
  LOAD_DICTIONARY_OKVED_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_OPF_ACTION,
  LOAD_DICTIONARY_OPF_FAILED_ACTION,
  LOAD_DICTIONARY_OPF_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_ORG_PROFILES_ACTION,
  LOAD_DICTIONARY_ORG_PROFILES_FAILED_ACTION,
  LOAD_DICTIONARY_ORG_PROFILES_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIAL_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIAL_FAILED_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIAL_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_ACTION,
  LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_FAILED_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_FAILED_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_FAILED_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPE_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPE_FAILED_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPE_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PALLET_TYPE_ACTION,
  LOAD_DICTIONARY_PALLET_TYPE_FAILED_ACTION,
  LOAD_DICTIONARY_PALLET_TYPE_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_POSITION_ACTION,
  LOAD_DICTIONARY_POSITION_FAILED_ACTION,
  LOAD_DICTIONARY_POSITION_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PROJECTS_GS1_ACTION,
  LOAD_DICTIONARY_PROJECTS_GS1_FAILED_ACTION,
  LOAD_DICTIONARY_PROJECTS_GS1_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_SALES_GS1_ACTION,
  LOAD_DICTIONARY_SALES_GS1_FAILED_ACTION,
  LOAD_DICTIONARY_SALES_GS1_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_SECTOR_ACTION,
  LOAD_DICTIONARY_SECTOR_FAILED_ACTION,
  LOAD_DICTIONARY_SECTOR_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_ACTION,
  LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_FAILED_ACTION,
  LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_SUBJECT_ACTION,
  LOAD_DICTIONARY_SUBJECT_FAILED_ACTION,
  LOAD_DICTIONARY_SUBJECT_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_TM_ACTION,
  LOAD_DICTIONARY_TM_FAILED_ACTION,
  LOAD_DICTIONARY_TM_SUCCEEDED_ACTION,
  LOAD_NOTIFICATION_ACTION,
  LOAD_NOTIFICATION_FAILED_ACTION,
  LOAD_NOTIFICATION_SUCCEEDED_ACTION,
  LOAD_PRODUCTS_CATEGORIES_ACTION,
  LOAD_PRODUCTS_CATEGORIES_FAILED_ACTION,
  LOAD_PRODUCTS_CATEGORIES_SUCCEEDED_ACTION,
  LOAD_TNVED_ACTION,
  LOAD_TNVEDS_FAILED_ACTION,
  LOAD_TNVEDS_SUCCEEDED_ACTION,
  MARK_NOTIFICATION_ACTION,
  MARK_NOTIFICATION_SUCCESSED_ACTION,
  LOAD_GTINS_LIST_BY_PACKAGE_ACTION,
  LOAD_GTINS_LIST_BY_PACKAGE_SUCCEEDED_ACTION,
  LOAD_GTINS_LIST_BY_PACKAGE_FAILED_ACTION,
  LOAD_ACTIVE_KZ_DICTIONARY_ACTION,
  LOAD_ACTIVE_KZ_DICTIONARY_SUCCEEDED_ACTION,
  LOAD_ACTIVE_KZ_DICTIONARY_FAILED_ACTION,
  CLEAR_ACTIVE_KZ_DICTIONARY_ACTION,
  CLEAR_GTINS_LIST_BY_PACKAGE_ACTION,
  LOAD_GTINS_LIST_FOR_VALIDATION_ACTION,
  LOAD_GTINS_LIST_FOR_VALIDATION_SUCCEEDED_ACTION,
  LOAD_GTINS_LIST_FOR_VALIDATION_FAILED_ACTION,
  // LOAD_NEW_OPF_DICTIONARY_ACTION,
  // LOAD_NEW_OPF_DICTIONARY_ACTION_SUCCEEDED,
  // LOAD_NEW_OPF_DICTIONARY_ACTION_FAILED,
  LOAD_KATO_DICTIONARY_ACTION,
  LOAD_KATO_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_KATO_DICTIONARY_ACTION_FAILED,
  LOAD_DICTIONARY_COUNTRIES_ACTION,
  LOAD_DICTIONARY_COUNTRIES_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_COUNTRIES_FAILED_ACTION,
  LOAD_NOTIFICATION_RECENT_ACTION,
  LOAD_NOTIFICATION_RECENT_SUCCEEDED_ACTION,
  LOAD_NOTIFICATION_RECENT_FAILED_ACTION,
} from './CatalogActionTypes.constants';

export const loadGtinsListForValidation: ActionCreator = () => ({
  type: LOAD_GTINS_LIST_FOR_VALIDATION_ACTION,
});

export const loadGtinsListForValidationSucceeded: ActionCreator = (data) => ({
  type: LOAD_GTINS_LIST_FOR_VALIDATION_SUCCEEDED_ACTION,
  data: data,
});

export const loadGtinsListForValidationFailed: ActionCreator = (data) => ({
  type: LOAD_GTINS_LIST_FOR_VALIDATION_FAILED_ACTION,
  data: data,
});

export const clearGtinsListByPackage: ActionCreator = () => ({
  type: CLEAR_GTINS_LIST_BY_PACKAGE_ACTION,
});

export const loadGtinsByPackageDictionary: ActionCreator = (data) => ({
  type: LOAD_GTINS_LIST_BY_PACKAGE_ACTION,
  data: data,
});

export const loadGtinsByPackageDictionarySuccessed: ActionCreator = (data) => ({
  type: LOAD_GTINS_LIST_BY_PACKAGE_SUCCEEDED_ACTION,
  data: data,
});

export const loadGtinsByPackageDictionaryFailed: ActionCreator = (data) => ({
  type: LOAD_GTINS_LIST_BY_PACKAGE_FAILED_ACTION,
  data: data,
});

export const loadTnveds: ActionCreator = () => ({
  type: LOAD_TNVED_ACTION,
});

export const loadTnvedsSucceesed: ActionCreator = (tnveds) => ({
  type: LOAD_TNVEDS_SUCCEEDED_ACTION,
  tnveds: tnveds,
});

export const loadTnvedsFailed: ActionCreator = (errors) => ({
  type: LOAD_TNVEDS_FAILED_ACTION,
  errors: errors,
});

export const loadDictionaryTm: ActionCreator = () => ({
  type: LOAD_DICTIONARY_TM_ACTION,
});

export const loadDictionaryTmSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_TM_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryTmFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_TM_FAILED_ACTION,
});

export const loadDictionaryPosition: ActionCreator = () => ({
  type: LOAD_DICTIONARY_POSITION_ACTION,
});

export const loadDictionaryPositionSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_POSITION_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryPositionFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_POSITION_FAILED_ACTION,
});

export const loadDictionaryCountry: ActionCreator = () => ({
  type: LOAD_DICTIONARY_COUNTRY_ACTION,
});

export const loadDictionaryCountrySuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_COUNTRY_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryCountryFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_COUNTRY_FAILED_ACTION,
});

export const loadDictionaryOpf: ActionCreator = (cbk = () => null) => ({
  type: LOAD_DICTIONARY_OPF_ACTION,
  cbk: cbk,
});

export const loadDictionaryOpfSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_OPF_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryOpfFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_OPF_FAILED_ACTION,
});

export const loadDictionarySubject: ActionCreator = () => ({
  type: LOAD_DICTIONARY_SUBJECT_ACTION,
});

export const loadDictionarySubjectSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_SUBJECT_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionarySubjectFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_SUBJECT_FAILED_ACTION,
});

export const loadDictionaryDepartment: ActionCreator = () => ({
  type: LOAD_DICTIONARY_DEPARTMENT_ACTION,
});

export const loadDictionaryDepartmentSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_DEPARTMENT_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryDepartmentFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_DEPARTMENT_FAILED_ACTION,
});

export const loadDictionaryKnowledge: ActionCreator = () => ({
  type: LOAD_DICTIONARY_KNOWLEDGE_GS1_ACTION,
});

export const loadDictionaryKnowledgeSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_KNOWLEDGE_GS1_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryKnowledgeFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_KNOWLEDGE_GS1_FAILED_ACTION,
});

export const loadDictionaryProjects: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PROJECTS_GS1_ACTION,
});

export const loadDictionaryProjectsSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_PROJECTS_GS1_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryProjectsFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PROJECTS_GS1_FAILED_ACTION,
});

export const loadDictionarySales: ActionCreator = () => ({
  type: LOAD_DICTIONARY_SALES_GS1_ACTION,
});

export const loadDictionarySalesSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_SALES_GS1_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionarySalesFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_SALES_GS1_FAILED_ACTION,
});

export const loadDictionaryChannels: ActionCreator = () => ({
  type: LOAD_DICTIONARY_CHANNELS_GS1_ACTION,
});

export const loadDictionaryChannelsSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_CHANNELS_GS1_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryChannelsFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_CHANNELS_GS1_FAILED_ACTION,
});

export const loadDictionaryOrgProfiles: ActionCreator = () => ({
  type: LOAD_DICTIONARY_ORG_PROFILES_ACTION,
});

export const loadDictionaryOrgProfilesSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_ORG_PROFILES_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryOrgProfilesFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_ORG_PROFILES_FAILED_ACTION,
});

export const loadDictionarySectors: ActionCreator = () => ({
  type: LOAD_DICTIONARY_SECTOR_ACTION,
});

export const loadDictionarySectorsSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_SECTOR_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionarySectorsFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_SECTOR_FAILED_ACTION,
});
export const loadDictionaryGcpProducts: ActionCreator = () => ({
  type: LOAD_DICTIONARY_GCP_PRODUCTS_ACTION,
});

export const loadDictionaryGcpProductsSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_GCP_PRODUCTS_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryGcpProductsFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_GCP_PRODUCTS_FAILED_ACTION,
});
export const loadDictionaryObtainingMethods: ActionCreator = () => ({
  type: LOAD_DICTIONARY_OBTAINING_METHOD_ACTION,
});

export const loadDictionaryObtainingMethodsSuccessed: ActionCreator = (
  data,
) => ({
  type: LOAD_DICTIONARY_OBTAINING_METHOD_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryObtainingMethodsFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_OBTAINING_METHOD_FAILED_ACTION,
});
export const loadDictionaryEdoProviders: ActionCreator = () => ({
  type: LOAD_DICTIONARY_EDO_PROVIDER_ACTION,
});

export const loadDictionaryEdoProvidersSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_EDO_PROVIDER_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryEdoProvidersFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_EDO_PROVIDER_FAILED_ACTION,
});

export const loadDictionaryOkpd: ActionCreator = () => ({
  type: LOAD_DICTIONARY_OKPD_ACTION,
});

export const loadDictionaryOkpdSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_OKPD_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryOkpdFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_OKPD_FAILED_ACTION,
});

export const loadDictionaryShowboxDisplaySign: ActionCreator = () => ({
  type: LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_ACTION,
});

export const loadDictionaryShowboxDisplaySignSuccessed: ActionCreator = (
  data,
) => ({
  type: LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryShowboxDisplaySignFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_FAILED_ACTION,
});

export const loadDictionaryPackageType: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PACKAGE_TYPE_ACTION,
});

export const loadDictionaryPackageTypeSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_PACKAGE_TYPE_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryPackageTypeFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PACKAGE_TYPE_FAILED_ACTION,
});

export const loadDictionaryPackageMaterial: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PACKAGE_MATERIAL_ACTION,
});

export const loadDictionaryPackageMaterialSuccessed: ActionCreator = (
  data,
) => ({
  type: LOAD_DICTIONARY_PACKAGE_MATERIAL_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryPackageMaterialFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PACKAGE_MATERIAL_FAILED_ACTION,
});

export const loadDictionaryPackageLetterNumeralCodeSuccessed: ActionCreator = (
  data,
) => ({
  type: LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryPackageLetterNumeralCodeFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_FAILED_ACTION,
});

export const loadDictionaryPackageTypesCapping: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_ACTION,
});

export const loadDictionaryPackageTypesCappingSuccessed: ActionCreator = (
  data,
) => ({
  type: LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryPackageTypesCappingFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_FAILED_ACTION,
});

export const loadDictionaryPackageMaterialsCapping: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_ACTION,
});

export const loadDictionaryPackageMaterialsCappingSuccessed: ActionCreator = (
  data,
) => ({
  type: LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryPackageMaterialsCappingFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_FAILED_ACTION,
});

export const loadDictionaryPalletType: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PALLET_TYPE_ACTION,
});

export const loadDictionaryPalletTypeSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_PALLET_TYPE_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryPalletTypeFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PALLET_TYPE_FAILED_ACTION,
});

export const loadDictionaryOkved: ActionCreator = (
  searchText,
  cbk = () => null,
) => ({
  type: LOAD_DICTIONARY_OKVED_ACTION,
  searchText: searchText,
  cbk: cbk,
});

export const loadDictionaryOkvedSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_OKVED_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryOkvedFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_OKVED_FAILED_ACTION,
});

export const loadNotification: ActionCreator = (
  startDate,
  endDate,
  pageNumber,
  pageSize,
  firstLoad,
  cbk = () => null,
) => ({
  type: LOAD_NOTIFICATION_ACTION,
  dateBegin: endDate && encodeURIComponent(endDate),
  dateEnd: startDate && encodeURIComponent(startDate),
  pageNumber: pageNumber,
  pageSize: pageSize,
  firstLoad: firstLoad,
  cbk: cbk,
});

export const loadNotificationSuccessed: ActionCreator = (data, firstLoad, totalCount) => ({
  type: LOAD_NOTIFICATION_SUCCEEDED_ACTION,
  data: data,
  firstLoad: firstLoad,
  totalCount: totalCount,
});

export const loadNotificationFailed: ActionCreator = () => ({
  type: LOAD_NOTIFICATION_FAILED_ACTION,
});

export const loadNotificationRecent: ActionCreator = () => ({
  type: LOAD_NOTIFICATION_RECENT_ACTION,
});

export const loadNotificationRecentSuccessed: ActionCreator = (data) => ({
  type: LOAD_NOTIFICATION_RECENT_SUCCEEDED_ACTION,
  data: data,
});

export const loadNotificationRecentFailed: ActionCreator = () => ({
  type: LOAD_NOTIFICATION_RECENT_FAILED_ACTION,
});

export const cleanNotification: ActionCreator = () => ({
  type: CLEAN_NOTIFICATION_ACTION,
});

export const markAsReadNotification: ActionCreator = (ids, cbk = () => null) => ({
  type: MARK_NOTIFICATION_ACTION,
  ids: ids,
  cbk: cbk,
});

export const markAsReadNotificationSuccessed: ActionCreator = (ids) => ({
  type: MARK_NOTIFICATION_SUCCESSED_ACTION,
  ids: ids,
});

export const loadProductsCategories: ActionCreator = (categoryId) => ({
  type: LOAD_PRODUCTS_CATEGORIES_ACTION,
  categoryId: categoryId,
});

export const loadProductsCategoriesSuccessed: ActionCreator = (data) => ({
  type: LOAD_PRODUCTS_CATEGORIES_SUCCEEDED_ACTION,
  data: data,
});

export const loadProductsCategoriesFailed: ActionCreator = () => ({
  type: LOAD_PRODUCTS_CATEGORIES_FAILED_ACTION,
});

export const loadDictionaryPackageLetterNumeralCode: ActionCreator = () => ({
  type: LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_ACTION,
});

export const loadActiveKzDictionary: ActionCreator = (modalType) => ({
  type: LOAD_ACTIVE_KZ_DICTIONARY_ACTION,
  modalType: modalType,
});

export const loadActiveKzDictionarySuccessed: ActionCreator = (data) => ({
  type: LOAD_ACTIVE_KZ_DICTIONARY_SUCCEEDED_ACTION,
  data: data,
});

export const loadActiveKzDictionaryFailed: ActionCreator = () => ({
  type: LOAD_ACTIVE_KZ_DICTIONARY_FAILED_ACTION,
});

export const clearActiveKzDictionary: ActionCreator = () => ({
  type: CLEAR_ACTIVE_KZ_DICTIONARY_ACTION,
});

export const loadKatoDictionary: ActionCreator = (data) => ({
  type: LOAD_KATO_DICTIONARY_ACTION,
  data,
});

export const loadKatoDictionarySucceeded: ActionCreator = (data) => ({
  type: LOAD_KATO_DICTIONARY_ACTION_SUCCEEDED,
  data,
});

export const loadKatoDictionaryFailed: ActionCreator = () => ({
  type: LOAD_KATO_DICTIONARY_ACTION_FAILED,
});

export const loadDictionaryCountries: ActionCreator = (cbk = () => null) => ({
  type: LOAD_DICTIONARY_COUNTRIES_ACTION,
  cbk: cbk,
});

export const loadDictionaryCountriesSuccessed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_COUNTRIES_SUCCEEDED_ACTION,
  data: data,
});

export const loadDictionaryCountriesFailed: ActionCreator = () => ({
  type: LOAD_DICTIONARY_COUNTRIES_FAILED_ACTION,
});
