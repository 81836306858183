import styled from 'styled-components';
import colors from '../../../common/styles/Common.colors';

export const PointerName = styled.span`
  cursor: pointer;
  color: ${colors.Buttons.textButton.color};
  white-space: nowrap;
  width: -webkit-fill-available;
  transition: 0.4s ease;
  &:hover {
    color: ${colors.Buttons.textButton.colorHovered};
  }
  &:active {
    color: ${colors.Buttons.textButton.colorHovered};
  }
`;
