import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEqual, isNull } from 'lodash';
import {
  Button,
  MuiThemeProvider,
  Popover,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import {
  IconClose,
  IconNewFilterSmall,
  IconNewFilterSmallActive,
} from '../../../../../common/icons/customIcons';
import {
  defaultFilterInput,
  DefaultFilterPopover,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
} from './Filters.styled';

class OrderMarkedCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      // canOrderMarkedCode: this.props.currentValue ? this.props.currentValue.join('\n') : '',
      canOrderMarkedCode: this.props.currentValue || null,
      error: '',
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        canOrderMarkedCode: '',
        error: '',
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state);
  }

  onValueChange = (e) => {
    this.setState({
      canOrderMarkedCode: e.target.checked,
      error: '',
    });
  };

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAccept = () => {
    const canOrderMarkedCodes = this.state.canOrderMarkedCode;
    this.setState({
      anchorEl: null,
      currentValue: canOrderMarkedCodes,
    });
    this.props.onAccept(
      'canOrderMarkedCode',
      canOrderMarkedCodes === false ? '' : canOrderMarkedCodes,
    );
  };

  validatecanOrderMarkedCodes = () => {
    let valid = true;
    return valid;
  };

  handleDissmiss = () => {
    this.setState({ anchorEl: null, canOrderMarkedCode: '', error: '' });
    this.props.onDismiss('canOrderMarkedCode');
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <MuiThemeProvider theme={FilterBtn}>
          <FilterButton>
            <Button
              aria-owns={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
              {isNull(this.props.currentValue) ||
              this.props.currentValue === '' ? (
                <IconNewFilterSmall />
              ) : (
                <IconNewFilterSmallActive />
              )}
            </Button>
          </FilterButton>
        </MuiThemeProvider>
        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Право заказа КМ')}</h6>

              <MuiThemeProvider theme={FilterBtn}>
                <Button className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </Button>
              </MuiThemeProvider>
            </FilterTitle>
            <FilterContent>
              <MuiThemeProvider theme={defaultFilterInput}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.canOrderMarkedCode}
                      onChange={this.onValueChange}
                      name="canOrderMarkedCode"
                      color="primary"
                    />
                  }
                  label={t('Да')}
                />
              </MuiThemeProvider>
            </FilterContent>
            <FilterBtns>
              <Button onClick={this.handleAccept}>{t('Применить')}</Button>
              <Button className="backEmpty" onClick={this.handleDissmiss}>
                {t('Сбросить')}
              </Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </div>
    );
  }
}

OrderMarkedCode.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  currentValue: PropTypes.bool,
  parentId: PropTypes.string.isRequired,
};

export default withTranslation()(OrderMarkedCode);
