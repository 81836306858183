import * as React from "react";
import * as PropTypes from 'prop-types';
import * as selectors from './ducks/Companies.selectors';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from "react-i18next";
import { withCookies } from 'react-cookie';
import memoize from 'memoize-state';
import { connect } from "react-redux";
import download from 'downloadjs';
import {
    GridActions,
    GridActionsLeft,
    GridActionsRight,
    // DefaultGridButton,
    GridTableContainer,
    GridEmpty,
    GridEmptyText,
    ColumnCellInner,
    ColumnHeaderProfile,
    ColumnCellCheckHeader,
    // SortingBtn,
    ColumnCellCheckInner,
    CheckBoxGridThead,
} from '../../common/styles/Common.styled';

import _ from 'lodash';

import {
    CompaniesGridContainer,
    GridActionsView,
    GridTable,
    ColumnCellProfile,
    CompaniesContainer,
    ResetFilterButton,
} from './Companies.styled';

import { Pagination, Preloader } from '../../components';

import { Button, Checkbox, MuiThemeProvider } from '@material-ui/core';
import {
    IconExport,
    IconCheck,
    IconCheckbox,
} from '../../common/icons/customIcons';
import TableGrid from "./views/TableGrid";
import GridPageSize from "./views/GridPageSize/GridPageSize";
import NameFilter from "./filters/NameFilter";
import INNTypeFilter from "./filters/INNTypeFilter";
import LegalAddress from "./filters/LegalAddress";
import GoodsFilter from "./filters/GoodsFilter";
import MarkGroupsFilter from "./filters/MarkGropsFilter";
import CategoriesFilter from "./filters/CategoriesFilter";
import FiltersCompaniesPopup from "./filters/FiltersCompaniesPopup";
import {
    loadCompaniesData,
    exportCompanies,
} from './ducks/Companies.actions';
import CheckboxCell from "./cell/CheckboxCell";
import ProfileEllipsisCell from '../profile/views/subProfiles/cell/ProfileEllipsisCell';
import {
    // sortColumns,
    returnSimplifiedSetFields,
    // getColumns,
} from "./ducks/Companies.utils";
import {
    getSortField,
} from "../../common/utils/utils";

const FiltersInit = {
    name: null,
    itn: null,
    address: null,
    hasGoods: null,
    hasMarkGroups: null,
    hasCategories: null,
    ids: null,
};

// Пока используется сортировка по умолчанию по полю "name", на бэке реализована на все поля на будущее.
const SortInit = [
    { field: 'name', direction: '' },
    { field: 'itn', direction: '' },
    { field: 'address', direction: '' },
    { field: 'goodsCount', direction: '' },
    { field: 'markGroupsCount', direction: '' },
    { field: 'categoriesCount', direction: '' },
];

const isNotActiveFilters = (filters) => _.isEqual(FiltersInit, filters);

const sizeConcat = _.flow([_.compact, _.size]);

const countActiveFilters = (filters) =>
    sizeConcat(
        _.map(filters, (i) => (_.isObjectLike(i) ? sizeConcat(_.valuesIn(i)) : i)),
    );

const fieldsArray = [
    "id",
    "name",
    "itn",
    "address",
    "goodsCount",
    "markGroupsCount",
    "categoriesCount",
];

const openxmlFormats =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';


class Companies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: localStorage.savedPageSizeCompanies
                ? _.toNumber(localStorage.getItem('savedPageSizeCompanies'))
                : 10,
            pageNum: _.get(this.props, 'filters.pageNum', 0),
            filters: localStorage.savedFiltersCompanies
                ? _.assign({}, JSON.parse(localStorage.getItem('savedFiltersCompanies')))
                : FiltersInit,
            sort: _.has(this.props.filters, 'sort')
                ? _.uniqBy(
                    _.compact(_.concat([], this.props.filters.sort, SortInit)),
                    'field',
                )
                : SortInit,
            fields: fieldsArray,
            columns: [],
            defaultColumns: [],
            checkboxColumn: {},
            selected: [],
        };

        const paramsList = {
            ..._.pick(this.state, ['pageNum', 'pageSize', 'sort', 'filters', 'fields']),
        };

        this.props.onLoadCompaniesData(paramsList);
        this.mIsNotActiveFilters = _.memoize(isNotActiveFilters);
        this.mCountActiveFilters = _.memoize(countActiveFilters);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.locale, this.props.locale)) {
            this.updateColumns();
        }
    }

    componentDidMount() {
        this.updateColumns();
    }

    getBuildParamsForList = () => {
        return {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            sort: getSortField(this.state.sort),
            filters: this.state.filters,
            fields: this.state.fields,
        };
    };

    changeGridParams = () => {
        const params = this.getBuildParamsForList();
        setTimeout(() => this.setState({ loading: false }), 200);
        this.props.onLoadCompaniesData(params);
    };

    onFilterAccept = (name, value) => {
        // сетаем фильтры в localStorage
        let savedFilters;
        savedFilters = localStorage.getItem('savedFiltersCompanies');
        // если в localStorage есть ранее сохраненные фильтры,, то достаём строку и делаем объект
        if (savedFilters) {
            savedFilters = JSON.parse(savedFilters);
        }
        // если в сторадже ничего нет, то копируем изначальные фильтры.
        if (!savedFilters) {
            savedFilters = _.cloneDeep(FiltersInit);
        }
        savedFilters[name] = value;
        localStorage.setItem('savedFiltersCompanies', JSON.stringify(savedFilters));

        if (name && toString(value)) {
            this.setState(
                {
                    pageNum: 0,
                    filters: _.assign({}, this.state.filters, { [name]: value }),
                },
                this.changeGridParams,
            );
        }
    };

    onFilterDissmiss = (name) => {
        // удалить один из фильтров в localStorage
        let savedFilters = localStorage.getItem('savedFiltersCompanies');
        savedFilters = JSON.parse(savedFilters);
        let filters = _.cloneDeep(this.state.filters);
        filters[name] = null;
        savedFilters[name] = null;
        localStorage.setItem('savedFiltersCompanies', JSON.stringify(savedFilters));
        this.setState(
            (prevState) => ({
                ...prevState,
                filters: filters,
            }),
            this.changeGridParams,
        );

    };

    resetFilters = () => {
        // убить все фильтры в localStorage
        localStorage.removeItem('savedFiltersCompanies');
        this.setState(
            {
                filters: FiltersInit,
            },
            this.changeGridParams,
        );
    };

    onFiltersSubmit = (filters) => {
        let savedFilters;
        savedFilters = localStorage.getItem('savedFiltersCompanies');
        if (savedFilters) {
            savedFilters = JSON.parse(savedFilters);
        }
        if (!savedFilters) {
            savedFilters = _.cloneDeep(FiltersInit);
        }
        savedFilters = { ...savedFilters, ...filters };
        localStorage.setItem('savedFiltersCompanies', JSON.stringify(savedFilters));

        this.setState(
            {
                pageNum: 0,
                filters: { ...this.state.filters, ...filters },
            },
            this.changeGridParams,
        );
    };

    getNoDataProps = () => ({ resetFilters: this.resetFilters });

    toggleRow = (id) => {
        const item = { id };
        let selectItem;
        if (_.find(this.state.selected, item)) {
            selectItem = _.reject(this.state.selected, item);
        } else {
            selectItem = _.concat(this.state.selected, item);
        }
        this.setState({ selected: selectItem });
    };

    toggleSelectAll = () => {
        let selected = [];
        if (
            !_.isEmpty(this.props.data) &&
            this.props.data.length !== this.state.selected.length
        ) {
            this.props.data.forEach((elem) => {
                selected.push(elem);
            })
        }
        this.setState({ selected });
    };

    exportCompanies = () => {
        const { onExportCompanies, t } = this.props;
        const params = this.getBuildParamsForList();
        const ids = [];

        if (this.state.selected) {
            _.forEach(this.state.selected, (object) => {
                ids.push(object.id);
            });

            params.ids = ids;
        }

        onExportCompanies(params, (errors, data) => {
            if (!errors) {
                this.setState({ loading: false });
                const blob = new Blob([data], { type: openxmlFormats });
                return download(blob, `${t('Oтчет')}.xlsx`);
            }
        });
    };

    render() {
        const { t } = this.props;
        const IsNotActiveFilters = this.mIsNotActiveFilters(this.state.filters);
        const emptyTable = !this.props.totalObjects && IsNotActiveFilters;
        const disableActionsEmptyGrid = emptyTable || this.props.loading;
        // const CountActiveFilters = this.mCountActiveFilters(this.state.filters);

        return (
            <React.Fragment>
                <CompaniesContainer>
                    <CompaniesGridContainer>
                        <GridActions>
                            <GridActionsLeft />
                            <GridActionsRight id="companiesFilters">
                                <GridActionsView>
                                    <FiltersCompaniesPopup
                                        parentId="companiesFilters"
                                        currentFiltersValue={this.state.filters}
                                        resetFilters={this.resetFilters}
                                        onFiltersSubmit={this.onFiltersSubmit}
                                        isNotActiveFilters={IsNotActiveFilters}
                                    />
                                </GridActionsView>
                                <MuiThemeProvider theme={ResetFilterButton}>
                                    <GridActionsView>
                                        <Button onClick={this.exportCompanies}>
                                            <IconExport style={{ marginRight: '8px' }} />
                                            {t('Экспорт')}
                                        </Button>
                                    </GridActionsView>
                                </MuiThemeProvider>
                            </GridActionsRight>
                        </GridActions>
                        <GridTableContainer /*noData={!emptyTable && !this.props.data.length}*/ height="53vh" minHeight="10vh">

                            <GridTable className="table-grid--list">
                                {emptyTable ? (
                                    <GridEmpty>
                                        <GridEmptyText>
                                            <p>{t('Нет данных для отображения')}.</p>
                                        </GridEmptyText>
                                    </GridEmpty>
                                ) : (
                                    <div style={{ display: this.props.loading ? 'none' : 'block', height: '100%' }}>
                                        <TableGrid
                                            key={disableActionsEmptyGrid}
                                            data={this.props.data}
                                            columns={_.concat(
                                                this.state.checkboxColumn,
                                                this.state.columns,
                                            )}
                                            defaultPageSize={this.state.pageSize}
                                            className="-highlight"
                                            page={this.state.pageNum}
                                            pages={Math.ceil(this.props.totalObjects / this.state.pageSize,)}
                                            pageSize={this.state.pageSize}
                                            PaginationComponent={Pagination}
                                            getPaginationProps={() => ({
                                                totalObjects: this.props.totalObjects,
                                                gridPageSize: (
                                                    <GridPageSize
                                                        disabled={disableActionsEmptyGrid}
                                                        activeValue={this.state.pageSize}
                                                        onChangePageSize={(pageSize) => {
                                                            localStorage.setItem('savedPageSizeCompanies', pageSize);
                                                            this.setState(
                                                                { pageNum: 0, loading: true },
                                                                () => setTimeout(() => this.setState({ pageSize }, this.changeGridParams), 1),
                                                            );
                                                        }}
                                                    />
                                                ),
                                            })}
                                            onPageChange={(page) => {
                                                this.setState({ pageNum: page }, this.changeGridParams);
                                            }}
                                            resizable={false}
                                            getNoDataProps={this.getNoDataProps}
                                            minRows={0}
                                            manual
                                            selected={this.state.selected}
                                            filters={this.state.filters}
                                        />
                                    </div>
                                )}
                            </GridTable>
                        </GridTableContainer>
                    </CompaniesGridContainer>
                    <Preloader isOpen={this.props.loading} text={t('Загрузка')} />
                </CompaniesContainer>
            </React.Fragment>
        )
    }

    updateColumns() {
        let { t, locale } = this.props;
        let checkBoxColumn = {
            id: 'checkbox',
            accessor: '',
            fixed: 'left',
            sortable: false,
            width: 52,
            Header: (x) => {
                return (
                    <ColumnCellCheckHeader>
                        <ColumnCellCheckInner>
                            <MuiThemeProvider theme={CheckBoxGridThead}>
                                <Checkbox
                                    checked={
                                        !_.isEmpty(this.props.data) &&
                                        this.props.data.length === this.state.selected.length
                                    }
                                    onChange={this.toggleSelectAll}
                                    checkedIcon={<IconCheck />}
                                    icon={<IconCheckbox />}
                                    color="default"
                                />
                            </MuiThemeProvider>
                        </ColumnCellCheckInner>
                    </ColumnCellCheckHeader>
                );
            },
            Cell: ({ original }) => {
                return (
                    <CheckboxCell
                        toggleRow={this.toggleRow}
                        original={original}
                        selected={this.state.selected}
                    />
                );
            },
        };

        let defaultColumns = [
            {
                name: t("Наименование участника системы"),
                show: true,
                position: 0,
                accessor: "name",
                sortable: false,
                minWidth: 452,
                Header: (x) => (
                    <ColumnHeaderProfile id="nameGridFilter">
                        {t("Наименование участника системы")}
                        <NameFilter
                            parentId="nameGridFilter"
                            currentValue={this.state.filters.name}
                            onAccept={this.onFilterAccept}
                            onDismiss={this.onFilterDissmiss}
                        />
                    </ColumnHeaderProfile>
                ),
                Cell: ({ original }) => (
                    <ProfileEllipsisCell
                        value={original.name}
                        cellId={`company-name-${original.id}`}
                    />
                )
            },
            {
                name: t("Налоговый идентификатор"),
                show: true,
                position: 1,
                accessor: "itn",
                sortable: false,
                width: 260,
                Header: (x) => (
                    <ColumnHeaderProfile id="ItnFilter">
                        {t("Налоговый идентификатор")}
                        <INNTypeFilter
                            parentId="ItnFilter"
                            onAccept={this.onFilterAccept}
                            onDismiss={this.onFilterDissmiss}
                            currentValue={this.state.filters.itn}
                        />
                    </ColumnHeaderProfile>
                ),
                Cell: ({ original }) => (
                    <ColumnCellProfile>
                        <ColumnCellInner>{original.itn}</ColumnCellInner>
                    </ColumnCellProfile>
                )
            },
            {
                name: "Юридический адрес",
                show: true,
                position: 2,
                accessor: "address",
                sortable: false,
                minWidth: 420,
                maxWidth: 470,
                Header: (x) => (
                    <ColumnHeaderProfile id="legalAddress">
                        {t("Юридический адрес")}
                        <LegalAddress
                            parentId="legalAddress"
                            onAccept={this.onFilterAccept}
                            onDismiss={this.onFilterDissmiss}
                            currentValue={this.state.filters.address}
                        />
                    </ColumnHeaderProfile>
                ),
                Cell: ({ original }) => (
                    <ProfileEllipsisCell
                        value={original.address}
                        cellId={`company-address-${original.id}`}
                    />
                )
            },
            {
                name: t("Товаров"),
                show: true,
                position: 3,
                accessor: "goodsCount",
                sortable: false,
                width: 140,
                Header: (x) => (
                    <ColumnHeaderProfile id="goodsCount">
                        {t("Товаров")}
                        <GoodsFilter
                            parentId="goodsCount"
                            onAccept={this.onFilterAccept}
                            onDismiss={this.onFilterDissmiss}
                            currentValue={this.state.filters.hasGoods}
                        />
                    </ColumnHeaderProfile>
                ),
                Cell: ({ original }) => (
                    <ColumnCellProfile>
                        <ColumnCellInner>{original.goodsCount}</ColumnCellInner>
                    </ColumnCellProfile>
                )
            },
            {
                name: t("Маркируемых групп"),
                show: true,
                position: 4,
                accessor: "markGroupsCount",
                sortable: false,
                width: 209,
                Header: (x) => (
                    <ColumnHeaderProfile id="markGroups">
                        {t("Маркируемых групп")}
                        <MarkGroupsFilter
                            parentId="markGroups"
                            onAccept={this.onFilterAccept}
                            onDismiss={this.onFilterDissmiss}
                            currentValue={this.state.filters.hasMarkGroups}
                        />
                    </ColumnHeaderProfile>
                ),
                Cell: ({ original }) => (
                    <ColumnCellProfile>
                        <ColumnCellInner>{original.markGroupsCount}</ColumnCellInner>
                    </ColumnCellProfile>
                )
            },
            {
                name: t("Товарных категорий"),
                show: true,
                position: 5,
                accessor: "categoriesCount",
                sortable: false,
                width: 209,
                Header: (x) => (
                    <ColumnHeaderProfile id="categoriesCount">
                        {t("Товарных категорий")}
                        < CategoriesFilter
                            parentId="categoriesCount"
                            onAccept={this.onFilterAccept}
                            onDismiss={this.onFilterDissmiss}
                            currentValue={this.state.filters.hasCategories}
                        />
                    </ColumnHeaderProfile>
                ),
                Cell: ({ original }) => (
                    <ColumnCellProfile>
                        <ColumnCellInner>{original.categoriesCount}</ColumnCellInner>
                    </ColumnCellProfile>
                )
            },
        ]

        let columns = _.cloneDeep(defaultColumns);
        defaultColumns = returnSimplifiedSetFields(columns);

        this.setState(
            {
                checkboxColumn: checkBoxColumn,
                columns: columns,
                defaultColumns: defaultColumns,

            }
        );
    };
}

Companies.propTypes = {
    onLoadCompaniesData: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    totalObjects: PropTypes.number.isRequired,
    filters: PropTypes.object,
    user: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    onExportCompanies: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    loading: selectors.loading(state),
    data: selectors.data(state),
    totalObjects: selectors.totalObjects(state),
    filters: selectors.filters(state),
    user: selectors.user(state),
    locale: selectors.locale(state),
    userInfo: state.profileReducer.info.user,
    location: state.router.location,
    usercompany: state.profileStatusReducer.data,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onLoadCompaniesData: (p) => loadCompaniesData(p.pageNum, p.pageSize, p.sort, p.filters, p.fields),
        onExportCompanies: (params, cbk) => exportCompanies(params, cbk),
    }, dispatch);
};

export default compose(
    withCookies,
    withTranslation(),
    connect(memoize(mapStateToProps), mapDispatchToProps),
)(Companies)