import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { Calendar } from 'react-date-range';
import { withTranslation } from 'react-i18next';
import ru from 'date-fns/locale/ru';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {
  Button,
  FormControl,
  MuiThemeProvider,
  Popover,
  TextField,
  IconButton,
} from '@material-ui/core';
import {
  IconClose,
  IconMenuClose,
  IconCalendar,
} from '../../common/icons/customIcons';
import {
  DatePickerBox,
  DefaultPickerPopover,
  FilterBtnReset,
  PickerBtn,
  PickerBtns,
  PickerContent,
  PickerDate,
  PickerIcon,
  PickerTitle,
} from './styled';
import { config, selectLang } from '../../config';
import colors from '../../common/styles/Common.colors';

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.selectLang = selectLang(config.lang);
    const tz = moment.tz.guess();
    moment.locale(this.selectLang);
    moment.tz.setDefault(tz);

    let currentValue;
    if (props.currentValue) {
      currentValue = moment(props.currentValue, 'DD.MM.YYYY');
    }

    this.state = {
      anchorEl: null,
      selected: !!currentValue,
      currentValue: currentValue || null,
      textValue: currentValue ? moment(currentValue).format('DD.MM.YYYY') : '',
    };
    this.lang = { ru: ru, kk: ru };
  }

  handleSelect = (date) => {
    this.setState({ selected: true, currentValue: date });
    // const { onBlur } = this.props;
    // if (onBlur) {
    //     onBlur(date);
    // }
  };

  handleClick = ({ currentTarget }) => {
    this.setState({ anchorEl: currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAccept = () => {
    const value = moment(this.state.currentValue).format('DD.MM.YYYY');
    this.props.onAccept(value, this.props.name);
    this.setState({ textValue: value, anchorEl: null });
  };

  handleDissmiss = () => {
    const value = '';
    this.setState({
      anchorEl: null,
      selected: false,
      currentValue: new Date(),
      textValue: value,
    });
    this.props.onDismiss(value, this.props.name);
  };

  handleClear = () => {
    const { setError, onChange, searchIds } = this.props;

    if (setError) {
      setError(searchIds, null)
    }
    this.handleSelect(null);
    this.setState({ textValue: '', currentValue: null });
    this.props.onAccept(null, this.props.name);
    if (onChange) {
      onChange('')
    }
  };

  renderCleanButton = () => {
    const { disabled, required } = this.props;

    if (!required && !disabled) {
      return (
        <Button
          style={{
            position: 'absolute',
            right: 65,
            width: 30,
            minWidth: 30,
            maxHeight: 30,
            backgroundColor: 'transparent',
            color: 'rgba(0, 0, 0, 0.87)',
            padding: '6px 16px',
            paddingBottom: '8px',
            fontSize: '0.875rem',
            transition:
              'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: 4,
            letterSpacing: '0.02857em',
            textTransform: 'uppercase',
            border: 0,
            cursor: 'pointer',
            margin: 0,
            display: 'inline-flex',
            outline: 0,
            alignItems: 'center',
            userSelect: 'none',
            verticalAlign: 'middle',
            justifyContent: 'center',
            textDecoration: 'none',
            boxShadow: 'none',
            top: '50%',
            marginTop: -7,
          }}
          onClick={this.handleClear}>
          <div style={{ width: 20, height: 20, paddingBottom: 5 }}>
            <IconMenuClose style={{ width: 12, height: 12 }} />
          </div>
        </Button>
      );
    }

    return null;
  };

  render() {
    const {
      required,
      disabled,
      name,
      valid,
      error,
      t,
      onBlur,
      onChange,
    } = this.props;
    const { anchorEl, textValue, currentValue } = this.state;
    const open = Boolean(anchorEl);
    const label = typeof error === 'string' && !_.startsWith(this.props.id, 'input-date-')
      ? error
      : '';

    return (
      <>
        <MuiThemeProvider theme={PickerDate}>
          <FormControl fullWidth={true}>
            <TextField
              style={{ marginTop: 0 }}
              id={this.props.id}
              disabled
              required={required}
              name={`field_${this.props.id}`}
              type="text"
              value={textValue || ''}
              label={name}
              error={error}
              inputProps={{ valid: _.toString(valid) }}
              InputProps={{ disableUnderline: true }}
              onChange={onChange}
              onClick={disabled ? void 8 : this.handleClick}
              className={cx({ 'disabled-textfield': disabled })}
            />
            {/* {this.renderCleanButton()} */}
            <PickerIcon onClick={disabled ? void 8 : this.handleClick}>
              <IconCalendar color={disabled ? '#AAA9A9' : colors.Common.textColor} />
            </PickerIcon>
          </FormControl>

          <MuiThemeProvider theme={DefaultPickerPopover}>
            <Popover
              id="simple-popper"
              open={open}
              anchorEl={anchorEl}
              onClose={this.handleClose}
              onExit={onBlur}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
              <PickerTitle>
                <h6>{name}</h6>
                <MuiThemeProvider theme={PickerBtn}>
                  <IconButton className="backEmpty" onClick={this.handleClose}>
                    <IconClose />
                  </IconButton>
                </MuiThemeProvider>
              </PickerTitle>

              <PickerContent>
                <DatePickerBox>
                  <Calendar
                    date={currentValue ? new Date(currentValue) : null}
                    showMonthAndYearPickers={true}
                    dragSelectionEnabled={false}
                    showMonthArrow={false}
                    showSelectionPreview={false}
                    onChange={this.handleSelect}
                    locale={this.lang[this.selectLang]}
                    weekdayDisplayFormat='EEEEEE'
                  />
                </DatePickerBox>
              </PickerContent>

              <PickerBtns>
                <MuiThemeProvider theme={FilterBtnReset}>
                  <Button
                    disabled={!this.state.selected}
                    onClick={this.handleDissmiss}
                  >
                    {t('Сбросить')}
                  </Button>
                </MuiThemeProvider>
                <Button
                  disabled={!this.state.selected}
                  onClick={this.handleAccept}>
                  {t('Применить')}
                </Button>
              </PickerBtns>
            </Popover>
          </MuiThemeProvider>
        </MuiThemeProvider>

        {label && (
          <p style={{ color: 'red', marginTop: 0, paddingLeft: 2 }}>{label}</p>
        )}
      </>
    );
  }
}

DatePicker.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default withTranslation()(DatePicker);
