import styled from 'styled-components';
import { ProfileTabCont } from '../../../Profile.styled';
import colors from '../../../../../common/styles/Common.colors';

export const DocAndOptionsCont = styled(ProfileTabCont)`
  height: calc(100% - 167px);
  min-height: fit-content;
  padding: 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
`;

export const ColumnItem = styled.div`
  flex-direction: column;
  display: flex;
  padding: 39px 36px 10px;
  min-width: 500px;
  max-width: 500px;
`;
export const ColumnItemTitle = styled.div`
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  color: ${colors.Common.textColor};
  padding-bottom: 24px;
`;
export const ColumnItemLeft = styled.div`
  flex-direction: column;
  display: flex;
  padding: 40px 40px 0 40px;
  min-width: 500px;
`;

export const RowItem = styled.div`
  display: flex;
  //justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 0;

  color: ${({ color }) => color ? color : '#677380'};
`;

export const RowItemCheckbox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

export const DateItem = styled.div`
  color: #677380;
`;

export const ValueItem = styled.div`
  margin-left: 5px;
  color: ${colors.Common.textColor};  
`;

export const ValueItemStatus = styled(ValueItem)`
color: ${props => props.status === 0
    ? '#00B634'
    : '#E32A38'};
`;
