import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, Menu, MenuItem, MuiThemeProvider } from '@material-ui/core';
import { IconMenu } from '../../../../../common/icons/customIcons';
import { GridActionsSelect } from '../../../../products/views/ProductsGridActions/ProductsGridActionSelect.styled';
import { ButtonActionCont } from '../../../../../common/styles/Common.styled';
import { withTranslation } from 'react-i18next';

class GridActionsItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.state, nextState);
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      onDelete,
      onEdit,
      onLoadLetter,
      onOpenPreview,
      id,
      t,
      isElk = false,
      grantKmPermission,
      kmPermission = false,
      revokeKmPermission,
      original,
    } = this.props;
    const actions = [];

    if (onLoadLetter)
      actions.push({
        id: 1,
        label: t('Загрузить письмо-подтверждение'),
        onClick: onLoadLetter,
        disabled: false,
      });
    if (onDelete)
      actions.push({
        id: 2,
        label: t('Удалить'),
        onClick: onDelete,
        disabled: false,
      });
    if (onEdit)
      actions.push({
        id: 3,
        label: t('Редактировать'),
        onClick: onEdit,
        disabled: false,
      });
      if (onOpenPreview)
        actions.push({
          id: 4, 
          label: t('Просмотреть'),
          onClick: onOpenPreview,
          disabled: false 
        });

    /**
     * TODO:
     * нужен рефакторинг.
     * странная фигня. возможно переписать на if'ы
     */

    // actions.push({
    //   id: 4,
    //   label: t('Предоставить право заказа КМ'),
    //   onClick: grantKmPermission,
    //   disabled: false,
    // });
    // actions.push({
    //   id: 5,
    //   label: t('Отменить право заказа КМ'),
    //   onClick: revokeKmPermission,
    //   disabled: false,
    // });

    return (
      <div>
        <ButtonActionCont>
          <Button
            onClick={this.handleClick}
            aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true">
            <IconMenu />
          </Button>
        </ButtonActionCont>

        <MuiThemeProvider theme={GridActionsSelect}>
          <Menu
            open={!!this.state.anchorEl}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            id="simple-menu"
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
            {_.map(actions, (action) => (
              <MenuItem
                key={`m_${id}_${action.id}`}
                disableGutters={true}
                onClick={this.handleClose}
                disabled={action.disabled}>
                {/* <Button onClick={() => action.onClick(id, isElk, kmPermission)}> */}
                <Button onClick={() => action.onClick(original)}>
                  {t(action.label)}
                </Button>
              </MenuItem>
            ))}
          </Menu>
        </MuiThemeProvider>
      </div>
    );
  }
}

GridActionsItems.propTypes = {
  onDelete: PropTypes.func,
  id: PropTypes.number.isRequired,
};

export default withTranslation()(GridActionsItems);
