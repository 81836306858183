import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const GridActionsSelect = createMuiTheme({
  typography: {},
  overrides: {
    MuiSelect: {
      root: {
        maxWidth: '210px',
        '&:focus': {
          background: '#fff',
        },
      },
      select: {
        padding: '10px 12px',
        paddingRight: '30px !important',
        lineHeight: '24px',
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      selectMenu: {
        color: `${colors.Common.textColor}`,
      },
    },
    MuiInputBase: {
      root: {
        marginTop: '0!important',
        border: '1px solid #C9C7C7',
        borderRadius: '4px',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
        '&$disabled': {
          '& svg': {
            stroke: '#C9C7C7',
          }
        },
        '& svg': {
          stroke: `${colors.Common.textColor}`,
          right: '11px',
          top: '15px',
        }
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
      },
      formControl: {
        transform: 'translate(13px, 11px) scale(1)',
        color: `${colors.Common.textColor}`,
        '&$disabled': {
          color: '#C9C7C7',
        }
      },
      shrink: {
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
        transform: 'translate(13px, 11px) scale(1)',
        '&:focus': {
          color: `${colors.Common.textColor}`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        '&$focused': {
          color: `${colors.Common.textColor}`,
        },
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '0',
        paddingTop: '0',
        paddingBottom: '0',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
        '&:hover': {
          backgroundColor: '#F5F5F5 !important',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '8px',
        minWidth: '210px!important',
        marginTop: '0',
      },
      elevation8: {
        boxShadow: '0 8px 48px rgba(86, 118, 129, 0.1)',
      },
    },
    MuiButton: {
      root: {
        width: '100%',
        justifyContent: 'flex-start',
        borderRadius: '0',
        backgroundColor: '#FFF',
        color: `${colors.Common.textColor}`,
        '&:hover': {
          backgroundColor: '#F5F5F5',
        },
      },
      text: {
        padding: '16px 24px',
        textTransform: 'none',
      },
      label: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 'normal',
        letterSpacing: '0',
      },
    },
    MuiTooltip: {
      popper: {
        maxWidth: '221px',
        margin: '0',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '1!important',
        transform: 'none!important',
        backgroundColor: `${colors.Common.textColor}`,
        borderRadius: '8px',
        padding: '12px',
        position: 'relative!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 6px 8px',
          borderColor: `transparent transparent ${colors.Common.textColor} transparent`,
          borderStyle: 'solid',
          position: 'absolute',
          top: '-7px',
          right: 'calc(50% - 8px)',
        },
      },
      tooltipPlacementBottom: {
        margin: '8px 0 0 !important',
      },
    },
  },
});
