import { Reducer } from 'redux';
import {
  CLEAR_ANALYTIC_PIE_DATA_ACTION,
  LOAD_ANALYTIC_PIE_ACTION,
  LOAD_ANALYTIC_PIE_FAILED_ACTION,
  LOAD_ANALYTIC_PIE_SUCCEEDED_ACTION,
  LOAD_TRADEMARKS_DICTIONARY_ACTION,
  LOAD_TRADEMARKS_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_TRADEMARKS_DICTIONARY_ACTION_FAILED,
  LOAD_CATEGORY_DICTIONARY_ACTION,
  LOAD_CATEGORY_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_CATEGORY_DICTIONARY_ACTION_FAILED,
  LOAD_MAIN_STATISTIC_ACTION,
  LOAD_MAIN_STATISTIC_SUCCEEDED_ACTION,
  LOAD_MAIN_STATISTIC_FAILED_ACTION,
  LOAD_GS1_STATUS_ACTION,
  LOAD_GS1_STATUS_ACTION_SUCCEEDED,
  LOAD_GS1_STATUS_ACTION_FAILED,
  LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION,
  LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION_SUCCEEDED,
  LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION_FAILED,
  LOAD_PHOTO_CONTENT_STATISTICS_ACTION,
  LOAD_PHOTO_CONTENT_STATISTICS_ACTION_SUCCEEDED,
  LOAD_PHOTO_CONTENT_STATISTICS_ACTION_FAILED,
  LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION,
  LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION_SUCCEEDED,
  LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION_FAILED,
  LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION,
  LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION_SUCCEEDED,
  LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION_FAILED,
} from './Analytic.constants';
import { subtractNotNegative } from '../../../common/utils/utils';

const _initialState = {
  loading: 0,
  data: {},
  trademarksDictionary: [],
  categoriesDictionary: [],
  goodsMainStatistic: {},
  gs1Status: {},
  tradeMarkElapsedStatistics: [],
  photoContentStatistic: [],
  goodsDateExpirationStatistics: [],
  goodsExemplarStatistics: [],
};

export const analyticReducer: Reducer = (state = _initialState, action) => {
  switch (action.type) {
    case LOAD_CATEGORY_DICTIONARY_ACTION:
    case LOAD_TRADEMARKS_DICTIONARY_ACTION:
    case LOAD_MAIN_STATISTIC_ACTION:
    case LOAD_GS1_STATUS_ACTION:
    case LOAD_ANALYTIC_PIE_ACTION:
    case LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION:
    case LOAD_PHOTO_CONTENT_STATISTICS_ACTION:
    case LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION:
    case LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION: {
      return { ...state, loading: state.loading + 1 };
    }

    case LOAD_MAIN_STATISTIC_SUCCEEDED_ACTION: {
      return {
        ...state,
        goodsMainStatistic: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }

    case LOAD_MAIN_STATISTIC_FAILED_ACTION: {
      return { ...state, loading: subtractNotNegative(state.loading) };
    }

    case LOAD_ANALYTIC_PIE_SUCCEEDED_ACTION: {
      return {
        ...state,
        data: {
          // ...state.data,
          ...action.data,
        },
        loading: subtractNotNegative(state.loading),
      };
    }

    case LOAD_ANALYTIC_PIE_FAILED_ACTION: {
      return { ...state, loading: subtractNotNegative(state.loading) };
    }

    case CLEAR_ANALYTIC_PIE_DATA_ACTION: {
      return {
        ...state,
        data: {},
        loading: 0,
      };
    }

    case LOAD_CATEGORY_DICTIONARY_ACTION_FAILED:
    case LOAD_GS1_STATUS_ACTION_FAILED:
    case LOAD_TRADEMARKS_DICTIONARY_ACTION_FAILED: {
      return { ...state, loading: subtractNotNegative(state.loading) };
    }

    case LOAD_TRADEMARKS_DICTIONARY_ACTION_SUCCEEDED: {
      return {
        ...state,
        trademarksDictionary: [...action.data],
        loading: subtractNotNegative(state.loading),
      };
    }

    case LOAD_CATEGORY_DICTIONARY_ACTION_SUCCEEDED: {
      return {
        ...state,
        categoriesDictionary: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }

    case LOAD_GS1_STATUS_ACTION_SUCCEEDED: {
      return {
        ...state,
        gs1Status: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }

    case LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION_FAILED:
    case LOAD_PHOTO_CONTENT_STATISTICS_ACTION_FAILED:
    case LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION_FAILED:
    case LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION_FAILED: {
      return { ...state, loading: subtractNotNegative(state.loading) };
    }

    case LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION_SUCCEEDED: {
      return {
        ...state,
        tradeMarkElapsedStatistics: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }

    case LOAD_PHOTO_CONTENT_STATISTICS_ACTION_SUCCEEDED: {
      return {
        ...state,
        photoContentStatistic: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }

    case LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION_SUCCEEDED: {
      return {
        ...state,
        goodsDateExpirationStatistics: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }

    case LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION_SUCCEEDED: {
      return {
        ...state,
        goodsExemplarStatistics: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }
    default:
      return { ...state };
  }
};
