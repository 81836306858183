import * as PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  DialogActions,
  Typography,
  IconButton,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  // ImportDialogMin,
  // ImportDialogMinInner,
  ImportModalDialog,
} from './MassMediaModal.styled';
import { IconClose } from '../../../../common/icons/customIcons';
import {
  massMediaImport,
  cancelMassMediaImport,
  changeMassUploadListCount,
} from '../../ducks/Products.actions';
import { throwNotification } from '../../../../common/structure';
import LinearProgress from '@material-ui/core/LinearProgress';
import DragDropFieldImport from '../../../../components/dragDropField/dragDropFieldImport';
import colors from '../../../../common/styles/Common.colors';

class MassMediaModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fileList: null,
      checkingFiles: false,
      filesAreValid: false,
      arrayData: null,
      currentRelativePath: null,
      isSameUpload: false,
    };
  }

  componentWillUnmount() {
    this.props.changeMassUploadListCount(null);
    this.props.cancelMassMediaImport(true);
  }

  handeUpload = () => {
    const { arrayData, fileList, isSameUpload } = this.state;

    return this.props.massMediaImport(
      { arrayData, resumeId: 0 },
      fileList,
      isSameUpload,
      (error) => {
        if (_.isNil(error)) {
          this.props.handleClose();
          this.props.changeGridParams();
        }
      },
    );
  };

  checkFiles = (files) => {
    const { t } = this.props;

    if (!files) return;

    let isSameUpload = false;
    const lastRelativePath = localStorage.getItem('lastRelativePath');

    //const regexp = /^([0-9]+)(\.)(A1N1\.|A2N1\.|A7N1\.|A8N1\.|A3N1\.|A9N1\.|A1R1\.|L1\.|L2\.|L3\.)/;
    const regexp = /^([0-9]+)(\.)(A1N1\.|A2N1\.|A7N1\.|A8N1\.|A3N1\.|A9N1\.|A1R1\.|L1\.|L2\.|L3\.)(W\.)*/;

    let size = 0;
    let sizeIsValid = true;
    let extensionsAreValid = true;
    let fileNamesAreValid = true;
    let fileSizeIsValid = true;

    let arrayData = [];
    const currentRelativePath = files[0].webkitRelativePath.split('/')[0];
    if (lastRelativePath === currentRelativePath) {
      isSameUpload = true;
    }

    localStorage.setItem('lastRelativePath', currentRelativePath);

    _.forEach(files, (file) => {
      const fileInFolderHierarchy =
        file.webkitRelativePath.split('/').length === 3;
      const gtinName = file.name.split('.')[0];
      let filePath = gtinName;
      const fileType = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
      size += file.size;

      if (fileInFolderHierarchy) {
        filePath = file.webkitRelativePath.split('/').slice(1).join('/');
      }

      if (size / 1024 / 1024 > 100) {
        sizeIsValid = false;
        this.props.throwNotification(
          t('Импорт фотоконтента остановлен') +
          '. ' +
          t('Превышено ограничение в 100 Mb для одного импорта фотоконтента'),
          'error',
        );
        return false;
      }

      if (
        !_.includes(
          ['jpg', 'png'],
          fileType
          // file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2),
        )
      ) {
        extensionsAreValid = false;
        !fileType
          ? this.props.throwNotification(t('Для загрузки папки используйте кнопку Выберите папку'))
          : this.props.throwNotification(
            t(
              'В указанной папке для импорта фотоконтента присутствуют, файлы формат которых не предусмотрен системой',
            ),
            'error',
          );
        return false;
      }

      if (!regexp.test(file.name)) {
        fileNamesAreValid = false;
        this.props.throwNotification(
          t('Импорт фотоконтента остановлен') +
          '. ' +
          t(
            'В указанной папке или вложенных папках система обнаружила файлы не предусмотренные к импорту',
          ) +
          '. ' +
          t(
            'Просьба проверить названия файлов и их формат согласно инструкции пользователя',
          ),
          'error',
        );
        return false;
      }

      if (file.size / 1024 / 1024 > 15) {
        fileSizeIsValid = false;
        this.props.throwNotification(t('В указанной папке или вложенных папках система обнаружила файлы, превышающие предельный размер для одного файла в 15 мб'));
        return false;
      }

      arrayData.push({
        name: file.name,
        lastModified: moment(file.lastModified).format('YYYY-MM-DD HH:mm:ss'),
        size: file.size,
        nameWithPath: filePath,
      });
    });

    return this.setState({
      arrayData,
      checkingFiles: false,
      filesAreValid: sizeIsValid && extensionsAreValid && fileNamesAreValid && fileSizeIsValid,
      currentRelativePath,
      isSameUpload,
    });
  };

  handleDirectoryUpload = (event) => {
    const fileList = event.target.files;

    return this.setState(
      {
        checkingFiles: true,
        fileList,
      },
      () => this.checkFiles(fileList),
    );
  };

  handleChangeFile = (event) => {
    const files = event.dataTransfer.files;

    if (files) {
      return this.setState(
        {
          checkingFiles: true,
          fileList: files,
        },
        () => this.checkFiles(files),
      );
    }
  };

  deleteFile = (name) => {
    const newArrayData = _.cloneDeep(this.state.arrayData);
    const newFileList = [...this.state.fileList];
    const deletedFileIndex = _.findIndex(newArrayData, elem => elem.name === name);
    const deletedFileKey = _.findIndex(newFileList, elem => elem.name === name);

    if (!_.isNil(deletedFileIndex) && !_.isNil(deletedFileKey)) {
      newFileList.splice(deletedFileKey, 1);
      newArrayData.splice(deletedFileIndex, 1);

      return this.setState({
        fileList: newFileList,
        arrayData: newArrayData,
      });
    } else return;
  };

  render() {
    const { fileList, checkingFiles, filesAreValid } = this.state;
    const { handleClose, t, massUploadListCount, loading } = this.props;

    const step = 100 / this.state?.fileList?.length;
    const progress = massUploadListCount * (step ? step : 0);
    const isEmptyData = _.isNil(this.state.arrayData)
      || _.isEmpty(this.state.arrayData)
      || !filesAreValid;

    return (
      <MuiThemeProvider theme={ImportModalDialog}>
        <Dialog open>
          <DialogTitle id="alert-dialog-title" disableTypography>
            <Typography variant="h2">{t('Импорт фотоконтента')}</Typography>
            <IconButton
              id="dismiss-btn-close"
              onClick={handleClose}
              disabled={loading}>
              <IconClose />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <p>
              {t(
                'Системное ограничение для импорта составляет 100 Mb',
              ) + '.'}
            </p>
            {loading ? (
              <div
                style={{
                  padding: '22px 40px',
                  height: '125px',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <LinearProgress variant="determinate" value={progress} />
                <div
                  style={{
                    paddingTop: '40px',
                    alignSelf: 'center',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}>
                  {t(`загружено`) +
                    ` ${massUploadListCount ? massUploadListCount : 0} ` +
                    t(`из`) +
                    ` ${fileList?.length}`}
                </div>
              </div>
            ) : (
              <DragDropFieldImport
                handleChangeFile={this.handleChangeFile}
                handleChangeFolder={this.handleDirectoryUpload}
                preview={isEmptyData ? null : 'withoutPhoto'}
                deleteImg={this.deleteFile}
                importFolder={true}
                folder={this.state.arrayData}
                message={`
                ${t('Загрузить можно файлы формата')}
                 *.jpg
                ${t('или')}
                 *.png,
                 ${t('не более 15 Mb')}
                `}
              />
              // <ImportDialogMin>
              //   <p>
              //     {t('Перетащите сюда файл или')}
              //   </p>
              //   <ImportDialogMinInner>
              //     <div>{this.state.currentRelativePath}</div>
              //     <Button component="label" disabled={false} color={'primary'}>
              //       {t('Выберите папку')}
              //       <input
              //         type="file"
              //         onChange={this.handleDirectoryUpload}
              //         style={{ display: 'none' }}
              //         webkitdirectory=""
              //         mozdirectory=""
              //         directory=""
              //         multiple
              //       />
              //     </Button>
              //   </ImportDialogMinInner>
              // </ImportDialogMin>
            )}
          </DialogContent>
          <DialogActions>
            <span>
              {!isEmptyData && (
                t('Всего файлов') + ': ' +
                `${this.state.arrayData.length}`
              )}
            </span>
            <div>
              <Button
                color={'secondary'}
                disabled={_.isEmpty(fileList) || checkingFiles || !loading}
                onClick={() => this.props.cancelMassMediaImport(true)}>
                {t('Отменить')}
              </Button>
              <Button
                color={'primary'}
                disabled={
                  _.isEmpty(fileList) ||
                  checkingFiles ||
                  !filesAreValid ||
                  loading
                }
                onClick={this.handeUpload}>
                {loading ? (
                  <CircularProgress
                    size={24}
                    style={{ color: colors.Common.textColor }}></CircularProgress>
                ) : (
                  t('Загрузить')
                )}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

MassMediaModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  throwNotification: PropTypes.func.isRequired,
  massMediaImport: PropTypes.func.isRequired,
  cancelMassMediaImport: PropTypes.func.isRequired,
  changeMassUploadListCount: PropTypes.func.isRequired,
  changeGridParams: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      massMediaImport: (data, files, isSameFolder, cbk) =>
        massMediaImport(data, files, isSameFolder, cbk),
      throwNotification: (message, type = 'error') =>
        throwNotification(message, type),
      cancelMassMediaImport: (data) => cancelMassMediaImport(data),
      changeMassUploadListCount: (data) => changeMassUploadListCount(data),
    },
    dispatch,
  );
};

const mapStateToProps = (state) => ({
  massUploadListCount: state.productsReducer.massUploadListCount,
  loading: state.productsReducer.loading,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(MassMediaModal);
