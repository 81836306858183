import * as React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import './style.css';
import {
  IconArrowLeft,
  IconArrowRight,
} from '../../common/icons/customIcons';
import {
  CountCont,
  CustomPagination,
} from '../../common/styles/Common.styled';

const defaultButton = (props) => <button {...props}>{props.children}</button>;
const filterPages = (visiblePages, totalPages) =>
  visiblePages.filter((page) => page <= totalPages);

const getVisiblePages = (page, total) => {
  if (total < 6) {
    return filterPages([1, 2, 3, 4, 5], total);
  } else {
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 1, page, page + 1, total];
    } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    } else {
      return [1, 2, 3, 4, 5, total];
    }
  }
};

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visiblePages: getVisiblePages(this.props.page + 1, this.props.pages),
      totalPages: this.props.pages,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!_.isEqual(this.state, nextState)];
    const nameProps = ['pages', 'page', 'totalObjects', 'locale'];
    _.forEach(nameProps, (i) =>
      equal.push(!_.isEqual(this.props[i], nextProps[i])),
    );
    return _.some(equal);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.totalPages !== nextProps.pages) {
      return {
        visiblePages: getVisiblePages(nextProps.page + 1, nextProps.pages),
        totalPages: nextProps.pages,
      };
    }
    return null;
  }

  changePage = (page) => {
    const activePage = this.props.page + 1;
    if (page === activePage) return;

    const visiblePages = getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: filterPages(visiblePages, this.props.pages),
      totalPages: this.props.pages,
    });

    this.props.onPageChange(page - 1);
  };

  render() {
    const {
      PageButtonComponent = defaultButton,
      pages,
      totalObjects,
      t,
    } = this.props;
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;
    const disabledPrev = activePage === 1;
    const disabledNext = activePage === pages;

    return (
      <CustomPagination>
        {this.state.totalPages > 0 && (
          <div className="Table__pagination">
            <CountCont>{`${t('Всего записей')}: ${totalObjects}`}</CountCont>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {this.props.gridPageSize}
              <div style={{ display: 'flex' }} >
                <div className="Table__prevPageWrapper">
                  <PageButtonComponent
                    className="Table__pageButton"
                    disabled={disabledPrev}
                    onClick={() => this.changePage(activePage - 1)}>
                    <IconArrowLeft
                      className="Table__arrowIcon"
                      disabled={disabledPrev}
                    />
                  </PageButtonComponent>
                </div>
                <div className="Table__visiblePagesWrapper">
                  {visiblePages.map((page, index, array) => {
                    const classPage =
                      activePage === page
                        ? 'Table__pageButton Table__pageButton--active'
                        : 'Table__pageButton';
                    const fnOnClick = () => this.changePage(page);
                    return array[index - 1] + 1 < page ? (
                      <span key={page}>
                        <span>...</span>
                        <PageButtonComponent
                          className={classPage}
                          onClick={fnOnClick}>
                          {page}
                        </PageButtonComponent>
                      </span>
                    ) : (
                      <PageButtonComponent
                        key={page}
                        className={classPage}
                        onClick={fnOnClick}>
                        {page}
                      </PageButtonComponent>
                    );
                  })}
                </div>
                <div className="Table__nextPageWrapper" >
                  <PageButtonComponent
                    className="Table__pageButton"
                    disabled={disabledNext}
                    onClick={() => this.changePage(activePage + 1)}>
                    <IconArrowRight
                      className="Table__arrowIcon"
                      disabled={disabledNext}
                    />
                  </PageButtonComponent>
                </div>
              </div>
            </div>
          </div>
        )}
      </CustomPagination>
    );
  }
}

Pagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  totalObjects: PropTypes.number.isRequired,
  gridPageSize: PropTypes.element,
  PageButtonComponent: PropTypes.any,
  previousText: PropTypes.string,
  nextText: PropTypes.string,
};

const mapStateToProps = (state) => ({
  locale: state.profileReducer.locale,
});

export default compose(withTranslation(), connect(mapStateToProps))(Pagination);
