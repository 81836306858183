import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Doughnut } from 'react-chartjs-2';
import { isKZ, isUZ } from '../../config';
import {
  IconGridEmpty,
  IconProductCheck,
  IconProductCategory,
  IconProductMark,
  IconGS1,
  IconUserManual,
  IconAPI,
  IconVideo,
  IconCertificate,
  IconEye,
  IconCriticalCard,
  IconUserSetting,
  IconGtin,
  IconList,
  IconImage,
  IconFile,
  IconShield,
  IconFlash,
  IconEllipseInstr,
  IconCar,
  IconChecks,
  IconBook,
  IconQuestion,
} from '../../common/icons/customIcons';
import {
  ChartCont,
  ChartContainer,
  ChartContainerHolder,
  ChartContainerLeft,
  ChartContainerTop,
  ChartContainerInner,
  ChartContainerTitle,
  ChartContainerRight,
  ChartStatusColor,
  ChartStatusColorContainer,
  ChartStatusesIconHolder,
  ChartStatusLine,
  ChartStatusTitle,
  ChartTooltip,
  ChartState,
  ChartStateInner,
  ChartStateTable,
  ChartStateBtn,
  ChartStateQuantity,
  ChartStateTitle,
  ChartStateNumber,
  ChartStateName,
  AnalyticContainer,
  AnalyticContainerLeft,
  AnalyticContainerRight,
  AnalyticInstruction,
  AnalyticInstructionImage,
  AnalyticBlocks,
  AnalyticBlock,
  AnaliticBlockTitle,
  AnaliticBlockCenter,
  AnaliticBlockData,
  AnaliticBlockIcon,
  AnalyticLinks,
  AnalyticLink,
  AnalyticLinkIcon,
  AnalyticLinkName,
  AnalyticInstructionTitle,
  AnalyticInstructionBottom,
  ChartStateMore,
  ChartContainerInnerTextEmpty,
  ChartContainerInnerEmpty,
  ChartStateTop,
  AnalyticLinksTitle,
  AnalyticInstructionBlock,
  AnalyticInstructionSvg,
  AnalyticInstructionName,
  AnalyticInstructionMap,
  AnalyticInstructionEllipse,
  AnalyticInstructionRight,
  AnalyticInstructionLeft,
  AnalyticInstructionService,
  AnalyticInstructionLeftTitle,
  AnalyticInstructionRightTitle,
  AnalyticInstructionFourSvg,
  AnalyticInstructionDashed,
  ChartStateNumberCritical,
  AnaliticBlockDataStatus,
} from './Analytic.styled';
import {
  NoDataIconHolder,
  NoDataInner,
  StickyBtnsAnalytic,
} from '../../common/styles/Common.styled';
import { FixingButton, FixingButtonView, FloatingButton, FloatingButtonView, Preloader } from '../../components';
import {
  clearAnalyticPie,
  loadAnalyticPie,
  //loadTradeMarksDictionary,
  //loadCategoriesDictionary,
  loadMainStatistic,
  loadGs1Status,
  loadTradeMarkElapsedStatistics,
  loadPhotoContentStatistics,
  loadGoodsDateExpirationStatistics,
  loadGoodsExemplarStatistics,
} from './ducks/Analytic.actions';
import PackingFilter from './filters/PackingFilter';
import { loadProfileInfo } from '../profile/ducks/Profile/Profile.actions';
import ModalStateMore from './modal/ModalStateMore';
import Instruction from './modal/Instruction';
import { Tooltip } from '@material-ui/core';
import colors from '../../common/styles/Common.colors';

const PackageTypes = [
  { value: 'consumer', text: 'Потребительская упаковка' },
  { value: 'group', text: 'Групповая упаковка' },
  { value: 'transport', text: 'Транспортная упаковка' },
  { value: 'techCard', text: 'Техническая карточка' },
  { value: 'unmarkable', text: 'Немаркируемый товар' },
];

// const AnalyticStatuses = [
//   { id: 0, title: 'Опубликованы', value: 'published', color: '#2DCD13' },
//   { id: 1, title: 'Черновик', value: 'draft', color: '#A6A6A6' },
//   { id: 2, title: 'На модерации', value: 'moderation', color: '#FFA500' },
//   { id: 3, title: 'Требует изменений', value: 'errors', color: '#E62020' },
//   { id: 4, title: 'Ожидает публикации', value: 'notSigned', color: '#67BAFF' },
//   { id: 5, title: 'В архиве', value: 'archived', color: '#A25D1C' },
// ];

const AnalyticStatuses = [
  { id: 0, title: 'Опубликованы', value: 'published', color: '#4AAC3A' },
  { id: 1, title: 'Ожидает публикации', value: 'notSigned', color: '#549FEA' },
  { id: 2, title: 'Черновик', value: 'draft', color: colors.Common.textColor },
  { id: 3, title: 'Требует изменений', value: 'errors', color: '#E32A38' },
  { id: 4, title: 'На модерации', value: 'moderation', color: '#FFA340' },
  // { id: 5, title: 'В архиве', value: 'archived', color: '#DCDCDC' },
];

const deleteAllClass = (analyticPie) => {
  _.forEach(AnalyticStatuses, (i) => {
    if (analyticPie[i.value]) {
      document.getElementsByName(i.title)[0].classList.remove('active');
    }
  });
};

const setClass = (name) =>
  document.getElementsByName(name)[0].classList.add('active');
const equalZero = (number) => number === 0;

const setTooltip = (name, count, pack) => {
  const tooltipEl = document.getElementById('chartjs-tooltip');
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.top = '52%';
  tooltipEl.style.transform = 'translateY(-50%)';
  tooltipEl.style.left = '0';
  tooltipEl.style.right = '0';
  tooltipEl.style.margin = '0 auto';
  tooltipEl.style.width = '200px';
  tooltipEl.style.textAlign = 'center';
  tooltipEl.style.opacity = 1;
  //tooltipEl.innerHTML = `<div>${name}</div> <div style="font-size: 24px">${count}</div>`;
  tooltipEl.innerHTML = `<div>${pack}</div>`;
};

class Analytic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        packingPurpose: null,
        category: null,
        brand: null,
      },
      modalStateMore: {
        isOpen: false,
        type: '',
      },
      instruction: {
        isOpen: false
      }
    };
  }

  componentDidMount() {
    this.props.loadAnalyticPie(null, () => {
      setTimeout(() => {
        this.defaultTooltip();
      }, 100);
    });
    this.props.loadMainStatistic();
    //this.props.loadGoodsCategory();
    //this.props.loadTrademarksDictionary();
    this.props.loadGs1Status();
    this.props.onLoadProfileInfo();
    this.props.loadTradeMarkElapsedStatistics();
    this.props.loadPhotoContentStatistics();
    this.props.loadGoodsDateExpirationStatistics();
    this.props.loadGoodsExemplarStatistics();
  }

  componentWillUnmount() {
    this.props.clearAnalyticPie();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.locale, prevProps.locale)) {
      this.defaultTooltip();
    }
  }

  packageName = () => {
    const { t } = this.props;
    const { packingPurpose } = this.state.filters;
    switch (packingPurpose?.value) {
      case 'consumer':
        return t(`Потребительская`);
      case 'group':
        return t(`Групповая`);
      case 'transport':
        return t(`Транспортная`);
      case 'techCard':
        return t('Техническая карточка');
      case 'unmarkable':
        return t('Немаркируемый товар');
      default:
        return t(`Все упаковки`);
    }
  };

  defaultTooltip = () => {
    const { pieData } = this.dataPreparation();
    const { analyticPie } = this.props;
    if (_.isEmpty(analyticPie) || _.values(analyticPie).every(equalZero)) {
      return;
    }
    if (_.isEmpty(pieData.labels)) {
      return setTooltip(_.replace(``), ``);
    }
    //setTooltip(_.replace(this.packageName()), ``);
    setTooltip(``, ``, _.replace(this.packageName()));
  };

  createDataSet = (analyticPie) => {
    if (_.isNil(analyticPie) || _.isEmpty(analyticPie)) {
      return {};
    }
    const labels = [];
    const data = [];
    const backgroundColors = [];
    _.forEach(AnalyticStatuses, (status) => {
      if (analyticPie[status.value]) {
        labels.push(status.title);
        data.push(_.defaultTo(analyticPie[status.value], 0));
        backgroundColors.push(status.color);
      }
    });

    return {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: backgroundColors,
          borderWidth: 2,
          // hoverBorderColor: backgroundColors,
          // hoverBorderWidth: 6,
          // weight: 3,
          // borderAlign: 'inner'
        },
      ],
    };
  };

  handleChange = (event, select) => {
    let packingType = _.find(PackageTypes, { value: event });
    let filters = _.clone(this.state.filters);
    filters[select] = packingType;

    switch (select) {
      case 'packingPurpose':
        return this.setState({ filters }, () =>
          this.props.loadAnalyticPie(filters, this.defaultTooltip),
        );
      default:
        return;
    }
  };

  dataPreparation = () => {
    const { analyticPie } = this.props;
    const dataPie = {
      ...analyticPie,
    };
    return { pieData: this.createDataSet(dataPie), dataPie };
  };

  handleClick = (event) => {
    const element = document.getElementById(this.props.parentId);
    this.setState({ anchorEl: !!element ? element : event.currentTarget });
  };

  gs1Status = () => {
    const { t } = this.props;
    const { status } = this.props.gs1Status;
    switch (status) {
      case 0:
        return t(`Активно`);
      case 1:
        return t(`Неактивно`);
      case 2:
        return t(`Исключен`);
      default:
        return t(`Отсутствует`);
    }
  };

  getLink = (link) => {
    if (isKZ) {
      switch (link) {
        case 'Инструкция пользователя':
          return 'https://drive.google.com/drive/folders/1GrukINpPSR1w1H5vbD7ZylPClxrfwk0D?usp=sharing';
        case 'Руководство по API':
          return 'https://drive.google.com/drive/folders/1p6ji3vvxeqKwXl_QMZg5ft5qF6-ztE5m?usp=sharing';
        case 'Обучающие видео':
          return 'https://drive.google.com/drive/folders/1yCGSododxUQMw5gRSZ13XkySYAj0lZo1?usp=sharing';
        case 'Справочные документы':
          return 'https://drive.google.com/drive/folders/1aIb-a7aoT0XZ8eHNrFH6DifuHVC9l-XY?usp=sharing';
        default:
          return null;
      }
    } else
      switch (link) {
        case 'Инструкция пользователя':
          return 'https://drive.google.com/drive/folders/1yKKS2_1zG61ASBh4u6EN0H11OFKvF1hy?usp=sharing';
        case 'Руководство по API':
          return 'https://drive.google.com/drive/folders/1dxuzZCmPU9NCVIR2c7MjIO6iEwaqF3rT?usp=sharing';
        case 'Обучающие видео':
          return 'https://drive.google.com/drive/folders/1VX6UmkJIHiQyV3Z762Gcokay3-sQPtBD?usp=sharing';
        case 'Справочные документы':
          return 'https://drive.google.com/drive/folders/14HkyNHixFNkZKEAWf8qQL3t2ma6yhFtT?usp=sharing';
        default:
          return null;
      }
  };

  handleClickMoreModal = (type) => {
    this.setState({
      modalStateMore: { ...this.state.modalStateMore, isOpen: true, type: type },
    });
  };

  hideMoreModal = (e) => {
    this.setState({
      modalStateMore: { ...this.state.modalStateMore, isOpen: false, type: '' }
    });
  };

  handleClickInstruction = () => {
    this.setState({
      instruction: { ...this.state.instruction, isOpen: true },
    });
  };

  hideInstruction = (e) => {
    this.setState({
      instruction: { ...this.state.instruction, isOpen: false }
    });
  };


  render() {
    const {
      t,
      analyticPie,
      mainStatistic,
      gs1Status,
      locale,
      goodsDateExpirationStatistics,
      goodsExemplarStatistics,
      photoContentStatistic,
      tradeMarkElapsedStatistics } = this.props;
    const { modalStateMore, instruction } = this.state;
    const { pieData, dataPie } = this.dataPreparation();
    const analyticPieIsEmpty = (analyticPie) =>
      _.isEmpty(analyticPie) || _.values(analyticPie).every(equalZero);
    return (
      <div>
        {modalStateMore.isOpen && (
          <ModalStateMore
            typeModal={modalStateMore.type}
            handleClose={this.hideMoreModal}
            t={t}
            goodsDateExpirationStatistics={goodsDateExpirationStatistics}
            goodsExemplarStatistics={goodsExemplarStatistics}
            tradeMarkElapsedStatistics={tradeMarkElapsedStatistics}
            photoContentStatistic={photoContentStatistic}
          />
        )}
        {instruction.isOpen && (
          <Instruction
            t={t}
            handleClose={this.hideInstruction}
          />
        )}
        <AnalyticContainer>
          <AnalyticContainerLeft>
            <AnalyticBlocks>
              <AnalyticBlock>
                <AnaliticBlockCenter>
                  <AnaliticBlockData>{analyticPie.published}</AnaliticBlockData>
                  <AnaliticBlockTitle>
                    <div>{t('Товары опубликованы')}</div>
                  </AnaliticBlockTitle>
                  <AnaliticBlockIcon>
                    <IconProductCheck />
                  </AnaliticBlockIcon>

                </AnaliticBlockCenter>
              </AnalyticBlock>
              <AnalyticBlock>
                <AnaliticBlockCenter>
                  <AnaliticBlockData>{analyticPie.publicCatsCount}</AnaliticBlockData>
                  <AnaliticBlockTitle>
                    <div>{t('Товарные категории')}</div>
                  </AnaliticBlockTitle>
                  <AnaliticBlockIcon>
                    <IconProductCategory />
                  </AnaliticBlockIcon>
                </AnaliticBlockCenter>
              </AnalyticBlock>
              <AnalyticBlock>
                <AnaliticBlockCenter>
                  <AnaliticBlockData>{analyticPie.publicBrandsCount}</AnaliticBlockData>
                  <AnaliticBlockTitle>
                    <div>{t('Товарные знаки')}</div>
                  </AnaliticBlockTitle>
                  <AnaliticBlockIcon>
                    <IconProductMark />
                  </AnaliticBlockIcon>
                </AnaliticBlockCenter>
              </AnalyticBlock>
              <AnalyticBlock>
                <AnaliticBlockCenter>
                  <AnaliticBlockDataStatus
                    status={gs1Status.status}
                    style={{ fontSize: '28px' }}>
                    {this.gs1Status()}
                  </AnaliticBlockDataStatus>
                  <AnaliticBlockTitle>
                    <div>{t('Членство в GS1')}</div>
                  </AnaliticBlockTitle>
                  <AnaliticBlockIcon>
                    <IconGS1 />
                  </AnaliticBlockIcon>
                </AnaliticBlockCenter>
              </AnalyticBlock>
            </AnalyticBlocks>
            <ChartState>
              <ChartStateTitle>
                <div>
                  {t('Статистика общего состояния')}
                </div>
              </ChartStateTitle>
              <ChartStateInner>
                <ChartStateTable>
                  <ChartStateQuantity>
                    <ChartStateNumber>
                      {mainStatistic.goodsDateExpirationCount}
                      <ChartStateNumberCritical>
                        {mainStatistic.goodsDateExpirationCount > 0 && <IconCriticalCard width={24} height={24} />}
                      </ChartStateNumberCritical>
                    </ChartStateNumber>
                    <ChartStateName>
                      {t(
                        'Завершился срок действия сертификата или декларации',
                      )}
                    </ChartStateName>
                    <ChartStateBtn>
                      <ChartStateMore
                        onClick={() => this.handleClickMoreModal('goodsDateExpirationCount')}
                        component="label">
                        {t('Посмотреть')}
                      </ChartStateMore>
                    </ChartStateBtn>
                  </ChartStateQuantity>
                  <ChartStateQuantity>
                    <ChartStateNumber>
                      {mainStatistic.goodsExemplarCount}
                    </ChartStateNumber>
                    <ChartStateName>
                      {t('Карточки товаров в совместном управлении участниками системы')}
                    </ChartStateName>
                    <ChartStateBtn>
                      <ChartStateMore
                        onClick={() => this.handleClickMoreModal('goodsExemplarCount')}
                        component="label">
                        {t('Посмотреть')}
                      </ChartStateMore>
                    </ChartStateBtn>
                  </ChartStateQuantity>
                  <ChartStateQuantity>
                    <ChartStateNumber>
                      {mainStatistic.goodsTradeMarkElapsed}
                      <ChartStateNumberCritical>
                        {mainStatistic.goodsTradeMarkElapsed > 0 && <IconCriticalCard width={24} height={24} />}
                      </ChartStateNumberCritical>
                    </ChartStateNumber>
                    <ChartStateName>
                      {t(
                        'Завершился срок действия свидетельства на товарный знак',
                      )}
                    </ChartStateName>
                    <ChartStateBtn>
                      <ChartStateMore
                        onClick={() => this.handleClickMoreModal('goodsTradeMarkElapsed')}
                        component="label">
                        {t('Посмотреть')}
                      </ChartStateMore>
                    </ChartStateBtn>
                  </ChartStateQuantity>
                  <ChartStateQuantity>
                    <ChartStateNumber>
                      {mainStatistic.goodsPhotoHasWatermarkCount}
                    </ChartStateNumber>
                    <ChartStateName>
                      {t(
                        'Статистика использования наложения водяного знака системой',
                      )}
                    </ChartStateName>
                    <ChartStateBtn>
                      <ChartStateMore
                        onClick={() => this.handleClickMoreModal('goodsPhotoHasWatermarkCount')}
                        component="label">
                        {t('Посмотреть')}
                      </ChartStateMore>
                    </ChartStateBtn>
                  </ChartStateQuantity>
                </ChartStateTable>
              </ChartStateInner>
            </ChartState>
            <ChartCont>
              <ChartContainer>
                {analyticPieIsEmpty(analyticPie) ? (
                  // <NoDataInner>
                  //   <NoDataIconHolder>
                  //     <IconGridEmpty />
                  //   </NoDataIconHolder>
                  //   <div>
                  //     {t('Информация появится после добавления товаров в реестр')}
                  //   </div>
                  // </NoDataInner>

                  <ChartContainerLeft>
                    <ChartContainerTop>
                      <ChartContainerTitle>
                        <div id="packingPurposeFilter">
                          {t('Статистика по статусам')}
                        </div>
                      </ChartContainerTitle>
                      {/* <PackingFilter
                        parentId="packingPurposeFilter"
                        onChange={(event) =>
                          this.handleChange(event, 'packingPurpose')
                        }
                        currentValue={_.defaultTo(
                          this.state.filters.packingPurpose?.value,
                          null,
                        )}
                        packageTypes={PackageTypes}
                      /> */}
                    </ChartContainerTop>
                    <ChartContainerInnerEmpty>
                      <ChartContainerInnerTextEmpty>
                        {t('Данные в этом разделе появятся после регистрации товаров')}
                      </ChartContainerInnerTextEmpty>
                    </ChartContainerInnerEmpty>
                  </ChartContainerLeft>
                ) : (
                  <ChartContainerLeft>
                    <ChartContainerTop>
                      <ChartContainerTitle>
                        <div id="packingPurposeFilter">
                          {t('Статистика по статусам')}
                        </div>
                      </ChartContainerTitle>
                      <PackingFilter
                        parentId="packingPurposeFilter"
                        onChange={(event) =>
                          this.handleChange(event, 'packingPurpose')
                        }
                        currentValue={_.defaultTo(
                          this.state.filters.packingPurpose?.value,
                          null,
                        )}
                        packageTypes={PackageTypes}
                        packageName={this.packageName()}
                      />
                    </ChartContainerTop>
                    <ChartContainerInner>
                      <ChartContainerHolder>
                        <ChartTooltip id="chartjs-tooltip" />
                        <Doughnut
                          data={pieData}
                          legend={{ display: false }}
                          height={258}
                          options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            cutoutPercentage: 80,
                            layout: {
                              padding: {
                                left: 0,
                                right: 4,
                                top: 25,
                                bottom: 25,
                              }
                            },
                            tooltips: {
                              itemSort: 80,
                              enabled: true,
                              intersect: true,
                              custom: (tooltipModel) => {
                                if (tooltipModel.opacity === 0) {
                                  this.defaultTooltip();
                                  deleteAllClass(dataPie);
                                  tooltipModel.displayColors = false;
                                  return;
                                }

                                if (tooltipModel.body) {
                                  deleteAllClass(dataPie);
                                  const str = tooltipModel.body[0].lines[0].split(
                                    ':',
                                  );
                                  const name = _.trim(str[0]);
                                  const count = _.trim(str[1]);
                                  setClass(name);
                                  setTooltip(t(name), count, this.packageName());
                                  tooltipModel.body[0].lines[0] = count
                                  tooltipModel.displayColors = false;
                                  tooltipModel.width = count.length === 1 || count.length === 2 ? 40 : count.length === 3 ? 50 : count * 10 + 30;
                                  tooltipModel.height = 30;
                                  tooltipModel.x = tooltipModel.caretX - 8;
                                  tooltipModel.y = tooltipModel.caretY - 32;
                                  tooltipModel.xAlign = 'left';
                                  tooltipModel.yAlign = 'bottom';
                                  tooltipModel.bodyFontSize = 18;
                                  tooltipModel.xPadding = 10;
                                  tooltipModel._bodyAlign = 'center';
                                }
                              },
                            },
                          }
                          }
                        />
                      </ChartContainerHolder>
                      <ChartStatusesIconHolder>
                        {_.map(AnalyticStatuses, (status) => {
                          return (
                            <ChartStatusLine
                              key={status.value}
                              style={{
                                opacity: _.isEmpty(this.state.statuses)
                                  ? 1
                                  : _.includes(
                                    this.state.statuses,
                                    status.value,
                                  )
                                    ? 1
                                    : 0.5,
                              }}
                              name={status.title}>
                              <ChartStatusColorContainer>
                                <ChartStatusColor
                                  className="status-color"
                                  style={{ background: status.color }}
                                />
                              </ChartStatusColorContainer>
                              &nbsp;
                              <ChartStatusTitle>
                                {t(status.title)}
                              </ChartStatusTitle>
                              &nbsp;
                              {/* <span>({dataPie[status.value]})</span> */}
                            </ChartStatusLine>
                          );
                        })}
                      </ChartStatusesIconHolder>
                    </ChartContainerInner>
                  </ChartContainerLeft>
                )}
                <ChartContainerRight>
                  <AnalyticLinksTitle>
                    {t('Справочный центр')}
                  </AnalyticLinksTitle>
                  <AnalyticLinks>
                    <AnalyticLink
                      target="_blank"
                      href={this.getLink('Инструкция пользователя')}>
                      <AnalyticLinkIcon>
                        <IconUserManual />
                      </AnalyticLinkIcon>
                      <AnalyticLinkName>
                        <div>{t('Инструкция пользователя')}</div>
                      </AnalyticLinkName>
                    </AnalyticLink>
                    <AnalyticLink
                      target="_blank"
                      href={this.getLink('Руководство по API')}>
                      <AnalyticLinkIcon>
                        <IconAPI />
                      </AnalyticLinkIcon>
                      <AnalyticLinkName>
                        <div>{t('Руководство по API')}</div>
                      </AnalyticLinkName>
                    </AnalyticLink>
                  </AnalyticLinks>
                  <AnalyticLinks>
                    <AnalyticLink
                      target="_blank"
                      href={this.getLink('Обучающие видео')}>
                      <AnalyticLinkIcon>
                        <IconVideo />
                      </AnalyticLinkIcon>
                      <AnalyticLinkName>
                        <div>{t('Обучающие видео')}</div>
                      </AnalyticLinkName>
                    </AnalyticLink>
                    <AnalyticLink
                      target="_blank"
                      href={this.getLink('Справочные документы')}>
                      <AnalyticLinkIcon>
                        <IconCertificate />
                      </AnalyticLinkIcon>
                      <AnalyticLinkName>
                        <div>{t('Справочные документы')}</div>
                      </AnalyticLinkName>
                    </AnalyticLink>
                  </AnalyticLinks>
                </ChartContainerRight>
              </ChartContainer>
            </ChartCont>

            <Preloader isOpen={this.props.loading} text={t('Загрузка')} />
          </AnalyticContainerLeft>
          <StickyBtnsAnalytic>
            <FixingButtonView>
              <React.Fragment>
                <FixingButton
                  //text={t('Открыть на сайте')}
                  onClick={this.handleClickInstruction}
                  color={'primary'}
                  icon={<IconBook style={{ marginRight: '5px' }} />}
                />
              </React.Fragment>
            </FixingButtonView>
          </StickyBtnsAnalytic>
          <AnalyticContainerRight>
            <AnalyticInstruction>
              <AnalyticInstructionTitle>
                <div>{t('Краткая инструкция пользователя')}</div>
              </AnalyticInstructionTitle>
              {/* <AnalyticInstructionImage>
                {isUZ && locale === 'uz'
                  ? <img src="/images/instructionUZ.png" alt="" />
                  : <img src="/images/instruction.png" alt="" />}
              </AnalyticInstructionImage> */}
              <AnalyticInstructionMap>
                <AnalyticInstructionLeft>
                  <AnalyticInstructionLeftTitle>
                    {t('Действия пользователя')}
                  </AnalyticInstructionLeftTitle>
                  <AnalyticInstructionBlock>
                    <AnalyticInstructionEllipse>
                      <IconEllipseInstr />
                      <AnalyticInstructionSvg>
                        <IconUserSetting />
                      </AnalyticInstructionSvg>
                    </AnalyticInstructionEllipse>
                    <AnalyticInstructionName>
                      {t('Настройка профиля')}
                    </AnalyticInstructionName>
                    <AnalyticInstructionDashed />
                  </AnalyticInstructionBlock>
                  <AnalyticInstructionBlock>
                    <AnalyticInstructionEllipse>
                      <IconEllipseInstr />
                      <AnalyticInstructionSvg>
                        <IconGtin />
                      </AnalyticInstructionSvg>
                    </AnalyticInstructionEllipse>
                    <AnalyticInstructionName>
                      {t('Регистрация кодов упаковок (GTIN)')}
                    </AnalyticInstructionName>
                  </AnalyticInstructionBlock>
                  <AnalyticInstructionBlock>
                    <AnalyticInstructionEllipse>
                      <IconEllipseInstr />
                      <AnalyticInstructionSvg>
                        <IconList color={colors.Icons.background} />
                      </AnalyticInstructionSvg>
                    </AnalyticInstructionEllipse>
                    <AnalyticInstructionName>
                      {t('Заполнение обязательных атрибутов')}
                    </AnalyticInstructionName>
                  </AnalyticInstructionBlock>
                  <AnalyticInstructionBlock>
                    <AnalyticInstructionEllipse>
                      <IconEllipseInstr />
                      <AnalyticInstructionSvg>
                        <IconImage color={colors.Icons.background} />
                      </AnalyticInstructionSvg>
                    </AnalyticInstructionEllipse>
                    <AnalyticInstructionName>
                      {t('Загрузка фотоконтента')}
                    </AnalyticInstructionName>
                  </AnalyticInstructionBlock>
                  <AnalyticInstructionBlock>
                    <AnalyticInstructionEllipse>
                      <IconEllipseInstr />
                      <AnalyticInstructionSvg>
                        <IconFile color={colors.Icons.background} />
                      </AnalyticInstructionSvg>
                    </AnalyticInstructionEllipse>
                    <AnalyticInstructionName>
                      {t('Внесение сведений по разрешительным документам')}
                    </AnalyticInstructionName>
                  </AnalyticInstructionBlock>
                  <AnalyticInstructionBlock>
                    <AnalyticInstructionEllipse>
                      <IconEllipseInstr />
                      <AnalyticInstructionSvg>
                        <IconShield />
                      </AnalyticInstructionSvg>
                    </AnalyticInstructionEllipse>
                    <AnalyticInstructionName>
                      {t('Прохождение модерации')}
                    </AnalyticInstructionName>
                    <AnalyticInstructionDashed />
                  </AnalyticInstructionBlock>
                  <AnalyticInstructionBlock>
                    <AnalyticInstructionEllipse>
                      <IconEllipseInstr />
                      <AnalyticInstructionSvg>
                        <IconFlash />
                      </AnalyticInstructionSvg>
                    </AnalyticInstructionEllipse>
                    <AnalyticInstructionName>
                      {t('Подписание ЭЦП и публикация')}
                    </AnalyticInstructionName>
                  </AnalyticInstructionBlock>
                </AnalyticInstructionLeft>
                <AnalyticInstructionRight>
                  <AnalyticInstructionRightTitle>
                    {t('Контент–сервис провайдер')}
                  </AnalyticInstructionRightTitle>
                  <AnalyticInstructionService>
                    <AnalyticInstructionEllipse>
                      <IconEllipseInstr />
                      <AnalyticInstructionSvg gray>
                        <IconCar />
                      </AnalyticInstructionSvg>
                    </AnalyticInstructionEllipse>
                    <AnalyticInstructionName>
                      {t('Предоставляете образцы товаров')}
                    </AnalyticInstructionName>
                  </AnalyticInstructionService>
                  <AnalyticInstructionService>
                    <AnalyticInstructionEllipse>
                      <IconEllipseInstr />
                      <AnalyticInstructionSvg>
                        <IconGtin />
                      </AnalyticInstructionSvg>
                    </AnalyticInstructionEllipse>
                    <AnalyticInstructionName style={{ paddingBottom: '90px' }}>
                      {t('Регистрация кодов упаковок (GTIN)')}
                    </AnalyticInstructionName>
                    <AnalyticInstructionFourSvg style={{ position: 'relative' }}>
                      <AnalyticInstructionDashed style={{ transform: 'rotate(90deg)', top: '-90px', left: '-46px', width: '91px', height: '91px' }} />
                      <AnalyticInstructionSvg fourSvg gray>
                        <IconImage color={'#AAA9A9'} />
                      </AnalyticInstructionSvg>
                      <AnalyticInstructionSvg fourSvg gray>
                        <IconList color={'#AAA9A9'} />
                      </AnalyticInstructionSvg>
                      <AnalyticInstructionSvg fourSvg gray>
                        <IconFile color={'#AAA9A9'} />
                      </AnalyticInstructionSvg>
                      <AnalyticInstructionSvg fourSvg>
                        <IconShield />
                      </AnalyticInstructionSvg>
                    </AnalyticInstructionFourSvg>
                    <AnalyticInstructionName style={{ padding: '0 0 51px' }}>
                      {t('Заполняем атрибуты, фотографируем, проходим модерацию')}
                    </AnalyticInstructionName>
                  </AnalyticInstructionService>
                  <AnalyticInstructionService>
                    <AnalyticInstructionEllipse>
                      <IconEllipseInstr />
                      <AnalyticInstructionSvg gray>
                        <IconChecks />
                      </AnalyticInstructionSvg>
                    </AnalyticInstructionEllipse>
                    <AnalyticInstructionName>
                      {t('Принимаете результат')}
                    </AnalyticInstructionName>
                  </AnalyticInstructionService>
                </AnalyticInstructionRight>
              </AnalyticInstructionMap>
              <AnalyticInstructionBottom>
                <div>
                  {t(
                    'Список аккредитованных контент-сервис провайдеров',
                  )}
                </div>
                <Tooltip
                  arrow
                  placement="top"
                  title={t('Предоставляется по запросу')}>
                  <div><IconQuestion /></div>
                </Tooltip>
              </AnalyticInstructionBottom>
            </AnalyticInstruction>
          </AnalyticContainerRight>
        </AnalyticContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  analyticPie: state.analyticReducer.data,
  mainStatistic: state.analyticReducer.goodsMainStatistic,
  gs1Status: state.analyticReducer.gs1Status,
  loading: !!state.analyticReducer.loading,
  locale: state.profileReducer.locale,
  photoContentStatistic: state.analyticReducer.photoContentStatistic,
  tradeMarkElapsedStatistics: state.analyticReducer.tradeMarkElapsedStatistics,
  goodsDateExpirationStatistics: state.analyticReducer.goodsDateExpirationStatistics,
  goodsExemplarStatistics: state.analyticReducer.goodsExemplarStatistics,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadAnalyticPie: (query, cbk) => loadAnalyticPie(query, cbk),
      loadMainStatistic: () => loadMainStatistic(),
      clearAnalyticPie: () => clearAnalyticPie(),
      //loadGoodsCategory: () => loadCategoriesDictionary(),
      //loadTrademarksDictionary: () => loadTradeMarksDictionary(),
      loadGs1Status: () => loadGs1Status(),
      onLoadProfileInfo: (cbk) => loadProfileInfo(cbk),
      loadTradeMarkElapsedStatistics: () => loadTradeMarkElapsedStatistics(),
      loadPhotoContentStatistics: () => loadPhotoContentStatistics(),
      loadGoodsDateExpirationStatistics: () => loadGoodsDateExpirationStatistics(),
      loadGoodsExemplarStatistics: () => loadGoodsExemplarStatistics(),
    },
    dispatch,
  );
};

Analytic.propTypes = {
  loadAnalyticPie: PropTypes.func.isRequired,
  loadMainStatistic: PropTypes.func.isRequired,
  //loadGoodsCategory: PropTypes.func.isRequired,
  loadGs1Status: PropTypes.func.isRequired,
  //loadTrademarksDictionary: PropTypes.func.isRequired,
  clearAnalyticPie: PropTypes.func.isRequired,
  analyticPie: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  loadTradeMarkElapsedStatistics: PropTypes.func.isRequired,
  loadPhotoContentStatistics: PropTypes.func.isRequired,
  loadGoodsDateExpirationStatistics: PropTypes.func.isRequired,
  loadGoodsExemplarStatistics: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Analytic);
