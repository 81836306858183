import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const DefaultOrderPopover = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '380px',
        maxWidth: '380px',
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
        borderRadius: '8px',
        padding: '24px 0 0',
        boxSizing: 'border-box',
        fontFamily: 'SegoeUI',
        color: `${colors.Common.textColor}`,
      },
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '4px',
        minWidth: '100px',
        transition: 'all 0.4s ease',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        textTransform: 'capitalize',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
      },
      textPrimary: {
        color: `${colors.Buttons.buttonPrimary.color}`,
        padding: '10px 35px',
      },
      textSecondary: {
        color: `${colors.Common.textColor}`,
        padding: '10px 24px',
        background: 'none',
        border: '1px solid #DCDCDC',
        textTransform: 'capitalize',
        marginRight: '20px',
        '&:hover': {
          backgroundColor: '#F5F5F5 !important',
          color: `${colors.Common.textColor} !important`,
        },
        '&$disabled': {
          color: '#C9C7C7',
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
        borderRadius: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
    MuiCheckbox: {
      root: {
        minWidth: '24px',
        height: '24px',
        padding: '0',
        '&$disabled path': {
          stroke: '#C9C7C7',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 28px 0',
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 28px 16px',
      },
    },
    MuiTypography: {
      h2: {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
      }
    },
    MuiDialogActions: {
      root: {
        padding: '32px 28px',
        borderTop: '1px solid #E7E7E7',
      },
      spacing: {
        '&>:not(:first-child)': {
          margin: '0 !important',
        }
      },
    },
    MuiDialogContentText: {
      root: {
        padding: '0',
      },
    },
  },
});

export const OrderNumber = styled.div`
  width: 40px;
  height: 40px;
  background: #f2f2f2;
  border-radius: 100px;
  text-align: center;
  line-height: 40px;
  margin-right: 30px;
`;
export const CheckCont = styled.div`  
  margin: 0 12px 0 0;
`;
export const DropIconCont = styled.span`
  padding: 0 0 0 8px;
  margin-left: auto;
`;
