import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../common/styles/Common.colors';

export const PickerIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  cursor: pointer;
  height: 24px;
`;
export const PickerDate = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #C9C7C7',
        borderRadius: '4px',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
        '&$error': {
          border: '1px solid #E32A38',
        },
      },
      input: {
        color: `${colors.Common.textColor} !important`,
        padding: '23px 44px 7px 15px',
        fontSize: '16px',
        lineHeight: '24px',
      },
      error: {},
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
        width: 'calc(100% - 54px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px, 32px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      shrink: {
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      filled: {
        color: `${colors.Common.textGrey}`,
      },
      disabled: {},
    },
    MuiInputBase: {
      root: {},
      input: {
        color: `${colors.Common.textColor}`,
        height: 'auto',
        '&:required': {
          borderRadius: '4px',
          borderLeft: '4px solid #E32A38',
          "&[valid='false']": {
            borderLeft: `4px solid #E32A38`,
          },
          "&[valid='true']": {
            borderLeft: 'none',
          },
        },
        '&$disabled': {
          cursor: 'pointer',
          borderLeft: 'none',
        },
      },
      multiline: {
        padding: '0',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        '&$focused': {
          color: `${colors.Common.textColor}`,
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});
export const DefaultPickerPopover = createMuiTheme({
  typography: {},
  overrides: {
    MuiPopover: {
      paper: {
        maxHeight: '500px',
        minWidth: '380px',
        maxWidth: '380px',
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
        borderRadius: '8px',
        padding: '24px 28px',
        boxSizing: 'border-box',
        marginLeft: '0',
        marginTop: '0',
      },
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '4px',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '10px 35px',
        fontWeight: '600',
        textTransform: 'capitalize',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
        lineHeight: '24px',
      },
      textPrimary: {
        color: `${colors.Buttons.buttonPrimary.color}`,
      },
      textSecondary: {
        background: 'none',
        padding: '10px 25px',
        marginRight: '16px',
        border: '1px solid #DCDCDC',
        color: `${colors.Common.textColor}`,
        '&:hover': {
          backgroundColor: '#F5F5F5 !important',
          color: `${colors.Common.textColor} !important`,
        },
        '&$disabled': {
          color: '#C9C7C7',
          backgroundColor: 'transparent !important',
        },
      },
      disabled: {},
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        minWidth: '120px',
        margin: '0 16px 0 0 !important',
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: `${colors.Common.textColor}!important`,
      },
    },
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '4px',
      },
      input: {
        padding: '15px 9px',
        lineHeight: '21px',
        boxSizing: 'border-box',
      },
    },
    MuiInputBase: {
      input: {
        boxSizing: 'border-box',
      },
    },
  },
});
export const PickerBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '25px',
        maxHeight: '45px',
        marginLeft: 'auto',
      },
      '&:hover': {
        backgroundColor: '#E7E7E7',
        borderRadius: '2px'
      },
      text: {
        padding: '2px',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    }
  },
});
export const FilterBtnReset = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        marginRight: '20px',
        borderRadius: '4px',
        border: '1px solid #DCDCDC',
        '&:hover': {
          backgroundColor: '#F5F5F5 !important',
          color: `${colors.Common.textColor} !important`,
          borderColor: '#F5F5F5',
        },
        '&$disabled': {
          color: '#C9C7C7',
          '& path': {
            stroke: '#C9C7C7',
          }
        },
      },
      text: {
        padding: '9px 24px',
        fontWeight: '600',
        textTransform: 'none',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0',
      },
    },
  },
});
export const PickerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6 {
    color: ${colors.Common.textColor};
    font-size: 18px;
    margin: 0;
    line-height: 24px;
    font-weight: 600;
  }
  .backEmpty {
    background: none;
  }
`;
export const PickerContent = styled.div`
  display: flex;
  padding: 0 0 28px;
  position: relative;
`;
export const PickerBtns = styled.div`
  display: flex;
  justify-content: flex-end;  
`;
export const DatePickerBox = styled.div`
  width: 100%;
  .rdrCalendarWrapper {
    width: 100%;
  }
  .rdrMonthAndYearPickers {
    justify-content: space-between;
  }
  .rdrMonths {
    padding-top: 16px;
  }
  .rdrMonth {
    padding: 0;
    box-sizing: border-box;
    width: 100% !important;
  }
  .rdrWeekDays {
    display: flex;
    padding-bottom: 12px;
    border-bottom: 1px solid ${colors.Buttons.buttonPrimary.backgroundInactive};
  }
  .rdrWeekDay {    
    color: #AAA9A9;
    height: 24px;
    font-size: 13px;
    line-height: 16px;
    text-transform: capitalize;
  }
  .rdrMonthPicker,
  .rdrYearPicker {    
    min-width: 150px;
    margin: 0;    
    select {
      width: 100%;
      border: 1px solid #C9C9C9;
      border-radius: 4px;
      text-transform: capitalize;
      &:hover {
        background-color: #ffffff;
        border: 1px solid #AAA9A9;
      }     
    }
  }
  .rdrDays {
    padding-top: 12px;
  }
  .rdrDay {
    font-size: 16px;
    line-height: 24px;
    color: ${colors.Common.textColor};
    font-weight: normal;
    height: 36px;
    .rdrDayStartPreview,
    .rdrDayEndPreview,
    .rdrSelected {
      background: ${colors.Common.background};
      height: 100%;
      left: 0;
      width: 100%;
      top: 0;
      border-radius: 4px;
      z-index: 2;     
    }
    .rdrDayStartPreview {
      border-radius: 4px 0 0 4px;
    }
    .rdrDayEndPreview {
      border-radius: 0 4px 4px 0;
    }

    .rdrDayStartPreview.rdrDayEndPreview {
      border-radius: 4px;
    }
    .rdrDayNumber {
      background: #ffffff;
      font-weight: normal;
      height: 100%;
      left: 0;
      width: 100%;
      top: 0;
      border: 1px solid #fff;
    }

    .rdrDayNumber span {
      color: ${colors.Common.textColor};
      z-index: 3;
    }
  }
  .rdrDayPassive .rdrDayNumber span {
    color: #AAA9A9 !important;
  }

  .rdrDayToday .rdrDayNumber {
    span: after {
      background: ${colors.Common.background} !important;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        bottom: 0;
    }
  }
  .rdrDayHovered .rdrDayNumber:after {
    display: none !important;
  }
  .rdrMonthAndYearWrapper {     
    padding: 0;
    height: 44px;
  }
`;
