import { Reducer } from 'redux';
import { assign, concat, includes, map, set, take, uniqBy } from 'lodash';
import {
  CLEAN_NOTIFICATION_ACTION,
  LOAD_DICTIONARY_CHANNELS_GS1_ACTION,
  LOAD_DICTIONARY_CHANNELS_GS1_FAILED_ACTION,
  LOAD_DICTIONARY_CHANNELS_GS1_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_COUNTRY_ACTION,
  LOAD_DICTIONARY_COUNTRY_FAILED_ACTION,
  LOAD_DICTIONARY_COUNTRY_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_DEPARTMENT_ACTION,
  LOAD_DICTIONARY_DEPARTMENT_FAILED_ACTION,
  LOAD_DICTIONARY_DEPARTMENT_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_EDO_PROVIDER_ACTION,
  LOAD_DICTIONARY_EDO_PROVIDER_FAILED_ACTION,
  LOAD_DICTIONARY_EDO_PROVIDER_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_GCP_PRODUCTS_ACTION,
  LOAD_DICTIONARY_GCP_PRODUCTS_FAILED_ACTION,
  LOAD_DICTIONARY_GCP_PRODUCTS_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_KNOWLEDGE_GS1_ACTION,
  LOAD_DICTIONARY_KNOWLEDGE_GS1_FAILED_ACTION,
  LOAD_DICTIONARY_KNOWLEDGE_GS1_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_OBTAINING_METHOD_ACTION,
  LOAD_DICTIONARY_OBTAINING_METHOD_FAILED_ACTION,
  LOAD_DICTIONARY_OBTAINING_METHOD_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_OKPD_ACTION,
  LOAD_DICTIONARY_OKPD_FAILED_ACTION,
  LOAD_DICTIONARY_OKPD_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_OKVED_ACTION,
  LOAD_DICTIONARY_OKVED_FAILED_ACTION,
  LOAD_DICTIONARY_OKVED_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_OPF_ACTION,
  LOAD_DICTIONARY_OPF_FAILED_ACTION,
  LOAD_DICTIONARY_OPF_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_ORG_PROFILES_ACTION,
  LOAD_DICTIONARY_ORG_PROFILES_FAILED_ACTION,
  LOAD_DICTIONARY_ORG_PROFILES_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIAL_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIAL_FAILED_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIAL_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_ACTION,
  LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_FAILED_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_FAILED_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_FAILED_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPE_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPE_FAILED_ACTION,
  LOAD_DICTIONARY_PACKAGE_TYPE_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PALLET_TYPE_ACTION,
  LOAD_DICTIONARY_PALLET_TYPE_FAILED_ACTION,
  LOAD_DICTIONARY_PALLET_TYPE_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_POSITION_ACTION,
  LOAD_DICTIONARY_POSITION_FAILED_ACTION,
  LOAD_DICTIONARY_POSITION_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_PROJECTS_GS1_ACTION,
  LOAD_DICTIONARY_PROJECTS_GS1_FAILED_ACTION,
  LOAD_DICTIONARY_PROJECTS_GS1_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_SALES_GS1_ACTION,
  LOAD_DICTIONARY_SALES_GS1_FAILED_ACTION,
  LOAD_DICTIONARY_SALES_GS1_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_SECTOR_ACTION,
  LOAD_DICTIONARY_SECTOR_FAILED_ACTION,
  LOAD_DICTIONARY_SECTOR_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_ACTION,
  LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_FAILED_ACTION,
  LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_SUBJECT_ACTION,
  LOAD_DICTIONARY_SUBJECT_FAILED_ACTION,
  LOAD_DICTIONARY_SUBJECT_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_TM_ACTION,
  LOAD_DICTIONARY_TM_FAILED_ACTION,
  LOAD_DICTIONARY_TM_SUCCEEDED_ACTION,
  LOAD_NOTIFICATION_SUCCEEDED_ACTION,
  LOAD_PRODUCTS_CATEGORIES_ACTION,
  LOAD_PRODUCTS_CATEGORIES_FAILED_ACTION,
  LOAD_PRODUCTS_CATEGORIES_SUCCEEDED_ACTION,
  LOAD_TNVED_ACTION,
  LOAD_TNVEDS_FAILED_ACTION,
  LOAD_TNVEDS_SUCCEEDED_ACTION,
  MARK_NOTIFICATION_SUCCESSED_ACTION,
  LOAD_GTINS_LIST_BY_PACKAGE_ACTION,
  LOAD_GTINS_LIST_BY_PACKAGE_SUCCEEDED_ACTION,
  LOAD_GTINS_LIST_BY_PACKAGE_FAILED_ACTION,
  LOAD_ACTIVE_KZ_DICTIONARY_ACTION,
  LOAD_ACTIVE_KZ_DICTIONARY_SUCCEEDED_ACTION,
  LOAD_ACTIVE_KZ_DICTIONARY_FAILED_ACTION,
  CLEAR_ACTIVE_KZ_DICTIONARY_ACTION,
  CLEAR_GTINS_LIST_BY_PACKAGE_ACTION,
  LOAD_GTINS_LIST_FOR_VALIDATION_ACTION,
  LOAD_GTINS_LIST_FOR_VALIDATION_SUCCEEDED_ACTION,
  LOAD_GTINS_LIST_FOR_VALIDATION_FAILED_ACTION,
  // LOAD_NEW_OPF_DICTIONARY_ACTION,
  // LOAD_NEW_OPF_DICTIONARY_ACTION_SUCCEEDED,
  // LOAD_NEW_OPF_DICTIONARY_ACTION_FAILED,
  LOAD_KATO_DICTIONARY_ACTION,
  LOAD_KATO_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_KATO_DICTIONARY_ACTION_FAILED,
  LOAD_DICTIONARY_COUNTRIES_ACTION,
  LOAD_DICTIONARY_COUNTRIES_SUCCEEDED_ACTION,
  LOAD_DICTIONARY_COUNTRIES_FAILED_ACTION,
  LOAD_NOTIFICATION_RECENT_SUCCEEDED_ACTION,
} from './CatalogActionTypes.constants';
import { getMonth, parseAddresses } from './Catalog.utils';
import { subtractNotNegative } from '../utils/utils';

const _initialState = {
  dictionaryTnveds: [],
  dictionaryTm: [],
  dictionaryCountry: [],
  dictionaryPosition: [],
  dictionaryOpf: [],
  dictionarySubject: [],
  dictionaryDepartment: [],
  dictionaryKnowledge: [],
  dictionarySales: [],
  dictionaryProjects: [],
  dictionaryChannels: [],
  dictionaryOrgProfiles: [],
  dictionarySectors: [],
  dictionaryGcpProducts: [],
  dictionaryObtainingMethods: [],
  dictionaryEdoProviders: [],
  dictionaryOkpd: [],
  dictionaryShowboxDisplaySigns: [],
  dictionaryPackageTypes: [],
  dictionaryPackageMaterials: [],
  dictionaryPackageLetterNumeralCode: [],
  dictionaryPackageTypesCapping: [],
  dictionaryPackageMaterialsCapping: [],
  dictionaryPalletTypes: [],
  dictionaryOkved: [],
  productsCategories: [],
  notifications: [],
  gtinsListByPackageType: [],
  gtinsListForValidation: [],
  gtinsListWasLoaded: false,
  gtinsValidationListWasLoaded: false,
  activeKzDictionary: [],
  loading: 0,
  katoDictionary: [],
  dictionaryCountries: [],
  notificationRecentInfo: {},
  notificationsTotalCount: 0,
};

export const catalogReducer: Reducer = (state = _initialState, action) => {
  switch (action.type) {
    case CLEAR_GTINS_LIST_BY_PACKAGE_ACTION: {
      return {
        ...state,
        gtinsListByPackageType: [],
        gtinsListForValidation: [],
        gtinsListWasLoaded: false,
        gtinsValidationListWasLoaded: false,
      };
    }

    case LOAD_KATO_DICTIONARY_ACTION:
    case LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_ACTION:
    case LOAD_TNVED_ACTION:
    case LOAD_DICTIONARY_TM_ACTION:
    case LOAD_DICTIONARY_COUNTRY_ACTION:
    case LOAD_DICTIONARY_OPF_ACTION:
    case LOAD_DICTIONARY_POSITION_ACTION:
    case LOAD_DICTIONARY_SUBJECT_ACTION:
    case LOAD_DICTIONARY_DEPARTMENT_ACTION:
    case LOAD_DICTIONARY_CHANNELS_GS1_ACTION:
    case LOAD_DICTIONARY_PROJECTS_GS1_ACTION:
    case LOAD_DICTIONARY_KNOWLEDGE_GS1_ACTION:
    case LOAD_DICTIONARY_SALES_GS1_ACTION:
    case LOAD_DICTIONARY_ORG_PROFILES_ACTION:
    case LOAD_DICTIONARY_SECTOR_ACTION:
    case LOAD_DICTIONARY_GCP_PRODUCTS_ACTION:
    case LOAD_DICTIONARY_OBTAINING_METHOD_ACTION:
    case LOAD_DICTIONARY_EDO_PROVIDER_ACTION:
    case LOAD_DICTIONARY_OKPD_ACTION:
    case LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_ACTION:
    case LOAD_DICTIONARY_PACKAGE_MATERIAL_ACTION:
    case LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_ACTION:
    case LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_ACTION:
    case LOAD_DICTIONARY_PACKAGE_TYPE_ACTION:
    case LOAD_DICTIONARY_PALLET_TYPE_ACTION:
    case LOAD_DICTIONARY_OKVED_ACTION:
    case LOAD_GTINS_LIST_BY_PACKAGE_ACTION:
    case LOAD_GTINS_LIST_FOR_VALIDATION_ACTION:
    case LOAD_ACTIVE_KZ_DICTIONARY_ACTION:
    case LOAD_PRODUCTS_CATEGORIES_ACTION:
    case LOAD_DICTIONARY_COUNTRIES_ACTION: {
      return { ...state, loading: state.loading + 1 };
    }

    case LOAD_KATO_DICTIONARY_ACTION_FAILED:
    case LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_FAILED_ACTION:
    case LOAD_TNVEDS_FAILED_ACTION:
    case LOAD_DICTIONARY_TM_FAILED_ACTION:
    case LOAD_DICTIONARY_COUNTRY_FAILED_ACTION:
    case LOAD_DICTIONARY_OPF_FAILED_ACTION:
    case LOAD_DICTIONARY_POSITION_FAILED_ACTION:
    case LOAD_DICTIONARY_SUBJECT_FAILED_ACTION:
    case LOAD_DICTIONARY_DEPARTMENT_FAILED_ACTION:
    case LOAD_DICTIONARY_CHANNELS_GS1_FAILED_ACTION:
    case LOAD_DICTIONARY_PROJECTS_GS1_FAILED_ACTION:
    case LOAD_DICTIONARY_KNOWLEDGE_GS1_FAILED_ACTION:
    case LOAD_DICTIONARY_SALES_GS1_FAILED_ACTION:
    case LOAD_DICTIONARY_ORG_PROFILES_FAILED_ACTION:
    case LOAD_DICTIONARY_SECTOR_FAILED_ACTION:
    case LOAD_DICTIONARY_GCP_PRODUCTS_FAILED_ACTION:
    case LOAD_DICTIONARY_OBTAINING_METHOD_FAILED_ACTION:
    case LOAD_DICTIONARY_EDO_PROVIDER_FAILED_ACTION:
    case LOAD_DICTIONARY_OKPD_FAILED_ACTION:
    case LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_FAILED_ACTION:
    case LOAD_DICTIONARY_PACKAGE_MATERIAL_FAILED_ACTION:
    case LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_FAILED_ACTION:
    case LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_FAILED_ACTION:
    case LOAD_DICTIONARY_PACKAGE_TYPE_FAILED_ACTION:
    case LOAD_DICTIONARY_PALLET_TYPE_FAILED_ACTION:
    case LOAD_DICTIONARY_OKVED_FAILED_ACTION:
    case LOAD_ACTIVE_KZ_DICTIONARY_FAILED_ACTION:
    case LOAD_PRODUCTS_CATEGORIES_FAILED_ACTION:
    case LOAD_DICTIONARY_COUNTRIES_FAILED_ACTION: {
      return { ...state, loading: subtractNotNegative(state.loading) };
    }

    case LOAD_ACTIVE_KZ_DICTIONARY_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        activeKzDictionary: action.data,
      };
    }

    case LOAD_DICTIONARY_PACKAGE_LETTER_NUMERAL_CODE_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryPackageLetterNumeralCode: action.data,
      };
    }

    case LOAD_TNVEDS_SUCCEEDED_ACTION: {
      return {
        ...state,
        dictionaryTnveds: action.tnveds,
        loading: subtractNotNegative(state.loading),
      };
    }
    case LOAD_DICTIONARY_TM_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryTm: action.data,
      };
    }
    case LOAD_DICTIONARY_COUNTRY_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryCountry: action.data.slice(0, 300),
      };
    }
    case LOAD_DICTIONARY_OPF_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryOpf: action.data,
      };
    }
    case LOAD_DICTIONARY_POSITION_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryPosition: action.data,
      };
    }
    case LOAD_DICTIONARY_SUBJECT_SUCCEEDED_ACTION: {
      let subjects = parseAddresses(action.data);
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionarySubject: subjects,
      };
    }
    case LOAD_DICTIONARY_DEPARTMENT_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryDepartment: action.data,
      };
    }
    case LOAD_DICTIONARY_KNOWLEDGE_GS1_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryKnowledge: action.data,
      };
    }
    case LOAD_DICTIONARY_CHANNELS_GS1_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryChannels: action.data,
      };
    }
    case LOAD_DICTIONARY_PROJECTS_GS1_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryProjects: action.data,
      };
    }
    case LOAD_DICTIONARY_SALES_GS1_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionarySales: action.data,
      };
    }
    case LOAD_DICTIONARY_ORG_PROFILES_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryOrgProfiles: action.data,
      };
    }
    case LOAD_DICTIONARY_SECTOR_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionarySectors: action.data,
      };
    }
    case LOAD_DICTIONARY_GCP_PRODUCTS_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryGcpProducts: action.data,
      };
    }
    case LOAD_DICTIONARY_OBTAINING_METHOD_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryObtainingMethods: action.data,
      };
    }
    case LOAD_DICTIONARY_EDO_PROVIDER_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryEdoProviders: action.data,
      };
    }
    case LOAD_DICTIONARY_OKPD_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryOkpd: action.data,
      };
    }
    case LOAD_DICTIONARY_SHOWBOX_DISPLAY_SIGN_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryShowboxDisplaySigns: action.data,
      };
    }
    case LOAD_DICTIONARY_PACKAGE_TYPE_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryPackageTypes: action.data,
      };
    }
    case LOAD_DICTIONARY_PACKAGE_MATERIAL_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryPackageMaterials: action.data,
      };
    }
    case LOAD_DICTIONARY_PACKAGE_TYPES_CAPPING_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryPackageTypesCapping: action.data,
      };
    }
    case LOAD_DICTIONARY_PACKAGE_MATERIALS_CAPPING_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryPackageMaterialsCapping: action.data,
      };
    }
    case LOAD_DICTIONARY_PALLET_TYPE_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryPalletTypes: action.data,
      };
    }
    case LOAD_DICTIONARY_OKVED_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryOkved: action.data,
      };
    }
    case LOAD_NOTIFICATION_SUCCEEDED_ACTION: {
      // const newNotification = map(action.data, (d) =>
      //   assign(d, { sectionName: getMonth(d.date) }),
      // );
      return {
        ...state,
        notifications: uniqBy(
          concat([], state.notifications, action.data),
          'id',
        ),
        notificationsTotalCount: action.totalCount,
      };
    }
    case LOAD_GTINS_LIST_BY_PACKAGE_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        gtinsListByPackageType: action.data,
        gtinsListWasLoaded: true,
      };
    }
    case LOAD_GTINS_LIST_BY_PACKAGE_FAILED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        gtinsListByPackageType: [],
        gtinsListWasLoaded: true,
      };
    }
    case CLEAN_NOTIFICATION_ACTION: {
      return {
        ...state,
        notifications: [],
        notificationsTotalCount: 0,
      };
    }
    case LOAD_GTINS_LIST_FOR_VALIDATION_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        gtinsListForValidation: action.data,
        gtinsValidationListWasLoaded: true,
      };
    }
    case LOAD_GTINS_LIST_FOR_VALIDATION_FAILED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        gtinsListForValidation: [],
        gtinsValidationListWasLoaded: true,
      };
    }

    case MARK_NOTIFICATION_SUCCESSED_ACTION: {
      return {
        ...state,
        notifications: !!action.ids
          ? map(state.notifications, (d) => {
            if (includes(action.ids, d.id)) {
              set(d, 'isRead', true);
            }
            return d;
          })
          : map(state.notifications, (d) => {
            set(d, 'isRead', true);
            return d;
          }),
        notificationRecentInfo: {
          ...state.notificationRecentInfo,
          newCount: !!action.ids ? state.notificationRecentInfo.newCount - 1 : 0,
        },
      };
    }

    case LOAD_PRODUCTS_CATEGORIES_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        productsCategories: action.data,
      };
    }

    case LOAD_KATO_DICTIONARY_ACTION_SUCCEEDED: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        katoDictionary: action.data,
      };
    }
    case LOAD_DICTIONARY_COUNTRIES_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        dictionaryCountries: action.data,
      };
    }
    case LOAD_NOTIFICATION_RECENT_SUCCEEDED_ACTION: {
      return {
        ...state,
        notificationRecentInfo: action.data,
      };
    }
    case CLEAR_ACTIVE_KZ_DICTIONARY_ACTION: {
      return {
        ...state,
        activeKzDictionary: [],
      };
    }

    default:
      return { ...state };
  }
};
