import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
// import { Element } from 'react-scroll';
import { MediaItem } from './itemElement';
import MediaContentView from './MediaContentView';
import { Button, MuiThemeProvider, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { IconErrorRed } from '../../../common/icons/customIcons';
import {
  AddressButton,
  AdresFields,
  AdresTitle,
  AdresTitleH3,
  AdresTitleMedia,
  CompanyBlock,
  CompanyBlockMedia,
  PackageTitleHolder,
  ProductTabBtn,
  TextButtonTheme,
  TextTooltip,
  CompanyBlockMediaTitle,
  CompanyBlockMediaContent,
} from '../../../common/styles/Common.styled';

const _getErrorsAdapter = (moderateErrors, images, flagField) => {
  let errors = {};
  const imageErrors = _.filter(moderateErrors, { type: 'image' });
  if (!imageErrors) return errors;
  _.forEach(imageErrors, (item) => {
    const { type, gtin, url, comment } = item.data;
    const image = _.find(images, { type, gtin, url });
    if (!!image) {
      errors[image[flagField]] = comment;
    }
  });
  return errors;
};

const _getAuthorAdapter = (moderateErrors, images, flagField) => {
  let errorAuthor = {};
  const imageErrors = _.filter(moderateErrors, { type: 'image' });
  if (!imageErrors) return errorAuthor;
  _.forEach(imageErrors, (item) => {
    const { type, gtin, url, author } = item.data;
    const image = _.find(images, { type, gtin, url });
    if (!!image) {
      errorAuthor[image[flagField]] = author;
    }
  });
  return errorAuthor;
};

const getErrors = (...args) => _getErrorsAdapter(...args, 'id');
const getPreviewErrors = (...args) => _getErrorsAdapter(...args, 'url');
const getAuthorErrors = (...args) => _getAuthorAdapter(...args, 'id');
const getPreviewAuthorErrors = (...args) => _getAuthorAdapter(...args, 'url');

class MediaContent extends React.Component {
  constructor(props) {
    super(props);
    const images = this.initialImages(this.props.productData.images);
    const index = this.setIndexForDownloadImages(this.props.productData.images);

    this.state = {
      currentIndex: index,
      data: {
        images: images,
      },
      errors:
        _.isNil(this.props.moderateErrors) ||
          _.isEmpty(this.props.productData.images)
          ? {}
          : getErrors(this.props.moderateErrors, images),
      author: _.isNil(this.props.moderateErrors) ||
        _.isEmpty(this.props.productData.images)
        ? {}
        : getAuthorErrors(this.props.moderateErrors, images),
      contentShow: true,
    };

    this.checkRequiredFields(this.state.data.images);
    this.props.updateData('images', this.state.data.images);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (_.isEmpty(prevState.errors) && !_.isEmpty(nextProps.moderateErrors)) {
      return {
        errors: getErrors(nextProps.moderateErrors, prevState.data.images),
      };
    }
    if (_.isEmpty(prevState.author) && !_.isEmpty(nextProps.moderateErrors)) {
      return {
        author: getAuthorErrors(nextProps.moderateErrors, prevState.data.images),
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const s = _.size(this.props.packaging) < _.size(prevProps.packaging);
    // проверка на то изменился ли какой-либо GTIN из упаковки?
    const equal = _.isEqualWith(
      this.props.packaging,
      prevProps.packaging,
      (obj, other) => {
        return _.reduce(
          obj,
          (sum, value, key) => {
            return sum || value.gtin !== _.get(other, `${[key]}.gtin`);
          },
          false,
        );
      },
    );
    const isChangedImages = !_.isEqual(this.state.data.images, prevState.data.images);

    let images = this.state.data.images;
    let index = this.state.currentIndex;
    if (s || equal || isChangedImages) {
      images = this.initialImages(images);
      index = this.setIndexForDownloadImages(images);
      this.setState((prevState) => ({ data: { ...prevState.data, images }, currentIndex: index }));
    }
    this.checkRequiredFields(images);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!_.isEqual(this.state, nextState)];
    const nameProps = ['packaging', 'preview', 'locale', 'moderateErrors', 'isFullPhotoContent'];
    _.forEach(nameProps, (i) =>
      equal.push(!_.isEqual(this.props[i], nextProps[i])),
    );
    return _.some(equal);
  }

  generateImages = (images, packaging) => {
    const gtinTradeUnit = _.defaultTo(
      _.find(packaging, { level: 'trade-unit' }),
      { gtin: '' },
    ).gtin;

    // костыли
    const { type } = this.props;
    const isNotConsumer = _.includes(['group', 'transport'], type);
    const isGroup = type === 'group';
    const gtinInnerPack = _.defaultTo(
      _.find(packaging, { level: 'inner-pack' }),
      { gtin: '' },
    ).gtin;
    const gtinBoxPack = _.defaultTo(_.find(packaging, { level: 'box' }), {
      gtin: '',
    }).gtin;

    // return _.map(images, (image) => {
    //   const gtin = image.gtin;
    //   const packageMedia = _.defaultTo(_.find(packaging, { gtin: gtin }), {
    //     gtin: isNotConsumer
    //       ? isGroup
    //         ? gtinInnerPack
    //         : gtinBoxPack
    //       : gtinTradeUnit,
    //   });
    //   return {
    //     id: image.id || _.uniqueId('imagesId_'),
    //     type: image.type,
    //     url: image.url,
    //     gtin: packageMedia.gtin,
    //     disabled: !!image.disabled,
    //     hasWatermark: image.hasWatermark,
    //   };
    // });

    return _.map(this.props.photoTypes, (item) => {
      const matchingImage = images.find(image => image.type === item.id);
      let gtin, id, url, disabled, hasWatermark;
      if (matchingImage) {
        gtin = matchingImage.gtin;
        id = matchingImage.id || _.uniqueId('imagesId_');
        url = matchingImage.url;
        disabled = !!matchingImage.disabled;
        hasWatermark = !_.isNil(matchingImage.hasWatermark) ? matchingImage.hasWatermark : '';
      } else {
        gtin = '';
        id = _.uniqueId('imagesId_');
        url = null;
        disabled = false;
        hasWatermark = '';
      }

      const packageMedia = _.defaultTo(_.find(packaging, { gtin: gtin }), {
        gtin: isNotConsumer
          ? isGroup
            ? gtinInnerPack
            : gtinBoxPack
          : gtinTradeUnit,
      });
      return {
        id: id,
        type: item.id,
        url: url,
        gtin: packageMedia.gtin,
        disabled: disabled,
        hasWatermark: hasWatermark,
      };
    })
  };

  /**
   * EACNC-256
   *
   * @param {*} isFirst флаг, который отвечает за выставляемый type. В случае, если инициализируется начальное поле, то передаётся true и всегда ставится
   *      лицевая сторона "A1N1". Все последующие поля добавленные в ручную не должны иметь типа(приравниваем к пустой строке)
   */
  getInitItem = (packaging, isFirst) => {
    const gtinTradeUnit = _.defaultTo(
      _.find(packaging, { level: 'trade-unit' }),
      { gtin: '' },
    ).gtin;

    // костыли
    const { type } = this.props;
    const isNotConsumer = _.includes(['group', 'transport'], type);
    const isGroup = type === 'group';
    const gtinInnerPack = _.defaultTo(
      _.find(packaging, { level: 'inner-pack' }),
      { gtin: '' },
    ).gtin;
    const gtinBoxPack = _.defaultTo(_.find(packaging, { level: 'box' }), {
      gtin: '',
    }).gtin;
    // const imageType = isFirst ? 'A1N1' : '';

    // return {
    //   id: _.uniqueId('imagesId_'),
    //   type: imageType,
    //   gtin: isNotConsumer
    //     ? isGroup
    //       ? gtinInnerPack
    //       : gtinBoxPack
    //     : gtinTradeUnit,
    //   url: '',
    //   disabled: false,
    // };

    return this.props.photoTypes.map(item => {
      return {
        id: _.uniqueId('imagesId_'),
        type: item.id,
        gtin: isNotConsumer
          ? isGroup
            ? gtinInnerPack
            : gtinBoxPack
          : gtinTradeUnit,
        url: null,
        disabled: false,
      }
    })
  };

  initialImages = (images) => {
    return _.isEmpty(images)
      ? this.getInitItem(this.props.packaging, true)
      : this.generateImages(images, this.props.packaging);
  };

  setIndexForDownloadImages = (images) => {
    if (_.isEmpty(images)) return 0;
    const usedImageTypes = _.compact(images.map((image) => {
      if (!!image.url) {
        return image.type;
      } else return null
    }));
    return _.findIndex(this.props.photoTypes, item => !_.includes(usedImageTypes, item.id));
  };

  onClickHide = () => {
    this.setState({ contentShow: !this.state.contentShow });
  };

  addItem = () => {
    const images = _.cloneDeep(this.state.data.images);
    images.push(this.getInitItem(this.props.packaging, false));
    this.setState({ data: { images } }, this.updateData);
  };

  deleteItem = (id) => {
    const images = _.reject(this.state.data.images, { id: id });
    this.setState({ data: { images } }, this.updateData);
  };

  updateItem = (id, fieldData) => {
    let images = _.cloneDeep(this.state.data.images);
    _.forEach(images, (v, k) => {
      if (v.id === id) images[k] = _.assign({}, v, fieldData);
    });
    this.setState({ data: { images } }, this.updateData);
  };

  updateData = () => this.props.updateData('images', this.state.data.images);

  checkRequiredFields = (images) => {
    let errors = {};
    _.forEach(images, (item) => {
      const error = _.isEmpty(item.gtin) && !!item.url;
      if (error) {
        errors[item.id] = error;
      }
    });
    this.props.handleErrors('images', errors, null);
  };

  setCurrentIndex = (index) => {
    return this.setState({ currentIndex: index });
  }

  render() {
    const { t, type, isCatalogCard, isFullPhotoContent } = this.props;
    const { data, currentIndex } = this.state;
    const { images } = data;
    const countItems = _.size(this.state.data.images);
    const isErrors = !_.isEmpty(this.state.errors);
    // const canAddNew = _.every(
    //   this.state.data.images,
    //   (item) => !_.isEmpty(item.type) && !!item.url,
    // );    

    return (
      <CompanyBlockMedia edit open={isFullPhotoContent}>
        <CompanyBlockMediaTitle>
          {/* <MuiThemeProvider theme={ProductTabBtn}> */}
          {/* <Button onClick={this.onClickHide}>
                {this.state.contentShow ? (
                  <IconDropDownGrey />
                ) : (
                  <IconDropDownGrey style={{ transform: 'rotate(180deg)' }} />
                )}
              </Button> */}
          <AdresTitleMedia>{t('Фотоконтент')}</AdresTitleMedia>
          {/* </MuiThemeProvider> */}
          <div>
            {isErrors && (
              <MuiThemeProvider theme={TextTooltip}>
                <Tooltip
                  placement="bottom-end"
                  title={t('По фотоконтенту есть комментарии модератора')}
                >
                  <span>
                    <IconErrorRed width={20} height={20} style={{ margin: 2 }} />
                  </span>
                </Tooltip>
              </MuiThemeProvider>
            )}
            {!isCatalogCard && this.state.contentShow && !this.props.preview && (
              <MuiThemeProvider theme={TextButtonTheme}>
                <Button
                  onClick={this.props.togglePhotoContent}
                  color={'secondary'}
                >
                  {isFullPhotoContent
                    ? t('Свернуть')
                    : t('Развернуть')}
                </Button>
              </MuiThemeProvider>
            )}
          </div>
        </CompanyBlockMediaTitle>
        <CompanyBlockMediaContent isFullPhotoContent={isFullPhotoContent}>
          <AdresFields>
            {this.state.contentShow && (
              <React.Fragment>
                {this.props.preview ? (
                  <MediaContentView
                    setCurrentIndex={this.setCurrentIndex}
                    // images={this.props.productData.images}
                    images={isCatalogCard
                      ? this.props.productData.images
                      : this.state.data.images}
                    identifier={this.props.productData.identifier}
                    errors={getPreviewErrors(
                      this.props.moderateErrors,
                      this.props.productData.images,
                    )}
                    author={getPreviewAuthorErrors(
                      this.props.moderateErrors,
                      this.props.productData.images,
                    )}
                    isTechCard={this.props.isTechCard}
                    isUnmarkable={this.props.isUnmarkable}
                    isFullPhotoContent={isFullPhotoContent}
                  />
                ) : (
                  <React.Fragment>
                    {images && (
                      //<Element key={images[currentIndex]?.id} name={`id_${images[currentIndex]?.id}`}>
                      <MediaItem
                        allowDelete={countItems > 1}
                        data={images[currentIndex]}
                        updateItem={this.updateItem}
                        deleteItem={this.deleteItem}
                        addItem={this.addItem}
                        packaging={this.props.packaging}
                        // error={this.state.errors[images[currentIndex]?.id]}
                        errors={this.state.errors}
                        authors={this.state.author}
                        status={this.props.productData.status}
                        disableImg={images[currentIndex]?.disabled}
                        type={type}
                        images={isCatalogCard
                          ? this.props.productData.images
                          : this.state.data.images}
                        isCatalogCard={isCatalogCard}
                        setCurrentIndex={this.setCurrentIndex}
                        isTechCard={this.props.isTechCard}
                        isUnmarkable={this.props.isUnmarkable}
                        isFullPhotoContent={isFullPhotoContent}
                        focusDownloadIndex={this.state.currentIndex}
                      />
                      //</Element>
                    )}
                    {/* <MuiThemeProvider theme={AddressButton}>
                    <Button
                      color={'secondary'}
                      onClick={this.addItem}
                      disabled={!canAddNew || this.props.isCatalogCard}>
                      <IconPlus style={{ marginRight: '15px' }} />
                      {t('Добавить')}
                    </Button>
                  </MuiThemeProvider> */}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </AdresFields>
        </CompanyBlockMediaContent>
      </CompanyBlockMedia>
    );
  }
}

const mapStateToProps = (state) => ({
  productData: state.productReducer.data,
  moderateErrors: state.productReducer.moderateErrors,
  locale: state.profileReducer.locale,
  photoTypes: state.productReducer.photoTypesDictionary,
});

MediaContent.propTypes = {
  handleErrors: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  productData: PropTypes.object.isRequired,
  moderateErrors: PropTypes.array.isRequired,
  packaging: PropTypes.array.isRequired,
  preview: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  status: PropTypes.string,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(MediaContent);
