import * as React from "react";
import * as PropTypes from "prop-types";
import moment from "moment-timezone";
import { series } from "async";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { throwNotification } from "../../../../common/structure";
import {
    config,
    // isKZ,
    selectLang
} from "../../../../config";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    MuiThemeProvider,
    Select
} from "@material-ui/core";

import {
    Autocomplete,
    CertificateSelectModal,
    ConfirmDialog,
    CustomInput,
    FloatingButton,
    FloatingButtonView,
    PasswordInput,
    PhoneInput,
    Preloader,
    SimpleTooltip,
} from "../../../../components";

import { BlueBtn, ProfileDivider, ProfileFooterButtonLine, ProfileMemberDetailsCont, ProfileTabCont, WhiteBtn } from "../../Profile.styled";
import {
    CheckBoxDefault,
    InputContainerMail,
    InputIcon,
    ProfileAutocomplete,
    StickyBtns,
    defaultSelectTheme,
} from "../../../../common/styles/Common.styled";
import {
    ConfirmEmailButton,
    InputInline,
    PasswordErrorsWarning,
    ProfileCenter,
    ProfileFooter,
    ProfileFooterButton,
    ProfileH3,
    ProfileInput,
    ProfileInputCompany,
    ProfileInputContainerMail,
    ProfileInputContainerMailPhone,
    ProfileInputInline,
    ProfileInputInlineCompany,
    ProfileInputInlineUser,
    ProfileInputUserCreated,
    ProfileInputUserEmail,
    ProfileInputUserPhone,
    ProfileInputUserWrap,
    ProfileLeft,
    ProfileMain,
    ProfileRight,
    ProfileWrap,
    InputEmail,
    InputPhone,
    InputCreated,
    ProfileInputIcon,
    ProfileInputCompanyINN,
} from "./MemberDetails.styled";
import { validateEmail, validatePhone } from "../../../../common/utils/Validation";
import {
    IconCheck,
    IconCheckCircle,
    IconCircle,
    IconDropDown,
    IconFloatSave,
    IconValid,
    IconErrorSmall,
    IconCheckMenu,
    IconCheckbox,
} from "../../../../common/icons/customIcons";

import CertificateView from "./certificateView";
import ConfirmEmailModal from "./modal/ConfirmEmailModal";
import * as selectors from "../../ducks/Profile/Profile.selectors";
import { loadCertificates, loadProfileInfo, saveInfo, savePassword } from "../../ducks/Profile/Profile.actions";
import {
    loadDictionaryOkved,
    loadDictionaryOpf,
    loadDictionaryCountries,
} from "../../../../common/catalog/Catalog.action";
import { fullTypes } from "../../../../common/constants/Constants";
import { client } from "../../../../api/Client";

function renderSuggestion(suggestion, { query, isHighlighted }) {
    return (
        <MenuItem disableGutters={true} selected={isHighlighted} component="div">
            {suggestion.text}
        </MenuItem>
    );
}

const getDefaultTo = (obj, path) => _.defaultTo(_.get(obj, path, ''), '');

const validatePassword = (password) => {
    const type = {
        minimum8Chars: /^.{8,}$/,
        containsNumbers: /^.*[0-9]+.*$/,
        containsUpperCaseLetters: /^.*[A-Z]+.*$/,
        containsLowerCaseLetters: /^.*[a-z]+.*$/,
        withoutSpecialChars: /[!@#$%^&*]/
    };
    const arr = _.reduce(type, (result, value) => {
        result.push(value.test(password));
        return result;
    }, []);
    return _.every(arr);
};

const gcpValidationForSearch = (gcp) => {
    return _.includes([4, 5, 6, 7, 8, 9], _.size(_.toString(gcp)));
};

class MemberDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            info: _.cloneDeep(this.props.info),
            selectCertModal: false,
            showChangeEmailModal: false,
            confirmEmailModal: false,
            certificate: {},
            password: {
                password: '',
                confirmPassword: ''
            },
            suggestions: this.props.dictionaryOkved,
            suggestionsLastValue: '',
            showFloatingButton: true,
            preloader: true,
            nonResidentialGcps: !_.isEmpty(this.props.info.company.nonResidentialGCPs)
                ? this.props.info.company.nonResidentialGCPs.join('\n')
                : '',
            gcpError: '',
        };
        this.onScrollThrottle = _.throttle(this.onScroll, config.throttleScroll);

        const tz = moment.tz.guess();
        moment.locale(selectLang(config.lang));
        moment.tz.setDefault(tz);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScrollThrottle);
        series([
            (cbk) => _.isEmpty(this.props.dictionaryOkved) ? this.props.onLoadDictionaryOkved(null, cbk) : cbk(null),
            (cbk) => _.isEmpty(this.props.opfDictionary) ? this.props.loadOpfDictionary(cbk) : cbk(null),
            (cbk) => this.props.onLoadProfileInfo(cbk),
            (cbk) => _.isEmpty(this.props.dictionaryCountries) ? this.props.loadDictionaryCountries(cbk) : cbk(null),
        ]).finally(() => this.setState({ preloader: false }));
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.info, this.props.info)) {
            this.setState({
                info: _.cloneDeep(this.props.info),
                nonResidentialGcps: !_.isEmpty(this.props.info.company.nonResidentialGCPs)
                    ? this.props.info.company.nonResidentialGCPs.join('\n')
                    : '',
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollThrottle);
    }

    onScroll = () => {
        const pageY = window.pageYOffset || document.documentElement.scrollTop;
        const clientHeight = document.documentElement.clientHeight;
        const offsetHeight = document.documentElement.offsetHeight;
        if ((offsetHeight - clientHeight - 250) > pageY) {
            if (!this.state.showFloatingButton) {
                this.setState({ showFloatingButton: true })
            }
        } else {
            if (this.state.showFloatingButton) {
                this.setState({ showFloatingButton: false })
            }
        }
    };

    onNewCertClick = () => {
        this.setState({ selectCertModal: !this.state.selectCertModal })
    };

    onSelectCertificate = (value) => {
        this.setState({ certificate: value })
    };

    handleChange = (name, value) => {
        switch (name) {
            case 'password': {
                this.setState({ password: { ...this.state.password, password: value } });
                break;
            }
            case 'confirmPassword': {
                this.setState({ password: { ...this.state.password, confirmPassword: value } });
                break;
            }
            case 'certificate': {
                this.setState({ selectCertModal: false });
                const certificate = {
                    certificate: value.certificate,
                    certUid: value.certUid,
                    fingerprint: value.fingerprint,
                    userFullName: `${value.lastName} ${value.firstName}`,
                    owner: value.owner,
                    issuedBy: value.issuedBy,
                    companyName: value.organization,
                    inn: value.inn,
                    userPosition: '',
                    validDateFrom: moment(value.startDate, "DD-MM-YYYY").toISOString(true),
                    validDateTo: moment(value.expireDate, "DD-MM-YYYY").toISOString(true),
                    isValid: value.isValid
                };
                this.handleChangeInfo('certificate', name, certificate);
                break;
            }
            case 'isMarked': {
                this.handleChangeInfo('company', 'isMarked', !this.state.info.company.isMarked);
                break;
            }
            case 'opf': {
                this.handleChangeInfo('company', 'opf', value);
                break;
            }
            case 'isTechCardSupport': {
                this.handleChangeInfo('user', 'isTechCardSupport', !this.state.info.user.isTechCardSupport);
                break;
            }
            case 'country': {
                this.handleChangeInfo('company', 'country', value);
                break;
            }
            default:
                this.handleChangeInfo('user', name, value);
                break;
        }
    };

    handleChangeInfo(section, name, value) {
        let info = _.cloneDeep(this.state.info);
        if (name === 'certificate') {
            info[section] = value;
        } else {
            info[section][name] = value;
        }
        this.setState({ info: info }, () => {
            if (name === 'certificate') {
                this.onSaveInfoButton();
            }
        });
    }

    onSavePassword = () => {
        if (!validatePassword(this.state.password.password)) return;
        this.props.onSavePassword(this.state.password.password);
        this.setState({ password: { password: '', confirmPassword: '' } });
    };

    onSaveInfoButton = () => {
        if (this.state.nonResidentialGcps &&
            !_.isEqual(this.state.nonResidentialGcps, this.props.info.company.nonResidentialGCPs.join('\n'))) {
            const gcps = _.compact(
                this.state.nonResidentialGcps.split(new RegExp('[\\n;,\\s+]', 'g')),
            );
            if (!this.validateGcps(gcps)) return;
        }
        this.props.onSaveInfo(this.props.info, this.state.info, (errors) => {
            if (errors) {
                if (_.get(errors, 'errors.type') === 'certificate') {
                    this.setState(prevState => ({ info: { ...prevState.info, certificate: this.props.info.certificate } }));
                }
            } else {
                if (!_.isEqual(this.state.info.user.email, this.props.info.user.email)) {
                    this.setState({ showChangeEmailModal: true });
                }
            }
        });
    };

    getSuggestionValue = (suggestion) => {
        let info = _.cloneDeep(this.state.info);
        info['company']['okved2'] = suggestion;
        this.setState({ info: info });
        return `${suggestion.text}`;
    };

    handleSuggestionsFetchRequested = (value) => {
        const inputValue = _.toLower(_.deburr(_.trim(value)));
        if (!inputValue || this.state.suggestionsLastValue === inputValue) return;

        this.props.onLoadDictionaryOkved(inputValue, (error, suggestions) => {
            this.setState({
                suggestionsLastValue: inputValue,
                suggestions: suggestions,
            });
        })
    };

    handleSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    showCertificateUpdate = () => {
        const certificate = this.props.info.certificate;
        return !(!this.props.userInfo.isMainAccountUser || (!!certificate && certificate.isValid))
    };

    confirmEmailModal = (e, reload) => {
        if (reload) {
            this.props.onLoadProfileInfo();
        }
        this.setState(prevState => ({ confirmEmailModal: !prevState.confirmEmailModal }))
    };

    // showPasswordChange = () => {
    //     const { t } = this.props;
    //     const { password, confirmPassword } = this.state.password;
    //     const isELK = this.props.info.user.isELK
    //     const passwordValid = validatePassword(password);
    //     const labelPasswordError = t('Пароль должен содержать не менее 8 символов, строчные и прописные буквы латинского алфавита, цифры и хотя бы один из спецсимволов(!@#$%^&*)');
    //     const disabledChangePassword = (!((password && confirmPassword) && password === confirmPassword)) || !passwordValid;

    //     if (isELK === false) {
    //         return (
    //             <React.Fragment>
    //                 <ProfileDivider />
    //                 <ProfileH3>{t('Смена пароля')}</ProfileH3>
    //                 <PasswordInput
    //                     id="password"
    //                     name="password"
    //                     placeholder={t('Введите новый пароль')}
    //                     label={t('Введите новый пароль')}
    //                     onChange={(value) => this.handleChange('password', value)}
    //                     value={password}
    //                     shrink={true}
    //                     autoComplete="off"
    //                 />
    //                 {(password && !passwordValid) &&
    //                     <PasswordErrorsWarning>{labelPasswordError}</PasswordErrorsWarning>
    //                 }

    //                 <PasswordInput
    //                     id="confirmPassword"
    //                     name="confirmPassword"
    //                     placeholder={t('Повторите пароль')}
    //                     label={!!password && password !== confirmPassword
    //                         ? t('Пароли не совпадают')
    //                         : t('Повторите пароль')
    //                     }
    //                     value={_.defaultTo(confirmPassword, '')}
    //                     onChange={(value) => this.handleChange('confirmPassword', value)}
    //                     error={!!(password && confirmPassword) && (password !== confirmPassword)}
    //                     shrink={true}
    //                     autoComplete="off"
    //                 />
    //                 <ProfileDivider />
    //                 <Button
    //                     disabled={disabledChangePassword}
    //                     onClick={this.onSavePassword}
    //                 >
    //                     {t('Изменить пароль')}
    //                 </Button>
    //             </React.Fragment>
    //         )
    //     }
    //     else return null
    // }

    onGetApiKey = () => {
        //this.props.onGetApiKey();
        //const email = this.props.info.user.email
        //const message = 'APIKEY направлен на Ваш электронный адрес'
        //this.props.throwNotification(message, 'success');
        return client().instance.post(`/profile/send_api_key`);

    }

    sendApiKey = () => {
        const message = 'APIKEY направлен на Ваш электронный адрес'
        this.onGetApiKey()
            .then(() => {
                this.props.throwNotification(message, 'success');
            })
            .catch((errors) => {
                const err = _.get(errors, 'response.data.errors');
                _.forEach(err, (i) => this.props.throwError(i));
            });
    }

    getRoles = () => {
        const roles = [];
        this.state.info.company.roles.forEach((item) => {
            const role = _.find(fullTypes, { value: item });
            if (role) {
                roles.push(getDefaultTo(role, 'title'))
            }
        });
        return _.uniq(roles).join(', ');
    }

    makeGcpArray = (gcpString) => {
        return _.compact(gcpString.split(new RegExp('[\\n;,\\s+]', 'g')),)
    };

    onGcpChange = (value) => {
        value = value.toString();
        value = value.replace(/[^\d|^\n|^;|^,|^\s]/, '');
        value = value.allReplace({
            ';;': ';',
            ';,': ';',
            '; ': ';',
            ',,': ',',
            ', ': ',',
            ',;': ',',
            '  ': ' ',
            ' ,': ' ',
            ' ;': ' ',
        });

        let values = value.split(new RegExp('[\\n;,\\s+]', 'g'));
        if (values[values.length - 1].length > 9) {
            const v = value[value.length - 1];
            value = value.substring(0, value.length - 1) + '\n' + _.trimStart(v);
        }

        this.setState({
            nonResidentialGcps: _.trimStart(value),
            gcpError: '',
        });
        this.handleChangeInfo('company', 'nonResidentialGCPs', this.makeGcpArray(_.trimStart(value)));
    }

    validateGcps = (gcpsArr) => {
        const { t } = this.props;
        let valid = true;
        let error = '';

        if (!_.isEmpty(gcpsArr)) {
            _.forEach(gcpsArr, (gcp) => {
                if (!gcpValidationForSearch(gcp)) {
                    error = `${t('Недостаточно символов')}. \n ${t(
                        'Код состоит от 4 до 9 цифр',
                    )}`;
                    valid = false;
                }
            });
            this.setState({ gcpError: error });
        }
        return valid;
    };

    render() {
        const { validationErrors, info, t } = this.props;
        // const fnUp = _.flow([t, _.upperCase]);
        const { certificate } = info;
        const { company, user } = this.state.info;
        // const { password, confirmPassword } = this.state.password;
        const isELK = this.props.info.user.isELK

        const isNoChange = _.isEqual(this.props.info, this.state.info);
        const isElkUser = _.get(this.props, 'userInfo.isElkUser');
        const opfProps = _.find(this.props.opfDictionary, { id: getDefaultTo(this.props, 'info.company.opf') });
        const valueOpf = getDefaultTo(company, 'opf');
        const valueOkved2Code = _.get(company, 'okved2.code');

        const valueEmail = getDefaultTo(user, 'email');
        const emailValid = isElkUser || validateEmail(valueEmail);
        const validationErrorEmail = _.has(validationErrors, 'email');

        const valuePhone = getDefaultTo(user, 'phone');
        const phoneValid = validatePhone(valuePhone);

        const countriesProps = _.find(this.props.dictionaryCountries, { id: getDefaultTo(this.props, 'info.company.country') });
        const valueCountry = getDefaultTo(company, 'country');

        // const passwordValid = validatePassword(password);
        // const labelPasswordError = t('Пароль должен содержать не менее 8 символов, иметь маленькие и большие буквы, цифры и хотя бы один из спецсимволов (!@#$%^&*)');
        // const disabledChangePassword = (!((password && confirmPassword) && password === confirmPassword)) || !passwordValid;

        if (this.state.preloader) {
            return <Preloader isOpen text={t('Загрузка данных профиля')} />;
        }

        return (
            <React.Fragment>
            <ProfileWrap>
                <ProfileMemberDetailsCont>
                    <ProfileMain>
                        <ProfileCenter>
                            {/* {!_.isNil(certificate) && <CertificateView cert={certificate} /> }
                            {!_.isNil(certificate) && <ProfileDivider />} */}

                            {!_.isNil(certificate) && <CertificateView cert={certificate} />}
                            {/* {!_.isNil(certificate) && <ProfileDivider />} */}
                            <ProfileH3>{t('Организация участника')}</ProfileH3>
                            <InputContainerMail>
                                <CustomInput
                                    disabled
                                    id="name"
                                    name="name"
                                    label={t('Полное наименование организации')}
                                    value={getDefaultTo(company, 'name')}
                                    type="text"
                                />
                                {!!getDefaultTo(company, 'name') && (
                                    <InputIcon>
                                        <IconCheckMenu />
                                    </InputIcon>
                                )}
                            </InputContainerMail>
                            {/* <CustomInput
                                disabled
                                id="nameShort"
                                name="nameShort"
                                label={t('Краткое наименование')}
                                value={getDefaultTo(company, 'nameShort')}
                                type="text"
                            /> */}
                            {company.isResident !== false && (
                                <MuiThemeProvider theme={defaultSelectTheme}>
                                    <FormControl fullWidth={true}>
                                        <InputLabel disabled={!!opfProps} htmlFor="opf-select">
                                            {t('Организационно-правовая форма')}
                                        </InputLabel>
                                        <Select
                                            required
                                            disabled={!!opfProps}
                                            value={valueOpf}
                                            valid={_.toString(!!valueOpf)}
                                            inputProps={{ id: 'opf-select' }}
                                            MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: { vertical: "bottom", horizontal: "left" }
                                            }}
                                            IconComponent={!!opfProps ? 'span' : IconDropDown}
                                            onChange={(event) => this.handleChange('opf', event.target.value)}
                                        >
                                            {_.map(this.props.opfDictionary, opf =>
                                                <MenuItem key={opf.id} value={opf.id}>
                                                    {opf.text}
                                                </MenuItem>
                                            )}
                                        </Select>
                                        {!!opfProps && (
                                            <InputIcon>
                                                <IconCheckMenu />
                                            </InputIcon>
                                        )}
                                    </FormControl>
                                </MuiThemeProvider>
                            )}
                            <InputContainerMail>
                                <CustomInput
                                    disabled
                                    id="accountType"
                                    name="accountType"
                                    label={t('Тип профиля')}
                                    // value={t(getDefaultTo(_.find(fullTypes, { value: company.role }), 'title'))}
                                    value={this.getRoles()}
                                    type="text"
                                />
                                <InputIcon>
                                    <IconCheckMenu />
                                </InputIcon>
                            </InputContainerMail>

                            {/* {this.props.userInfo.accountType !== 'csp' && <MuiThemeProvider theme={CheckBoxDefault}> */}
                            {/* {!_.includes(this.props.userInfo.accountTypes, 'csp') && <MuiThemeProvider theme={CheckBoxDefault}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={company.isMarked}
                                            onChange={() => this.handleChange('isMarked')}
                                            checkedIcon={<IconCheck />}
                                        />
                                    }
                                    label={t('Я участник оборота товаров, маркированных средствами идентификации')}
                                />
                            </MuiThemeProvider>} */}
                            {/* {!_.includes(this.props.userInfo.accountTypes, 'csp') && <MuiThemeProvider theme={CheckBoxDefault}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={user.isTechCardSupport}
                                            onChange={() => this.handleChange('isTechCardSupport')}
                                            checkedIcon={<IconCheck />}
                                        />
                                    }
                                    label={t('Включить функционал маркировки остатков и перемаркировки')}
                                />
                            </MuiThemeProvider>} */}

                            {/* <ProfileDivider /> */}
                            <ProfileInputInlineCompany>
                                <ProfileInputCompanyINN fullWidth={company.isResident === false}>
                                    <InputContainerMail>
                                        <CustomInput
                                            disabled
                                            id="inn"
                                            name="inn"
                                            label={t('Налоговый идентификатор')}
                                            value={getDefaultTo(company, 'inn')}
                                            maxLength={12}
                                            type="text"
                                        />
                                        {!!getDefaultTo(company, 'inn') && (
                                            <InputIcon>
                                                <IconCheckMenu />
                                            </InputIcon>
                                        )}
                                    </InputContainerMail>
                                </ProfileInputCompanyINN>
                                {/* for using in future */}
                                {/* {!isKZ &&
                                <CustomInput
                                    disabled
                                    id="ogrn"
                                    name="ogrn"
                                    label={t('ОГРН')}
                                    value={getDefaultTo(company, 'ogrn')}
                                    type="text"
                                />
                            } */}
                                <ProfileInputCompany fullWidth={company.isResident !== false}>
                                    {company.isResident !== false && (
                                        <MuiThemeProvider theme={ProfileAutocomplete}>
                                            <FormControl fullWidth={true} >
                                                <Autocomplete
                                                    name="okved2"
                                                    suggestions={this.state.suggestions.slice(0, 200)}
                                                    label={t('Основной вид деятельности')}
                                                    handleSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                                                    handleSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                                                    renderSuggestion={renderSuggestion}
                                                    getSuggestionValue={this.getSuggestionValue}
                                                    initValue={!!valueOkved2Code
                                                        ? `${valueOkved2Code} ${company.okved2.description}`
                                                        : ''
                                                    }
                                                    forceSetValue={!!valueOkved2Code}
                                                    emptyLabel={true}
                                                />
                                            </FormControl>
                                        </MuiThemeProvider>
                                    )}
                                </ProfileInputCompany>
                            </ProfileInputInlineCompany>
                            {/* for using in future */}
                            {/* {!isKZ &&
                                <CustomInput
                                    disabled
                                    id="kpp"
                                    name="kpp"
                                    label={t('КПП головного филиала')}
                                    value={getDefaultTo(company, 'kpp')}
                                    type="text"
                                />
                            } */}
                            <InputContainerMail>
                                <CustomInput
                                    disabled
                                    id="address"
                                    name="address"
                                    label={t('Юридический адрес')}
                                    value={getDefaultTo(company, 'address')}
                                    type="text"
                                />
                                {!!getDefaultTo(company, 'address') && (
                                    <InputIcon>
                                        <IconCheckMenu />
                                    </InputIcon>
                                )}
                            </InputContainerMail>
                            {company.isResident === false && (
                                <>
                                    <FormControl fullWidth={true}>
                                        <MuiThemeProvider theme={defaultSelectTheme}>
                                            <InputLabel disabled={!!countriesProps} htmlFor="countries-select">
                                                {t('Страна резидентства')}
                                            </InputLabel>
                                            <Select
                                                required
                                                disabled={!!countriesProps}
                                                value={valueCountry}
                                                valid={_.toString(!!valueCountry)}
                                                inputProps={{ id: 'countries-select' }}
                                                MenuProps={{
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: { vertical: "bottom", horizontal: "left" }
                                                }}
                                                IconComponent={!!countriesProps ? 'span' : IconDropDown}
                                                onChange={(event) => this.handleChange('country', event.target.value)}
                                            >
                                                {_.map(this.props.dictionaryCountries, country =>
                                                    <MenuItem key={country.id} value={country.id}>
                                                        {country.text}
                                                    </MenuItem>
                                                )}
                                            </Select>
                                            {!!countriesProps && (
                                                <InputIcon>
                                                    <IconCheckMenu />
                                                </InputIcon>
                                            )}
                                        </MuiThemeProvider>
                                    </FormControl>
                                    <div style={{ position: 'relative' }}>
                                        <CustomInput
                                            multiline={true}
                                            id="nonResidentialGCP"
                                            name="nonResidentialGCP"
                                            label={t('Префиксы GCP')}
                                            onChange={(value) => this.onGcpChange(value)}
                                            value={this.state.nonResidentialGcps}
                                            rows={1}
                                            rowsMax={6}
                                        />
                                        {!!this.state.gcpError &&
                                            <span style={{ position: 'absolute', right: 25, top: '50%', marginTop: -28, }}>
                                                {SimpleTooltip(this.state.gcpError, <IconErrorSmall />)}
                                            </span>}
                                    </div>
                                </>
                            )}
                            {/* <ProfileDivider /> */}
                        </ProfileCenter>

                        <ProfileRight certificate={!_.isNil(certificate)} >

                            <ProfileH3>{t('Пользователь организации')}</ProfileH3>
                            <InputContainerMail>
                                <CustomInput
                                    disabled
                                    id="fullname"
                                    name="fullname"
                                    label={t('ФИО')}
                                    value={_.defaultTo(`${user.lastName} ${user.firstName} ${user.middleName}`, '')}
                                    type="text"
                                />
                                {!!_.defaultTo(`${user.lastName} ${user.firstName} ${user.middleName}`, '') && (
                                    <InputIcon>
                                        <IconCheckMenu />
                                    </InputIcon>
                                )}
                            </InputContainerMail>
                            <ProfileInputInlineUser isELK={isELK} >
                                <ProfileInputContainerMailPhone>
                                    <InputContainerMail style={{ width: '100%' }}>
                                        <CustomInput
                                            required
                                            //disabled={isElkUser}
                                            disabled={true}
                                            id="email"
                                            name="email"
                                            placeholder="E-mail"
                                            label={
                                                !emailValid
                                                    ? t('Введите корректный E-mail')
                                                    : validationErrorEmail ? validationErrors.email : "E-mail"
                                            }
                                            onChange={(value) => this.handleChange('email', value)}
                                            onUpdate={() => { }}
                                            shrink={true}
                                            value={valueEmail}
                                            valid={emailValid && !validationErrorEmail}
                                            error={!emailValid || validationErrorEmail}
                                            type="email"
                                            autoComplete="email"
                                            style={{ marginRight: '20px' }}
                                        />
                                        {_.get(this.props, 'info.user.isVerifiedEmail')
                                            ? _.isEqual(this.props.info.user.email, this.state.info.user.email) &&
                                            <ProfileInputIcon><IconCheckCircle width={24} height={24} /></ProfileInputIcon>
                                            : (!isElkUser && !!this.props.info.user.email) && (
                                                <MuiThemeProvider theme={ConfirmEmailButton}>
                                                    <ProfileInputIcon><IconCheckMenu /></ProfileInputIcon>
                                                    <Button onClick={this.confirmEmailModal}>{t('Подтвердить адрес')}</Button>
                                                </MuiThemeProvider>
                                            )
                                        }
                                    </InputContainerMail>

                                    <MuiThemeProvider theme={InputPhone}>
                                        <PhoneInput
                                            phone={valuePhone}
                                            placeholder={phoneValid ? t('Телефон') : t('Введите корректный номер телефона')}
                                            onChange={(value) => this.handleChange('phone', value)}
                                            onUpdate={() => { }}
                                            phoneValid={phoneValid}
                                            autoComplete="off"
                                        />
                                    </MuiThemeProvider>
                                </ProfileInputContainerMailPhone>
                                <MuiThemeProvider theme={InputCreated}>
                                    <InputContainerMail>
                                        <CustomInput
                                            disabled
                                            id="created"
                                            name="created"
                                            label={t('Дата регистрации')}
                                            value={_.defaultTo(moment(user.created).format('DD-MM-YYYY'), '')}
                                            type="text"
                                        />
                                        <InputIcon>
                                            <IconCheckMenu />
                                        </InputIcon>
                                    </InputContainerMail>
                                </MuiThemeProvider>
                            </ProfileInputInlineUser>

                            {/* {this.showPasswordChange()} */}
                            {isELK && (
                                <>
                                    <ProfileDivider style={isELK ? { height: '60px' } : { height: '40px' }} />
                                    <MuiThemeProvider theme={CheckBoxDefault}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={user.isEmailNotifications}
                                                    onChange={() => this.handleChange('isEmailNotifications', !user.isEmailNotifications)}
                                                    checkedIcon={<IconCheck />}
                                                    icon={<IconCheckbox />}
                                                    color="default"
                                                />
                                            }
                                            label={t(`Осуществлять информирование меня обо всех изменениях в личном кабинете, путём направления ежедневного отчёта на мой электронный адрес`)}
                                        />
                                    </MuiThemeProvider>
                                </>
                            )}
                        </ProfileRight>
                    </ProfileMain>
                </ProfileMemberDetailsCont>
                <ProfileFooter>
                    <ProfileFooterButton>
                        <MuiThemeProvider theme={WhiteBtn}>
                            <Button
                                disabled={!isELK || (isELK && company.isResident === false)}
                                onClick={this.sendApiKey}
                            >
                                {t('Предоставить Apikey')}
                            </Button>
                        </MuiThemeProvider>
                        <MuiThemeProvider theme={BlueBtn}>
                            <Button
                                onClick={this.onSaveInfoButton}
                                disabled={isNoChange || !phoneValid || !emailValid || this.state.gcpError}
                            >
                                {t('Сохранить изменения')}
                            </Button>
                        </MuiThemeProvider>
                    </ProfileFooterButton>
                </ProfileFooter>
                {/* <ProfileFooterButtonLine /> */}
                {/* {this.state.showFloatingButton &&
                    <FloatingButtonView>
                        <StickyBtns>
                            <FloatingButton
                                text={t('Сохранить изменения')}
                                onClick={this.onSaveInfoButton}
                                disabled={isNoChange || !phoneValid || !emailValid}
                                color={"primary"}
                                icon={<IconFloatSave style={{ marginRight: "5px" }} />}
                            />
                        </StickyBtns>
                    </FloatingButtonView>
                } */}

                {this.state.selectCertModal &&
                    <CertificateSelectModal
                        acceptBtnText={t('Загрузить новый сертификат')}
                        onSelectCert={(cert) => this.onSelectCertificate(cert)}
                        onSubmit={(certificate) => this.handleChange('certificate', certificate)}
                        onGetAllCerts={this.props.onLoadCertificates}
                        cert={this.state.certificate}
                        certificates={this.props.certificates}
                        onCancel={this.onNewCertClick}
                    />
                }

                {this.state.showChangeEmailModal &&
                    <ConfirmDialog
                        onCancel={() => this.setState({ showChangeEmailModal: false })}
                        message="Для подтверждения смены почты пройдите по ссылке, высланной на указанный вами e-mail"
                    />
                }

                {this.state.confirmEmailModal &&
                    <ConfirmEmailModal
                        email={valueEmail}
                        throwError={this.props.throwError}
                        onCancel={this.confirmEmailModal}
                    />
                }
            </ProfileWrap>
        </React.Fragment>
        );
    }
}

MemberDetails.propTypes = {
    throwError: PropTypes.func.isRequired,
    onLoadProfileInfo: PropTypes.func.isRequired,
    onLoadCertificates: PropTypes.func.isRequired,
    onSavePassword: PropTypes.func.isRequired,
    onSaveInfo: PropTypes.func.isRequired,
    onLoadDictionaryOkved: PropTypes.func.isRequired,
    loadOpfDictionary: PropTypes.func.isRequired,
    info: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    validationErrors: PropTypes.object.isRequired,
    certificates: PropTypes.array.isRequired,
    dictionaryOkved: PropTypes.array.isRequired,
    opfDictionary: PropTypes.array.isRequired,
    userInfo: PropTypes.object.isRequired,
    throwNotification: PropTypes.func.isRequired,
    loadDictionaryCountries: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
    info: selectors.info(state),
    errors: selectors.errors(state),
    certificates: selectors.certificates(state),
    dictionaryOkved: selectors.dictionaryOkved(state),
    opfDictionary: selectors.dictionaryOpf(state),
    validationErrors: selectors.validationErrors(state),
    userInfo: state.authentificationReducer.user,
    dictionaryCountries: selectors.dictionaryCountries(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onLoadProfileInfo: (cbk) => loadProfileInfo(cbk),
        onLoadCertificates: () => loadCertificates(),
        onSavePassword: (password) => savePassword(password),
        onSaveInfo: (oldInfo, newInfo, cbk) => saveInfo(oldInfo, newInfo, cbk),
        onLoadDictionaryOkved: (searchText, cbk) => loadDictionaryOkved(searchText, cbk),
        loadOpfDictionary: (cbk) => loadDictionaryOpf(cbk),
        throwError: (message) => throwNotification(message, 'error'),
        throwNotification: (message, type) => throwNotification(message, type),
        loadDictionaryCountries: (cbk) => loadDictionaryCountries(cbk),
    }, dispatch);
};


export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(MemberDetails);
