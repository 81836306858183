import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MuiThemeProvider,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconClose, IconComponentClose } from '../../../../../common/icons/customIcons';
import {
  DialogTitleStyled,
  defaultTheme,
} from '../../../../../common/styles/Common.styled';
import { BlueBtn, CreationCloseBtns, WhiteBtn, CloseBtn } from '../../../Profile.styled';
import { CreationSiteDialog } from '../../profileStatus/styled/CreationStatusModal.styled';
import { CustomInput, PhoneInput } from '../../../../../components';
import {
  validateEmail,
  validatePhoneInput,
} from '../../../../../common/utils/Validation';
import {
  addSubProfile,
  editSubProfile,
} from '../../../ducks/SubProfiles/SubProfiles.actions';
import { loading } from '../../../ducks/SubProfiles/SubProfiles.selectors';
import { AddTrustItemContainer, AddTrustItemEmail, AddTrustItemErrorContent, AddTrustItemLastName, AddTrustItemName, AddTrustItemPhone } from '../styled/AddTrustedAccountModal.styled';

const getDefaultTo = (obj, path, defaultValue = '') =>
  _.defaultTo(_.get(obj, path, defaultValue), defaultValue);

class AddTrustedAccountModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        middleName: getDefaultTo(this.props.modalData, 'middleName'), //required
        firstName: getDefaultTo(this.props.modalData, 'firstName'), //required
        lastName: getDefaultTo(this.props.modalData, 'lastName'),
        ITN: getDefaultTo(this.props.modalData, 'ITN'), //required
        email: getDefaultTo(this.props.modalData, 'email'), //required
        phone: getDefaultTo(this.props.modalData, 'phone'), //required
        organizationName: getDefaultTo(
          this.props.modalData,
          'organizationName',
        ), //required
      },
      mode: getDefaultTo(this.props, 'mode'),
    };
  }

  handleChange = (name) => (value) => {
    if (name === 'ITN') {
      value = value.replace(/[^[0-9]/, '');
    }
    if (name === 'phone') {
      value = value.replace(/[A-Za-z]/, '');
    }
    // if (name === 'organizationName') {
    //   //eslint-disable-next-line
    //   value = value.replace(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/0-9]/, '');
    // }
    this.setState((prevState) => ({
      data: { ...prevState.data, [name]: value },
    }));
  };

  handlePaste = (name, typeId) => (value) => {
    typeId = this.props.typeId;
    this.handleChange(name);
  };

  onSubmit = () => {
    const data = _.cloneDeep(this.state.data);
    if (this.props.mode === 'create') {
      return this.props.addSubProfile(data, (error) => {
        if (_.isNull(error)) {
          return this.props.handleClose();
        }
        return;
      });
    }
    data.userId = this.props.modalData.id;
    return this.props.editSubProfile(data, (error) => {
      if (_.isNull(error)) {
        return this.props.handleClose();
      }
      return;
    });
  };

  copyOfStateFromProps = () => {
    const dataKeys = [
      'middleName',
      'firstName',
      'lastName',
      'ITN',
      'email',
      'phone',
      'organizationName',
    ];

    return _.pick(this.props.modalData, dataKeys);
  };

  render() {
    const { t, mode, dismissModal, loading, typeId } = this.props;
    const { data } = this.state;
    const emailIsValid = validateEmail(_.defaultTo(this.state.data.email, ''));

    const valuePhone = getDefaultTo(data, 'phone');
    const phoneValid =
      !!valuePhone.replace(/\D/g, '') && validatePhoneInput(valuePhone);
    const itnIsValid =
      !!getDefaultTo(data, 'ITN') &&
      _.includes(
        [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
        _.size(_.toString(getDefaultTo(data, 'ITN'))),
      );
    const firstNameIsValid =
      !!getDefaultTo(data, 'firstName') &&
      getDefaultTo(data, 'firstName') !== getDefaultTo(data, 'lastName');
    const lastNameIsValid =
      !!getDefaultTo(data, 'lastName') &&
      getDefaultTo(data, 'lastName') !== getDefaultTo(data, 'firstName');
    const equalLastNameFirstName =
      getDefaultTo(data, 'firstName') &&
      getDefaultTo(data, 'lastName') &&
      getDefaultTo(data, 'firstName') === getDefaultTo(data, 'lastName');
    const organizationNameIsValid =
      !!getDefaultTo(data, 'organizationName') &&
      getDefaultTo(data, 'organizationName').length > 2;

    const dataIsEqualToProps = _.isEqual(this.copyOfStateFromProps(), data);

    const allFieldsFilled =
      lastNameIsValid &&
      firstNameIsValid &&
      itnIsValid &&
      emailIsValid &&
      phoneValid &&
      organizationNameIsValid;

    const isEditOrCreate = _.includes(['create', 'edit'], mode);

    return (
      <MuiThemeProvider theme={CreationSiteDialog}>
        <Dialog open>
          <DialogTitle>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <DialogTitleStyled>
              {t('Карточка доверенного пользователя')}
            </DialogTitleStyled>
            <MuiThemeProvider theme={CloseBtn}>
              <IconButton className="backEmpty" onClick={dismissModal}>
                <IconClose />
              </IconButton>
            </MuiThemeProvider>
            </div>
          </DialogTitle>
          <DialogContent style={{marginTop: '-20px'}}>
          <AddTrustItemContainer>
            <MuiThemeProvider theme={defaultTheme}>
              <AddTrustItemLastName>
                <CustomInput
                  name="lastName"
                  id="lastName"
                  required
                  valid={lastNameIsValid}
                  type="text"
                  value={getDefaultTo(data, 'lastName')}
                  label={t('Фамилия')
                    // lastNameIsValid
                    //   ? t('Фамилия')
                    //   : equalLastNameFirstName
                    //     ? t('Фамилия и имя не могут быть одинаковыми')
                    //     : t('Фамилия')
                  }
                  onChange={this.handleChange('lastName')}
                  disabled={!isEditOrCreate || !!loading}
                  error={!lastNameIsValid && equalLastNameFirstName}
                />
              <AddTrustItemErrorContent>{equalLastNameFirstName && t('Указаны некорректные сведения')}</AddTrustItemErrorContent>
              </AddTrustItemLastName>
              <AddTrustItemName>
                <CustomInput
                  name="firstName"
                  id="firstName"
                  required
                  valid={firstNameIsValid}
                  type="text"
                  value={getDefaultTo(data, 'firstName')}
                  label={t('Имя')
                    // firstNameIsValid
                    //   ? t('Имя')
                    //   : equalLastNameFirstName
                    //     ? t('Фамилия и имя не могут быть одинаковыми')
                    //     : t('Имя')
                  }
                  onChange={this.handleChange('firstName')}
                  disabled={!isEditOrCreate || !!loading}
                  error={!firstNameIsValid && equalLastNameFirstName}
                />
                <AddTrustItemErrorContent>{equalLastNameFirstName && t('Указаны некорректные сведения')}</AddTrustItemErrorContent>
              </AddTrustItemName>
              <AddTrustItemName>
                <CustomInput
                  name="middleName"
                  id="middleName"
                  valid={!!getDefaultTo(data, 'middleName')}
                  type="text"
                  value={getDefaultTo(data, 'middleName')}
                  label={t('Отчество')}
                  onChange={this.handleChange('middleName')}
                  disabled={!isEditOrCreate}
                />
              </AddTrustItemName>
              <CustomInput
                name="ITN"
                id="ITN"
                required
                valid={itnIsValid}
                type="text"
                value={getDefaultTo(data, 'ITN')}
                label={t('Налоговый идентификатор')}
                onChange={this.handleChange('ITN')}
                onPaste={this.handlePaste('ITN', typeId)}
                disabled={!isEditOrCreate || !!loading}
                maxLength={14}
                error={!itnIsValid && !!getDefaultTo(data, 'ITN')}
                style={{marginBottom: '16px'}}
              />
              <AddTrustItemEmail>
                <CustomInput
                  name="email"
                  id="email"
                  placeholder="E-mail"
                  autoComplete="email"
                  label={'E-mail'}
                  onUpdate={() => { }}
                  onChange={this.handleChange('email')}
                  value={getDefaultTo(data, 'email')}
                  valid={getDefaultTo(data, 'email') ? emailIsValid : false}
                  error={!emailIsValid && !!getDefaultTo(data, 'email')}
                  type="email"
                  required
                  disabled={!isEditOrCreate || !!loading}
                />
                <AddTrustItemErrorContent>{data.email && !emailIsValid&& t('Введите корректный E-mail')}</AddTrustItemErrorContent>
              </AddTrustItemEmail>
              <AddTrustItemPhone>
                <PhoneInput
                  id="phone"
                  name="phone"
                  //maskGS="+9(999)999-99-99"
                  label={t('Телефон')}
                  phone={valuePhone}
                  placeholder={t('Телефон')}
                  onChange={this.handleChange('phone')}
                  onUpdate={() => { }}
                  phoneValid={phoneValid || !valuePhone}
                  autoComplete="off"
                  valid={!!getDefaultTo(data, 'phone') && phoneValid}
                  error={!phoneValid}
                  required
                  disabled={!isEditOrCreate || !!loading}
                />
                <AddTrustItemErrorContent>{data.phone && !phoneValid&& t('Введите корректный номер телефона')}</AddTrustItemErrorContent>
              </AddTrustItemPhone>
              <CustomInput
                name="organizationName"
                id="organizationName"
                required
                valid={organizationNameIsValid}
                type="text"
                value={getDefaultTo(data, 'organizationName')}
                label={t('Организация')}
                onChange={this.handleChange('organizationName')}
                disabled={!isEditOrCreate || !!loading}
                error={!organizationNameIsValid && !!getDefaultTo(data, 'organizationName')}
              />
            </MuiThemeProvider>
            </AddTrustItemContainer>
          </DialogContent>
          <DialogActions>
            {mode !== 'view' && (
              <>
                <MuiThemeProvider theme={WhiteBtn}>
                  <Button
                    onClick={dismissModal}
                    disabled={!!loading}>
                    {!!loading ? (
                      <CircularProgress size={26} style={{ color: '#52535A' }} />
                    ) : (
                      t('Отменить')
                    )}
                  </Button>
                </MuiThemeProvider>
                <MuiThemeProvider theme={BlueBtn}>
                  <Button
                      disabled={
                        !allFieldsFilled ||
                        !isEditOrCreate ||
                        dataIsEqualToProps ||
                        !!loading
                      }
                      onClick={this.onSubmit}>
                      {!!loading ? (
                        <CircularProgress size={26} style={{ color: '#52535A' }} />
                      ) : (
                        t('Подтвердить')
                      )}
                    </Button>
                </MuiThemeProvider>
              </>
            )}
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

AddTrustedAccountModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  dismissModal: PropTypes.func.isRequired,
  addSubProfile: PropTypes.func.isRequired,
  editSubProfile: PropTypes.func.isRequired,
  loading: PropTypes.number.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addSubProfile: (data, callback) => addSubProfile(data, callback),
      editSubProfile: (data, callback) => editSubProfile(data, callback),
    },
    dispatch,
  );
};

const mapStateToProps = (state) => ({
  loading: loading(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(AddTrustedAccountModal);
