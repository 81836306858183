import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../../common/styles/Common.colors';

export const CopyProductAlertTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '550px',
        maxWidth: '550px',
        borderRadius: '8px',
        padding: '32px 0 0',
        fontFamily: 'SegoeUI',
        color: `${colors.Common.textColor}`,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '0 36px 0',
      },
    },
    MuiTypography: {
      h3: {
        fontSize: '21px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
      },
      root: {
        '& span': {
          display: 'block',
        }
      },
    },
    MuiDialogContent: {
      root: {
        padding: '16px 36px 24px',
        fontSize: '18px',
        lineHeight: '24px',
      },
    },
    MuiDialogActions: {
      root: {
        margin: '0',
        padding: '32px 36px',
        borderTop: '1px solid #E7E7E7',
      },
      spacing: {
        '&>:not(:first-child)': {
          margin: '0 !important',
        }
      },
    },
    MuiButton: {
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        '&:hover': {
          color: `${colors.Buttons.buttonPrimary.color}!important`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
        },
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
      },
      textSecondary: {
        background: 'none',
        color: `${colors.Common.textColor}`,
        border: '1px solid #DCDCDC',
        marginRight: '20px',
        '&:hover': {
          color: `${colors.Common.textColor} !important`,
          backgroundColor: '#F5F5F5 !important',
        },
        '&$disabled': {
          color: '#C9C7C7',
          backgroundColor: 'transparent !important',
        },
      },
      root: {
        borderRadius: '4px',
        height: 'auto',
        minWidth: '30px',
        transition: '.4s ease',
      },
      text: {
        padding: '10px 35px',
        textTransform: 'none',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
      },
      disabled: {},
    },
  },
});
