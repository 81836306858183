import React, { useMemo } from 'react';
import { compose } from 'redux';
import {
  DialogContent,
  MuiThemeProvider,
  FormControl,
  TextField,
  Tooltip,
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CustomInput, SimpleTooltip } from '../../../../components';
import { gtinDisplay } from '../../../../common/utils/utils';
import {
  ProductBlockNew,
  GenerateGtinButton,
  ErrorContent,
} from './PopupNewProduct.styled';
import {
  defaultTheme,
  InputContainer,
  InputIcon,
  FieldIcon,
} from '../../../../common/styles/Common.styled';
import {
  IconPreloader,
  IconDropDown,
  IconClose,
  IconInfoCard,
  IconCheckMenu,
} from '../../../../common/icons/customIcons';
import { Autocomplete } from '@material-ui/lab';
import PopupNewProductItem from './PopupNewProductItem';
import { ProductGtinBlock } from './styled/PopupNewProduct.styled';
import { PackInfoItemMax } from '../Product/Product.styled';
import { CertSelectAuto } from '../../../../components/certificateSelect/CertificateSelect.styled';
import _, { concat } from 'lodash';

const PopupDialogContent = ({
  profileStatusData,
  activeKzDictionary,
  company,
  subaccountData,
  t,
  disabledGTIN,
  handleChange,
  editingIsPermitted,
  onManufacturerImporterSelectChange,
  manufacturerChecked,
  manufacturerFields,
  producerFields,
  producerChecked,
  onProducerSelectChange,
  updateConsumerItem,
  handleErrors,
  onValidateCensorship,
  sendSubaccount,
  addItem,
  deleteItem,
  importerChecked,
  importerFields,
  technicalMode,
  fieldsFilled,
  data,
  gtinValidation,
  errors,
  gtin,
  countries,
  isAccountCanCreateGtin,
  isAvailabilityCreationGtin,
  generatedGtin,
  modalGcp,
  btnGtinTooltipTitle,
  isRequiredNumberRu,
}) => {
  // const currentAccountList = useMemo(() => {
  //   const profileObjectsWithGcp = _.filter(profileStatusData, (item) => {
  //     return item.type === 0;
  //   });

  //   const currentAccountList = [];

  //   if (profileObjectsWithGcp.length) {
  //     _.forEach(profileObjectsWithGcp, (item) => {
  //       currentAccountList.push({ ...company, ...item });
  //     });
  //   } else {
  //     currentAccountList.push(company);
  //   }

  //   return currentAccountList;
  // }, [profileStatusData, company]);

  const makeUniqueSubaccountList = () => {
    const newSubaccountData = _.cloneDeep(subaccountData);
    _.forEach(newSubaccountData, (accountObject) => {
      accountObject.uniqueId = _.uniqueId('account_');
    });

    return newSubaccountData;
  };

  const uniqueSubaccountList = makeUniqueSubaccountList();

  const getOptions = () => {
    // let currentAccountListNonResident;
    // if (company.isResident === false) {
    //   currentAccountListNonResident = currentAccountList.filter(
    //     (item) => !uniqueSubaccountList.some(elem => elem.ITN === item.inn)
    //   );
    // }
    // const makeCurrentAccounts = (arr) => {
    //   return _.map(arr, (item) =>
    //     item.gcp
    //       ? {
    //         type: 'currentProfile',
    //         path: `${item.name}, ${item.inn}, ${item.gcp}`,
    //       }
    //       : {
    //         type: 'currentProfile',
    //         path: `${item.name}, ${item.inn}`,
    //       })
    // };

    // const currentAccounts = company.isResident === false
    //   ? makeCurrentAccounts(currentAccountListNonResident)
    //   : makeCurrentAccounts(currentAccountList);

    return concat(
      {
        type: 'manually',
        path: `${t('Заполнить вручную')}`,
      },
      // _.map(currentAccountList, (item) =>
      //   item.gcp
      //     ? {
      //       type: 'currentProfile',
      //       path: `${item.name}, ${item.inn}, ${item.gcp}`,
      //     }
      //     : {
      //       type: 'currentProfile',
      //       path: `${item.name}, ${item.inn}`,
      //     },
      // ),
      // currentAccounts,
      _.map(uniqueSubaccountList, (i) =>
        i && i.GCP
          ? {
            type: i.AccountId,
            path: `${i.Name}, ${i.ITN}, ${i.GCP}`,
            uniqueId: i.uniqueId,
          }
          : {
            type: i.AccountId,
            path: `${i.Name}, ${i.ITN}`,
            uniqueId: i.uniqueId,
          },
      ),
    );
  };

  const getListSubaccount = () =>
    concat(
      // _.map(currentAccountList, (item) =>
      //   item.gcp
      //     ? {
      //       type: 'currentProfile',
      //       name: item.name,
      //       inn: item.inn,
      //       gcp: item.gcp,
      //     }
      //     : {
      //       type: 'currentProfile',
      //       name: item.name,
      //       inn: item.inn,
      //     },
      // ),
      _.map(uniqueSubaccountList, (i) =>
        i && i.GCP
          ? {
            type: i.AccountId,
            name: i.Name,
            inn: i.ITN,
            gcp: i.GCP,
            uniqueId: i.uniqueId,
          }
          : {
            type: i.AccountId,
            name: i.Name,
            inn: i.ITN,
            uniqueId: i.uniqueId,
          },
      ),
    );

  const handleChangeManufacturer = (_, item) => {
    onManufacturerImporterSelectChange(
      item && item.type,
      'manufacturer',
      item,
      uniqueSubaccountList,
    );
  };

  /**
   * для корректного вывода информации о компании в поле автокомплита
   *
   * @param {*} name string, название компании
   * @param {*} inn string, inn компании
   * @param {*} gcp string, gcp компании, может и не быть у компаний.
   */
  const concatNameInnGcp = (name, gcp, inn) => {
    if (_.isEmpty(name) && _.isEmpty(inn) && _.isEmpty(inn)) {
      return '';
    }
    if (_.isEmpty(gcp)) {
      return `${name}, ${inn}`;
    }
    return `${name}, ${gcp}, ${inn}`;
  };

  const arrayInnBusy = []
  const innBusy = (value) => {
    if (value.editable) {
      if ((_.some(uniqueSubaccountList, (i) => i.ITN === value.inn))
        // || (_.some(currentAccountList, (i) => i.ITN === value.inn))
        || (value.inn === company.inn))
        return arrayInnBusy.push(value);
    }
  };

  const arrayGcpInnBusy = []
  const gspInnBusy = (value) => {
    const arrayGcpBusy = []
    if (value.editable) {
      if ((_.some(uniqueSubaccountList, (i) => i.GCP === value.gcp && i.ITN === value.inn))
        // || (_.some(currentAccountList, (i) => i.GCP === value.gcp && i.ITN === value.inn))
        || (value.gcp === company.gcp && value.inn === company.inn))
        return arrayGcpInnBusy.push(value);
    }
  };

  const isReadySubmit = () => {
    if (generatedGtin) {
      return fieldsFilled() && gtinValidation.successed;
    } else {
      const gtinValid = gtinValidation.successed && data.gtin;
      return fieldsFilled() && gtinValid;
    }
  };

  const disableNumberRu = isRequiredNumberRu
    ? (!!data.CatId && !!data.registrationCertNumber && !!data.packageId)
    : !!data.CatId;

  return (
    <DialogContent>
      <div>
        <PackInfoItemMax>
          <FormControl fullWidth={true}>
            <MuiThemeProvider theme={CertSelectAuto}>
              <Autocomplete
                options={getOptions()}
                autoHighlight
                onChange={handleChangeManufacturer}
                getOptionLabel={(item) => item.path}
                popupIcon={<IconDropDown />}
                closeIcon={<IconClose style={{ width: 20, height: 20 }} />}
                clearText=''
                openText=''
                disabled={disabledGTIN || !editingIsPermitted}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Выберите владельца идентификатора товара (GTIN)')}
                    variant="standard"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      value: concatNameInnGcp(
                        manufacturerFields[0].name,
                        manufacturerFields[0].gcp,
                        manufacturerFields[0].inn,
                      ),
                      autoComplete: 'disabled',
                    }}
                  />
                )}
                noOptionsText={t('Нет совпадений')}
              />
            </MuiThemeProvider>
          </FormControl>
        </PackInfoItemMax>

        {manufacturerChecked && (
          <>
            {_.map(manufacturerFields, (value, index) => (
              <MuiThemeProvider key={value.id} theme={defaultTheme}>
                <PopupNewProductItem
                  typeId={index}
                  key={value.id}
                  data={value}
                  isInnBusy={innBusy(value)}
                  isGcpInnBusy={gspInnBusy(value)}
                  updateItem={updateConsumerItem}
                  handleErrors={handleErrors}
                  onValidate={onValidateCensorship}
                  updateData={() => { }}
                  validCbk={() => { }}
                  disabledGtin={disabledGTIN}
                  sendSubaccount={sendSubaccount}
                  isHasAddItemButton={false}
                  blockType={'manufacturer'}
                  t={t}
                  countries={countries[0]}
                />
              </MuiThemeProvider>
            ))}
          </>
        )}
      </div>

      <MuiThemeProvider theme={CertSelectAuto}>
        <div style={{ position: 'relative' }}>
          <Autocomplete
            options={activeKzDictionary}
            autoHighlight
            onChange={(_, item) => handleChange('CatId', (item ? item.id : ''), (item ? item : ''))}
            getOptionLabel={(item) => item.name}
            popupIcon={<IconDropDown style={disabledGTIN && !!data.CatId ? { display: 'none' } : { display: 'block' }} />}
            closeIcon={<IconClose style={{ width: 20, height: 20 }} />}
            clearText=''
            openText=''
            // disabled={!isReadySubmit()}
            disabled={!fieldsFilled() || disabledGTIN}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Выберите код государственного классификатора')}
                variant="standard"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'disabled',
                }}
              />
            )}
            noOptionsText={t('Нет совпадений')}
          />
          {disabledGTIN && !!data.CatId && (
            <FieldIcon>
              <IconCheckMenu />
            </FieldIcon>
          )}
        </div>
      </MuiThemeProvider>

      {isRequiredNumberRu && (
        <ProductBlockNew>
          <div style={{ width: '42%', marginRight: 20 }}>
            <MuiThemeProvider theme={defaultTheme}>
              <InputContainer>
                <CustomInput
                  id="registrationCertNumber"
                  name="registrationCertNumber"
                  type="text"
                  maxLength={50}
                  required
                  label={t('Номер РУ')}
                  onUpdate={() => { }}
                  onChange={(value) => handleChange('registrationCertNumber', value)}
                  value={_.defaultTo(data.registrationCertNumber, '')}
                  // error={}
                  valid={!!data.registrationCertNumber}
                  disabled={disabledGTIN}
                />
                {disabledGTIN && !!data.registrationCertNumber && (
                  <InputIcon>
                    <IconCheckMenu />
                  </InputIcon>
                )}
              </InputContainer>
            </MuiThemeProvider>
          </div>
          <div style={{ width: 'calc(58% - 20px)' }}>
            <MuiThemeProvider theme={defaultTheme}>
              <InputContainer>
                <CustomInput
                  id="packageId"
                  name="packageId"
                  type="text"
                  maxLength={50}
                  required
                  label={t('Идентификатор упаковки')}
                  onUpdate={() => { }}
                  onChange={(value) => handleChange('packageId', value)}
                  value={_.defaultTo(data.packageId, '')}
                  // error={}
                  valid={!!data.packageId}
                  disabled={disabledGTIN}
                />
                {disabledGTIN && !!data.packageId && (
                  <InputIcon>
                    <IconCheckMenu />
                  </InputIcon>
                )}
              </InputContainer>
            </MuiThemeProvider>
          </div>
        </ProductBlockNew>
      )}

      {/* <div>
        <PackInfoItemMax>
          <FormControl fullWidth={true}>
            <MuiThemeProvider theme={CertSelectAuto}>
              <Autocomplete
                options={getOptions()}
                autoHighlight
                onChange={(_, item) =>
                  onProducerSelectChange(
                    item && item.type,
                    0,
                    item,
                    uniqueSubaccountList,
                  )
                }
                disableClearable={producerFields.length !== 1}
                getOptionLabel={(item) => item.path}
                popupIcon={<IconDropDown />}
                closeIcon={<IconClose style={{ width: 20, height: 20 }} />}
                clearText=''
                openText=''
                disabled={disabledGTIN || !editingIsPermitted}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Выберите производственную площадку')}
                    variant="standard"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      value: producerFields.length !== 0 ? concatNameInnGcp(
                        producerFields[0].name,
                        producerFields[0].gcp,
                        producerFields[0].inn,
                      ) : null,
                      autoComplete: 'disabled',
                    }}
                  />
                )}
                noOptionsText={t('Нет совпадений')}
              />
            </MuiThemeProvider>
          </FormControl>
        </PackInfoItemMax>

        {producerChecked && (
          <MuiThemeProvider theme={defaultTheme}>
            {_.map(producerFields, (value, index) => (
              <PackInfoItemMax key={index}>
                <FormControl fullWidth={true}>
                  {index !== 0 && (
                    <MuiThemeProvider theme={CertSelectAuto}>
                      <Autocomplete
                        options={getOptions()}
                        autoHighlight
                        onChange={(_, item) =>
                          onProducerSelectChange(
                            item && item.type,
                            index,
                            item,
                            uniqueSubaccountList,
                          )
                        }
                        getOptionLabel={(item) => item.path}
                        disableClearable={
                          index !== 0 ||
                          (index === 0 && producerFields.length !== 1)
                        }
                        popupIcon={<IconDropDown />}
                        closeIcon={<IconClose style={{ width: 20, height: 20 }} />}
                        clearText=''
                        openText=''
                        disabled={disabledGTIN || !editingIsPermitted}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label={t('Выберите производственную площадку')}
                              variant="standard"
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                value: concatNameInnGcp(
                                  producerFields[index].name,
                                  producerFields[index].gcp,
                                  producerFields[index].inn,
                                ),
                                autoComplete: 'disabled',
                              }}
                            />
                          );
                        }}
                        noOptionsText={t('Нет совпадений')}
                      />
                    </MuiThemeProvider>
                  )}
                  <PopupNewProductItem
                    typeId={index}
                    key={value.id}
                    data={value}
                    isInnBusy={innBusy(value)}
                    isGcpInnBusy={gspInnBusy(value)}
                    updateItem={updateConsumerItem}
                    handleErrors={handleErrors}
                    onValidate={onValidateCensorship}
                    updateData={() => { }}
                    validCbk={() => { }}
                    disabledGtin={disabledGTIN}
                    sendSubaccount={sendSubaccount}
                    addItem={() => addItem('producer')}
                    deleteItem={(id) => deleteItem(id, 'producer')}
                    isHasAddItemButton={producerFields.length - 1 === index}
                    blockType={'producer'}
                    t={t}
                    countries={countries[1]}
                  />
                </FormControl>
              </PackInfoItemMax>
            ))}
          </MuiThemeProvider>
        )}
      </div> */}

      {/* <div>
        <PackInfoItemMax>
          <FormControl fullWidth={true}>
            <MuiThemeProvider theme={CertSelectAuto}>
              <Autocomplete
                options={getOptions()}
                autoHighlight
                onChange={(_, item) =>
                  onManufacturerImporterSelectChange(
                    item && item.type,
                    'importer',
                    item,
                    uniqueSubaccountList,
                  )
                }
                getOptionLabel={(item) => item.path}
                popupIcon={<IconDropDown />}
                closeIcon={<IconClose style={{ width: 20, height: 20 }} />}
                clearText=''
                openText=''
                disabled={disabledGTIN || !editingIsPermitted}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Выберите участника оборота')}
                    variant="standard"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      value: concatNameInnGcp(
                        importerFields[0].name,
                        importerFields[0].gcp,
                        importerFields[0].inn,
                      ),
                      autoComplete: 'disabled',
                    }}
                  />
                )}
                noOptionsText={t('Нет совпадений')}
              />
            </MuiThemeProvider>
          </FormControl>
        </PackInfoItemMax>

        {importerChecked && (
          <>
            {_.map(importerFields, (value, index) => (
              <MuiThemeProvider key={value.id} theme={defaultTheme}>
                <PopupNewProductItem
                  typeId={index}
                  key={value.id}
                  data={value}
                  isInnBusy={innBusy(value)}
                  isGcpInnBusy={gspInnBusy(value)}
                  updateItem={updateConsumerItem}
                  handleErrors={handleErrors}
                  onValidate={onValidateCensorship}
                  updateData={() => { }}
                  validCbk={() => { }}
                  disabledGtin={disabledGTIN}
                  sendSubaccount={sendSubaccount}
                  addItem={() => addItem('importer')}
                  deleteItem={(id) => deleteItem(id, 'importer')}
                  isHasAddItemButton={importerFields.length - 1 === index}
                  blockType={'importer'}
                  t={t}
                  countries={countries[2]}
                />
              </MuiThemeProvider>
            ))}
          </>
        )}
      </div> */}

      {/**
       * GTIN учавстующий в создании consumer package;
       */}
      {!technicalMode && (
        <ProductBlockNew>
          <ProductGtinBlock>
            <MuiThemeProvider theme={defaultTheme}>
              <InputContainer>
                <CustomInput
                  id="gtin"
                  name="gtin"
                  type="text"
                  maxLength={13}
                  required={editingIsPermitted}
                  disabled={disabledGTIN || !fieldsFilled() || arrayInnBusy.length > 0 || !disableNumberRu}
                  label={t('Введите код упаковки')}
                  onUpdate={() => { }}
                  onChange={(value) => handleChange('gtin', value, getListSubaccount())}
                  value={
                    disabledGTIN || !editingIsPermitted
                      ? gtinDisplay(_.defaultTo(gtin, ''))
                      : _.defaultTo(gtin, '')
                  }
                  error={!!errors.gtin || !_.isEmpty(gtinValidation.message)}
                  valid={gtinValidation.successed}
                  onPaste={() => { }}
                  t={t}
                />
                {gtinValidation.process ? (
                  <InputIcon>
                    <IconPreloader />
                  </InputIcon>
                )
                  : gtinValidation.successed ? (
                    <InputIcon>
                      <IconCheckMenu />
                    </InputIcon>
                  )
                    : (disabledGTIN || !fieldsFilled() || arrayInnBusy.length > 0) ?
                      null
                      : (
                        SimpleTooltip(
                          t('Код товара состоит из 8, 12 или 13 цифр'),
                          <IconInfoCard style={{ width: '24px', height: '24px' }} />
                        )
                      )}
              </InputContainer>
              <ErrorContent>
                {data.gtin && errors.gtin
                  ? errors.gtin
                  : !_.isEmpty(gtinValidation.message)
                    ? gtinValidation.message
                    : ''
                }
              </ErrorContent>
            </MuiThemeProvider>
          </ProductGtinBlock>
          {isAccountCanCreateGtin &&
            <MuiThemeProvider theme={GenerateGtinButton}>
              <Tooltip
                title={!fieldsFilled() ? '' : btnGtinTooltipTitle}
              >
                <span>
                  <Button
                    disabled={disabledGTIN
                      || !fieldsFilled()
                      || !isAvailabilityCreationGtin
                      || !!gtin
                      || !!generatedGtin
                      || arrayInnBusy.length > 0
                      || !disableNumberRu
                    }
                    onClick={modalGcp}
                  >
                    {t('Сгенерировать код')}
                  </Button>
                </span>
              </Tooltip>
            </MuiThemeProvider>
          }
        </ProductBlockNew>
      )}

    </DialogContent >
  );
};

const mapStateToProps = (state) => ({
  activeKzDictionary: state.catalogReducer.activeKzDictionary,
  profileStatusData: state.profileStatusReducer.data,
  subaccountData: state.subAccountReducer.subaccountsForGoodsCreation,
  isAvailabilityCreationGtin: state.productReducer.isAvailabilityCreationGtin,
  generatedGtin: state.productReducer.generatedGtin,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(PopupDialogContent);
