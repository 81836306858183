import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../common/styles/Common.colors';

export const MenuItemInner = styled.div`
  padding: 15px 60px 15px;  
  font-size: 14px;
  lineHeight: 16px;
  color: ${colors.Common.textColor};
  width: 100%;
  position: relative;
  white-space: normal;
  word-break: break-all;
  span {
    display: block;
  }
`;
export const MenuItemIcon = styled.div`
  position: absolute;
  left: 21px;
  top: 20px;
`;
export const MenuItemName = styled.div`
  font-size: 16px;
`;
export const SelectedCert = styled.div`
  display: flex;
  position: relative;
  margin-top: 20px;
  &:before {
    content: '';
    background: #f9f9f9;
    position: absolute;
    min-width: 1200px;
    height: 100%;
    top: 0;
    left: -18%;
  }
`;

export const MenuItemDate = styled.span`
  color: ${(props) => (props.isValid ? colors.Common.textColor : '#E32A38')};
`;
export const SelectedCertInner = styled.div`
  color: ${colors.Common.textColor};
  font-size: 12px;
  padding: 15px 20px 15px 33px;
  display: flex;
  position: relative;
  width: 100%;
  @media (max-width: 1024px) {
    font-size: 12px;
    padding: 15px 20px 15px 25px;
  }
`;
export const InvalidCert = styled.div`
  background: #f9f9f9;
  color: #E32A38;
  font-size: 14px;
  padding: 15px 110px 15px 150px;
  display: flex;
  position: relative;
`;
export const SelectedIcon = styled(MenuItemIcon)`
  left: 0;
`;
export const SelectedMinInner = styled.div`
  display: flex;
  margin-bottom: 5px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`;
export const SelectedMinInnerLeft = styled(SelectedMinInner)`
  padding-right: 10px;
`;
export const SelectedMinTitle = styled.span`
  padding-right: 5px;
  word-break: normal !important;
`;
export const SelectedMinText = styled.span`
  width: 200px;
  word-break: break-all;
`;
export const SelectedMinTitleCert = styled(SelectedMinTitle)`
  word-break: normal !important;
`;
export const CertSelectAuto = createMuiTheme({
  typography: {},
  overrides: {
    MuiAutocomplete: {
      listbox: {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      popupIndicator: {
        marginRight: '15px',
      },
      clearIndicator: {
        padding: '0',
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
      option: {
        backgroundColor: 'transparent',
        paddingLeft: '0',
        paddingRight: '0',
        paddingTop: '16px',
        paddingBottom: '16px',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
        '&:hover': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
        },
      },
      inputRoot: {
        border: '1px solid #C9C7C7',
        borderRadius: '4px',
        paddingRight: '0!important',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
      },
      input: {
        padding: '23px 68px 7px 15px !important',
      },
      popper: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
        padding: '0',
        borderRadius: '8px',
      },
      paper: {
        margin: '8px 0 0 0',
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      root: {
        margin: '16px 0 16px',
      },

    },
    MuiInputBase: {
      input: {
        width: '0',
        fontSize: '16px',
        color: `${colors.Common.textColor}`,
        lineHeight: '24px',
        fontWeight: 'normal',
        height: 'auto',
        fontFamily: 'SegoeUI',
        '&::placeholder': {
          color: `${colors.Common.textColor}`,
          opacity: '1',
          transform: 'translateY(-10px)',
        },
      },
      root: {
        '&$disabled': {
          color: `${colors.Common.textGrey}`,
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '& path': {
            stroke: '#C9C7C7',
          },
          '&:hover': {
            borderColor: '#DCDCDC',
          },
        },
        '&$error': {
          borderColor: '#E32A38!important',
        },
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
      root: {
        paddingBottom: '0px!important',
      },
      formControl: {
        'label + &': {
          marginTop: 0,
        }
      },
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '16px',
        lineHeight: '24px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px, 16px) scale(1)',
      },
      shrink: {
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 8px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      filled: {
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 8px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        fontFamily: 'SegoeUI',
        '&$focused': {
          color: `${colors.Common.textGrey} !important`,
          '&$disabled': {
            color: `${colors.Common.textGrey} !important`,
          },
        },
      },
      disabled: {},
      asterisk: {
        display: 'none',
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 'none',
        padding: '0',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      // label: {
      //   '&:hover path': {
      //     stroke: '#AAA9A9',
      //   },
      // },
    },
  },
});

export const CurrentInputTnvedGpc = createMuiTheme({
  typography: {},
  overrides: {
    MuiAutocomplete: {
      listbox: {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      option: {
        backgroundColor: 'transparent',
        paddingLeft: '0',
        paddingRight: '0',
        paddingTop: '16px',
        paddingBottom: '16px',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
        '&:hover': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
        },
      },
      inputRoot: {
        border: '1px solid #C9C7C7',
        borderRadius: '4px',
        paddingRight: '0!important',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
      },
      input: {
        padding: '23px 68px 7px 15px !important',
        // borderLeft: '4px solid #60E84A',
        '&:required': {
          borderRadius: '4px',
          borderLeft: '4px solid #E32A38',
          "&[valid='true']": {
            borderLeft: 'none',
          },
          "&[valid='false']": {
            borderLeft: `4px solid #E32A38`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
        '&:disabled': {
          borderLeft: 'none',
        },
      },
      popper: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
        padding: '0',
        borderRadius: '8px',
      },
      paper: {
        margin: '8px 0 0 0',
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      popupIndicator: {
        marginRight: '15px',
      },
      clearIndicator: {
        padding: '4px',
        visibility: 'inherit',
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
    MuiInputBase: {
      root: {
        marginBottom: '16px',
        fontFamily: 'SegoeUI',
        '&$disabled': {
          cursor: 'default',
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
          },
        },
      },
      input: {
        width: '0',
        fontSize: '16px',
        color: `${colors.Common.textColor}`,
        lineHeight: '24px',
        height: 'auto',
        fontFamily: 'SegoeUI',
        '&::placeholder': {
          color: `${colors.Common.textColor}`,
          opacity: '1',
          transform: 'translateY(-10px)',
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
      root: {
        paddingBottom: '0px!important',
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      formControl: {
        'label + &': {
          marginTop: 0,
        }
      },
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '16px',
        lineHeight: '24px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px, 16px) scale(1)',
      },
      shrink: {
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 8px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      filled: {
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 8px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        fontFamily: 'SegoeUI',
        '&$focused': {
          color: `${colors.Common.textGrey} !important`,
          '&$disabled': {
            color: `${colors.Common.textGrey} !important`,
          },
        },
      },
      disabled: {},
      asterisk: {
        display: 'none',
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 'none',
        padding: '0',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$disabled path': {
          stroke: '#AAA9A9',
        },
      },
      // label: {
      //   '&:hover path': {
      //     stroke: '#AAA9A9',
      //   },
      // },
    },
    MuiFormControl: {
      root: {
        marginTop: '16px',
      },
    },
  },
});
