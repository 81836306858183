import * as React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { DateRange } from 'react-date-range';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { addDays } from 'date-fns';
import ru from 'date-fns/locale/ru';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Button, IconButton, MuiThemeProvider, Popover } from '@material-ui/core';
import {
  IconClose,
  IconCalendarBlue,
  IconCalendarSmall,
} from '../../../../../common/icons/customIcons';
import {
  DateRangeCont,
  DefaultFilterPopover,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
  FilterBtnReset,
} from './Filters.styled';
import { CustomInput } from '../../../../../components';
import { config, selectLang } from '../../../../../config';

class TmValidDateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.selectLang = selectLang(config.lang);
    const tz = moment.tz.guess();
    moment.locale(this.selectLang);
    moment.tz.setDefault(tz);

    const date = new Date();
    let startDate = date;
    let endDate = date;
    if (props.currentValue.from) {
      startDate = moment(props.currentValue.from).toDate();
      endDate = moment(props.currentValue.to).toDate();
    }

    this.state = {
      anchorEl: null,
      selected: !!props.currentValue.from,
      selectionRange: {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      },
      currentValue: props.currentValue,
      textValue: '',
    };
    this.lang = { ru: ru, kk: ru };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (
      _.isNull(nextProps.currentValue.from) &&
      !_.isNull(state.currentValue.from)
    ) {
      const date = new Date();
      return {
        anchorEl: null,
        selected: false,
        selectionRange: {
          startDate: date,
          endDate: date,
          key: 'selection',
        },
        currentValue: nextProps.currentValue,
        textValue: '',
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextState, this.state);
  }

  handleSelect = (range) => {
    this.setState({
      selected: true,
      selectionRange: range.selection,
    });
  };

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleAccept = () => {
    const startDate = this.state.selectionRange.startDate;
    const endDate = this.state.selectionRange.endDate;
    const equalDate = startDate.getTime() === endDate.getTime();
    const textValue = equalDate
      ? moment(startDate).format('DD.MM.YYYY')
      : `${moment(startDate).format('DD.MM.YYYY')} - ${moment(endDate).format(
          'DD.MM.YYYY',
        )}`;

    const range = equalDate
      ? {
          from: moment(startDate).toISOString(true),
          to: moment(startDate.setHours(23, 59, 59, 999)).toISOString(true),
        }
      : {
          from: moment(startDate).toISOString(true),
          to: moment(endDate)
            .add(1, 'days')
            .subtract(1, 'seconds')
            .toISOString(true),
        };

    const { name } = this.props;

    if (moment(range.from).isAfter(range.to)) {
      this.handleDissmiss();
    } else {
      this.props.onAccept(name, range);
      this.setState({
        currentValue: range,
        textValue: textValue,
        anchorEl: null,
      });
    }
  };

  handleDissmiss = () => {
    const date = new Date();
    const { name } = this.props;
    this.setState({
      anchorEl: null,
      selected: false,
      selectionRange: {
        startDate: date,
        endDate: date,
        key: 'selection',
      },
      textValue: '',
    });
    this.props.onDismiss(name);
  };

  render() {
    const { t } = this.props;
    const { anchorEl, textValue } = this.state;
    const open = Boolean(anchorEl);
    const date = new Date();

    return (
      <div>
        {this.props.showButton ? (
          <MuiThemeProvider theme={FilterBtn}>
            <FilterButton>
              <Button
                aria-owns={open ? 'simple-popper' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}>
                {_.isNull(this.props.currentValue.from) ? (
                  <IconCalendarSmall />
                ) : (
                  <IconCalendarBlue />
                )}
              </Button>
            </FilterButton>
          </MuiThemeProvider>
        ) : (
          <CustomInput
            id="input-date"
            name="date"
            label={t('Дата оформления')}
            onUpdate={() => {}}
            onChange={() => {}}
            disabled
            onClick={this.handleClick}
            value={textValue}
            type="text"
          />
        )}
        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Срок действия')}</h6>
              <MuiThemeProvider theme={FilterBtn}>
                <IconButton className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </IconButton>
              </MuiThemeProvider>
            </FilterTitle>
            <FilterContent style={{paddingTop:'0px'}}>
              <DateRangeCont>
                <DateRange
                  ranges={[this.state.selectionRange]}
                  onChange={this.handleSelect}
                  locale={this.lang[this.selectLang]}
                  showMonthAndYearPickers={true}
                  showDateDisplay={false}
                  showSelectionPreview={false}
                  showPreview={false}
                  showMonthArrow={false}
                  minDate={addDays(date, -3000)}
                  weekdayDisplayFormat="EEEEEE"
                  // maxDate={date}
                />
              </DateRangeCont>
            </FilterContent>
            <FilterBtns>
              <MuiThemeProvider theme={FilterBtnReset}>
                <Button
                  disabled={!this.state.selected}
                  onClick={this.handleDissmiss}>
                  {t('Сбросить')}
                </Button>
              </MuiThemeProvider>
              <Button
                disabled={!this.state.selected}
                onClick={this.handleAccept}>
                {t('Применить')}
              </Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </div>
    );
  }
}

TmValidDateFilter.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  currentValue: PropTypes.object.isRequired,
  parentId: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
};

TmValidDateFilter.defaultProps = {
  showButton: true,
};
export default withTranslation()(TmValidDateFilter);
