import styled from 'styled-components';

export const TableWrapper = styled.div`
  margin-top: 30px;
`;

export const SignFormRow = styled.div`
  padding-bottom: 8px;
`;

export const SignFormRowValue = styled.span`
  font-size: 16px;
  line-height: 24px;
`;

export const SignFormRowSubTitle = styled.span`
  color: #808080;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

export const SignFormRowTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 16px;
  padding-top: 35px;
`;

export const SignFormSpace = styled.div`
  height:
`;
