export const validatePhone = (phone) => {
  if (phone[0] === '+' && phone[1] === '7') {
    phone = phone.replace(/\D/g, '');
    return phone === '' || phone.length === 11;
  }
  return true;
};

export const validatePhoneInput = (phone) => {
  if (phone[0] === '+' && phone[1] === '7') {
    phone = phone.replace(/\D/g, '');
    return phone.length === 11;
  }
  if (phone[0] === '+' && phone[1] !== '7') {
    phone = phone.replace(/\D/g, '');
    return phone.length === 12;
  }
  return false;
};

export const validateEmail = (email) => {
  if (!email || !email.length) return false;
  // eslint-disable-next-line
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!email.match(regex);
};

export const validateEmailAndEmpty = (email) => {
  if (!email || !email.length) return true;
  // eslint-disable-next-line
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!email.match(regex);
};

export const validateOkved = (okved) => {
  const regex = /^\d{1,2}(\.[\d]{1,2}|){1,2}$/;
  return !!okved.match(regex);
};

export const validateEmailOnDirty = (value) => {
  if (!value || !value.length) return true;
  return undefined;
};

export const validatePassword = (value) => {
  if (!value || !value.length) return true;
  return undefined;
};
