import * as React from 'react';
import * as PropTypes from 'prop-types';
import { assign } from 'lodash';
import { AlertPopup } from './Alert.styled';
import { IconClose, IconCloseWhite, IconInfoCard } from '../../common/icons/customIcons';

/**
 *  Пример использования:
 *   this.props.alert.error("К сожалению, ваш сертификат просрочен. Выберите другой.", {
                timeout: 2000 ,
                onOpen: () => { console.log('hey') }, // callback that will be executed after this alert open
                onClose: () => { console.log('closed') } // callback that will be executed after this alert is removed
            });
 */
class Alert extends React.Component {
  render() {
    let { style, options } = this.props;
    if (options.containerStyle) {
      assign(style, {
        ...options.containerStyle,
      });
    }
    return (
      <AlertPopup style={style}>
        {options.type === 'info' && (
          <span>
            <IconInfoCard style={{ width: '24px', height: '24px', marginRight: '12px' }} />
          </span>
        )}
        <p>{this.props.message}</p>
        {!options.deleteCloseButton && (
          <button onClick={this.props.close}>
            {options.type === 'info'
              ? (<IconClose style={{ width: 20, height: 20 }} />)
              : (<IconCloseWhite style={{ width: 20, height: 20 }} />)
            }
          </button>
        )}
      </AlertPopup>
    );
  }
}

Alert.propTypes = {
  options: PropTypes.object.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  close: PropTypes.func.isRequired,
};

export default Alert;
