import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import * as selectors from './ducks/Authentification.selectors';
import { login, remindPassword } from './ducks/Authentification.actions';
import AuthentificationLoginView from './views/AuthentificationLogin.view';
import { HeaderLanguageSelection } from '../../components';
import { isKZ } from '../../config';
import SvgLogo from '../../common/icons/logo';
import { LogoKz, LogoRusKz } from '../../common/icons/customIcons';
import ChangePasswordView from './views/ChangePassword/ChangePassword.view';
import { throwNotification } from '../../common/structure';
import { push } from 'connected-react-router';
import {
  AuthContainer,
  AuthBlockLeft,
  AuthBlockRight,
  LogoBlock,
} from './Authentification.styled';

// const domain = !isLocalhost
//     ? `${window.location.protocol}//${window.location.hostname.split('.').slice(1).join('.')}`
//     : '/';

class Authentification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginMode: true,
      isHowToOpen: false,
      emailValid: true,
    };
  }

  onChangeMode = () => {
    if (this.props.loading) return;
    this.setState({ loginMode: !this.state.loginMode });
  };

  render() {
    const { t, locale, changePassword, location } = this.props;
    // const logoKZ = locale === 'kz' ? <LogoKz /> : <LogoRusKz />;
    const logoKZ = <LogoKz />;
    return (
      <AuthContainer>
        <AuthBlockLeft>
          <LogoBlock>{isKZ ? logoKZ : <SvgLogo />}</LogoBlock>
        </AuthBlockLeft>
        <AuthBlockRight>
          <HeaderLanguageSelection />
          <div>
            {changePassword ? (
              <ChangePasswordView
                loading={this.props.loading}
                location={location}
                onChangePassword={this.props.onChangePassword}
                onLogin={this.props.onLogin}
                throwError={this.props.throwError}
                goToPage={this.props.goToPage}
              />
            ) : (
              <AuthentificationLoginView
                loading={this.props.loading}
                onChange={this.handleChange}
                onLogin={this.props.onLogin}
                onRemindPassword={this.props.onRemindPassword}
                isActiveSessionOpenModal={this.props.isActiveSessionOpenModal} //449
                activeSessionInfo={this.props.activeSessionInfo}
              />
            )}
          </div>
        </AuthBlockRight>
        {/**
         * скрыто до лучших времён.
         */}
        {/* <Footer>
                    {t('Впервые в системе?')}<br />
                    <Link to="/registration">{t('Зарегистрируйтесь')}</Link> {t('или узнайте')} <a rel="noopener noreferrer" href={domain}>
                        {t('больше на сайте эксперимента')}</a>.
                </Footer> */}
      </AuthContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: selectors.loading(state),
  locale: selectors.locale(state),
  isActiveSessionOpenModal: selectors.isActiveSessionOpenModal(state), //449
  activeSessionInfo: selectors.activeSessionInfo(state),
  location: state.router.location,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLogin: (data) => login(data),
      onRemindPassword: (data) => remindPassword(data),
      //onChangePassword : (data) => changePassword(data),
      throwError: (message) => throwNotification(message, 'error'),
      goToPage: (page) => push(page),
    },
    dispatch,
  );
};

Authentification.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onRemindPassword: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  isActiveSessionOpenModal: PropTypes.bool.isRequired,
  activeSessionInfo: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  onChangePassword: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Authentification);
