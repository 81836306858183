import styled from "styled-components";
import { createMuiTheme } from "@material-ui/core";
import colors from "../../../styles/Common.colors";


export const HeaderMainRight = styled.div`
    display: flex;
    align-items: center;
`;
export const HeaderMainLeft = styled.div`
    display: flex;
    align-items: center;
`;
export const HeaderLogo = styled.div`
    display: flex;
    padding: 23px 40px 23px 25px;
`;
export const HeaderLink = styled.a`
    padding: 20px;
    //box-shadow: 0px 1px 0px #F2F2F2, inset 0px 1px 0px #F2F2F2;
    cursor: pointer;
    transition:background .4s ease;
    display: inline-flex;
    align-items: center;
    justify-content: ${props => props.open ? 'flex-start' : 'center'};
    &.open-menu{
        box-shadow: inset  0px 1px 1px #F2F2F2;
        justify-content: flex-start;
    }
    &:disabled{
        color: #F9F9F9!important;
        cursor: default;
        background-color: #F9F9F9;
        pointer-events: none
    }
`;
export const HeaderLinkTitle = styled.span`
    font-size: 16px;
    opacity: 0.6;
    font-weight: 500;
    transition: .4s ease;
    &.active {
        color: #FFFFFF;
        opacity: 1;
    }
    &:hover{
        color: #FFFFFF;
        opacity: 1;
    }
`;

export const HeaderMain = styled.div`
    display: block;
    //background: #FCFCFC;
    background: ${colors.Home.background};
    box-shadow: 0 1px 0 #E5E5E5;
    margin-bottom: 1px;
`;
export const HeaderMainInner = styled.div`
    display: flex;
    justify-content: space-between;
    //padding: ${props => props.open ? '0 0 0 0' : '0 0 0 70px'};
    padding: 0 40px 0 20px;
    align-items: stretch;
`;
export const HeaderMainCell = styled.div`
    padding: 0 0;
    display: flex;
    align-items: center;
    transition: .4s ease;
`;
export const HeaderMainCellAlert = styled(HeaderMainCell)`
    text-align: center;
    margin-left: auto;
    justify-content: center;
    button {
        background-color: transparent!important;
        border: none;
        box-shadow: none;
        padding: 0px;
    }
`;
export const HeaderMainMenu = styled(HeaderMainCellAlert)`
    min-width: 70px;
    border-left: none;

`;
export const HeaderMainCellSearch = styled(HeaderMainCell)`
    flex-grow: 1;
    position: relative;
    height: 56px;
    width: 413px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-sizing: border-box;
    border-radius: 4px;
    & svg g {
        transition: .4s ease;
    }
    &:hover{
        & svg g {
            opacity: 1; 
        }
    }
    &:focus-within{
        border: 1px solid #FFFFFF;
    }
`;
export const HeaderSearchIcon = styled(HeaderMainCell)`
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
`;
export const HeaderMainCellLogin = styled(HeaderMainCell)` 
    padding-left: 20px;
    white-space: nowrap;
    &:hover{
        opacity: 0.7; 
    }  
`;
export const LoginAvatar = styled.div`
    width: 40px;
    height: 40px;
    background: ${colors.Header.profileLogo};
    font-size: 16px;
    color: ${colors.Header.profileColor};
    font-weight: 500;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    margin-right: 10px;
`;
export const LoginType = styled.span`
    font-size: 12px;
    color: #434343;
    line-height: 16px;
    text-transform: capitalize;
`;
export const LoginName = styled.span`
    font-size: 16px;
    color: #FFFFFF;
    line-height: 24px;
    text-transform: capitalize;
    margin-right: 11px;
`;
export const LoginUserIcon = styled.span`
    width: 24px;
    height: 24px;
`;
export const LoginCompany = styled.span`
    font-size: 16px;
    color: #52535A;
    line-height: 21px;
    font-weight: 600;
    text-transform: capitalize;
`;
export const LoginInfo = styled.span`
    display: flex;
    align-items: center;
    padding-left: 15px;
`;
export const LogoutIcon = styled.span`
    width: 20px;
    height: 20px;
`;
export const LoginIconDrop = styled.span`
    position: absolute;
    right: 35px;
`;
export const NotBlockMin = styled.div`
    padding: 16px 30px 16px 16px;
    transition: background-color .4s ease;
    border-bottom: 1px solid #F2F2F2;
    outline: none;
    &:hover{
        background-color: #F7F7F7;
    }
    &.bordered{
        border-left: 4px solid ${colors.Common.background};
    }
`;
export const NotTitle = styled.h3`
    color: #ADADAD;
    margin: 0 0 10px;
    font-size: 12px;
    font-weight: 600;
`;
export const NotContent = styled.div`
    font-size: 14px;
    color: #52535A;
    font-weight: normal;
`;
export const MenuItemSpan = styled.span`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${colors.Common.textColor};
    font-size: 16px;
    text-decoration: none;
    padding: 20px;
    line-height: 24px;
    width: 100%;
    transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
     
    & svg path {
        transition: fill 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
`;
export const MenuItemLink = styled.a`
    text-decoration: none;
    width: 100%;
`;
export const MenuItemDot = styled.span`
    color: green;
`;
export const HeaderLangBtn = styled(HeaderMainCell)`
    min-width: 80px;
    height: auto;
    border-left: 1px solid #F2F2F2;
    border-right: 1px solid #F2F2F2;
    text-align: center;
    margin-left: auto;
    justify-content: center;
    &:hover, &:focus-within{
        background: #fff;
            box-shadow: 0 4px 15px rgba(0, 0, 0, .15);
    } 
`;
export const HeaderLogout = styled(HeaderMainCell)`
    min-width: 80px;
    border-left: 1px solid #F2F2F2;
    border-right: 1px solid #F2F2F2;
    text-align: center;
    margin-left: auto;
    justify-content: center;
    &:hover, &:focus-within{
        background: #fff;
            box-shadow: 0 4px 15px rgba(0, 0, 0, .15);
    }
`;
export const HeaderMenuSelect = createMuiTheme({
    typography: {},
    overrides: {
        MuiMenuItem: {
            root: {
                height: "auto",
                padding: "0",
                paddingTop: "0",
                paddingBottom: "0",
                '&:hover': {
                    backgroundColor: '#F5F5F5',
                }
            }
        },
        MuiList: {
            padding: {
                paddingTop: "0",
                paddingBottom: "0"
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: "32px",
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "8px",
                minWidth: "296px!important",
                marginTop: "-1px"
            },
            elevation8: {
                boxShadow: "0px 8px 48px rgba(86, 118, 129, 0.1)",
            }
        },
        MuiTypography: {
            root: {
                flexGrow: "1",
                display: "flex",
                justifyContent: "space-between",
                color: `${colors.Common.textColor}`,
            }
        }
    }
});
export const NotifBtn = createMuiTheme({
    typography: {},
    overrides: {
        MuiButton: {
            root: {
                width: "100%",
                backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
                borderRadius: "0",
                color: `${colors.Buttons.buttonPrimary.color}`,
                '&:hover': {
                    boxShadow: `0px 10px 30px ${colors.Buttons.buttonPrimary.boxShadowHovered}`,
                    backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
                    opacity: "0.8",
                    color: `${colors.Buttons.buttonPrimary.color}`
                }
            },
            text: {
                padding: "16px 20px",
                fontSize: "16px",
                textTransform: "none",
                fontWeight: "600"
            },
            label: {
                color: `${colors.Buttons.buttonPrimary.color}`,
                justifyContent: "flex-start"
            }
        }
    }
});
export const AlertBtn = createMuiTheme({
    typography: {},
    overrides: {
        MuiIconButton: {
            root: {
                minHeight: "100%",
                transition: ".4s ease",
                borderRadius: "0",
            },
            colorSecondary: {
                color: "rgba(0, 0, 0, 0.54)",
                width: "80px"
            },
            label: {
                transition: ".4s ease",
                width: "35px",
                height: "35px",
                '&:hover': {
                    opacity: "0.7"
                },
            }
        },
    }
});

export const DotsBtn = createMuiTheme({
    typography: {},
    overrides: {
        MuiIconButton: {
            root: {
                width: "56px",
                height: "56px",
                transition: ".4s ease",
                borderRadius: "50%",
            },
            colorSecondary: {
                color: "rgba(0, 0, 0, 0.54)",
                padding: '0',
            },
            label: {
                '@media (min-width: 1920px)': {
                    width: "41px",
                    height: "41px"
                }
            }
        },
        MuiButtonBase: {
            root: {
                backgroundColor: "transparent!important",
                padding: "0 10px",
                '&:hover': {
                    background: "#fff",
                    boxShadow: "0 4px 15px rgba(0, 0, 0, .15)"
                },
            },
        }
    }
});
export const LoginBtn = createMuiTheme({
    typography: {},
    overrides: {
        MuiIconButton: {
            root: {
                width: "80px",
                minHeight: "100%",
                transition: ".4s ease",
                borderRadius: "0",
                '@media (min-width: 1920px)': {
                    width: "100px",
                }
            },
            colorSecondary: {
                color: "rgba(0, 0, 0, 0.54)",
                width: "80px"
            },
            label: {
                '@media (min-width: 1920px)': {
                    width: "41px",
                    height: "41px"
                }
            }
        },
        MuiButtonBase: {
            root: {
                backgroundColor: "transparent!important",
                padding: "0 10px",
                '&:hover': {
                    background: "#fff",

                },
            },
        }
    }
});
export const NotPaper = createMuiTheme({
    typography: {},
    overrides: {
        MuiMenu: {
            paper: {
                maxWidth: "540px",
                minWidth: "540px",
                maxHeight: "calc(100vh - 105px)!important",
                overflowY: "hidden",
                boxShadow: "0px 8px 48px rgba(86, 118, 129, 0.1)",
                borderRadius: "8px",
                color: `${colors.Common.textColor}`,
                fontFamily: 'SegoeUI',
            }
        },
        MuiList: {
            padding: {
                paddingTop: "0",
                paddingBottom: "0"
            }
        }
    }
});
export const LoginActionSelect = createMuiTheme({
    typography: {},
    overrides: {
        MuiMenuItem: {
            root: {
                height: "auto",
                padding: "18px",
                paddingTop: "18px",
                paddingBottom: "18px",
                fontSize: "16px",
                color: "#52535A",
                '&:hover': {
                    backgroundColor: `${colors.Header.profileMenuBackground}`,
                    color: `${colors.Header.profileMenuColor}`
                }
            }
        },
        MuiPaper: {
            root: {
                marginLeft: "12.2px",
                borderRadius: "0 0 3px 3px!important"
            },
            elevation8: {
                boxShadow: "0 5px 14px rgba(0, 0, 0, .15)"
            }
        },
        MuiList: {
            padding: {
                paddingTop: "0",
                paddingBottom: "0"
            }
        },
        MuiListItem: {
            button: {
                '&:focus': {
                    backgroundColor: "transparent"
                }
            }
        },
        MuiPopover: {
            paper: {
                minWidth: "340px"
            }
        }
    }
});
export const SearchInput = createMuiTheme({
    typography: {},
    overrides: {
        MuiInputBase: {
            input: {
                padding: "29px 20px 30px",
                fontSize: "16px",
                color: "#FFFFFF",
                fontFamily: "SegoeUI",
            }
        },
        MuiPaper: {
            root: {
                borderRadius: "0 0 3px 3px",
                maxHeight: "300px!important",
                overflowY: "auto"
            },
            elevation2: {
                boxShadow: "0 4px 15px rgba(0, 0, 0, .15)"
            }
        },
        MuiMenuItem: {
            root: {
                padding: "13px 23px",
                paddingTop: "13px",
                paddingBottom: "13px",
                display: "flex",
                color: "#52535A",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "SegoeUI",
                height: "auto"
            }
        },
        MuiListItem: {
            button: {
                '&:hover': {
                    backgroundColor: "#F7F7F7"
                },
                '&:focus': {
                    backgroundColor: "#F7F7F7",
                    border: '2px'
                }
            }
        }
    }
});
export const LoginButton = createMuiTheme({
    typography: {},
    overrides: {
        MuiButton: {
            root: {
                minWidth: "340px",
                minHeight: "80px",
                position: "relative",
                borderRadius: "0"
            },
            label: {
                display: "flex",
                flexDirection: "row",
                lineHeight: "1.2",
                alignItems: "center",
                justifyContent: "flex-start"
            },
            text: {
                padding: "0 115px 0 30px"
            }
        },
        MuiButtonBase: {
            root: {
                backgroundColor: "transparent!important",
                padding: "0 10px",
                '&:hover': {
                    backgroundColor: "transparent!important",
                    color: "#000"
                }
            }
        }
    }
});

export const HeaderLangSelect = createMuiTheme({
    typography: {},
    overrides: {
        MuiMenuItem: {
            root: {
                width: "78px",
                height: "100%",
                padding: "30px 0",
                paddingTop: "30px",
                paddingBottom: "30px",
                borderTop: "1px solid #F2F2F2",
                color: '#63666A',
                '&:hover': {
                    backgroundColor: `${colors.Common.background}`,
                    color: '#fff'
                },
                '@media (min-width: 1920px)': {
                    width: "98px",
                    fontSize: "16px",
                }
            },
        },
        MuiSelect: {
            select: {
                padding: "0 30px 0 25px",
                color: "#63666A",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "21px",
                display: "flex",
                alignItems: "center",
            },
            selectMenu: {
                height: "100%"
            }
        },
        MuiListItem: {
            root: {
                '&$selected': {
                    '&:hover': {
                        color: `${colors.Common.background}`,
                    }
                }
            }
        },
        MuiList: {
            padding: {
                paddingTop: "0",
                paddingBottom: "0"
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "2px",
                minWidth: "auto",
                marginTop: "-1px",
                //left: "0!important"
                top: '81px'
            },
            elevation8: {
                boxShadow: "none"
            }
        },
        MuiTypography: {
            root: {
                flexGrow: "1",
                display: "flex",
                justifyContent: "space-between"
            }
        },
        MuiInput: {
            underline: {
                height: '100%',
                color: 'transparent',
                '&:after': {
                    borderBottom: "none!important"
                },
                '&:before': {
                    borderBottom: "none!important"
                },
                '&:hover:not($disabled):after': {
                    backgroundColor: 'transparent',
                    borderBottom: "none"
                },
                '&:hover:not($disabled):before': {
                    backgroundColor: 'transparent',
                    borderBottom: "none!important"
                }
            }
        }
    }
});