import * as React from 'react';
import * as PropTypes from 'prop-types';
import memoize from 'memoize-state';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { push, replace } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import { withCookies } from 'react-cookie';
import * as selectors from './ducks/ProductGrid.selectors';
import { Button, Checkbox, MuiThemeProvider } from '@material-ui/core';
import _ from 'lodash';
import {
  getColumns,
  getSelectedIds,
  returnSimplifiedSetFields,
  sortColumns,
} from './ducks/ProductGrid.utils';
import { config, isELK } from '../../../config';
import {
  findStatusObj,
  getSortField,
  gtinDisplay,
} from '../../../common/utils/utils';
import {
  deleteNotification,
  throwNotification,
} from '../../../common/structure';
import { loadCertificates } from '../../profile/ducks/Profile/Profile.actions';
import {
  archiveCards,
  clearAlert,
  deleteProducts,
  loadProductData,
  openDraft,
  publishProduct,
  sendForModerationProduct,
} from '../ducks/Products.actions';

import {
  BrandFilter,
  CategoryFilter,
  // ContentProviderFilter,
  DateFilter,
  GtinFilter,
  NameFilter,
  PackagesFilter,
  PhotoFilter,
  StatusFilter,
  CategoryTnvedNameFilter,
  PackingTypeFilter,
  IsValidFilter,
  // IsExemplarFilter,
  FiltersPopup,
} from './filters';

import {
  BrandCell,
  CategoryCell,
  CheckboxCell,
  // ContentProviderCell,
  CreatedCell,
  GtinCell,
  NameCell,
  PackagesCell,
  // StatusCell,
  PackingPurpose,
} from './cell';

import TableGrid from './TableGrid';
import {
  ColumnOrder,
  ExportModal,
  ImportModal,
  GridPageSize,
  PhotoPopover,
  ProductsGridActions,
  ProductsGridActionSelect,
  ProductVersions,
  SelectSwitchingGoods,
  MassMediaModal,
} from '../views';

import {
  AddProductGridBtns,
  ColumnCellPhoto,
  GridActionsView,
  GridSelect,
  GridTable,
  ResetFilterButton,
  Sorted,
  SortIcon,
  // GridExamplarFilter,
  ColumnCellStatus,
  ProductContainer,
} from './ProductsGrid.styled';
import {
  CheckBoxGridThead,
  ColumnCellCheckHeader,
  ColumnCellCheckInner,
  ColumnHeader,
  ColumnHeaderPhoto,
  ColumnHeaderIsValid,
  DefaultGridButton,
  GridActions,
  GridActionsLeft,
  GridActionsRight,
  GridEmpty,
  GridEmptyTitle,
  GridEmptySubstring,
  GridTableContainerRedesign,
  ProductsGridContainer,
  SortingBtn,
  WithoutSortingBtn,
  WithoutSortingTitle,
  DefaultTooltip,
} from '../../../common/styles/Common.styled';

import {
  IconCheck,
  IconExport,
  IconGridEmpty,
  IconGridView,
  // IconGridViewDisabled,
  IconSortDown,
  IconSortUp,
  IconAddProduct,
  IconImport,
  IconAddProductDisabled,
  IconConsumerPackage,
  IconGroupPackage,
  IconTransportPackage,
  IconTechnicalCard,
  // IconExample,  
  IconActivity,
  IconPlusNew,
  IconNotMarkedGood,
  IconImage,
  IconCheckbox,
  IconEllipse,
} from '../../../common/icons/customIcons';

import {
  CertificateSelectModal,
  ConfirmDialog,
  ModalLoader,
  Preloader,
  RedirectModal,
  RemainderModal,
  SuccessedModal,
} from '../../../components';

import {
  AddProductFull,
  AddProductSelect,
  AddProductSelectItemSpan,
} from '../../../common/structure/sidebar/Sidebar.styled';

import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';

import {
  ProductRegistration,
  ProductRegistrationIcon,
  ProductRegistrationTitle,
} from '../views/ProductRegistration/ProductRegistration.styled';

const ComponentItems = ({ Item, t, userInfo, handleCloseMenuAddProduct }) => {
  // const { isElkUser, isKitEnabled, isTechGtinEnabled } = user;
  return (
    <>
      <Item
        onClick={handleCloseMenuAddProduct('unit')}
        disableGutters={true}
        disabled={false}>
        <AddProductSelectItemSpan>
          <ListItemIcon>
            <IconConsumerPackage />
          </ListItemIcon>
          {t('Потребительская упаковка')}
        </AddProductSelectItemSpan>
      </Item>
      <Item
        onClick={handleCloseMenuAddProduct('group')}
        disableGutters={true}
        disabled={false}>
        <AddProductSelectItemSpan>
          <ListItemIcon>
            <IconGroupPackage />
          </ListItemIcon>
          {t('Групповая упаковка')}
        </AddProductSelectItemSpan>
      </Item>
      <Item
        onClick={handleCloseMenuAddProduct('transport')}
        disableGutters={true}
        disabled={false}>
        <AddProductSelectItemSpan>
          <ListItemIcon>
            <IconTransportPackage />
          </ListItemIcon>
          {t('Транспортная упаковка')}
        </AddProductSelectItemSpan>
      </Item>
      {userInfo.isTechCardSupport && (
        <Item
          onClick={handleCloseMenuAddProduct('technical')}
          disableGutters={true}>
          <AddProductSelectItemSpan>
            <ListItemIcon>
              <IconTechnicalCard />
            </ListItemIcon>
            {t('Техническая карточка')}
          </AddProductSelectItemSpan>
        </Item>
      )}
      {userInfo.isNotMarkedSupport && (
        <Item
          onClick={handleCloseMenuAddProduct('technicalUnmarked')}
          disableGutters={true}>
          <AddProductSelectItemSpan>
            <ListItemIcon>
              <IconNotMarkedGood />
            </ListItemIcon>
            {t('Немаркируемый товар')}
          </AddProductSelectItemSpan>
        </Item>
      )}
      {/*временно отключаем*/}
      {/* {isElkUser && <>
            <Item
                onClick={handleCloseMenuAddProduct('kit')}
                disableGutters={true}
                disabled={!isKitEnabled}
                className="hovered-item"
            >
                <AddProductSelectItemSpan>
                    <Tooltip
                        arrow
                        placement="bottom-start"
                        title={t("Описание комплекта")}
                    >
                        <ListItemIcon><IconAddProductNew /></ListItemIcon>
                    </Tooltip>
                    {t('Комплект')}
                </AddProductSelectItemSpan>
            </Item>
            <Item
                onClick={handleCloseMenuAddProduct('technical')}
                disableGutters={true}
                disabled={!isTechGtinEnabled}
                className="hovered-item"
            >
                <AddProductSelectItemSpan>
                    <Tooltip
                        arrow
                        placement="bottom-start"
                        title={t("Описание остатков и перемаркировка товара. Используется технический код товара из диапазона 290")}
                    >
                        <ListItemIcon><IconAddProductUnionNew /></ListItemIcon>
                    </Tooltip>
                    {t('Техническая карточка')}
                </AddProductSelectItemSpan>
            </Item>
        </>} */}
    </>
  );
};

const iconSwitch = (sort, name, action) => {
  switch (sort) {
    case 'ASC':
      return [
        <SortIcon id="ask" onClick={action} key={'sr_1'}>
          <IconSortUp />
        </SortIcon>,
        <Sorted key={'sr_2'}>{name}</Sorted>,
      ];
    case 'DESC':
      return [
        <SortIcon id="desc" onClick={action} key={'sr_1'}>
          <IconSortDown />
        </SortIcon>,
        <Sorted key={'sr_2'}>{name}</Sorted>,
      ];
    case '':
      return [
        <SortIcon onClick={action} key={'sr_1'}>
          <IconSortDown />
        </SortIcon>,
        <span key={'sr_2'}>{name}</span>,
      ];
    default:
      return <span>{name}</span>;
  }
};

const ConfirmStateInit = {
  isOpen: false,
  title: '',
  message: '',
  funcAccept: null,
  funcDismiss: null,
  funcCancel: null,
  acceptText: '',
  dismissText: '',
};

const SuccessedStateInit = {
  isOpen: false,
  message: '',
  tooltip: '',
};

const FiltersInit = {
  category: null,
  gtin: null,
  packages: null,
  photo: null,
  status: null,
  created: {
    from: null,
    to: null,
  },
  publicationTime: {
    from: null,
    to: null,
  },
  brand: null,
  name: null,
  contentProvider: {
    name: null,
    inn: null,
  },
  categoryTnved: null,
  packingPurpose: null,
  isValid: null,
  isExemplar: null,
  attributes: null,
};

const SortInit = [
  { field: 'category', direction: '' },
  { field: 'name', direction: '' },
  { field: 'status', direction: '' },
  { field: 'created', direction: '' },
  { field: 'brand', direction: '' },
  { field: 'contentProvider', direction: '' },
  { field: 'publicationTime', direction: '' },
  { field: 'categoryTnved', direction: '' },
  { field: 'packingPurpose', direction: '' },
];

const isNotActiveFilters = (filters) => _.isEqual(FiltersInit, filters);

const sizeConcat = _.flow([_.compact, _.size]);
const countActiveFilters = (filters) =>
  sizeConcat(
    _.map(filters, (i) => (_.isObjectLike(i) ? sizeConcat(_.valuesIn(i)) : i)),
  );

const mGenerateSelectDTO = memoize((selectDTOItems, data, status) => {
  const selectDTO = [];
  _.forEach(data, (i) => {
    if (i.goods && i.goods.status === status) {
      selectDTO.push({ id: i.goods.id, dto: 'goods' });
      return;
    }
    if (i.goods && i.draft && i.draft.status === status) {
      selectDTO.push({ id: i.goods.id, dto: 'draft' });
    }
  });
  return _.uniqBy(_.concat([], selectDTOItems, selectDTO), 'id');
});

class ProductsGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // pageSize: _.get(this.props, 'filters.pageSize', 10),
      pageSize: localStorage.savedPageSize ? _.toNumber(localStorage.getItem('savedPageSize')) : 10,
      pageNum: _.get(this.props, 'filters.pageNum', 0),
      // filters: _.get(this.props, 'filters.filters', FiltersInit),
      filters: localStorage.savedFilters ? _.assign({}, JSON.parse(localStorage.getItem('savedFilters'))) : FiltersInit,
      sort: _.has(this.props.filters, 'sort')
        ? _.uniqBy(
          _.compact(_.concat([], this.props.filters.sort, SortInit)),
          'field',
        )
        : SortInit,
      columns: [],
      defaultColumns: [],
      checkboxColumn: {},
      actionColumn: {},
      orderedColumns: [],
      selected: [],
      gtin: 0,
      deleteIds: [],
      archiveIds: [],
      selectItemProduct: {},
      modal: {
        isOpenExportModal: false,
        isOpenTableAppearance: false,
        isOpenVersions: false,
        isOpenMassAction: false,
        isOpenRedirectModal: false,
        successed: SuccessedStateInit,
        confirm: ConfirmStateInit,
        isOpenSelectCertModal: false,
        isOpenArchiveCertModal: false,
        isOpenRemainderModal: null,
        isOpenImportModal: false,
        isOpenMassMediaModal: false,
      },
      selectDTOItems: [],
      certificate: {},
      params: null,
      anchorEl: null,
      isOpenList: false,
      open: false,
    };

    // this.accountType = _.get(this.props, 'user.accountType', 'producer');// было до 338 задачи: роль-строка, теперь массив.
    this.accountTypes = _.cloneDeep(_.get(this.props, 'user.accountTypes', ['producer']));
    this.columnsGrid = [
      'photo',
      'created',
      'gtin',
      'name',
      'category',
      'packages',
      'brand',
      'categoryTnved',
      'publicationTime',
      'packingPurpose',
      'isValid',
      'isExemplar',
      'status',
      'isUnmarkable',
      'isTechCard',
    ];
    // this.columnsGrid = _.includes(['producer', 'importer'], this.accountType)
    // this.columnsGrid = _.includes(this.accountTypes, ('producer' || 'importer'))
    //   ? [...commonField, 'status']
    //   : [...commonField, 'contentProvider'];

    const paramsList = {
      ..._.pick(this.state, ['pageNum', 'pageSize', 'sort', 'filters']),
      // columns: this.columnsGrid,
      fields: _.concat(this.columnsGrid, 'isRemainder')
    };

    this.props.onLoadProductData(paramsList);
    this.mGetSelectedIds = memoize(getSelectedIds);
    this.mGetStatusByIds = memoize(this.getStatusByIds);
    this.mGetStatusByIds = memoize(this.getStatusByIds);
    this.mGetRemainderByIds = memoize(this.getRemainderByIds);
    this.mCurrentSDI = memoize(this.currentSDI);
    this.mIsNotActiveFilters = _.memoize(isNotActiveFilters);
    this.mCountActiveFilters = _.memoize(countActiveFilters);
    this.mIconSwitch = memoize(iconSwitch, { cacheSize: 5 });
    this.mDivisionDto = memoize(this.divisionDto);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!_.isNull(prevState.filters.status)) {
      const selectDTOItems = mGenerateSelectDTO(
        prevState.selectDTOItems,
        nextProps.data,
        prevState.filters.status,
      );
      if (!_.isEqual(selectDTOItems, prevState.selectDTOItems)) {
        return { selectDTOItems: selectDTOItems };
      }
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      this.updateColumns();
    }

    if (!prevProps.notification.message && !!this.props.notification.message) {
      this.props.throwNotification(
        this.props.notification.message,
        this.props.notification.type,
      );
      this.props.clearAlert();
    }

    if (!_.isEqual(prevProps.data, this.props.data)) {
      this.setState({ selected: [] });
    }
  }

  componentDidMount() {
    this.updateColumns();
    const { state } = this.props.location;
    let usedFilters = false;

    // * Переделан этот блок, т.к. приводил к нескольким вызовам на сервер за данными, проверка на локалстораж теперь в конструкторе

    // let savedFilters = localStorage.getItem('savedFilters');
    // // let savedSort = localStorage.getItem('savedSort');
    // const savedPageSize = localStorage.getItem('savedPageSize');

    // if (savedFilters) {
    //   savedFilters = JSON.parse(savedFilters);
    //   this.setState(
    //     {
    //       pageNum: 0,
    //       filters: _.assign({}, this.state.filters, savedFilters),
    //       selectDTOItems: [],
    //     },
    //     this.changeGridParams,
    //   );
    // }

    // if (savedPageSize) {
    //   this.setState(
    //     {
    //       pageSize: _.toNumber(savedPageSize),
    //     },
    //     this.changeGridParams,
    //   );
    // }

    /**
     * а нужно ли сохранять и сетать сорт?
     */
    // if (savedSort) {
    //     savedSort = JSON.parse(savedSort)
    //     this.setState({
    //         sort: savedSort
    //     }, this.changeGridParams);
    // }

    /**
     * TODO:
     * относится к задаче eacnc-199
     * удалить при мерже задачи eacnc-245, т.к. больше не будет перехода со страницы статистики
     * на страницу грида с установкой фильтра
     */
    if (state?.brand || state?.category || state?.packingPurpose) {
      this.setState(
        {
          pageNum: 0,
          filters: _.assign({}, this.state.filters, this.props.location.state),
          selectDTOItems: [],
        },
        this.changeGridParams,
      );
      usedFilters = true;
    }

    /**
     * TODO:
     * относится к задаче eacnc-199
     * удалить при мерже задачи eacnc-245, т.к. больше не будет перехода со страницы статистики
     * на страницу грида с установкой фильтра
     */
    if (usedFilters) {
      this.props.clearHistoryState({
        ...this.props.location,
        state: {},
      });
    }
  }

  goToProduct = () => {
    const { t } = this.props;
    if (!_.get(this.props, 'user.readyToWorkWithGoods')) {
      this.props.throwNotification(
        t('Создание карточек будет доступно после наполнения реестра товаров'),
        'error',
      );
    } else {
      this.props.deleteNotification();
      this.props.goToPage(config.urls.productNew);
    }
  };

  massActionsPopup = (action) => {
    this.setState((prevState) => ({
      modal: { ...prevState.modal, isOpenMassAction: action === 'open' },
    }));
  };

  confirmModal = (confirm, modal = {}, state = {}) => {
    const m = _.assign({}, modal, { confirm: confirm });
    const s = _.assign({}, state);
    this.setState((prevState) => ({
      ...prevState,
      ...s,
      modal: { ...prevState.modal, ...m },
    }));
  };

  confirmModalClose = () => {
    this.setState((prevState) => ({
      modal: { ...prevState.modal, confirm: ConfirmStateInit },
    }));
  };

  onSelectCertificate = (value) => {
    this.setState({ certificate: value });
  };

  onChooseCertificate = (...args) => {
    this.setState((prevState) => ({
      params: args,
      modal: {
        ...prevState.modal,
        isOpenSelectCertModal: !prevState.modal.isOpenSelectCertModal,
      },
    }));
  };

  onDismissCertificate = () => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        isOpenArchiveCertModal: !prevState.modal.isOpenArchiveCertModal,
        confirm: { ...prevState.modal.confirm, isOpen: true },
      },
    }));
  };

  toggleRow = (dto, nameDto) => {
    const item = { id: dto.id, nameDto: nameDto };
    let selected;
    if (_.find(this.state.selected, item)) {
      selected = _.reject(this.state.selected, item);
    } else {
      selected = _.concat(this.state.selected, item);
    }
    this.setState({ selected });
  };

  toggleSelectAll = () => {
    let selected = [];
    if (
      !_.isEmpty(this.props.data) &&
      this.props.data.length !== this.state.selected.length
    ) {
      this.props.data.forEach((x) => {
        const item = this.selectDataFromDTO(x, this.state.selectDTOItems);
        selected.push({ id: item.dto.id, nameDto: item.nameDto });
      });
    }
    this.setState({ selected });
  };

  onFilterDissmiss = (name) => {
    // удалить один из фильтров в localStorage
    let savedFilters = localStorage.getItem('savedFilters');
    if (savedFilters) {
      savedFilters = JSON.parse(savedFilters);

      let filters = _.cloneDeep(this.state.filters);

      if (name === 'created') {
        filters['created'] = { to: null, from: null };
        savedFilters['created'] = { to: null, from: null };
      } else if (name === 'publicationTime') {
        filters['publicationTime'] = { to: null, from: null };
        savedFilters['publicationTime'] = { to: null, from: null };
      } else if (name === 'contentProvider') {
        filters['contentProvider'] = { name: null, inn: null };
        savedFilters['contentProvider'] = { name: null, inn: null };
      } else {
        filters[name] = null;
        savedFilters[name] = null;
      }
      localStorage.setItem('savedFilters', JSON.stringify(savedFilters));
      this.setState(
        (prevState) => ({
          ...prevState,
          filters: filters,
          selectDTOItems: [],
        }),
        this.changeGridParams,
      );
    } else return;
  };

  onFilterAccept = (name, value) => {
    // сетаем фильтры в localStorage
    let savedFilters;
    savedFilters = localStorage.getItem('savedFilters');
    // если в localStorage есть ранее сохраненные фильтры,, то достаём строку и делаем объект
    if (savedFilters) {
      savedFilters = JSON.parse(savedFilters);
    }
    // если в сторадже ничего нет, то копируем изначальные фильтры.
    if (!savedFilters) {
      savedFilters = _.cloneDeep(FiltersInit);
    }
    savedFilters[name] = value;
    localStorage.setItem('savedFilters', JSON.stringify(savedFilters));

    if (name && toString(value)) {
      this.setState(
        {
          pageNum: 0,
          filters: _.assign({}, this.state.filters, { [name]: value }),
          selectDTOItems: [],
        },
        this.changeGridParams,
      );
    }
  };

  onFiltersSubmit = (filters) => { //504
    let savedFilters;
    savedFilters = localStorage.getItem('savedFilters');
    if (savedFilters) {
      savedFilters = JSON.parse(savedFilters);
    }
    if (!savedFilters) {
      savedFilters = _.cloneDeep(FiltersInit);
    }
    savedFilters = { ...savedFilters, ...filters }
    localStorage.setItem('savedFilters', JSON.stringify(savedFilters));

    this.setState(
      {
        pageNum: 0,
        filters: { ...this.state.filters, ...filters },
        selectDTOItems: [],
      },
      this.changeGridParams,
    );
  };

  resetFilters = () => {
    // убить все фильтры в localStorage
    localStorage.removeItem('savedFilters');
    this.setState(
      {
        filters: FiltersInit,
        selectDTOItems: [],
      },
      this.changeGridParams,
    );
  };

  onSort = (name) => () => {
    let direction;
    const sort = _.clone(this.state.sort);
    const field = _.find(sort, { field: name });
    switch (field.direction) {
      case 'ASC': {
        direction = 'DESC';
        break;
      }
      default:
        direction = 'ASC';
        //clear other sorts
        _.forEach(_.without(sort, field), (s) => (s.direction = ''));
        break;
    }
    field.direction = direction;
    // localStorage.setItem('savedSort', JSON.stringify(sort));
    this.setState({ sort }, this.changeGridParams);
  };

  getBuildParamsForList = () => {
    return {
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize,
      sort: getSortField(this.state.sort),
      filters: this.state.filters,
      fields: _.concat(
        getColumns(this.state.columns),
        'isRemainder',
        'isExemplar',
        'isUnmarkable',
        'isTechCard',
      ),
    };
  };

  changeGridParams = () => {
    const params = this.getBuildParamsForList();
    setTimeout(() => this.setState({ loading: false }), 200);
    this.props.onLoadProductData(params);
  };

  onChangeTableAppearanceShow = () => {
    const columns = returnSimplifiedSetFields(this.state.columns);
    this.setState({
      modal: {
        ...this.state.modal,
        isOpenTableAppearance: !this.state.modal.isOpenTableAppearance,
      },
      orderedColumns: columns,
    });
  };

  onChangeTableAppearance = (columns) => this.updateColumns(columns);

  onExportModal = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        isOpenExportModal: !this.state.modal.isOpenExportModal,
      },
    });
  };

  onImportModal = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        isOpenImportModal: !this.state.modal.isOpenImportModal,
      },
    });
  };

  onMassMediaModal = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        isOpenMassMediaModal: !this.state.modal.isOpenMassMediaModal,
      },
    });
  };

  onViewRemainder = (id, dtoName) => {
    this.setState({ modal: { ...this.state.modal, isOpenRemainderModal: id } });
  };

  onView = (id, dtoName) => {
    // const page = _.includes(['producer', 'importer'], this.accountType) // было до 338 задачи: роль-строка, теперь массив.
    const page = this.accountTypes.some(elem => elem === ('producer' || 'importer'))
      ? `${config.urls.product}/${dtoName}/${id}`
      : `${config.urls.print}/${dtoName}/${id}`;
    this.props.goToPage(page);
  };

  onPrint = (id, dtoName) => {
    this.props.goToPage(`${config.urls.print}/${dtoName}/${id}`);
  };

  onEdit = (id, dtoName) => {
    this.props.goToPage(`${config.urls.product}/${id}`);
  };

  onSendForModeration = (id) => {
    const { t } = this.props;
    const ids = _.isNumber(id)
      ? [id]
      : this.mGetSelectedIds(this.state.selected);
    const m2 = t('Ожидайте результатов проверки');
    const text =
      _.size(ids) === 1
        ? `${t('Карточка успешно отправлена на модерацию')}. ${m2}`
        : `${t('Карточки успешно отправлены на модерацию')}. ${m2}`;

    if (!_.isEmpty(ids)) {
      this.massActionsPopup('open');
      this.props.onSendForModerationProduct(ids, (error, data) => {
        if (!error) {
          this.setState(
            {
              modal: {
                ...this.state.modal,
                isOpenMassAction: false,
                successed: { isOpen: true, message: text, tooltip: null },
              },
            },
            this.changeGridParams,
          );
        } else {
          if (!_.isEmpty(data)) {
            this.props.throwNotification(text, 'success');
            this.changeGridParams();
          }
          if (_.isArray(error)) {
            _.map(error, (i) =>
              this.props.throwNotification(i, 'errorRed', 1000000),
            );
          }
          this.massActionsPopup('close');
        }
      });
    }
  };

  hideModalSuccessed = () => {
    this.setState({
      selected: [],
      modal: { ...this.state.modal, successed: SuccessedStateInit },
    });
  };

  onPubSigned = (certificate, params) => {
    const { t } = this.props;
    const obj = this.mDivisionDto(_.cloneDeep(this.props.data));
    const comObj = { goods: _.compact(obj.goods), draft: _.compact(obj.draft) };
    const id = this.state.params[0];
    const dtoName = this.state.params[1];
    const objSelectItems = [];

    let ids;
    if (_.isNumber(id) && dtoName) {
      const item = _.find(obj[dtoName], { id: id });
      ids = [item.goodId];
      objSelectItems.push(item);
    } else {
      ids = _.map(this.state.selected, (i) => {
        const item = _.find(comObj[i.nameDto], { id: i.id });
        objSelectItems.push(item);
        return item.goodId;
      });
    }

    this.onChooseCertificate(null);
    if (!_.isEmpty(ids)) {
      this.massActionsPopup('open');
      const setting = {
        ids: ids,
        isSigned: true,
        certificate: certificate,
        params: params,
        certId: certificate.id,
      };
      this.props.onPublishProduct(setting, (error, data) => {
        const text =
          _.size(ids) === 1
            ? t('Карточка товара с кодом товара успешно опубликована', {
              gtin: _.find(objSelectItems, { goodId: _.head(ids) }).gtin,
            })
            : t('Карточки товара с кодом товара успешно опубликованы', {
              gtins: _.join(
                _.slice(
                  _.map(
                    data,
                    (i) => _.find(objSelectItems, { goodId: i.goodId }).gtin,
                    ', ',
                  ), 0, 2)
              ),
            });

        const gtinPublishProduct =
          _.size(ids) === 1
            ? null
            : _.join(
              (_.map(
                data,
                (i) => _.find(objSelectItems, { goodId: i.goodId }).gtin,
                ', ',
              ))
            );

        if (!error) {
          this.setState(
            (prevState) => ({
              selected: [],
              modal: {
                ...prevState.modal,
                isOpenMassAction: false,
                successed: { isOpen: true, message: text, tooltip: gtinPublishProduct },
              },
            }),
            this.changeGridParams,
          );
        } else {
          if (!_.isEmpty(data)) {
            this.props.throwNotification(text, 'success');
            this.changeGridParams();
          }
          if (_.isArray(error)) {
            _.map(error, (i) => {
              const label = i === 'action.canceled' ? 'Публикация товара отменена' : i;
              return this.props.throwNotification(t(label), 'error');
            });
          }
          this.massActionsPopup('close');
        }
      });
    }
  };

  // TODO Если не нужно удалить
  onPubNotsigned = (id, dtoName) => {
    const { t } = this.props;
    const obj = this.mDivisionDto(_.cloneDeep(this.props.data));
    const comObj = { goods: _.compact(obj.goods), draft: _.compact(obj.draft) };
    const objSelectItems = [];

    let ids;
    if (_.isNumber(id) && dtoName) {
      const item = _.find(obj[dtoName], { id: id });
      ids = [item.goodId];
      objSelectItems.push(item);
    } else {
      ids = _.map(this.state.selected, (i) => {
        const item = _.find(comObj[i.nameDto], { id: i.id });
        objSelectItems.push(item);
        return item.goodId;
      });
    }

    if (!_.isEmpty(ids)) {
      this.massActionsPopup('open');
      const setting = {
        ids: ids,
        isSigned: false,
        certificate: null,
        params: {},
      };
      this.props.onPublishProduct(setting, (error, data) => {
        const text =
          _.size(ids) === 1
            ? t('Карточка товара с кодом товара успешно опубликована', {
              gtin: _.find(objSelectItems, { goodId: _.head(ids) }).gtin,
            })
            : t('Карточки товара с кодом товара успешно опубликованы', {
              gtins: _.join(
                _.slice(
                  _.map(
                    data,
                    (i) => _.find(objSelectItems, { goodId: i.goodId }).gtin,
                    ', ',
                  ), 0, 2)
              ),
            });

        const gtinPublishProduct =
          _.size(ids) === 1
            ? null
            : _.join(
              _.map(
                data,
                (i) => _.find(objSelectItems, { goodId: i.goodId }).gtin,
                ', ',
              ),
            )

        if (!error) {
          this.setState(
            (prevState) => ({
              selected: [],
              modal: {
                ...prevState.modal,
                isOpenMassAction: false,
                successed: { isOpen: true, message: text, tooltip: gtinPublishProduct },
              },
            }),
            this.changeGridParams,
          );
        } else {
          if (!_.isEmpty(data)) {
            this.props.throwNotification(text, 'success');
            this.changeGridParams();
          }
          if (_.isArray(error)) {
            _.map(error, (i) => this.props.throwNotification(i, 'error'));
          }
          this.massActionsPopup('close');
        }
      });
    }
  };

  // предпологаем, что все выбранные items являются однотипными DTO
  onDraft = (id, dtoName, isExemplar) => {
    const { t } = this.props;
    let ids, nameDto;
    if (_.isNumber(id) && dtoName) {
      ids = [id];
      nameDto = dtoName;
    } else {
      ids = this.mGetSelectedIds(this.state.selected);
      nameDto = _.find(this.state.selected, { id: _.head(ids) }).nameDto;
    }
    const obj = this.mDivisionDto(this.props.data);
    const item = _.find(obj[nameDto], { id: _.head(ids) });

    const objModal = {
      isOpen: true,
      funcDismiss: this.confirmModalClose,
      funcCancel: this.confirmModalClose,
      acceptText: t('Подтвердить'),
      dismissText: t('Закрыть'),
    };

    if (_.includes(['published', 'published_notsigned'], item.status)) {
      this.confirmModal({
        ...objModal,
        message: t(
          'Вы действительно хотите создать черновик для внесения изменений в опубликованную версию карточки?',
        ),
        funcAccept: () => this.onDraftPublished(ids, isExemplar),
      });
    }
  };

  onArchive = (id) => {
    const { t } = this.props;
    let ids;
    if (_.isNumber(id)) {
      ids = [id];
    } else {
      ids = this.mGetSelectedIds(this.state.selected);
    }
    if (!!ids) {
      const idItemsDTO = [];
      const idItemsDTOCert = [];

      this.checkAchivesIds(idItemsDTO, idItemsDTOCert, ids);
      const m1 = t(
        'Перевод карточки в статус «Архив» необходим в случаях, когда товар снят с производства',
      );
      const message = !_.isEmpty(idItemsDTOCert)
        ? `${m1}. ${t(
          'Для подтверждения действия воспользуйтесь электронной подписью',
        )}`
        : `${m1}. ${t('Вы действительно хотите перевести карточку в архив?')}`;

      const objModal = {
        isOpen: true,
        message: message,
        funcAccept: this.onArchiveAccept,
        funcDismiss: this.onArchiveDissmiss,
        funcCancel: this.onArchiveDissmiss,
        acceptText: t('Подтвердить'),
        dismissText: t('Отменить'),
      };
      this.confirmModal(objModal, null, { archiveIds: ids });
    }
  };

  onArchiveDissmiss = () => {
    this.confirmModal(ConfirmStateInit, null, { archiveIds: [] });
  };

  onArchiveCallback = (errors, data) => {
    this.massActionsPopup('close');
    this.processArchiveResult(errors, data);
  };

  processArchiveResult(error, data) {
    const { t } = this.props;
    if (error) {
      this.props.throwNotification(
        _.defaultTo(
          _.get(error, 'error'),
          t('Произошла ошибка при выполнении запроса на архивацию карточек'),
        ),
        'error',
      );
    } else {
      const goods = _.map(
        _.filter(this.props.data, (d) => !_.isNull(d.goods)),
        (g) => g.goods,
      );
      if (data) {
        const success = _.filter(data, (d) => _.isNull(d.errorMessage));
        const errors = _.filter(data, (d) => !_.isNull(d.errorMessage));
        if (!_.isEmpty(success)) {
          const text =
            _.size(success) === 1
              ? t('Карточка товара с кодом товара успешно отправлена в архив', {
                gtin: _.find(goods, { id: success[0].goodId }).gtin,
              })
              : t(
                'Карточки товара с кодами товаров успешно отправлены в архив',
                {
                  gtins: _.join(
                    _.map(success, (i) => i.GTIN),
                    ', ',
                  ),
                },
              );
          this.props.throwNotification(text, 'info');
        }

        if (!_.isEmpty(errors)) {
          // Вариант с объединением всех сообщений в одно

          // const text = _.size(errors) === 1
          //     ? `Карточка с кодом товара ${_.find(goods, {id: errors[0].goodId}).gtin} не переведена в архив. ${errors[0].errorMessage}`
          //     : `Карточки с кодами товаров ${_.join(_.map(errors, i => _.find(goods, {id: i.goodId}).gtin, ', '))} не переведены в архив. ${_.join(_.uniq(_.map(errors, err => err.errorMessage)), ', ')} `;
          // this.props.throwNotification(text, 'errorRed');

          // Вариант с группировкой по сообщению
          _.forEach(_.groupBy(errors, 'errorMessage'), (err) => {
            const m1 = t('Произошла ошибка');
            const text =
              _.size(errors) === 1
                ? `${m1}. ${t('Карточка с кодом товара не переведена в архив', {
                  gtin: _.find(goods, { id: err[0].goodId }).gtin,
                })}. 
                                ${err[0].errorMessage}`
                : `${m1}. ${t(
                  'Карточки с кодами товаров не переведены в архив',
                  {
                    gtins: _.join(
                      _.map(
                        err,
                        (i) => _.find(goods, { id: i.goodId }).gtin,
                        ', ',
                      ),
                    ),
                  },
                )}. ${_.join(
                  _.uniq(_.map(err, (e) => e.errorMessage)),
                  ', ',
                )} `;
            this.props.throwNotification(text, 'errorRed');
          });
        } else {
          if (_.isEmpty(this.state.archiveIds)) {
            this.changeGridParams();
          }
        }
      }
    }
  }

  onArchiveCertCallback = (errors, data) => {
    this.massActionsPopup('close');
    this.processArchiveResult(errors, data);
    if (!_.isEmpty(this.state.archiveIds)) {
      this.onArchive(this.state.archiveIds);
    }
  };

  onArchiveAccept = () => {
    const idItemsDTO = [];
    const idItemsDTOCert = [];

    this.checkAchivesIds(idItemsDTO, idItemsDTOCert);

    if (!_.isEmpty(idItemsDTOCert)) {
      this.setState((prevState) => ({
        modal: {
          ...prevState.modal,
          isOpenArchiveCertModal: !prevState.modal.isOpenArchiveCertModal,
          confirm: { ...prevState.modal.confirm, isOpen: false },
        },
      }));
    } else {
      const state = {
        archiveIds: [],
        selected: [],
        selectDTOItems: _.reject(this.state.selectDTOItems, (o) =>
          _.includes(idItemsDTO, o.id),
        ),
      };
      this.archiveCardsModal(
        state,
        idItemsDTO,
        null,
        false,
        { isOpenMassAction: true },
        this.onArchiveCallback,
      );
    }
  };

  checkAchivesIds = (idItemsDTO, idItemsDTOCert, ids) => {
    const archiveIds = _.defaultTo(ids, this.state.archiveIds);
    _.forEach(this.props.data, (o) => {
      if (!_.isNull(o.goods) && _.includes(archiveIds, o.goods.id)) {
        o.goods.status === 'published'
          ? idItemsDTOCert.push(o.goods.id)
          : idItemsDTO.push(o.goods.id);
      }
    });
  };

  archiveCardsModal = (
    state,
    idItemsDTO,
    certificate,
    isSigned,
    modal,
    callback,
  ) => {
    const m = _.assign({}, modal, { confirm: ConfirmStateInit });
    const s = _.assign({}, state);
    this.setState(
      (prevState) => ({
        ...prevState,
        ...s,
        modal: { ...prevState.modal, ...m },
      }),
      () =>
        this.props.archiveCards(idItemsDTO, certificate, isSigned, callback),
    );
  };

  onArchiveSigned = (certificate) => {
    const idItemsDTO = [];
    const idItemsDTOCert = [];

    this.checkAchivesIds(idItemsDTO, idItemsDTOCert);
    const state = {
      archiveIds: _.reject(this.state.archiveIds, (o) =>
        _.includes(idItemsDTOCert, o),
      ),
      selected: _.reject(this.state.selected, (o) =>
        _.includes(idItemsDTOCert, o.id),
      ),
      selectDTOItems: _.reject(this.state.selectDTOItems, (o) =>
        _.includes(idItemsDTOCert, o.id),
      ),
    };
    this.archiveCardsModal(
      state,
      idItemsDTOCert,
      certificate,
      true,
      {
        isOpenMassAction: true,
        isOpenArchiveCertModal: false,
      },
      this.onArchiveCertCallback,
    );
  };

  onDraftPublished = (ids, isExemplar) => {
    const { t } = this.props;
    this.confirmModal(ConfirmStateInit, { isOpenMassAction: true });
    this.props.onDraftOpen(ids, 'published', isExemplar, (error, data) => {
      this.massActionsPopup('close');
      let text = `${t('Черновики успешно созданы')}. ${t(
        'Вы можете вносить изменения в карточки',
      )}.`;
      if (error) {
        if (!_.isEmpty(data)) {
          this.props.throwNotification(text, 'success');
        }
        if (_.isArray(error)) {
          _.map(error, (i) => this.props.throwNotification(i, 'error'));
        }
      } else {
        if (_.size(ids) === 1) {
          text = `${t('Черновик успешно создан')}. ${t(
            'Вы можете вносить изменения в карточку',
          )}.`;
        }
        this.props.throwNotification(text, 'success');
      }
    });
  };

  // удаляется не продукт, а его черновик.
  onDeleteDraft = (id) => {
    const { t } = this.props;
    let item = _.find(
      this.props.data,
      (o) => !_.isNull(o.draft) && o.draft.id === id,
    );
    if (!!item) {
      const objModal = {
        isOpen: true,
        message: t('Вы уверены, что хотите удалить карточку с кодом товара?', {
          gtin: item.draft.gtin,
          name: item.draft.name,
        }),
        funcAccept: this.onDeleteAccept,
        funcDismiss: this.onDeleteDissmiss,
        funcCancel: this.onDeleteDissmiss,
        acceptText: t('Удалить'),
        dismissText: t('Отменить'),
      };
      this.confirmModal(objModal, null, { deleteIds: [item.draft.id] });
    }
  };

  onDeleteDrafts = () => {
    const { t } = this.props;
    const ids = this.mGetSelectedIds(this.state.selected);
    if (!!ids) {
      let message = t('Вы уверены, что хотите удалить карточки?');
      if (_.size(ids) === 1) {
        const item = _.find(
          this.props.data,
          (o) => !_.isNull(o.draft) && o.draft.id === ids[0],
        );
        message = t('Вы уверены, что хотите удалить карточку с кодом товара?', {
          gtin: item.draft.gtin,
          name: item.draft.name,
        });
      }
      const objModal = {
        isOpen: true,
        message: message,
        funcAccept: this.onDeleteAccept,
        funcDismiss: this.onDeleteDissmiss,
        funcCancel: this.onDeleteDissmiss,
        acceptText: t('Удалить'),
        dismissText: t('Отменить'),
      };
      this.confirmModal(objModal, null, { deleteIds: ids });
    }
  };

  onDeleteDissmiss = () => {
    this.confirmModal(ConfirmStateInit, null, { deleteIds: [] });
  };

  onDeleteAccept = () => {
    const idItemsDTO = [];
    const deleteIds = this.state.deleteIds;
    const data = _.cloneDeep(this.props.data);
    _.forEach(data, (o) => {
      if (
        !_.isNull(o.goods) &&
        !_.isNull(o.draft) &&
        _.includes(deleteIds, o.draft.id)
      ) {
        idItemsDTO.push(o.goods.id);
      }
    });

    const state = {
      deleteIds: [],
      selected: [],
      selectDTOItems: _.reject(this.state.selectDTOItems, (o) =>
        _.includes(idItemsDTO, o.id),
      ),
    };
    this.confirmModal(ConfirmStateInit, { isOpenMassAction: true }, state);
    this.props.onDeleteProducts(deleteIds, (error) => {
      this.massActionsPopup('close');
      if (!error) {
        if (_.size(data) === _.size(deleteIds)) {
          this.setState(
            (prevState) => ({ pageNum: prevState.pageNum - 1 }),
            this.changeGridParams,
          );
        } else {
          this.changeGridParams();
        }
      }
    });
  };

  onVersion = (item) => {
    this.setState({
      selectItemProduct: item,
      modal: { ...this.state.modal, isOpenVersions: true },
    });
  };

  hideModalVersion = () => {
    this.setState({
      selectItemProduct: {},
      modal: { ...this.state.modal, isOpenVersions: false },
    });
  };

  onViewSite = (id, dtoName) => {
    const obj = this.mDivisionDto(_.cloneDeep(this.props.data));
    const item = _.find(obj[dtoName], { id: id });
    if (!!item.photo.url) {
      window.open(item.link, '_black');
    } else {
      this.handleRedirectModal();
    }
  };

  handleRedirectModal = () => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        isOpenRedirectModal: !prevState.modal.isOpenRedirectModal,
      },
    }));
  };

  handleChangeDTOSelect = (e, i) => {
    const original = i.props.original;
    const selectDTOItems = _.reject(this.state.selectDTOItems, {
      id: original.goods.id,
    });
    selectDTOItems.push({ id: original.goods.id, dto: i.props.value });

    const oldSelectedDto = {
      draft: { id: original.goods.id, nameDto: 'goods' },
      goods: { id: original.draft.id, nameDto: 'draft' },
    };
    this.setState({
      selected: _.reject(this.state.selected, oldSelectedDto[i.props.value]),
      selectDTOItems,
    });
  };

  currentSDI = (original, selectDTOItems) => {
    return _.defaultTo(_.find(selectDTOItems, { id: original.goods.id }), {
      dto: 'goods',
    });
  };

  selectDataFromDTO = (original, selectDTOItems) => {
    if (_.isNull(original.goods) || _.isNull(original.draft)) {
      if (_.isNull(original.goods)) {
        return { dto: original.draft, nameDto: 'draft' };
      }
      if (_.isNull(original.draft)) {
        return { dto: original.goods, nameDto: 'goods' };
      }
    } else {
      const SDI = this.mCurrentSDI(original, selectDTOItems);
      return { dto: original[SDI.dto], nameDto: SDI.dto };
    }
  };

  divisionDto = (data) => {
    return {
      draft: _.map(data, 'draft'),
      goods: _.map(data, 'goods'),
    };
  };

  getStatusByIds = (selected) => {
    const obj = this.mDivisionDto(this.props.data);
    return _.map(
      selected,
      (i) => findStatusObj(_.find(obj[i.nameDto], { id: i.id }).status).id,
    );
  };

  getRemainderByIds = (selected) => {
    const obj = this.mDivisionDto(this.props.data);
    return _.map(
      selected,
      (i) => _.find(obj[i.nameDto], { id: i.id }).isRemainder,
    );
  };

  getNoDataProps = () => ({ resetFilters: this.resetFilters });

  isDoubleStatus = () => {
    const doubleStatus = [];
    _.forEach(this.state.selected, (i) => {
      _.forEach(this.props.data, (item, ind) => {
        if (_.get(item[i.nameDto], 'goodId') === i.id) {
          doubleStatus.push(
            !_.isNull(this.props.data[ind].draft) &&
            !_.isNull(this.props.data[ind].goods),
          );
        }
      });
    });
    return _.some(doubleStatus);
  };

  getClassName = (pathname) =>
    this.props.pathname === pathname ? 'active' : '';

  handleCloseMenuAddProduct = (type) => () => {
    const { t } = this.props;
    this.setState({ anchorEl: null });
    if (!_.isNull(type)) {
      if (!_.get(this.props, 'user.readyToWorkWithGoods')) {
        this.props.throwError(
          t(
            'Создание карточек будет доступно после наполнения реестра товаров',
          ),
        );
      } else {
        this.props.deleteNotification();
        this.props.goToPage(`${config.urls.productNew}?type=${type}`);
      }
    }
  };

  openExemplar = () => {
    this.setState({ isExemplar: true });
  }

  render() {
    const { t, userInfo, usercompany, companyInfo } = this.props;
    const { loading } = this.state;
    const isEklUser = _.get(userInfo, 'isELK', false);
    const isELKInfo = this.props.info?.user?.isELK || this.props.user?.isElkUser;
    const IsNotActiveFilters = this.mIsNotActiveFilters(this.state.filters);
    // const CountActiveFilters = this.mCountActiveFilters(this.state.filters);
    const emptyTable = !this.props.totalObjects && IsNotActiveFilters;
    const disableActionsEmptyGrid = emptyTable || this.props.loading;

    // const userCanRegisterGoods = !_.isEmpty(usercompany);
    const userCannotRegisterGoods = companyInfo.isResident === false && !companyInfo.country;
    const tooltipText =
      'Для регистрации новой упаковки необходимо в настройках профиля указать страну, в которой зарегистрирована компания';
    const propsComponentItems = {
      t,
      userInfo: _.get(this.props, 'userInfo'),
      handleCloseMenuAddProduct: this.handleCloseMenuAddProduct,
    };

    return (
      <React.Fragment>
        <ProductContainer>
          <ProductsGridContainer>
            <GridActions>
              <GridActionsLeft>
                <GridActionsView>
                  {!_.includes(this.accountTypes, 'net') && (
                    // this.accountType !== 'net' && (
                    <GridSelect>
                      <ProductsGridActionSelect
                        onDelete={this.onDeleteDrafts}
                        onSendForModeration={this.onSendForModeration}
                        onPubSigned={this.onChooseCertificate}
                        onPubNotsigned={this.onPubNotsigned}
                        onDraft={this.onDraft}
                        onArchive={this.onArchive}
                        statuses={this.mGetStatusByIds(this.state.selected)}
                        isRemainder={this.mGetRemainderByIds(this.state.selected)}
                        ids={this.mGetSelectedIds(this.state.selected)}
                        certificate={this.props.certificate}
                        doubleStatus={this.isDoubleStatus()}
                        isEklUser={isEklUser || isELKInfo}
                      />
                    </GridSelect>
                  )}
                </GridActionsView>

                {/* {isELK && _.includes(['producer', 'importer'], this.accountType) && ( //было когда роль-строка, сейчас массив */}
                {isELK && this.accountTypes.some(elem => elem === ('producer' || 'importer')) && (
                  <GridActionsView>
                    <MuiThemeProvider theme={AddProductGridBtns}>
                      <Button onClick={this.goToProduct}>
                        <IconAddProduct />
                      </Button>
                    </MuiThemeProvider>
                  </GridActionsView>
                )}
                {this.accountTypes.some(elem => elem === ('producer' || 'importer')) && (
                  // _.includes(['producer', 'importer'], this.accountType) && (
                  <>
                    {!userCannotRegisterGoods ? (
                      <ProductRegistration
                        className={this.state.isOpenList ? 'open-menu' : ''}
                        aria-owns={
                          this.state.anchorEl ? 'sidebar-menu' : undefined
                        }
                        onClick={(event) =>
                          this.state.open
                            ? this.setState({
                              isOpenList: !this.state.isOpenList,
                            })
                            : this.setState({ anchorEl: event.currentTarget })
                        }>
                        <ProductRegistrationIcon
                          className={this.getClassName(config.urls.productNew)}>
                          <IconPlusNew />
                        </ProductRegistrationIcon>
                        <ProductRegistrationTitle open={true}>
                          {t('Добавить упаковку')}
                        </ProductRegistrationTitle>
                      </ProductRegistration>
                    ) : (
                      <Tooltip title={t(tooltipText)} placement="right">
                        <ProductRegistration
                          className={this.state.isOpenList ? 'open-menu' : ''}
                          aria-owns={
                            this.state.anchorEl ? 'sidebar-menu' : undefined
                          }>
                          <ProductRegistrationIcon
                            className={this.getClassName(config.urls.productNew)}>
                            <IconPlusNew />
                          </ProductRegistrationIcon>
                          <ProductRegistrationTitle open={true}>
                            {t('Регистрация упаковок')}
                          </ProductRegistrationTitle>
                        </ProductRegistration>
                      </Tooltip>
                    )}

                    <MuiThemeProvider theme={AddProductSelect}>
                      <Menu
                        id="sidebar-menu"
                        anchorEl={this.state.anchorEl}
                        open={!!this.state.anchorEl}
                        onClose={this.handleCloseMenuAddProduct(null)}
                        disableAutoFocusItem={true}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                        <ComponentItems
                          Item={MenuItem}
                          {...propsComponentItems}
                        />
                      </Menu>
                    </MuiThemeProvider>

                    <Collapse
                      in={this.state.isOpenList}
                      timeout="auto"
                      unmountOnExit>
                      <MuiThemeProvider theme={AddProductFull}>
                        <List>
                          <ComponentItems
                            Item={ListItem}
                            {...propsComponentItems}
                          />
                        </List>
                      </MuiThemeProvider>
                    </Collapse>
                  </>
                )}

              </GridActionsLeft>
              <GridActionsRight id="goodsFilters">
                <MuiThemeProvider theme={DefaultGridButton}>
                  <GridActionsView style={{ padding: '0 0 0 20px' }}>
                    <GridActionsView>
                      <FiltersPopup
                        parentId="goodsFilters"
                        currentFiltersValue={this.state.filters}
                        resetFilters={this.resetFilters}
                        onFiltersSubmit={this.onFiltersSubmit}
                        isNotActiveFilters={IsNotActiveFilters}
                      />
                    </GridActionsView>
                    {/* <GridExamplarFilter id="isExemplarGridFilter"> */}
                    {/* <div style={{ marginRight: '8px', fontSize: '14px', color: '#868686', fontWeight: 'bold' }}>{t('Экземпляры')}</div> */}
                    {/* <IsExemplarFilter
                      currentValue={this.state.filters.isExemplar}
                      onAccept={this.onFilterAccept}
                      onDismiss={this.onFilterDissmiss}
                      parentId="isExemplarGridFilter"
                    /> */}
                    {/* <img
                      src="/images/isExemplarIcon.png"
                      alt=""
                      style={{ marginRight: '10px' }}
                      height={23}
                    />
                    <IconExample /> */}
                    {/* </GridExamplarFilter> */}
                    <MuiThemeProvider theme={ResetFilterButton}>
                      <Button onClick={this.onMassMediaModal}>
                        <IconImport style={{ marginRight: '8px' }} />
                        {t('Импорт')}
                        <IconImage style={{ marginLeft: '8px' }} />
                      </Button>
                      <Button onClick={this.onImportModal} disabled={false}>
                        <IconImport style={{ marginRight: '8px' }} />
                        {t('Импорт')}
                      </Button>
                      <Button onClick={this.onExportModal}>
                        <IconExport style={{ marginRight: '8px' }} />
                        {t('Экспорт')}
                      </Button>
                      <Button
                        onClick={this.onChangeTableAppearanceShow}
                        disabled={disableActionsEmptyGrid}
                      >
                        <IconGridView />
                      </Button>
                    </MuiThemeProvider>
                  </GridActionsView>
                </MuiThemeProvider>
              </GridActionsRight>
            </GridActions>

            <GridTableContainerRedesign noData={!emptyTable && !this.props.data.length} height="53vh" minHeight="10vh">
              {!!this.state.modal.isOpenRemainderModal && (
                <RemainderModal
                  goodId={this.state.modal.isOpenRemainderModal}
                  handleClose={this.onViewRemainder}
                />
              )}

              {this.state.modal.isOpenRedirectModal && (
                <RedirectModal
                  handleClose={this.handleRedirectModal}
                  noImg={true}
                />
              )}

              {this.state.modal.isOpenTableAppearance && (
                <ColumnOrder
                  onCancel={this.onChangeTableAppearanceShow}
                  onSave={this.onChangeTableAppearance}
                  columns={this.state.orderedColumns}
                  defaultColumns={this.state.defaultColumns}
                />
              )}

              {this.state.modal.isOpenVersions && (
                <ProductVersions
                  product={this.state.selectItemProduct}
                  handleClose={this.hideModalVersion}
                />
              )}

              {this.state.modal.confirm.isOpen && (
                <ConfirmDialog
                  onAccept={this.state.modal.confirm.funcAccept}
                  onDismiss={this.state.modal.confirm.funcDismiss}
                  onCancel={this.state.modal.confirm.funcCancel}
                  title={this.state.modal.confirm.title}
                  message={this.state.modal.confirm.message}
                  acceptBtnText={this.state.modal.confirm.acceptText}
                  dismissBtnText={this.state.modal.confirm.dismissText}
                />
              )}

              {!!this.state.modal.isOpenMassAction && (
                <ModalLoader
                  text={`${_.toUpper(
                    t('Пожалуйста, подождите, идет обработка карточек'),
                  )} ...`}
                />
              )}

              {!!this.state.modal.successed.isOpen && (
                <SuccessedModal
                  message={this.state.modal.successed.message}
                  handleClose={this.hideModalSuccessed}
                  tooltip={this.state.modal.successed.tooltip}
                  t={this.props.t}
                />
              )}

              {!!this.state.modal.isOpenMassMediaModal && (
                <MassMediaModal
                  handleClose={this.onMassMediaModal}
                  changeGridParams={this.changeGridParams}
                />
              )}

              {!!this.state.modal.isOpenExportModal && (
                <ExportModal
                  handleClose={this.onExportModal}
                  getBuildParamsForList={this.getBuildParamsForList}
                  filters={this.state.filters}
                  selected={this.state.selected}
                />
              )}

              {!!this.state.modal.isOpenImportModal && (
                <ImportModal
                  handleClose={this.onImportModal}
                  changeGridParams={this.changeGridParams}
                />
              )}

              {this.state.modal.isOpenSelectCertModal && (
                <CertificateSelectModal
                  acceptBtnText={t('Опубликовать')}
                  onSelectCert={(cert) => this.onSelectCertificate(cert)}
                  onSubmit={(certificate, params) =>
                    this.onPubSigned(certificate, params)
                  }
                  // onGetAllCerts={this.props.onLoadCertificates}
                  cert={this.state.certificate}
                  certificates={this.props.certificates}
                  onCancel={this.onChooseCertificate}
                  questionAboutPublishing={true}
                />
              )}

              {this.state.modal.isOpenArchiveCertModal && (
                <CertificateSelectModal
                  acceptBtnText={t('Подтвердить')}
                  isCancelButton={true}
                  onSelectCert={(cert) => this.onSelectCertificate(cert)}
                  onSubmit={(certificate) => this.onArchiveSigned(certificate)}
                  // onGetAllCerts={this.props.onLoadCertificates}
                  cert={this.state.certificate}
                  certificates={this.props.certificates}
                  onCancel={this.onDismissCertificate}
                />
              )}
              <GridTable className="table-grid--list">
                {emptyTable ? (
                  <GridEmpty>
                    <GridEmptyTitle>{t('Таблица не заполнена')}</GridEmptyTitle>
                    <GridEmptySubstring>{t('У вас еще нет зарегистрированных упаковок')}</GridEmptySubstring>
                  </GridEmpty>
                ) : (
                  <div style={{ display: this.props.loading ? 'none' : 'block', height: '100%' }}>
                    <TableGrid
                      key={disableActionsEmptyGrid}
                      data={this.props.data}
                      columns={_.concat(
                        this.state.checkboxColumn,
                        this.state.columns,
                        this.state.actionColumn,
                      )}
                      defaultPageSize={this.state.pageSize}
                      page={this.state.pageNum}
                      pages={Math.ceil(
                        this.props.totalObjects / this.state.pageSize,
                      )}
                      pageSize={this.state.pageSize}
                      getPaginationProps={() => ({
                        totalObjects: this.props.totalObjects,
                        gridPageSize: (
                          <GridPageSize
                            disabled={disableActionsEmptyGrid}
                            activeValue={this.state.pageSize}
                            onChangePageSize={(pageSize) => {
                              localStorage.setItem('savedPageSize', pageSize);
                              this.setState(
                                { pageNum: 0, loading: true },
                                () => setTimeout(() => this.setState({ pageSize }, this.changeGridParams), 1),
                              );
                            }}
                          />
                        ),
                      })}
                      onPageChange={(page) => {
                        this.setState({ pageNum: page }, this.changeGridParams);
                      }}
                      getNoDataProps={this.getNoDataProps}
                      selectDataFromDTO={this.selectDataFromDTO}
                      selected={this.state.selected}
                      selectDTOItems={this.state.selectDTOItems}
                      filters={this.state.filters}
                    />
                  </div>
                )}
              </GridTable>
            </GridTableContainerRedesign>
          </ProductsGridContainer>

          <Preloader isOpen={this.props.loading || loading} text={t('Загрузка')} />
        </ProductContainer>
      </React.Fragment>
    );
  }

  updateColumns(orderedColumns) {
    let { cookies, locale, t } = this.props;
    let checkBoxColumn = {
      id: 'checkbox',
      accessor: '',
      fixed: 'left',
      sortable: false,
      width: 52,
      Header: (x) => {
        return (
          <ColumnCellCheckHeader>
            <ColumnCellCheckInner>
              <MuiThemeProvider theme={CheckBoxGridThead}>
                <Checkbox
                  checked={
                    !_.isEmpty(this.props.data) &&
                    this.props.data.length === this.state.selected.length
                  }
                  onChange={this.toggleSelectAll}
                  checkedIcon={<IconCheck />}
                  icon={<IconCheckbox />}
                  color="default"
                />
              </MuiThemeProvider>
            </ColumnCellCheckInner>
          </ColumnCellCheckHeader>
        );
      },
      Cell: ({ original }) => {
        return (
          <CheckboxCell
            toggleRow={this.toggleRow}
            original={original}
            selected={this.state.selected}
          />
        );
      },
    };

    let defaultColumns = [
      {
        name: t('Фото'),
        show: true,
        accessor: 'photo',
        position: 0,
        sortable: false,
        width: 90,
        style: { justifyContent: 'center' },
        Header: (x) => {
          return (
            <ColumnHeaderPhoto id="photoGridFilter">
              {t('Фото')}
              <PhotoFilter
                parentId="photoGridFilter"
                currentValue={this.state.filters.photo}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
              />
            </ColumnHeaderPhoto>
          );
        },
        Cell: ({ original }) => {
          return (
            <ColumnCellPhoto>
              {!_.isUndefined(original.dto.photo) && (
                <PhotoPopover
                  photo={original.dto.photo}
                  name={original.dto.name}
                />
              )}
            </ColumnCellPhoto>
          );
        },
      },
      {
        name: t('Степень заполненности'),
        show: true,
        accessor: 'isValid',
        position: 1,
        sortable: false,
        width: 80,
        style: { justifyContent: 'center' },
        Header: (x) => {
          return (
            <ColumnHeaderIsValid id="isValidGridFilter">
              <WithoutSortingBtn style={{ paddingLeft: '3px' }}>
                <IconActivity />
                <IsValidFilter
                  parentId="isValidGridFilter"
                  currentValue={this.state.filters.isValid}
                  onAccept={this.onFilterAccept}
                  onDismiss={this.onFilterDissmiss}
                />
              </WithoutSortingBtn>
            </ColumnHeaderIsValid>
          );
        },
        Cell: ({ original }) => {
          return (
            <ColumnCellPhoto>
              {original.dto.isValid ? (
                <IconEllipse
                  color={'#00B634'}
                />
              ) : (
                <IconEllipse
                  color={'#E32A38'}
                />
              )}
            </ColumnCellPhoto>
          );
        },
      },
      {
        name: t('Назначение упаковки'),
        show: true,
        position: 2,
        accessor: 'packingPurpose',
        sortable: false,
        width: 212,
        Header: (x) => {
          return (
            <ColumnHeader id="packingPurposeGridFilter">
              {/*<SortingBtn>*/}
              {/*  {this.mIconSwitch(*/}
              {/*    _.find(this.state.sort, { field: 'packingPurpose' })*/}
              {/*      .direction,*/}
              {/*    t('Назначение упаковки'),*/}
              {/*    this.onSort('packingPurpose'),*/}
              {/*  )}*/}
              {/*</SortingBtn>*/}
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'12px'}>
                  {t('Назначение упаковки')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>

              <PackingTypeFilter
                parentId="packingPurposeGridFilter"
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
                currentValue={this.state.filters.packingPurpose}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <PackingPurpose value={original.dto} t={t} />;
        },
      },
      {
        name: t('Код упаковки'),
        show: true,
        position: 3,
        accessor: 'gtin',
        sortable: false,
        width: 159,
        Header: (x) => {
          return (
            <ColumnHeader id="gtinGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'12px'}>
                  {t('Код упаковки')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>
              <GtinFilter
                parentId="gtinGridFilter"
                currentValue={this.state.filters.gtin}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return (
            <GtinCell
              value={gtinDisplay(original.dto.gtin)}
              fillWithZeros={true}
              isClipped={false}
            />
          ); //флаг для того чтобы дополнять нулями ячейку. или лучше сделать проверку внутри utils?????
        },
      },
      {
        name: t('Торговое наименование товара'),
        show: true,
        position: 4,
        accessor: 'name',
        sortable: false,
        minWidth: 280,
        Header: (x) => {
          return (
            <ColumnHeader id="nameGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'12px'}>
                  {t('Торговое наименование товара')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>
              <NameFilter
                parentId="nameGridFilter"
                currentValue={this.state.filters.name}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return (
            <NameCell
              original={original}
              onView={this.onView}
              onViewRemainder={this.onViewRemainder}
            />
          );
        },
      },
      {
        name: t('Товарный знак'),
        show: true,
        position: 5,
        accessor: 'brand',
        sortable: false,
        width: 167,
        Header: (x) => {
          return (
            <ColumnHeader id="brandGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'12px'}>
                  {t('Товарный знак')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>
              <BrandFilter
                parentId="brandGridFilter"
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
                currentValue={this.state.filters.brand}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <BrandCell value={original.dto.brand} brandId={`brand-${original.dto.id}`} />;
        },
      },
      {
        name: t('Классификация'),
        show: true,
        position: 6,
        accessor: 'category',
        sortable: false,
        width: 172,
        Header: (x) => {
          return (
            <ColumnHeader id="categoryGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'12px'}>
                  {t('Классификация')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>

              <CategoryFilter
                parentId="categoryGridFilter"
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
                currentValue={this.state.filters.category}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <CategoryCell value={original.dto.category} />;
        },
      },
      {
        name: t('Код ТНВЭД'),
        show: true,
        position: 7,
        accessor: 'categoryTnved',
        sortable: false,
        width: 146,
        Header: (x) => {
          return (
            <ColumnHeader id="categoryTnvedGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'12px'}>
                  {t('Код ТНВЭД')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>
              <CategoryTnvedNameFilter
                parentId="categoryTnvedGridFilter"
                currentValue={this.state.filters.categoryTnved}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <GtinCell value={original.dto.categoryTnved} isClipped />;
        },
      },
      {
        name: t('Статус'),
        show: true,
        position: 8,
        accessor: 'status',
        sortable: false,
        width: 226,
        Header: (x) => {
          return (
            <ColumnHeader id="statusGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'12px'}>
                  {t('Статус')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>

              <StatusFilter
                parentId="statusGridFilter"
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
                currentValue={this.state.filters.status}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return (
            <ColumnCellStatus>
              <SelectSwitchingGoods
                onChange={this.handleChangeDTOSelect}
                original={original}
              />
              {/* <StatusCell original={original} onVersion={this.onVersion} /> */}
            </ColumnCellStatus>
          );
        },
      },
      {
        name: t('Регистрация'),
        show: false,
        position: 9,
        accessor: 'created',
        sortable: false,
        width: 200,
        Header: (x) => {
          return (
            <ColumnHeader id="dateGridFilter">
              <SortingBtn>
                {this.mIconSwitch(
                  _.find(this.state.sort, { field: 'created' }).direction,
                  t('Регистрация'),
                  this.onSort('created'),
                )}
              </SortingBtn>

              <DateFilter
                parentId="dateGridFilter"
                currentValue={this.state.filters.created}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
                name={'created'}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <CreatedCell value={original.dto.created} />;
        },
      },
      {
        name: t('Публикация'),
        show: false,
        position: 10,
        accessor: 'publicationTime',
        sortable: false,
        width: 150,
        Header: (x) => {
          return (
            <ColumnHeader id="publicationTimeGridFilter">
              <SortingBtn>
                {this.mIconSwitch(
                  _.find(this.state.sort, { field: 'publicationTime' })
                    .direction,
                  t('Публикация'),
                  this.onSort('publicationTime'),
                )}
              </SortingBtn>

              <DateFilter
                parentId="publicationTimeGridFilter"
                currentValue={this.state.filters.publicationTime}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
                name={'publicationTime'}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <CreatedCell value={original.dto.publicationTime} />;
        },
      },
      {
        name: t('Вложенные упаковки'),
        show: false,
        position: 11,
        accessor: 'packages',
        sortable: false,
        width: 211,
        Header: (x) => {
          return (
            <ColumnHeader id="packagesGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'12px'}>
                  {t('Вложенные упаковки')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>
              <PackagesFilter
                parentId="packagesGridFilter"
                currentValue={this.state.filters.packages}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <PackagesCell packages={original.dto.packages} />;
          // return <GtinCell value={gtinDisplay(original.dto.packages)} />
        },
      },
      // {
      //   name: t('Поставщик контента'),
      //   show: true,
      //   position: 12,
      //   accessor: 'contentProvider',
      //   sortable: false,
      //   minWidth: 290,
      //   Header: (x) => {
      //     return (
      //       <ColumnHeader id="contentProviderGridFilter">
      //         <WithoutSortingBtn>
      //           {t('Поставщик контента')}
      //         </WithoutSortingBtn>

      //         <ContentProviderFilter
      //           parentId="contentProviderGridFilter"
      //           onAccept={this.onFilterAccept}
      //           onDismiss={this.onFilterDissmiss}
      //           currentName={this.state.filters.contentProvider.name}
      //           currentInn={_.join(
      //             this.state.filters.contentProvider.inn,
      //             '\n',
      //           )}
      //         />
      //       </ColumnHeader>
      //     );
      //   },
      //   Cell: ({ original }) => {
      //     return <ContentProviderCell original={original} />;
      //   },
      // },
    ];


    const { userInfo } = this.props;
    const isEklUser = _.get(userInfo, 'isELK', false);
    const isELKInfo = this.props.info?.user?.isELK || this.props.user?.isElkUser;

    let actionsColumn = {
      accessor: 'actions',
      Header: '',
      sortable: false,
      filterable: false,
      width: 52,
      Cell: ({ original }) => {
        return (
          <div>
            {original.dto.status !== 'archive' && !original.dto.isRemainder && (
              <ProductsGridActions
                accountTypes={this.accountTypes}
                onViewSite={this.onViewSite}
                onPrint={this.onPrint}
                onDelete={this.onDeleteDraft}
                onEdit={this.onEdit}
                onSendForModeration={this.onSendForModeration}
                onPubSigned={this.onChooseCertificate}
                onPubNotsigned={this.onPubNotsigned}
                onDraft={this.onDraft}
                onArchive={this.onArchive}
                original={original}
                isEklUser={isEklUser || isELKInfo}
                onVersion={this.onVersion}
              />
            )}
          </div>
        );
      },
    };

    let columns = _.cloneDeep(defaultColumns);
    if (!orderedColumns) {
      // let savedColumns = cookies.get('columns');
      let savedColumns = localStorage.getItem('columns');
      if (!!savedColumns) {
        // if (Array.isArray(savedColumns.accountType) && (this.accountTypes.join(',') === savedColumns.accountType.join(','))) {
        // columns = sortColumns(columns, savedColumns.columns);
        columns = sortColumns(columns, JSON.parse(savedColumns));
        // }
      }
    }
    columns = sortColumns(columns, orderedColumns);
    columns = _.filter(_.compact(columns), (i) =>
      _.includes(this.columnsGrid, i.accessor),
    );
    defaultColumns = _.filter(_.compact(defaultColumns), (i) =>
      _.includes(this.columnsGrid, i.accessor),
    );
    defaultColumns = returnSimplifiedSetFields(defaultColumns);
    // cookies.set(
    //   'columns',
    //   JSON.stringify({ accountType: _.cloneDeep(this.accountTypes), columns: columns }),
    // );
    localStorage.setItem('columns', JSON.stringify(columns));
    this.setState(
      {
        modal: {
          ...this.state.modal,
          isOpenTableAppearance: false,
        },
        checkboxColumn: checkBoxColumn,
        actionColumn: actionsColumn,
        columns: columns,
        defaultColumns: defaultColumns,
      },
      () => {
        if (orderedColumns) {
          this.changeGridParams();
        }
      },
    );
  }
}

ProductsGrid.propTypes = {
  goToPage: PropTypes.func.isRequired,
  onLoadProductData: PropTypes.func.isRequired,
  onSendForModerationProduct: PropTypes.func.isRequired,
  onPublishProduct: PropTypes.func.isRequired,
  onDraftOpen: PropTypes.func.isRequired,
  onDeleteProducts: PropTypes.func.isRequired,
  throwNotification: PropTypes.func.isRequired,
  clearAlert: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  totalObjects: PropTypes.number.isRequired,
  notification: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  certificate: PropTypes.object,
  filters: PropTypes.object,
  locale: PropTypes.string.isRequired,
  clearHistoryState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: selectors.loading(state),
  data: selectors.data(state),
  totalObjects: selectors.totalObjects(state),
  filters: selectors.filters(state),
  notification: selectors.alert(state),
  user: selectors.user(state),
  certificate: selectors.certificate(state),
  certificates: selectors.certificates(state),
  locale: selectors.locale(state),
  userInfo: state.profileReducer.info.user,
  location: state.router.location,
  usercompany: state.profileStatusReducer.data,
  companyInfo: state.profileReducer.info.company,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLoadProductData: (p) =>
        loadProductData(p.pageNum, p.pageSize, p.sort, p.filters, p.fields),
      onSendForModerationProduct: (draftsIds, cbk) =>
        sendForModerationProduct(draftsIds, cbk),
      onPublishProduct: (setting, cbk) => publishProduct(setting, cbk),
      onDraftOpen: (ids, status, isExemplar, cbk) =>
        openDraft(ids, status, isExemplar, cbk),
      archiveCards: (ids, isSigned, certificate, cbk) =>
        archiveCards(ids, isSigned, certificate, cbk),
      onDeleteProducts: (draftsIds, cbk) => deleteProducts(draftsIds, cbk),
      throwNotification: (message, type, timeout = 5000) =>
        throwNotification(message, type, timeout),
      deleteNotification: () => deleteNotification(),
      clearAlert: () => clearAlert(),
      goToPage: (page) => push(page),
      onLoadCertificates: () => loadCertificates(),
      clearHistoryState: (locationObject) => replace(locationObject),
    },
    dispatch,
  );
};

export default compose(
  withCookies,
  withTranslation(),
  connect(memoize(mapStateToProps), mapDispatchToProps),
)(ProductsGrid);
