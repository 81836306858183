import styled from 'styled-components';
import colors from '../../../../common/styles/Common.colors';

export const ProductRegistration = styled.a`
  padding: 10px 24px;
  // border: 1px solid #e5e5e5;
  background-color: ${props => props.inactive ?
    colors.Buttons.buttonPrimary.backgroundInactive :
    colors.Buttons.buttonPrimary.background} !important;
  border-radius: 4px;
  width: auto;
  cursor: pointer;
  transition: background 0.4s ease;
  display: inline-flex;
  align-items: center;
  &.open-menu {
    // box-shadow: inset 0px 1px 1px #f2f2f2;   
  }
  &:hover {
    background-color: ${props => props.inactive ?
    colors.Buttons.buttonPrimary.backgroundInactive :
    colors.Buttons.buttonPrimary.backgroundHovered} !important;
  }
  &:disabled {
    // color: #f9f9f9 !important;
    cursor: default;
    background-color: ${colors.Buttons.buttonPrimary.backgroundInactive} ;
    pointer-events: none;
  }
`;

export const ProductRegistrationIcon = styled.span`
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: 0 8px 0 0;
  box-sizing: border-box;

  & svg path {
    stroke: ${colors.Buttons.buttonPrimary.color};
  }
`;

export const ProductRegistrationTitle = styled.span`
  opacity: ${(props) => (props.open ? 1 : 0)};
  font-size: 16px;
  line-height: 24px;  
  color: ${colors.Buttons.buttonPrimary.color};
  // padding-right: 1px;
  font-weight: 600;
  transition: 0.4s ease;
  white-space: nowrap;
`;
