import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import {
  ColumnCellInner,
  DefaultTooltipCenter,
  EllipsisStringContainer,
} from '../../../../common/styles/Common.styled';
import { ColumnCell } from '../ProductsGrid.styled';

export default class BrandCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNeedTooltip: false,
    };
  }

  componentDidMount() {
    this.calculateIsNeedTooltip();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!_.isEqual(this.state, nextState)];
    const nameProps = ['value', 'brandId'];
    _.forEach(nameProps, (i) =>
      equal.push(!_.isEqual(this.props[i], nextProps[i])),
    );
    return _.some(equal);
  }

  calculateIsNeedTooltip = () => {
    const elemDiv = document.getElementById(this.props.brandId);
    if (!_.isNil(elemDiv)) {
      this.setState({
        isNeedTooltip: elemDiv.scrollHeight > 40,
      });
    }
  };

  render() {
    return (
      <ColumnCell>
        <ColumnCellInner>
          <MuiThemeProvider theme={DefaultTooltipCenter}>
            <Tooltip
              placement="bottom"
              title={
                this.state.isNeedTooltip
                  ? this.props.value
                  : ''
              }
            >
              <EllipsisStringContainer id={this.props.brandId}>
                {this.props.value ? this.props.value : '-'}
              </EllipsisStringContainer>
            </Tooltip>
          </MuiThemeProvider>
        </ColumnCellInner>
      </ColumnCell>
    );
  }
}

BrandCell.propTypes = {
  value: PropTypes.string.isRequired,
  brandId: PropTypes.string.isRequired,
};
