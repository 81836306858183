import styled from 'styled-components';
import { ProductViewMdiaItem } from '../../../../common/styles/Common.styled';
import colors from '../../../../common/styles/Common.colors';
import { createMuiTheme } from '@material-ui/core';

export const ProductViewTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.Common.textColor};
  margin-top: 0;
`;
export const ProductViewTitleMin = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.Common.textColor};
`;
export const ProductViewTitleTop = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.Common.textColor};
  margin: 0 0 4px;
`;
export const ProductViewButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 40px;
  margin: 0;
`;
export const ProductViewMinBorder = styled.div`
  margin-bottom: 44px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  &:nth-of-type(1) {
    margin-bottom: 44px;
  }
`;
export const ProductViewColumn = styled.div`
  margin-bottom: 44px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  &:nth-of-type(1) {
    margin-bottom: 20px;
  }
  &:nth-of-type(2) {
    margin-bottom: 20px;
  }
  &:nth-of-type(3) {
    margin-bottom: 20px;
  }
`;
export const ProductViewColumnVGH = styled.div`
  margin-bottom: 0
`;
export const ProductViewColumnMin = styled.div`
  color: ${colors.Common.textColor};
  font-size: 14px;
  //border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
  display: flex;
  &:nth-last-of-type(1) {
    padding-bottom: 0;
  }
`;
export const ProductColumnName = styled.div`
  color: #677380;
  font-size: 16px;
  line-height: 24px;
  flex-basis: 310px;
  flex-shrink: 0;
`;
export const ProductColumnValue = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.Common.textColor};
  padding-left: 20px;
  flex-grow: 1;
  word-break: break-word;
`;
export const ProductPrintMediaItem = styled(ProductViewMdiaItem)`
  cursor: auto;
  margin-bottom: 16px;
  padding: 8px 4px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;
export const ProductPrintMain = styled.div`
  //box-shadow: 0 1px 8px rgba(41, 49, 53, 0.1);
  //border-radius: 0 0 4px 4px;
  //background: #fff;
  // @media (max-width: 1200px) {
  //   padding: 70px 20px 100px;
  // }
  display: flex;
  min-height: calc(100% - 210px);
  //padding: 40px;
`;
export const ProductPrintLeft = styled.div`
  width: 372px;
  background: #fff;
  flex-shrink: 0;  
  button {
    width: 100%;
    margin-top: 20px;
  }
`;
export const ProductPrintimgHolder = styled.div`
  max-width: 270px;
  padding: 30px 20px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  text-align: center;
`;
export const ProductPrintRight = styled.div`
  flex-grow: 1;
  margin-left: 28px;
  background: #fff;
  padding: 28px 36px;
`;
export const ProductViewContainer = styled.div`
  padding: 28px 40px;
  position: relative;
  height: calc(100vh - 80px);
  overflow-y: auto;
  background-color: #f8f8f8;
`;
export const ProductImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 338px;
  height: calc(100vh - 460px);
  min-height: 120px;
  max-width: 338px;
  border: 1px solid #E7E7E7;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
`;
export const NewGsTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: 18px;
    line-height: 24px;
    color: ${colors.Common.textColor};
    font-weight: 600;
  }
`;
export const NewProductTitle = styled(NewGsTitle)`
  position: inherit;
  width: fit-content;
  top: 15px;
  justify-content: space-between;
  padding: 28px 20px 28px 36px; 
  white-space: nowrap;
  color: ${colors.Common.textColor};
`;
export const NewProductGtin = styled.div`
  font-size: 16px;
`;
export const ProductStatus = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 36px 0 0;
`;

export const ProductStatusLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.Common.textColor};
`;
export const ProductStatusRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.Common.textColor};
`;
export const StatusLine = styled.span`
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    padding-left: 8px;
    font-weight: 400;
  }
`;
export const ProductStatusLine = styled(StatusLine)`
  color: ${(props) => props.color};
  span {
    font-size: 16px;
  }
  @media (min-width: 1920px) {
    padding-left: 28px;
  }
`;
export const ProductPackingPurpose = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 16px;
  @media (min-width: 1920px) {
    padding-left: 48px;
  }
`;
export const StatusBar = styled.div`
  position: sticky;
  display: flex;
  top: 0;
  z-index: 5;
  background: #fff;
  border-radius: 8px;
  width: 100%;
  min-height: 80px;
  margin-bottom: 28px;
  &::before {
    content: "";
    position: absolute;
    top: -28px;
    left: 0;
    background-color: #f8f8f8; 
    width: 100%;
    height: 28px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -28px;
    left: 0;
    background-color: #f8f8f8; 
    width: 100%;
    height: 28px;
  }
`;
export const CardState = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
  }
  @media (min-width: 1920px) {
    margin-right: 51px;
  }
`;
export const ProductStatusInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 23px;
`;
export const ProductStatusInfoLeft = styled.div`
  margin-right: 23px; 
  display: flex;
  align-items: center;
  span {
    margin-left: 11px;
    font-size: 16px;
  }
  @media (min-width: 1920px) {
    margin-right: 51px; 
  }
`;
export const ProductStatusInfoRight = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 11px;
    font-size: 16px;
  }
`;
export const ProductViewImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 85px,  
`;
export const ProductCodeIkpu = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-right: 17px;  
`;
export const ProductCodeIkpuTitle = styled.div`
  font-size: 16px;
  cursor: pointer;
`;
export const ProductCodeIkpuIcons = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;
export const CopyBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '25px',
        maxHeight: '45px',
        marginLeft: '8px',
      },
      text: {
        padding: '4px',
      },
    },
  },
});
export const TooltipIkpu = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: "340px",
        minHeight: "80px",
        position: "relative",
        borderRadius: "0"
      },
      label: {
        display: "flex",
        flexDirection: "row",
        lineHeight: "1.2",
        alignItems: "center",
        justifyContent: "flex-start"
      },
      text: {
        padding: "0 115px 0 30px"
      }
    },
    MuiButtonBase: {
      root: {
        backgroundColor: "transparent!important",
        padding: "0 10px",
        '&:hover': {
          backgroundColor: "transparent!important",
          color: "#000"
        }
      }
    }
  }
});
