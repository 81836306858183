import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { throwNotification } from '../../../../common/structure';
import { NoDataComponent, Pagination, Preloader } from '../../../../components';
import StatusCell from './cell/StatusCell';
import { IconGridEmpty } from '../../../../common/icons/customIcons';
import { ColumnCellProfile } from '../../../products/productGrid/ProductsGrid.styled';
import {
  ColumnCellInner,
  ColumnHeaderProfile,
  GridEmptyText,
  GridTableContainer,
  ManagementSiteCont,
  TableEmpty,
} from '../../../../common/styles/Common.styled';
import * as selectors from '../../ducks/ParentCompany/ParentCompany.selectors';
import { loadParentCompany } from '../../ducks/ParentCompany/ParentCompany.actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

class ParentCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      pageNum: 0,
    };
  }

  componentDidMount() {
    this.changeGridParams();
  }

  changeGridParams = () => {
    const params = {
      pageSize: this.state.pageSize,
      pageNum: this.state.pageNum,
    };
    this.props.onLoadParentCompanyData(params);
  };

  getPaginationProps = () => ({ totalObjects: this.props.totalObjects });

  render() {
    const { loading, t } = this.props;

    return (
      <React.Fragment>
        <ManagementSiteCont>
          <GridTableContainer className="customTable">
            {!this.props.totalObjects ? (
              <TableEmpty>
                <IconGridEmpty />
                <GridEmptyText>
                  <p>{t('У вас еще нет родительских компаний')}.</p>
                </GridEmptyText>
              </TableEmpty>
            ) : (
              <div style={{ display: loading ? 'none' : 'block' }}>
                <ReactTableFixedColumns
                  data={this.props.data}
                  columns={[
                    {
                      id: 'checkbox',
                      accessor: '',
                      fixed: 'left',
                      sortable: false,
                      maxWidth: 300,
                      Header: (x) => {
                        return (
                          <ColumnHeaderProfile>
                            {t('Доступ к товарам')}
                          </ColumnHeaderProfile>
                        );
                      },
                      Cell: ({ original }) => {
                        return (
                          <StatusCell
                            accessAllowed={original.accessAllowed}
                            t={t}
                          />
                        );
                      },
                    },
                    {
                      name: t('Наименование'),
                      show: true,
                      position: 0,
                      accessor: 'name',
                      sortable: false,
                      minWidth: 170,
                      Header: (x) => {
                        return (
                          <ColumnHeaderProfile>
                            {t('Наименование')}
                          </ColumnHeaderProfile>
                        );
                      },
                      Cell: ({ original }) => {
                        return (
                          <ColumnCellProfile>
                            <ColumnCellInner>{original.name}</ColumnCellInner>
                          </ColumnCellProfile>
                        );
                      },
                    },
                    {
                      name: t('ИНН'),
                      show: true,
                      position: 1,
                      accessor: 'inn',
                      sortable: false,
                      width: 440,
                      Header: (x) => {
                        return (
                          <ColumnHeaderProfile>{t('ИНН')}</ColumnHeaderProfile>
                        );
                      },
                      Cell: ({ original }) => {
                        return (
                          <ColumnCellProfile>
                            <ColumnCellInner>{original.inn}</ColumnCellInner>
                          </ColumnCellProfile>
                        );
                      },
                    },
                  ]}
                  className="-highlight"
                  resizable={false}
                  defaultPageSize={this.state.pageSize}
                  page={this.state.pageNum}
                  pages={Math.ceil(
                    this.props.totalObjects / this.state.pageSize,
                  )}
                  pageSize={this.state.pageSize}
                  PaginationComponent={Pagination}
                  getPaginationProps={this.getPaginationProps}
                  onPageChange={(page) =>
                    this.setState({ pageNum: page }, this.changeGridParams)
                  }
                  NoDataComponent={NoDataComponent}
                  minRows={0}
                  manual
                />
              </div>
            )}
          </GridTableContainer>

          <Preloader isOpen={loading} text={t('Загрузка')} />
        </ManagementSiteCont>
      </React.Fragment>
    );
  }
}

ParentCompany.propTypes = {
  throwNotification: PropTypes.func.isRequired,
  onLoadParentCompanyData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  totalObjects: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  loading: selectors.loading(state),
  totalObjects: selectors.totalObjects(state),
  data: selectors.data(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwNotification: (message, type) => throwNotification(message, type),
      onLoadParentCompanyData: (params) => loadParentCompany(params),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ParentCompany);
