import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import colors from '../../../../../common/styles/Common.colors';

export const PackInfoHelperCont = styled.div`
  //padding-right: 30px;
  position: relative;
  margin-bottom: 16px;
`;
export const GridItemA1 = styled.div`
  grid-column: col 1 / span 1;
`;
export const GridItemB1 = styled.div`
  grid-column: col 2 / span 1;
`;
export const GridItemC1 = styled.div`
  grid-column: col 3 / span 1;
`;
export const GridItemD1 = styled.div`
  grid-column: col 4 / span 1;
`;
export const GridItemE1 = styled.div`
  grid-column: col 7 / span 1;
`;
export const GridItemF1 = styled.div`
  grid-column: col 8 / span 1;
`;
export const GridItemG1 = styled.div`
  grid-column: col 3 / span 4;
`;

export const packageSelectTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiSelect: {
      root: {
        width: '100%',
        //marginBottom: '16px',
      },
      select: {
        padding: '23px 44px 7px 15px !important',
        borderRadius: '4px',
        lineHeight: '24px',
        border: '1px solid #C9C7C7',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$disabled': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
          },
        },
        '&:focus': {
          borderRadius: '4px',
          backgroundColor: '#ffffff',
          border: `1px solid ${colors.Input.border}`,
        }
      },
      selectMenu: {
        color: `${colors.Common.textColor}`,
      },
      disabled: {},
      icon: {
        right: '16px',
        '&$disabled': {
          '& path': {
            stroke: '#AAA9A9',
          },
        },
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
        fontFamily: 'SegoeUI',
        '&$disabled': {
          borderLeft: 'none !important',
        },
        "&[required][valid='false']": {
          '& div': {
            borderRadius: '0 4px 4px 0',
            borderLeft: 'none',
          },
        },
        "&[required][valid='false']$disabled": {
          '& div': {
            borderRadius: '4px',
            borderLeft: '1px solid #DCDCDC',
          },
        },
        "&$error": {
          borderRadius: '4px',
          '& div': {
            border: '1px solid #E32A38 !important',
          },
        },
      },
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
        width: 'calc(100% - 54px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px, 32px) scale(1)',
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey} !important`,
        '&$focused': {
          zIndex: '1',
        }
      },
      filled: {
        fontSize: '14px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        '&$focused': {
          color: `${colors.Common.textGrey} !important`,
          '&$disabled': {
            color: `${colors.Common.textGrey} !important`,
          },
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '0',
        paddingTop: '16px',
        paddingBottom: '16px',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
        whiteSpace: 'normal',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '300px!important',
        overflowY: 'auto',
        padding: '8px 24px',
        marginTop: '8px',
      },
      rounded: {
        borderRadius: '8px',
      },
      elevation8: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
      },
    },
  },
});
