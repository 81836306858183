import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactTable from 'react-table';
import moment from 'moment-timezone';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import _ from 'lodash';
import { IconGridEmpty } from '../../../../common/icons/customIcons';
import { Preloader, Pagination } from '../../../../components';
import {
  ColumnCellInner,
  ColumnHeaderProfile,
  GridEmpty,
  GridEmptyText,
  GridTableContainer,
  ManagementSiteCont,
} from '../../../../common/styles/Common.styled';
import { ColumnCellProfile } from '../../../products/productGrid/ProductsGrid.styled';

import {
  deleteProfileStatus,
  loadGlnDictionary,
  loadProfileStatus,
} from '../../ducks/ProfileStatus/ProfileStatus.actions';
import { throwNotification } from '../../../../common/structure';
import * as selectors from '../../ducks/ProfileStatus/ProfileStatus.selectors';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const ConfirmStateInit = {
  isOpen: false,
  title: '',
  message: '',
  funcAccept: null,
  funcDismiss: null,
  funcCancel: null,
  acceptText: '',
  dismissText: '',
};

const FiltersInit = {
  name: null,
  inn: null,
  address: null,
  gln: null,
};

const SortInit = [
  { field: 'name', direction: '' },
  { field: 'inn', direction: '' },
  { field: 'address', direction: '' },
  { field: 'gln', direction: '' },
];

const isNotActiveFilters = (filters) => _.isEqual(FiltersInit, filters);

class ProfileStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      pageNum: 0,
      sort: SortInit,
      filters: FiltersInit,

      selectedIds: [],
      deleteIds: [],
      editData: {},
      modalConfirm: ConfirmStateInit,
      modalCreation: { isOpen: false },
    };

    const tz = moment.tz.guess();
    moment.tz.setDefault(tz);
    this.mIsNotActiveFilters = _.memoize(isNotActiveFilters);
  }

  // componentDidMount() {
  //   if (_.isEmpty(this.props.glnDictionary)) {
  //     this.props.loadGlnDictionary();
  //   }
  //   this.changeGridParams();
  // }

  // getBuildParamsForList = () => {
  //   return {
  //     pageSize: this.state.pageSize,
  //     pageNum: this.state.pageNum,
  //     filters: this.state.filters,
  //     sort: getSortField(this.state.sort),
  //   };
  // };

  // changeGridParams = () => {
  //   // const params = this.getBuildParamsForList();
  //   this.props.onLoadProfileStatus();
  // };

  // resetFilters = () => {
  //   this.setState(
  //     (prevState) => ({ ...prevState, filters: FiltersInit }),
  //     this.changeGridParams
  //   );
  // };

  // getNoDataProps = () => ({ resetFilters: this.resetFilters });
  // getPaginationProps = () => ({ totalObjects: this.props.totalObjects });

  // confirmModal = (confirm, state = {}) => {
  //   const s = _.assign({}, state);
  //   this.setState((prevState) => ({
  //     ...prevState,
  //     ...s,
  //     modalConfirm: confirm,
  //   }));
  // };

  // onDelete = (id) => {
  //   const ids = _.isNumber(id) ? [id] : this.state.selectedIds;
  //   let message = "Вы уверены, что хотите удалить статусы?";
  //   if (_.size(ids) === 1) {
  //     const item = _.find(this.props.data, { id: ids[0] });
  //     message = `Вы уверены, что хотите удалить статус «${renderTypeToString(item.type)}» ?`;
  //   }

  //   if (!_.isEmpty(ids)) {
  //     const objModal = {
  //       isOpen: true,
  //       message: message,
  //       funcAccept: this.onDeleteAccept,
  //       funcDismiss: this.onDeleteDissmiss,
  //       funcCancel: this.onDeleteDissmiss,
  //       acceptText: "Удалить",
  //       dismissText: "Отменить",
  //     };
  //     this.confirmModal(objModal, { deleteIds: ids });
  //   }
  // };

  // onDeleteAccept = () => {
  //   const state = { deleteIds: [], selectedIds: [] };
  //   const deleteIds = _.clone(this.state.deleteIds);
  //   this.confirmModal(ConfirmStateInit, state);
  //   this.props.onDeleteProfileStatus(deleteIds[0], (error, data) => {
  //     let message = "Статусы успешно удалены";
  //     if (_.isNull(error)) {
  //       const item = _.find(this.props.data, { id: deleteIds[0] });
  //       message = `Статус «${renderTypeToString(item.type)}» успешно удален`;
  //       this.props.throwNotification(message, "success");
  //       this.changeGridParams();
  //     }
  //   });
  // };

  // onDeleteDissmiss = () => {
  //   this.confirmModal(ConfirmStateInit, { deleteIds: [] });
  // };

  // // method for possible future using
  // // onEdit = (id) => {
  // //   const item = _.find(this.props.data, {id: id});
  // //   this.setState({
  // //     modalCreation: { ...this.state.modalCreation, isOpen: true },
  // //     editData: item,
  // //   });
  // // };

  // openCreationModal = () => {
  //   this.setState({ modalCreation: { ...this.state.modalCreation, isOpen: true } });
  // };

  // hideCreationModal = (reload) => {
  //   this.setState({
  //     modalCreation: { ...this.state.modalCreation, isOpen: false },
  //     editData: {},
  //   });
  //   if (_.isBoolean(reload)) {
  //     this.changeGridParams();
  //   }
  // };

  render() {
    const {
      t,
      loading,
      totalObjects,
      // data,
    } = this.props;
    const { pageNum, pageSize } = this.state;

    const data = [
      {
        regNumber: 'GCP - 1324567894564231',
        glnNumber: 'GLN - 9876543213215648',
      },
      {
        regNumber: 'GCP - 13245678977778531',
        glnNumber: 'GLN - 999756543213215648',
      },
    ];

    return (
      <div>
        {/* {modalCreation.isOpen && (
          <CreationStatusModal
            editData={editData}
            handleClose={this.hideCreationModal}
            t={t}
          />
        )} */}

        {/* {modalConfirm.isOpen && (
          <ConfirmDialog
            onAccept={modalConfirm.funcAccept}
            onDismiss={modalConfirm.funcDismiss}
            onCancel={modalConfirm.funcCancel}
            title={modalConfirm.title}
            message={modalConfirm.message}
            acceptBtnText={modalConfirm.acceptText}
            dismissBtnText={modalConfirm.dismissText}
          />
        )} */}

        <ManagementSiteCont>
          {/* <ManagementSiteActions>
            <GridActionsRight>
              <MuiThemeProvider theme={DefaultGridButton}>
                <Button
                  disabled={loading || !isMainAccountUser}
                  onClick={this.openCreationModal}
                >
                  <IconPlusBlue style={{ marginRight: "10px" }} />{" "}
                  {t("Добавить статус профиля")}
                </Button>
              </MuiThemeProvider>
            </GridActionsRight>
          </ManagementSiteActions> */}

          <GridTableContainer className="customTable">
            {!data.length ? (
              <GridEmpty>
                <IconGridEmpty />
                <GridEmptyText>
                  <p>{t('У вас еще ничего нет')}.</p>
                </GridEmptyText>
              </GridEmpty>
            ) : (
              <div style={{ display: loading ? 'none' : 'block' }}>
                <ReactTableFixedColumns
                  data={data}
                  columns={[
                    {
                      name: t('Регистрационный номер предприятия GCP'),
                      show: true,
                      position: 0,
                      accessor: 'regNumber',
                      sortable: false,
                      minWidth: 400,
                      Header: (x) => (
                        <ColumnHeaderProfile id="nameGridFilter">
                          {t('Регистрационный номер предприятия GCP')}
                        </ColumnHeaderProfile>
                      ),
                      Cell: ({ original }) => (
                        <ColumnCellProfile>
                          <ColumnCellInner>
                            {original.regNumber}
                          </ColumnCellInner>
                        </ColumnCellProfile>
                      ),
                    },
                    {
                      name: t('Глобальный идентификационный номер GLN'),
                      show: true,
                      position: 1,
                      accessor: 'glnNumber',
                      sortable: false,
                      minWidth: 400,
                      Header: (x) => (
                        <ColumnHeaderProfile id="nameGridFilter">
                          {t('Глобальный идентификационный номер GLN')}
                        </ColumnHeaderProfile>
                      ),
                      Cell: ({ original }) => (
                        <ColumnCellProfile>
                          <ColumnCellInner>
                            {original.glnNumber || ''}
                          </ColumnCellInner>
                        </ColumnCellProfile>
                      ),
                    },
                    // {
                    //   name: null,
                    //   accessor: "actions",
                    //   sortable: false,
                    //   width: 0,
                    //   Header: null,
                    //   Cell: ({ original }) => (
                    //     <GridActionsItems
                    //       onDelete={this.onDelete}
                    //       id={original.id}
                    //     />
                    //   )
                    // },
                  ]}
                  defaultPageSize={pageSize}
                  className="-highlight"
                  page={pageNum}
                  pages={Math.ceil(totalObjects / pageSize)}
                  pageSize={pageSize}
                  PaginationComponent={Pagination}
                  getPaginationProps={this.getPaginationProps}
                  onPageChange={(page) => {
                    this.setState({ pageNum: page }, this.changeGridParams);
                  }}
                  resizable={false}
                  getNoDataProps={this.getNoDataProps}
                  minRows={0}
                  manual
                />
              </div>
            )}
          </GridTableContainer>
          <Preloader isOpen={loading} text={t('Загрузка')} />
        </ManagementSiteCont>
      </div>
    );
  }
}

ProfileStatus.propTypes = {
  throwNotification: PropTypes.func.isRequired,
  onLoadProfileStatus: PropTypes.func.isRequired,
  onDeleteProfileStatus: PropTypes.func.isRequired,
  loadGlnDictionary: PropTypes.func.isRequired,
  glnDictionary: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  totalObjects: PropTypes.number.isRequired,
  isMainAccountUser: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: !!selectors.loading(state),
  totalObjects: selectors.totalObjects(state),
  data: selectors.data(state),
  glnDictionary: selectors.glnDictionary(state),
  isMainAccountUser: selectors.isMainAccountUser(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwNotification: (message, type) => throwNotification(message, type),
      onLoadProfileStatus: () => loadProfileStatus(),
      onDeleteProfileStatus: (id, cbk) => deleteProfileStatus(id, cbk),
      loadGlnDictionary: () => loadGlnDictionary(),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ProfileStatus);
