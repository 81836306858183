import styled from 'styled-components';
import { ProfileTabCont } from '../../Profile.styled';
import colors from '../../../../common/styles/Common.colors';

export const ProfileTabGS1Cont = styled.div`
  padding: 16px 36px 28px;
  position: relative;
  min-height: fit-content;
`;
export const ProfileGsCont = styled(ProfileTabCont)`
  position: relative;
  padding: 70px 100px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
`;
export const NewGsTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: 18px;
    line-height: 24px;
    color: ${colors.Common.textColor};
    font-weight: 600;
    margin: 12px 0;
  }
`;
export const GsTitle = styled.div`
    font-size: 18px;
    line-height: 24px;
    color: ${colors.Common.textColor};
    font-weight: 600;
  }
`;
export const GsWrap = styled.div`
    padding: 20px 0 0 0;
    max-width: 1140px;
  }
`;
