import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MuiThemeProvider,
  Tooltip,
} from '@material-ui/core';
import Slider from 'infinite-react-carousel';
import { IconArrowLeft, IconArrowRight, IconClose, IconComponentClose } from '../../../../../common/icons/customIcons';
import {
  DialogTitleStyled,
  DialogTitleOfContentStyled,
  DefaultTooltip,
} from '../../../../../common/styles/Common.styled';
import { CloseBtn, CreationCloseBtns, LinkButton, WhiteBtn } from '../../../Profile.styled';
import { SubAccountDocViewer } from '../styled/AssignSubAccountModal.styled';
import {
  SliderImage,
  DialogTitleContent,
  // PreloaderWrapper, // переделать логику загрузки, чтобы добавить прелоадер.
  ImageContentWrapper,
  ImageActionsWrapper,
  DeleteImageButton,
  NoImagesWrapper,
  SubAccountFileTitle,
} from '../styled/SubAccountFileView.styled';
import {
  getGcpGlnLetters,
  deleteGcpGlnLetter,
} from '../../../ducks/SubAccount/SubAccount.actions';
import { subtractNotNegative } from '../../../../../common/utils/utils';

/**
 * TODO:
 * и почистить ненужные импорты.
 */
class SubAccountFileView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentIndex: 0,
      isOpen: false,
      currentImgLink: null,
    };
  }

  componentDidMount() {
    if (_.isEmpty(this.state.data)) {
      this.getImages();
    }
  }

  getImages = () => {
    this.props.getImages(this.props.subaccountData.AccountId, (success) => {
      if (_.isArray(success)) {
        return this.setState({
          data: success,
        });
      }
    });
  };

  deleteImage = () => {
    const objectToDelete = _.cloneDeep(
      this.state.data[this.state.currentIndex],
    );
    this.props.deleteLetter(objectToDelete, (success) => {
      if (_.isArray(success) && !_.isEmpty(success) && success[0] === true) {
        this.getImages();
        this.setState((prevState) => ({
          currentIndex: subtractNotNegative(prevState.currentIndex),
        }));
      }
    });
  };

  setObjectIndex = (oldIndex, newIndex) => {
    if (
      newIndex === this.setState.currentIndex ||
      oldIndex === this.setState.currentIndex
    ) {
      return;
    }

    if (newIndex !== undefined && newIndex !== this.setState.currentIndex) {
      return this.setState({
        currentIndex: newIndex,
      });
    }

    if (newIndex === undefined && _.isNumber(oldIndex)) {
      return this.setState({
        currentIndex: oldIndex,
      });
    }
  };

  render() {
    const { handleClose, t, subaccountData } = this.props;
    const { data } = this.state;

    /**
     * TODO:
     * переписать всё на подобие TradeMarkFileView;
     */
    const imageSrc = _.defaultTo(
      this.state.data[this.state.currentIndex]?.Attachment,
      ``,
    );

    return (
      <MuiThemeProvider theme={SubAccountDocViewer}>
        <Dialog open>
          <DialogTitle>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <DialogTitleStyled>
                {t('Просмотр загруженных документов')}
                {/* {t('Просмотр письма-подтверждения индентификаторов GCP/GLN')} */}
              </DialogTitleStyled>
              <MuiThemeProvider theme={CloseBtn}>
                <IconButton className="backEmpty" onClick={handleClose}>
                  <IconClose />
                </IconButton>
              </MuiThemeProvider>
            </div>
            <DialogTitleContent>
              <SubAccountFileTitle>
                <span>GCP:</span>
                  {(subaccountData.GCP && _.split(subaccountData.GCP, ', ').length > 1)
                    ? (
                      <MuiThemeProvider theme={DefaultTooltip}>
                        <Tooltip placement="bottom" title={subaccountData.GCP}>
                          <div>
                            {_.split(subaccountData.GCP, ', ')[0] + ', ...'}
                          </div>
                        </Tooltip>
                      </MuiThemeProvider>
                    )
                    : subaccountData.GCP || ''}
              </SubAccountFileTitle>
              {/* <DialogTitleStyled>{`GCP: ${subaccountData.GCP}`}</DialogTitleStyled> */}
              {/* <DialogTitleStyled>{`GLN: ${subaccountData.GLN}`}</DialogTitleStyled> */}
            </DialogTitleContent>
          </DialogTitle>

          <DialogContent>
            {data.length ? (
              <ImageContentWrapper>
                <Slider
                  prevArrow={<button><IconArrowLeft/></button>}
                  nextArrow={<button><IconArrowRight/></button>}
                  beforeChange={(oldIndex, newIndex) =>
                    this.setObjectIndex(oldIndex, newIndex)
                  }>
                  {data.length &&
                    data.map((item) => {
                      return (
                        <div key={item.SubAccountCodeConfirmationMailId}>
                          <SliderImage>
                            <div>
                              <img
                                alt="Изображение"
                                src={item.Attachment}
                                style={{
                                  //maxWidth: '710px',
                                  maxWidth: '-webkit-fill-available',
                                  maxHeight: 'auto',
                                  //maxWidth: '595px',
                                  //maxHeight: 'calc(100vh - 285px)',
                                  //objectFit: 'contain',
                                }}
                              />
                            </div>
                          </SliderImage>
                        </div>
                      );
                    })}
                </Slider>
                {/* <ImageActionsWrapper>
                  <DeleteImageButton onClick={this.deleteImage}>
                    {' '}
                    {t('Удалить письмо')}{' '}
                  </DeleteImageButton>
                  <div style={{ display: 'flex' }}>
                    <a
                      href={`${imageSrc}`}
                      download="Letter"
                      target="_blank"
                      rel="noopener noreferrer">
                      <div>{t('Открыть оригинал')}</div>
                    </a>
                  </div>
                </ImageActionsWrapper> */}
              </ImageContentWrapper>
            ) : (
              <DialogTitleOfContentStyled>
                <NoImagesWrapper>
                  {t('У вас нет загруженных писем')}
                </NoImagesWrapper>
              </DialogTitleOfContentStyled>
            )}
          </DialogContent>
          <DialogActions>
            <MuiThemeProvider theme={WhiteBtn}>
              <Button onClick={this.deleteImage}>
                {t('Удалить')}
              </Button>
            </MuiThemeProvider>
            <LinkButton>
              <a
                href={`${imageSrc}`}
                download="Letter"
                target="_blank"
                rel="noopener noreferrer">
                <div>{t('Открыть оригинал')}</div>
              </a>
            </LinkButton>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

SubAccountFileView.propTypes = {
  handleClose: PropTypes.func.isRequired,
  getImages: PropTypes.func.isRequired,
  deleteLetter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getImages: (id, cbk) => getGcpGlnLetters(id, cbk),
      deleteLetter: (data, cbk) => deleteGcpGlnLetter(data, cbk),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(SubAccountFileView);
