import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEqual, isNull } from 'lodash';
import { Button, MuiThemeProvider, Popover, IconButton } from '@material-ui/core';
import { CustomInput, SimpleTooltip } from '../../../components';
import { isEmpty } from 'lodash';
import {
  IconClose,
  IconInfoCard,
  IconNewFilterSmall,
  IconNewFilterSmallActive,
} from '../../../common/icons/customIcons';
import {
  defaultFilterInput,
  DefaultFilterPopover,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
  FilterErrorSmall,
  FilterBtnReset,
  FilterContentPopup,
  ErrorContent,
  FilterInfo,
} from './Filters.styled';

class INNTypeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      // inn: this.props.currentValue ? this.props.currentValue.join('\n') : '',
      inn: this.props.currentValue ? String(this.props.currentValue) : '',
      error: '',
      currentValue: this.props.currentValue,
      message: 'Налоговый идентификатор содержит от 3 до 14 цифр',
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        inn: '',
        error: '',
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state);
  }

  componentDidUpdate(prevState) {
    if (this.props.type === 'modal' && (prevState.inn !== this.state.inn)) {
      this.validateinn(this.state.inn);
    }
  }

  onValueChange = (value) => {
    value = value.replace(/[^[0-9]/, '');
    this.setState({
      inn: value,
      error: '',
    });

    if (this.props.type === 'modal') {
      this.props.onValueChange('itn', value);
    }
  };

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      inn: this.props.currentValue ? String(this.props.currentValue) : '',
      error: '',
    });
  };

  handleAccept = () => {
    if (this.validateinn(this.state.inn)) {
      this.setState({
        anchorEl: null,
        currentValue: this.state.inn,
      });
      this.props.onAccept('itn', this.state.inn);
    }
  };

  validateinn = (inn) => {
    const { t } = this.props;
    let valid = true;
    let error = '';

    if ((inn.length < 3 && inn.length > 0) || inn.length > 14) {
      error = `${t('Недостаточно символов')}. \n ${t(
        'Налоговый идентификатор должен содержать от 3 до 14 цифр',
      )}`;
      valid = false;
    }
    this.setState({ inn, error });

    if (this.props.type === 'modal') {
      this.props.setErrorInn(error);
    }
    return valid;
  };

  handleDissmiss = () => {
    this.setState({ anchorEl: null, inn: '', error: '' });
    this.props.onDismiss('itn');
  };

  render() {
    const { t } = this.props;
    const { anchorEl, message } = this.state;
    const open = Boolean(anchorEl);

    return (
      <>
        {this.props.type !== 'modal' && (
          <div>
            <MuiThemeProvider theme={FilterBtn}>
              <FilterButton>
                <Button
                  aria-owns={open ? 'simple-popper' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}>
                  {isNull(this.props.currentValue) ? (
                    <IconNewFilterSmall />
                  ) : (
                    <IconNewFilterSmallActive />
                  )}
                </Button>
              </FilterButton>
            </MuiThemeProvider>
            <MuiThemeProvider theme={DefaultFilterPopover}>
              <Popover
                id="simple-popper"
                open={open}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                <FilterTitle>
                  <h6>{t('Налоговый идентификатор')}</h6>

                  <MuiThemeProvider theme={FilterBtn}>
                    <IconButton className="backEmpty" onClick={this.handleClose}>
                      <IconClose />
                    </IconButton>
                  </MuiThemeProvider>
                </FilterTitle>
                <FilterContent withErrorContent>
                  <MuiThemeProvider theme={defaultFilterInput}>
                    <CustomInput
                      cleanButtonStyles={{ marginTop: -15, display: 'none' }}
                      label={t('Введите идентификатор')}
                      onChange={this.onValueChange}
                      value={this.state.inn}
                      onBlur={false}
                      name="inn-filter"
                      maxLength={14}
                      error={!!this.state.error}
                    />
                  </MuiThemeProvider>
                  <FilterInfo>
                    {SimpleTooltip(t(message), <IconInfoCard style={{ width: '24px', height: '24px' }} />)}
                  </FilterInfo>
                </FilterContent>
                <ErrorContent>{this.state.error}</ErrorContent>
                <FilterBtns>
                  <MuiThemeProvider theme={FilterBtnReset}>
                    <Button
                      disabled={!this.state.inn}
                      onClick={this.handleDissmiss}>
                      {t('Сбросить')}
                    </Button>
                  </MuiThemeProvider>
                  <Button disabled={!this.state.inn} onClick={this.handleAccept}>
                    {t('Применить')}
                  </Button>
                </FilterBtns>
              </Popover>
            </MuiThemeProvider>
          </div>
        )}
        {this.props.type === 'modal' && (
          <div>
            <FilterTitle>
              <h6>{t('Налоговый идентификатор')}</h6>
            </FilterTitle>
            <FilterContentPopup withErrorContent>
              <MuiThemeProvider theme={defaultFilterInput}>
                <CustomInput
                  cleanButtonStyles={{ marginTop: -15, display: 'none' }}
                  label={t('Введите идентификатор')}
                  onChange={this.onValueChange}
                  value={this.state.inn}
                  onBlur={false}
                  name="inn-filter"
                  maxLength={14}
                  error={!!this.state.error}
                />
              </MuiThemeProvider>
              <FilterInfo modal>
                {SimpleTooltip(t(message), <IconInfoCard style={{ width: '24px', height: '24px' }} />)}
              </FilterInfo>
            </FilterContentPopup>
            <ErrorContent>{this.state.error}</ErrorContent>
          </div>
        )}
      </>
    );
  }
}

INNTypeFilter.propTypes = {
  onAccept: PropTypes.func,
  onDismiss: PropTypes.func,
  currentValue: PropTypes.string,
  parentId: PropTypes.string,
  onValueChange: PropTypes.func,
  type: PropTypes.string,
};

export default withTranslation()(INNTypeFilter);
