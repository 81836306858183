import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../common/styles/Common.colors';

export const CompaniesGridContainer = styled.div`
  padding: 28px 36px 0;
  background: #ffffff;
  position: relative;
  height: 100%;
`;

export const GridActionsView = styled.div`
  padding: 0 20px 0 0;
  flex-shrink: 0;
  @media (max-width: 1366px) {
    padding: 0 16px 0 0;
  } 
  display: flex;
  justify-content: space-between;  
`;

export const ResetFilterButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      label: {
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
        textTransform: "none"
      },
      root: {
        borderRadius: '4px',
        border: '1px solid #DCDCDC',
        outline: 'none !important',
        padding: '9px 23px !important',
        marginRight: '20px',
        '@media (max-width: 1366px)': {
          marginRight: '16px',
        },
        '&:hover': {
          backgroundColor: '#F5F5F5',
          borderColor: '#F5F5F5',
        },
        '&:first-child': {
          marginRight: '0',
        },
        '&:last-child': {
          marginRight: '0',
        }
      }
    },
  },
});

export const GridTable = styled.div`
  box-shadow: 0px 1px 8px rgba(41, 49, 53, 0.1);
  border-radius: 4px;
`;

export const ColumnCell = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.Common.textColor};
  padding: 10px 12px;
  width: 100%;
  height: 100%;
`;

export const ColumnCellProfile = styled(ColumnCell)`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.Common.textColor};
  padding: 10px 12px;
  width: 100%;
  height: 100%;
`;

export const CompaniesContainer = styled.div`
  height: calc(100vh - 80px);
  background-color: #f8f8f8;
  padding: 28px 40px;
`;
