import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../common/styles/Common.colors';

export const DefaultFilterPopover = createMuiTheme({
  typography: {},
  overrides: {
    MuiPopover: {
      paper: {
        maxHeight: '500px',
        minWidth: '380px',
        maxWidth: '420px',
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
        borderRadius: '8px',
        padding: '24px 28px',
        boxSizing: 'border-box',
        marginLeft: '0',
        marginTop: '0',
      },
    },
    MuiButton: {
      root: {
        maxWidth: '160px',
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '4px',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '10px 35px',
        fontWeight: '600',
        textTransform: 'capitalize',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
        lineHeight: '24px',
      },
      disabled: {},
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        minWidth: '80px',
        margin: '0 16px 0 0 !important'
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: `${colors.Common.textColor} !important`,
      },
    },
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '4px',
      },
      input: {
        padding: '15px 9px',
        lineHeight: '21px',
        boxSizing: 'border-box',
      },
    },
    MuiInputBase: {
      input: {
        boxSizing: 'border-box',
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '3px',
        marginRight: '8px',
      },
    },
    MuiSvgIcon: {
      root: {
        width: '18px',
        height: '18px',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
      },
    },
  },
});
export const FilterBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '25px',
        maxHeight: '45px',
        marginLeft: 'auto',
        '&:hover': {
          backgroundColor: '#E7E7E7',
          borderRadius: '2px'
        },
      },
      text: {
        padding: '2px',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    }
  },
});
export const FilterIcon = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '25px',
        maxHeight: '45px',
      },
      text: {
        padding: '4px',
      },
    },
  },
});
export const FilterTypingSelect = createMuiTheme({
  typography: {},
  overrides: {
    MuiInputBase: {
      root: {
        borderRadius: '4px',
        border: '1px solid #C9C7C7',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
      },
      input: {
        padding: '15px 68px 15px 15px',
        fontSize: '16px',
        lineHeight: '24px',
        color: `${colors.Common.textColor}`,
        height: 'auto',
        fontFamily: 'SegoeUI',
        textOverflow: 'ellipsis',
      },
    },
    MuiMenuItem: {
      root: {
        padding: '0',
        paddingTop: '16px',
        paddingBottom: '16px',
        fontSize: '16px',
        lineHeight: '24px',
        color: `${colors.Common.textColor}`,
        fontFamily: 'SegoeUI',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '300px',
        overflowY: 'auto',
        padding: '8px 24px',
        borderTop: '1px solid #AAA9A9',
      },
      elevation1: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
      }
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        width: '24px',
        maxHeight: '100%',
        pointerEvents: 'none',
      },
      positionStart: {
        marginRight: '15px'
      }
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '0',
        minWidth: '15px',
        width: '20px',
        height: '20px',
        '&:hover': {
          background: 'none!important',
        },
      },
      text: {
        padding: '0',
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      }
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '14px',
        lineHeight: '16px',
      },
      formControl: {
        transform: 'translate(16px, 16px) scale(1) !important',
        color: `${colors.Common.textGrey} !important`,
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 20px) scale(1) !important',
        color: `${colors.Common.textGrey} !important`,
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)!important',
        },
      }
    }
  },
});

export const defaultFilterInput = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        width: '100%',
        borderRadius: '4px',
        border: '1px solid #C9C7C7',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
        '&$error': {
          border: '1px solid #E32A38',
        },
      },
      input: {
        color: `${colors.Common.textColor}`,
        padding: '23px 44px 7px 15px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 'normal',
        '&:required': {
          borderRadius: '4px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
      inputMultiline: {
        padding: '0!important',
      },
      multiline: {
        minHeight: '56px',
        padding: '23px 44px 7px 15px',
      },
      error: {},
    },
    MuiInputBase: {
      root: {
        width: '100%',
        marginTop: '0!important',
      },
      input: {
        boxSizing: 'border-box',
        height: 'auto',
        fontFamily: 'SegoeUI',
      },
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
      },
      formControl: {
        transform: 'translate(16px, 16px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 8px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        '&$focused': {
          color: `${colors.Common.textColor}`,
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});
export const FilterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6 {
    color: ${colors.Common.textColor};
    font-size: 18px;
    margin: 0 24px 0 0;
    line-height: 24px;
    font-weight: 600;
    padding-bottom: 3px;
  }
  .backEmpty {
    background: none;
  }
`;
export const FilterContent = styled.div`
  display: flex;
  padding: 28px 0;
  position: relative;
  padding-bottom: ${props => props.withErrorContent ? "2px" : "28px"};
`;
export const FilterContentPopup = styled(FilterContent)`  
  padding: 16px 0 32px;
  padding-bottom: ${props => props.withErrorContent ? "4px" : "32px"};
`;
export const FilterContentProvider = styled(FilterContent)`
  flex-direction: column;
`;
export const FilterContentProviderContainer = styled.div`
  position: relative;
`;
export const FilterBtns = styled.div`
  display: flex;
  justify-content: flex-end;
  .backEmpty {
    background: none;
    color: ${colors.Common.textColor} !important;
    &:hover{
      color: #fff !important;
    }
  }
`;
export const FilterErrorSmall = styled.div`
  position: absolute;
  right: 25px;
  top: 12%;
`;
export const FilterInfo = styled.div`
position: absolute;
right: 8px;
top: ${props => props.modal ? "0" : "13px"};
`;
export const FilterContainer = styled.div`
  .filterBtn {
    min-width: 25px;
    padding: 4px;
    height: 25px;
  }
`;

export const FilterButton = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

export const FilterPopover = createMuiTheme({
  typography: {},
  overrides: {
    MuiPopover: {
      paper: {
        maxHeight: 'calc(100vh - 218px)',
        minHeight: '414px',
        minWidth: '540px',
        maxWidth: '540px',
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
        borderRadius: '8px',
        padding: '32px 0',
        boxSizing: 'border-box',
        marginLeft: '0',
        marginTop: '0',
      },
      root: {
        transform: 'translate(36px, 8px)',
      },
    },
    MuiButton: {
      root: {
        maxWidth: '160px',
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '4px',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '10px 35px',
        fontWeight: '600',
        textTransform: 'capitalize',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
        lineHeight: '24px',
      },
      disabled: {},
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        minWidth: '120px',
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: `${colors.Common.textColor} !important`,
      },
    },
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
      },
      input: {
        padding: '15px 9px',
        lineHeight: '21px',
        boxSizing: 'border-box',
      },
    },
    MuiInputBase: {
      input: {
        boxSizing: 'border-box',
      },
    },
  },
});

export const FilterBtnTitle = styled.span`
  margin-left: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letterSpacing: 0;
  display: none;
  @media screen and (min-width: 1920px) {
    display: block;
  }
  `;

export const FilterMainTitle = styled.h3`  
    color: ${colors.Common.textColor};
    font-size: 21px;
    font-weight: 600;
    margin: 0 36px 28px;
    line-height: 24px;    
`;

export const FilterMainContent = styled.div`
 min-height: 222px;
 max-height: calc(100vh - 430px);
 overflow: auto;
//  border-top: 1px solid #e7e7e7;
`;

export const FilterMainBtns = styled.div`
display: flex;
justify-content: flex-end;
padding: 32px 36px 0;
border-top: 1px solid #e7e7e7;
`;

export const FilterItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;  
`;

export const ErrorContent = styled.span`
    display: block;
    font-size: 13px;    
    line-height: 16px;
    color: #E32A38;
    min-height: ${props => props.modal ? "16px" : "32px"};
    margin-bottom: ${props => props.modal ? "12px" : "2px"};
`;

export const CancelFilterButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '44px',
        maxWidth: '44px',
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '4px',
        margin: '0 0 0 8px',
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '10px',
      }
    },
    MuiTooltip: {
      popper: {
        width: '162px',
        margin: '12px 0 0 0',
      },
      tooltip: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 'normal',
        letterSpacing: '0',
        fontFamily: 'SegoeUI',
        color: '#FFFFFF',
        opacity: '1!important',
        transform: 'none!important',
        backgroundColor: `${colors.Common.textColor}`,
        borderRadius: '8px',
        padding: '7px 12px',
        textAlign: 'center',
        position: 'relative!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 6px 8px',
          borderColor: `transparent transparent ${colors.Common.textColor} transparent`,
          borderStyle: 'solid',
          position: 'absolute',
          top: '-7px',
          right: '73px',
        },
      },
      tooltipPlacementBottom: {
        margin: '0 !important',
      }
    },
  },
});

export const FilterBtnReset = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        marginRight: '20px',
        borderRadius: '4px',
        border: '1px solid #DCDCDC',
        '&:hover': {
          backgroundColor: '#F5F5F5 !important',
          color: `${colors.Common.textColor} !important`,
          borderColor: '#F5F5F5',
        },
        '&$disabled': {
          color: '#C9C7C7',
          '& path': {
            stroke: '#C9C7C7',
          }
        },
      },
      text: {
        padding: '9px 24px',
        fontWeight: '600',
        textTransform: 'capitalize',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
      }
    },
  },
});
