import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  IconClose,
  IconDrop,
  IconCheck,
  IconCheckbox,
} from '../../../../common/icons/customIcons';
import {
  CheckCont,
  DefaultOrderPopover,
  DropIconCont,
  // OrderNumber,
} from './ColumnOrder.styled';
import { withTranslation } from 'react-i18next';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 0;

const getItemStyle = (isDragging, draggableStyle, isShow) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '16px 0',
  margin: `0 0 ${grid}px 0`,
  boxShadow: isDragging
    ? '0px 8px 48px rgba(86, 118, 129, 0.1)'
    : 'none',
  // change background colour if dragging
  background: '#fff',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 'normal',
  cursor: 'move',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'none' : 'none',
  padding: grid,
  width: '100%',
});

class ColumnOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.columns,
      dirty: false,
    };
  }

  handleChange = (e, value) => {
    const items = _.map(this.state.items, (item) => {
      if (value.accessor === item.accessor) {
        item.show = !item.show;
      }
      return item;
    });
    this.setState({ items: items, dirty: true });
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index,
    );

    items = _.map(items, (item, index) => {
      item.position = index;
      return item;
    });
    this.setState({ items: items, dirty: true });
  };

  resetToDefault = () => {
    this.setState({
      items: _.cloneDeep(this.props.defaultColumns),
      dirty: true,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <MuiThemeProvider theme={DefaultOrderPopover}>
        <Dialog open>
          <DialogTitle disableTypography>
            <Typography variant="h2">
              {t('Вид таблицы')}
            </Typography>
            <IconButton
              onClick={this.props.onCancel}
            >
              <IconClose />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}>
                    {_.map(this.state.items, (item, index) => (
                      <Draggable
                        key={item.accessor}
                        draggableId={item.accessor}
                        index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              item.show,
                            )}>
                            {/* <OrderNumber>{index + 1}</OrderNumber> */}
                            <CheckCont>
                              {item.name !== t('Статус') &&
                                item.name !== t('Торговое наименование товара')
                                ? (
                                  <Checkbox
                                    checkedIcon={<IconCheck />}
                                    icon={<IconCheckbox />}
                                    color="default"
                                    checked={item.show}
                                    onChange={(e) => this.handleChange(e, item)}
                                  />
                                )
                                : (
                                  <Checkbox
                                    checkedIcon={<IconCheck />}
                                    disabled
                                    checked
                                  />
                                )
                              }
                            </CheckCont>
                            {item.name}
                            <DropIconCont>
                              {/* {snapshot.isDragging ? (
                                <IconDropColor />
                              ) : ( */}
                              <IconDrop />
                              {/* )} */}
                            </DropIconCont>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={_.isEqual(
                this.state.items,
                this.props.defaultColumns,
              )}
              color={'secondary'}
              onClick={this.resetToDefault}>
              {t('Отменить')}
            </Button>
            <Button
              disabled={!this.state.dirty}
              color={'primary'}
              onClick={() => this.props.onSave(this.state.items)}>
              {t('Сохранить')}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

ColumnOrder.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  defaultColumns: PropTypes.array.isRequired,
};

export default withTranslation()(ColumnOrder);
