import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../common/styles/Common.colors';

export const InstructionDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '595px',
        maxWidth: '595px',
        boxShadow: 'none',
        borderRadius: '8px',
        padding: '0',
        overflow: 'auto',
        '@media (min-width: 1920px)': {
          minWidth: '628ppx',
          maxWidth: '628ppx',
        },
      },
      paper: {
        overflowY: 'none',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '30px 36px',
        textAlign: 'start',
        position: 'relative',
        color: '#63666A',
        fontSize: '14px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 36px 36px',
        overflowY: 'none',
      },
    },
    MuiDialogActions: {
      root: {
        margin: '0',
        padding: '30px 36px',
        borderTop: '1px solid #E7E7E7'
      },
    },
    MuiButton: {
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: '.4s ease',
        borderRadius: '3px',
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        padding: '14px 35px',
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          color: '#63666A',
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          boxShadow: 'none',
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textSecondary: {
        background: 'none',
        color: '#63666A',
        transition: '.4s ease',
        borderRadius: '3px',
        border: '1px solid #868686',
        padding: '14px 35px',
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          color: '#868686',
          backgroundColor: 'transparent',
        },
      },
      root: {
        fontSize: '16px',
        textTransform: 'none',
        borderRadius: '3px',
        height: 'auto',
        minWidth: '200px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: '600',
      },
      text: {
        padding: '14px 20px',
      },
      label: {
        lineHeight: '19px',
      },
      disabled: {},
    },
  },
});

export const AnalyticInstruction = styled.div`
  height: 100%;
  padding: 10px 12px 10px;
  border-radius: 4px;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const AnalyticInstructionTitle = styled.div`
  font-size: 21px;
  line-height: 24px;
  color: #000000;
  //padding-bottom: 28px;
`;
export const AnalyticInstructionImage = styled.div`
  display: flex;
  justify-content: center;
  img {
    @media (min-width: 1600px) {
      width: 100%;
    }
  }
`;
export const AnalyticInstructionMap = styled.div`
  display: flex;
`;
export const AnalyticInstructionDashed = styled.div`
  border-top: 1px dashed #C9C7C7;
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
`;
export const AnalyticInstructionLeft = styled.div`
  width: 55%
`;
export const AnalyticInstructionLeftTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 28px;
  margin-left: -10px;
`;
export const AnalyticInstructionRight = styled.div`
  width: 45%;
  padding-top: 54px;
`;
export const AnalyticInstructionRightTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 28px;
  margin-left: -10px;
`;
export const AnalyticInstructionService = styled.div`
  border-left: 1px solid #DCDCDC;
  //transform: rotate(90deg);
  padding: 0px 28px 24px;
  position: relative;
  min-width: 245px;
  &:nth-last-of-type(1) {
    border-left: 0;
  }
`;
export const AnalyticInstructionBlock = styled.div`
  border-left: 1px solid ${colors.Icons.borderLight};
  //transform: rotate(90deg);
  padding: 0px 28px 24px;
  position: relative;
  max-width: 262px;
  &:nth-last-of-type(1) {
    border-left: 0;
  }
`;
export const AnalyticInstructionSvg = styled.div`
  width: 40px;
  height: 40px;
  background: ${colors.Icons.backgroundLight};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${(props) =>
    props.fourSvg
      ? 'block'
      : 'absolute'
  };
  top: -9px;
  left: 40px;
`;
export const AnalyticInstructionFourSvg = styled.div`
  display: flex;
  width: 88px;
  height: 88px;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const AnalyticInstructionName = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding-top: 35px;
  max-width: 244px;
`;
export const AnalyticInstructionEllipse = styled.div`
  position: absolute;
  left: -13px;
  height: 24px;
  width: 24px;
`;
export const AnalyticInstructionBottom = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-top: 38px;;
  display: flex;
  justify-content: flex-start;
  margin-left: -10px;
  div {
    max-width: 494px;
    margin-right: 4px;
  }
  @media (min-width: 1920px) {
    padding: 38px 55px 18px;
  }
`;

export const CreationCloseBtns = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '24px',
        //height: '24px',
        position: 'absolute',
        top: '30px',
        right: '20px',
      },
      text: {
        padding: '3px',
        color: '#868686',
        fontSize: '14px',
        textTransform: 'uppercase',
      },
    },
  },
});