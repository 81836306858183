import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { ProfileDivider } from '../../../Profile.styled';
import { IconCheck, IconCheckbox } from '../../../../../common/icons/customIcons';
import { SmallEl } from '../../../../../common/styles/Common.styled';
import { AgreementMain, AgreementTitle } from './styled/Agreement.styled';

class Agreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // sendData = (certificate) => {
  //     const {t} = this.props;
  //     const email = get(this.props.data, 'applicant.email');

  //     this.onSelectCertClick();
  //     this.setState({loading: true});
  //     this.props.save(this.props.info, certificate.certificate, (error) => {
  //         this.setState({loading: false});
  //         if (isNil(error)) {
  //             this.props.switchTab(6);
  //             const text = `
  //             ${t('Заявка успешно зарегистрирована')}.
  //             ${t('Пожалуйста, ожидайте счёт-подтверждение на электронную почту', {email: email || ''})}.
  //             ${t('Обращаем внимание, что с момента получения счёта Вам предоставлена возможность генерации до 100 бесплатных кодов товаров и отсрочка оплаты счёта - 180 дней с момента использования выделенных номеров бесплатного диапазона')}.
  //             ${t('Информацию о членстве в ассоциации Вы сможете посмотреть в профиле во вкладке «Заявка в»', {gs1Name: t(config.gs1Name), gs1Membership: t('Заявка в', {gs1Name: t(config.gs1Name)})})}.`;

  //             this.props.throwNotification(text, 'success', 10000);
  //         }
  //     });
  // };

  // onSelectCertClick = () => {
  //     this.setState({selectCertModal: !this.state.selectCertModal});
  // };

  // onSelectCertificate = (value) => {
  //     this.setState({certificate: value});
  // };

  render() {
    const { t, agreement, handleCheckBox } = this.props;
    const str = `${t(
      'Настоящим согласием Вы подтверждаете, что переданные Вами данные являются полными и достоверными и могут быть переданы третьей стороне',
    )}. ${t(
      'Ваше согласие дается на неопределенный срок и может быть в любой момент отозвано путем направления письменного уведомления в адрес организации GS1',
    )}.`;

    return (
      <div>
        <AgreementMain>
          {/* <AgreementTitle>
            <SmallEl>* </SmallEl>{' '}
            {t('Согласие на передачу данных третьей стороне')}
          </AgreementTitle> */}
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreement}
                  onChange={handleCheckBox}
                  checkedIcon={<IconCheck />}
                  icon={<IconCheckbox />}
                />
              }
              label={str}
            />
          </div>
        </AgreementMain>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // data: state.gs1Reducer.data,
  // info: state.profileReducer.info,
  // certificates: state.profileReducer.certificates
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      // save: (data, certificate, cbk) => saveGS1(data, certificate, cbk),
      // throwNotification: (message, type) => throwNotification(message, type),
      // onLoadCertificates: () => loadCertificates(),
    },
    dispatch,
  );
};

Agreement.propTypes = {
  // throwNotification: PropTypes.func.isRequired,
  // data: PropTypes.object.isRequired,
  // info: PropTypes.object.isRequired,
  // certificates: PropTypes.array.isRequired
  agreement: PropTypes.bool.isRequired,
  handleCheckBox: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Agreement);
