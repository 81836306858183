import styled from 'styled-components';
import colors from '../../../../../common/styles/Common.colors';
import { createMuiTheme } from '@material-ui/core';

export const TradeMarkDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '628px',
        maxWidth: '628px',
        boxShadow: 'none',
        borderRadius: '8px',
        padding: '0',
        '@media (min-width: 1920px)': {
          minWidth: '628ppx',
          maxWidth: '628ppx',
        },
      },
      paper: {
        overflowY: 'none',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '32px 36px',
        textAlign: 'center',
        position: 'relative',
        color: '#63666A',
        fontSize: '14px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 36px 32px',
        overflowY: 'none',
      },
    },
    MuiDialogActions: {
      root: {
        margin: '0',
        padding: '30px 36px',
        borderTop: '1px solid #E7E7E7'
      },
    },
    MuiButton: {
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: '.4s ease',
        borderRadius: '3px',
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        padding: '14px 35px',
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          color: '#63666A',
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          boxShadow: 'none',
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textSecondary: {
        background: 'none',
        color: '#63666A',
        transition: '.4s ease',
        borderRadius: '3px',
        border: '1px solid #868686',
        padding: '14px 35px',
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          color: '#868686',
          backgroundColor: 'transparent',
        },
      },
      root: {
        fontSize: '16px',
        textTransform: 'none',
        borderRadius: '3px',
        height: 'auto',
        minWidth: '200px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: '600',
      },
      text: {
        padding: '14px 20px',
      },
      label: {
        lineHeight: '19px',
      },
      disabled: {},
    },
  },
});

export const TradeMarkViewDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '515px',
        maxWidth: '515px',
        maxHeight: 'calc(100% - 80px)',
        minHeight: 'calc(100% - 80px)',
        boxShadow: 'none',
        borderRadius: '8px',
        padding: '0',
        '@media (min-width: 1920px)': {
          minWidth: '765px',
          maxWidth: '765px',
          maxHeight: 'calc(100% - 60px)',
          minHeight: 'calc(100% - 60px)',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '30px 36px',
        textAlign: 'center',
        position: 'relative',
        color: '#63666A',
        fontSize: '14px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 36px',
      },
    },
    MuiDialogActions: {
      root: {
        margin: '0',
        padding: '30px 36px',
        border: '1px #E7E7E7 solid',
      },
    },
    MuiButton: {
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: '.4s ease',
        borderRadius: '3px',
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        padding: '14px 35px',
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          color: '#63666A',
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          boxShadow: 'none',
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textSecondary: {
        background: 'none',
        color: '#63666A',
        transition: '.4s ease',
        borderRadius: '3px',
        border: '1px solid #868686',
        padding: '14px 35px',
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          color: '#868686',
          backgroundColor: 'transparent',
        },
      },
      root: {
        fontSize: '16px',
        textTransform: 'none',
        borderRadius: '3px',
        height: 'auto',
        minWidth: '200px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: '600',
      },
      text: {
        padding: '14px 20px',
      },
      label: {
        lineHeight: '19px',
      },
      disabled: {},
    },
  },
});

export const AddLetterFormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const RadioInputsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  .inputClass {
    height: auto;
    font-size: 18px;
    font-weight: 600;
    background: none;
    border: 0;
    margin-top: 12px;
    color: #2F3D4A;
  }
`;

export const ImportDialogMinInner = styled.div`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  padding: 3px 3px 3px 21px;
  display: flex;
  align-items: center;
  border: 1px solid #f2f2f2;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  span.exportSpan {
    color: #ded7d7;
  }
  &:nth-of-type(1) {
    border-bottom: 1px solid #f2f2f2;
  }
`;
