import styled from 'styled-components';

export const AddTrustItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;
export const AddTrustItemLastName = styled.div`
  position: relative;
  flex-basis: 100%;
`;
export const AddTrustItemName = styled.div`
  position: relative;
  flex-basis: calc(50% - 10px);
`;
export const AddTrustItemEmail = styled.div`
  position: relative;
  flex-basis: calc(50% - 10px);
`;
export const AddTrustItemPhone = styled.div`
  position: relative;
  flex-basis: calc(50% - 10px);
`;
export const AddTrustItemErrorContent = styled.span`
  display: block;
  font-size: 13px;    
  line-height: 16px;
  color: #E32A38;
  min-height: 16px;
  margin-bottom: 2px;
`;