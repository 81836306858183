import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';
import {
  Button,
  FormControl,
  InputAdornment,
  MuiThemeProvider,
  Paper,
  Popper,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';
// import { MoreHoriz } from '@material-ui/icons';
import {
  FieldIconHolder,
  MoreIconAuto,
  TextTooltip,
  TextTooltipUpper,
} from '../../common/styles/Common.styled';
import {
  IconDropDown,
  IconClose,
} from '../../common/icons/customIcons';
// import { validateText } from '../../pages/ducks/utils.actions';

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => { }, ref, valid, isHiddenIconArrow, ...other } = inputProps;

  return (
    <FormControl fullWidth={true}>
      <TextField
        InputProps={{
          inputRef: (node) => {
            ref(node);
            inputRef(node);
          },
          disableUnderline: true,
          classes: {
            input: classes.input,
          },
          endAdornment: (
            <InputAdornment position="start">
              <IconDropDown style={isHiddenIconArrow
                ? { display: "none" }
                : { display: "block" }} />
            </InputAdornment>
          ),
        }}
        inputProps={{ valid: _.toString(valid) }}
        {...other}
      />
    </FormControl>
  );
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
});

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popper: _.trimStart(this.props.initValue) || '',
      dirty: false,
      alwaysRenderSuggestions: false,
      focus: false,
      open: false,
      isHiddenText: false,
      bottom: true,
      labelError: '',
    };
    this.fetchRequestedDebounce = _.debounce(
      this.props.handleSuggestionsFetchRequested,
      500,
    );
    this.validateDebounce = _.debounce(this.validateString, 1000);
    this.uidItem = this.props.attrUid
      ? `autocomplete_${this.props.attrUid}`
      : _.uniqueId('autocomplete_uid_');
  }

  componentDidMount() {
    this.handleHiddenText(this.state.popper, {
      alwaysRenderSuggestions: false,
    });
  }

  componentWillUnmount() {
    this._cancelFunctions();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!_.isEqual(this.state, nextState)];
    const nameProps = [
      'suggestions',
      'initValue',
      'needClean',
      'disabled',
      'required',
      'forceSetValue',
      'error',
      'locale',
      'valid',
    ];
    _.forEach(nameProps, (i) =>
      equal.push(!_.isEqual(this.props[i], nextProps[i])),
    );
    return _.some(equal);
  }

  componentDidUpdate(prevProps) {
    const initValue = _.trimStart(this.props.initValue);
    let state = { popper: initValue || '' };
    if (_.isEmpty(state.popper)) {
      state = { ...state, labelError: '' };
    }

    if (!prevProps.needClean && this.props.needClean) {
      this.setState({ ...state, isHiddenText: false });
    } else {
      if (!prevProps.forceSetValue && this.props.forceSetValue) {
        if (!_.isEqual(prevProps.initValue, this.props.initValue)) {
          this.handleHiddenText(initValue, state);
        }
      }
    }
  }

  _cancelFunctions = () => {
    this.isSuggestionValue = false;
    this.fetchRequestedDebounce.cancel();
    this.validateDebounce.cancel();
    this.onValidate();
  };

  onValidate = (value = '') => {
    if (_.isFunction(this.props.onValidate)) {
      this.props.onValidate(this.uidItem, value);
    }
  };

  handleHiddenText = (newValue, addState = {}) => {
    let isHiddenText = false;
    const elemDiv = document.getElementById(`div-autocomplete-${this.uidItem}`);
    if (!_.isNil(elemDiv)) {
      elemDiv.innerText = newValue;
      const widthCss = parseInt(
        getComputedStyle(this.popperNode).getPropertyValue('width'),
      );
      const popperNodeWidth = !!widthCss
        ? widthCss
        : this.popperNode.clientWidth - 160;

      isHiddenText = !!newValue ? elemDiv.clientWidth > popperNodeWidth : false;
    }
    this.setState({ isHiddenText, ...addState });
  };

  handleChange = (event, { newValue, method }) => {
    /**
     * массив из полей, который должны себя вести как number.
     * @param 15796 - "Концентрация"
     * @param 15448 - "Нетто"
     * @param 15811 - "Мера нетто" для 205 группы(Вложенные незарегистрированные товары)
     * @param 15815 - "Мера нетто" для 206 группы(Вложенные товары без кода)
     *
     * возможно вынести список куда-нибудь в utils
     */
    // const fieldsToNumberLike = ['15796', '15448', '15811', '15815'];
    /**
     * EACNC-69
     * нужен список всех id у полей, чтобы прокручивать их через эту проверку.
     * констой вынести список этих id, у this.props.name отрезать часть из '_0', и проверять вхождение
     * в этот массив. Если true и method === 'type', то режем value с помощью регулярки.
     */
    // if (_.includes(fieldsToNumberLike, _.trimEnd(this.props.name, '_0')) && method === 'type') {
    //   newValue = newValue.replace(/[^[0-9.]/, '');
    //}
    if (this.props.type === 'number' && !_.toNumber(newValue)) {
      return '';
    }

    if (this.props.name === 'tnved' && method === 'type') {
      newValue = newValue.substring(0, 4);
    }

    let value = _.trimStart(newValue);

    this.handleHiddenText(value, { popper: value });
    if (this.props.isValidateText) {
      if (method === 'click') {
        this.handleValidateError();
      } else {
        this.validateDebounce(value);
      }
    }
    if (!_.isUndefined(this.props.handleChange)) {
      this.props.handleChange(value);
    }
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.fetchRequestedDebounce(_.trimStart(value));
  };

  handleFocus = () => {
    this.fetchRequestedDebounce('');
    if (!this.state.dirty) {
      this.setState({
        alwaysRenderSuggestions: true,
        dirty: true,
        focus: true,
      });
    } else {
      if (!this.state.alwaysRenderSuggestions)
        this.setState({ alwaysRenderSuggestions: true, focus: true });
    }
    if (!_.isUndefined(this.props.handleFocus)) {
      this.props.handleFocus();
    }
  };

  handleBlur = (event, { highlightedSuggestion }) => {
    const value = this.state.popper;
    if (this.state.alwaysRenderSuggestions) {
      this.setState({ alwaysRenderSuggestions: false, focus: false });
    }

    if (this.props.onlyFromList && !this.isSuggestionValue) {
      const findItem = _.find(
        this.props.suggestions,
        (i) => _.toLower(i.text) === _.toLower(value),
      );
      if (value !== '' && !findItem) {
        return this.clearField();
      }
    }

    this.isSuggestionValue = false;
    this.validateString(value);
    if (
      !_.isUndefined(this.props.handleBlur) &&
      _.isNull(highlightedSuggestion)
    ) {
      this.props.handleBlur(value);
    }
  };

  handleValidateError = (errorText = '') => {
    if (this.state.labelError || errorText) {
      this.setState({ labelError: errorText });
      this.onValidate(errorText);
    }
  };

  validateString = (value) => {
    if (!this.props.isValidateText) return;
    if (this.oldValidateValue === value) return;
    if (_.isEmpty(value) || !_.isNaN(_.toNumber(value))) {
      return this.handleValidateError();
    }

    this.oldValidateValue = value;
    // this.props.validateText(value, ({ successed, error }) => {
    //   if (successed && error) {
    //     this.handleValidateError(error);
    //   } else {
    //     this.handleValidateError();
    //   }
    // });
  };

  getSuggestionValue = (suggestion) => {
    this.isSuggestionValue = true;
    return this.props.getSuggestionValue(suggestion);
  };

  handleHiddenBlur = () => {
    this.setState({ open: false });
  };

  handleHiddenFocus = () => {
    this.setState({ open: true });
  };

  handleOpen = (event) => {
    const element = document.getElementById(`tooltip-${this.uidItem}`);
    if (!_.isNil(element)) {
      const bottom = element.getBoundingClientRect().y > event.clientY;
      if (this.state.bottom !== bottom) {
        this.setState({ bottom });
      }
    }
  };

  clearField = () => {
    this._cancelFunctions();
    _.result(
      this.props,
      'handleClearField',
      this.props.handleSuggestionsClearRequested,
    );
    this.setState({ popper: '', labelError: '', isHiddenText: false });
  };

  render() {
    const { classes, labelError, label } = this.props;
    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.props.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested:
        (this.state.popper && this.props.name === 'regionCode')
          ? () => { }
          : this.props.handleSuggestionsClearRequested,
      renderSuggestion: this.props.renderSuggestion,
      getSuggestionValue: this.getSuggestionValue,
      alwaysRenderSuggestions: this.state.alwaysRenderSuggestions,
      focusInputOnSuggestionClick: false,
    };

    const emptyLabel = this.props.emptyLabel ? '' : this.props.placeholder;
    const classicLabel = (this.state.popper || this.state.focus) && emptyLabel;

    const isError = this.props.error || !!this.state.labelError;

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            focus: this.state.focus,
            classes,
            disabled: this.props.disabled,
            required: this.props.required,
            placeholder: this.state.focus ? '' : this.props.placeholder,
            value: this.state.popper,
            onChange: this.handleChange,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            inputRef: (node) => {
              this.popperNode = node;
            },
            // label: labelError || this.state.labelError || classicLabel || label,
            label: label || classicLabel,
            InputLabelProps: label ? {} : { shrink: true },
            type: this.props.type,
            error: isError,
            valid: this.props.required && !!this.props.valid && !isError,
            autoComplete: 'off',
            isHiddenIconArrow: this.props.isHiddenIconArrow,
          }}
          theme={{
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={(options) => (
            <Popper
              placement={'bottom-start'}
              style={{ zIndex: '9999' }}
              anchorEl={this.popperNode}
              open={Boolean(options.children)}
              popperOptions={{}}>
              <Paper
                square
                {...options.containerProps}
                style={{
                  width: this.popperNode ? this.popperNode.clientWidth : null,
                }}>
                {options.children}
              </Paper>
            </Popper>
          )}
        />

        <FieldIconHolder>
          <MuiThemeProvider
            theme={this.state.bottom ? TextTooltip : TextTooltipUpper}>
            <Tooltip
              id={`tooltip-${this.uidItem}`}
              placement="bottom-end"
              open={this.state.open}
              title={this.state.popper}
              onOpen={this.handleOpen}>
              <MoreIconAuto>
                {this.state.isHiddenText && (
                  // <MoreHoriz
                  //   style={{ cursor: 'pointer' }}
                  //   tabIndex="1"
                  //   onMouseLeave={this.handleHiddenBlur}
                  //   onMouseEnter={this.handleHiddenFocus}
                  // />
                  <span
                    style={{ display: 'block', width: '24px', height: '28px', cursor: 'pointer' }}
                    tabIndex="1"
                    onMouseLeave={this.handleHiddenBlur}
                    onMouseEnter={this.handleHiddenFocus}
                  />
                )}
              </MoreIconAuto>
            </Tooltip>
          </MuiThemeProvider>
          {!this.props.disabled && !!this.state.popper && (
            <Button onClick={this.clearField}>
              <IconClose style={{ width: 20, height: 20 }} />
            </Button>
          )}
          {this.props.children}
        </FieldIconHolder>

        <div
          id={`div-autocomplete-${this.uidItem}`}
          style={{
            width: 'auto',
            display: 'inline-block',
            visibility: 'hidden',
            position: 'fixed',
            overflow: 'auto',
            fontWeight: 600,
            fontSize: '16px',
          }}
        />
      </div>
    );
  }
}

Autocomplete.propTypes = {
  handleSuggestionsFetchRequested: PropTypes.func.isRequired,
  handleSuggestionsClearRequested: PropTypes.func.isRequired,
  validateText: PropTypes.func.isRequired,
  isValidateText: PropTypes.bool.isRequired,
  onValidate: PropTypes.func,
  handleClearField: PropTypes.func,
  getSuggestionValue: PropTypes.func,
  renderSuggestion: PropTypes.func,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  handleChange: PropTypes.func,
  children: PropTypes.element,
  attrUid: PropTypes.string,
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  valid: PropTypes.bool,
  suggestions: PropTypes.array,
  initValue: PropTypes.string,
  onlyFromList: PropTypes.bool,
  needClean: PropTypes.bool,
  disabled: PropTypes.bool,
  forceSetValue: PropTypes.bool,
  emptyLabel: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  label: PropTypes.string,
};

Autocomplete.defaultProps = {
  onlyFromList: false,
  needClean: false,
  required: false,
  disabled: false,
  forceSetValue: false,
  emptyLabel: false,
  type: 'text',
  error: false,
  isValidateText: false,
};

const mapStateToProps = (state) => ({
  locale: state.profileReducer.locale,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
    },
    dispatch,
  );
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Autocomplete);
