import React from 'react';
import { HeaderLogoUZ } from './customIcons';
// import colors from "../styles/Common.colors";

export const SvgLogo = props => (
    // <svg version="1.1" width="308" height="100" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    //     viewBox="0 0 878 287" style={{ enableBackground: 'new 0 0 878 287' }}>
    //     <g>
    //         <g>
    //             <path
    //                 fill="#ffffff"
    //                 d="M245.6,143.8c0,21.8,0.1,43.7,0,65.5c0,8.9-4.1,15.7-11.3,20.9c-4,2.9-8.2,4-13.2,4
    //                 c-41.8-0.1-83.7-0.1-125.5,0c-6.9,0-12-2.7-16.8-7.2c-5.2-5-6.9-11.1-6.7-18c0.1-2.9,0-5.8,0-8.7c0-2.3-1.1-3.3-3.4-3.3
    //                 c-3.3,0-6.7,0-10,0c-2,0-2.9-0.8-2.9-2.8c0-4.6,0.1-9.2,0-13.7c0-2.1,1-2.8,2.9-2.7c3.4,0.1,6.8,0,10.2,0c2.3,0,3.2-0.9,3.2-3.3
    //                 c-0.1-5.5-0.1-11,0-16.5c0.1-2.5-1.1-3.5-3.4-3.5c-3.1,0-6.2,0-9.2-0.1c-2.9-0.1-3.6-0.7-3.6-3.5c-0.1-4.6,0-9.2,0-13.7
    //                 c0-2.1,0.8-3,3-3c3.2,0.1,6.5,0.1,9.7,0c3.1,0,3.5-0.5,3.5-3.5c0-5.4-0.1-10.8,0-16.2c0.1-2.3-1-3.2-3.1-3.2
    //                 c-3.4,0-6.8-0.1-10.2,0c-2.2,0.1-3-0.9-3-3c0-4.6,0-9.2,0-13.7c0-2.3,0.9-3.6,3.4-3.4c3.1,0.2,6.2,0.1,9.2,0
    //                 c2.8-0.1,3.5-0.9,3.6-3.6c0.1-4.3,0.2-8.7,0.4-13c0.4-7.9,4.7-13.3,11.2-17.4c5.1-3.1,10.5-4.2,16.4-4.2
    //                 c39.8,0.1,79.7,0.1,119.5,0.1c7.9,0,14.3,3.1,19.5,9c4.4,5,6.6,10.7,6.5,17.4C245.6,100.8,245.6,122.3,245.6,143.8z M226.2,144.1
    //                 c0-20.9-0.1-41.8,0.1-62.7c0-6-3.5-9.4-9.5-9.4c-38.6,0.1-77.1,0-115.7,0c-0.9,0-1.8,0-2.7,0c-4,0.3-6.7,3.2-6.7,7.2c0,3,0,6,0,9
    //                 c0,1.6,0.5,2.5,2.3,3c4.1,1.2,7.9,2.5,8.8,7.8c0.6,3.4,0,6.2-2.2,8.3c-1.9,1.8-4.6,2.5-6.9,3.9c-0.8,0.5-1.8,1.4-1.8,2.1
    //                 c-0.1,6.2-0.1,12.5,0,18.7c0,0.7,0.9,1.9,1.6,2c4.6,1,8.1,3,9.2,8.1c0.8,3.5,0.1,6.5-2.2,8.8c-1.5,1.5-3.9,2.5-6,3
    //                 c-2.1,0.5-2.8,1.4-2.8,3.4c0,5.9,0.1,11.8,0,17.7c0,1.9,0.7,2.7,2.6,2.7c4.7-0.1,8.8,5.6,8.8,9.8c0.1,5.4-3.2,8.9-8.7,9.3
    //                 c-2.1,0.2-2.8,1-2.8,2.9c0,3,0,6,0,9c0,4.5,1.8,6.4,6.3,6.4c40.5,0,81,0,121.5,0.1c2.5,0,4.2-1.1,5.3-3c0.8-1.6,1.5-3.6,1.5-5.3
    //                 C226.2,185.9,226.2,165,226.2,144.1z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M153.1,156.7c8.6-8.7,17-17.2,25.4-25.7c6.4-6.4,12.8-12.8,19.2-19.2c2.5-2.5,3.1-2.5,5.6,0
    //                 c2.7,2.6,5.3,5.3,8,7.9c1.9,1.9,2.1,3.2,0.1,5.1c-6.4,6.5-12.8,12.8-19.2,19.3c-12.1,12.1-24.3,24.2-36.4,36.4
    //                 c-2.9,2.9-3.6,2.9-6.5,0c-11.4-11.4-22.8-22.7-34.2-34c-1.8-1.8-1.9-3-0.1-4.8c2.9-3,5.9-5.9,8.8-8.8c1.9-1.9,3-1.8,5,0.2
    //                 c7,7,13.9,14,20.9,20.9C150.6,154.7,151.7,155.5,153.1,156.7z"
    //             />
    //         </g>
    //         <g>
    //             <path
    //                 fill="#ffffff"
    //                 d="M293.5,107.2l3.4-33.1c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.3h8.2c0.6,0,0.9,0.3,1.1,0.9l3.2,10.5
    //                 l3.2-10.4c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.1,0.5-0.2,0.7-0.2h8.1c0.3,0,0.5,0.1,0.8,0.3c0.2,0.2,0.4,0.4,0.4,0.7
    //                 c0.6,5.6,1.1,11.1,1.7,16.6c0.6,5.5,1.1,11,1.7,16.6v0.1c0,0.6-0.3,0.9-1,0.9h-6.7c-0.7,0-1.1-0.3-1.1-1l-2.1-18.3l-2,7.4
    //                 c0,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.3h-6.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.6l-2.1-7.3l-2.1,18.3
    //                 c0,0.7-0.4,1-1.2,1h-6.7C293.8,108.2,293.5,107.9,293.5,107.2z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M333.5,107.2V74c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.8-0.3h7c0.2,0,0.5,0.1,0.8,0.3
    //                 c0.3,0.2,0.4,0.4,0.4,0.8v33.1c0,0.7-0.4,1-1.2,1h-7C333.8,108.2,333.5,107.9,333.5,107.2z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M350.2,107.2V74c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.3h7c0.3,0,0.5,0.1,0.7,0.3
    //                 c0.2,0.2,0.3,0.4,0.3,0.8v25.8H371c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.3-0.1,0.5-0.3,0.7
    //                 c-0.2,0.2-0.4,0.3-0.8,0.3h-19.7C350.6,108.2,350.2,107.9,350.2,107.2z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M377.9,107.2V74c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.3h7c0.3,0,0.5,0.1,0.7,0.3
    //                 c0.2,0.2,0.3,0.4,0.3,0.8v25.8h11.6c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.3-0.1,0.5-0.3,0.7s-0.4,0.3-0.8,0.3
    //                 H379C378.3,108.2,377.9,107.9,377.9,107.2z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M405.5,107.2V74c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.8-0.3h7c0.2,0,0.5,0.1,0.8,0.3
    //                 c0.3,0.2,0.4,0.4,0.4,0.8v33.1c0,0.7-0.4,1-1.2,1h-7C405.9,108.2,405.5,107.9,405.5,107.2z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M429.4,73.7l5.8,12.3l5.7-12.3c0.1-0.2,0.2-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1-0.1,0.2-0.1
    //                 c0.1,0,0.2,0,0.3,0h9.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-11.2,19.9
    //                 v13.3c0,0.7-0.4,1.1-1.1,1.1h-7.1c-0.3,0-0.6-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.8V93.8l-11.2-19.9c-0.1-0.1-0.2-0.2-0.2-0.3
    //                 c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.2s0.1-0.1,0.3-0.1h9.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.2
    //                 S429.3,73.5,429.4,73.7z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M468.1,107.2l3.4-33.1c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.3h8.2c0.6,0,0.9,0.3,1.1,0.9l3.2,10.5
    //                 l3.2-10.4c0.1-0.3,0.3-0.5,0.5-0.7s0.5-0.2,0.7-0.2h8.1c0.3,0,0.5,0.1,0.8,0.3c0.2,0.2,0.4,0.4,0.4,0.7c0.6,5.6,1.1,11.1,1.7,16.6
    //                 s1.1,11,1.7,16.6v0.1c0,0.6-0.3,0.9-1,0.9h-6.7c-0.7,0-1.1-0.3-1.1-1l-2.1-18.3l-2,7.4c0,0.2-0.2,0.4-0.4,0.6
    //                 c-0.2,0.2-0.4,0.3-0.6,0.3h-6.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.6l-2.1-7.3l-2.1,18.3c0,0.7-0.4,1-1.2,1h-6.7
    //                 C468.5,108.2,468.1,107.9,468.1,107.2z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M530.4,107.3l-0.7-1.9h-14.9l-0.7,1.9c-0.2,0.6-0.6,0.9-1.2,0.9h-7c-0.3,0-0.5-0.1-0.7-0.2
    //                 c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.1,0-0.2,0.1-0.3L518,73.9c0.1-0.2,0.2-0.4,0.5-0.6c0.3-0.2,0.5-0.3,0.8-0.3h6.1
    //                 c0.3,0,0.5,0.1,0.7,0.2c0.2,0.2,0.3,0.3,0.4,0.5l13.1,33.5c0,0.1,0.1,0.3,0.1,0.4c0,0.5-0.3,0.7-1,0.7h-6.8
    //                 c-0.4,0-0.6-0.1-0.8-0.2C530.7,107.9,530.5,107.6,530.4,107.3z M522.3,84l-5,14.3h9.9L522.3,84z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M544.8,107.3V74c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3h7c0.2,0,0.5,0.1,0.7,0.3
    //                 c0.3,0.2,0.4,0.4,0.4,0.7v11.2h11.8V74c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.3h7c0.3,0,0.6,0.1,0.8,0.2s0.4,0.4,0.4,0.8
    //                 v33.2c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3h-7c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.4-0.3-0.8V93.7h-11.8v13.5
    //                 c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.9,0.3h-7C545.1,108.2,544.8,107.9,544.8,107.3z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M580.7,100.1c0.9-0.1,1.6-0.2,2.2-0.4c0.6-0.2,1.1-0.4,1.5-0.7c0.4-0.3,0.7-0.6,0.8-1
    //                 c0.2-0.4,0.3-0.9,0.3-1.6c0-0.4-0.1-0.9-0.4-1.5s-0.5-1.2-0.9-1.9c-0.4-0.7-0.8-1.4-1.2-2.2s-0.8-1.6-1.2-2.4
    //                 c-0.4-0.8-0.7-1.7-0.9-2.6s-0.4-1.8-0.4-2.7c0-1.5,0.3-3,0.9-4.4s1.6-2.6,2.8-3.6c1.2-1,2.8-1.8,4.6-2.4c1.8-0.6,4-0.9,6.4-0.9
    //                 h0.1c0.2,0,0.4,0.1,0.5,0.3c0.1,0.1,0.2,0.4,0.2,0.6v7.3c0,0.2-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2c-0.9,0.1-1.6,0.2-2.2,0.4
    //                 c-0.6,0.2-1.1,0.4-1.5,0.7c-0.4,0.3-0.7,0.6-0.8,1s-0.3,0.9-0.3,1.6c0,0.4,0.1,0.9,0.4,1.5c0.2,0.6,0.5,1.2,0.9,1.9
    //                 c0.4,0.7,0.8,1.4,1.2,2.2c0.4,0.8,0.8,1.6,1.2,2.4c0.4,0.8,0.7,1.7,0.9,2.6c0.2,0.9,0.4,1.8,0.4,2.7c0,1.5-0.3,3-0.9,4.4
    //                 c-0.6,1.4-1.6,2.6-2.8,3.6c-1.2,1-2.8,1.8-4.6,2.4s-4,0.9-6.4,0.9h-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.2-0.4-0.2-0.6v-7.3
    //                 c0-0.2,0.1-0.4,0.2-0.6S580.5,100.1,580.7,100.1z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M610.9,74v20.1c0,1.2,0.2,2.1,0.5,3s0.8,1.5,1.3,2s1.1,0.9,1.9,1.1s1.4,0.4,2.2,0.4c0.8,0,1.5-0.1,2.2-0.4
    //                 c0.7-0.3,1.3-0.7,1.9-1.2s0.9-1.2,1.3-2s0.5-1.7,0.5-2.8V74c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.3h6.9
    //                 c0.4,0,0.6,0.1,0.9,0.3c0.2,0.2,0.3,0.5,0.3,0.7v20c0,2.8-0.4,5.1-1.2,7c-0.8,1.9-1.9,3.4-3.3,4.5c-1.4,1.2-3,2-4.8,2.5
    //                 c-1.8,0.5-3.7,0.7-5.7,0.7s-3.9-0.3-5.7-0.8s-3.4-1.4-4.8-2.5c-1.4-1.2-2.5-2.7-3.3-4.6c-0.8-1.9-1.2-4.2-1.2-6.9V74
    //                 c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3h7.1c0.2,0,0.5,0.1,0.7,0.3C610.7,73.5,610.9,73.7,610.9,74z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M638.4,107.2V74c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.3h7c0.3,0,0.5,0.1,0.7,0.3
    //                 c0.2,0.2,0.3,0.4,0.3,0.8v25.8h11.6c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.3-0.1,0.5-0.3,0.7
    //                 c-0.2,0.2-0.4,0.3-0.8,0.3h-19.7C638.8,108.2,638.4,107.9,638.4,107.2z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M662.6,90.6c0-1.7,0.2-3.3,0.6-4.8c0.4-1.5,1-3,1.8-4.3s1.7-2.6,2.8-3.7s2.3-2.1,3.7-2.8
    //                 c1.3-0.8,2.8-1.4,4.3-1.8c1.5-0.4,3.2-0.7,4.8-0.7s3.3,0.2,4.8,0.7c1.5,0.4,3,1,4.3,1.8c1.3,0.8,2.6,1.7,3.7,2.8s2.1,2.3,2.8,3.7
    //                 c0.8,1.4,1.4,2.8,1.8,4.3c0.4,1.5,0.7,3.2,0.7,4.8c0,1.7-0.2,3.3-0.7,4.8c-0.4,1.6-1,3-1.8,4.3c-0.8,1.3-1.7,2.6-2.8,3.7
    //                 s-2.3,2-3.7,2.8c-1.3,0.8-2.8,1.4-4.3,1.8c-1.5,0.4-3.2,0.6-4.8,0.6c-1.7,0-3.3-0.2-4.8-0.6c-1.5-0.4-3-1-4.3-1.8
    //                 s-2.6-1.7-3.7-2.8s-2-2.3-2.8-3.7c-0.8-1.3-1.4-2.8-1.8-4.3C662.9,93.9,662.6,92.3,662.6,90.6z M671.8,90.6c0,1.3,0.2,2.5,0.7,3.7
    //                 c0.4,1.1,1.1,2.1,1.9,3c0.8,0.8,1.7,1.5,2.8,2c1.1,0.5,2.3,0.7,3.6,0.7c1.3,0,2.5-0.2,3.6-0.7s2-1.1,2.8-2c0.8-0.8,1.4-1.8,1.9-3
    //                 c0.4-1.1,0.7-2.4,0.7-3.7c0-1.3-0.2-2.5-0.7-3.7c-0.4-1.1-1.1-2.1-1.9-3c-0.8-0.9-1.7-1.5-2.8-2s-2.3-0.7-3.6-0.7
    //                 c-1.3,0-2.5,0.2-3.6,0.7c-1.1,0.5-2,1.2-2.8,2c-0.8,0.9-1.4,1.9-1.9,3C672,88.1,671.8,89.3,671.8,90.6z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M699.9,79.9V74c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3h25.6c0.3,0,0.5,0.1,0.8,0.3
    //                 c0.2,0.2,0.3,0.4,0.4,0.8v5.9c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.3-0.5,0.4-0.8,0.4h-8.2v26c0,0.3-0.1,0.5-0.3,0.8
    //                 c-0.2,0.2-0.5,0.3-0.8,0.3h-7c-0.3,0-0.5-0.1-0.8-0.3s-0.4-0.5-0.4-0.8v-26h-8.2c-0.3,0-0.6-0.1-0.8-0.3
    //                 C700.1,80.6,699.9,80.3,699.9,79.9z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M732.3,107.2V74c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.8-0.3h7c0.3,0,0.5,0.1,0.7,0.3
    //                 c0.2,0.2,0.3,0.4,0.3,0.8v25.8H753c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.3-0.1,0.5-0.3,0.7
    //                 c-0.2,0.2-0.4,0.3-0.8,0.3h-19.7C732.6,108.2,732.3,107.9,732.3,107.2z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M783.1,107.3l-0.7-1.9h-14.9l-0.7,1.9c-0.2,0.6-0.6,0.9-1.2,0.9h-7c-0.3,0-0.5-0.1-0.7-0.2
    //                 c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.1,0-0.2,0.1-0.3l12.9-33.4c0.1-0.2,0.2-0.4,0.5-0.6c0.3-0.2,0.5-0.3,0.8-0.3h6.1
    //                 c0.3,0,0.5,0.1,0.7,0.2c0.2,0.2,0.3,0.3,0.4,0.5l13.1,33.5c0,0.1,0.1,0.3,0.1,0.4c0,0.5-0.3,0.7-1,0.7h-6.8
    //                 c-0.4,0-0.6-0.1-0.8-0.2C783.4,107.9,783.2,107.6,783.1,107.3z M775,84l-5,14.3h9.9L775,84z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M821.7,83.7c0,2.4-0.4,4.6-1.3,6.6c-0.9,2.1-2,3.9-3.5,5.5l4.6,10c0.1,0.2,0.3,0.5,0.4,0.8
    //                 c0.2,0.3,0.3,0.6,0.3,0.8c0,0.3-0.1,0.4-0.2,0.6c-0.2,0.1-0.4,0.2-0.7,0.2h-8.1c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.2-0.4-0.4-0.4-0.6
    //                 l-2.9-6.3c-0.8,0.3-1.7,0.5-2.5,0.7v4.3c0,0.5,0,0.9,0,1.2c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.2-0.5,0.3
    //                 c-0.2,0-0.5,0.1-0.8,0.1c-0.1,0-0.3,0-0.5,0s-0.4,0-0.6,0h-4c-0.2,0-0.5,0-0.6,0c-0.2,0-0.4,0-0.5,0c-0.3,0-0.6,0-0.8-0.1
    //                 c-0.2,0-0.3-0.1-0.5-0.3c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.3,0-0.7,0-1.2V75.4c0-0.6,0-1.1,0-1.4c0-0.3,0.1-0.6,0.3-0.7
    //                 c0.1-0.1,0.4-0.2,0.7-0.2c0.3,0,0.8,0,1.5,0h10.5c2.2,0,4.1,0.3,5.6,0.8s2.6,1.3,3.5,2.3c0.9,1,1.5,2.1,1.8,3.4
    //                 S821.7,82.2,821.7,83.7z M806.6,81.3v11.1c0.9-0.3,1.7-0.8,2.4-1.4c0.8-0.6,1.4-1.3,2-2.1c0.6-0.8,1-1.7,1.3-2.7
    //                 c0.3-1,0.5-2,0.5-3c0-0.5-0.2-1-0.6-1.3s-0.8-0.5-1.4-0.5H806.6z"
    //             />
    //         </g>
    //         <g>
    //             <path
    //                 fill="#ffffff"
    //                 d="M357.2,138.9l-20.4,18l22.6,56.4c0.2,0.6,0.3,1,0.2,1.4c-0.1,0.5-0.6,0.8-1.4,0.8h-17.5
    //                 c-0.6,0-1.1-0.2-1.6-0.5c-0.5-0.4-0.8-0.8-1.1-1.4c-0.6-1.3-1.3-3-2.2-5.1c-0.9-2.1-1.9-4.5-3-7c-1.1-2.6-2.2-5.3-3.3-8.1
    //                 c-1.2-2.8-2.3-5.6-3.4-8.3c-1.1-2.7-2.1-5.3-3-7.7c-1-2.4-1.7-4.5-2.4-6.1l-7.1,6.3v35.8c0,1.6-0.8,2.3-2.5,2.3h-15.6
    //                 c-1.6,0-2.4-0.8-2.4-2.3v-74c0-0.6,0.2-1.2,0.6-1.6c0.4-0.5,1-0.7,1.7-0.7H311c0.8,0,1.4,0.2,1.9,0.6s0.7,1,0.7,1.7v14.6
    //                 c2.9-2.5,5.8-5.1,8.7-7.6c2.9-2.5,5.7-5,8.4-7.4c0.6-0.6,1.3-1.1,2-1.4c0.7-0.3,1.4-0.5,2-0.5h22.7c0.8,0,1.2,0.3,1,0.8
    //                 C358.1,138,357.8,138.4,357.2,138.9z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M424,213.5l-1.6-4.3h-33.2l-1.5,4.3c-0.5,1.3-1.4,2-2.7,2h-15.6c-0.6,0-1.1-0.1-1.5-0.3s-0.6-0.6-0.6-1.1
    //                 c0-0.2,0.1-0.4,0.2-0.7l28.8-74.5c0.2-0.4,0.5-0.9,1.1-1.4c0.6-0.5,1.1-0.7,1.7-0.7h13.6c0.6,0,1.1,0.2,1.6,0.5
    //                 c0.5,0.3,0.8,0.7,0.9,1l29.1,74.8c0.1,0.3,0.2,0.6,0.2,0.8c0,1.1-0.8,1.6-2.3,1.6h-15.2c-0.8,0-1.4-0.1-1.9-0.5
    //                 C424.6,214.7,424.3,214.2,424,213.5z M405.9,161.6l-11.2,32h22.2L405.9,161.6z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M438.1,152.3v-13.2c0-0.7,0.2-1.3,0.7-1.7c0.5-0.4,1.1-0.6,1.8-0.6h57.1c0.6,0,1.2,0.2,1.7,0.6
    //                 c0.5,0.4,0.8,1,0.8,1.7v13.2c0,0.6-0.2,1.2-0.7,1.8c-0.4,0.6-1.1,0.9-1.9,0.9h-18.3v58c0,0.6-0.2,1.2-0.7,1.7s-1.1,0.8-1.8,0.8
    //                 h-15.6c-0.6,0-1.2-0.2-1.7-0.7c-0.5-0.5-0.8-1.1-0.8-1.8v-58h-18.2c-0.7,0-1.3-0.2-1.8-0.7C438.3,153.9,438.1,153.2,438.1,152.3z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M552.6,213.5l-1.6-4.3h-33.2l-1.5,4.3c-0.5,1.3-1.4,2-2.7,2h-15.6c-0.6,0-1.1-0.1-1.5-0.3s-0.6-0.6-0.6-1.1
    //                 c0-0.2,0.1-0.4,0.2-0.7l28.8-74.5c0.2-0.4,0.5-0.9,1.1-1.4c0.6-0.5,1.1-0.7,1.7-0.7h13.6c0.6,0,1.1,0.2,1.6,0.5
    //                 c0.5,0.3,0.8,0.7,0.9,1l29.1,74.8c0.1,0.3,0.2,0.6,0.2,0.8c0,1.1-0.8,1.6-2.3,1.6h-15.2c-0.8,0-1.4-0.1-1.9-0.5
    //                 C553.1,214.7,552.8,214.2,552.6,213.5z M534.4,161.6l-11.2,32h22.2L534.4,161.6z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M584.2,213.2v-74.1c0-0.6,0.2-1.2,0.6-1.6c0.4-0.5,1-0.7,1.7-0.7h15.7c0.6,0,1.2,0.2,1.7,0.6
    //                 c0.5,0.4,0.8,1,0.8,1.7v57.6h25.9c0.6,0,1.2,0.2,1.6,0.6s0.7,1,0.7,1.7v13.9c0,0.6-0.2,1.2-0.6,1.7c-0.4,0.5-1,0.8-1.7,0.8h-44.1
    //                 C585,215.5,584.2,214.7,584.2,213.2z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M638.3,176.2c0-3.7,0.5-7.3,1.4-10.8c1-3.4,2.3-6.7,4.1-9.7c1.8-3,3.9-5.8,6.3-8.2c2.5-2.5,5.2-4.6,8.2-6.3
    //                 c3-1.7,6.2-3.1,9.7-4.1c3.4-1,7-1.5,10.8-1.5c3.7,0,7.3,0.5,10.8,1.5c3.4,1,6.7,2.3,9.7,4.1c3,1.7,5.7,3.9,8.2,6.3
    //                 s4.6,5.2,6.3,8.2c1.7,3,3.1,6.3,4.1,9.7c1,3.4,1.5,7,1.5,10.8c0,3.7-0.5,7.3-1.5,10.8c-1,3.5-2.3,6.7-4.1,9.7
    //                 c-1.7,3-3.9,5.7-6.3,8.2c-2.5,2.5-5.2,4.6-8.2,6.3s-6.2,3.1-9.7,4.1c-3.5,1-7,1.4-10.8,1.4c-3.7,0-7.3-0.5-10.8-1.4
    //                 s-6.7-2.3-9.7-4.1c-3-1.8-5.7-3.9-8.2-6.3c-2.5-2.5-4.6-5.2-6.3-8.2c-1.8-3-3.1-6.2-4.1-9.6C638.8,183.6,638.3,180,638.3,176.2z
    //                 M658.7,176.2c0,2.9,0.5,5.7,1.5,8.2c1,2.5,2.4,4.8,4.2,6.6c1.8,1.9,3.9,3.4,6.4,4.5s5.1,1.6,8,1.6c2.9,0,5.6-0.5,8.1-1.6
    //                 s4.6-2.6,6.4-4.5s3.2-4.1,4.2-6.6c1-2.5,1.5-5.3,1.5-8.2s-0.5-5.7-1.5-8.2c-1-2.5-2.4-4.8-4.2-6.7c-1.8-1.9-3.9-3.4-6.4-4.5
    //                 s-5.1-1.6-8.1-1.6c-2.9,0-5.6,0.5-8,1.6s-4.6,2.6-6.4,4.5c-1.8,1.9-3.2,4.1-4.2,6.7C659.2,170.5,658.7,173.2,658.7,176.2z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M781.5,215.5c-5,0-9.8-0.6-14.4-1.9c-4.6-1.2-8.8-3-12.7-5.2c-3.9-2.2-7.5-4.9-10.6-8.1s-5.9-6.6-8.1-10.4
    //                 c-2.2-3.8-3.9-7.9-5.2-12.3s-1.8-8.9-1.8-13.6c0-3,0.5-5.8,1.4-8.4c0.9-2.6,2.2-4.9,3.7-7c1.6-2.1,3.4-3.9,5.5-5.5
    //                 c2.1-1.6,4.3-2.9,6.6-4c2.3-1.1,4.7-1.9,7.2-2.5c2.4-0.6,4.8-0.8,7.1-0.8s4.6,0.1,6.9,0.4c2.3,0.3,4.6,0.7,6.8,1.3
    //                 c2.2,0.6,4.3,1.3,6.4,2.3c2,0.9,4,2,5.8,3.3c0.3,0.2,0.5,0.5,0.7,0.8c0.2,0.4,0.3,0.7,0.3,0.9l-0.2,0.5l-9.1,15.1
    //                 c-0.3,0.4-0.6,0.6-0.8,0.6c-0.2,0-0.6-0.1-1-0.4c-1-0.6-2-1.3-3.2-1.9c-1.1-0.6-2.3-1.2-3.4-1.6c-1.2-0.5-2.3-0.8-3.5-1.1
    //                 c-1.2-0.3-2.3-0.4-3.4-0.4c-1.7,0-3.2,0.2-4.7,0.6c-1.5,0.4-2.8,1.1-4,2c-1.1,0.9-2.1,2.2-2.7,3.7c-0.7,1.5-1,3.4-1,5.6
    //                 c0,2.5,0.4,4.9,1.2,7.2c0.8,2.3,2,4.4,3.4,6.4c1.5,2,3.2,3.8,5.1,5.5c1.9,1.7,4,3.1,6.3,4.4v-14.4c0-0.6,0.2-1.2,0.6-1.6
    //                 c0.4-0.5,1-0.7,1.7-0.7h15.6c0.5,0,1.1,0.2,1.7,0.6c0.6,0.4,0.9,1,0.9,1.7v36.6c0,1.6-0.9,2.3-2.6,2.3H781.5z"
    //             />
    //             <path
    //                 fill="#ffffff"
    //                 d="M801.4,213.2v-74c0-0.6,0.2-1.2,0.6-1.6c0.4-0.5,1-0.7,1.7-0.7h15.6c0.5,0,1.1,0.2,1.7,0.6
    //                 c0.6,0.4,0.9,1,0.9,1.7v74c0,1.6-0.9,2.3-2.6,2.3h-15.6C802.2,215.5,801.4,214.7,801.4,213.2z"
    //             />
    //         </g>
    //     </g>
    // </svg>
    <HeaderLogoUZ
        width={252}
        height={55}
    />
);

export default SvgLogo;
