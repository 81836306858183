import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
// import _ from 'lodash';
import { MenuItem, MuiThemeProvider, Select } from '@material-ui/core';
import { setLocale } from '../../pages/profile/ducks/Profile/Profile.actions';
import {
  IconDropDown,
  IconFlagRU,
  IconFlagUZ,
  IconFlagUS,
} from '../../common/icons/customIcons';
import {
  HomeLangSelect,
  HomeLangSelectTheme,
  LangMenuIconHome,
} from '../../common/styles/Common.styled';

class HeaderLanguageSelection extends React.Component {
  componentDidMount() {
    const savedLang = localStorage.getItem('savedLanguage');

    if (savedLang) {
      this.props.setLocale(savedLang);
    }
  }

  onLangChange = (e) => {
    localStorage.setItem('savedLanguage', e.target.value);
    this.props.setLocale(e.target.value);
  };

  a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  render() {
    const { locale, t } = this.props;
    // const fnUp = _.flow([t, _.upperCase]);

    return (
      <HomeLangSelect>
        <MuiThemeProvider theme={HomeLangSelectTheme}>
          <Select
            value={locale}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: { vertical: 'top', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'left' },
            }}
            onChange={this.onLangChange}
            IconComponent={IconDropDown}
          >
            <MenuItem value="ru">
              <IconFlagRU />
              <LangMenuIconHome>
              </LangMenuIconHome>
              {/* {fnUp('Русский')} */}
              {t('Русский')}
            </MenuItem>
            <MenuItem value="uz">
              <IconFlagUZ />
              <LangMenuIconHome>
              </LangMenuIconHome>
              {t('Узбекский')}
            </MenuItem>
            <MenuItem value="en">
              <IconFlagUS />
              <LangMenuIconHome>
              </LangMenuIconHome>
              {t('Английский')}
            </MenuItem>
          </Select>
        </MuiThemeProvider>
      </HomeLangSelect>
    );
  }
}

HeaderLanguageSelection.propTypes = {
  setLocale: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  locale: state.profileReducer.locale,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLocale: (locale) => setLocale(locale),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(HeaderLanguageSelection)
