import styled from 'styled-components';
import colors from '../../common/styles/Common.colors';

export const MessageDesktopContainer = styled.div` 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(47, 61, 74, 0.3);  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  overflow-y: auto; 
`;
export const MessageDesktopContent = styled.div` 
  box-sizing: border-box;
  width: 628px;
  display: flex;
  flex-direction: column;        
  background-color: #ffffff;
  border-radius: 8px;
  padding: 32px;  
  color: ${colors.Common.textColor}; 
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    min-height: 320px;
    border-radius: 0;
    justify-content: center;  
    padding: 20px;     
}
@media (max-width: 479px) {
  min-height: 240px;
  svg {
    width: 120px;
    height: 30px;
  }
}
`;
export const MessageDesktopTitle = styled.h2` 
  margin: 22px 0 8px;
  font-size: 21px; 
  line-height: 28px;
  font-weight: 600; 
  @media (max-width: 479px) {
    font-size: 18px; 
    line-height: 24px;
  }
`;
export const MessageDesktopText = styled.p` 
  margin: 0;
  font-size: 16px; 
  line-height: 24px; 
  @media (max-width: 479px) {
    font-size: 14px; 
    line-height: 20px;
  }
`;
