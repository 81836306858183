import * as React from 'react';
import {
  // IconStatusReq,
  IconUserDeleted,
  IconUserBlocked,
  IconUserActive,
  IconCheckCircle,
  IconPencil,
  IconFileText,
  IconTrash,
  IconShield,
  IconCriticalCard,
} from '../icons/customIcons';
import colors from '../../common/styles/Common.colors';

export const Statuses = [
  {
    id: 0,
    title: 'Черновик',
    value: 'draft',
    style: { color: colors.Common.textColor, icon: <IconFileText color={colors.Common.textColor} /> },
  },
  {
    id: 1,
    title: 'На модерации',
    value: 'moderation',
    style: { color: '#FFA340', icon: <IconShield style={{ width: '20px', height: '20px' }} color={'#FFA340'} /> },
  },
  {
    id: 2,
    title: 'Требует изменений',
    value: 'errors',
    style: { color: '#E32A38', icon: <IconCriticalCard width={20} height={20} /> },
  },
  {
    id: 3,
    title: 'Ожидает подписания',
    value: 'notsigned',
    style: { color: '#549FEA', icon: <IconPencil style={{ width: '20px', height: '20px' }} color={'#549FEA'} /> },
  },
  {
    id: 4,
    title: 'Опубликована (с УКЭП)',
    value: 'published',
    style: { color: '#00B634', icon: <IconCheckCircle width={20} height={20} /> },
  },
  {
    id: 5,
    title: 'Опубликована (без УКЭП)',
    value: 'published_notsigned',
    style: { color: '#00B634', icon: <IconCheckCircle width={20} height={20} /> },
  },
  {
    id: 6,
    title: 'В архиве',
    value: 'archive',
    style: { color: '#AAA9A9', icon: <IconTrash style={{ width: '20px', height: '20px' }} color={'#AAA9A9'} /> },
  },
  // {
  //   id: 7,
  //   title: 'Требует обработки',
  //   value: 'require_changes',
  //   style: { color: '#52535A', icon: <IconStatusReq /> },
  // },
];

export const TradeMarksStatuses = [
  {
    id: 0,
    title: 'На проверке',
    value: 'moderation',
    style: { color: '#FFA340', icon: <IconShield color={'#FFA340'} /> },
  },
  {
    id: 1,
    title: 'Подтверждён',
    value: 'published',
    style: { color: '#00B634', icon: <IconUserActive /> },
  },
  {
    id: 2,
    title: 'Отклонён',
    value: 'cancelled',
    style: { color: '#E32A38', icon: <IconUserDeleted color={'#E32A38'} /> },
  },
];

export const UserStatuses = [
  {
    id: 0,
    title: 'Активен',
    value: 'active',
    //style: { color: '#2FA93B', icon: <IconUserActive /> },
    style: { color: '#00B634', icon: <IconUserActive /> },
  },
  {
    id: 1,
    title: 'Заблокирован',
    value: 'blocked',
    //style: { color: '#FF4B4B', icon: <IconUserBlocked /> },
    style: { color: '#E32A38', icon: <IconUserBlocked /> },
  },
  {
    id: 2,
    title: 'Удалён',
    value: 'deleted',
    //style: { color: '#868686', icon: <IconUserDeleted /> },
    style: { color: '#AAA9A9', icon: <IconUserDeleted color={'#AAA9A9'} /> },
  },
];

export const PackingTypes = [
  { id: 0, title: 'Потребительская', value: 'consumer' },
  { id: 1, title: 'Групповая', value: 'group' },
  { id: 2, title: 'Транспортная', value: 'transport' },
  { id: 3, title: 'Техническая карточка', value: 'techCard' },
  { id: 4, title: 'Немаркируемый товар', value: 'unmarkable' },
];
