import { createMuiTheme } from '@material-ui/core';
import colors from '../../common/styles/Common.colors';
import styled from 'styled-components';

export const DefaultConfirmDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '628px',
        maxWidth: '628px',
        minHeight: '270px',
        borderRadius: '8px',
        padding: '32px 0 0',
        boxSizing: 'border-box',
        fontFamily: 'SegoeUI',
        color: `${colors.Common.textColor}`,
      },
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '4px',
        minWidth: '30px',
        transition: 'all .4s ease',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '10px 35px',
        textTransform: 'capitalize',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
      },
      textPrimary: {
        color: `${colors.Buttons.buttonPrimary.color}`,
      },
      textSecondary: {
        background: 'none',
        marginRight: '20px',
        border: '1px solid #DCDCDC',
        color: `${colors.Common.textColor}`,
        '&:hover': {
          backgroundColor: '#F5F5F5 !important',
          color: `${colors.Common.textColor} !important`,
        },
        '&$disabled': {
          color: '#C9C7C7',
          backgroundColor: 'transparent !important',
        },
      },
      disabled: {},
    },
    MuiDialogContent: {
      root: {
        padding: '16px 36px 24px',
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: '600',
        minHeight: '60px',
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 36px 0',
      },
    },
    MuiTypography: {
      h2: {
        fontSize: '21px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
      }
    },
    MuiDialogActions: {
      root: {
        padding: '32px 36px',
        borderTop: '1px solid #E7E7E7',
      },
      spacing: {
        '&>:not(:first-child)': {
          margin: '0 !important',
        }
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
        borderRadius: 'none',
        maxWidth: '24px',
        minWidth: '24px',
        maxHeight: '24px',
        minHeight: '24px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
  },
});
export const ConfirmDialogButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .backEmpty {
    background: none;
    border: 1px solid #868686;
    margin-left: 20px;
  }
`;
