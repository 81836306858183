import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import { ColumnCellInner, DefaultTooltip } from '../../../../common/styles/Common.styled';
import { ColumnCell } from '../ProductsGrid.styled';
import { clippedValue } from '../../../../common/utils/utils';

export default class CategoryCell extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { value } = this.props;

    return (
      <ColumnCell>
        <ColumnCellInner>
          <MuiThemeProvider theme={DefaultTooltip}>
            <Tooltip placement="bottom" title={value}>
              <div>{this.props.value ? clippedValue(value) : ''}</div>
            </Tooltip>
          </MuiThemeProvider>
        </ColumnCellInner>
      </ColumnCell>
    );
  }
}

CategoryCell.propTypes = {
  value: PropTypes.string.isRequired,
};
