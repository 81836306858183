import { all, call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { client } from '../../../api/Client';
import { handleErrorNotification } from '../../ducks/HandleErrors';

import {
  loadCatalogDataSucceeded,
  loadCatalogDataFailed,
} from './Catalog.actions';

import {
  LOAD_CATALOG_DATA_ACTION,
  EXPORT_CATALOG_ACTION,
} from './Catalog.constants';

function buildQueryParametersCatalogList(action) {
  let params = {
    pageSize: action.pageSize,
    pageNumber: action.pageNum + 1 || 1,
    sort: action.sort,
    fields: action.fields,
  };

  if (_.isArray(action.sort) || _.isUndefined(action.sort)) {
    const sort = _.defaultTo(_.find(action.sort, 'direction'), {
      field: 'created',
      direction: 'DESC',
    });
    params = _.assign({}, params, { sort: sort });
  }

  let filters = {};
  _.forIn(action.filters, (value, key) => {
    if (!_.isNull(value)) {
      if (key === 'created') {
        if (!_.isNull(value.from) && !_.isNull(value.to)) {
          filters[key] = value;
        }
      } else if (key === 'publicationTime') {
        if (!_.isNull(value.from) && !_.isNull(value.to)) {
          filters[key] = value;
        }
      } else if (key === 'contentProvider') {
        if (!_.isEmpty(value.name)) {
          filters['contentProviderName'] = value.name;
        }
        if (!_.isEmpty(_.head(value.inn))) {
          filters['contentProviderInn'] = value.inn;
        }
      } else if (key === 'packingPurpose' &&
        (value === 'consumer' || value === 'unmarkable' || value === 'techCard')) {
        filters[key] = 'consumer';
        if (value === 'consumer') {
          filters.isTechCard = '0';
          filters.IsUnmarkable = '0';
        }
        if (value === 'techCard') {
          filters.isTechCard = '1';
          filters.IsUnmarkable = '0';
        }
        if (value === 'unmarkable') {
          filters.isTechCard = '1';
          filters.IsUnmarkable = '1';
        }
      }
      else if (key === 'photo' || key === 'isValid' || key === 'isExemplar') {
        filters[key] = value;
      }
      else if (key === 'attributes') {
        filters[key] = _.map(value, item => ({ id: item.id, value: item.value }));
      }
      else {
        if (!_.isEmpty(value)) {
          filters[key] = _.has(value, 'id') ? value.id : value;
        }
      }
    }
  });

  return _.assign({}, params, { filter: filters });
}


export function* loadCatalogData(action) {
  try {
    const params = buildQueryParametersCatalogList(action);
    const response = yield call(
      client().post,
      'consumer/goods',
      JSON.stringify(params),
    );

    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      if (_.get(response, 'error.response.status') === 500) {
        yield put(push('/500'));
      } else {
        yield handleErrorNotification(response.error);
        yield put(loadCatalogDataFailed());
      }
    } else {
      yield put(
        loadCatalogDataSucceeded(
          response.data || [],
          response.totalObjects || 0,
          {
            filters: action.filters,
            sort: action.sort,
            pageNum: action.pageNum,
            pageSize: action.pageSize,
          },
        ),
      );
    }
  } catch (e) {
    yield put(loadCatalogDataFailed());
  }
}

export function* exportCatalog(action) {
  const params = _.pick(buildQueryParametersCatalogList(action.params), [
    'sort',
    'filter',
    'pageNumber',
    'pageSize',
  ]);

  if (!_.isEmpty(action.params.goodIds)) {
    params.filter.goodIds = action.params.goodIds;
  }

  const url =
    action.status === 'full' ? 'consumer/goods/reports/full' : 'consumer/goods/reports/short';
  try {
    const response = yield call(client().post, url, JSON.stringify(params), {
      responseType: 'arraybuffer',
    });
    if (response.error) {
      yield handleErrorNotification(response.error);
      action.cbk(response.error);
    } else {
      action.cbk(null, response);
    }
  } catch (e) {
    action.cbk({ error: e });
  }
}

export function* watchLoadCatalogData() {
  yield takeLatest(LOAD_CATALOG_DATA_ACTION, loadCatalogData);
}

export function* watchExportCatalog() {
  yield takeLatest(EXPORT_CATALOG_ACTION, exportCatalog);
}

export default function* catalogGoodSaga() {
  yield all([
    watchLoadCatalogData(),
    watchExportCatalog(),
  ]);
}