import { createMuiTheme } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../common/styles/Common.colors';

export const FieldBlock = styled.div`
  padding-bottom: ${props => props.padding}px;
  margin-right: -20px;
`;

export const FieldTitle = styled.h3`
  color: ${colors.Common.textColor};
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin: 0 16px 0 0;
`;
export const FieldTitleMargin = styled(FieldTitle)`
  margin-top: ${props => props.margin}px;
`;
export const FieldTitleWrap = styled.div`
  display: flex;
  //margin-top: ${props => props.margin}px;
`;
export const BtnArray = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {

      text: {
        padding: '0',
        textTransform: 'inherit',
      }
    }
  }
})
