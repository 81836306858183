import { createMuiTheme } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../common/styles/Common.colors';

export const ExportModalDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '628px',
        maxWidth: '628px',
        borderRadius: '8px',
        padding: '32px 36px 46px',
        boxSizing: 'border-box',
        fontFamily: 'SegoeUI',
        color: `${colors.Common.textColor}`,
      },
    },
    MuiDialogContent: {
      root: {
        padding: '16px 0 0',
        '& p': {
          margin: '0',
          fontSize: '18px',
          lineHeight: '24px',
          fontWeight: '600',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0',
        textAlign: 'center',
      },
    },
    MuiTypography: {
      h2: {
        fontSize: '21px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
      }
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '4px',
        minWidth: '30px',
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: 'all .4s ease',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        textTransform: 'capitalize',
        padding: '10px 35px',
      },
      textPrimary: {
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
        borderRadius: 'none',
        maxWidth: '24px',
        minWidth: '24px',
        maxHeight: '24px',
        minHeight: '24px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
  },
});
export const ExportDialogMin = styled.div`
  padding: 31px 0 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;  
`;
export const ExportDialogMinInner = styled.div`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  margin-top: 16px;
  padding: 16px 22px;
  display: flex;
  align-items: center;
  border: 1px dashed #C9C7C7;
  border-radius: 8px;
  justify-content: space-between;
  width: 100%; 
  h3 {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }
`;
