import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  Button,
  FormControlLabel,
  MuiThemeProvider,
  Popover,
  Radio,
  IconButton,
} from '@material-ui/core';
import {
  IconClose,
  IconNewFilterSmall,
  IconNewFilterSmallActive,
} from '../../../common/icons/customIcons';
import {
  DefaultFilterPopover,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContainer,
  FilterContent,
  FilterTitle,
  FilterBtnReset,
  FilterContentPopup,
} from './Filters.styled';

class CategoriesFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      hasCategories: !!this.props.currentValue,
      isDirty: !_.isNull(this.props.currentValue),
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (_.isNull(nextProps.currentValue) && !_.isNull(state.currentValue)) {
      return {
        anchorEl: null,
        hasCategories: false,
        isDirty: false,
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextState, this.state);
  }

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      hasCategories: !!this.props.currentValue,
      isDirty: !_.isNull(this.props.currentValue),
    });
  };

  handleAccept = () => {
    this.setState({
      anchorEl: null,
      currentValue: this.state.hasCategories,
    });
    this.props.onAccept('hasCategories', this.state.hasCategories);
  };

  handleDissmiss = () => {
    this.setState({
      isDirty: false,
      anchorEl: null,
    });
    this.props.onDismiss('hasCategories');
  };

  handleChange = (value) => {
    this.setState({
      isDirty: true,
      hasCategories: value,
    });

    if (this.props.type === 'modal') {
      this.props.onValueChange('hasCategories', value);
    }
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <>
        {this.props.type !== 'modal' && (
          <FilterContainer>
            <MuiThemeProvider theme={FilterBtn}>
              <FilterButton>
                <Button
                  aria-owns={open ? 'simple-popper' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}>
                  {_.isNull(this.props.currentValue) ? (
                    <IconNewFilterSmall />
                  ) : (
                    <IconNewFilterSmallActive />
                  )}
                </Button>
              </FilterButton>
            </MuiThemeProvider>
            <MuiThemeProvider theme={DefaultFilterPopover}>
              <Popover
                id="simple-popper"
                open={open}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                <FilterTitle>
                  <h6>{t('Товарные категории')}</h6>
                  <MuiThemeProvider theme={FilterBtn}>
                    <IconButton className="backEmpty" onClick={this.handleClose}>
                      <IconClose />
                    </IconButton>
                  </MuiThemeProvider>
                </FilterTitle>
                <FilterContent>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={this.state.hasCategories && this.state.isDirty}
                        onChange={() => this.handleChange(true)}
                      />
                    }
                    label={t('Да')}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={!this.state.hasCategories && this.state.isDirty}
                        onChange={() => this.handleChange(false)}
                      />
                    }
                    label={t('Нет')}
                  />
                </FilterContent>
                <FilterBtns>
                  <MuiThemeProvider theme={FilterBtnReset}>
                    <Button
                      disabled={!this.state.isDirty}
                      onClick={this.handleDissmiss}>
                      {t('Сбросить')}
                    </Button>
                  </MuiThemeProvider>
                  <Button
                    disabled={!this.state.isDirty}
                    onClick={this.handleAccept}>
                    {t('Применить')}
                  </Button>
                </FilterBtns>
              </Popover>
            </MuiThemeProvider>
          </FilterContainer>
        )}
        {this.props.type === 'modal' && (
          <div>
            <FilterTitle>
              <h6>{t('Товарные категории')}</h6>
            </FilterTitle>
            <FilterContentPopup>
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.hasCategories && this.state.isDirty}
                    onChange={() => this.handleChange(true)}
                  />
                }
                label={t('Да')}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={!this.state.hasCategories && this.state.isDirty}
                    onChange={() => this.handleChange(false)}
                  />
                }
                label={t('Нет')}
              />
            </FilterContentPopup>
          </div>
        )}
      </>
    );
  }
}

CategoriesFilter.propTypes = {
  onAccept: PropTypes.func,
  onDismiss: PropTypes.func,
  currentValue: PropTypes.bool,
  parentId: PropTypes.string,
  onValueChange: PropTypes.func,
  type: PropTypes.string,
};

export default withTranslation()(CategoriesFilter);
