import { all, call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { client } from '../../../../api/Client';
import i18n from '../../../../i18n';
import {
  // CHECK_UNIQUENESS_KPP_ACTION,
  CREATE_PROFILE_STATUS_ACTION,
  DELETE_PROFILE_STATUS_ACTION,
  LOAD_GLN_DICTIONARY_ACTION,
  LOAD_PROFILE_STATUS_ACTION,
  LOAD_STATISTIC_AND_OPTIONS_ACTION,
  SAVE_OPTIONS_ACTION,
} from './ProfileStatus.constants';

import { throwNotification } from '../../../../common/structure';
import {
  loadGlnDictionaryFailed,
  loadGlnDictionarySuccessed,
  loadProfileStatusFailed,
  loadProfileStatusSucceeded,
  loadStatisticAndOptionsSucceeded,
  loadStatisticAndOptionsFailed,
  saveOptionsSucceeded,
  saveOptionsFailed,
} from './ProfileStatus.actions';
import { handleErrorNotification } from '../../../ducks/HandleErrors';

export function* loadProfileStatus() {
  try {
    const url = `/usercompany`;
    const response = yield call(client().get, url);
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadProfileStatusFailed());
    } else {
      yield put(
        loadProfileStatusSucceeded(response || [], response.totalObjects || 0),
      );
    }
  } catch (e) {
    yield put(
      throwNotification(i18n.t('Ошибка при загрузке статусов!'), 'error'),
    );
    yield put(loadProfileStatusFailed());
  }
}

export function* deleteProfileStatus(action) {
  const errT = i18n.t('Ошибка при удалении статусов!');
  try {
    const response = yield call(client().delete, `/usercompany/${action.id}`);

    if (response.error) {
      yield handleErrorNotification(response.error);
      action.cbk({ error: errT });
    } else {
      action.cbk(null, 'Успешно');
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: errT });
  }
}

// for using in future
// export function* checkUniquenessKpp(action) {
//     try {
//         const response = yield call(client().post, "/account/parties/validate-kpp", {kpp: action.kpp});

//         if (response.error) {
//             const errors = _.get(response, 'error.response.data.errors');
//             if (errors) {
//                 action.cbk({error: errors});
//             } else {
//                 action.cbk(null);
//             }
//         } else {
//             action.cbk(null);
//         }
//     } catch (e) {
//         yield put(throwNotification(i18n.t('Ошибка при проверке уникальности КПП!'), 'error'));
//     }
// }

export function* createProfileStatus(action) {
  const errT = i18n.t('Ошибка при сохранении статуса!');
  try {
    // const response = _.isNil(action.partyId)
    //   ? yield call(client().post, "/account/party", JSON.stringify(action.data))
    //   : yield call(
    //       client().put,
    //       `/account/party/${action.partyId}`,
    //       JSON.stringify(action.data)
    //     );
    const response = yield call(
      client().post,
      '/usercompany',
      JSON.stringify({
        type: action.statusType,
      }),
    );

    if (response.error) {
      yield handleErrorNotification(response.error);
      action.cbk({ error: errT });
    } else {
      action.cbk(null);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: errT });
  }
}

export function* loadGlnDictionary() {
  const errT = i18n.t('Ошибка получения справочника glns!');
  try {
    const response = yield call(client().get, '/gs1/glns');
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadGlnDictionaryFailed());
    } else {
      if (!_.isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadGlnDictionarySuccessed(_.isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadGlnDictionaryFailed());
  }
}

export function* loadStatisticAndOptions() {
  const errT = i18n.t('Ошибка получения данных!');

  try {
    const response = yield call(client().get, '/gs1/account');
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadStatisticAndOptionsFailed());
    } else {
      if (!_.isObject(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadStatisticAndOptionsSucceeded(_.isObject(response) ? response : {}),
      );
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadStatisticAndOptionsFailed());
  }
}

export function* saveOptions(action) {
  const errT = i18n.t('Ошибка сохранения опций!');
  const succT = i18n.t('Опции успешно сохранены!');
  try {
    const response = yield call(
      client().post,
      '/gs1/account/options',
      action.data,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      return yield put(saveOptionsFailed());
    }
    yield put(saveOptionsSucceeded());
    yield call(loadStatisticAndOptions);
    yield put(throwNotification(succT, 'success'));
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(saveOptionsFailed());
  }
}

export function* watchcreateProfileStatus() {
  yield takeEvery(CREATE_PROFILE_STATUS_ACTION, createProfileStatus);
}

export function* watchProfileStatus() {
  yield takeEvery(LOAD_PROFILE_STATUS_ACTION, loadProfileStatus);
}

export function* watchProfileStatusDelete() {
  yield takeEvery(DELETE_PROFILE_STATUS_ACTION, deleteProfileStatus);
}

// export function* watchCheckUniquenessKpp() {
//     yield takeEvery(CHECK_UNIQUENESS_KPP_ACTION, checkUniquenessKpp);
// }

export function* watchLoadGlnDictionary() {
  yield takeEvery(LOAD_GLN_DICTIONARY_ACTION, loadGlnDictionary);
}

export function* watchLoadStatisticAndOptions() {
  yield takeEvery(LOAD_STATISTIC_AND_OPTIONS_ACTION, loadStatisticAndOptions);
}

export function* watchSaveOptions() {
  yield takeEvery(SAVE_OPTIONS_ACTION, saveOptions);
}

export default function* profileStatusSaga() {
  yield all([
    watchProfileStatus(),
    watchProfileStatusDelete(),
    // watchCheckUniquenessKpp(),
    watchcreateProfileStatus(),
    watchLoadGlnDictionary(),
    watchLoadStatisticAndOptions(),
    watchSaveOptions(),
  ]);
}
