import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import _ from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import {
  MuiThemeProvider,
  FormControl,
  TextField,
  // InputLabel,
  // Select,
  // MenuItem,
} from '@material-ui/core';
import {
  IconDropDown,
  IconClose,
} from '../../../../common/icons/customIcons';
import {
  //  defaultSelectTheme,
  defaultTheme,
} from '../../../../common/styles/Common.styled';
import { CertSelectAuto } from '../../../../components/certificateSelect/CertificateSelect.styled';
import { CustomInput } from '../../../../components';
import { ErrorContent } from './PopupNewProduct.styled';
import { ProductGtinBlock } from './styled/PopupNewProduct.styled';

class PopupNewTechnicalUnmarkedCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    const {
      t,
      data,
      technicalCategoriesUnmarkable,
    } = this.props;

    const getOptions = () => {
      return _.map(technicalCategoriesUnmarkable, (item) => {
        return {
          id: item.id,
          name: t(item.text),
        };
      });
    }

    /* Должно быть от 1 до 100 */
    const getCorrectCount = (value) => {
      return value = value.replace(/[^[0-9]/, '')
    };

    const isCorrectInterval = data.count > 100 || data.count < 0

    return (
      <div>
        <MuiThemeProvider theme={CertSelectAuto}>
          <FormControl fullWidth={true}>
            <Autocomplete
              options={getOptions()}
              autoHighlight
              onChange={(_, item) => this.props.handleChange('CatId', (item ? item.id : ''))}
              getOptionLabel={(item) => item.name}
              popupIcon={<IconDropDown />}
              closeIcon={<IconClose style={{ width: 20, height: 20 }} />}
              clearText=''
              openText=''
              required={!data.CatId}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Выберите код государственного классификатора')}
                  variant="standard"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'disabled',
                  }}
                />
              )}
              noOptionsText={t('Нет совпадений')}
            />
          </FormControl>
        </MuiThemeProvider>
        {/* <MuiThemeProvider theme={defaultSelectTheme}>
          <FormControl fullWidth={true}>
            <InputLabel
              htmlFor="gtin-select"
              disabled
              error={false}>
              {t('Выберите код государственного классификатора')}
            </InputLabel>
            <Select
              required
              name="nestedPackagingCode"
              //value={getDefaultTo(data, 'nestedPackagingCode')}
              valid={_.toString(!!data.CatId)}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              }}
              inputProps={{ id: 'gtin-select' }}
              IconComponent={IconDropDown}
              onChange={(_, item) => this.props.handleChange('CatId', item.props.value.id)}
              disabled={false}>
              {_.map(technicalCategoriesUnmarkable, (item, index) => (
                <MenuItem key={`${item}_${index}`} value={item}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MuiThemeProvider> */}
        <MuiThemeProvider theme={defaultTheme}>
          <ProductGtinBlock>
            <CustomInput
              id="technicalCount"
              name="count"
              type="text"
              disabled={!data.CatId}
              label={t('Укажите количество карточек')}
              onUpdate={() => { }}
              onChange={(value) => this.props.handleChange('count', getCorrectCount(value))}
              value={_.defaultTo(data.count, '')}
              error={isCorrectInterval}
              required
              valid={!!data.count}
              hideCleanButton={true}
            />
            <ErrorContent>
              {isCorrectInterval ?
                t('Максимальное количество 100')
                : ''
              }
            </ErrorContent>
          </ProductGtinBlock>
        </MuiThemeProvider>
      </div>
    )
  }
}

PopupNewTechnicalUnmarkedCard.propTypes = {
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  technicalCategoriesUnmarkable: PropTypes.array.isRequired,
};

export default compose(withTranslation())(PopupNewTechnicalUnmarkedCard);