import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from '@material-ui/core';
import {
  IconClose,
  IconComponentClose,
  IconDropDown,
  IconLogoDropDown,
} from '../../../../../common/icons/customIcons';
import {
  addTradeMark,
  updateTradeMark,
} from '../../../ducks/TradeMarks/TradeMarks.actions';
import { BlueBtn, CloseBtn, CreationCloseBtns, WhiteBtn } from '../../../Profile.styled';
import {
  defaultSelectTheme,
  defaultTheme,
  DialogTitleStyled,
} from '../../../../../common/styles/Common.styled';
import { datePicker, DatePickerWrap, DaughterCompanyDialog } from '../styled/AssignDaughterCompanyModal.styled';
import { CustomInput, DatePicker } from '../../../../../components';

const formatDate = (date) => {
  if (!date) return '';

  return moment(date.split('.').reverse().join('')).format(
    'YYYY-MM-DD HH:mm:ss',
  );
};

const formatCompareDate = (date) => {
  if (!date) return '';

  return date.split('.').reverse().join('-');
};

const tradeMarksTypes = [
  {
    id: 'owner',
    text: 'Собственный',
  },
  {
    id: 'rights',
    text: 'С правом использования',
  },
];

class TradeMarkRegistrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
    };
  }

  componentDidUpdate() {
    const dateIsValid = moment(
      formatCompareDate(this.state.data?.issueDate),
    ).isBefore(formatCompareDate(this.state.data?.validDate));
    if (
      this.state.data?.issueDate &&
      this.state.data?.validDate &&
      this.state.errors?.dateValid !== dateIsValid
    ) {
      return this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          dateValid: dateIsValid,
        },
      }));
    }
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {
      data: {},
    };
    if (props.currentRow && !Object.keys(state.data).length) {
      if (_.isEmpty(state.data.tmName) && !_.isEmpty(props.currentRow.tmName)) {
        newState.data.tmName = props.currentRow.tmName;
      }
      if (_.isEmpty(state.data.tmType) && !_.isEmpty(props.currentRow.tmType)) {
        newState.data.tmType = props.currentRow.tmType;
      }
      if (
        _.isEmpty(state.data.tmNumber) &&
        !_.isEmpty(props.currentRow.tmNumber)
      ) {
        newState.data.tmNumber = props.currentRow.tmNumber;
      }
      if (
        _.isEmpty(state.data.issueDate) &&
        !_.isEmpty(props.currentRow.issueDate)
      ) {
        newState.data.issueDate = moment(
          props.currentRow.issueDate.split(' ')[0],
        ).format('DD.MM.YYYY');
      }
      if (
        _.isEmpty(state.data.validDate) &&
        !_.isEmpty(props.currentRow.validDate)
      ) {
        newState.data.validDate = moment(
          props.currentRow.validDate.split(' ')[0],
        ).format('DD.MM.YYYY');
      }
      if (_.isEmpty(state.data.tmBy) && !_.isEmpty(props.currentRow.tmBy)) {
        newState.data.tmBy = props.currentRow.tmBy;
      }

      if (Object.keys(newState.data).length) {
        return newState;
      }
    }

    return null;
  }

  isButtonDisabled = () => {
    const { data } = this.state;
    let result = false;

    if (!Object.keys(this.state.data).length) {
      return (result = true);
    }

    if (
      _.isEmpty(data.tmName) ||
      _.isEmpty(data.tmType) ||
      _.isEmpty(data.tmNumber) ||
      _.isEmpty(data.issueDate) ||
      _.isEmpty(data.validDate) ||
      _.isEmpty(data.tmBy)
    ) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  handleSubmit = () => {
    const {
      modalType,
      addTradeMark,
      currentRow,
      updateTradeMark,
      changeGridParams,
      handleClose,
    } = this.props;

    const newData = _.cloneDeep(this.state.data);

    newData.issueDate = formatDate(newData.issueDate);
    newData.validDate = formatDate(newData.validDate);

    if (modalType === 'create') {
      return addTradeMark(newData, (errors) => {
        if (_.isNil(errors)) {
          const message = `Товарный знак успешно добавлен`;
          this.props.throwNotification(message, 'success');
          changeGridParams();
          handleClose();
        }
      });
    }

    if (modalType === 'edit') {
      return updateTradeMark(newData, currentRow.tmId, (errors) => {
        if (_.isNil(errors)) {
          const message = `Товарный знак успёшно сохранён`;
          this.props.throwNotification(message, 'success');
          changeGridParams();
          handleClose();
        }
      });
    }
  };

  handleChange = (name, value) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [name]: value,
      },
    }));
  };

  dialogTitle = () => {
    const { modalType, t } = this.props;
    switch (modalType) {
      case 'create':
        return t(`Регистрация Товарного знака`);
      case 'edit':
        return t(`Редактирование Товарного знака`);
      case 'view':
        return t(`Просмотр Товарного знака`);
      default:
        return ``;
    }
  };

  render() {
    const { handleClose, t, modalType } = this.props;
    const disabled = this.isButtonDisabled() || !this.state.errors?.dateValid;

    const issueDateError = false;
    const validDateError = false;

    const buttonsDisabled = modalType === 'view';

    const id = Date.now();

    return (
      <MuiThemeProvider theme={DaughterCompanyDialog}>
        <Dialog open>
          <DialogTitle>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <DialogTitleStyled>{this.dialogTitle()}</DialogTitleStyled>
              <MuiThemeProvider theme={CloseBtn}>
                <IconButton className="backEmpty" onClick={handleClose}>
                  <IconClose />
                </IconButton>
              </MuiThemeProvider>
            </div>
          </DialogTitle>

          <DialogContent style={{marginTop: '-20px'}}>
            <MuiThemeProvider theme={defaultTheme}>
              <CustomInput
                id="tmNameId"
                name="tmName"
                required={!buttonsDisabled}
                label={t('Укажите Товарный Знак')}
                onUpdate={() => { }}
                onChange={(value) => this.handleChange('tmName', value)}
                value={_.defaultTo(this.state.data.tmName, '')}
                disabled={buttonsDisabled || modalType === 'edit'}
                valid={!!_.defaultTo(this.state.data.tmName, '')}
                type="text"
                style={{marginBottom: '16px'}}
              />
            </MuiThemeProvider>
            <FormControl fullWidth>
              <MuiThemeProvider theme={defaultSelectTheme}>
                <InputLabel disabled htmlFor={'packaging-select-' + id}>
                  {t('Тип')}
                </InputLabel>
                <Select
                  value={_.defaultTo(this.state.data.tmType, '')}
                  inputProps={{ id: 'type-select' }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  IconComponent={IconDropDown}
                  onChange={(event) =>
                    this.handleChange('tmType', event.target.value)
                  }
                  style={{marginBottom: '16px'}}
                  disabled={buttonsDisabled}
                  required={buttonsDisabled ? false : true}
                  valid={_.toString(!!_.defaultTo(this.state.data.tmType, ''))}>
                  {_.map(tradeMarksTypes, (i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {t(i.text)}
                    </MenuItem>
                  ))}
                </Select>
              </MuiThemeProvider>
            </FormControl>
            <FormControl fullWidth>
              <MuiThemeProvider theme={defaultTheme}>
                <CustomInput
                  id="tmNumberId"
                  name="tmNumber"
                  onChange={(value) => this.handleChange('tmNumber', value)}
                  label={t('Укажите № Свидетельства')}
                  value={_.defaultTo(this.state.data.tmNumber, '')}
                  type="text"
                  disabled={buttonsDisabled}
                  required
                  valid={!!_.defaultTo(this.state.data.tmNumber, '')}
                  style={{marginBottom: '16px'}}
                />
              </MuiThemeProvider>
            </FormControl>
            <DatePickerWrap style={{marginBottom: '16px'}}>
              <MuiThemeProvider theme={datePicker}>
                <FormControl>
                  <DatePicker
                    id={`issueDateId`}
                    disabled={buttonsDisabled}
                    error={issueDateError}
                    name={t('Дата выдачи свидетельства')}
                    currentValue={_.defaultTo(this.state.data.issueDate, '')}
                    onAccept={(value) => this.handleChange('issueDate', value)}
                    onDismiss={() => this.handleChange('issueDate', null)}
                    required
                    valid={
                      !!_.defaultTo(this.state.data.issueDate, '') &&
                      _.defaultTo(this.state.errors.dateValid, true)
                    }
                  />
                </FormControl>
              
                <FormControl>
                  <DatePicker
                    id={`validDateId`}
                    disabled={buttonsDisabled}
                    error={validDateError}
                    name={t('Срок действия свидетельства')}
                    currentValue={_.defaultTo(this.state.data.validDate, '')}
                    onAccept={(value) => this.handleChange('validDate', value)}
                    onDismiss={() => this.handleChange('validDate', null)}
                    required
                    valid={
                      !!_.defaultTo(this.state.data.validDate, '') &&
                      _.defaultTo(this.state.errors.dateValid, true)
                    }
                  />
                </FormControl>
              </MuiThemeProvider>
            </DatePickerWrap>
            <FormControl fullWidth>
              <MuiThemeProvider theme={defaultTheme}>
                <CustomInput
                  id="tmById"
                  name="tmBy"
                  onChange={(value) => this.handleChange('tmBy', value)}
                  label={t('Кем выдано')}
                  value={_.defaultTo(this.state.data.tmBy, '')}
                  type="text"
                  disabled={buttonsDisabled}
                  required
                  valid={!!_.defaultTo(this.state.data.tmBy, '')}
                />
              </MuiThemeProvider>
            </FormControl>
          </DialogContent>
          {!buttonsDisabled ? (
            <DialogActions>
              <MuiThemeProvider theme={WhiteBtn}>
                <Button onClick={handleClose}>
                  {buttonsDisabled ? t('Закрыть') : t('Отменить')}
                </Button>
              </MuiThemeProvider>
              <MuiThemeProvider theme={BlueBtn}>
                <Button
                  disabled={disabled || buttonsDisabled}
                  onClick={this.handleSubmit}>
                  {t('Отправить')}
                </Button>
              </MuiThemeProvider>
            </DialogActions>
          ) : (
            <DialogActions style={{ justifyContent: 'flex-end' }}>
              <MuiThemeProvider theme={WhiteBtn}>
                <Button onClick={handleClose}>
                  {buttonsDisabled ? t('Закрыть') : t('Отменить')}
                </Button>
              </MuiThemeProvider>
            </DialogActions>
          )}
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

TradeMarkRegistrationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  addTradeMark: PropTypes.func.isRequired,
  changeGridParams: PropTypes.func.isRequired,
  updateTradeMark: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  // currentRow: PropTypes.object.is,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addTradeMark: (data, cbk) => addTradeMark(data, cbk),
      updateTradeMark: (data, id, cbk) => updateTradeMark(data, id, cbk),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps),
)(TradeMarkRegistrationModal);
