import styled from 'styled-components';
import colors from '../../common/styles/Common.colors';

export const DragDropContainer = styled.fieldset`
  border: ${props => props.border ? `4px dashed ${colors.Common.background}` : "1px dashed #C9C7C7"};  
  //height: 255px;
  padding: ${props => props.border ? "29px" : "32px"};
  text-align: center;
  border-radius: 4px;   
`;

export const DragDropContainerImport = styled(DragDropContainer)`
padding: ${props => props.border ? "53px 29px 21px" : "56px 32px 24px"};
`;

export const DragDrop = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DragDropForm = styled.form`
  display: flex;
  justify-content: space-between;
  height: calc(100% - 150px);
`;

export const DragDropTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  padding: 40px 0 20px;
`;

export const DragDropLabel = styled.label`
  font-size: 16px;
  line-height: 24px;
`;

export const DragDropInput = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
`;

export const DragDropContent = styled.div`
  width: 100%;
  position: relative;
  //margin: 10px 0 40px;
  text-align: center;
  padding-top: ${props => props.top || "0"};
  padding-bottom: ${props => props.bottom || "0"};
`;

export const DragDropButton = styled.label`
  width: 100%;
  max-width: 190px;
  height: 44px;
  background: ${colors.Buttons.buttonPrimary.background};
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;
  &:hover {
    background: ${colors.Buttons.buttonPrimary.backgroundHovered};
  }
`;

export const DragDropButtonText = styled.span`
  line-height: 1;
  margin-top: 1px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const DragDropView = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #DCDCDC;
  padding: 16px;
  align-items: center;
  margin-top: 12px;
  &:first-child {
    margin-top: 28px;
  }
`;
export const DragDropViewFile = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
`;
export const DragDropViewRight = styled.div`
  display: flex;
  align-content: center;
`;
export const DragDropViewFileName = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 0 0 4px;
  word-break: break-all;
`;
export const DragDropViewFileSize = styled.div`
  padding-right: 15px;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.Common.textGrey};
`;
export const DragDropViewLeft = styled.div`
  display: flex;
  align-items: center;
`;
export const DragDropWrapper = styled.div`
  width: 100%;
  padding-bottom: 12px;
`;
export const DragDropMsg = styled.span`
  line-height: 20px;
  color: ${colors.Common.textGrey};
`;
export const DragDropText = styled(DragDropMsg)`
  margin-top: 40px;
`;
