import * as React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import { Button, MuiThemeProvider } from '@material-ui/core';
import { validateRangesIds } from '../../../../common/constants/Constants';
import _ from 'lodash';
import { IconDropDownGrey } from '../../../../common/icons/customIcons';
import {
  AdresFields,
  AdresTitle,
  AdresTitleH3,
  CompanyBlock,
  MultipleTransportBlock,
  MultipleTransportEl,
  MultipleTransportTitleH3,
  PackageTitleHolder,
  ProductTabBtn,
} from '../../../../common/styles/Common.styled';
import { ConsumerItem } from '../../../../pages/product/view/itemElement';

import MultipleAndTransportPackageInputItem from '../itemElement/MultipleAndTransportPackageInputItem';
import { DeveloperBoard } from '@material-ui/icons';

const findItemIndex = (attributes, item, index) => {
  let findIndex;
  let i = 0;
  for (let k = 0; k < attributes.length; k++) {
    if (attributes[k].id === item.id) {
      if (i === index) {
        findIndex = k;
        break;
      }
      i++;
    }
  }
  return findIndex;
};

class MultipleAndTransportPackageInputs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentShow: true,
      errors: [],
    };
  }

  updateItem = (items, groupIndex, index) => {
    let attrGroup = _.cloneDeep(this.props.attrGroup);
    let attributes = attrGroup[groupIndex].attributes;
    const updateItem = items[index];

    const updatedIndex = findItemIndex(attributes, items[index], index);
    if (!_.isUndefined(updatedIndex)) {
      _.set(
        attrGroup,
        `[${groupIndex}].attributes.[${updatedIndex}]`,
        updateItem,
      );
      if (validateRangesIds.range1.includes(updateItem.id)) {
        const [id1, id2] = validateRangesIds.range1;
        this.validateRanges(attrGroup, groupIndex, id1, id2);
      }
      if (validateRangesIds.range2.includes(updateItem.id)) {
        const [id1, id2] = validateRangesIds.range2;
        this.validateRanges(attrGroup, groupIndex, id1, id2);
      }
      this.props.updateData('attrGroup', attrGroup);
    }
  };

  buildBlocks = (firstPackageGroups) => {
    const jsxBlocks = [];
    _.forEach(firstPackageGroups, (attribute, attributeIndex) => {
      const elements = [];
      const groupedAttr = [];
      const resultArray = [];
      _.forEach(
        ['Код вложенной упаковки', 'Количество вложенных упаковок'],
        (field) => {
          groupedAttr.push(
            attribute.attributes.filter((el) => el.name.indexOf(field) === 0),
          );
        },
      );

      for (let i = 0; i < groupedAttr[0].length; i++) {
        resultArray.push(groupedAttr[0][i], groupedAttr[1][i]);
      }

      _.forEach(resultArray, (element) => {
        elements.push(
          this.buildElement(element, attribute.attributes, attributeIndex),
        );
      });

      jsxBlocks.push(
        <MultipleTransportBlock key={`block_group_${attributeIndex + Math.random()}`}>
          {elements}
        </MultipleTransportBlock>,
      );
    });

    return jsxBlocks;
  };

  buildElement = (element, elements, attributeIndex) => {
    const { data } = this.props;

    let props = {
      groupIndex: attributeIndex,
      updateItem: this.updateItem,
      onValidate: () => {},
      showPopupSearch: () => {},
      closePopupSearch: () => {},
      errors: {},
      preview: true,
      hasPublishedGood: _.get(data, 'hasPublishedGood', false),
      shouldBePadded: true,
    };

    if (element.multiplicity) {
      props = {
        ...props,
        multiplicity: true,
        items: _.filter(elements, { id: element.id }),
        addItem: this.addItem,
        deleteItem: this.deleteItem,
      };
    } else {
      props = {
        ...props,
        multiplicity: false,
        items: [element],
        addItem: () => {},
        deleteItem: () => {},
      };
    }

    element._attruid = _.uniqueId('attruid_');

    return (
      <MultipleTransportEl key={`element_${element._attruid}`}>
        <Element key={`element_${element._attruid}`} name={`id_${element.id}`} style={{width: '100%'}}>
          <ConsumerItem {...props} />
        </Element>
      </MultipleTransportEl>
      
    );
  };

  onClickHide = () => {
    this.setState({ contentShow: !this.state.contentShow });
  };

  handleChange = (name, value) => {
    this.setState((prevState) => ({
      data: { ...prevState.data, [name]: value },
    }));
  };

  returnPackageType = (type) => {
    const { t } = this.props;

    switch (type) {
      case 'consumer':
        return t('Потребительская упаковка');
      case 'transport':
        return t('Транспортная упаковка');
      case 'group':
        return t('Групповая упаковка');
      default:
        return;
    }
  };

  render() {
    const { t, data } = this.props;
    const { attrGroup } = data;
    const fields = this.buildBlocks(attrGroup);

    return (
      <CompanyBlock>
        {/* <AdresTitle>
          {data.isExemplar ? (
            <PackageTitleHolder style={{ paddingTop: '25px' }}>
              <MuiThemeProvider theme={ProductTabBtn}>
                <Button onClick={this.onClickHide}>
                  {this.state.contentShow ? (
                    <IconDropDownGrey />
                  ) : (
                    <IconDropDownGrey style={{ transform: 'rotate(180deg)' }} />
                  )}
                </Button>
                <AdresTitleH3>
                  {this.returnPackageType(data.packingPurpose)}
                  <img
                    src="/images/isExemplarIcon.png"
                    alt=""
                    style={{ marginLeft: '10px' }}
                    height={32}
                  />
                </AdresTitleH3>
              </MuiThemeProvider>
            </PackageTitleHolder>
          ) : (
            <PackageTitleHolder>
              <MuiThemeProvider theme={ProductTabBtn}>
                <Button onClick={this.onClickHide}>
                  {this.state.contentShow ? (
                    <IconDropDownGrey />
                  ) : (
                    <IconDropDownGrey style={{ transform: 'rotate(180deg)' }} />
                  )}
                </Button>
                <AdresTitleH3>
                  {this.returnPackageType(data.packingPurpose)}
                </AdresTitleH3>
              </MuiThemeProvider>
            </PackageTitleHolder>
          )}
        </AdresTitle> */}
        <AdresFields style={{paddingBottom: '44px'}}>
          {this.state.contentShow && data.attachments && (
            <React.Fragment>
              <MultipleTransportTitleH3>{t('Вложенные упаковки')}</MultipleTransportTitleH3>
              {_.map(data.attachments, (inputItem, index) => (
                <Element
                  key={Math.random() + `${inputItem.gtin}`}
                  name={`id_${inputItem.packingPurpose}`}>
                  <MultipleAndTransportPackageInputItem
                    key={`${inputItem.gtin}${inputItem.index}`}
                    data={inputItem}
                  />
                </Element>
              ))}
            </React.Fragment>
          )}
          {this.state.contentShow && !data.attachments && (
            <React.Fragment>
              <MultipleTransportTitleH3>{t('Вложенные упаковки')}</MultipleTransportTitleH3>
              {fields}
            </React.Fragment>
          )}
        </AdresFields>
      </CompanyBlock>
    );
  }
}

const mapStateToProps = (state) => ({
  productData: state.productReducer.data,
});

MultipleAndTransportPackageInputs.propTypes = {
  preview: PropTypes.bool,
  data: PropTypes.object,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(MultipleAndTransportPackageInputs);
