import { createMuiTheme } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../common/styles/Common.colors';

export const DialogProductTitleContent = styled.div`
  font-size: 14px;
  button {
    position: absolute;
    top: 20px;
    right: 20px;
    min-width: 40px !important;
    width: 40px !important;
    height: 40px !important;
  }
  &.newProduct {
    button {
      top: 11px;
      right: 17px;
    }
  }
`;
export const PackInfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
export const PackInfoItemVgh = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 16px;
`;
export const PackInfoItemMax = styled.div`
  flex-grow: 1;
`;
export const PackInfoItemMin = styled.div`
  padding-left: 20px;
`;
export const ProductViewMinBorder = styled.div`
  margin-bottom: 80px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }  
`;
export const ProductViewMediaItemType = styled.span`  
  font-size: 14px;
  color: ${colors.Common.textGrey};
  line-height: 20px; 
  margin-bottom: 8px;   
`;
export const ProductViewMediaItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;  
`;
export const ProductSearchBtns = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const ModerationIconHolder = styled.div`
  transform: translateX(-18.5px) translateY(36px);
  height: 0;
  width: 0;
`;
export const ProductSearchBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: 'none',
        height: '50px',
        minWidth: '60px',
        transition: '0.4s ease',
        marginTop: '16px',
        border: '1px solid #D9D9D9',
        borderRadius: '3px 0 0 3px',
      },
      text: {
        padding: '9px 20px',
      },
      label: {
        lineHeight: '19px',
      },
    },
  },
});
export const NewProductDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '628px',
        maxWidth: '628px',
        borderRadius: '8px',
        padding: '32px 0 0',
        fontFamily: 'SegoeUI',
        color: `${colors.Common.textColor}`,
        backgroundColor: '#FFFFFF',
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 36px 12px',
      },
    },
    MuiTypography: {
      h2: {
        fontWeight: '600',
        fontSize: '21px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 36px 32px',
      },
    },
    MuiDialogActions: {
      root: {
        borderTop: "1px solid #E7E7E7",
        padding: '32px 36px',
        margin: '0',
        justifyContent: 'flex-end',
      },
      spacing: {
        '& :not(:first-child)': {
          marginLeft: '20px',
        }
      }
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '4px',
        textTransform: 'none',
        fontFamily: 'SegoeUI',
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: '.4s ease',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '10px 35px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
      },
      textPrimary: {
        color: `${colors.Buttons.buttonPrimary.color}`,
      },
      textSecondary: {
        color: `${colors.Common.textColor}`,
        background: 'none',
        border: '1px solid #DCDCDC',
        fontSize: '16px',
        textTransform: 'none',
        '&:hover': {
          color: `${colors.Common.textColor} !important`,
          backgroundColor: '#F5F5F5!important',
        },
        '&$disabled': {
          color: '#C9C7C7',
          backgroundColor: 'transparent !important',
        },
      },
      disabled: {},
    },
    MuiIconButton: {
      root: {
        padding: '0',
        borderRadius: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
  },
});
