import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const GridActionsPageSize = styled.div`
  font-size: 14px;
  color: ${colors.Common.textColor};
  display: flex;
  align-items: center;
  font-weight: normal;
  line-height: 20px;
  margin-right: 80px;
  &.disabled {  
    button {
      color: #868686;
      &:disabled {
        color: #868686;
        background: none;
        span {
          color: #868686;
        }
      }
    }
  }
  button {
    font-size: 14px;
    color: ${colors.Common.textColor};
    font-weight: normal;
    border-radius: 4px;
    width: 28px;
    height: 28px;
    padding: 4px 0;
    margin-right: 8px;
    line-height: 20px;
    span {
      color: ${colors.Common.textColor};
    }
    &:disabled {
      color: ${colors.Grid.pageSizeColor};
      background: ${colors.Grid.pageSizeBackground};
      span {
        color: ${colors.Grid.pageSizeColor};
      }
    }
  }
`;
export const PageSizeTitle = styled.div`
  margin-right: 8px;
`;
export const PageSizeBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '20px',
        height: '20px',
        lineHeight: '1',
        borderRadius: '4px',
        marginRight: '10px',
        backgroundColor: 'none',
        '&$disabled': {
          color: `${colors.Grid.pageSizeColor}`,
        },
      },
      label: {
        color: `${colors.Grid.pageSizeColor}`,
        fontSize: '12px',
      },
      text: {
        padding: '2px',
      },
      disabled: {},
    },
  },
});
