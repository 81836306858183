import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { MuiThemeProvider, Tab, Tabs } from '@material-ui/core';
import _ from 'lodash';
import { MemberDetails, ProfileManagement, Gs1Interactions } from './views';
import * as selectorsP from './ducks/Profile/Profile.selectors';
import * as selectorsGS from './ducks/GS1/GS1.selectors';
import { DefaultTabs, TabEmpty } from './Profile.styled';
import { ProfileContainer } from '../../common/styles/Common.styled';
import { loadProfileStatus } from '../profile/ducks/ProfileStatus/ProfileStatus.actions';
import { IconEllipse } from '../../common/icons/customIcons';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      valueProfileManagement: 2,
    };
  }

  componentDidMount() {
    this.props.onLoadProfileStatus();
  }

  handleChangeTab = (event, value) => this.setState({ value });

  handleChangeProfileManagement = (value) => this.setState({ valueProfileManagement: value });

  render() {
    const { loader, user, t, userCompanyInfo } = this.props;
    const { value, valueProfileManagement } = this.state;
    const withScroll = (value === 0 || value === 2);
    const isValid = _.isBoolean(userCompanyInfo.isValid) && !userCompanyInfo.isValid;

    return (
      <ProfileContainer value={value} valueProfileManagement={valueProfileManagement} >
        <MuiThemeProvider theme={DefaultTabs}>
          <Tabs
            value={value}
            onChange={this.handleChangeTab}
            variant="fullWidth">
            <Tab disabled={loader} 
                label={t('Данные участника')} 
                icon={isValid && <IconEllipse style={{position: 'absolute', top: '30px', right: '30px'}} color={'#E32A38'}/>}/>
            {/* {user && user.accountType !== 'csp' && ( */}
            {user && !_.includes(user.accountTypes, 'csp') && (
              <Tab disabled={loader} label={t('Настройки профиля')} />
            )}
            {user &&
              // user.accountType !== 'net' &&
              // user.accountType !== 'csp' && (
              !_.includes(user.accountTypes, 'net') &&
              !_.includes(user.accountTypes, 'csp') && (
                <Tab disabled={
                  loader ||
                  !user.isElkUser ||
                  (user.isElkUser && userCompanyInfo.isResident === false)}
                  label={t('Взаимодействие с GS1')}
                />
              )}
            <TabEmpty/>
          </Tabs>
        </MuiThemeProvider>
        {value === 0 && <MemberDetails />}
        {value === 1 && <ProfileManagement handleChangeProfileManagement={this.handleChangeProfileManagement} />}
        {value === 2 && <Gs1Interactions />}
      </ProfileContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: selectorsP.errors(state),
  loader: selectorsGS.loader(state),
  user: state.authentificationReducer.user,
  userCompanyInfo: state.profileReducer.info.company,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLoadProfileStatus: () => loadProfileStatus(),
    },
    dispatch,
  );
};

Profile.propTypes = {
  loader: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Profile);
