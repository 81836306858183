import { createMuiTheme } from '@material-ui/core';
import colors from '../../common/styles/Common.colors';
import styled from 'styled-components';

export const CertSelectDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paper: {
        minWidth: '628px',
        maxWidth: '628px',
        borderRadius: '8px',
        padding: '32px 0 0',
        boxSizing: 'border-box',
        fontFamily: 'SegoeUI',
        color: `${colors.Common.textColor}`,
      },
      paperWidthSm: {
        maxWidth: '628px',
      },
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '4px',
        minWidth: '30px',
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: 'all .4s ease',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '10px 35px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
        textTransform: 'capitalize',
      },
      textPrimary: {
        color: `${colors.Buttons.buttonPrimary.color}`,
      },
      textSecondary: {
        color: `${colors.Common.textColor}`,
        background: 'none',
        border: '1px solid #DCDCDC',
        marginRight: '20px',
        '&:hover': {
          color: `${colors.Common.textColor} !important`,
          backgroundColor: '#F5F5F5 !important',
        },
        '&$disabled': {
          color: '#C9C7C7',
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '16px 32px',
        '& p': {
          margin: '0',
          fontSize: '18px',
          lineHeight: '24px',
          fontWeight: '600',
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: '32px',
        borderTop: '1px solid #E7E7E7',
      },
      spacing: {
        '&>:not(:first-child)': {
          margin: '0 !important',
        }
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0 32px 0',
      },
    },
    MuiTypography: {
      h2: {
        fontSize: '21px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
      }
    },
    MuiIconButton: {
      root: {
        padding: '0',
        borderRadius: 'none',
        maxWidth: '24px',
        minWidth: '24px',
        maxHeight: '24px',
        minHeight: '24px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
  },
});

export const DialogQuestion = styled.div`
  padding: 0 40px 20px;
  margin-bottom: 5px;
  border-bottom: 1px solid #f7f7f7;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #63666a;
  a {
    font-size: 16px;
    color: #4aa5e8;
  }
`;
export const DialogSelect = styled.div`
  padding: 0;
`;
export const DialogSelectAddLetter = styled.div`
  width: 100%;
  padding: 0;
`;
export const DialogBtnsHolder = styled.div`
  margin-top: 20px;
  button {
    &:nth-of-type(1) {
      margin-right: 20px;
    }
  }
`;
export const DialogWarning = styled.div`
  color: #63666a;
  font-size: 10px;
  font-weight: 400;
`;

export const DialogBtnHolder = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
        transition: 'all .4s ease',
      },
      text: {
        padding: '3px 35px',
        fontSize: '12px',
        fontWeight: '600',
      },
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        minWidth: 'auto',
        textTransform: 'none',
        height: 'auto',
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
          boxShadow: `0px 10px 10px -4px ${colors.Buttons.buttonPrimary.boxShadow}`,
          border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        },
        '&.active': {
          backgroundColor: '#B1B2B4 ',
          borderColor: '#B1B2B4',
          '&:hover': {
            boxShadow: '0px 10px 10px -4px rgba(177, 178, 180, .4)',
          },
        },
      },
      textSecondary: {
        border: '1px solid #868686',
        textTransform: 'none',
        minWidth: 'auto',
        height: 'auto',
        color: '#63666A',
        opacity: '.5',
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#63666A',
          border: '1px solid #868686',
          opacity: '1',
        },
        '&.active': {
          backgroundColor: '#B1B2B4 ',
          borderColor: '#B1B2B4',
          opacity: '1',
          color: '#fff',
          '&:hover': {
            boxShadow: '0px 10px 10px -4px rgba(177, 178, 180, .4)',
          },
        },
      },
    },
  },
});
