import _ from 'lodash';

export const PACKAGING_TYPE = [
  { level: 'trade-unit', text: 'Потребительская упаковка' },
  { level: 'inner-pack', text: 'Групповая упаковка' },
  { level: 'box', text: 'Транспортная упаковка' },
  { level: 'pallet', text: 'Паллета' },
  { level: 'second-pack', text: 'Вторичная упаковка' },
  { level: 'techCard', text: 'Техническая карточка' },
  { level: 'unmarkable', text: 'Немаркируемый товар' },
  //{ level: '', text: 'Вторичная упаковка' }, {/* времено, пока с бека не приходит для вторичной упаковки */}
];

export const NEW_PACKAGE_TYPES = [
  { level: 'consumer', text: 'Потребительская' },
  { level: 'group', text: 'Групповая' },
  { level: 'transport', text: 'Транспортная' },
];

export const MEDIA_PACKAGE_TYPES = [
  { level: 'consumer', text: 'Потребительская' },
  { level: 'group', text: 'Групповая упаковка' },
  { level: 'transport', text: 'Транспортная упаковка' },
  { level: 'techCard', text: 'Техническая карточка' },
  { level: 'unmarkable', text: 'Немаркируемый товар' },
];

export const PHOTO_TYPE = [
  { type: 'A1N1', text: 'Лицевая сторона' },
  { type: 'A2N1', text: 'Левая сторона' },
  { type: 'A7N1', text: 'Задняя сторона' },
  { type: 'A8N1', text: 'Правая сторона' },
  { type: 'A3N1', text: 'Верх' },
  { type: 'A9N1', text: 'Низ' },
  { type: 'A1R1', text: 'Три грани' },
  { type: 'L1', text: 'Техническое фото 1' },
  { type: 'L2', text: 'Техническое фото 2' },
  { type: 'L3', text: 'Техническое фото 3' },
  //
  { type: 'default', text: 'Вид спереди' },
  { type: '7', text: 'Вид слева' },
  { type: '19', text: 'Вид справа' },
  { type: '13', text: 'Вид сзади' },
  { type: 'si1', text: 'Вид сверху' },
  { type: 'si2', text: 'Вид снизу' },
  // временно убираем, т.к. эти ракурсы ломают бэк-энд.
  // {type: 'si3', text: 'Вид в упаковке'},
  // {type: 'si4', text: 'Вид без упаковки'},
  // {type: 'si5', text: 'Вид внутри упаковки'},
  // {type: '3ds', text: '3D серия'},
  // {type: 'marketing', text: 'Маркетинговое фото'},
  // {type: 'text', text: 'Фото текста'},
  // {type: 'ecommerce', text: 'e-commerce фото'},
  // {type: 'facing', text: 'Фото для планограмм'},
  // {type: 'undef', text: 'Иной вид'},
  // {type: 'cubi', text: 'Фото измерения ВГХ'}
];

export const PackagingFieldName = (name) => {
  switch (name) {
    case 'name':
      return 'Краткое наименование';
    case 'packageMaterial':
      return 'Материал упаковки';
    case 'packageType':
      return 'Тип упаковки';
    case 'packageMaterialCapping':
      return 'Материал укупорочного средства';
    case 'packageTypeCapping':
      return 'Тип укупорочного средства';
    case 'gtin':
      return 'Код товара упаковки';
    case 'gtinInner':
      return 'Код товара вложения в упаковку';
    case 'purpose':
      return 'Назначение упаковки';
    case 'width':
      return 'Ширина';
    case 'height':
      return 'Высота';
    case 'length':
      return 'Глубина';
    case 'weight':
      return 'Вес брутто';
    case 'capacity':
      return 'Расчетный объем';
    case 'multiplier':
      return 'Количество вложений в упаковку';
    case 'layerCount':
      return 'Количество слоев на паллете для выбранного кода товара';
    case 'hasShowBox':
      return 'Признак выкладки шоубокс';
    default:
      return '';
  }
};

export const getPackagingType = (level) => {
  return _.defaultTo(_.find(PACKAGING_TYPE, { level: level }), { text: level })
    .text;
};

export const getNewPackagingType = (level) => {
  return _.defaultTo(_.find(NEW_PACKAGE_TYPES, { level: level }), {
    text: level,
  }).text;
};

export const getPhotoType = (type) => {
  return _.defaultTo(_.find(PHOTO_TYPE, { type: type }), { text: type }).text;
};
