import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isEqual, isNull } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { client } from '../../../../../api/Client';
import {
  Button,
  IconButton,
  MuiThemeProvider,
  Popover,
  TextField,
} from '@material-ui/core';
import {
  IconClose,
  IconDropDown,
  IconNewFilterSmall,
  IconNewFilterSmallActive,
} from '../../../../../common/icons/customIcons';
import {
  AttributeSelect,
  DefaultFilterPopover,
  FilterBtn,
  FilterBtnReset,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
} from './Filters.styled';

class Country extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      // country: this.props.currentValue ? this.props.currentValue.join('\n') : '',
      country: this.props.currentValue || null,
      error: '',
      currentValue: this.props.currentValue,
      // countries: [],
      search: this.props.currentValue || '',
    };
  }

  // componentDidMount() {
  //   this.requestCountries(null, this.props.currentValue || '');
  // }

  requestCountries = (_, search) => {
    const query = search ? `?q=${search}` : '';
    this.setState({ search });
    client()
      .get(`/dictionaries/isocountries${query}`)
      .then((res) => {
        this.setState({ countries: res });
      });
  };

  setSearch = (event) => {
    this.setState({ search: event.target.value });
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        country: '',
        error: '',
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state);
  }

  onValueChange = (_, e) => {
    e ?
      this.setState({
        country: e,
        error: '',
        search: e.text
      })
      : this.setState({
        country: '',
        error: '',
        search: ''
      });
  };

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAccept = () => {
    const country = this.state.country;
    if (!country) {
      return;
    }
    this.setState({
      anchorEl: null,
      currentValue: country.id,
    });
    this.props.onAccept('country', country.id);
  };

  validatecountrys = () => {
    let valid = true;
    return valid;
  };

  handleDissmiss = () => {
    this.setState({ anchorEl: null, country: '', error: '' });
    this.props.onDismiss('country');
  };

  render() {
    const { t, countries } = this.props;
    const { anchorEl, search, country } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <MuiThemeProvider theme={FilterBtn}>
          <FilterButton>
            <Button
              aria-owns={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
              {isNull(this.props.currentValue) ? (
                <IconNewFilterSmall />
              ) : (
                <IconNewFilterSmallActive />
              )}
            </Button>
          </FilterButton>
        </MuiThemeProvider>
        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Страна')}</h6>
              {/* <InputLabel htmlFor="simple-popper">
                    {t('Выберите статус')}
                  </InputLabel> */}
              <MuiThemeProvider theme={FilterBtn}>
                <IconButton className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </IconButton>
              </MuiThemeProvider>
            </FilterTitle>
            <FilterContent>
              <MuiThemeProvider theme={AttributeSelect}>
                <Autocomplete
                  style={{ width: '100%' }}
                  options={countries}
                  onChange={this.onValueChange}
                  autoHighlight
                  id="country"
                  getOptionLabel={(option) => option.text}
                  popupIcon={<IconDropDown />}
                  closeIcon={<IconClose style={{ width: 20, height: 20 }} />}
                  renderOption={(option) => (
                    <React.Fragment>{option.text}</React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Страна юридического адреса')}
                      variant="standard"
                      onChange={this.setSearch}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'country',
                        maxLength: 100,
                        value: search
                      }}
                    />
                  )}
                />
              </MuiThemeProvider>
            </FilterContent>
            <FilterBtns>
              <MuiThemeProvider theme={FilterBtnReset}>
                <Button
                  onClick={this.handleDissmiss}
                  disabled={!country}
                >
                  {t('Сбросить')}
                </Button>
              </MuiThemeProvider>
              <Button
                onClick={this.handleAccept}
                disabled={!country}
              >
                {t('Применить')}
              </Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </div>
    );
  }
}

Country.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
  parentId: PropTypes.string.isRequired,
  countries: PropTypes.array,
};

export default withTranslation()(Country);
