import * as React from 'react';
import { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  MuiThemeProvider,
  Popover,
  Tooltip,
} from '@material-ui/core';
import {
  IconNewFilter,
  IconCloseWhite,
} from '../../../common/icons/customIcons';
import { CustomInput } from '../../../components';
import GoodsFilter from './GoodsFilter';
import CategoriesFilter from './CategoriesFilter';
import MarkGropsFilter from './MarkGropsFilter';
import INNTypeFilter from './INNTypeFilter';
import {
  FilterTitle,
  defaultFilterInput,
  FilterPopover,
  FilterMainTitle,
  FilterMainContent,
  FilterMainBtns,
  FilterItemContent,
  FilterContentPopup,
  CancelFilterButton,
  FilterBtnReset,
  FilterBtnTitle,
} from './Filters.styled';
import { ResetFilterButton } from '../Companies.styled';

const FiltersCompaniesPopup = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({});
  const [errorInn, setErrorInn] = useState('');
  const { name, itn, address, hasGoods, hasMarkGroups, hasCategories } = filters;
  const { t } = props;
  const disabled = (
    !name &&
    !itn &&
    !address &&
    (hasGoods === null) &&
    (hasMarkGroups === null) &&
    (hasCategories === null)
  );
  const open = Boolean(anchorEl);

  const initializeFilters = () => {
    setFilters({
      name: _.defaultTo(props.currentFiltersValue.name, ''),
      itn: _.defaultTo(props.currentFiltersValue.itn, ''),
      address: _.defaultTo(props.currentFiltersValue.address, ''),
      hasGoods: _.defaultTo(props.currentFiltersValue.hasGoods, null),
      hasMarkGroups: _.defaultTo(props.currentFiltersValue.hasMarkGroups, null),
      hasCategories: _.defaultTo(props.currentFiltersValue.hasCategories, null),
    })
  };

  const onValueChange = (name, value) => {
    if (name === 'itn') {
      value = value.replace(/[^[0-9]/, '');
    }
    setFilters({ ...filters, [name]: value });
  };

  const handleClick = (event) => {
    let element = document.getElementById(props.parentId);
    setAnchorEl(!!element ? element : event.currentTarget);
    initializeFilters();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetFilters = () => {
    props.resetFilters();
    handleClose();
  };

  const clearFilters = () => {
    props.resetFilters();
  };

  const handleSubmit = () => {
    props.onFiltersSubmit({
      name: name ? name : null,
      itn: itn ? itn : null,
      address: address ? address : null,
      hasGoods: (hasGoods !== null) ? hasGoods : null,
      hasMarkGroups: (hasMarkGroups !== null) ? hasMarkGroups : null,
      hasCategories: (hasCategories !== null) ? hasCategories : null,
    })
    handleClose();
  };

  return (
    <div>
      <MuiThemeProvider theme={ResetFilterButton}>
        <Button
          aria-owns={open ? 'filter-popper' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{
            textTransform: 'none', padding: '10px 24px',
            outline: '1px solid #dcdcdc'
          }}>
          <IconNewFilter />
          <FilterBtnTitle>
            {t('Фильтры')}
          </FilterBtnTitle>
        </Button>
      </MuiThemeProvider>
      {!props.isNotActiveFilters && (
        <MuiThemeProvider theme={CancelFilterButton}>
          <Tooltip title={t('Сбросить фильтры')}>
            <Button
              onClick={clearFilters}
            >
              <IconCloseWhite />
            </Button>
          </Tooltip>
        </MuiThemeProvider>
      )}
      <MuiThemeProvider theme={FilterPopover}>
        <Popover
          id="filter-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <FilterMainTitle>
            {t('Фильтры')}
          </FilterMainTitle>
          <FilterMainContent>
            <FilterItemContent>
              <div style={{ padding: '0 36px' }}>
                <FilterTitle>
                  <h6>{t('Наименование участника системы')}</h6>
                </FilterTitle>
                <FilterContentPopup>
                  <MuiThemeProvider theme={defaultFilterInput}>
                    <CustomInput
                      label={t('Введите наименование')}
                      onChange={(value) => onValueChange('name', value)}
                      cleanButtonStyles={{ marginTop: -15, display: 'none' }}
                      onBlur={false}
                      value={filters.name}
                      name="name-filter"
                    />
                  </MuiThemeProvider>
                </FilterContentPopup>

                <INNTypeFilter
                  type='modal'
                  onValueChange={onValueChange}
                  currentValue={props.currentFiltersValue.itn}
                  setErrorInn={setErrorInn}
                />

                <FilterTitle>
                  <h6>{t('Юридический адрес')}</h6>
                </FilterTitle>
                <FilterContentPopup>
                  <MuiThemeProvider theme={defaultFilterInput}>
                    <CustomInput
                      cleanButtonStyles={{ marginTop: -15, display: 'none' }}
                      label={t('Юридический адрес')}
                      onChange={(value) => onValueChange('address', value)}
                      value={filters.address}
                      onBlur={false}
                      name="address-filter"
                    />
                  </MuiThemeProvider>
                </FilterContentPopup>
                <GoodsFilter
                  type='modal'
                  onValueChange={onValueChange}
                  currentValue={props.currentFiltersValue.hasGoods}
                />
                <MarkGropsFilter
                  type='modal'
                  onValueChange={onValueChange}
                  currentValue={props.currentFiltersValue.hasMarkGroups}
                />
                <CategoriesFilter
                  type='modal'
                  onValueChange={onValueChange}
                  currentValue={props.currentFiltersValue.hasCategories}
                />
              </div>
            </FilterItemContent>
          </FilterMainContent>
          <FilterMainBtns>
            <MuiThemeProvider theme={FilterBtnReset}>
              <Button
                onClick={resetFilters}
                disabled={disabled}
              >
                {t('Сбросить')}
              </Button>
            </MuiThemeProvider>
            <Button
              disabled={disabled || !!errorInn}
              onClick={handleSubmit}
            >
              {t('Применить')}
            </Button>
          </FilterMainBtns>
        </Popover>
      </MuiThemeProvider>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {},
    dispatch,
  );
};

FiltersCompaniesPopup.propTypes = {
  currentFiltersValue: PropTypes.object.isRequired,
  resetFilters: PropTypes.func.isRequired,
  onFiltersSubmit: PropTypes.func.isRequired,
  parentId: PropTypes.string.isRequired,
  isNotActiveFilters: PropTypes.bool.isRequired,
};

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps),
)(FiltersCompaniesPopup);
