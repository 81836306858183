import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
  deburr,
  defaultTo,
  filter,
  forEach,
  get,
  includes,
  isEmpty,
  isEqual,
  isNull,
  some,
  toLower,
  trim,
  toString,
} from 'lodash';
import {
  Button,
  FormControl,
  MenuItem,
  MuiThemeProvider,
  Popover,
  Tooltip,
  IconButton,
  TextField,
} from '@material-ui/core';
// import { Autocomplete } from '../../../../components';
import { Autocomplete } from '@material-ui/lab';
import {
  DefaultFilterPopover,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
  FilterTypingSelect,
  FilterBtnReset,
  FilterContentPopup,
  AttributeSelect,
} from './Filters.styled';
import { loadDictionaryGoodsCategory, clearDictionaryGoodsCategory } from '../../ducks/Products.actions';
import {
  IconClose,
  IconNewFilterSmall,
  IconNewFilterSmallActive,
  IconDropDown,
} from '../../../../common/icons/customIcons';
import * as selectors from '../ducks/ProductGrid.selectors';
class CategoryFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      category: defaultTo(this.props.currentValue, ''),
      currentValue: this.props.currentValue,
      suggestions: this.props.dictionaryCategory,
    };
  }

  componentDidMount() {
    if (this.props.type === 'modal' && isEmpty(this.props.dictionaryCategory)) {
      this.props.fetchDictionaryCategory();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.anchorEl !== prevState.anchorEl && this.state.anchorEl !== null) {
      this.props.fetchDictionaryCategory();
    }
    if (this.state.anchorEl !== prevState.anchorEl && this.state.anchorEl === null) {
      this.props.clearDictionaryGoodsCategory();
    }
    if (this.props.dictionaryCategory !== prevProps.dictionaryCategory) {
      this.setState({ suggestions: this.props.dictionaryCategory });
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        category: '',
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!isEqual(this.state, nextState)];
    const nameProps = ['dictionaryCategory', 'currentValue'];
    forEach(nameProps, (i) =>
      equal.push(!isEqual(this.props[i], nextProps[i])),
    );
    return some(equal);
  }

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      category: defaultTo(this.props.currentValue, ''),
    });
  };

  handleAccept = () => {
    this.setState({
      anchorEl: null,
      currentValue: this.state.category,
    });
    this.props.onAccept('category', this.state.category);
  };

  handleDissmiss = () => {
    this.setState({
      anchorEl: null,
      category: '',
    });
    this.props.onDismiss('category');
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <Tooltip placement="left" title={suggestion.text}>
        <MenuItem
          disableGutters={true}
          selected={isHighlighted}
          component="div">
          {suggestion.text}
        </MenuItem>
      </Tooltip>
    );
  };

  getSuggestionValue = (suggestion) => {
    this.setState({ category: suggestion });
    return suggestion.text;
  };

  getSuggestionValueModal = (suggestion) => { //504
    this.props.onValueChange('category', suggestion);
    return suggestion.text;
  };

  getSuggestions = (value) => {
    const inputValue = toLower(deburr(trim(value)));
    return inputValue.length === 0
      ? this.props.dictionaryCategory.slice(0, 30)
      : filter(this.props.dictionaryCategory, (item) =>
        includes(toLower(item.text), inputValue),
      );
  };

  handleSuggestionsFetchRequested = (value) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.props.dictionaryCategory.slice(0, 10),
      category: '',
    });
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <>
        {this.props.type !== 'modal' && (
          <div>
            <MuiThemeProvider theme={FilterBtn}>
              <FilterButton>
                <Button
                  aria-owns={open ? 'simple-popper' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}>
                  {isNull(this.props.currentValue) ? (
                    <IconNewFilterSmall />
                  ) : (
                    <IconNewFilterSmallActive />
                  )}
                </Button>
              </FilterButton>
            </MuiThemeProvider>
            <MuiThemeProvider theme={DefaultFilterPopover}>
              <Popover
                id="simple-popper"
                open={open}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                <FilterTitle>
                  <h6>{t('Классификация')}</h6>
                  <MuiThemeProvider theme={FilterBtn}>
                    <IconButton className="backEmpty" onClick={this.handleClose}>
                      <IconClose />
                    </IconButton>
                  </MuiThemeProvider>
                </FilterTitle>
                <FilterContent>
                  {/* <MuiThemeProvider theme={FilterTypingSelect}>
                    <FormControl fullWidth={true}>
                      <Autocomplete
                        suggestions={this.state.suggestions}
                        name="category"
                        placeholder={t('Выберите классификацию')}
                        handleSuggestionsFetchRequested={
                          this.handleSuggestionsFetchRequested
                        }
                        handleSuggestionsClearRequested={
                          this.handleSuggestionsClearRequested
                        }
                        renderSuggestion={this.renderSuggestion}
                        getSuggestionValue={this.getSuggestionValue}
                        initValue={get(this.state, 'currentValue.text', '')}
                        emptyLabel={false}
                      />
                    </FormControl>
                  </MuiThemeProvider> */}
                  {/* Заменен компонент c Autocomplete из /components на Autocomplete
                  из '@material-ui/lab' , в связи с тем, что его затруднительно было
                  стилизовать под требования нового дизайна
                  (в Firefox плейсхолдер не выравнивался по вертикали; во всех браузерах при наведении на иконку открытия списка не было эффекта осветления)) */}
                  <MuiThemeProvider theme={AttributeSelect}>
                    <FormControl fullWidth={true}>
                      <Autocomplete
                        id="category"
                        value={this.state.category}
                        options={this.state.suggestions}
                        autoHighlight
                        onChange={(event, newValue) => {
                          this.setState({ category: newValue })
                        }}
                        onInputChange={(event, newInputValue) => {
                          this.handleSuggestionsFetchRequested(newInputValue);
                        }}
                        getOptionLabel={(option) =>
                          toString(option.text) || ''
                        }
                        popupIcon={<IconDropDown />}
                        closeIcon={<IconClose style={{ width: 20, height: 20 }} />}
                        clearText=''
                        openText=''
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('Выберите классификацию')}
                            variant="standard"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'off',
                            }}
                          />
                        )}
                        noOptionsText={t('Нет совпадений')}
                      />
                    </FormControl>
                  </MuiThemeProvider>
                </FilterContent>
                <FilterBtns>
                  <MuiThemeProvider theme={FilterBtnReset}>
                    <Button
                      disabled={!this.state.category}
                      onClick={this.handleDissmiss}>
                      {t('Сбросить')}
                    </Button>
                  </MuiThemeProvider>
                  <Button
                    disabled={!this.state.category}
                    onClick={this.handleAccept}>
                    {t('Применить')}
                  </Button>
                </FilterBtns>
              </Popover>
            </MuiThemeProvider>
          </div>
        )}
        {this.props.type === 'modal' && ( //504
          <div>
            <FilterTitle>
              <h6>{t('Классификация')}</h6>
            </FilterTitle>
            <FilterContentPopup>
              {/* <MuiThemeProvider theme={FilterTypingSelect}>
                <FormControl fullWidth={true}>
                  <Autocomplete
                    suggestions={this.state.suggestions}
                    name="category"
                    placeholder={t('Выберите классификацию')}
                    handleSuggestionsFetchRequested={
                      this.handleSuggestionsFetchRequested
                    }
                    handleSuggestionsClearRequested={
                      this.handleSuggestionsClearRequested
                    }
                    renderSuggestion={this.renderSuggestion}
                    getSuggestionValue={this.getSuggestionValueModal}
                    initValue={get(this.state, 'currentValue.text', '')}
                    emptyLabel={false}
                  />
                </FormControl>
              </MuiThemeProvider> */}
              <MuiThemeProvider theme={AttributeSelect}>
                <FormControl fullWidth={true}>
                  <Autocomplete
                    id="category-modal"
                    value={this.state.category}
                    options={this.state.suggestions}
                    autoHighlight
                    onChange={(event, newValue) => {
                      this.setState({ category: newValue });
                      this.props.onValueChange('category', newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      this.handleSuggestionsFetchRequested(newInputValue);
                    }}
                    getOptionLabel={(option) =>
                      toString(option.text) || ''
                    }
                    popupIcon={<IconDropDown />}
                    closeIcon={<IconClose style={{ width: 20, height: 20 }} />}
                    clearText=''
                    openText=''
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('Выберите классификацию')}
                        variant="standard"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                        }}
                      />
                    )}
                    noOptionsText={t('Нет совпадений')}
                  />
                </FormControl>
              </MuiThemeProvider>
            </FilterContentPopup>
          </div>
        )}
      </>
    );
  }
}

CategoryFilter.propTypes = {
  onAccept: PropTypes.func,
  onDismiss: PropTypes.func,
  fetchDictionaryCategory: PropTypes.func.isRequired,
  dictionaryCategory: PropTypes.array.isRequired,
  currentValue: PropTypes.object,
  parentId: PropTypes.string,
  onValueChange: PropTypes.func,
  type: PropTypes.string,
};

const mapStateToProps = (state) => ({
  dictionaryCategory: selectors.dictionaryCategory(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDictionaryCategory: () => loadDictionaryGoodsCategory(),
      clearDictionaryGoodsCategory: () => clearDictionaryGoodsCategory(),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(CategoryFilter);
