import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { push, goBack } from 'connected-react-router';
import {
  forEach,
  get,
  head,
  isArray,
  isEmpty,
  isNil,
  isString,
  omit,
  reject,
} from 'lodash';
import { client } from '../../../api/Client';
import { config } from '../../../config';
import i18n from '../../../i18n';
import { throwNotification } from '../../../common/structure';
import {
  CLEAR_COPY_OF_PRODUCT_ACTION,
  CLEAR_TRANSACTION_ACTION,
  CREATE_CARD_ITEM_ACTION,
  CREATE_CONSUMER_ITEM_ACTION,
  DOWNLOAD_PRODUCT_ACTION,
  GENERATE_GTIN_ACTION,
  GET_SUGGESTIONS_ACTION,
  LOAD_CARD_ITEM_ACTION,
  LOAD_CATALOG_CARD_ITEM_ACTION,
  LOAD_CARD_ITEM_VERSION_ACTION,
  LOAD_MODAL_ACTION,
  LOAD_MODERATE_ERRORS_ACTION,
  MODERATE_PRODUCT_ACTION,
  SAVE_PRODUCT_ACTION,
  SEARCH_STATE_CERTIFICATE_ACTION,
  VALIDATION_GTIN_ACTION,
  VALIDATION_GTIN_PACKAGE_ACTION,
  LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_ACTION,
  VALIDATION_GROUP_AND_TRANSPORT_GTIN_ACTION,
  LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_ACTION,
  LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_ACTION,
  VALIDATION_UNREGISTERED_GTIN_ACTION,
  CHECK_TNVED_FOR_MARKING_ACTION,
  LOAD_PHOTO_TYPES_ACTION,
  LOAD_TECHNICAL_OPTIONS_ACTION,
  LOAD_TECHNICAL_CATEGORIES_ACTION,
  CREATE_TECHNICAL_ITEM_ACTION,
  VALIDATION_TECHNICAL_CARD_ACTION,
  LOAD_COUNTRIES_ACTION,
  CHECK_ACCOUNT_CAN_CREATE_GTIN_ACTION,
  LOAD_ACCOUNT_GCP_LIST_ACTION,
  CHECK_AVAILABILITY_CREATION_GTIN_ACTION,
  CREATE_GTIN_ACTION,
  LOAD_TECHNICAL_CATEGORIES_UNMARKABLE_ACTION,
} from './Product.constants';
import {
  createCardItemFailed,
  createCardItemSucceeded,
  loadCardItemFailed,
  loadCardItemSucceeded,
  loadModerateErrorsFailed,
  loadModerateErrorsSucceeded,
  saveProductSucceeded,
  searchStateCertificateFailed,
  searchStateCertificateSucceeded,
  loadGcpConsumerPackageDictionarySucceeded,
  loadGcpConsumerPackageDictionaryFailed,
  createConsumerPackagingFailed,
  createConsumerPackagingSucceeded,
  loadDictionaryGpcConsumerPackageSucceeded,
  loadDictionaryGpcConsumerPackageFailed,
  loadDictionaryTnvedConsumerPackageSucceeded,
  loadDictionaryTnvedConsumerPackageFailed,
  checkTnvedForMarkingSucceeded,
  checkTnvedForMarkingFailed,
  loadPhtotoTypesSucceeded,
  loadPhtotoTypesFailed,
  loadTechnicalOptionsSucceeded,
  loadTechnicalOptionsFailed,
  loadTechnicalCategoriesSucceeded,
  loadTechnicalCategoriesFailed,
  createTechnicalPackagingSucceeded,
  createTechnicalPackagingFailed,
  loadCountriesSucceeded,
  loadCountriesFailed,
  checkAccountCanCreateGtinSucceeded,
  checkAccountCanCreateGtinFailed,
  loadAccountGcpListSucceeded,
  loadAccountGcpListFailed,
  checkAvailabilityCreationGtinSucceeded,
  checkAvailabilityCreationGtinFailed,
  createGtinSucceeded,
  createGtinFailed,
  loadTechnicalCategoriesUnmarkableFailed,
  loadTechnicalCategoriesUnmarkableSucceeded,
} from './Product.actions';
import { handleErrorNotification } from '../../ducks/HandleErrors';
import { insertParam } from '../../../common/utils/utils';
import { tnvedArrayForMarking } from './Product.selectors';

export function* generationGtin(action) {
  const errT = i18n.t('У аккаунта превышен лимит создания кодов товара');
  const errT1 = i18n.t('Ошибка генерации кода товара');
  const succ = i18n.t('Новый код товара для данного товара удачно сформирован');
  try {
    const response = yield call(client().post, 'gtin/generate', {
      ...action.data,
    });
    if (isString(response)) {
      yield put(push('/500'));
      return;
    }

    if (response.error) {
      if (get(response, 'error.response.status') === 403) {
        yield put(throwNotification(errT, 'error'));
        action.cbk({ error: errT });
      } else if (!isEmpty(get(response, 'error.response.data.errors'))) {
        action.cbk({ error: errT1 });
        yield handleErrorNotification(response.error);
      } else {
        action.cbk({ error: errT1 });
      }
    } else {
      action.cbk(null, response.gtin);
      yield put(throwNotification(succ, 'info'));
    }

  } catch (e) {
    action.cbk({ error: errT1 });
  }
}

export function* createCardItem(action) {
  const errT = i18n.t('Ошибка создания карточки товара!');
  try {
    const url =
      action.mode === 'technical' ? '/draft/generate-remainder' : '/drafts';

    const response = yield call(client().post, url, action.data);
    if (isString(response) || response.error) {
      action.cbk({ error: errT }, null);
      yield handleErrorNotification(response.error);
      yield put(createCardItemFailed());
    } else {
      action.cbk(null, response.id);
      yield put(createCardItemSucceeded(response.id));
    }
  } catch (e) {
    action.cbk({ error: errT });
    yield put(createCardItemFailed());
  }
}

export function* loadCardItem(action) {
  const errT = i18n.t('Ошибка открытия карточки товара!');
  try {
    const response = yield call(client().get, `${action.dtoName}/${action.id}`);
    if (isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadCardItemFailed());
    } else {
      let data = response;
      if (isEmpty(get(response, 'errors', []))) {
        data = omit(response, ['errors']);
      }
      yield put(loadCardItemSucceeded(data));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadCardItemFailed());
  }
}

export function* loadCatalogCardItem(action) {
  const errT = i18n.t('Ошибка открытия карточки товара!');
  try {
    const response = yield call(client().get, `/consumer/goods/${action.id}`);
    if (isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadCardItemFailed());
    } else {
      let data = response;
      if (isEmpty(get(response, 'errors', []))) {
        data = omit(response, ['errors']);
      }
      yield put(loadCardItemSucceeded(data));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadCardItemFailed());
  }
}

export function* loadCardItemVersion(action) {
  const errT = i18n.t('Ошибка открытия версии карточки товара!');
  try {
    const response = yield call(
      client().get,
      `/goods/version/${action.id}/${action.versionId}`,
    );
    if (isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadCardItemFailed());
    } else {
      yield put(loadCardItemSucceeded(response));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadCardItemFailed());
  }
}

export function* loadModerateErrors(action) {
  const errT = i18n.t('Ошибка загрузки данных об ошибках карточки!');
  try {
    const response = yield call(client().get, `/draft/errors/${action.id}`);
    if (isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadModerateErrorsFailed());
    } else {
      yield put(loadModerateErrorsSucceeded(response));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadModerateErrorsFailed());
  }
}

export function* validateGtin(action) {
  const errT = i18n.t('Ошибка при валидации кода товара!');
  try {
    const response = yield call(client().post, 'validate-consumer-package', {
      ...action.data,
    });
    // const response = yield call(client().post, 'gtin/validate', {...action.data});
    if (response.error) {
      yield handleErrorNotification(response.error);
      const validationErrors = get(
        response,
        'error.response.data.validationErrors',
        null,
      );
      const systemError = get(
        response,
        'error.response.data.systemError',
        null,
      );
      const usersError = get(response, 'error.response.data.errors', null);
      if (!isNil(validationErrors)) {
        action.cbk({ error: validationErrors }, false, null);
      } else {
        if (!isNil(systemError)) {
          action.cbk({ error: errT }, false, null);
        } else {
          action.cbk(
            { error: { gtin: head(usersError) || null } },
            false,
            null,
          );
        }
      }
    } else {
      action.cbk(null, true, response);
    }
  } catch (errors) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: errT }, false);
  }
}

export function* validateGtinPackage(action) {
  const errT = i18n.t('Ошибка при валидации кода товара!');
  try {
    const response = yield call(client().post, 'gtin/validate-package', {
      gtin: action.gtin,
      packageLevel: action.level,
    });
    if (response.error) {
      yield handleErrorNotification(response.error);
      const validationErrors = get(
        response,
        'error.response.data.validationErrors',
        null,
      );
      const systemError = get(
        response,
        'error.response.data.systemError',
        null,
      );
      const usersError = get(response, 'error.response.data.errors', null);
      if (!isNil(validationErrors)) {
        action.cbk({ error: validationErrors }, false);
      } else {
        if (!isNil(systemError)) {
          action.cbk({ error: errT }, false);
        } else {
          action.cbk({ error: { gtin: head(usersError) || null } }, false);
        }
      }
    } else {
      if (response.goodDraftId) {
        action.cbk(
          {
            error: `${i18n.t('Введенный код товара присвоен другой упаковке')}. 
                    ${i18n.t(
              'Пожалуйста, сгенерируйте или введите новое значение',
            )}`,
          },
          false,
        );
      } else {
        action.cbk(null, true);
      }
    }
  } catch (errors) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: errT }, false);
  }
}

export function* validateGroupAndTransportGtin(action) {
  const errT = i18n.t('Ошибка при валидации кода товара!');
  try {
    const response = yield call(
      client().post,
      '/validate-group-transport-package',
      { ...action.data },
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      const validationErrors = get(
        response,
        'error.response.data.validationErrors',
        null,
      );
      const systemError = get(
        response,
        'error.response.data.systemError',
        null,
      );
      const usersError = get(response, 'error.response.data.errors', null);
      if (!isNil(validationErrors)) {
        action.cbk({ error: validationErrors }, false, null);
      } else {
        if (!isNil(systemError)) {
          action.cbk({ error: errT }, false, null);
        } else {
          action.cbk(
            { error: { gtin: head(usersError) || null } },
            false,
            null,
          );
        }
      }
    } else {
      action.cbk(null, true, response);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: errT }, false);
  }
}

export function* validateUnregisteredGtin(action) {
  const errT = i18n.t('Ошибка при валидации кода товара!');
  try {
    const response = yield call(
      client().post,
      '/validate-group-nested-unregistered-gtin',
      action.data,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      const validationErrors = get(
        response,
        'error.response.data.validationErrors',
        null,
      );
      const systemError = get(
        response,
        'error.response.data.systemError',
        null,
      );
      const usersError = get(response, 'error.response.data.errors', null);
      if (!isNil(validationErrors)) {
        action.cbk({ error: validationErrors }, false, null);
      } else {
        if (!isNil(systemError)) {
          action.cbk({ error: errT }, false, null);
        } else {
          action.cbk(
            { error: { gtin: head(usersError) || null } },
            false,
            null,
          );
        }
      }
    } else {
      action.cbk(null, true, response);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: errT }, false);
  }
}

export function* getAutocompleteSuggestions(action) {
  const errT = i18n.t('Ошибка получения предположений для автозаполнения');
  if (action.url) {
    try {
      const url = insertParam(action.url, 'q', action.value);
      const response = yield call(client().get, url);

      if (isString(response) || response.error) {
        yield handleErrorNotification(response.error, errT);
        action.cbk({ error: errT });
      } else {
        const suggestions = isArray(response) ? response : [];
        action.cbk(null, suggestions);
      }
    } catch (e) {
      yield put(throwNotification(errT, 'error'));
      action.cbk({ error: errT });
    }
  }
}

export function* clearTransaction(action) {
  const errT = i18n.t('Ошибка освобождения сгенерированнного кода товара');

  try {
    const response = yield call(client().post, '/gtin/unset', action.gtin);
    if (isString(response) || response.error) {
      yield handleErrorNotification(response.error, errT);
      action.cbk({ error: errT });
    } else {
      action.cbk(null);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: errT });
  }
}

export function* clearCopyOfProduct(action) {
  const errT = i18n.t('Ошибка удаления черновика');

  try {
    const response = yield call(client().delete, `/draft/${action.id}`);
    if (isString(response) || response.error) {
      yield handleErrorNotification(response.error, errT);
      action.cbk({ error: errT });
    } else {
      action.cbk(null);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: errT });
  }
}

export function* saveProduct(action) {
  try {
    const draftId = action.data.draftId;
    const formData = new FormData();
    const formDataHasWatermark = new FormData();
    const imagesCollections = reject(action.data.images, (i) => !i.url);
    let isUpload = false;
    let isHasWatermark = false;
    let isNotHasWatermark = false;

    forEach(imagesCollections, (image) => {
      if (!!image.url && !isString(image.url)) {
        if (image.hasWatermark === 1) {
          formDataHasWatermark.append('files[]', image.url);
          isUpload = true;
          isHasWatermark = true;
          return;
        }
        else formData.append('files[]', image.url);
        isUpload = true;
        isNotHasWatermark = true;
      }
    });

    let urls;
    let urlsWatermark;
    let urlsNotWatermark;
    if (isUpload) {
      if (formDataHasWatermark && isHasWatermark) {
        formDataHasWatermark.append('hasWatermark', 1);
        urlsWatermark = yield call(
          client().post,
          `/draft/uploadphoto/${draftId}`,
          formDataHasWatermark,
          {
            headers: { 'content-type': 'multipart/form-data' },
          },
        );
      }
      if (formData && isNotHasWatermark) {
        urlsNotWatermark = yield call(
          client().post,
          `/draft/uploadphoto/${draftId}`,
          formData,
          {
            headers: { 'content-type': 'multipart/form-data' },
          },
        );
      }
    }
    let indexUrl = 0;
    let indexUrlNotWatermark = 0;
    const images = [];
    forEach(imagesCollections, (image) => {
      if (!!image.url && isString(image.url) && !isEmpty(image.url)) {
        images.push({
          url: image.url,
          gtin: image.gtin,
          type: image.type,
          hasWatermark: image.hasWatermark,
        });
      } else {
        if (image.hasWatermark) {
          const n = indexUrl++;
          if (!!urlsWatermark.url[n]) {
            images.push({
              url: urlsWatermark.url[n],
              gtin: image.gtin,
              type: image.type,
              hasWatermark: image.hasWatermark,
            });
          }
        }
        else {
          const n = indexUrlNotWatermark++;
          if (!!urlsNotWatermark.url[n]) {
            images.push({
              url: urlsNotWatermark.url[n],
              gtin: image.gtin,
              type: image.type,
              hasWatermark: image.hasWatermark,
            });
          }
        }
      }
    });
    action.data.images = images;
    const response = yield call(client().put, `/draft/${draftId}`, action.data);
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        i18n.t('Ошибка сохранения черновика с id', { id: draftId }),
      );
      if (action.cbk && action.mode === 'moderate') {
        action.cbk(response.error);
      }
      if (action.mode === 'save') {
        action.cbk();
      }
    } else {
      if (action.cbk && (action.mode === 'moderate')) {
        action.cbk(null);
      } else {
        yield put(saveProductSucceeded(response));
        yield put(throwNotification(i18n.t('Изменения сохранены'), 'info'));
        action.cbk();
      }
    }
  } catch (e) {
    yield put(
      throwNotification(i18n.t('Ошибка сохранения черновика'), 'error'),
    );
    if (action.mode === 'save') {
      action.cbk();
    } else action.cbk({ error: e });
  }
}

export function* moderateProduct(action) {
  const errT = i18n.t('Ошибка отправки черновика на модерацию');
  try {
    const response = yield call(client().post, `/draft/send/${action.draftId}`);
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        i18n.t('Ошибка отправки на модерацию черновика с id', {
          id: action.draftId,
        }),
      );
      action.cbk(response.error);
    } else {
      action.cbk(null);
    }
  } catch (e) {
    action.cbk({ error: e });
    yield put(throwNotification(errT, 'error'));
  }
}

export function* searchStateCertificate(action) {
  const errT = i18n.t(
    'Ошибка получения результатов поиска Сертификатов о государственной регистрации',
  );
  try {
    const response = yield call(
      client().post,
      '/dictionaries/sr-certificates',
      action.data,
    );
    if (response.error) {
      yield handleErrorNotification(response.error, errT);
      yield put(searchStateCertificateFailed());
      action.cbk({ error: response.error });
    } else {
      yield put(searchStateCertificateSucceeded(response.certificates));
      action.cbk(null);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
  }
}

export function* loadModal(action) {
  const errT = i18n.t('Ошибка получения информации о товаре с id', {
    id: action.productId,
  });
  try {
    const response = yield call(
      client().get,
      `/draft/modal-data/${action.productId}`,
    );
    if (isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error, errT);
      action.cbk({ error: errT });
    } else {
      action.cbk(null, response);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
  }
}

export function* downloadProduct(action) {
  try {
    const response = [];
    //const response = yield call(client().post, '???', JSON.stringify(params), {responseType: 'arraybuffer'});
    if (response.error) {
      yield handleErrorNotification(response.error);
      action.cbk(response.error);
    } else {
      action.cbk(null, response);
    }
  } catch (e) {
    action.cbk({ error: e });
  }
}

// возможно удалить.
export function* loadGcpConsumerPackageDictionary() {
  const errT = i18n.t('Ошибка получения справочника GCP!');
  try {
    const response = yield call(client().get, '/dictionaries/gpc');
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadGcpConsumerPackageDictionaryFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadGcpConsumerPackageDictionarySucceeded(
          isArray(response) ? response : [],
        ),
      );
    }
  } catch (e) {
    yield put(loadGcpConsumerPackageDictionaryFailed());
  }
}

export function* createConsumerPackaging(action) {
  const errT = i18n.t('Ошибка создания потребительской упаковки!');
  try {
    const url = '/draftspackage';

    const response = yield call(client().post, url, action.data);
    const validationErrors = get(
      response,
      'error.response.data.validationErrors',
      null,
    );

    if (validationErrors) {
      yield put(throwNotification(validationErrors.gtin, 'error'));
      yield put(createConsumerPackagingFailed());
      yield put(goBack());
    } else if (isString(response) || response.error) {
      action.cbk({ error: errT }, null);
      yield handleErrorNotification(response.error);
      yield put(createConsumerPackagingFailed());
      yield put(push('/500'));
    } else {
      action.cbk(null, response.id);
      yield put(createConsumerPackagingSucceeded(response.id));
      yield put(push(`${config.urls.product}/${response.id}`)); //костыль.
    }
  } catch (e) {
    action.cbk({ error: errT });
    yield put(createConsumerPackagingFailed());
    yield put(push('/500'));
  }
}

export function* loadDictionaryGpcConsumerPackage(action) {
  const errT = i18n.t('Ошибка получения справочника GPС!');
  try {
    const response = yield call(client().get, `/gs1/bricks/gpc/${action.data}`);
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryGpcConsumerPackageFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryGpcConsumerPackageSucceeded(
          isArray(response) ? response : [],
        ),
      );
    }
  } catch (e) {
    yield put(loadDictionaryGpcConsumerPackageFailed());
  }
}

export function* loadDictionaryTnvedConsumerPackage(action) {
  const errT = i18n.t('Ошибка получения справочника TNVED!');
  try {
    const response = yield call(
      client().get,
      `/gs1/bricks/tnved/${action.data}`,
    );
    if (response.error) {
      yield handleErrorNotification(
        response.error,
        `${errT} Error: ${response.error}`,
      );
      yield put(loadDictionaryTnvedConsumerPackageFailed());
    } else {
      if (!isArray(response)) {
        yield put(throwNotification(errT, 'error'));
      }
      yield put(
        loadDictionaryTnvedConsumerPackageSucceeded(
          isArray(response) ? response : [],
        ),
      );
    }
  } catch (e) {
    yield put(loadDictionaryTnvedConsumerPackageFailed());
  }
}

export function* checkTnvedforMarking(action) {
  const errT = i18n.t(
    'Ошибка получения справочника TNVED! Невозможно определить отношение к маркировке',
  );
  let filteredArray;
  try {
    const { _attruid, value } = action.data;
    const response = yield call(
      client().get,
      `/gs1/bricks/tnved-marked?q=${value}`,
    );
    if (isString(response) || get(response, 'error.response.status') === 500) {
      yield put(throwNotification(errT, 'error'));
      yield put(checkTnvedForMarkingFailed());
      return;
    } else {
      const tnvedArray = yield select(tnvedArrayForMarking);
      filteredArray = tnvedArray.filter((label) => label.uiId !== _attruid);
      filteredArray = [
        ...filteredArray,
        { uiId: _attruid, marked: Number(response[0].marked) },
      ];
      yield put(checkTnvedForMarkingSucceeded(filteredArray));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'errorRed'));
    yield put(checkTnvedForMarkingFailed());
  }
}

export function* loadPhotoTypes() {
  const errT = i18n.t('Ошибка получения справочника "Ракурсы"');

  try {
    const response = yield call(client().get, `/dictionaries/photo-types`);
    if (response.error) {
      yield handleErrorNotification(response.error);
      return yield put(loadPhtotoTypesFailed());
    }
    if (isString(response)) {
      yield put(throwNotification(errT, 'error'));
      return yield put(loadPhtotoTypesFailed());
    }
    yield put(loadPhtotoTypesSucceeded(response));
  } catch (e) {
    yield put(throwNotification(errT, 'errorRed'));
    yield put(loadPhtotoTypesFailed());
  }
}

export function* loadTechnicalCategoriesUnmarkable() {
  const errT = i18n.t('Ошибка получения категорий');

  try {
    const response = yield call(client().get, `/categories/technical/unmarkable`);
    if (response.error) {
      yield handleErrorNotification(response.error);
      return yield put(loadTechnicalCategoriesUnmarkableFailed());
    }
    if (isString(response)) {
      yield put(throwNotification(errT, 'error'));
      return yield put(loadTechnicalCategoriesUnmarkableFailed());
    }
    yield put(loadTechnicalCategoriesUnmarkableSucceeded(response));
  } catch (e) {
    yield put(throwNotification(errT, 'errorRed'));
    yield put(loadTechnicalCategoriesUnmarkableFailed());
  }
}

export function* loadTechnicalOptions() {
  const errT = i18n.t('Ошибка получения опций');

  try {
    const response = yield call(client().get, `/draft/technical-options`);
    if (response.error) {
      yield handleErrorNotification(response.error);
      return yield put(loadTechnicalOptionsFailed());
    }
    if (isString(response)) {
      yield put(throwNotification(errT, 'error'));
      return yield put(loadTechnicalOptionsFailed());
    }
    yield put(loadTechnicalOptionsSucceeded(response));
  } catch (e) {
    yield put(throwNotification(errT, 'errorRed'));
    yield put(loadTechnicalOptionsFailed());
  }
}

export function* loadTechnicalCategories() {
  const errT = i18n.t('Ошибка получения категорий');

  try {
    const response = yield call(client().get, `/categories/technical`);
    if (response.error) {
      yield handleErrorNotification(response.error);
      return yield put(loadTechnicalCategoriesFailed());
    }
    if (isString(response)) {
      yield put(throwNotification(errT, 'error'));
      return yield put(loadTechnicalCategoriesFailed());
    }
    yield put(loadTechnicalCategoriesSucceeded(response));
  } catch (e) {
    yield put(throwNotification(errT, 'errorRed'));
    yield put(loadTechnicalCategoriesFailed());
  }
}

export function* createTechnicalPackaging(action) {
  const isUnmark = action.data.isUnmark
  const errT = i18n.t('Ошибка создания технической карточки!');
  try {
    const url = 'draft/technical';

    const response = yield call(client().post, url, action.data);
    const validationErrors = get(
      response,
      'error.response.data.validationErrors',
      null,
    );

    if (validationErrors) {
      yield put(throwNotification(validationErrors.gtin, 'error'));
      yield put(createTechnicalPackagingFailed());
      yield put(goBack());
    } else if (isString(response) || response.error) {
      action.cbk({ error: errT }, null);
      yield handleErrorNotification(response.error);
      yield put(createTechnicalPackagingFailed());
      yield put(push(`${config.urls.product}/goods/${response.goodId}`));
      //yield put(push('/500'));
    } else if (response.goodId) {
      action.cbk(null, response.goodId);
      yield put(createTechnicalPackagingSucceeded(response.goodId));
      yield put(push(`${config.urls.product}/goods/${response.goodId}`)); //костыль.
    }
    else {
      yield put(createTechnicalPackagingSucceeded(response));
      yield put(push(`${config.urls.products}`)); //костыль.
      isUnmark ? (
        yield put(throwNotification(i18n.t(action.data.count >= 2
          ? 'Создание технической карточки немаркируемого товара поставлено в очередь на обработку'
          : 'Создание технической карточки немаркируемого товара поставлено в очередь на обработку'), 'info'))
      ) : (
        yield put(throwNotification(i18n.t(action.data.count >= 2
          ? 'Создание технических карточек поставлено в очередь на обработку'
          : 'Создание технической карточки поставлено в очередь на обработку'), 'info'))
      )
    }
  } catch (e) {
    action.cbk({ error: errT });
    yield put(createTechnicalPackagingFailed());
    //yield put(push('/500'));
  }
}

export function* validateTechnicalCard(action) {
  const errT = i18n.t('Ошибка при валидации кода товара!');
  try {
    const response = yield call(client().post, 'validate-technical-package', {
      ...action.data,
    });
    // const response = yield call(client().post, 'gtin/validate', {...action.data});
    if (response.error) {
      yield handleErrorNotification(response.error);
      const validationErrors = get(
        response,
        'error.response.data.validationErrors',
        null,
      );
      const systemError = get(
        response,
        'error.response.data.systemError',
        null,
      );
      const usersError = get(response, 'error.response.data.errors', null);
      if (!isNil(validationErrors)) {
        action.cbk({ error: validationErrors }, false, null);
      } else {
        if (!isNil(systemError)) {
          action.cbk({ error: errT }, false, null);
        } else {
          action.cbk(
            { error: { gtin: head(usersError) || null } },
            false,
            null,
          );
        }
      }
    } else {
      action.cbk(null, true, response);
    }
  } catch (errors) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: errT }, false);
  }
}

export function* loadCountries() {
  const errT = i18n.t('Ошибка получения стран');

  try {
    const response = yield call(client().get, `/countries`);
    if (response.error) {
      yield handleErrorNotification(response.error);
      return yield put(loadCountriesFailed());
    }
    if (isString(response)) {
      yield put(throwNotification(errT, 'error'));
      return yield put(loadCountriesFailed());
    }
    yield put(loadCountriesSucceeded(response));
  } catch (e) {
    yield put(throwNotification(errT, 'errorRed'));
    yield put(loadCountriesFailed());
  }
}

export function* checkAccountCanCreateGtin() {
  try {
    const response = yield call(
      client().post,
      `/gtin/check-account-can-create-gtin`,
    );
    if (isString(response) || response.error) {
      yield put(checkAccountCanCreateGtinFailed());
      return;
    } else {
      yield put(checkAccountCanCreateGtinSucceeded());
    }
  } catch (e) {
    yield put(checkAccountCanCreateGtinFailed());
  }
}

export function* loadAccountGcpList() {
  try {
    const response = yield call(
      client().get,
      `/account/gcp-list`,
    );
    if (isString(response) || response.error) {
      yield put(loadAccountGcpListFailed());
      return;
    } else {
      yield put(loadAccountGcpListSucceeded(response));
    }
  } catch (e) {
    yield put(loadAccountGcpListFailed());
  }
}

export function* checkAvailabilityCreationGtin(action) {
  try {
    const response = yield call(
      client().post,
      `/gtin/check-availability-creation-gtin`,
      { ...action.data }
    );
    if (isString(response) || response.error) {
      yield put(checkAvailabilityCreationGtinFailed());
      if ((get(response, 'error.response.status') === 400)
        && !isEmpty(get(response, 'error.response.data.errors'))) {
        action.cbk(response.error.response.data.errors[0]);
      } else action.cbk(null);
      return;
    } else {
      yield put(checkAvailabilityCreationGtinSucceeded());
      action.cbk(null);
    }
  } catch (e) {
    yield put(checkAvailabilityCreationGtinFailed());
    action.cbk(null);
  }
}

export function* createGtin(action) {
  const errT = i18n.t('Ошибка генерации кода товара');

  try {
    const response = yield call(
      client().post,
      `/gtin/create`,
      { ...action.data }
    );

    if (isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      if (!isEmpty(get(response, 'error.response.data.errors'))) {
        yield put(throwNotification(response.error.response.data.errors[0], 'error'));
        action.cbk();
      } else {
        action.cbk();
        yield put(throwNotification(errT, 'error'));
      }
      yield put(createGtinFailed());
      return;
    } else {
      action.cbk();
      yield put(createGtinSucceeded(response));
    }
  } catch (e) {
    action.cbk();
    yield put(createGtinFailed());
    yield put(throwNotification(errT, 'errorRed'));
  }
}

export function* watchCreateNewCard() {
  yield takeEvery(CREATE_CARD_ITEM_ACTION, createCardItem);
}

export function* watchCreateNewConsumerCard() {
  yield takeEvery(CREATE_CONSUMER_ITEM_ACTION, createConsumerPackaging);
}

export function* watchGenerationGtin() {
  yield takeEvery(GENERATE_GTIN_ACTION, generationGtin);
}

export function* watchValidateGtin() {
  yield takeEvery(VALIDATION_GTIN_ACTION, validateGtin);
}

export function* watchvalidateGtinPackage() {
  yield takeEvery(VALIDATION_GTIN_PACKAGE_ACTION, validateGtinPackage);
}

export function* watchGetSuggestions() {
  yield takeEvery(GET_SUGGESTIONS_ACTION, getAutocompleteSuggestions);
}

export function* watchClearTransaction() {
  yield takeEvery(CLEAR_TRANSACTION_ACTION, clearTransaction);
}

export function* watchClearCopyOfProduct() {
  yield takeEvery(CLEAR_COPY_OF_PRODUCT_ACTION, clearCopyOfProduct);
}

export function* watchSaveProduct() {
  yield takeEvery(SAVE_PRODUCT_ACTION, saveProduct);
}

export function* watchLoadProduct() {
  yield takeEvery(LOAD_CARD_ITEM_ACTION, loadCardItem);
}

export function* watchLoadCatalogProduct() {
  yield takeEvery(LOAD_CATALOG_CARD_ITEM_ACTION, loadCatalogCardItem);
}

export function* watchLoadModerateErrors() {
  yield takeEvery(LOAD_MODERATE_ERRORS_ACTION, loadModerateErrors);
}

export function* watchLoadProductVersion() {
  yield takeEvery(LOAD_CARD_ITEM_VERSION_ACTION, loadCardItemVersion);
}

export function* watchSearchStateCertificate() {
  yield takeEvery(SEARCH_STATE_CERTIFICATE_ACTION, searchStateCertificate);
}

export function* watchModerateProduct() {
  yield takeEvery(MODERATE_PRODUCT_ACTION, moderateProduct);
}

export function* watchLoadModal() {
  yield takeEvery(LOAD_MODAL_ACTION, loadModal);
}

export function* watchDownloadProduct() {
  yield takeEvery(DOWNLOAD_PRODUCT_ACTION, downloadProduct);
}

export function* watchLoadGcpDictionary() {
  yield takeEvery(
    LOAD_DICTIONARY_GCP_CONSUMER_PACKAGE_ACTION,
    loadGcpConsumerPackageDictionary,
  );
}

export function* watchValidationGroupAndTransportGtin() {
  yield takeEvery(
    VALIDATION_GROUP_AND_TRANSPORT_GTIN_ACTION,
    validateGroupAndTransportGtin,
  );
}

export function* watchLoadDictionaryGpcConsumerPackage() {
  yield takeEvery(
    LOAD_DICTIONARY_GPC_CONSUMER_PACKAGE_ACTION,
    loadDictionaryGpcConsumerPackage,
  );
}

export function* watchLoadDictionaryTnvedConsumerPackage() {
  yield takeEvery(
    LOAD_DICTIONARY_TNVED_CONSUMER_PACKAGE_ACTION,
    loadDictionaryTnvedConsumerPackage,
  );
}

export function* watchValidationUnregisteredGtin() {
  yield takeEvery(
    VALIDATION_UNREGISTERED_GTIN_ACTION,
    validateUnregisteredGtin,
  );
}

export function* watchCheckTnvedForMarking() {
  yield takeEvery(CHECK_TNVED_FOR_MARKING_ACTION, checkTnvedforMarking);
}

export function* watchLoadPhotoTypes() {
  yield takeEvery(LOAD_PHOTO_TYPES_ACTION, loadPhotoTypes);
}

export function* watchloadTechnicalCategoriesUnmarkable() {
  yield takeEvery(LOAD_TECHNICAL_CATEGORIES_UNMARKABLE_ACTION, loadTechnicalCategoriesUnmarkable);
}

export function* watchloadTechnicalOptions() {
  yield takeEvery(LOAD_TECHNICAL_OPTIONS_ACTION, loadTechnicalOptions);
}

export function* watchloadTechnicalCategories() {
  yield takeEvery(LOAD_TECHNICAL_CATEGORIES_ACTION, loadTechnicalCategories);
}

export function* watchCreateNewTechnicalCard() {
  yield takeEvery(CREATE_TECHNICAL_ITEM_ACTION, createTechnicalPackaging);
}

export function* watchValidateTechnicalCard() {
  yield takeEvery(VALIDATION_TECHNICAL_CARD_ACTION, validateTechnicalCard);
}

export function* watchloadCountries() {
  yield takeEvery(LOAD_COUNTRIES_ACTION, loadCountries);
}

export function* watchCheckAccountCanCreateGtin() {
  yield takeEvery(CHECK_ACCOUNT_CAN_CREATE_GTIN_ACTION, checkAccountCanCreateGtin);
}

export function* watchLoadAccountGcpList() {
  yield takeEvery(LOAD_ACCOUNT_GCP_LIST_ACTION, loadAccountGcpList);
}

export function* watchCheckAvailabilityCreationGtin() {
  yield takeEvery(CHECK_AVAILABILITY_CREATION_GTIN_ACTION, checkAvailabilityCreationGtin);
}

export function* watchCreateGtin() {
  yield takeEvery(CREATE_GTIN_ACTION, createGtin);
}

export default function* productSaga() {
  yield all([
    watchGenerationGtin(),
    watchValidateGtin(),
    watchCreateNewCard(),
    watchCreateNewConsumerCard(),
    watchGetSuggestions(),
    watchClearTransaction(),
    watchClearCopyOfProduct(),
    watchvalidateGtinPackage(),
    watchSaveProduct(),
    watchLoadProduct(),
    watchLoadCatalogProduct(),
    watchLoadProductVersion(),
    watchSearchStateCertificate(),
    watchModerateProduct(),
    watchLoadModal(),
    watchLoadModerateErrors(),
    watchDownloadProduct(),
    watchLoadGcpDictionary(),
    watchValidationGroupAndTransportGtin(),
    watchLoadDictionaryGpcConsumerPackage(),
    watchLoadDictionaryTnvedConsumerPackage(),
    watchValidationUnregisteredGtin(),
    watchCheckTnvedForMarking(),
    watchLoadPhotoTypes(),
    watchloadTechnicalCategoriesUnmarkable(),
    watchloadTechnicalOptions(),
    watchloadTechnicalCategories(),
    watchCreateNewTechnicalCard(),
    watchValidateTechnicalCard(),
    watchloadCountries(),
    watchCheckAccountCanCreateGtin(),
    watchLoadAccountGcpList(),
    watchCheckAvailabilityCreationGtin(),
    watchCreateGtin(),
  ]);
}
