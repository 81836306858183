import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../common/styles/Common.colors';

export const AnalyticBlocks = styled.div`
  display: flex;
  justify-content: space-between;
  height: calc(23% - 14px)
  min-height: 206px;
`;
export const AnalyticBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  padding: 28px 36px;
  //box-shadow: 0px 1px 8px rgb(41 49 53 / 10%);
  border-radius: 8px;
  background: #fff;
  position: relative;
  width: calc(25% - 21px);
  //min-height: 200px;
`;
export const AnaliticBlockTitle = styled.div`
  display: flex;
  font-size: 18px;
  line-height: 24px;
  color: #52535a;
  white-space: nowrap;
  padding-top: 2px;
`;
export const AnaliticBlockCenter = styled.div`
  display: block;
`;
export const AnaliticBlockData = styled.div`
  flex-basis: 50%;
  font-size: 40px;
  line-height: 48px;
  font-weight: bold;
  align-self: start;
  color: #2F3D4A;
`;
export const AnaliticBlockDataStatus = styled.div`
  flex-basis: 50%;
  font-size: 40px;
  line-height: 48px;
  font-weight: bold;
  align-self: start;
  color: ${(props) =>
    props.status === 0
      ? '#00B634'
      : props.status === 1
        ? '#E32A38'
        : props.status === 2
          ? '#E32A38'
          : '#C9C7C7'};
`;
export const AnaliticBlockIcon = styled.div`
  padding-top: 23px;
`;
export const AnalyticLinksTitle = styled.div`
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  padding-bottom: 6px;
`;
export const AnalyticLinks = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 28px;
`;
export const AnalyticLink = styled.a`
  display: flex;
  align-items: center;
  padding: 44px 25px;
  border: 1px solid #E7E7E7;
  border-radius: 4px;
  background: #fff;
  position: relative;
  width: calc(50% - 12px);
  min-height: 136px;
  text-decoration: none;
  color: #52535a;
  &:hover {
    background: rgba(231, 231, 231, 0.35);
    cursor: pointer;
    border: 1px solid rgba(231, 231, 231, 0.35);
  }
`;
export const AnalyticLinkIcon = styled.div`
  margin-right: 15px;
`;
export const AnalyticLinkName = styled.div`
  font-size: 18px;
  line-height: 24px;
  width: 60%;
`;
export const AnalyticContainer = styled.div`
  padding: 28px 40px;
  overflow: auto;
  height: calc(100vh - 80px);
  
  @media (min-width: 1920px) {
    display: flex;
  }
`;
export const AnalyticContainerLeft = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  @media (min-width: 1920px) {
    width: 66%;
  }
`;
export const AnalyticContainerRight = styled.div`
  padding-top: 20px;
  @media (max-width: 1919px) {
    display: none;
  }
  @media (min-width: 1920px) {
    width: 34%;
    min-height: 944px;
    padding: 0 0 0 20px;
  }
`;
export const AnalyticInstruction = styled.div`
  height: 100%;
  padding: 28px 36px 24px;
  border-radius: 4px;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const AnalyticInstructionTitle = styled.div`
  font-size: 21px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 28px;
`;
export const AnalyticInstructionImage = styled.div`
  display: flex;
  justify-content: center;
  img {
    @media (min-width: 1600px) {
      width: 100%;
    }
  }
`;
export const AnalyticInstructionMap = styled.div`
  display: flex;
`;
export const AnalyticInstructionDashed = styled.div`
  border-top: 1px dashed #C9C7C7;
  position: absolute;
  bottom: 24px;
  left: 0;
  width: 100%;
`;
export const AnalyticInstructionLeft = styled.div`
  width: 55%
`;
export const AnalyticInstructionLeftTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 28px;
`;
export const AnalyticInstructionRight = styled.div`
  width: 45%;
  padding-top: 54px;
`;
export const AnalyticInstructionRightTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 28px;
`;
export const AnalyticInstructionService = styled.div`
  border-left: 1px solid #DCDCDC;
  //transform: rotate(90deg);
  padding: 0px 28px 30px;
  position: relative;
  &:nth-last-of-type(1) {
    border-left: 0;
  }
`;
export const AnalyticInstructionBlock = styled.div`
  border-left: 1px solid ${colors.Icons.borderLight};
  //transform: rotate(90deg);
  padding: 0px 28px 32px;
  position: relative;
  &:nth-last-of-type(1) {
    border-left: 0;
  }
`;
export const AnalyticInstructionSvg = styled.div`
  width: 40px;
  height: 40px;
  background: ${(props) =>
    props.gray
      ? 'rgba(231, 231, 231, 0.35)'
      : `${colors.Icons.backgroundLight}`
  };
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${(props) =>
    props.fourSvg
      ? 'block'
      : 'absolute'
  };
  top: -9px;
  left: 40px;
`;
export const AnalyticInstructionFourSvg = styled.div`
  display: flex;
  width: 88px;
  height: 88px;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const AnalyticInstructionName = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding-top: 35px;
  max-width: 244px;
`;
export const AnalyticInstructionEllipse = styled.div`
  position: absolute;
  left: -13px;
  height: 24px;
  width: 24px;
`;
export const AnalyticInstructionBottom = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-top: 38px;
  display: flex;
  justify-content: flex-start;
  div {
    max-width: 494px;
    margin-right: 4px;
  }

`;
export const ChartCont = styled.div`
  // padding: 40px 40px 100px;
  // box-shadow: 0px 1px 8px rgba(41, 49, 53, .1);;
  // border-radius: 4px;
  // background: #fff;
  // position: relative;
  // width: 30%;
  height: calc(49% - 14px);
  min-height: 443px;
`;
export const ChartSelect = styled.div`
  width: 260px;
  border: 1px solid #868686;
  border-radius: 2px;
  padding: 7px 20px 7px 12px;
  display: flex;
  align-items: center;
  color: #63666a;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  &.active {
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  }
`;
export const ChartSelectInner = styled.div`
  position: relative;
`;

export const ChartContainerInnerTextEmpty = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 80%;
  font-size: 25px;
`;

export const ChartSelectInnerWithPadding = styled.div`
  position: relative;
  padding-left: 100px;
`;
export const ChartSelectOptions = styled.div`
  position: absolute;
  top: 100%;
  max-width: 270px;
  min-width: 260px;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.15);
  background: #fff;
  z-index: 1;
  max-height: 400px;
  overflow: auto;
`;
export const ChartSelectOption = styled.div`
  padding: 17px 12px;
  font-size: 14px;
  color: #63666a;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
export const ChartStatusLine = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #63666a;
  margin-bottom: 10px;
  margin-right: 21px;
  min-height: 25px;
  display: flex;
  align-items: center;
  &.active {
    font-weight: 600;
    .status-color {
      width: 20px;
      height: 20px;
      position: relative;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: 50%;
      }
    }
  }
`;
export const ChartStatusColorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ChartStatusColor = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  display: inline-block;
  transition: 0.4s ease;
`;
export const ChartStatusTitle = styled.span`
    color: #000000
    margin: 0 5px 0 10px
    display: inline-block;
`;
export const ChartSelectTitle = styled.span`
  padding-left: 12px;
`;
export const ChartSelectIconHolder = styled.div`
  margin-left: auto;
  padding: 0;
`;
export const ChartStatusesIconHolder = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;
export const ChartContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 28px;
  height: 100%;
`;
export const ChartContainerLeft = styled.div`
  border-radius: 4px;
  background: #fff;
  width: calc(50% - 14px);
  padding: 28px 36px;
  display: flex;
  flex-direction: column;

`;
export const ChartContainerTop = styled.div`
  position: relative;
  display: flex;
`;
export const ChartContainerTitle = styled.div`
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
`;
export const ChartContainerHolder = styled.div`
  position: relative;
  padding: 20px 38px 10px;
`;
export const ChartContainerInner = styled.div`
  
`;
export const ChartContainerInnerEmpty = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;

`;
export const ChartContainerRight = styled.div`
  width: calc(50% - 14px);
  border-radius: 4px;
  background: #fff;
  padding: 28px 36px 32px;
`;
export const ChartState = styled.div`
  height: calc(29% - 14px);
  min-height: 264px;
  background: #FFFFFF;
  margin-top: 28px;
`;
export const ChartStateInner = styled.div`
  min-height: 176px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ChartStateTable = styled.div`  
  width: 100%;
  border-collapse: collapse;
  display: flex;    
`;
export const ChartStateQuantity = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px 0 36px;
  margin-bottom: 28px;
  width: 25%;
  border-right: 1px solid #E7E7E7;
  &:nth-last-of-type(1) {
    border-right: 0;
  }
`;
export const ChartStateTop = styled.div`
  
  }
`;
export const ChartStateTitle = styled.div`
  font-size: 21px;
  line-height: 24px;
  font-weight: 600;
  color: ${colors.Common.textColor};
  padding: 28px 28px 28px 36px;
`;
export const ChartStateName = styled.div`
  font-size: 18px;
  line-height: 24px;
  padding: 8px 0 12px;
`;
export const ChartStateBtn = styled.div`    
  margin-top: auto;  
`;
export const ChartStateNumber = styled.div`
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;
export const ChartStateNumberCritical = styled.div`
  margin-left: 15px;
`;
export const ChartStateMore = styled.button`
  background: none;
  border-radius: 0;
  border: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.Buttons.textButton.color};
  padding: 0;
  transition: color 0.4s ease;
  &:hover {
    color: ${colors.Buttons.textButton.colorHovered};
  }
  &:active {
    color: ${colors.Buttons.textButton.colorHovered};
  }
  &:focus {
    outline: none;
  }
`;

export const ChartBlockMin = styled.div`
  border-radius: 3px;
  background: #f7f7f7;
  padding: 40px 20px;
  max-width: 680px;
  min-width: 480px;
  color: #868686;
  text-align: center;
  a {
    color: #868686;
    text-decoration: none;
    transition: color 0.4s ease;
    &:hover {
      color: #000;
    }
  }
  &:nth-of-type(1) {
    margin-bottom: 20px;
  }
  button {
    padding: 16px 60px;
    margin-top: 40px;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
`;
export const ChartBlockNumbers = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 38px;
`;
export const ChartBlockNumber = styled.div`
  font-size: 52px;
  font-weight: bold;
`;
export const ChartBlockName = styled.div`
  font-size: 18px;
  font-weight: bold;
`;
export const ChartTooltip = styled.div`
  color: #63666a;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
`;
export const SelectedStatusesContainer = styled.div`
  display: flex;
`;
export const SelectedStatus = styled.div`
  color: #fff;
  font-size: 12px;
  background: #63666a;
  border-radius: 3px;
  line-height: 16px;
  padding: 10px 0 10px 10px;
  margin-left: 10px;

  button {
    width: 20px;
    height: 20px;
    background: none;
    min-width: 20px;
    padding: 0;
    box-shadow: none;
    margin-left: 5px;
    &:hover {
      background: none;
      box-shadow: none;
    }
  }
`;

export const FilterSelectAuto = createMuiTheme({
  typography: {},
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#868686',
        },
        '&:hover $notchedOutline': {
          borderColor: '#868686',
        },
        '&$focused $notchedOutline': {
          borderColor: '#868686',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#868686',
        },
      },
    },
  },
});
