import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { defaultTo, find, isEqual, isNull } from 'lodash';
import {
  Button,
  Checkbox,
  FormControlLabel,
  MuiThemeProvider,
  Popover,
  List,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { PackingTypes } from '../../../common/constants/Statuses';
import {
  DefaultFilterPopover,
  defaultFilterSelect,
  FilterBtn,
  FilterButton,
  FilterContent,
  MenuItemLink,
  MenuItemSpan,
} from './PackingFilter.styled';
import {
  IconFilter,
  IconFilteredSquareOrange,
  IconCheck,
  IconCaretDown,
  IconCheckMenu,
} from '../../../common/icons/customIcons';

/**
 * TODO:
 *
 * переписать и превести в порядок.
 */

class PackingFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      packingPurpose: defaultTo(
        find(PackingTypes, { value: this.props.currentValue }),
        '',
      ),
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        isDirty: false,
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.state);
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentValue !== prevProps.currentValue) {
      this.setState({ currentValue: this.props.currentValue });
    }
  }

  handleChange = (event) => {
    const value = event.target.value;
    if (this.state.currentValue === value) {
      this.setState(
        {
          currentValue: null,
        },
        () => this.props.onChange(null),
      );

      return this.handleClose();
    }

    this.setState({ currentValue: value }, () => this.props.onChange(value));

    return this.handleClose();
  };

  handleClick = (event) => {
    const element = document.getElementById(this.props.parentId);
    this.setState({ anchorEl: !!element ? event.currentTarget : element });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onPackClick = (value) => {
    if (this.state.currentValue === value) {
      this.setState(
        {
          currentValue: null,
        },
        () => this.props.onChange(null),
      );
      return this.handleClose();
    }
    this.setState({ currentValue: value }, () => this.props.onChange(value));
    return this.handleClose();
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const IconFilterView = isNull(this.props.currentValue) ? (
      <IconFilter />
    ) : (
      <IconFilteredSquareOrange />
    );
    return (
      <>
        <MuiThemeProvider theme={FilterBtn}>

            <Button
              aria-owns={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
              {t(this.props.packageName)}
              <IconCaretDown style={{ marginLeft: '10px' }} />
            </Button>
         
        </MuiThemeProvider>

        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Menu id="menu-filter"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            //anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            //transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            style={{ top: '120px', left: '175px' }}>
            <FilterContent>
              <MuiThemeProvider theme={defaultFilterSelect}>
                <List component="div" disablePadding>
                  <MenuItem disableGutters={true} onClick={() => this.onPackClick('consumer')} >
                    <MenuItemSpan>
                      <Typography variant="inherit">{t('Все упаковки')}</Typography>
                      {!this.state.currentValue && <IconCheckMenu />}
                    </MenuItemSpan>
                  </MenuItem>
                  <MenuItem disableGutters={true} value='consumer' onClick={() => this.onPackClick('consumer')} >
                    <MenuItemSpan>
                      <Typography variant="inherit">{t('Потребительская')}</Typography>
                      {this.state.currentValue === 'consumer' && <IconCheckMenu />}
                    </MenuItemSpan>
                  </MenuItem>
                  <MenuItem disableGutters={true} value="group" onClick={() => this.onPackClick('group')}>
                    <MenuItemSpan>
                      <Typography variant="inherit">{t('Групповая')}</Typography>
                      {this.state.currentValue === 'group' && <IconCheckMenu />}
                    </MenuItemSpan>
                  </MenuItem>
                  <MenuItem disableGutters={true} value="transport" onClick={() => this.onPackClick('transport')}>
                    <MenuItemSpan>
                      <Typography variant="inherit">{t('Транспортная')}</Typography>
                      {this.state.currentValue === 'transport' && <IconCheckMenu />}
                    </MenuItemSpan>
                  </MenuItem>
                  {this.props.isTechCardSupport && (
                    <MenuItem disableGutters={true} value="techCard" onClick={() => this.onPackClick('techCard')}>
                      <MenuItemSpan>
                        <Typography variant="inherit">{t('Техническая карточка')}</Typography>
                        {this.state.currentValue === 'techCard' && <IconCheckMenu />}
                      </MenuItemSpan>
                    </MenuItem>)}
                  {this.props.isNotMarkedSupport && (
                    <MenuItem disableGutters={true} value="unmarkable" onClick={() => this.onPackClick('unmarkable')}>
                      <MenuItemSpan>
                        <Typography variant="inherit">{t('Немаркируемый товар')}</Typography>
                        {this.state.currentValue === 'unmarkable' && <IconCheckMenu />}
                      </MenuItemSpan>
                    </MenuItem>)}
                </List>
                {/* <FormControlLabel
                  label={t(this.props.packageTypes[0].text)}
                  control={
                    <IconCheckMenu/>
                    <Checkbox
                      checked={this.props.currentValue === 'consumer'}
                      onChange={this.handleChange}
                      checkedIcon={<IconCheck />}
                      name={'consumer'}
                      value={'consumer'}
                    />
                  }
                  value={'consumer'}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.currentValue === 'group'}
                      onChange={this.handleChange}
                      checkedIcon={<IconCheck />}
                      name={'group'}
                      value={'group'}
                      style={{border:'0'}}
                    />
                  }
                  label={t(this.props.packageTypes[1].text)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.currentValue === 'transport'}
                      onChange={this.handleChange}
                      checkedIcon={<IconCheck />}
                      name={'transport'}
                      value={'transport'}
                    />
                  }
                  label={t(this.props.packageTypes[2].text)}
                /> */}
                {/* {this.props.isTechCardSupport && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.currentValue === 'techCard'}
                        onChange={this.handleChange}
                        checkedIcon={<IconCheck />}
                        name={'techCard'}
                        value={'techCard'}
                      />
                    }
                    label={t(this.props.packageTypes[3].text)}
                  />
                )}
                {this.props.isNotMarkedSupport && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.currentValue === 'unmarkable'}
                        onChange={this.handleChange}
                        checkedIcon={<IconCheck />}
                        name={'unmarkable'}
                        value={'unmarkable'}
                      />
                    }
                    label={t(this.props.packageTypes[4].text)}
                  />
                )} */}
              </MuiThemeProvider>
            </FilterContent>
          </Menu>
        </MuiThemeProvider>
      </>
    );
  }
}

PackingFilter.propTypes = {
  currentValue: PropTypes.string,
  parentId: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
    },
    dispatch,
  );
};

const mapStateToProps = (state) => ({
  isTechCardSupport: state.profileReducer.info.user.isTechCardSupport,
  isNotMarkedSupport: state.profileReducer.info.user.isNotMarkedSupport,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(PackingFilter);
