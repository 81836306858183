import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import { ProductViewMdiaItem } from '../../../../common/styles/Common.styled';
import colors from '../../../../common/styles/Common.colors';

export const DefaultVersionsPopover = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '628px',
        maxWidth: '628px',
        borderRadius: '8px',
        padding: '32px 36px',
        boxSizing: 'border-box',
        fontFamily: 'SegoeUI',
        color: `${colors.Common.textColor}`,
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '0',
        '& div': {
          fontSize: '21px',
          lineHeight: '28px',
          fontWeight: '600',
        }
      },
    },
    MuiTypography: {
      h2: {
        fontSize: '21px',
        lineHeight: '28px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
      }
    },
    MuiDialogContent: {
      root: {
        padding: '28px 0 0',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
        borderRadius: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
  },
});
export const VersionLineInner = styled.div`
  display: block;
  width: 100%;
  border-top: 1px solid #E7E7E7; 
  &:last-child {
    border-bottom: 1px solid #E7E7E7;
  }
`;
export const VersionLineInnerTop = styled.div`
  font-size: 14px;
  line-height: 20px; 
  font-weight: normal;
  padding: 18px 16px;
  display: flex;
  justify-content: space-between;
  border-right: 1px solid #E7E7E7;
  border-left: 1px solid #E7E7E7;  
`;
export const VersionLineInnerBottom = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding: 20px 16px 0;
  border-top: 1px solid #E7E7E7;  
  p {
    margin: 0;
  }
  // div {
  //   margin-bottom: 24px;    
  // }
`;
export const VersionInfoDate = styled.div` 
  padding: 0 8px;
`;
export const VersionInfoChanges = styled.div`  
  cursor: pointer;
  transition: color 0.4s ease;
  &:hover path{   
    stroke: #AAA9A9;
  }
`;
export const VersionInfoView = styled.div`
  a {
    color: ${colors.Buttons.textButton.color}; 
    margin-right: 98px;   
    text-decoration: none;
    transition: color 0.4s ease;
    &:hover {
      color: ${colors.Buttons.textButton.colorHovered};
    }
    &:active {
      color: ${colors.Buttons.textButton.colorHovered};
    }
  }
`;
export const VersionImages = styled.div`
  display: flex;
`;
export const VersionImagesItem = styled.div`
  padding-left: 4px;
`;
export const ProductVersionMediaItemInner = styled.div`
  overflow: hidden;
  padding: 6px 3px;
  height: 100%;
  transition: background-color 0.4s ease;
  &:hover {
    background-color: #fff;
  }
  img {
    transition: transform 0.4s ease;
    &:hover {
      transform: scale(1.5);
    }
  }
`;
export const ProductVersionMdiaItem = styled(ProductViewMdiaItem)`
  max-width: 70px;
  padding: 0;
  height: 50px;
  margin-top: 10px;
  border: none;
`;

export const BlockContainer = styled.div`
  margin: 0 !important;
`;
