import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from './../../../../common/styles/Common.colors'

export const ProfileMain = styled.div`
  display: flex;
  height: 100%;
`;
export const ProfileFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px #E7E7E7 solid;
  background-color: #FFFFFF;
  padding: 32px 36px;
  position: fixed;
  right: 40px;
  left: 40px;
  bottom: 0px;
  z-index: 2;
  border-bottom: 20px #fafafa solid;
`;
export const ProfileFooterButton = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ProfileLeft = styled.div`
  min-width: 220px;
  @media (max-width: 1200px) {
    min-width: 120px;
  }
`;
export const ProfileCenter = styled.div`
  min-width: 220px;
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 30px;
  @media (min-width: 1920px) {
    padding-right: 185px;
  }
`;
export const ProfileRight = styled.div`
  min-width: 220px;
  flex-grow: 1;
  flex-basis: 0;
  //margin-top: ${props => props.certificate ? '0px' : '-10px'};
  margin-top: 0px;
  //padding-top: 10px;
  @media (min-width: 1920px) {
    padding-right: 150px;
  }
`;
export const ProfileLoadCert = styled.div`
  margin-bottom: 20px;
`;
export const ProfileH3 = styled.h3`
  color: #52535a;
  font-size: 16px;
  margin: 0 0 5px;
  line-height: 21px;
`;

export const PasswordErrorsWarning = styled.div`
  color: red;
  font-size: 12px;
`;

export const ProfileInputInlineCompany = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProfileInputCompany = styled.div`
  width: ${props => props.fullWidth ? 'calc(100% - 268px)' : 'auto'};
`;
export const ProfileInputCompanyINN = styled.div`
  width: ${props => props.fullWidth ? '100%' : '248px'};
`;
export const ProfileInputInlineUser = styled.div`
  margin-bottom: ${props => props.isELK ? '0px' : '45px'};
  @media (min-width: 1920px) {
    display: flex;
    justify-content: space-between;
  }
`;
export const ProfileInputUserWrap = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media (min-width: 1920px) {
    margin-right: 20px;
  }
`;

export const ProfileInputUserEmail = styled.div`
  width: 100%;
  @media (min-width: 1920px) {
    margin-right: 20px;
  }
`;

export const ProfileInputUserPhone = styled.div`
  min-width: 186px;
  @media (min-width: 1920px) {
    margin-left: 20px;
  }
  
`;

export const ProfileInputContainerMailPhone = styled.div`
  display: flex;
  @media (min-width: 1920px) {
    margin-right: 20px;
    width: 100%;
  }
`;
export const InputContainerMail = styled.div`
  width: 100%;
`;
export const ProfileInputIcon = styled.span`
  position: absolute;
  top: 35px;
  right: 35px;
  transform: translateY(-3px);
`;
export const ProfileWrap = styled.div`
  height: calc(100% - 57px);
  //height: auto;
  
`;

export const InputPhone = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #C9C7C7',
        borderRadius: '4px',
        '&:hover': {
          border: `1px solid ${colors.Common.textGrey}`,
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: `${colors.Common.textGrey}`,
        },
        '&$error': {
          border: '1px solid #E32A38 !important',
        },
      },
      input: {
        padding: '23px 24px 7px 15px',
        lineHeight: '24px',
        textOverflow: 'ellipsis',
        '&:required': {
          borderLeft: '4px solid #E32A38',
          borderRadius: '4px 0 0 4px',
          "&[valid='false']": {
            borderLeft: `4px solid #E32A38`,
          },
          "&[valid='true']": {
            borderLeft: 'none',
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '16px',
        lineHeight: '24px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px,32px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      filled: {
        color: `${colors.Common.textGrey}`,
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
      },
      disabled: {},
    },
    MuiInputBase: {
      input: {
        fontWeight: 'normal',
        height: 'auto',
        color: `${colors.Common.textColor}`,
        fontFamily: 'SegoeUI',
      },
      multiline: {
        padding: '0',
      },
      root: {
        '&$disabled': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
          },
        },
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        fontFamily: 'SegoeUI',
        '&$focused': {
          color: `${colors.Common.textGrey}`,
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        right: '-30px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
      },
    },
    MuiSelect: {
      icon: {
        right: '15px',
      },
    },
    MuiFormControl: {
      fullWidth: {
        width: '52%',
      }
    },
    MuiButton: {
      root: {
        borderRadius: 'none',
        padding: '0',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$disabled path': {
          stroke: '#AAA9A9',
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
  },
});

export const InputCreated = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #C9C7C7',
        borderRadius: '4px',
        '&:hover': {
          border: `1px solid ${colors.Common.textGrey}`,
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: `${colors.Common.textGrey}`,
        },
        '&$error': {
          border: '1px solid #E32A38 !important',
        },
      },
      input: {
        padding: '23px 44px 7px 15px',
        lineHeight: '24px',
        textOverflow: 'ellipsis',
        '&:required': {
          borderLeft: '4px solid #E32A38',
          borderRadius: '4px 0 0 4px',
          "&[valid='false']": {
            borderLeft: `4px solid #E32A38`,
          },
          "&[valid='true']": {
            borderLeft: 'none',
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '16px',
        lineHeight: '24px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(16px,32px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      filled: {
        color: `${colors.Common.textGrey}`,
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 24px) scale(1)',
      },
      disabled: {},
    },
    MuiInputBase: {
      input: {
        fontWeight: 'normal',
        height: 'auto',
        color: `${colors.Common.textColor}`,
        fontFamily: 'SegoeUI',
      },
      multiline: {
        padding: '0',
      },
      root: {
        '&$disabled': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
          borderColor: '#DCDCDC',
          '&:hover': {
            borderColor: '#DCDCDC',
          },
        },
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        fontFamily: 'SegoeUI',
        '&$focused': {
          color: `${colors.Common.textGrey}`,
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        right: '-30px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
      },
    },
    MuiSelect: {
      icon: {
        right: '15px',
      },
    },
    // MuiFormControl: {
    //   fullWidth: {
    //     '@media (min-width: 1920px)': {
    //       width: "35%",
    //     }
    //   }
    // },
    MuiButton: {
      root: {
        borderRadius: 'none',
        padding: '0',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$disabled path': {
          stroke: '#AAA9A9',
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
  },
});

export const ConfirmEmailButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'inherit',
        borderRadius: '4px',
        border: 'none',
        background: 'none',
        minWidth: '30px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        color: `${colors.Buttons.textButton.color}`,
        margin: '8px 0 0',
      },
      text: {
        padding: '3px',
        fontSize: '16px',
        lineHeight: '24px',
        '&:hover': {
          backgroundColor: 'transparent',
          color: `${colors.Buttons.textButton.colorHovered}`,
        },
        '&:active': {
          backgroundColor: 'transparent !important',
          color: `${colors.Buttons.textButton.colorHovered}`,
        },
      },
    },
  },
});
