import * as PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  DialogActions,
  Typography,
  IconButton,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  // ImportDialogMin,
  // ImportDialogMinInner,
  ImportModalDialog,
} from './ImportModal.styled';
import { IconClose } from '../../../../common/icons/customIcons';
import { importProduct } from '../../ducks/Products.actions';
import { throwNotification } from '../../../../common/structure';
import DragDropFieldImport from '../../../../components/dragDropField/dragDropFieldImport';
import colors from '../../../../common/styles/Common.colors';

class ImportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentReport: {},
    };
  }

  handleChangeFile = (event) => {
    const { t, throwNotification } = this.props;
    const file = !!event.target.files ? event.target.files[0] : event.dataTransfer.files[0];

    if (!_.includes(['xlsx'],
      file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2),
    )) {
      throwNotification(t('Неподдерживаемый формат'));
      return;
    }

    if (file) {
      this.setState({
        currentReport: {
          name: file.name,
          file: file,
        },
      });
    }
  };

  onUpload = () => {
    const {
      t,
      handleClose,
      changeGridParams,
      throwNotification,
      onImportProduct,
    } = this.props;
    const { currentReport } = this.state;

    if (!_.isEmpty(currentReport)) {
      onImportProduct(currentReport, (error) => {
        if (!error) {
          throwNotification(t('Шаблон успешно загружен. Начался импорт'));
          changeGridParams();
          handleClose();
        }
      });
    }

    return;
  };

  deleteFile = () => {
    this.setState({
      currentReport: {},
    });
  };

  handleCancel = () => {
    this.deleteFile();
    this.props.handleClose();
  };

  render() {
    const { currentReport } = this.state;
    const { handleClose, loadingProductReport, t } = this.props;
    const fileName =
      currentReport?.name
        ? currentReport?.name
        : '';
    const fileSize =
      currentReport?.file
        ? (currentReport?.file?.size / (1024 * 1024)).toFixed(2)
        : '';

    return (
      <MuiThemeProvider theme={ImportModalDialog}>
        <Dialog open>
          <DialogTitle id="alert-dialog-title" disableTypography>
            <Typography variant="h2">{t('Импорт атрибутов')}</Typography>
            <IconButton
              id="dismiss-btn-close"
              onClick={handleClose}>
              <IconClose />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <p>
              {t('Системное ограничение для импорта составляет 100 товаров',) + '.'}
            </p>
            <DragDropFieldImport
              handleChangeFile={this.handleChangeFile}
              preview={fileName ? 'withoutPhoto' : null}
              deleteImg={this.deleteFile}
              fileName={fileName}
              fileSize={fileSize}
              importFile={true}
              message={`${t('Загрузить можно один файл')} *.xlsx`}
            />

            {/* <ImportDialogMin>
              <p>{t('Перетащите сюда файл или')}</p>
              <ImportDialogMinInner>
                <div>{this.state.currentReport.name}</div>
                <Button component="label" disabled={false} color={'primary'}>
                  {t('Выберите файл')}
                  <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{ display: 'none' }}
                    onChange={this.handleChangeFile}
                  />
                </Button>
              </ImportDialogMinInner>
            </ImportDialogMin> */}
          </DialogContent>
          <DialogActions>
            <Button
              color={'secondary'}
              onClick={this.handleCancel}
              disabled={loadingProductReport}
            >
              {t('Отменить')}
            </Button>
            <Button
              color={'primary'}
              disabled={_.isEmpty(currentReport) || loadingProductReport}
              onClick={this.onUpload}>
              {loadingProductReport ? (
                <CircularProgress
                  size={24}
                  style={{ color: colors.Common.textColor }}></CircularProgress>
              ) : (
                t('Загрузить')
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

ImportModal.propTypes = {
  onImportProduct: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  throwNotification: PropTypes.func.isRequired,
  changeGridParams: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onImportProduct: (params, cbk) => importProduct(params, cbk),
      throwNotification: (message, type = 'error') =>
        throwNotification(message, type),
    },
    dispatch,
  );
};

const mapStateToProps = (state) => ({
  loadingProductReport: state.productsReducer.loadingProductReport,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ImportModal);
