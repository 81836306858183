import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
    DragDrop,
    DragDropButton,
    DragDropButtonText,
    DragDropContainer,
    DragDropContent,
    DragDropFile,
    DragDropForm,
    DragDropInput,
    DragDropLabel,
    DragDropTitle,
    DragDropView,
    DragDropViewLeft,
    DragDropViewRight,
    DragDropViewFileName,
    DragDropViewFileSize,
    DragDropViewFile,
    DragDropText,
    DragDropWrapper
} from './dragDropField.styled';

import { IconEye, IconImage, IconTrash } from '../../common/icons/customIcons';

import { CreationEyeBtns, CreationTrashBtns } from '../../pages/profile/Profile.styled';
import { MuiThemeProvider, Button } from '@material-ui/core';


class DragDropField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: false,
            isDragging: false,
        };
    }

    onDropHandler = (event) => {
        event.preventDefault();
        let files = [...event.dataTransfer.files]
        this.props.handleChangeFile(event)
        this.setState({
            value: false,
            isDragging: false,
        })
    }

    dragStartHandler = (event) => {
        event.preventDefault();
        this.setState({
            value: true
        })
    }

    dragLeaveHandler = (event) => {
        event.preventDefault();
        this.setState({
            value: false,
            isDragging: false,
        })
    };

    dragOverHandler = (event) => {
        event.preventDefault();
        this.setState({
            value: true,
            isDragging: true,
        })
    };


    render() {
        const { preview, fileName, fileSize, importFile, t } = this.props


        return (
            <DragDropWrapper>
                {importFile && preview ? null : (
                    <DragDropContainer
                        border={this.state.isDragging}
                        id="drop-area"
                        onDragStart={this.dragStartHandler}
                        onDragLeave={this.dragLeaveHandler}
                        onDragOver={this.dragOverHandler}
                        onDrop={this.onDropHandler}
                    >
                        {importFile ? (
                            <DragDrop className="my-form">
                                <DragDropContent>
                                    <DragDropLabel htmlFor="fileselect">{t('Перетащите сюда файл или')}</DragDropLabel>
                                    <DragDropInput type="file" accept="image/*" id="fileselect" name="fileselect" multiple="multiple" onChange={this.props.handleChangeFile} />
                                    <DragDropButton htmlFor="fileselect" >
                                        <DragDropButtonText>Выберите файл</DragDropButtonText>
                                    </DragDropButton>
                                </DragDropContent>
                            </DragDrop>
                        ) : (
                            <DragDrop className="my-form">
                                <IconImage color={'#AAA9A9'} style={{ width: '36px', height: '36px' }} />
                                <DragDropContent>
                                    <DragDropLabel htmlFor="fileselect">{t('Перетащите сюда изображения или')}</DragDropLabel>
                                    <DragDropInput type="file" accept="image/*" id="fileselect" name="fileselect" multiple="multiple" onChange={this.props.handleChangeFile} />
                                    <DragDropButton htmlFor="fileselect" >
                                        <DragDropButtonText>{t('Выберите файл')}</DragDropButtonText>
                                    </DragDropButton>
                                </DragDropContent>
                                <DragDropText id="filedrag" >{t('Загрузить можно один файл jpg, не более 15MB')}</DragDropText>
                            </DragDrop>

                        )}
                    </DragDropContainer>
                )}
                {preview && (
                    <DragDropView>
                        <DragDropViewLeft>
                            <img
                                style={{ maxWidth: '198px', height: '64px', paddingRight: '20px' }}
                                alt=""
                                src={preview}
                            />
                            <DragDropViewFile>
                                <DragDropViewFileName>{fileName}</DragDropViewFileName>
                                {fileSize && <DragDropViewFileSize>{fileSize + '' + 'MB'}</DragDropViewFileSize>}
                            </DragDropViewFile>

                        </DragDropViewLeft>
                        <DragDropViewRight>
                            <MuiThemeProvider theme={CreationTrashBtns}>
                                <Button onClick={this.props.deleteImg}>
                                    <IconTrash />
                                </Button>
                            </MuiThemeProvider>
                            {this.props.handleOpen && (
                                <MuiThemeProvider theme={CreationEyeBtns}>
                                    <Button
                                        onClick={this.props.handleOpen(preview)}
                                        component="label"
                                        disabled={false}>
                                        <IconEye />
                                    </Button>
                                </MuiThemeProvider>
                            )}

                        </DragDropViewRight>
                    </DragDropView>
                )}
            </DragDropWrapper>
        );
    }
}

DragDropField.propTypes = {

};

export default withTranslation()(DragDropField);