import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const ProductBlockNew = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
export const ProductBlockNewLeft = styled.div`
  flex-grow: 1;
  //padding-right: 15px;
`;
export const ProductBlockNewRight = styled.div`
  width: 630px;
  padding-top: 11px;
  margin-left: 15px;
  button {
    height: 52px;
    text-transform: none;
    width: 100%;
  }
`;
export const ModalInfo = styled.div`
  background: #63666a;
  position: relative;
  padding: 20px 20px 10px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  p {
    margin: 0;
  }
  &.disabled {
    background: #868686;
  }
  button {
    margin-top: 20px;
    padding: 7px 35px;
    font-size: 12px;
    line-height: 16px;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
`;
export const ModalInfoRequired = styled.div`
  padding: 20px 20px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #63666a;
  font-size: 14px;
  line-height: 19px;
  opacity: 0.5;
  span {
    color: #63666a;
  }
  span.icon {
    margin-right: 12px;
    svg {
      transform: translateY(3px);
    }
  }
  svg {
    path {
      fill: #63666a;
    }
  }
  &.disabled {
    opacity: 1;
    color: #63666a;
    span {
      color: #ff4b4b;
    }
    svg {
      path {
        fill: #ff4b4b;
      }
    }
  }
`;
export const ModalInfoRequiredValid = styled(ModalInfoRequired)`
  opacity: 1;
  svg {
    path {
      fill: #60e84a;
    }
  }
`;
export const NewProductSearchHolder = styled.div`
  position: relative;
  .icon {
    position: absolute;
    left: 22px;
    top: 50%;
    margin-top: 8px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
  }
`;
export const SearchIconAutocomplete = createMuiTheme({
  typography: {},
  overrides: {
    MuiInputBase: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        marginTop: '15px!important',
        '&$disabled': {
          backgroundColor: '#F9F9F9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      error: {},
      input: {
        padding: '23px 110px 6px 50px',
        fontSize: '16px',
        color: '#52535A',
        fontWeight: '600',
        lineHeight: '21px',
        height: 'auto',
        fontFamily: 'SegoeUI',
        '&::placeholder': {
          color: '#52535A',
          opacity: '1',
          fontWeight: '500',
          transform: 'translateY(-10px)',
        },
        '&:required': {
          borderRadius: '3px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: '#63666A',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(51px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        fontSize: '12px',
        transform: 'translate(51px, 20px) scale(1)',
        color: '#868686',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686!important',
          '&$disabled': {
            color: '#868686!important',
          },
        },
      },
      disabled: {},
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        padding: '10px 20px',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: '16px',
        color: '#52535A',
        fontWeight: '500',
        whiteSpace: 'normal',
        lineHeight: '1.22',
        height: 'auto',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '300px!important',
        overflowY: 'scroll',
      },
      rounded: {
        borderRadius: '0 0 3px 3px',
      },
      elevation8: {
        boxShadow: '0 4px 15px rgba(0, 0, 0, .15)',
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        width: '20px',
        maxHeight: '100%',
        pointerEvents: 'none',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '0',
        minWidth: '15px',
        width: '20px',
        height: '20px',
        //position: "absolute",
        //top: "50%",
        //transform: "translateY(-50%)",
        //marginTop: "9px",
        //right: "35px",
        '&:hover': {
          background: 'none!important',
        },
      },
      text: {
        padding: '3px',
      },
    },
  },
});

export const GenerateGtinButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        width: '268px',
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '4px',
        textTransform: 'none',
        fontFamily: 'SegoeUI',
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: '.4s ease',
        margin: '16px 0 4px',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '16px 35px',
        fontSize: '16px',
        lineHeight: '24px',
      },
      textPrimary: {
        color: `${colors.Buttons.buttonPrimary.color}`,
      },
      disabled: {},
    },
    MuiTooltip: {
      popper: {
        minWidth: '162px',
        maxWidth: '221px',
        margin: '0',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '1!important',
        transform: 'translate(0, -12px)!important',
        backgroundColor: `${colors.Common.textColor}`,
        borderRadius: '8px',
        padding: '12px',
        position: 'relative!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 6px 8px',
          borderColor: `transparent transparent ${colors.Common.textColor} transparent`,
          borderStyle: 'solid',
          position: 'absolute',
          top: '-7px',
          right: 'calc(50% - 8px)',
        },
      },
      tooltipPlacementBottom: {
        margin: '0 0 0 !important',
      }
    },
  },
});

export const ErrorContent = styled.span`
  display: block;
  font-size: 13px;    
  line-height: 16px;
  color: #E32A38;
  min-height: 16px;
  padding: 0 0 0 17px;       
`;

export const ErrorContentAbsolute = styled.span`
  position: absolute;
  top: 3px;
  right: ${props => props.right ? props.right : '48px'};  
`;
