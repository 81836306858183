import { createMuiTheme } from '@material-ui/core';
import colors from '../../../common/styles/Common.colors';

export const ActiveSessionDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '628px',
        maxWidth: '628px',
        borderRadius: '8px',
        padding: '32px 36px 46px',
        boxSizing: 'border-box',
        fontFamily: 'SegoeUI',
        color: `${colors.Common.textColor}`,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '0',
        textAlign: 'center',
        position: 'relative',
      },
    },
    MuiTypography: {
      h2: {
        fontWeight: '600',
        fontSize: '21px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '32px 0',
        '& p': {
          margin: 0,
          fontSize: '18px',
          lineHeight: '24px',
          fontWeight: '600',
          textAlign: 'center',
        },
      },
    },
    MuiDialogActions: {
      root: {
        borderTop: '1px solid #E7E7E7',
        padding: '32px 36px 0',
        justifyContent: 'center',
      },
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        borderRadius: '4px',
        textTransform: 'none',
        transition: '.4s ease',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '10px 35px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
      },
      disabled: {},
    },
  },
});
