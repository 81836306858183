import * as React from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import {
  TableWrapper,
  SignFormRowSubTitle,
  SignFormRowValue,
  SignFormRow,
  SignFormRowTitle,
  SignFormSpace,
} from '../styled/SignForm.styled';

const formatDate = (date) => moment(date).format('DD.MM.YYYY');
class SignForm extends React.Component {
  getPorductionType = (id) => {
    const product = _.find(this.props.productDictionary, (item) => {
      return item.id === id;
    });

    if (product) {
      return product.text;
    }
    return `-`;
  };

  render() {
    const { t, data } = this.props;

    return (
      <>
        <SignFormRowTitle>{t('Заявитель')}</SignFormRowTitle>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Наименование организации')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.applicant.companyName || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Налоговый идентификатор')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.applicant.inn || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Юридический адрес')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.addresses.legalAddress.fullAddress || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Фактический адрес')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.addresses.physicalAddress.fullAddress || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Основной вид экономической деятельности')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.applicant.okved2 || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Руководитель организации')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.applicant.fio || ''}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Должность руководителя')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.applicant.position || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Телефон руководителя')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.applicant.phone || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('E-mail')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.applicant.email || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRowTitle>{t('Ответственный по работе с ГС1')}</SignFormRowTitle>
        <SignFormRow>
        <SignFormRowSubTitle>{t('ФИО')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.contacts[0].fio || ''}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Отдел')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.contacts[0].department || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('E-mail')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.contacts[0].email || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Рабочий телефон')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.contacts[0].workPhone || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Мобильный телефон')}:{' '}</SignFormRowSubTitle>
          <SignFormRowValue>{data.contacts[0].phone || '-'}</SignFormRowValue>
        </SignFormRow>
        {/* <SignFormRow>
          <SignFormRowSubTitle>{t('Основной вид продукции')}:</SignFormRowSubTitle>
          <SignFormRowValue>{this.getPorductionType(data.applicant.productType)}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Основной вид деятельности')}:</SignFormRowSubTitle>
          <SignFormRowValue>{data.applicant.okved2 || '-'}</SignFormRowValue>
        </SignFormRow> */}
        {/* <SignFormRow>
          <SignFormRowSubTitle>{t('Наименование банка')}:</SignFormRowSubTitle>
          <SignFormRowValue>{data.bankDetails.name || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('БИК')}:</SignFormRowSubTitle>
          <SignFormRowValue>{data.bankDetails.bik || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Корр.счёт (IBAN)')}:</SignFormRowSubTitle>
          <SignFormRowValue>{data.bankDetails.correspondentAccount || '-'}</SignFormRowValue>
        </SignFormRow>
        <SignFormRow>
          <SignFormRowSubTitle>{t('Расчётный счёт')}:</SignFormRowSubTitle>
          <SignFormRowValue>{data.bankDetails.settlementAccount || '-'}</SignFormRowValue>
        </SignFormRow> */}
        <SignFormRowTitle>
          {t('Электронное заявление в Ассоциацию ГС1 было успешно отправлено')}{' '}
          {formatDate(data.sendDate)}
        </SignFormRowTitle>
        <SignFormSpace/>
      </>
    );
  }
}

export default withTranslation()(SignForm);
