/**
 * Всё еще надо доделать.
 */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  FormControl,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
} from '@material-ui/core';
import { CustomInput, PhoneInput } from '../../../../../components';
import {
  validateEmail,
  validatePhoneInput,
} from '../../../../../common/utils/Validation';
import {
  GridBlockA,
  GridBlockD,
  GridBlockE,
  GridItemA,
  GridItemB,
  GridItemSmall,
  GridItemSmallA,
  InputContainer,
  ProfileAutocomplete,
  defaultSelectTheme,
  InputIcon,
} from '../../../../../common/styles/Common.styled';
import { GridBlockB, GridItemC, ProfileDivider } from '../../../Profile.styled';
import { IconDropDown, IconCheckMenu } from '../../../../../common/icons/customIcons';
import { GsTitle } from '../Gs1.styled';

/**
 * TODO:
 * отрефакторить и прибраться.
 */

// const OPF_IP = 'FS_IP';

const isEmptyNull = (item) =>
  _.isNumber(item) ? false : _.isNil(item) || _.isEmpty(item) || item === '';

// function validIpNameShort(opf, value) {
//     const v = _.trim(_.toLower(value));
//     return opf === OPF_IP
//         ? !!v && !_.includes(['ип', 'индивидуальный предприниматель'], v)
//         : !!v;
// }

class Applicant extends React.Component {
  constructor(props) {
    super(props);
    this.OPF = _.get(this.props, 'profile.company.opf', null);
  }

  componentDidMount() {
    // if (!this.isValidOpf(this.props.applicant.opf) && this.props.applicant.opf) {
    //     this.props.handleStateChange('applicant', 'opf', null, false);
    // }
  }

  /**
   * Нужно дописать регулярку для UZ языка.
   */
  handleChange = (name, value) => {
    const { handleStateChange } = this.props;
    switch (name) {
      case 'companyName':
      case 'companyNameShort': {
        // this.handleChangeInfo(name, value.replace(/[^[а-яА-Я0-9ёЁ!.?,\\'\\"\s-+«»]/, ''));
        handleStateChange(
          'applicant',
          name,
          value.replace(/[^[а-яА-Я0-9ёЁ!.?,\\'\\"\s-+«»]/, ''),
          !!!_.defaultTo(value, ''),
        );
        break;
      }
      case 'companyNameStateLang': {
        handleStateChange(
          'applicant',
          name,
          // value.replace(/[^[a-zA-Z0-9!.?,\\'\\"\s-+]/, ''),
          value,
          !!!_.defaultTo(value, ''),
        );
        // this.handleChangeInfo(name, value.replace(/[^[\u0410\u0430\u04D8\u04D9\u0411\u0431\u0412\u0432\u0413\u0433\u0492\u0493\u0414\u0434\u0415\u0435\u0401\u0451\u0416\u0436\u0417\u0437\u0418\u0438\u0419\u0439\u041A\u043A\u049A\u049B\u041B\u043B\u041C\u043C\u041D\u043D\u04A2\u04A3\u041E\u043E\u04E8\u04E9\u041F\u043F\u0420\u0440\u0421\u0441\u0422\u0442\u0423\u0443\u04B0\u04B1\u04AE\u04AF\u0424\u0444\u0425\u0445\u04BA\u04BB\u0426\u0446\u0427\u0447\u0428\u0448\u0429\u0449\u042A\u044A\u042B\u044B\u0406\u0456\u042C\u044C\u042D\u044D\u042E\u044E\u042F\u044F!.?,\\'\\"\s-+«»]/, ''));
        break;
      }
      case 'fio': {
        handleStateChange('applicant', name, value, !!!_.defaultTo(value, ''));
        break;
      }
      // case 'lastName': {
      //   // handleStateChange('applicant', name, value.replace(/[^[а-яА-ЯёЁ-\s]/, ''), !(!!_.defaultTo(value, '')));
      //   handleStateChange('applicant', name, value, !!!_.defaultTo(value, ''));
      //   break;
      // }

      // case 'firstName': {
      //   // this.handleChangeInfo(name, value.replace(/[^[а-яА-ЯёЁ]/, ''));
      //   // handleStateChange('applicant', name, value.replace(/[^[а-яА-ЯёЁ]/, ''), !(!!_.defaultTo(value, '')));
      //   handleStateChange('applicant', name, value, !!!_.defaultTo(value, ''));
      //   break;
      // }
      // case 'middleName': {
      //   // this.handleChangeInfo(name, value.replace(/[^[а-яА-ЯёЁ]/, ''));
      //   // handleStateChange('applicant', name, value.replace(/[^[а-яА-ЯёЁ]/, ''), !(!!_.defaultTo(value, '')));
      //   handleStateChange('applicant', name, value, false);
      //   break;
      // }
      case 'okved2': {
        // this.handleChangeInfo(name, value.replace(/[^[0-9.]/, ''));
        // handleStateChange('applicant', name, value.replace(/[^[0-9.]/, ''), !(validateOkved(_.defaultTo(value, ''))));
        handleStateChange(
          'applicant',
          name,
          value.replace(/[^[0-9.]/, ''),
          !!!_.defaultTo(value, ''),
        );
        break;
      }
      case 'inn': {
        // this.handleChangeInfo(name, value.replace(/[^[0-9]/, ''));
        // handleStateChange('applicant', name, value.replace(/[^[0-9]/, ''), !(validateInn(_.defaultTo(value, ''))));
        handleStateChange(
          'applicant',
          name,
          value.replace(/[^[0-9]/, ''),
          !!!_.defaultTo(value, ''),
        );
        break;
      }
      case 'companyType': {
        handleStateChange(
          'applicant',
          name,
          _.toString(value) === '' ? null : value,
          false,
        );
        break;
      }
      case 'position':
      case 'productType':
      case 'activityType':
      case 'opf':
      case 'corporationType': {
        // let v = _.find(this.props.countries, { id: value });
        handleStateChange(
          'applicant',
          name,
          _.toString(value) === '' ? null : _.toString(value),
          !!!_.defaultTo(value, ''),
        );
        break;
      }
      case 'email': {
        handleStateChange(
          'applicant',
          name,
          _.toString(value) === '' ? '' : _.toString(value),
          !validateEmail(value),
        );
        break;
      }
      case 'phone': {
        handleStateChange(
          'applicant',
          name,
          value === '' ? '' : value,
          !validatePhoneInput(value),
        );
        break;
      }
      default:
        break;
    }
  };

  // isValidOpf = (opfId) => {
  //     const correspondItem = _.find(this.props.gs1OpfDictionary, (item) => item.id === opfId)

  //     return !!correspondItem;
  // }

  render() {
    const {
      t,
      gs1OpfDictionary,
      activityTypesDictionary,
      mainProductTypesDictionary,
      companyTypesDictionary,
    } = this.props;
    const {
      companyName,
      companyNameShort,
      companyNameStateLang,
      position,
      email,
      phone,
      okved2,
      inn,
      opf,
      isMarked,
      companyType,
      activityType,
      productType,
      fio,
    } = this.props.applicant;

    const phoneValid = validatePhoneInput(phone);

    return (
      <div>
        <GsTitle>{t('Заявитель')}</GsTitle>
        <GridBlockA>
          <GridItemA>
            <MuiThemeProvider theme={defaultSelectTheme}>
              <FormControl fullWidth={true}>
                <InputLabel disabled htmlFor="corporation-type">
                  {t('Тип предприятия')}
                </InputLabel>
                <Select
                  valid={_.toString(!!_.defaultTo(companyType, ''))}
                  value={_.isEmpty(companyType) ? '' : _.defaultTo(companyType)}
                  inputProps={{ id: 'corporation-type', required: true }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  IconComponent={IconDropDown}
                  onChange={(event) =>
                    this.handleChange('companyType', event.target.value)
                  }>
                  {_.map(companyTypesDictionary, (company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MuiThemeProvider>
          </GridItemA>
          <GridItemB>
            <InputContainer>
              <CustomInput
                id="companyName"
                name="companyName"
                required
                valid={!!_.defaultTo(companyName, '')}
                label={t('Наименование организации')}
                onUpdate={(value) => this.handleChange('companyName', value)}
                onChange={(value) => this.handleChange('companyName', value)}
                value={_.defaultTo(companyName, '')}
                type="text"
                disabled
              />
              {!!_.defaultTo(companyName, '') && (
                <InputIcon>
                  <IconCheckMenu />
                </InputIcon>
              )}
            </InputContainer>
          </GridItemB>
          <GridItemA>
            <InputContainer>
              <CustomInput
                id="companyNameShort"
                required
                valid={!!_.defaultTo(companyNameShort, '')}
                name="companyNameShort"
                label={t('Сокращенное наименование предприятия')}
                onUpdate={() => { }}
                onChange={(value) => this.handleChange('companyNameShort', value)}
                value={_.defaultTo(companyNameShort, '')}
                type="text"
                disabled
              />
              {!!_.defaultTo(companyNameShort, '') && (
                <InputIcon>
                  <IconCheckMenu />
                </InputIcon>
              )}
            </InputContainer>
          </GridItemA>
          <GridItemB>
            <CustomInput
              id="companyNameStateLang"
              required
              valid={!!_.defaultTo(companyNameStateLang, '')}
              name="companyNameStateLang"
              label={t('Наименование предприятия на государственном языке')}
              onUpdate={() => { }}
              onChange={(value) =>
                this.handleChange('companyNameStateLang', value)
              }
              value={_.defaultTo(companyNameStateLang, '')}
              type="text"
            />
          </GridItemB>
          <GridItemA>
            <InputContainer>
              <CustomInput
                id="okved2"
                required
                // valid={validateOkved(_.defaultTo(okved2, ''))}
                valid={!!_.defaultTo(okved2, '')}
                name="okved2"
                label={t('Основной вид экономической деятельности')}
                onChange={(value) => this.handleChange('okved2', value)}
                value={_.defaultTo(okved2, '')}
                type="text"
                maxLength={8}
                disabled
              />
              {!!_.defaultTo(okved2, '') && (
                <InputIcon>
                  <IconCheckMenu />
                </InputIcon>
              )}
            </InputContainer>
          </GridItemA>
          <GridItemB>
            <InputContainer>
              <CustomInput
                id="inn"
                required
                // valid={validateInn(_.defaultTo(inn, ''))}
                valid={!!_.defaultTo(inn, '')}
                name="inn"
                label={t('Идентификационный номер налогоплательщика')}
                onChange={(value) => this.handleChange('inn', value)}
                onUpdate={(value) => this.handleChange('inn', value)}
                value={_.defaultTo(inn, '')}
                type="text"
                maxLength={12}
                disabled
              />
              {!!_.defaultTo(inn, '') && (
                <InputIcon>
                  <IconCheckMenu />
                </InputIcon>
              )}
            </InputContainer>
          </GridItemB>
        </GridBlockA>
        <GridBlockD>
          <MuiThemeProvider theme={defaultSelectTheme}>
            <GridItemSmall>
              <FormControl fullWidth={true}>
                <InputLabel disabled htmlFor="opf-select">
                  {t('Организационно-правовая форма')}
                </InputLabel>
                <Select
                  required
                  valid={_.toString(!!_.defaultTo(opf, ''))}
                  value={_.isEmpty(gs1OpfDictionary) ? '' : _.defaultTo(opf, '')}
                  inputProps={{ id: 'opf-select' }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  IconComponent={IconDropDown}
                  onChange={(event) =>
                    this.handleChange('opf', event.target.value)
                  }>
                  {_.map(gs1OpfDictionary, (opf, index) => (
                    <MenuItem key={opf.id} value={opf.id}>
                      {opf.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItemSmall>

            <GridItemSmallA>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="mark-select" disabled>
                  {t('Участник маркировки')}
                </InputLabel>
                <Select
                  required
                  valid={_.toString(isEmptyNull(isMarked))}
                  value={_.defaultTo(isMarked, '')}
                  inputProps={{ id: 'mark-select' }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  IconComponent={'span'}
                  onChange={(event) =>
                    this.handleChange('isMarked', event.target.value)
                  }
                  disabled>
                  <MenuItem key="true" value={true}>
                    {t('Да')}
                  </MenuItem>
                  <MenuItem key="false" value={false}>
                    {t('Нет')}
                  </MenuItem>
                </Select>
                <InputIcon>
                  <IconCheckMenu />
                </InputIcon>
              </FormControl>
            </GridItemSmallA>
          </MuiThemeProvider>
        </GridBlockD>

        <GridBlockE>
          <GridItemSmall>
            <MuiThemeProvider theme={defaultSelectTheme}>
              <FormControl fullWidth={true}>
                <InputLabel disabled htmlFor="activity-select">
                  {t('Вид деятельности')}
                </InputLabel>
                <Select
                  required
                  valid={_.toString(!!_.defaultTo(activityType, ''))}
                  value={_.defaultTo(activityType, '')}
                  inputProps={{ id: 'activity-select' }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  IconComponent={IconDropDown}
                  onChange={(event) =>
                    this.handleChange('activityType', event.target.value)
                  }>
                  {_.map(activityTypesDictionary, (type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {t(type.text)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MuiThemeProvider>
          </GridItemSmall>
          <GridItemC>
            <MuiThemeProvider theme={defaultSelectTheme}>
              <FormControl fullWidth={true}>
                <InputLabel disabled htmlFor="production-select">
                  {t('Основной вид продукции')}
                </InputLabel>
                <Select
                  required
                  valid={_.toString(!!_.defaultTo(productType, ''))}
                  value={_.defaultTo(productType, '')}
                  inputProps={{ id: 'production-select' }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  IconComponent={IconDropDown}
                  onChange={(event) =>
                    this.handleChange('productType', event.target.value)
                  }>
                  {_.map(mainProductTypesDictionary, (type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {t(type.text)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MuiThemeProvider>
          </GridItemC>
          {/* <GridItemSmall>
            <CustomInput
              id="lastName"
              required
              valid={!!_.defaultTo(lastName, '')}
              name="lastName"
              label={t('Фамилия руководителя')}
              onUpdate={() => { }}
              onChange={(value) => this.handleChange('lastName', value)}
              value={_.defaultTo(lastName, '')}
              type="text"
            />
          </GridItemSmall> */}
          <GridItemSmall>
            <CustomInput
              id="fio"
              required
              valid={!!_.defaultTo(fio, '')}
              name="fio"
              label={t('ФИО руководителя')}
              onUpdate={() => { }}
              onChange={(value) => this.handleChange('fio', value)}
              value={_.defaultTo(fio, '')}
              type="text"
            />
          </GridItemSmall>
          <GridItemC>
            {/* <MuiThemeProvider theme={ProfileAutocomplete}> */}
            <CustomInput
              id="position"
              required
              valid={!!_.defaultTo(position, '')}
              name="position"
              label={t('Должность')}
              onUpdate={() => { }}
              onChange={(value) => this.handleChange('position', value)}
              value={_.defaultTo(position, '')}
              type="text"
            />
            {/* </MuiThemeProvider> */}
          </GridItemC>
          {/* <GridItemSmall>
            <CustomInput
              id="firstname"
              required
              valid={!!_.defaultTo(firstName, '')}
              name="firstname"
              label={t('Имя руководителя')}
              onUpdate={() => { }}
              onChange={(value) => this.handleChange('firstName', value)}
              value={_.defaultTo(firstName, '')}
              type="text"
            />
          </GridItemSmall> */}
          <GridItemSmall>
            <CustomInput
              id="email"
              name="email"
              required
              valid={validateEmail(_.defaultTo(email, ''))}
              // label={this.state.errors.email ? t('Введите корректный E-mail') : "E-mail"}
              label={'E-mail'}
              placeholder="E-mail"
              onChange={(value) => this.handleChange('email', value)}
              value={_.defaultTo(email, '')}
              type="email"
              onUpdate={(value) => this.handleChange('email', value)}
            // error={this.state.errors.email}
            />
          </GridItemSmall>
          {/* <GridItemSmall>
            <CustomInput
              id="middleName"
              name="middleName"
              label={t('Отчество руководителя')}
              onUpdate={() => { }}
              onChange={(value) => this.handleChange('middleName', value)}
              value={_.defaultTo(middleName, '')}
              type="text"
            />
          </GridItemSmall> */}
          <GridItemC>
            <PhoneInput
              id={'applicant_phone'}
              // maskGS="+9(999)999-99-99"
              phone={phone ? phone : ''}
              label={t('Рабочий телефон')}
              // placeholder={this.state.errors.workPhone ? t('Рабочий телефон') : t('Введите корректный номер телефона')}
              placeholder={t('Введите корректный номер телефона')}
              defaultLabel={t('Рабочий телефон')}
              onChange={(value) => this.handleChange('phone', value)}
              onUpdate={(value) => this.handleChange('phone', value)}
              phoneValid={phoneValid}
              required
              valid={phoneValid}
            />
          </GridItemC>
        </GridBlockE>
        <ProfileDivider />
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  activityTypesDictionary: state.gs1Reducer.activityTypesDictionary,
  companyTypesDictionary: state.gs1Reducer.companyTypesDictionary,
  mainProductTypesDictionary: state.gs1Reducer.mainProductTypesDictionary,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

Applicant.propTypes = {
  gs1OpfDictionary: PropTypes.array.isRequired,
  activityTypesDictionary: PropTypes.array.isRequired,
  companyTypesDictionary: PropTypes.array.isRequired,
  mainProductTypesDictionary: PropTypes.array.isRequired,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Applicant);
