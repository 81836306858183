import * as React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from 'connected-react-router';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from "react-i18next";
// import memoize from 'memoize-state';
import _ from "lodash";
import download from 'downloadjs';
import { config, isUZ } from "../../../../config";
import {
    Button,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    MuiThemeProvider,
    Typography,
    Tooltip,
    Collapse,
    List,
} from "@material-ui/core";
import {
    AlertBtn,
    HeaderMain,
    HeaderMainCellAlert,
    HeaderMainCellLogin,
    HeaderMainCellSearch,
    HeaderMainInner,
    HeaderMainMenu,
    HeaderMenuSelect,
    HeaderSearchIcon,
    LoginButton,
    LoginName,
    LoginInfo,
    LoginUserIcon,
    LogoutIcon,
    MenuItemDot,
    MenuItemLink,
    MenuItemSpan,
    NotPaper,
    HeaderLogo,
    HeaderLink,
    HeaderLinkTitle,
    ProfileBtn,
    LoginBtn,
    DotsBtn,
    HeaderMainRight,
    HeaderMainLeft,
} from "./Header.styled";
import {
    IconMarked,
    IconDotsNine,
    HeaderLogoUZ,
    IconCaretDown,
    IconSearchField,
    IconNotification,
    IconNewNotification,
    IconCompany,
    IconUser,
    IconGlobe,
    IconSignOut,
    IconDropDown,
    IconFlagRU,
    IconFlagUZ,
    IconCheckMenu,
    IconFlagUS,
} from "../../../icons/customIcons";
// import { HeaderAutocomplete } from "../../../../components";
import { logout } from "../../../../pages/authentication/ducks/Authentification.actions";
import { cleanNotification, loadNotificationRecent, markAsReadNotification } from "../../../catalog/Catalog.action";
import { setLocale } from "../../../../pages/profile/ducks/Profile/Profile.actions";
import { client } from '../../../../api/Client';
import { throwNotification } from '../../../../common/structure';
import NotificationMenu from "../NotificationMenu/NotificationMenu";

// const sortNotifications = memoize((notifications) => _.orderBy(_.takeRight(notifications, 4), ['date'], ['desc']));
// const hasNewNotification = _.memoize((notifications) => !_.isEmpty(_.filter(notifications, { isRead: false })));
const openxmlFormats =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: {
                bell: null,
                userInfo: null,
                elk: null
            },
            notificationModal: false,
            // hasNewNotification: false,
            openLanguage: false,
        };
        // this.accountType = _.get(this.props, 'user.accountType'); // было до 338 задачи: роль-строка, теперь массив.
        this.accountTypes = _.cloneDeep(_.get(this.props, 'user.accountTypes'));
        this.markirovkaDomain = window.env.REACT_APP_REDIRECT_URL;
    }

    componentDidMount() {
        this.props.loadNotificationRecent();
        this.interval = setInterval(this.props.loadNotificationRecent, 60000);
        const savedLang = localStorage.getItem('savedLanguage');

        if (savedLang) {
            this.props.setLocale(savedLang);
        }
    }

    // componentDidUpdate() {
    //     const h = hasNewNotification(this.props.notifications);
    //     if (this.state.hasNewNotification !== h) {
    //         this.setState({ hasNewNotification: h });
    //     }
    // }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleClick = item => event => {
        let anchorEl = this.state.anchorEl;
        let userInfo = this.state.anchorEl.userInfo;
        anchorEl[item] = event.currentTarget;
        // if (item === 'bell' && _.isEmpty(this.props.notifications)) {
        //     this.props.loadNotification();
        // }
        if (item === 'userInfo') {
            this.setState({ userInfo: userInfo });
        };
        this.setState({ anchorEl: anchorEl });
    };

    markAsRead = () => {
        const unReadIds = _.map(_.filter(this.props.notifications, { isRead: false }), 'id');
        if (!_.isEmpty(unReadIds)) {
            this.props.markAsReadNotification(unReadIds);
        }
    };

    handleCloseBell = () => {
        this.handleClose();
        //this.markAsRead();
    };

    handleClose = () => {
        this.setState({ anchorEl: { bell: null, userInfo: null } });
    };

    handleShowLanguage = () => {
        this.setState({ openLanguage: !this.state.openLanguage });
    };
    handleShowAllNotification = () => {
        this.setState({
            notificationModal: !this.state.notificationModal,
            anchorEl: { ...this.state.anchorEl, bell: null }
        });
        //this.markAsRead();
        //this.props.cleanNotifications();
    };

    pageProfile = () => {
        this.handleClose();
        this.props.goToPage('/profile');
    };

    onLogout = () => {
        // localStorage.removeItem('savedFilters');
        // localStorage.removeItem('savedPageSize');
        // localStorage.removeItem('savedSort');
        // localStorage.removeItem('savedFiltersCatalog');
        // localStorage.removeItem('savedPageSizeCatalog');
        // localStorage.removeItem('savedFiltersCompanies');
        // localStorage.removeItem('savedPageSizeCompanies');
        this.handleClose();
        this.props.logout();
    };

    getTitle = (pathname) => {
        const { t } = this.props;
        const goodsUrl = new RegExp(`${config.urls.product}/goods`);
        const draftUrl = new RegExp(`${config.urls.product}/draft`);
        const dataProfile = new RegExp(`${config.urls.profile}/data`);
        const productsProfile = new RegExp(`${config.urls.profile}/products`);
        const accountsProfile = new RegExp(`${config.urls.profile}/accounts`);

        switch (true) {
            case pathname === config.urls.products:
            case goodsUrl.test(pathname):
            case draftUrl.test(pathname): return t('Товары');
            case pathname === config.urls.productNew: return t('Добавить товар');
            case pathname === "/profile":
            case dataProfile.test(pathname):
            case productsProfile.test(pathname):
            case accountsProfile.test(pathname): return t('Профиль');
            case pathname === config.urls.analytic: return t('Дашборд');
            case pathname === config.urls.catalog: return t('Каталог');
            case pathname === config.urls.companies: return t('Компании');
            default: return "";
        }
    };

    onLangChange = (e) => {
        localStorage.setItem('savedLanguage', e.target.value);
        this.props.setLocale(e.target.value);
    };

    onLangClick = (lang) => {
        localStorage.setItem('savedLanguage', lang);
        this.props.setLocale(lang);
    };


    exportIfReport = (e) => {
        if (e.target.classList.contains('download-csv-report')) {
            e.preventDefault();
            const { t } = this.props;
            const message = 'Не удалось скачать отчет';
            const link = e.target.getAttribute('href');
            if (e.currentTarget.getAttribute('id') === "render-props-menu-bell") {
                this.handleCloseBell();
            }
            client()
                .get(link, { responseType: 'arraybuffer' })
                .then((res) => {
                    if (!res.error) {
                        const blob = new Blob([res], { type: openxmlFormats });
                        return download(blob, `${t('Oтчет по импорту')}.xlsx`);
                    } else {
                        const errorObj = JSON.parse(String.fromCharCode.apply(
                            null, new Uint8Array(res.error.response.data))
                        );
                        if (res.error && !_.isEmpty(_.get(errorObj, 'errors'))) {
                            this.props.throwError(t(errorObj.errors[0]));
                        } else this.props.throwError(t(message));
                    }
                })
                .catch((err) => this.props.throwError(t(message)));
        }
    }

    cancelReportLink = (e) => {
        if (e.target.classList.contains('download-csv-report')) {
            e.preventDefault();
        }
    }

    getClassName = (pathname) => this.props.pathname === pathname ? 'active' : '';

    goToProducts = () => {
        const { t } = this.props;
        if (!_.get(this.props, 'user.readyToWorkWithGoods')) {
            this.props.throwError(
                `${t('Идет процесс загрузки карточек из', {
                    gs1Name: t(config.gs1Name)
                })}. ${t('Начать работу с реестром товаров Вы сможете после окончания загрузки')}`
            );
        } else {
            this.props.goToPage(config.urls.products);
        }
    };

    goToAnalytic = () => {
        this.props.goToPage(config.urls.analytic);
    };

    goToCompanies = () => {
        this.props.goToPage(config.urls.companies);
    };

    goToCatalog = () => {
        this.props.goToPage(config.urls.catalog);
    };

    goToProfile = () => {
        this.props.goToPage('/profile');
    }

    render() {
        const { user, t, locale, profile, open, notificationNewCount } = this.props;
        const anchorElELK = this.state.anchorEl.elk;
        const anchorElBell = this.state.anchorEl.bell;
        const openLanguage = this.state.openLanguage;
        const anchorElUserInfo = this.state.anchorEl.userInfo;
        const openELK = Boolean(anchorElELK);
        const openBell = Boolean(anchorElBell);
        const openUserInfo = Boolean(anchorElUserInfo);
        // const notifications = sortNotifications(this.props.notifications);
        // const HeaderLangBtnStyle = { margin: 'auto', fontWeight: "500" }

        return (
            <HeaderMain>
                <HeaderMainInner open={open}>
                    <HeaderMainLeft>
                        {!!user.isElkUser &&
                            <HeaderMainMenu>
                                <MuiThemeProvider theme={DotsBtn}>
                                    <IconButton
                                        aria-label="More"
                                        aria-controls="menu-elk"
                                        aria-haspopup="true"
                                        aria-owns={openELK ? 'menu-elk' : undefined}
                                        onClick={this.handleClick('elk')}
                                        color={"secondary"}
                                    >
                                        <IconDotsNine />
                                    </IconButton>
                                </MuiThemeProvider>
                                <MuiThemeProvider theme={HeaderMenuSelect}>
                                    <Menu id="menu-elk"
                                        anchorEl={anchorElELK}
                                        open={openELK}
                                        onClose={this.handleClose}
                                        disableAutoFocusItem={true}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                        getContentAnchorEl={null}
                                        style={{ top: '25px', left: '13px' }}
                                    >
                                        <MenuItem disableGutters={true}>
                                            <MenuItemLink href={`${this.markirovkaDomain}/dashboard`}>
                                                <MenuItemSpan>
                                                    <ListItemIcon>
                                                        <IconMarked />
                                                    </ListItemIcon>
                                                    <Typography variant="inherit">{t('ЕЛК маркировка')}</Typography>
                                                </MenuItemSpan>
                                            </MenuItemLink>
                                        </MenuItem>
                                        {/* <MenuItem onClick={this.handleClose} disableGutters={true} disabled>
                                            <MenuItemSpan>
                                                <ListItemIcon>
                                                    <IconCatalog />
                                                </ListItemIcon>
                                                <Typography variant="inherit">
                                                    {t('Каталог')}
                                                    <MenuItemDot>•</MenuItemDot>
                                                </Typography>
                                            </MenuItemSpan>
                                        </MenuItem>
                                        <MenuItem onClick={this.handleClose} disableGutters={true}>
                                            <MenuItemLink href={`${this.markirovkaDomain}/dashboard`}>
                                                <MenuItemSpan>
                                                    <ListItemIcon>
                                                        <IconSupport />
                                                    </ListItemIcon>
                                                    <Typography variant="inherit">{t('Техподдержка')}</Typography>
                                                </MenuItemSpan>
                                            </MenuItemLink>
                                        </MenuItem> */}
                                    </Menu>
                                </MuiThemeProvider>
                            </HeaderMainMenu>
                        }


                        <HeaderLogo>
                            <HeaderLogoUZ />
                        </HeaderLogo>

                        <HeaderLink open={this.state.open} onClick={this.goToAnalytic}>
                            <HeaderLinkTitle open={this.state.open} className={this.getClassName(config.urls.analytic)}>
                                {t('Дашборд')}
                            </HeaderLinkTitle>
                        </HeaderLink>

                        <HeaderLink open={this.state.open} onClick={this.goToProducts}>
                            <HeaderLinkTitle open={this.state.open} className={this.getClassName(config.urls.products)}>
                                {t('Товары')}
                            </HeaderLinkTitle>
                        </HeaderLink>

                        {user.isElkUser && _.includes(this.accountTypes, 'content-consumer.companies') &&
                            <HeaderLink open={this.state.open} onClick={this.goToCompanies}>
                                <HeaderLinkTitle open={this.state.open} className={this.getClassName(config.urls.companies)}>
                                    {t('Компании')}
                                </HeaderLinkTitle>
                            </HeaderLink>
                        }

                        {user.isElkUser && _.includes(this.accountTypes, 'content-consumer.catalog') &&
                            <HeaderLink open={this.state.open} onClick={this.goToCatalog}>
                                <HeaderLinkTitle open={this.state.open} className={this.getClassName(config.urls.catalog)}>
                                    {t('Каталог')}
                                </HeaderLinkTitle>
                            </HeaderLink>
                        }
                    </HeaderMainLeft>

                    <HeaderMainRight>
                        {/* {this.accountTypes.some(elem => elem === ('producer' || 'importer')) &&
                            <HeaderMainCellSearch>
                                <MuiThemeProvider theme={SearchInput}>
                                    <HeaderAutocomplete />
                                </MuiThemeProvider>
                                <HeaderSearchIcon>
                                    <IconSearchField />
                                </HeaderSearchIcon>
                            </HeaderMainCellSearch>
                        } */}

                        <HeaderMainCellAlert>
                            <MuiThemeProvider theme={AlertBtn}>
                                <IconButton
                                    id="buttonBell"
                                    aria-owns={openBell ? 'render-props-menu-bell' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleClick('bell')}
                                >
                                    {/* {this.state.hasNewNotification ? <IconNewNotification /> : <IconNotification />} */}
                                    {notificationNewCount && notificationNewCount > 0
                                        ? <IconNewNotification />
                                        : <IconNotification />}
                                </IconButton>
                            </MuiThemeProvider>
                            <MuiThemeProvider theme={NotPaper}>
                                <Menu id="render-props-menu-bell"
                                    anchorEl={anchorElBell}
                                    open={openBell}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    getContentAnchorEl={null}
                                    onClose={this.handleCloseBell}
                                    style={{ top: '35px', left: '-20px' }}
                                >
                                    <NotificationMenu
                                        handleClose={this.handleShowAllNotification}
                                        exportIfReport={this.exportIfReport}
                                        cancelReportLink={this.cancelReportLink}
                                    />
                                </Menu>
                            </MuiThemeProvider>
                        </HeaderMainCellAlert>

                        <HeaderMainCellLogin>
                            <MuiThemeProvider theme={LoginButton}>

                                <HeaderMainMenu>
                                    <MuiThemeProvider theme={LoginBtn}>
                                        <Button onClick={this.handleClick('userInfo')}>
                                            <Tooltip
                                                arrow={true}
                                                placement="bottom-end"
                                                title={t(`${profile.company.nameShort}`)}
                                            >
                                                <LoginUserIcon><IconCompany valid={profile.company.isValid} /></LoginUserIcon>
                                            </Tooltip>
                                            <LoginInfo>
                                                <LoginName>{user.firstName}{' '}{user.lastName}</LoginName>
                                                <IconCaretDown color={'white'} />
                                            </LoginInfo>
                                        </Button>
                                    </MuiThemeProvider>
                                    <MuiThemeProvider theme={HeaderMenuSelect}>
                                        <Menu id="menu-profile"
                                            anchorEl={anchorElUserInfo}
                                            open={openUserInfo}
                                            onClose={this.handleClose}
                                            disableAutoFocusItem={true}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            getContentAnchorEl={null}
                                            style={{ top: '40px' }}
                                        >
                                            <MenuItem disableGutters={true} onClick={this.handleClose}>
                                                <MenuItemLink onClick={this.goToProfile}>
                                                    <MenuItemSpan>
                                                        <ListItemIcon>
                                                            <IconUser />
                                                        </ListItemIcon>
                                                        <Typography variant="inherit">{t('Профиль')}</Typography>
                                                    </MenuItemSpan>
                                                </MenuItemLink>
                                            </MenuItem>
                                            <MenuItem onClick={this.handleShowLanguage} disableGutters={true}>
                                                <MenuItemSpan>
                                                    <ListItemIcon>
                                                        <IconGlobe />
                                                    </ListItemIcon>
                                                    <Typography variant="inherit" >
                                                        {t('Язык интерфейса')}
                                                        {openLanguage
                                                            ? <IconDropDown style={{ transform: 'rotate(180deg)' }} />
                                                            : <IconDropDown />
                                                        }
                                                    </Typography>
                                                </MenuItemSpan>
                                            </MenuItem>
                                            <Collapse in={openLanguage} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    <MenuItem disableGutters={true} value="uz" onClick={() => this.onLangClick('ru')} >
                                                        <MenuItemLink style={{ paddingLeft: '35px' }}>
                                                            <MenuItemSpan>
                                                                <ListItemIcon>
                                                                    <IconFlagRU />
                                                                </ListItemIcon>
                                                                <Typography variant="inherit">{t('Русский')}</Typography>
                                                                {locale === 'ru' ? <IconCheckMenu /> : null}
                                                            </MenuItemSpan>

                                                        </MenuItemLink>
                                                    </MenuItem>
                                                    <MenuItem disableGutters={true} value="ru" onClick={() => this.onLangClick('uz')}>
                                                        <MenuItemLink style={{ paddingLeft: '35px' }}>
                                                            <MenuItemSpan>
                                                                <ListItemIcon>
                                                                    <IconFlagUZ />
                                                                </ListItemIcon>
                                                                <Typography variant="inherit">{t('Узбекский')}</Typography>
                                                                {locale === 'uz' ? <IconCheckMenu /> : null}
                                                            </MenuItemSpan>
                                                        </MenuItemLink>
                                                    </MenuItem>
                                                    <MenuItem disableGutters={true} value="en" onClick={() => this.onLangClick('en')}>
                                                        <MenuItemLink style={{ paddingLeft: '35px' }}>
                                                            <MenuItemSpan>
                                                                <ListItemIcon>
                                                                    <IconFlagUS />
                                                                </ListItemIcon>
                                                                <Typography variant="inherit">{t('Английский')}</Typography>
                                                                {locale === 'en' ? <IconCheckMenu /> : null}
                                                            </MenuItemSpan>
                                                        </MenuItemLink>
                                                    </MenuItem>
                                                </List>
                                            </Collapse>

                                            <MenuItem onClick={this.handleClose} disableGutters={true}>
                                                <MenuItemLink onClick={this.onLogout}>
                                                    <MenuItemSpan>
                                                        <ListItemIcon>
                                                            <LogoutIcon><IconSignOut /></LogoutIcon>
                                                        </ListItemIcon>
                                                        <Typography variant="inherit">{t('Выход')}</Typography>
                                                    </MenuItemSpan>
                                                </MenuItemLink>
                                            </MenuItem>
                                        </Menu>
                                    </MuiThemeProvider>
                                </HeaderMainMenu>

                            </MuiThemeProvider>
                        </HeaderMainCellLogin>
                    </HeaderMainRight>
                </HeaderMainInner>
            </HeaderMain>
        );
    }
}

Header.propTypes = {
    user: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired,
    goToPage: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    markAsReadNotification: PropTypes.func.isRequired,
    setLocale: PropTypes.func.isRequired,
    throwError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.authentificationReducer.user,
    pathname: state.router.location.pathname,
    // notifications: state.catalogReducer.notifications,
    locale: state.profileReducer.locale,
    profile: state.profileReducer.info,
    notificationNewCount: state.catalogReducer.notificationRecentInfo.newCount,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        goToPage: (page) => push(page),
        logout: () => logout(),
        loadNotificationRecent: () => loadNotificationRecent(),
        cleanNotifications: () => cleanNotification(),
        markAsReadNotification: (ids) => markAsReadNotification(ids),
        setLocale: (locale) => setLocale(locale),
        throwError: (message) => throwNotification(message, 'error'),
    }, dispatch);
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(Header)
