import styled from 'styled-components';
import { NotBlockMin } from '../Header/Header.styled';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const SugIcon = styled.div`
  display: flex;
  margin-left: 5px;
`;
export const SugName = styled.div`
  margin-right: 120px;
  width: 280px;
  margin-left: 20px;
  white-space: normal;
  line-height: 1;
`;
export const SugCat = styled.div`
  color: #868686;
  margin-left: auto;
  font-weight: 400;
  padding-right: 130px;
`;

export const NotDialogEllipse = styled.div`
  &:hover{
    svg {
      cursor: pointer;
      circle {
        fill: ${colors.Buttons.textButton.colorHovered};
      }
    }
  }
`;

export const NotDialogBlock = styled(NotBlockMin)`
  padding: 0;
`;

export const NotDialogBlockMin = styled(NotBlockMin)`
  padding: 20px 40px;
  display: flex;
  align-items: center;
`;
export const NotDialogBlockDate = styled(NotBlockMin)`
    background: #F5F5F5;
    padding: 14px 16px 14px 40px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
`;
export const NotDialogContent = styled.div`
  font-size: 16px;
  line-height: 24px;  
  width: 440px;
  margin: 0 17px;
`;
export const NotDialogTitle = styled.h3`
  font-size: 21px;
  line-height: 24px;
  font-weight: 600;
  margin: 0 20px 0 0;  
  text-align: center;
`;
export const NotifMonth = styled.div`
  position: absolute;
  font-weight: 500;
  font-size: 8px;
  color: #fff;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3px 15px;
  top: calc(100% + 10px);
  z-index: 10;
  opacity: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 100px;
  text-align: center;
`;
export const NotNoData = styled.span`
  color: #ff4b4b;
`;
export const NotifLoader = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  right: 0;
  top: 57px;
  margin: 0 auto;
  left: 0;
  border-radius: 0 0 100px 100px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const DialogTitleTop = styled.div`
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;
export const MessageContent = styled.p`
  margin: 0;
  padding: 0 36px ${(props) => props.small ? '16px' : '39px'};
  color: ${(props) => props.small ? colors.Common.textGrey : colors.Common.textColor}; 
  font-size: 16px;
  line-height: 24px;
`;
export const ErrorContent = styled.span`
    display: block;
    font-size: 13px;    
    line-height: 16px;
    color: #E32A38;
    min-height: 16px;    
`;
export const PreloaderContainer = styled.div`
  display: flex;  
  justify-content: center;
`;

export const SearchTabs = createMuiTheme({
  typography: {},
  overrides: {
    MuiTabs: {
      root: {
        margin: '0',
        minHeight: '51px',
        padding: '0',
        overflow: 'visible',

      },
      indicator: {
        backgroundColor: '#FFF',
        height: '1px',
        bottom: '-1px',
        zIndex: '9',
      },
      fixed: {
        overflow: 'visible!important',
        marginBottom: '16px',
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'SegoeUI',
        minHeight: '40px',
        '&$selected': {
          borderBottom: `1px solid ${colors.Tabs.tabLine}`,
        },
        borderBottom: `0`,
        '&$disabled': {
          opacity: '1!important',
        },
        textTransform: 'inherit;',
        fontSize: '18px!important',
        lineHeight: '20px',
        //color: '#63666A!important',
        letterSpacing: '0!important',
      },
      fullWidth: {
        flexGrow: '0',
        minWidth: 'max-content',
        padding: '15px 40px',
      },
      textColorInherit: {
        opacity: '1!important',
        '&$selected': {
          color: `${colors.Common.background}`,
        },
      },

      disabled: {},

    },
  },
});

export const defaultSearchInput = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        width: '100%',
        borderRadius: '4px',
        border: '1px solid #C9C7C7',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
        '&$error': {
          border: '1px solid #E32A38',
        },
      },
      input: {
        color: `${colors.Common.textColor}`,
        padding: '15px 72px 15px 20px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 'normal',
        '&:required': {
          borderRadius: '4px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
      inputMultiline: {
        padding: '0!important',
      },
      multiline: {
        minHeight: '56px',
        padding: '23px 44px 7px 15px',
      },
      error: {},
    },
    MuiInputBase: {
      root: {
        width: '100%',
        marginTop: '0!important',
      },
      input: {
        boxSizing: 'border-box',
        height: 'auto',
        fontFamily: 'SegoeUI',
      },
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
      },
      formControl: {
        transform: 'translate(16px, 16px) scale(1)',
        color: `${colors.Common.textGrey} !important`,
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 8px) scale(1)',
        color: `${colors.Common.textGrey} !important`,
      },
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        '&$focused': {
          color: `${colors.Common.textColor}`,
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});
export const NotAllDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minHeight: '568px',
        minWidth: '749px',
        maxWidth: '749px',
        boxShadow: '0 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
        boxSizing: 'border-box',
        position: 'relative',
        color: `${colors.Common.textColor}`,
        fontFamily: 'SegoeUI',
      },
    },
    MuiTypography: {
      root: {
        width: '100%',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 347px)',
        '&:first-child': {
          paddingTop: '0',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '30px 36px 15px',
        display: 'flex',
        flexDirection: 'column',
        zIndex: '9',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
      },
      text: {
        padding: '3px',
      },
      textPrimary: {
        background: 'none',
        // whiteSpace: 'nowrap',
        color: `${colors.Buttons.textButton.color}`,
        width: 'auto',
        padding: '0',
        fontSize: '16px',
        lineHeight: '24px',
        textTransform: 'inherit',
        transition: '.4s ease',
        '&:hover': {
          backgroundColor: 'transparent',
          color: `${colors.Buttons.textButton.colorHovered}`,
        },
        '&:active': {
          backgroundColor: 'transparent',
          color: `${colors.Buttons.textButton.colorHovered}`,
        },
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        }
      },
    },
    MuiTouchRipple: {
      root: {
        display: 'none',
      },
    },
  },
});
