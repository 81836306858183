import * as React from 'react';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import { IconErrorRed } from '../../common/icons/customIcons';
import {
  InputIcon,
  TextTooltip,
  TextTooltipModeration,
  TextTooltipModerationUpper,
  TextTooltipUpper,
} from '../../common/styles/Common.styled';
import { ModerationIconHolder } from '../../pages/product/view/Product/Product.styled';

const _customTooltip = (
  type,
  id,
  title,
  icon,
  bottom = true,
  handleOpen = () => null,
  author,
) => {
  // const theme =
  //   type === 'moderate'
  //     ? bottom
  //       ? TextTooltipModeration
  //       : TextTooltipModerationUpper
  //     : bottom
  //     ? TextTooltip
  //     : TextTooltipUpper;

  const theme =
    type === 'moderate'
      ? TextTooltipModeration
      : bottom
        ? TextTooltip
        : TextTooltipUpper;

  const props =
    type === 'simple' ? { title } : { id, title, onOpen: handleOpen(id) };

  return (
    <MuiThemeProvider theme={theme}>
      {/* <Tooltip {...props} placement="bottom-end">
        {icon}
      </Tooltip> */}
      {!!author || author === '' ? (
        <Tooltip {...props} placement="bottom-end"
          title={
            <div>
              <b>Комментарий: </b>
              <span>{title}</span>
              <p>
                <i>Модератор: {author}</i>
              </p>
            </div>
          }>
          {icon}
        </Tooltip>
      ) : (
        <Tooltip {...props} placement="bottom-end">{icon}</Tooltip>
      )}
    </MuiThemeProvider>
  );
};

export const SimpleTooltip = (title, icon) => {
  const icons = <InputIcon>{icon}</InputIcon>;
  return _customTooltip('simple', null, title, icons);
};

export const RenderTooltip = (...arg) => _customTooltip('normal', ...arg);

export const ModerateTooltip = (id, title, bottom, handleOpen, author = null) => {
  const icon = (
    <ModerationIconHolder>
      <IconErrorRed width={18} height={18} />
    </ModerationIconHolder>
  );
  return _customTooltip('moderate', id, title, icon, bottom, handleOpen, author);
};
