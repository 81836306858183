export const LOAD_ANALYTIC_PIE_ACTION = 'LOAD_ANALYTIC_PIE';
export const LOAD_ANALYTIC_PIE_SUCCEEDED_ACTION = 'LOAD_ANALYTIC_PIE_SUCCEEDED';
export const LOAD_ANALYTIC_PIE_FAILED_ACTION = 'LOAD_ANALYTIC_PIE_FAILED';

export const CLEAR_ANALYTIC_PIE_DATA_ACTION = 'CLEAR_ANALYTIC_PIE_DATA';

export const LOAD_TRADEMARKS_DICTIONARY_ACTION =
  'LOAD_TRADEMARKS_DICTIONARY_ACTION';
export const LOAD_TRADEMARKS_DICTIONARY_ACTION_SUCCEEDED =
  'LOAD_TRADEMARKS_DICTIONARY_ACTION_SUCCEEDED';
export const LOAD_TRADEMARKS_DICTIONARY_ACTION_FAILED =
  'LOAD_TRADEMARKS_DICTIONARY_ACTION_FAILED';

export const LOAD_CATEGORY_DICTIONARY_ACTION =
  'LOAD_CATEGORY_DICTIONARY_ACTION';
export const LOAD_CATEGORY_DICTIONARY_ACTION_SUCCEEDED =
  'LOAD_CATEGORY_DICTIONARY_ACTION_SUCCEEDED';
export const LOAD_CATEGORY_DICTIONARY_ACTION_FAILED =
  'LOAD_CATEGORY_DICTIONARY_ACTION_FAILED';

export const LOAD_MAIN_STATISTIC_ACTION = 'LOAD_MAIN_STATISTIC_ACTION';
export const LOAD_MAIN_STATISTIC_SUCCEEDED_ACTION =
  'LOAD_MAIN_STATISTIC_SUCCEEDED_ACTION';
export const LOAD_MAIN_STATISTIC_FAILED_ACTION =
  'LOAD_MAIN_STATISTIC_FAILED_ACTION';

export const LOAD_GS1_STATUS_ACTION = 'LOAD_GS1_STATUS_ACTION';
export const LOAD_GS1_STATUS_ACTION_SUCCEEDED =
  'LOAD_GS1_STATUS_ACTION_SUCCEEDED';
export const LOAD_GS1_STATUS_ACTION_FAILED = 'LOAD_GS1_STATUS_ACTION_FAILED';

export const LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION = 'LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION';
export const LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION_SUCCEEDED = 
  'LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION_SUCCEEDED'
export const LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION_FAILED = 
  'LOAD_TRADE_MARK_ELAPSED_STATISTICS_ACTION_FAILED'

export const LOAD_PHOTO_CONTENT_STATISTICS_ACTION = 'LOAD_PHOTO_CONTENT_STATISTICS_ACTION';
export const LOAD_PHOTO_CONTENT_STATISTICS_ACTION_SUCCEEDED = 
  'LOAD_PHOTO_CONTENT_STATISTICS_ACTION_SUCCEEDED'
export const LOAD_PHOTO_CONTENT_STATISTICS_ACTION_FAILED = 
  'LOAD_PHOTO_CONTENT_STATISTICS_ACTION_FAILED'

export const LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION = 'LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION';
export const LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION_SUCCEEDED = 
  'LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION_SUCCEEDED'
export const LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION_FAILED = 
  'LOAD_GOODS_DATE_EXPIRATIONS_STATISTICS_ACTION_FAILED'

export const LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION = 'LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION';
export const LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION_SUCCEEDED = 
  'LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION_SUCCEEDED'
export const LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION_FAILED = 
  'LOAD_GOODS_EXEMPLAR_STATISTICS_ACTION_FAILED'