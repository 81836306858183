import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { config } from './config';

import PrivateRoute from './privateRoute';
import RouteErrorPage from './routeErrorPage';

import AppView from './common/App.view';

import {
  Analytic,
  Authentification,
  BrowserCheck,
  Catalog,
  ChangePassword,
  Companies,
  Page403,
  Page404,
  Page500,
  Product,
  ProductPrint,
  Products,
  ProductVersion,
  Profile,
  Registration,
} from './pages';

const Routes = () => (
  <AppView>
    <Switch>
      <Redirect exact from="/" to="/analytic" />

      <Route path="/login" render={(props) => <Authentification />} />
      <Route path="/change-password" render={(props) => < Authentification changePassword />} />
      {/* <Route path="/registration" render={(props) => <Registration />} /> */}
      {/* <Route path="/browserCheck" render={(props) => <BrowserCheck />} /> */}
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute
        exact
        path={`${config.urls.products}`}
        component={Products}
      />
      <PrivateRoute
        exact
        path={`${config.urls.productNew}`}
        component={Product}
      />
      <PrivateRoute
        exact
        path={`${config.urls.product}/:productId(\\d+)`}
        component={Product}
      />
      <PrivateRoute
        exact
        path={`${config.urls.product}/:dto(goods|draft)/:productId(\\d+)`}
        component={Product}
      />
      <PrivateRoute
        exact
        path={`${config.urls.print}/:dto(goods|draft)/:productId(\\d+)`}
        component={ProductPrint}
      />
      <PrivateRoute
        exact
        path={`${config.urls.print}/:catalog/:productId(\\d+)`}
        component={ProductPrint}
      />
      <PrivateRoute
        exact
        path={`${config.urls.product}/:productId(\\d+)/version/:versionId(\\d+)`}
        component={ProductVersion}
      />
      <PrivateRoute
        exact
        path={`${config.urls.analytic}`}
        component={Analytic}
      />
      <PrivateRoute
        exact
        path={`${config.urls.companies}`}
        component={Companies}
      />
      <PrivateRoute
        exact
        path={`${config.urls.catalog}`}
        component={Catalog}
      />
      <PrivateRoute
        exact
        path={`${config.urls.catalog}/:productId(\\d+)`}
        component={Product}
      />
      <RouteErrorPage path="/403" component={Page403} />
      <RouteErrorPage path="/404" component={Page404} />
      <RouteErrorPage path="/500" component={Page500} />
      <RouteErrorPage component={Page404} />
    </Switch>
  </AppView>
);

export default Routes;
