import styled from 'styled-components';
import colors from './../../../../../common/styles/Common.colors'

export const CertViewBlock = styled.div`
  margin-bottom: 15px;
  position: relative;
  svg {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
export const CertViewBlockIcon = styled.div`
  padding: 10px 0 0 35px;
  position: relative;
`;
export const CertIcon = styled.div`
  position: absolute;
  left: 0;
  top: 10px;
`;
export const CertOwner = styled.span`
  color: ${colors.Common.textColor};
  font-size: 16px;
  line-height: 24px;
  display: block;
  //margin-bottom: 5px;
`;
export const CertName = styled.span`
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin-bottom: 3px;
`;
export const CertViewMainBlock = styled.div`
    background: #F9F9F9;
    padding: 24px 28px;
    margin-bottom 48px;
`;

export const CertTitleLine = styled.div`
  white-space: normal;
`;
export const CertTitle = styled.span`
  color: #677380;
  font-size: 16px;
  line-height: 24px;
`;
export const CertTitleName = styled.span`
  color: ${colors.Common.textColor};
  font-size: 16px;
  line-height: 24px;
`;
export const SelectedCert = styled.div`
  flex-basis: 50%;
  flex-shrink: 0;
  span {
    
    white-space: normal;
    word-break: break-word;
  }
`;