import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  IconButton,
} from '@material-ui/core';
import { CertificateSelect } from '../index';
// import { ConfirmDialogStyled as CDS } from '../styled';
// import { DialogTitleContent } from '../../common/styles/Common.styled';
// import { FilterBtn } from '../../pages/products/productGrid/filters/Filters.styled';
import {
  CertSelectDialog,
  // DialogQuestion,
  DialogSelect,
} from "./CertificateSelectModal.styled";
import { IconClose } from "../../common/icons/customIcons";

class CertificateSelectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publishOnWebsite: 'yes'
    };
  }


  onSubmit = () => {
    let params = {};
    if (this.props.questionAboutPublishing) {
      params = { ...params, publishOnWebsite: this.state.publishOnWebsite === 'yes' }
    }
    this.props.onSubmit(this.props.cert, params);
    if (this.props.onGetAllCerts)
      this.props.onGetAllCerts();
  }

  render() {
    const { certificates, cert, onSelectCert, onCancel, acceptBtnText, t } = this.props;
    const validCert = _.get(cert, 'isValid', false);
    const answer = this.props.questionAboutPublishing && _.isNull(this.state.publishOnWebsite);

    const disabledOnSubmit = !validCert || answer;

    return (
      <MuiThemeProvider theme={CertSelectDialog}>
        <Dialog open>
          <DialogTitle disableTypography>
            <IconButton
              onClick={onCancel}>
              <IconClose />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            {this.props.questionAboutPublishing && (
              // <DialogQuestion>
              <p>{t('Публикация карточки упаковки товара')}</p>
              // </DialogQuestion>
            )}

            <DialogSelect>
              <CertificateSelect
                certificates={certificates}
                onSelectCert={onSelectCert}
                cert={cert}
              />
            </DialogSelect>
          </DialogContent>

          <DialogActions>
            {/* <CDS.ConfirmDialogButtons> */}
            {this.props.isCancelButton && (
              <Button
                color="secondary"
                onClick={onCancel}
              >
                {t('Отменить')}
              </Button>
            )}
            <Button
              color="primary"
              disabled={disabledOnSubmit}
              onClick={this.onSubmit}>
              {acceptBtnText}
            </Button>
            {/* </CDS.ConfirmDialogButtons> */}
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

CertificateSelectModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSelectCert: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  certificates: PropTypes.array.isRequired,
  cert: PropTypes.any.isRequired,
  acceptBtnText: PropTypes.string.isRequired,
  isCancelButton: PropTypes.bool.isRequired,
  questionAboutPublishing: PropTypes.bool.isRequired
};

CertificateSelectModal.defaultProps = {
  isCancelButton: false,
  questionAboutPublishing: false,
};

export default withTranslation()(CertificateSelectModal);
