import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  defaultTo,
  forEach,
  forIn,
  get,
  has,
  isNil,
  map,
  reverse,
  trim,
} from 'lodash';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  IconClose,
  IconDropDown,
} from '../../../../common/icons/customIcons';
import {
  DefaultVersionsPopover,
  ProductVersionMdiaItem,
  ProductVersionMediaItemInner,
  VersionImages,
  VersionImagesItem,
  VersionInfoChanges,
  VersionInfoDate,
  VersionInfoView,
  VersionLineInner,
  VersionLineInnerBottom,
  VersionLineInnerTop,
  BlockContainer,
} from './ProductVersions.styled';
import {
  clearVersions,
  loadProductVersions,
} from '../../ducks/Products.actions';
import * as selectors from '../../productGrid/ducks/ProductGrid.selectors';
import {
  getPackagingType,
  getPhotoType,
  PackagingFieldName,
} from '../../../../common/constants/Packaging';
import PhotoPopover from '../PhotoPopover/PhotoPopover';
import { config } from '../../../../config';
import { gtinDisplay } from '../../../../common/utils/utils';

const oldValueThrough = (str) =>
  str ? (
    <span>
      <span style={{ textDecoration: 'none' }}>{trim(str)}</span> →
    </span>
  ) : (
    ''
  );

const getDefaultTo = (obj, path) => defaultTo(get(obj, path, ''), '');

const twoValue = (obj, path) => {
  const str = getDefaultTo(obj, path);
  return str ? ` (${str})` : '';
};

const strPadEnd = (obj, path) => {
  const str = getDefaultTo(obj, path);
  return str ? `${str} ` : '';
};

const boxChangesAttributes = (block, t) => {
  const changesField = [];
  forEach(block, (item, k) => {
    if (has(item, 'value.oldValue') || has(item, 'type.oldValue')) {
      const textOld =
        strPadEnd(item, 'value.oldValue') +
        strPadEnd(item, 'type.oldValue') +
        twoValue(item, 'valueTitle.oldValue');
      const textNew =
        strPadEnd(item, 'value.newValue') +
        strPadEnd(item, 'type.newValue') +
        twoValue(item, 'valueTitle.newValue');
      changesField.push(
        <div key={`at_${k}`}>
          <p>{t('Потребительская упаковка')}:</p>
          <p>{item.name} →{' '}
            {oldValueThrough(textOld)} {textNew || t('значение удалено')}
          </p>
        </div>,
      );
    }
  });
  return changesField;
};

const boxChangesIdentifier = (block, t) => {
  const changesField = [];
  forEach(block, (item, key) => {
    const nameLevel = t(getPackagingType(item.level));
    const name = t('Весогабаритные характеристики');

    changesField.push(
      <div key={`idenf_${key}_${item.level}`}>
        <p>{name}:</p>
        <p>{t('Уровень упаковки')} → {nameLevel}</p>
      </div>,
    );
    forIn(item, (v, k) => {
      if (has(v, 'oldValue')) {
        changesField.push(
          <div key={`idenf_${key}_${k}`}>
            <p>{name}:</p>
            <p>
              {t(PackagingFieldName(k))} → {oldValueThrough(v.oldValue)}{' '}
              {v.newValue}
            </p>
          </div>,
        );
      }
    });
  });
  return changesField;
};

const boxChangesImages = (block, t) => {
  const changesField = [];
  forEach(block, (item, key) => {
    let stringType = '';
    let stringUrl = '';
    let stringGtin = '';
    const oldType = t(getPhotoType(item.type.oldValue)) || '';
    const newType = t(getPhotoType(item.type.newValue));
    forIn(item, (v, k) => {
      if (k === 'type') {
        stringType = !isNil(v.oldValue) ? (
          <div>
            ${oldValueThrough(oldType)} ${newType}
          </div>
        ) : (
          newType
        );
      }
      if (k === 'url') {
        stringUrl = !isNil(v.oldValue) ? (
          <div>
            <ProductVersionMdiaItem>
              <ProductVersionMediaItemInner>
                <PhotoPopover
                  name={oldType}
                  photo={{ previewUrl: v.oldValue, url: v.oldValue }}
                  showName={true}
                />
              </ProductVersionMediaItemInner>
            </ProductVersionMdiaItem>
            <ProductVersionMdiaItem>
              <ProductVersionMediaItemInner>
                <PhotoPopover
                  name={newType}
                  photo={{ previewUrl: v.newValue, url: v.newValue }}
                  showName={true}
                />
              </ProductVersionMediaItemInner>
            </ProductVersionMdiaItem>
          </div>
        ) : (
          <ProductVersionMdiaItem>
            <ProductVersionMediaItemInner>
              <PhotoPopover
                name={newType}
                photo={{ previewUrl: v.newValue, url: v.newValue }}
                showName={true}
              />
            </ProductVersionMediaItemInner>
          </ProductVersionMdiaItem>
        );
      }
      if (k === 'gtin') {
        if (has(v, 'oldValue')) {
          stringGtin = (
            <div key={`img_${key}_${k}`}>
              <p>{t('Фотоконтент')}:</p>
              <p> GTIN → {oldValueThrough(v.oldValue)}{' '}
                {v.newValue}
              </p>
            </div>
          );
        }
      }
    });

    if (has(item, 'type.oldValue') || has(item, 'url.oldValue')) {
      changesField.push(
        <div key={`img_${key}`}>
          <p>{t('Фотоконтент')}:</p>
          <VersionImages>
            <p>{t('Ракурс')} →</p>
            <VersionImagesItem>
              {stringType}
              {stringUrl}
            </VersionImagesItem>
          </VersionImages>
        </div>,
      );
    }

    if (stringGtin) {
      changesField.push(stringGtin);
    }
  });
  return changesField;
};

class ProductVersions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenViewerBlock: false,
    };
  }

  handleChangeView = (key) => {
    this.setState((prevState) => ({
      isOpenViewerBlock: prevState.isOpenViewerBlock !== key ? key : false,
    }));
  };

  componentDidMount() {
    this.props.onLoadProductVersions(this.props.product.id);
  }

  componentWillUnmount() {
    this.props.onClearVersions();
  }

  render() {
    const { handleClose, versions, product, t } = this.props;

    return (
      <MuiThemeProvider theme={DefaultVersionsPopover}>
        <Dialog open>
          <DialogTitle disableTypography>
            <div>
              <Typography variant="h2">{t('Версии изменений карточки товара')}</Typography>
              <span>{gtinDisplay(product.gtin)}</span>
            </div>
            <IconButton onClick={handleClose}>
              <IconClose />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            {map(versions, (item, index) => {
              const key = `vid_${index}`;
              return (
                <VersionLineInner key={key}>
                  <VersionLineInnerTop>
                    <div style={{ display: 'flex' }}>
                      <VersionInfoChanges
                        onClick={() => this.handleChangeView(key)}>
                        {this.state.isOpenViewerBlock === key ? (
                          <IconDropDown style={{ width: '20px', height: '20px', transform: 'rotate(180deg)' }} />
                        ) : (
                          <IconDropDown style={{ width: '20px', height: '20px' }} />
                        )}{' '}
                      </VersionInfoChanges>
                      <VersionInfoDate>
                        <Moment format="DD.MM.YYYY">{item.endDate}</Moment>
                      </VersionInfoDate>
                    </div>
                    <VersionInfoView>
                      <Link
                        target="_blank"
                        to={`${config.urls.product}/${item.goodId}/version/${item.version}`}>
                        {t('Посмотреть')}{' '}
                      </Link>
                    </VersionInfoView>
                  </VersionLineInnerTop>
                  {this.state.isOpenViewerBlock === key && (
                    <VersionLineInnerBottom>
                      {reverse(
                        map(item.changes, (block, key) => (
                          <BlockContainer key={`changes_${key}`}>
                            {key === 'attributes' &&
                              boxChangesAttributes(block, t)}
                            {key === 'identifier' &&
                              boxChangesIdentifier(block, t)}
                            {key === 'images' && boxChangesImages(block, t)}
                          </BlockContainer>
                        )),
                      )}
                    </VersionLineInnerBottom>
                  )}
                </VersionLineInner>
              );
            })}
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

ProductVersions.propTypes = {
  onLoadProductVersions: PropTypes.func.isRequired,
  onClearVersions: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  versions: PropTypes.arrayOf(PropTypes.object).isRequired,
  product: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLoadProductVersions: (productId) => loadProductVersions(productId),
      onClearVersions: () => clearVersions(),
    },
    dispatch,
  );
};

const mapStateToProps = (state) => ({
  versions: selectors.versions(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ProductVersions);
