import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators, compose } from 'redux';
import { push } from 'connected-react-router';
import { config } from "../../../config";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MuiThemeProvider,
    Tooltip,
    Typography,
    IconButton,
} from '@material-ui/core';
import StatisticsTable from './StatisticsTable';
import StatisticsPie from './StatisticsPie';
import {
    CreationSiteDialog,
    CreationSiteDialogPie,
    TotalRecordsDialog,
    DialogActionsRight,
} from '../styled/ModalStateMore.styled';
import _ from 'lodash';
import { IconClose, IconQuestion } from '../../../common/icons/customIcons';

const renderTypeToContent = (type) => {
    switch (type) {
        case 'goodsDateExpirationCount':
            return 'Статистика по просроченным разрешительным документам';
        case 'goodsExemplarCount':
            return 'Статистика по карточкам в совместном управлении';
        case 'goodsTradeMarkElapsed':
            return 'Статистика по просроченным свидетельствам на Товарный знак';
        case 'goodsPhotoHasWatermarkCount':
            return 'Статистика по фотоконтенту';
        default:
            return;
    }
};

const FiltersInit = {
    category: null,
    gtin: null,
    packages: null,
    photo: null,
    status: null,
    created: {
        from: null,
        to: null,
    },
    publicationTime: {
        from: null,
        to: null,
    },
    brand: null,
    name: null,
    contentProvider: {
        name: null,
        inn: null,
    },
    categoryTnved: null,
    packingPurpose: null,
    isValid: null,
    isExemplar: null,
    attributes: null,
};


class ModalStateMore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    changeGridParams = (value, name) => {
        this.props.goToPage(config.urls.products);

        let savedFilters;
        //очищаем все фильтры на гриде
        localStorage.removeItem('savedFilters');
        localStorage.setItem('savedFilters', JSON.stringify(FiltersInit));
        //записываем список кода упаковок в фильтр
        savedFilters = localStorage.getItem('savedFilters');
        savedFilters = JSON.parse(savedFilters);
        savedFilters[name] = value;
        localStorage.setItem('savedFilters', JSON.stringify(savedFilters));
    }

    render() {
        const {
            t,
            handleClose,
            typeModal,
            goodsDateExpirationStatistics,
            goodsExemplarStatistics,
            tradeMarkElapsedStatistics,
            photoContentStatistic,
        } = this.props
        const isTable = typeModal === 'goodsDateExpirationCount' || typeModal === 'goodsExemplarCount' || typeModal === 'goodsTradeMarkElapsed'
        const isPie = typeModal === 'goodsPhotoHasWatermarkCount'
        const sumValues = _.sum(_.values(photoContentStatistic))
        const gtinGoodsDateExpirationUniq = Array.from(new Set(goodsDateExpirationStatistics.map(i => i.gtin)))
        const gtinTradeMarkElapsedUniq = Array.from(new Set(tradeMarkElapsedStatistics.map(i => i.gtin)))

        const gtin = (type) => {
            switch (type) {
                case 'goodsDateExpirationCount':
                    return gtinGoodsDateExpirationUniq;
                case 'goodsTradeMarkElapsed':
                    return gtinTradeMarkElapsedUniq;
                default:
                    return;
            }
        }
        const totalRecords = (type) => {
            switch (type) {
                case 'goodsDateExpirationCount':
                    return goodsDateExpirationStatistics.length;
                case 'goodsExemplarCount':
                    return goodsExemplarStatistics.length;
                case 'goodsTradeMarkElapsed':
                    return tradeMarkElapsedStatistics.length;
                default:
                    return;
            }
        };
        return (
            <MuiThemeProvider theme={typeModal === 'goodsPhotoHasWatermarkCount' ? CreationSiteDialogPie : CreationSiteDialog} >
                <Dialog open>
                    <DialogTitle disableTypography>
                        <Typography variant="h2">
                            {t(renderTypeToContent(typeModal))}
                        </Typography>
                        <IconButton
                            onClick={handleClose}>
                            <IconClose />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {isTable && (
                            <StatisticsTable
                                t={t}
                                typeModal={typeModal}
                                goodsDateExpirationStatistics={goodsDateExpirationStatistics}
                                goodsExemplarStatistics={goodsExemplarStatistics}
                                tradeMarkElapsedStatistics={tradeMarkElapsedStatistics}
                            />
                        )}
                        {isPie && (
                            <StatisticsPie
                                t={t}
                                sumValues={sumValues}
                                photoContentStatistic={photoContentStatistic}
                            />
                        )}
                    </DialogContent>
                    {(isTable && totalRecords(typeModal))
                        ? (
                            <MuiThemeProvider theme={TotalRecordsDialog}>
                                <DialogActions>
                                    <span>
                                        {`${t('Всего записей')}: ${totalRecords(typeModal)}`}
                                    </span>
                                    <DialogActionsRight>
                                        <Tooltip
                                            placement="top"
                                            title={t(`Товары будут отфильтрованы в таблице в разделе Товары`)}
                                        >
                                            <div><IconQuestion /></div>
                                        </Tooltip>
                                        <Button
                                            color="secondary"
                                            onClick={
                                                typeModal !== 'goodsExemplarCount'
                                                    ? () => this.changeGridParams(gtin(typeModal), 'gtin')
                                                    : () => this.changeGridParams(true, 'isExemplar')
                                            }>
                                            {t('Отфильтровать товары')}
                                        </Button>
                                    </DialogActionsRight>
                                </DialogActions>
                            </MuiThemeProvider>
                        )
                        : null
                    }
                </Dialog>
            </MuiThemeProvider>
        )
    }
}

ModalStateMore.propTypes = {

};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            goToPage: (page) => push(page),
        },
        dispatch,
    );
};

const mapStateToProps = (state) => ({

});

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ModalStateMore);