import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import { GridBlock } from '../../common/styles/Common.styled';
import colors from '../../common/styles/Common.colors';

export const ErrorContent = styled.span`
  display: block;
  font-size: 13px;    
  line-height: 16px;
  color: #E32A38;
  min-height: ${props => props.modal ? "16px" : "32px"};
  margin-bottom: ${props => props.modal ? "12px" : "2px"};
`;

export const ProfileTabCont = styled.div`
  position: relative;
  padding: 40px;
  border-radius: 0 0 4px 4px;
  background: #fff;
  height: calc(100% - 109px);
  //min-height: 700px;

  @media (max-width: 1200px) {
    padding: 70px 20px 100px;
  }
`;
export const ProductGS1Cont = styled(ProfileTabCont)`
  padding: 20px 0 0;
  height: ${props => props.value === 0 ? '100%' : 'fit-content'};
  min-height: calc(100vh - 303px);
`;
export const ProfileMemberDetailsCont = styled(ProfileTabCont)`
  min-height: calc(100vh - 303px);
`;
export const ProfileDivider = styled.div`
  height: 60px;
`;
export const ProfileFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px #E7E7E7 solid;
  background-color: #FFFFFF;
  padding: 32px 36px;
  position: fixed;
  right: 40px;
  left: 40px;
  bottom: 0px;
  z-index: 2;
  border-bottom: 20px #fafafa solid;
`;
export const ProfileFooterButton = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ProfileFooterButtonLine = styled.div`
  height: 29px;
  position: fixed;
  bottom: 0;
  background-color: #fafafa;
  z-index: 2;
`;
export const GridBlockB = styled(GridBlock)`
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr;
  grid-template-rows: 1fr auto auto;
`;
export const GridItem = styled.div`
  grid-column: 1 / -1;
`;
export const NewBtnsContainer = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
`;
export const GridItemC = styled.div`
  grid-column: col 2 / span 2;
`;
export const AdressEachBlock = styled.div`
  position: relative;
  margin-bottom: 20px;
`;
export const AvatarCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const LoginAvatarCell = styled.div`
  width: 40px;
  height: 40px;
  background: ${colors.Header.profileLogo};
  font-size: 16px;
  color: ${colors.Header.profileColor};
  font-weight: 500;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
`;
export const ProfileGridWrap = styled.div`
  padding: 0 36px;
  height: calc(100% - 106px);

`;
export const GridTableProfile = styled.div`
  height: 100%;
  .ReactTable .rt-noData {
    display: none;
  }
`;
export const TabEmpty = styled.div`
  flex-grow: 1;
  border-bottom: 1px solid #E7E7E7;
  height: 57px;
`;
export const LinkButton = styled.div`
  min-width: 215px;
  min-height: 44px;
  max-height: 44px;
  background-color: ${colors.Buttons.buttonPrimary.background};
  position: relative;
  border-radius: 4px;
  &:hover,
  &:active {
    background-color: ${colors.Buttons.buttonPrimary.backgroundHovered};
  }

  a {
    text-decoration: none;
    color: #FFFFFF;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    white-space: nowrap;
    text-align: center;
    padding: 10px 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;
export const DefaultTabs = createMuiTheme({
  typography: {},
  overrides: {
    MuiTabs: {
      root: {
        //marginTop: '10px',
        backgroundColor: `#ffffff`,
        height: '57px',
      },
      indicator: {
        display: 'none',
        height: '4px',
        backgroundColor: `${colors.Tabs.tabLine}`,
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'Segoe UI',
        padding: '16px 50px 16px 40px',
      },
      fullWidth: {
        flexGrow: '1',
        minWidth: 'max-content',
        maxWidth: 'max-content',
        borderBottom: '1px solid #E7E7E7',
        height: '57px',
        '&:first-child': {
          border: '1px solid #E7E7E7',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          borderBottom: 'none',
        },
        '&:last-child': {
          flexGrow: '3',
          display: 'flex',
          justifyContent: 'flex-start',
        },
      },
      wrapper: {
        textTransform: 'none',
        fontSize: '21px',
        fontWeight: 'normal',
        lineHeight: '24px',
        letterSpacing: '0',
        width: 'auto',
      },
      textColorInherit: {
        opacity: '1',
        color: `${colors.Common.textGrey}`,
        '&$selected': {
          color: `${colors.Common.background}`,
        },
        '&$disabled': {
          color: '#E7E7E7',
          opacity: '1',
        },
      },
    },
  },
});
export const SmallTabs = createMuiTheme({
  typography: {},
  overrides: {
    MuiTabs: {
      root: {
        margin: '10px 0 15px',
        minHeight: '40px',
      },
      indicator: {
        backgroundColor: '#FFF',
        height: '4px',
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'SegoeUI',
        minHeight: '40px',
        borderBottom: '1px solid #F2F2F2',
        '&$selected': {
          border: '1px solid #F2F2F2',
          borderBottom: '1px solid transparent',
        },
      },
      wrapper: {
        textTransform: 'uppercase',
        fontSize: '10px',
        fontWeight: '700',
        lineHeight: '20px',
      },
      selected: {},
    },
  },
});
export const CloseBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '25px',
        maxHeight: '45px',
        marginLeft: 'auto',
        '&:hover': {
          borderRadius: '2px'
        },
      },
      text: {
        padding: '2px',
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
        '&:hover': {
          background: 'none !important',
          borderRadius: '2px'
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    }
  },
});
export const CreationCloseBtns = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '22px',
        height: '30px',
        position: 'absolute',
        top: '30px',
        right: '30px',
      },
      text: {
        padding: '3px',
        color: '#868686',
        fontSize: '14px',
        textTransform: 'uppercase',
      },
    },
  },
});
export const CreationTrashBtns = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '22px',
        height: '30px',
      },
      text: {
        padding: '3px',
        color: '#868686',
        fontSize: '14px',
        textTransform: 'uppercase',
      },
    },
  },
});
export const CreationEyeBtns = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '22px',
        height: '30px',
      },
      text: {
        padding: '3px',
        color: '#868686',
        fontSize: '14px',
        textTransform: 'uppercase',
      },
    },
  },
});
export const GridButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        color: `${colors.Common.textColor}`,
        border: '1px solid #DCDCDC',
        '&$disabled': {
          color: '#52535A',
          opacity: '.7',
        },
        '&:hover': {
          borderColor: '#F5F5F5',
        },
      },
      label: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
        textTransform: 'none',
      },
      disabled: {},
      text: {
        padding: '10px 24px',
      },
    },
  },
});
export const BlueBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '44px',
        maxHeight: '44px',
        backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
        textTransform: 'inherit',
        color: '#FFFFFF',
        fontWeight: '600',
        '&$disabled': {
          color: '#FFFFFF',
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered} !important`,
        },
      },
      text: {
        padding: '10px 36px',
      },
    },
  },
});
export const WhiteBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '44px',
        maxHeight: '44px',
        marginRight: '20px',
        border: '1px solid #DCDCDC',
        textTransform: 'inherit',
        color: `${colors.Common.textColor}`,
        fontWeight: '600',
        '&:hover': {
          borderColor: '#F5F5F5',
        },
      },
      text: {
        padding: '10px 24px',
      },
    },
  },
});
