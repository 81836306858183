import * as React from 'react';
import * as PropTypes from 'prop-types';
import colors from '../styles/Common.colors';

class Root extends React.Component {
  showErrorAlert(message: string, timeout: number, color: string) {
    this.props.alert.error(message, {
      timeout,
      containerStyle: { backgroundColor: color || '' },
    });
  }

  showErrorsAlerts(errors: any[], timeout: number) {
    errors.forEach((error) => {
      this.props.alert.error(error.text, { timeout });
    });
  }

  showSuccessAlert(message: string, timeout: number) {
    this.props.alert.success(message, { timeout });
  }

  showInfoAlert(message: string, timeout: number) {
    this.props.alert.info(message, {
      timeout,
      containerStyle: {
        backgroundColor: '#ffffff',
        border: `1px solid ${colors.Common.background}`,
        color: `${colors.Common.textColor}`,
      },
    });
  }

  showSystemErrorAlert(message: string) {
    this.props.alert.show(message, {
      deleteCloseButton: true,
      containerStyle: {
        backgroundColor: '#D61515',
      },
    });
  }

  showErrorWithoutTimeout(message: string) {
    this.props.alert.show(message, {
      deleteCloseButton: true,
    });
  }

  showAlert(type, message, timeout = 5000) {
    switch (type) {
      case 'success': {
        this.showSuccessAlert(message, timeout);
        break;
      }
      case 'info': {
        this.showInfoAlert(message, timeout);
        break;
      }
      case 'error': {
        this.showErrorAlert(message, timeout);
        break;
      }
      case 'errorRed': {
        this.showErrorAlert(message, timeout, '#D61515');
        break;
      }
      case 'systemerror': {
        this.showSystemErrorAlert(message);
        break;
      }
      case 'errorWithoutTimeout': {
        this.showErrorWithoutTimeout(message);
        break;
      }
      default:
        break;
    }
  }

  removeAlert(alert) {
    this.props.alert.remove(alert);
  }
}

Root.propTypes = {
  alert: PropTypes.object,
};

export default Root;
