import { createMuiTheme } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../common/styles/Common.colors';

export const ImportModalDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '628px',
        maxWidth: '628px',
        minHeight: '544px',
        borderRadius: '8px',
        padding: '32px 0 0',
        boxSizing: 'border-box',
        fontFamily: 'SegoeUI',
        color: `${colors.Common.textColor}`,
      },
    },
    MuiDialogContent: {
      root: {
        padding: '16px 18px 0',
        '& p': {
          margin: '0 18px 24px',
          fontSize: '18px',
          lineHeight: '24px',
          fontWeight: '600',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 36px 0',
      },
    },
    MuiTypography: {
      h2: {
        fontSize: '21px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
      }
    },
    MuiButton: {
      root: {
        borderRadius: '4px',
        minWidth: '30px',
        color: `${colors.Buttons.buttonPrimary.color}`,
        background: `${colors.Buttons.buttonPrimary.background}`,
        transition: 'all .4s ease',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        textTransform: 'none',
        padding: '10px 35px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
      },
      textPrimary: {
        color: `${colors.Buttons.buttonPrimary.color}`,
      },
      textSecondary: {
        background: 'none',
        marginRight: '20px',
        border: '1px solid #DCDCDC',
        color: `${colors.Common.textColor}`,
        '&:hover': {
          backgroundColor: '#F5F5F5 !important',
          color: `${colors.Common.textColor} !important`,
        },
        '&$disabled': {
          color: '#C9C7C7',
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
        borderRadius: 'none',
        maxWidth: '24px',
        minWidth: '24px',
        maxHeight: '24px',
        minHeight: '24px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: '32px 36px',
        borderTop: '1px solid #E7E7E7',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& span': {
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '600',
          marginRight: '8px'
        }
      },
      spacing: {
        '&>:not(:first-child)': {
          margin: '0 !important',
        }
      },
    },
  },
});

export const ImportDialogMin = styled.div`
  min-height: 282px;
  margin-top: 25px;  
  display: flex;
  align-items: center;  
  flex-direction: column;
  justify-content: center;
  border: 1px dashed #C9C7C7;
  border-radius: 4px;
  >p {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 16px;
  }
`;

export const ImportDialogMinInner = styled.div`  
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: space-between;
  width: 100%;
`;
