import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { debounce, defaultTo, isEqual } from 'lodash';
import { throwNotification } from '../../../../../common/structure';
import {
  CustomInput,
  SimpleTooltip,
  PhoneInput,
} from '../../../../../components';
// import {validateInn} from "../../../../../common/utils/validationBankDetails";
import {
  IconError,
  IconPreloader,
  IconValid,
} from '../../../../../common/icons/customIcons';
import { InputIcon } from '../../../../../common/styles/Common.styled';
import {
  SubItemContainer,
  SubItemInn,
  SubItemName,
  SubItemGcp,
  SubItemGln,
  SubItemEmail,
  SubItemPhone,
  SubItemCountry,
  SubItemErrorContent,
  SubItemAdress,
} from '../styled/SubAccountItem.styled';
import { validateInnSubAccount } from '../../../ducks/SubAccount/SubAccount.actions';
import _ from 'lodash';
import { Element } from 'react-scroll';
import { ConsumerItem } from '../../../../product/view/itemElement';
import {
  validateEmailAndEmpty,
  validatePhone,
} from '../../../../../common/utils/Validation';

const WAIT_INTERVAL = 1000;

const getDefaultTo = (obj, path) => _.defaultTo(_.get(obj, path, ''), '');

const findItemIndex = (attributes, item, index) => {
  let findIndex;
  let i = 0;
  for (let k = 0; k < attributes.length; k++) {
    if (attributes[k].id === item.id) {
      if (i === index) {
        findIndex = k;
        break;
      }
      i++;
    }
  }
  return findIndex;
};

class SubAccountItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      innValidation: {
        process: false,
        successed: false,
      },
      emailValidation: {
        process: false,
        successed: false,
      },
      data: {
        attrGroup: [
          {
            id: 25,
            name: 'Идентификация Участников Оборота Товаров',
            attributes: [
              {
                dataQualifier: null,
                description: null,
                disabled: false,
                fieldType: 'text',
                groupId: 25,
                gtin: '',
                gtinRequired: false,
                id: 15677,
                isFromGs1: false,
                isGtinAffect: true,
                isNtinAffect: true,
                isPresetOnly: false,
                markedProductRequired: false,
                modalView: null,
                multiplicity: false,
                name: this.props.t('Юридический адрес'),
                preset: [],
                presetError: null,
                required: true,
                sellProductRequired: false,
                sortOrder: 5,
                type: 'r',
                uId: null,
                url: null,
                value: null,
                valueId: null,
                valueTitle: null,
                valueType: null,
                // valuesTypePreset: [
                //   { id: 'RU', text: 'RU' },
                //   { id: 'BY', text: 'BY' },
                //   { id: 'UA', text: 'UA' },
                //   { id: 'PL', text: 'PL' },
                //   { id: 'IT', text: 'IT' },
                //   { id: 'DE', text: 'DE' },
                //   { id: 'FR', text: 'FR' },
                //   { id: 'RO', text: 'RO' },
                //   { id: 'GB', text: 'GB' },
                //   { id: 'FI', text: 'FI' },
                //   { id: 'TR', text: 'TR' },
                //   { id: 'SL', text: 'SL' },
                //   { id: 'SE', text: 'SE' },
                //   { id: 'CH', text: 'CH' },
                //   { id: 'CN', text: 'CN' },
                //   { id: 'HU', text: 'HU' },
                //   { id: 'ES', text: 'ES' },
                //   { id: 'CZ', text: 'CZ' },
                //   { id: 'BE', text: 'BE' },
                //   { id: 'NL', text: 'NL' },
                //   { id: 'AT', text: 'AT' },
                //   { id: 'LT', text: 'LT' },
                //   { id: 'EE', text: 'EE' },
                //   { id: 'BG', text: 'BG' },
                //   { id: 'BR', text: 'BR' },
                //   { id: 'US', text: 'US' },
                //   { id: 'ID', text: 'ID' },
                //   { id: 'GR', text: 'GR' },
                //   { id: 'IE', text: 'IE' },
                //   { id: 'TH', text: 'TH' },
                //   { id: 'JP', text: 'JP' },
                //   { id: 'SI', text: 'SI' },
                //   { id: 'SG', text: 'SG' },
                //   { id: 'MY', text: 'MY' },
                //   { id: 'AU', text: 'AU' },
                //   { id: 'IL', text: 'IL' },
                //   { id: 'HR', text: 'HR' },
                //   { id: 'GE', text: 'GE' },
                //   { id: 'RS', text: 'RS' },
                //   { id: 'LK', text: 'LK' },
                //   { id: 'DK', text: 'DK' },
                //   { id: 'MD', text: 'MD' },
                //   { id: 'MX', text: 'MX' },
                //   { id: 'SK', text: 'SK' },
                //   { id: 'AM', text: 'AM' },
                //   { id: 'IN', text: 'IN' },
                //   { id: 'EG', text: 'EG' },
                //   { id: 'LV', text: 'LV' },
                //   { id: 'PK', text: 'PK' },
                //   { id: 'VN', text: 'VN' },
                //   { id: 'PT', text: 'PT' },
                //   { id: 'TT', text: 'TT' },
                //   { id: 'GT', text: 'GT' },
                //   { id: 'CU', text: 'CU' },
                //   { id: 'AE', text: 'AE' },
                //   { id: 'CL', text: 'CL' },
                //   { id: 'ZA', text: 'ZA' },
                //   { id: 'NZ', text: 'NZ' },
                //   { id: 'CI', text: 'CI' },
                //   { id: 'AR', text: 'AR' },
                //   { id: 'MA', text: 'MA' },
                //   { id: 'KR', text: 'KR' },
                //   { id: 'UZ', text: 'UZ' },
                //   { id: 'KG', text: 'KG' },
                //   { id: 'KZ', text: 'KZ' },
                // ],
                valuesTypePreset: [],
                _attruid: 'attruid_13',
              },
            ],
          },
        ],
      },
      // attrGroup: _attrGroupAdapter.set(this.props.productData.attrGroup)
      moderateErrors: [],
      popupElement: {},
    };
    // this.validateInnLengthDebounce = debounce(this.validateInnLength, WAIT_INTERVAL);
    this.validateEmailDebounce = debounce(
      this.validateEmailAndEmpty,
      WAIT_INTERVAL,
    );
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!isEqual(this.props.data.inn, prevProps.data.inn)) {
      this.setState(
        (prevState) => ({
          errors: { ...prevState.errors, inn: false },
          innValidation: {
            ...prevState.innValidation,
            message: '',
            successed: false,
          },
        }),
        // this.validateInnLengthDebounce
      );
    }

    const valueEmail = getDefaultTo(this.props.data, 'email');
    const emailValid = validateEmailAndEmpty(valueEmail);
    if (prevState.emailValid !== emailValid) {
      this.setState({ emailValid });
      emailValid ? this.props.validCbk(true) : this.props.validCbk(false);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {

    const valuesTypePreset = prevState.data.attrGroup[0].attributes[0].valuesTypePreset
    if (nextProps.countries && nextProps.countries !== prevState.data.attrGroup[0].attributes[0].valuesTypePreset) {
      return valuesTypePreset.splice(0, valuesTypePreset.length, ...nextProps.countries)
    }
    return null;
  }

  componentWillUnmount() {
    // this.validateInnLengthDebounce.cancel();
    this.validateEmailDebounce.cancel();
  }

  deleteItem = () => {
    this.props.deleteItem(this.props.data.id);
  };

  handleChange = (name) => (value) => {
    if (name === 'inn' || name === 'gcp' || name === 'gln') {
      value = value.replace(/[^[0-9]/, '');
    }
    if (name === 'phone') {
      value = value.replace(/[A-Za-z]/, '');
    }
    this.props.updateItem(this.props.data.id, { [name]: value });
  };

  handlePaste = (name, typeId) => (value) => {
    this.props.updateItem(this.props.data.id, { [name]: value }, typeId);
  };

  // validateInnLength = () => {
  //     const {checkRegisteredInn, handleErrors, updateItem, isInnBusy, t} = this.props;
  //     const {inn, id} = this.props.data;
  //     let error = {};
  //     if (!validateInn(inn, error)) {
  //         this.setState(
  //             prevState => ({errors: {...prevState.errors, inn: {message: error.message}}}),
  //             () => handleErrors(id, true)
  //         );
  //     } else if (isInnBusy) {
  //         this.setState(
  //             prevState => ({errors: {...prevState.errors, inn: {message: t('ИНН занят')}}}),
  //             () => handleErrors(id, true)
  //         );
  //     } else {
  //         this.setState(prevState => ({innValidation: {...prevState.innValidation, process: true}}));
  //         checkRegisteredInn(inn, (errors, data) => {
  //             let error = errors ? {inn: {message: errors.error[0]}} : {};
  //             this.setState({
  //                     innValidation: {
  //                         process: false,
  //                         successed: !errors
  //                     },
  //                     errors: error
  //                 }, () => {
  //                     handleErrors(id, !!errors);
  //                     if (data) updateItem(id, {'name': get(data, 'name', '')});
  //                 }
  //             );
  //         });
  //     }
  // };

  validateEmailAndEmpty = () => {
    const { handleErrors } = this.props;
    const { id } = this.props.data;

    this.setState(
      (prevState) => ({
        errors: { ...prevState.errors, email: { message: 'error.message' } },
      }),
      () => handleErrors(id, true),
    );
  };

  updateData = (selection, data) => {
    if (selection === 'identifier') {
      _.forEach(data, (pack) => {
        _.forEach(['length', 'width', 'height', 'weight'], (i) => {
          if (!_.isNil(pack[i])) {
            pack[i] = Number(pack[i]);
          }
        });
      });
    }
    this.setState((prevState) => ({
      data: { ...prevState.data, [selection]: data },
    }));
  };

  updateItem = (items, groupIndex, index) => {
    let attrGroup = _.cloneDeep(this.state.data.attrGroup);
    let attributes = attrGroup[groupIndex].attributes;
    const updateItem = items[index];

    this.handleChange('direction')({
      address: updateItem.value,
      country: updateItem.valueType,
    });

    const updatedIndex = findItemIndex(attributes, items[index], index);
    if (!_.isUndefined(updatedIndex)) {
      _.set(
        attrGroup,
        `[${groupIndex}].attributes.[${updatedIndex}]`,
        updateItem,
      );
      this.updateData('attrGroup', attrGroup);
    }
  };

  buildBlocks = (firstPackageGroups) => {
    const jsxBlocks = [];
    _.forEach(firstPackageGroups, (attribute, attributeIndex) => {
      const elements = [];
      const ids = [];
      _.forEach(attribute.attributes, (element) => {
        if (!ids.includes(element.id)) {
          ids.push(element.id);
          elements.push(
            this.buildElement(element, attribute.attributes, attributeIndex),
          );
        }
      });

      jsxBlocks.push(
        <div key={`block_group_${attributeIndex}`}>{elements}</div>,
      );
    });

    return jsxBlocks;
  };

  buildElement = (element, elements, attributeIndex) => {
    const { onValidate, productData } = this.props;
    // const {preview} = this.props;
    const { moderateErrors, popupElement, errors } = this.state;

    let props = {
      groupIndex: attributeIndex,
      updateItem: this.updateItem,
      onValidate: onValidate,
      showPopupSearch: this.showPopupSearch,
      closePopupSearch: this.closePopupSearch,
      popupElement: popupElement,
      errors: errors,
      moderateErrors: moderateErrors,
      // preview: preview,
      hasPublishedGood: _.get(productData, 'hasPublishedGood', false),
    };

    if (element.multiplicity) {
      props = {
        ...props,
        multiplicity: true,
        items: _.filter(elements, { id: element.id }),
        addItem: this.addItem,
        deleteItem: this.deleteItem,
      };
    } else {
      props = {
        ...props,
        multiplicity: false,
        items: [element],
        addItem: () => { },
        deleteItem: () => { },
      };
    }

    return (
      <Element key={`element_${element._attruid}`} name={`id_${element.id}`}>
        <ConsumerItem {...props} />
      </Element>
    );
  };

  render() {
    const { data, t, typeId, modalType } = this.props;
    const { emailValid } = this.state;
    const { id } = data;
    const view = modalType === 'view'

    const fields = this.buildBlocks(this.state.data.attrGroup);

    const valuePhone = getDefaultTo(data, 'phone');
    const phoneValid =
      !!valuePhone.replace(/\D/g, '') && validatePhone(valuePhone);

    const gcpIsValid = _.includes(
      [4, 5, 6, 7, 8, 9],
      _.size(_.toString(data.gcp)),
    );
    const glnIsValid = _.includes([13], _.size(_.toString(data.gln)),);

    return (
      <div>
        <SubItemContainer>
          <SubItemInn>
            <CustomInput
              id={`inn_${id}`}
              disabled={view}
              name="inn"
              required
              label={t('Налоговый идентификатор')}
              onUpdate={() => { }}
              onChange={this.handleChange('inn')}
              onPaste={this.handlePaste('inn', typeId)}
              value={defaultTo(data.inn, '')}
              valid={!!data.inn && data.inn.length >= 3}
              maxLength={14}
              type="text"
              t={t}
            />
            {/* {this.state.errors.inn
                            ? SimpleTooltip(this.state.errors.inn.message, <IconError/>)
                            : this.state.innValidation.process
                                ? <InputIcon><IconPreloader/></InputIcon>
                                : this.state.innValidation.successed
                                    ? <InputIcon><IconValid/></InputIcon>
                                    : ''
                        } */}
          </SubItemInn>

          <SubItemName>
            <CustomInput
              disabled={view}
              id={`name_${id}`}
              name="name"
              required
              label={t('Наименование компании')}
              onUpdate={() => { }}
              onChange={this.handleChange('name')}
              value={defaultTo(data.name, '')}
              valid={!!data.name}
              type="text"
            />
          </SubItemName>

          { view
            ? <SubItemAdress>
                <CustomInput
                  disabled
                  id="address"
                  name="address"
                  label={t('Юридический адрес')}
                  value={getDefaultTo(data.direction, 'address')}
                  type="text"
                />
              </SubItemAdress>
           : <SubItemCountry>{fields}</SubItemCountry>}

          <SubItemGcp>
            <CustomInput
              id={`gcp_${id}`}
              disabled={view}
              name="gcp"
              label={t('GCP компании')}
              onUpdate={() => { }}
              onChange={this.handleChange('gcp')}
              onPaste={this.handlePaste('gcp', typeId)}
              value={defaultTo(data.gcp, '')}
              valid={!view && gcpIsValid}
              error={!view && !!(data.gcp && !gcpIsValid)}
              type="text"
              t={t}
              maxLength={9}
            />
            <SubItemErrorContent>{!view && data.gcp && !gcpIsValid && t('Указаны некорректные сведения')}</SubItemErrorContent>
          </SubItemGcp>

          <SubItemGln>
            <CustomInput
              id={`gln_${id}`}
              disabled={view}
              name="gln"
              label={t('GLN компании')}
              onUpdate={() => { }}
              onChange={this.handleChange('gln')}
              onPaste={this.handlePaste('gln', typeId)}
              value={defaultTo(data.gln, '')}
              valid={!view && !!data.gln && glnIsValid}
              type="text"
              maxLength={13}
              error={!view && !!(data.gln && !glnIsValid)}
              t={t}
            />
            <SubItemErrorContent>{!view && data.gln && !glnIsValid && t('Указаны некорректные сведения')}</SubItemErrorContent>
          </SubItemGln>

          <SubItemEmail>
            <CustomInput
              id={`email_${id}`}
              name="email"
              disabled={view}
              label={t('E-mail')}
              onUpdate={() => { }}
              onChange={this.handleChange('email')}
              value={defaultTo(data.email, '')}
              valid={!view && data.email ? emailValid : true}
              error={!view && !!_.defaultTo(data.email, '') && !emailValid}
              type="text"
            />
            {this.state.errors.email ? (
              SimpleTooltip(this.state.errors.email.message, <IconError />)
            ) : this.state.emailValidation.process ? (
              <InputIcon>
                <IconPreloader />
              </InputIcon>
            ) : this.state.emailValidation.successed ? (
              <InputIcon>
                <IconValid />
              </InputIcon>
            ) : (
              ''
            )}
            <SubItemErrorContent>{!view && data.email && !emailValid  && t('Введите корректный E-mail')}</SubItemErrorContent>
          </SubItemEmail>

          <SubItemPhone>
            <PhoneInput
              id={`phone_${id}`}
              name="phone"
              disabled={view}
              label={t('Телефон')}
              phone={valuePhone}
              placeholder={t('Телефон')}
              onChange={this.handleChange('phone')}
              onUpdate={() => { }}
              phoneValid={phoneValid || !getDefaultTo(data, 'phone')}
              autoComplete="off"
              valid={!!data.phone && phoneValid}
              error={!phoneValid}
            />
            <SubItemErrorContent>{!view && data.phone && !phoneValid && t('Введите корректный номер телефона')}</SubItemErrorContent>
          </SubItemPhone>
        </SubItemContainer>
      </div>
    );
  }
}

SubAccountItem.propTypes = {
  throwError: PropTypes.func.isRequired,
  // deleteItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  handleErrors: PropTypes.func.isRequired,
  checkRegisteredInn: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  // allowDelete: PropTypes.bool.isRequired,
  // isInnBusy: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  productData: state.productReducer.data,
  moderateErrors: state.productReducer.moderateErrors,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwError: (message) => throwNotification(message, 'error'),
      checkRegisteredInn: (inn, cbk) => validateInnSubAccount(inn, cbk),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(SubAccountItem);
