import * as React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Typography,
} from '@material-ui/core';
import { CopyProductAlertTheme } from './styled/CopyProductAlert.styled';

class CopyProductAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmed: false,
    };
  }

  render() {
    const { t, onConfirm, onCancel } = this.props;

    return (
      <MuiThemeProvider theme={CopyProductAlertTheme}>
        <Dialog open>
          <DialogTitle disableTypography>
            <Typography variant="h3">
              <span>
                {t('Внимание!')}
              </span>
              {t('Создаётся экземпляр карточки')}
            </Typography>
            {/**скрыто до уточнения */}
            {/* <MuiThemeProvider theme={CreationCloseBtns}>
              <Button className="backEmpty" onClick={onCancel}>
                <IconClose />
              </Button>
            </MuiThemeProvider> */}
          </DialogTitle>

          <DialogContent>
            {t(
              'Не весь набор полей карточки экземпляра будет доступен для редактирования',
            )}
          </DialogContent>

          <DialogActions>
            <Button color={'secondary'} onClick={onCancel}>
              {t('Отменить')}
            </Button>
            <Button color={'primary'} onClick={onConfirm}>
              {t('Продолжить')}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

CopyProductAlert.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default compose(withTranslation())(CopyProductAlert);
