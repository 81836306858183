import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import {
  FormControl,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
} from '@material-ui/core';
import { IconDropDown } from '../../../../../common/icons/customIcons';
import { customInputLeft } from './SelectTypeItem.styled';
import { Button } from '@material-ui/core';
import { IconMenuClose } from '../../../../../common/icons/customIcons';

class SelectTypeItem extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!_.isEqual(this.state, nextState)];
    const nameProps = ['id', 'valueType', 'error', 'disabled', 'valid', 'required'];
    _.forEach(nameProps, (i) =>
      equal.push(!_.isEqual(this.props[i], nextProps[i])),
    );
    return _.some(equal);
  }

  renderCleanButton = () => {
    const { disabled, required, infoText } = this.props;

    const marginRight = infoText ? 35 : 5;

    if (!required && !disabled) {
      return (
        <Button
          style={{
            position: 'absolute',
            right: marginRight,
            width: 30,
            minWidth: 30,
            maxHeight: 30,
            backgroundColor: 'transparent',
            color: 'rgba(0, 0, 0, 0.87)',
            padding: '6px 16px',
            paddingBottom: '8px',
            fontSize: '0.875rem',
            transition:
              'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: 4,
            letterSpacing: '0.02857em',
            textTransform: 'uppercase',
            border: 0,
            cursor: 'pointer',
            margin: 0,
            display: 'inline-flex',
            outline: 0,
            alignItems: 'center',
            userSelect: 'none',
            verticalAlign: 'middle',
            justifyContent: 'center',
            textDecoration: 'none',
            boxShadow: 'none',
            top: '50%',
            marginTop: -15,
          }}
          onClick={this.handleClear}>
          <div style={{ width: 20, height: 20, paddingBottom: 5 }}>
            <IconMenuClose style={{ width: 12, height: 12 }} />
          </div>
        </Button>
      );
    }

    return null;
  };

  render() {
    const {
      id,
      valueType,
      onChange,
      valuesTypePreset,
      required,
      error,
      disabled,
      valid,
      defaultValue,
      onBlur,
    } = this.props;
    const valueItem = defaultValue ? defaultValue : _.defaultTo(valueType, '');

    return (
      <MuiThemeProvider theme={customInputLeft}>
        <FormControl
          style={{ position: 'relative' }}
          key={`select-${id}`}
          fullWidth={true}>
          <InputLabel
            disabled={disabled}
            error={error}
            htmlFor={`select-type-${id}`}
          />
          <Select
            onBlur={onBlur}
            disabled={disabled}
            required={required}
            valid={id === '15677_0' ? _.toString(required && !!valueItem && !!valid && !error || !!defaultValue) : _.toString(required && !!valueItem && !!valid && !error)}
            value={valueItem}
            error={error}
            inputProps={{ id: `select-type-${id}` }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            }}
            IconComponent={IconDropDown}
            onChange={(event) => onChange(event)}
            className={cx({ 'disabled-item': disabled })}
          >
            {_.map(valuesTypePreset, (item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MuiThemeProvider>
    );
  }
}

SelectTypeItem.propTypes = {
  attrUid: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  valueType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  valuesTypePreset: PropTypes.array.isRequired,
  required: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
};
SelectTypeItem.defaultProps = {
  required: false,
  error: false,
};
export default SelectTypeItem;
