import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import Slider from 'infinite-react-carousel';
import {
  IconImageBig,
  IconDropDown,
} from '../../common/icons/customIcons';
import {
  SliderContainer,
  SliderDots,
  SliderImage,
  SliderImageTitle,
  SliderImageContent,
} from './slider.styled';
import { getPhotoType } from '../../common/constants/Packaging';

class SimpleSlider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
    };
  }

  setObjectIndex = (oldIndex, newIndex) => {
    if (newIndex !== undefined && newIndex !== this.state.currentIndex) {
      return this.setState({
        currentIndex: newIndex,
      });
    }

    if (newIndex === undefined && _.isNumber(oldIndex)) {
      return this.setState({
        currentIndex: oldIndex,
      });
    }
  };

  render() {
    const { t, data } = this.props;
    const imageType = _.defaultTo(
      data[this.state.currentIndex]?.type,
      ``,
    );

    return (
      <SliderContainer>
        <SliderDots>
          {data.length
            ? (
              <Slider
                prevArrow={<button><IconDropDown /></button>}
                nextArrow={<button><IconDropDown /></button>}
                beforeChange={(oldIndex, newIndex) =>
                  this.setObjectIndex(oldIndex, newIndex)}
              >
                {data.length &&
                  data.map((item) => {
                    return (
                      <div key={item.id}>
                        <SliderImage>
                          {!!item.url
                            ? <SliderImageContent
                              alt="Изображение"
                              src={typeof item.url === 'string' ? item.url : URL.createObjectURL(item.url)}
                            />
                            : <IconImageBig />}
                        </SliderImage>
                      </div>
                    );
                  })}
              </Slider>
            )
            : (
              <SliderImage empty>
                <IconImageBig />
              </SliderImage>
            )
          }
          {!!data.length && (
            <SliderImageTitle>{t(getPhotoType(imageType))}</SliderImageTitle>
          )}
        </SliderDots>
      </SliderContainer>
    );
  }
}

SimpleSlider.propTypes = {
  data: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  photoTypes: state.productReducer.photoTypesDictionary,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {

    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(SimpleSlider);
