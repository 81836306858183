import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, MuiThemeProvider, Popover, IconButton, } from '@material-ui/core';
import { CustomInput } from '../../../../components';
import {
  IconClose,
  IconNewFilterSmall,
  IconNewFilterSmallActive,
} from '../../../../common/icons/customIcons';
import {
  defaultFilterInput,
  DefaultFilterPopover,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
  FilterBtnReset,
} from './Filters.styled';

class NameFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      name: _.defaultTo(this.props.currentValue, ''),
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.currentValue === null && state.currentValue !== null) {
      return {
        anchorEl: null,
        name: '',
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextState, this.state);
  }

  onValueChange = (value) => {
    this.setState({ name: value });
  };

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    if (!_.isNull(this.props.currentValue) && !this.state.name) {
      this.props.onDismiss('name');
    }
    this.setState({
      anchorEl: null,
      name: _.defaultTo(this.props.currentValue, ''),
    });
  };

  handleAccept = () => {
    this.setState({
      anchorEl: null,
      currentValue: this.state.name,
    });
    this.props.onAccept('name', this.state.name);
  };

  handleDissmiss = () => {
    this.setState({ anchorEl: null, name: '' });
    this.props.onDismiss('name');
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <MuiThemeProvider theme={FilterBtn}>
          <FilterButton>
            <Button
              aria-owns={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
              {_.isNull(this.props.currentValue) ? (
                <IconNewFilterSmall />
              ) : (
                <IconNewFilterSmallActive />
              )}
            </Button>
          </FilterButton>
        </MuiThemeProvider>
        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Торговое наименование товара')}</h6>

              <MuiThemeProvider theme={FilterBtn}>
                <IconButton className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </IconButton>
              </MuiThemeProvider>
            </FilterTitle>

            <FilterContent>
              <MuiThemeProvider theme={defaultFilterInput}>
                <CustomInput
                  label={t('Введите наименование товара')}
                  onChange={(value) => this.onValueChange(value)}
                  cleanButtonStyles={{ marginTop: -15, display: 'none' }}
                  onBlur={false}
                  value={this.state.name}
                  name="name-filter"
                />
              </MuiThemeProvider>
            </FilterContent>

            <FilterBtns>
              <MuiThemeProvider theme={FilterBtnReset}>
                <Button
                  disabled={!this.state.name}
                  onClick={this.handleDissmiss}>
                  {t('Сбросить')}
                </Button>
              </MuiThemeProvider>
              <Button disabled={!this.state.name} onClick={this.handleAccept}>
                {t('Применить')}
              </Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </div>
    );
  }
}

NameFilter.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
  parentId: PropTypes.string.isRequired,
};

export default withTranslation()(NameFilter);
