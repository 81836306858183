import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators, compose } from 'redux';
import { Doughnut } from 'react-chartjs-2';
import {
    ChartContainerInner,
    ChartContainerHolder,
    ChartTooltip,
    ChartStatusLine,
    ChartStatusColorContainer,
    ChartStatusColor,
    ChartStatusTitle,
    ChartStatusesIconHolder,
} from '../styled/StatisticsPie.styled';
import _ from 'lodash';

const AnalyticStatuses = [
    { id: 0, title: 'Все ракурсы имеют водяной знак', value: 'photoHasWatermarkCount', color: '#2DCD13' },
    { id: 1, title: 'Часть ракурсов имеют водяной знак', value: 'photoHasWatermarkPartlyCount', color: '#67BAFF' },
    { id: 2, title: 'Полностью без водяного знака', value: 'photoHasNoWatermarkCount', color: '#A6A6A6' },
];

const deleteAllClass = (photoContentStatistic) => {
    _.forEach(AnalyticStatuses, (i) => {
      if (photoContentStatistic[i.value]) {
        document.getElementsByName(i.title)[0].classList.remove('active');
      }
    });
  };

const equalZero = (number) => number === 0;

const setTooltip = (name, count, sumValues, defaultName) => {

  const tooltipEl = document.getElementById('chartjs-tooltip-photo');
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.top = '50%';
  tooltipEl.style.transform = 'translateY(-50%)';
  tooltipEl.style.left = '0';
  tooltipEl.style.right = '0';
  tooltipEl.style.margin = '0 auto';
  tooltipEl.style.width = '70%';
  tooltipEl.style.textAlign = 'center';
  tooltipEl.style.opacity = 1;
  //tooltipEl.innerHTML = `<div>${name === 'Всего товаров' || name === 'Жалпы элементтер' ? name : ''}</div> <div style="font-size: 24px">${count}</div>`;
  tooltipEl.innerHTML = `<div>${defaultName}</div> <div>${sumValues}</div>`;
};

const setClass = (name) =>
  document.getElementsByName(name)[0].classList.add('active');

class StatisticsPie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photo: null
    };
  }

  componentDidMount() {
    this.defaultTooltip();
  };

    defaultTooltip = () => {
        const { pieData } = this.dataPreparation();
        const { photoContentStatistic, sumValues, t } = this.props;
        if (_.isEmpty(photoContentStatistic) || _.values(photoContentStatistic).every(equalZero)) {
          return;
        }
        if (_.isEmpty(pieData.labels)) {
          return setTooltip(_.replace(``), ``);
        }
        //setTooltip(_.replace(this.packageName()), sumValues);
        setTooltip(_.replace(this.packageName()),``,sumValues, t('Всего товаров'));
      };

    packageName = (name) => {
      const { t } = this.props;
      switch (name) {
        case 'Все ракурсы имеют водяной знак':
          return t(`Все с водяным знаком`);
        case 'Часть ракурсов имеют водяной знак':
          return t(`Часть с водяным знаком`);
        case 'Полностью без водяного знака':
          return t(`Без водяного знака`);
        default:
          return t(`Всего товаров`);
      }
    };


    dataPreparation = () => {
      const { photoContentStatistic } = this.props;
      const dataPie = {
        ...photoContentStatistic,
      };
      return { pieData: this.createDataSet(dataPie), dataPie };
    };

    createDataSet = (photoContentStatistic) => {
      if (_.isNil(photoContentStatistic) || _.isEmpty(photoContentStatistic)) {
        return {};
      }
      const labels = [];
      const data = [];
      const backgroundColors = [];
      _.forEach(AnalyticStatuses, (status) => {
        if (photoContentStatistic[status.value]) {
          labels.push(status.title);
          data.push(_.defaultTo(photoContentStatistic[status.value], 0));
          backgroundColors.push(status.color);
        }
      });
  
      return {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColors,
            borderWidth: 2,
            // hoverBorderColor: backgroundColors,
            // hoverBorderWidth: 6,
            // weight: 3,
            // borderAlign: 'inner'
          },
        ],
      };
    };

 
    render() {
        const { t, sumValues, photoContentStatistic } = this.props
        const { pieData, dataPie } = this.dataPreparation();
        return (
            <ChartContainerInner>
                <ChartContainerHolder>
                <ChartTooltip id="chartjs-tooltip-photo" />
                <Doughnut
                    height={280}
                    data={pieData}
                    legend={{ display: false }}
                    options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    cutoutPercentage: 80,
                    layout: {
                      padding: {
                        left: 0,
                        right: 4,
                        top: 25,
                        bottom: 25,
                      }
                    },
                    tooltips: {
                        enabled: true,
                        intersect: true,
                        custom: (tooltipModel) => {
                        if (tooltipModel.opacity === 0) {
                            this.defaultTooltip();
                            deleteAllClass(dataPie);
                            return;
                        }

                        if (tooltipModel.body) {
                            deleteAllClass(dataPie);
                            const str = tooltipModel.body[0].lines[0].split(
                            ':',
                            );
                            const name = _.trim(str[0]);
                            const count = _.trim(str[1]);
                            setClass(name);
                            setTooltip(t(name), count, sumValues,t('Всего товаров'));
                            tooltipModel.body[0].lines[0] = count
                            tooltipModel.displayColors = false;
                            tooltipModel.width = 50;
                            tooltipModel.x = tooltipModel.caretX - 8;
                            tooltipModel.y = tooltipModel.caretY - 32;
                            tooltipModel.xAlign = 'left';
                            tooltipModel.yAlign = 'bottom';
                        }
                        },
                    },
                    }}
                />
                </ChartContainerHolder>
                <ChartStatusesIconHolder>
                {_.map(AnalyticStatuses, (status) => {
                    return (
                    <ChartStatusLine
                        key={status.value}
                        style={{
                        opacity: _.isEmpty(this.state.statuses)
                            ? 1
                            : _.includes(
                                this.state.statuses,
                                status.value,
                            )
                            ? 1
                            : 0.5,
                        }}
                        name={status.title}>
                        <ChartStatusColorContainer>
                        <ChartStatusColor
                            className="status-color"
                            style={{ background: status.color }}
                        />
                        </ChartStatusColorContainer>
                        &nbsp;
                        <ChartStatusTitle>
                        {t(status.title)}
                        </ChartStatusTitle>
                        {/* &nbsp;
                        <span>({dataPie[status.value]})</span> */}
                    </ChartStatusLine>
                    );
                })}
                </ChartStatusesIconHolder>
            </ChartContainerInner>
        )
    }
}

StatisticsPie.propTypes = {
    
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
    
      },
      dispatch,
    );
  };
  
  const mapStateToProps = (state) => ({

  });

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
  )(StatisticsPie);