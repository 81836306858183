import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../../common/styles/Common.colors';

export const DefaultFilterPopover = createMuiTheme({
  typography: {},
  overrides: {
    MuiPopover: {
      paper: {
        maxHeight: '500px',
        minWidth: '380px',
        maxWidth: '420px',
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
        borderRadius: '8px',
        padding: '24px 28px',
        boxSizing: 'border-box',
        marginLeft: '0',
        marginTop: '0',
      },
    },
    MuiButton: {
      root: {
        maxWidth: '160px',
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '4px',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '10px 35px',
        fontWeight: '600',
        textTransform: 'capitalize',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
        lineHeight: '24px',
      },
      disabled: {},
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        minWidth: '80px',
        margin: '0 16px 0 0 !important'
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: `${colors.Common.textColor} !important`,
      },
    },
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '4px',
      },
      input: {
        padding: '15px 9px',
        lineHeight: '21px',
        boxSizing: 'border-box',
      },
    },
    MuiInputBase: {
      input: {
        boxSizing: 'border-box',
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '3px',
        marginRight: '8px',
      },
    },
    MuiSvgIcon: {
      root: {
        width: '18px',
        height: '18px',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
      },
    },
  },
});
export const FilterBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '25px',
        maxHeight: '45px',
        marginLeft: 'auto',
        '&:hover': {
          backgroundColor: '#E7E7E7',
          borderRadius: '2px'
        },
      },
      text: {
        padding: '2px',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    }
  },
});
export const FilterBtnReset = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        marginRight: '20px',
        borderRadius: '4px',
        border: '1px solid #DCDCDC',
        '&:hover': {
          backgroundColor: '#F5F5F5 !important',
          color: `${colors.Common.textColor} !important`,
          borderColor: '#F5F5F5',
        },
        '&$disabled': {
          color: '#C9C7C7',
          '& path': {
            stroke: '#C9C7C7',
          }
        },
      },
      text: {
        padding: '9px 24px',
        fontWeight: '600',
        textTransform: 'none',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0',
      },
    },
  },
});
export const FilterTypingSelect = createMuiTheme({
  typography: {},
  overrides: {
    MuiInputBase: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
      },
      input: {
        padding: '16px 85px 16px 9px',
        fontSize: '14px',
        color: '#52535A',
        height: 'auto',
        fontFamily: 'SegoeUI',
      },
    },
    MuiMenuItem: {
      root: {
        padding: '8px 10px',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontSize: '14px',
        color: '#52535A',
        fontWeight: '500',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '300px',
        overflowY: 'auto',
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        width: '20px',
        maxHeight: '100%',
        pointerEvents: 'none',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '0',
        minWidth: '15px',
        width: '20px',
        height: '20px',
        marginTop: '-15px',
        '&:hover': {
          background: 'none!important',
        },
      },
      text: {
        padding: '3px',
      },
    },
  },
});
export const defaultFilterSelect = createMuiTheme({
  typography: {},
  overrides: {
    MuiSelect: {
      root: {
        width: '100%',
      },
      select: {
        padding: '23px 44px 7px 15px !important',
        borderRadius: '4px',
        lineHeight: '24px',
        '&:focus': {
          backgroundColor: 'transparent',
        }
      },
      selectMenu: {
        color: `${colors.Common.textColor}`,
      },
      icon: {
        right: '16px',
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
        marginTop: '0!important',
        textAlign: 'left',
        borderRadius: '4px',
        border: '1px solid #C9C7C7',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        // '&:hover path': {
        //   stroke: '#AAA9A9',
        // },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        }
      },
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey}`,
        fontSize: '16px',
        lineHeight: '24px',
      },
      formControl: {
        transform: 'translate(16px, 16px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 8px) scale(1)',
        color: `${colors.Common.textGrey} !important`,
      },
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        '&$focused': {
          color: `${colors.Common.textColor}`,
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '0',
        paddingTop: '16px',
        paddingBottom: '16px',
        color: `${colors.Common.textColor}`,
        fontSize: '16px',
        lineHeight: '24px',
        '&:hover': {
          backgroundColor: 'rgba(231, 231, 231, 0.35)',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingRight: '24px',
        paddingLeft: '24px',
      },
    },
    MuiPaper: {
      root: {
        marginTop: '8px',
      },
      rounded: {
        borderRadius: '8px',
      },
      elevation8: {
        boxShadow: '0px 8px 48px rgba(86, 118, 129, 0.1)',
      },
    },
  },
});
export const defaultFilterInput = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        width: '100%',
        borderRadius: '4px',
        border: '1px solid #C9C7C7',
        '&:hover': {
          border: '1px solid #AAA9A9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
        },
        '&$error': {
          border: '1px solid #E32A38',
        },
      },
      input: {
        color: `${colors.Common.textColor}`,
        padding: '23px 44px 7px 15px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 'normal',
        '&:required': {
          borderRadius: '4px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
      inputMultiline: {
        padding: '0!important',
      },
      multiline: {
        minHeight: '56px',
        padding: '23px 44px 7px 15px',
      },
      error: {},
    },
    MuiInputBase: {
      root: {
        width: '100%',
        marginTop: '0!important',
      },
      input: {
        boxSizing: 'border-box',
        height: 'auto',
        fontFamily: 'SegoeUI',
      },
    },
    MuiInputLabel: {
      root: {
        color: `${colors.Common.textGrey} !important`,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'SegoeUI',
      },
      formControl: {
        transform: 'translate(16px, 16px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
      shrink: {
        display: 'block',
        fontSize: '14px',
        lineHeight: '16px',
        transform: 'translate(16px, 8px) scale(1)',
        color: `${colors.Common.textGrey}`,
      },
    },
    MuiFormLabel: {
      root: {
        color: `${colors.Common.textColor}`,
        '&$focused': {
          color: `${colors.Common.textColor}`,
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});
export const FilterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6 {
    color: ${colors.Common.textColor};
    font-size: 18px;
    margin: 0 24px 0 0;
    line-height: 24px;
    font-weight: 600;
    padding-bottom: 3px;
  }
  .backEmpty {
    background: none;
  }
`;
export const FilterContent = styled.div`
  display: flex;
  padding: 28px 0;
  position: relative;
`;
export const FilterContentProvider = styled(FilterContent)`
  flex-direction: column;
`;
export const FilterContentProviderContainer = styled.div`
  position: relative;
`;
export const FilterBtns = styled.div`
  display: flex;
  justify-content: flex-end;
  .backEmpty {
    background: none;
    color: ${colors.Common.textColor} !important;
    &:hover{
      color: #fff !important;
    }
  }
`;
export const FilterContainer = styled.div`
  .filterBtn {
    min-width: 25px;
    padding: 4px;
    height: 25px;
  }
`;
export const DateRangeCont = styled.div`
  width: 100%;
  .rdrCalendarWrapper {
    width: 100%;
  }
  .rdrMonthAndYearPickers {
    justify-content: flex-start;
  }
  .rdrMonths {
    padding-top: 20px;
  }
  .rdrMonth {
    padding: 0;
    box-sizing: border-box;
    width: 100% !important;
  }
  .rdrWeekDays {
    display: none;
  }
  .rdrMonthPicker,
  .rdrYearPicker {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    min-width: 130px;
    select {
      width: 100%;
    }
  }
  .rdrDay {
    font-size: 14px;
    color: #52535a;
    font-weight: 600 !important;
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrInRange {
      background: ${colors.Common.background};
      height: 100%;
      left: 0;
      width: 100%;
      top: 0;
      border-radius: 4px;
      z-index: 2;
    }
    .rdrStartEdge {
      border-radius: 4px 0 0 4px;
    }
    .rdrEndEdge {
      border-radius: 0 4px 4px 0;
    }
    .rdrInRange {
      border-radius: 0;
    }
    .rdrStartEdge.rdrEndEdge {
      border-radius: 4px;
    }
    .rdrDayNumber {
      background: #f9f9f9;
      font-weight: 600 !important;
      height: 100%;
      left: 0;
      width: 100%;
      top: 0;
      border: 1px solid #fff;
    }

    .rdrDayNumber span {
      color: #52535a !important;
      z-index: 3;
    }
  }
  .rdrDayPassive .rdrDayNumber span {
    color: #d9d9d9 !important;
  }
  .rdrDay.rdrDayToday {
    .rdrDayNumber {
      span: after {
        background: #52535a !important;
      }
    }
  }
`;
export const FilterButton = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;
