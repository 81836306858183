import styled from 'styled-components';

export const AutoInfoIcon = styled.span`
  display: block;
  height: 24px;
  width: 24px;  
  padding: 2px;
`;
export const AutoInfoIconHolder = styled.div`  
  height: 28px;
  width: 28px;
`;
export const AutoErrorIconHolder = styled.div`  
  position: absolute;
  top: 19px;
  right: 54px;
`;
