import styled from 'styled-components';

export const SliderImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 2px);  
  width: 338px;
  border: 1px solid #E7E7E7;
  border-radius: 4px;
  position: relative;
  margin: ${props => props.empty ? '0 32px' : '0'}; 
`;
export const SliderImageContent = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%; 
  object-fit: contain;
`;
export const SliderDots = styled.div` 
  .carousel-initialized {
    display: flex;
    justify-content: center;    
    width: 402px;
    max-height: 338px;
    height: calc(100vh - 460px);
    min-height: 120px;       
  }
  .carousel-next {
    transform: rotate(-90deg);
    width: 24px;    
  }
  .carousel-prev {
    transform: rotate(90deg);
    width: 24px;   
  }
  .carousel-track {
    width: 338px;
    overflow: hidden;    
  }
  .carousel-row {
    height: 100%;
    display: flex;   
  }
  .carousel-item {
    max-width: 338px;
    max-height: 338px;
    height: calc(100vh - 462px);
    min-height: 118px;
    overflow: hidden;   
  }  
  .carousel-arrow: hover path {
    stroke: #AAA9A9;
  }
`;
export const SliderImageTitle = styled.h3` 
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;  
  text-align: center;
  margin: 0;
`;

export const SliderContainer = styled.div`
    .slider__dots {
        display: flex !important;
        list-style: none;
        li {
            border: 0.5px solid #DCDCDC;
            border-radius: 4px;
            height: 65px;
            width: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px;
        }
    }
`;
