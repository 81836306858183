const commonColors = {
  Home: {
    background: 'linear-gradient(#67AC5C, #58944E)',
    title: '#FFF',
  },
  Header: {
    profileLogo: "#67AC5C",
    profileColor: "#FFF",
    profileMenuColor: "#FFF",
    profileMenuBackground: "#67AC5C",
  },
  Sidebar: {
    buttonBorder: "#67AC5C"
  },
  Buttons: {
    buttonPrimary: {
      background: "#67AC5C",
      backgroundHovered: "#54964A",
      backgroundInactive: '#B1D1AC',
      color: "#FFF",
      colorHovered: "#67AC5C",
      boxShadow: "rgba(103, 172, 92, .4)",
      boxShadowHovered: "rgba(103, 172, 92, .6)"
    },
    textButton: {
      color: '#67AC5C',
      colorHovered: '#54964A',
    },
  },
  Tabs: {
    tabLine: "#67AC5C"
  },
  Grid: {
    pageSizeBackground: '#F5F5F5',
    paginationBackground: '#F5F5F5',
    paginationColor: '#2F3D4A',
    pageSizeColor: '#2F3D4A',
  },
  Scroll: {
    background: "#67AC5C"
  },
  Icons: {
    background: "#67AC5C",
    fill: "#FFF",
    fillSecondary: "#FFF",
    borderLight: 'rgba(103, 172, 92, .2)',
    backgroundLight: 'rgba(103, 172, 92, .07)',
  },
  Input: {
    border: "#67AC5C",
    checkBox: "#67AC5C",
  },
  Common: {
    background: "#67AC5C",
    backgroundLight: '#E2FBDD',
    color: "#FFF",
    textColor: '#2F3D4A',
    textGrey: '#949494',
  },
};
export default commonColors;
