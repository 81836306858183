import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../common/styles/Common.colors';

export const DialogActionsRight = styled.div`
  width: 292px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.4s ease;
`;

export const CreationSiteDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '762px',
        maxWidth: '778px',
        borderRadius: '8px',
        padding: '0',
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '32px 36px 30px',
      },
    },
    MuiTypography: {
      h2: {
        fontSize: '21px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        marginRight: '16px',
      }
    },
    MuiDialogContent: {
      root: {
        padding: '0 36px',
        maxHeight: '700px',
      },
    },
    MuiDialogActions: {
      root: {
        margin: '0',
        padding: '32px 36px',
        justifyContent: 'flex-end',
      },
    },
    MuiButton: {
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: '.4s ease',
        borderRadius: '4px',
        padding: '10px 35px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
      },
      textSecondary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: '.4s ease',
        borderRadius: '4px',
        padding: '10px 35px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      root: {
        fontSize: '16px',
        textTransform: 'none',
        borderRadius: '4px',
        height: 'auto',
        minWidth: '200px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: '600',
      },
      text: {
        padding: '14px 20px',
      },
      label: {
        lineHeight: '19px',
      },
      disabled: {},
    },
    MuiIconButton: {
      root: {
        padding: '0',
        borderRadius: 'none',
        maxWidth: '24px',
        minWidth: '24px',
        maxHeight: '24px',
        minHeight: '24px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
  },
});

export const CreationSiteDialogPie = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '740px',
        maxWidth: '740px',
        borderRadius: '8px',
        padding: '32px 36px',
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 0 8px',
      },
    },
    MuiTypography: {
      h2: {
        fontSize: '21px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
      }
    },
    MuiDialogContent: {
      root: {
        padding: '0',
        maxHeight: '700px',
      },
    },
    MuiDialogActions: {
      root: {
        margin: '0',
        padding: '32px 0 0',
        justifyContent: 'flex-end',
      },
    },
    MuiButton: {
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: '.4s ease',
        borderRadius: '4px',
        padding: '10px 35px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
      },
      textSecondary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: '.4s ease',
        borderRadius: '4px',
        padding: '10px 35px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      root: {
        fontSize: '16px',
        textTransform: 'none',
        borderRadius: '4px',
        height: 'auto',
        minWidth: '200px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: '600',
      },
      text: {
        padding: '14px 20px',
      },
      label: {
        lineHeight: '19px',
      },
      disabled: {},
    },
    MuiIconButton: {
      root: {
        padding: '0',
        borderRadius: 'none',
        maxWidth: '24px',
        minWidth: '24px',
        maxHeight: '24px',
        minHeight: '24px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        '&:hover path': {
          stroke: '#AAA9A9',
        },
      },
    },
  },
});

export const CreationCloseBtns = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '24px',
        //height: '24px',
        position: 'absolute',
        top: '30px',
        right: '20px',
      },
      text: {
        padding: '3px',
        color: '#868686',
        fontSize: '14px',
        textTransform: 'uppercase',
      },
    },
  },
});

export const TotalRecordsDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialogActions: {
      root: {
        margin: '0',
        padding: '32px 36px',
        justifyContent: 'space-between',
        fontSize: '14px',
        lineHeight: '20px',
        color: `${colors.Common.textGrey}`,
        borderTop: '1px solid #E7E7E7',
      },
    },
    MuiButton: {
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: '.4s ease',
        borderRadius: '4px',
        padding: '10px 35px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundInactive}`,
        },
      },
      textSecondary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: '.4s ease',
        borderRadius: '4px',
        padding: '10px 35px',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.backgroundHovered}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      root: {
        fontSize: '16px',
        lineHeight: '24px',
        textTransform: 'none',
        borderRadius: '4px',
        height: 'auto',
        minWidth: '200px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: '600',
      },
      text: {
        padding: '10px 35px',
      },
      label: {
        lineHeight: '19px',
      },
      disabled: {},
    },
    MuiTooltip: {
      popper: {
        minWidth: '162px',
        maxWidth: '221px',
        margin: '0',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '300',
        letterSpacing: 'normal',
        color: '#FFF',
        opacity: '1!important',
        transform: 'none!important',
        backgroundColor: `${colors.Common.textColor}`,
        borderRadius: '8px',
        padding: '12px',
        position: 'relative!important',
        '&::after': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '8px 6px 0',
          borderColor: `${colors.Common.textColor} transparent transparent transparent`,
          borderStyle: 'solid',
          position: 'absolute',
          bottom: '-7px',
          right: 'calc(50% - 8px)',
        },
      },
      tooltipPlacementBottom: {
        margin: '8px 0 0 !important',
      }
    },
  }
});
